//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KeyboardAlpha from './keyboard.vue';
import KeyboardNumeric from './keypad.vue';
import KeyboardFullAlphaNumeric from './full-keyboard.vue';

export default {
  name: 'VirtualKeyboardDialog',
  components: {
    KeyboardAlpha,
    KeyboardNumeric,
    KeyboardFullAlphaNumeric,
  },
  data() {
    return {
      dialog: false,
      input: '',
      inputError: false,
      capsLockValue: false,
      inputMaxError: false,
    };
  },
  methods: {
    closeDialog() {
      this.inputMaxError = false;
      this.dialog = false;
    },
    onChange(input) {
      this.input = input;
      if (this.keyboardType === 'alphaNumericFull' || this.keyboardType === 'alphaNumericNotes') {
        const value = {
          input: this.input,
          modelName: this.modelName,
        };
        this.$emit('keyPressed', value);
      }
    },
    onKeyPress(button) {
      this.inputError = false;
      this.inputMaxError = false;
      if (button === '{enter}'
      && (this.keyboardType !== 'alphaNumericNotes' || this.keyboardType !== 'alphaNumericFull')) {
        if (this.input || this.allowEmpty) {
          if (this.maxLength && this.input.length > parseInt(this.maxLength, 10)) {
            this.inputMaxError = true;
            this.inputError = true;
          } else {
            const value = {
              input: this.input,
              modelName: this.modelName,
            };
            this.$emit('enterClicked', value);
            this.inputMaxError = false;
            setTimeout(
              this.closeDialog,
              300,
            );
          }
        } else {
          this.inputError = true;
        }
      }
      if (button === '{enter}'
      && (this.keyboardType === 'alphaNumericNotes' || this.keyboardType === 'alphaNumericFull')) {
        const value = {
          input: this.input,
          modelName: this.modelName,
        };
        this.$emit('enterClicked', value);
        setTimeout(
          this.closeDialog,
          300,
        );
      }
      if (button === '{cancel}') {
        this.input = '';
        const value = {
          input: '',
          modelName: this.modelName,
        };
        this.inputError = false;
        this.$emit('enterClicked', value);
        setTimeout(
          this.closeDialog,
          300,
        );
      }
      if (button === 'outside' && this.keyboardType === 'alphaNumericNotes') {
        const value = {
          input: this.input,
          modelName: this.modelName,
        };
        this.$emit('enterClicked', value);
        setTimeout(
          this.closeDialog,
          300,
        );
      }
    },
    onInputChange(input) {
      this.input = input.target.value;
    },
    getWidth() {
      if (this.keyboardType === 'alphaNumeric' || this.keyboardType === 'alphaNumericNotes') {
        return '70%';
      }
      if (this.keyboardType === 'alphaNumericFull') {
        return '80%';
      }
      return '22%';
    },
    getPersistent() {
      if (this.keyboardType === 'alphaNumericFull' || this.keyboardType === 'alphaNumericNotes') {
        return false;
      }
      return true;
    },
    getKeyboardClass() {
      if (this.keyboardType === 'alphaNumeric'
      || this.keyboardType === 'alphaNumericFull'
      || this.keyboardType === 'alphaNumericNotes') {
        return 'dialog-bottom-0';
      }
      return '';
    },
    getOverlayColor() {
      if (this.keyboardType === 'alphaNumericFull' || this.keyboardType === 'alphaNumericNotes') {
        return 'white';
      }
      return 'default';
    },
    getOverlayOpacity() {
      if (this.keyboardType === 'alphaNumericFull' || this.keyboardType === 'alphaNumericNotes') {
        return 0;
      }
      return 0.5;
    },
    getOverlay() {
      if (this.keyboardType === 'alphaNumericFull' || this.keyboardType === 'alphaNumericNotes') {
        return true;
      }
      return false;
    },
    close() {
      this.inputMaxError = false;
      this.$emit('dialogInputChanged', false);
    },
    cancel() {
      this.input = this.modelValue;
      const value = {
        input: this.modelValue,
        modelName: this.modelName,
      };
      this.inputError = false;
      this.$emit('enterClicked', value);
      setTimeout(
        this.closeDialog,
        300,
      );
    },
    confirm() {
      if (this.input) {
        const value = {
          input: this.input,
          modelName: this.modelName,
        };
        this.$emit('enterClicked', value);
        setTimeout(
          this.closeDialog,
          300,
        );
      } else if (this.allowEmpty) {
        const value = {
          input: '',
          modelName: this.modelName,
        };
        this.$emit('enterClicked', value);
        setTimeout(
          this.closeDialog,
          300,
        );
      } else {
        this.inputError = true;
      }
    },
    clickOutside() {
      this.onKeyPress('outside');
    },
  },
  props: {
    openDialog: Boolean,
    keyboardType: {
      default: '',
      type: String,
    },
    modelName: {
      default: '',
      type: String,
    },
    labelName: {
      default: '',
      type: String,
    },
    modelValue: {
      default: '',
      type: String,
    },
    confirmButtonText: {
      default: 'Confirm',
      type: String,
    },
    capsLock: {
      default: '',
      type: String,
    },
    maxLength: {
      default: '',
      type: String,
    },
    allowEmpty: {
      default: false,
      type: Boolean,
    },
  },

  watch: {
    openDialog(newVal) {
      this.inputError = false;
      if (this.keyboardType === 'numeric' && ((this.modelValue === 0 || this.modelValue === '0') && this.labelName !== 'Bottle weight')) {
        this.input = '';
      } else {
        this.input = this.modelValue;
      }
      this.dialog = newVal;
    },
    dialog(newVal) {
      this.$emit('dialogInputChanged', newVal);
    },
    capsLock(newVal) {
      if (newVal === 'Caps Lock') {
        this.capsLockValue = true;
      }
    },
  },
};
