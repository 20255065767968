
module.exports = (store, pageType, fromPath, toPath, triggerDialog) => {
  if (!store.state.stopDetail.stopVoidStatus) {
    store.commit('leaveApproval/setLeaveApprovalDialog', {
      isLeaveApprovalDialog: true,
      pageType,
      fromUrl: fromPath,
      redirectUrl: toPath,
      triggerDialog,
    });
  }
};
