<template>
  <v-row
    no-gutters
    class="ccid-main-list mt-3 cursor-pointer"
    @click.prevent="changeSelect()"
    :class="{ 'ccid-card-select': consolidateData.is_selected === true && !consolidateData.is_full}"
  >
    <v-col
      class="ccid-select-box"
      :class="getClass(consolidateData)"
    >
      <p class="ccid-select-title">
        {{ getSelectLabel(consolidateData) }}
      </p>
      <img
        alt="tick-outline"
        class="ccid-select-icon"
        :src="getImgUrl(consolidateData)"
      >
    </v-col>
    <v-col
      class="width-135 padding-10"
    >
      <label class="ccid-list-title mb-1 d-block">
        CCID
      </label>
      <p class="ccid-list-value">
        {{ consolidateData.ccid }}
      </p>
    </v-col>
    <div
      v-if="errorCCIDCard"
      :class="errorCardClass"
    >
      <span>
        <span class="forgot-font-weight">!</span>
        &nbsp; &nbsp;{{ ccidErrorMessage }}
      </span>
    </div>
    <form class="display-contents">
      <v-col
        class="width-130 padding-10"
      >
        <label class="ccid-list-title mb-1 d-block">
          Type
        </label>
        <div
          @click.stop
          class="mb-5"
          :class="{ 'has-error': containerError }"
        >
          <v-select
            ref="containerType"
            v-model="form.containerId"
            :items="containerTypeList"
            @change="CCIDContainerChange($event, consolidateData, 'container_id')"
            item-value="id"
            item-text="concat_desc"
            return-object
            outlined
            append-icon="fa-angle-down"
            :menu-props="{ offsetY: true }"
            background-color="white"
            placeholder=""
            :disabled="checkDisabled"
            @click:append="closeSelectDropdown('containerType')"
          />
        </div>
      </v-col>
      <v-col
        class="width-130 padding-10"
      >
        <label class="ccid-list-title mb-1 d-block">
          Size
        </label>
        <div
          @click.stop
          class="mb-5"
          :class="{ 'has-error': containerSizeError }"
        >
          <div class="select-dropdown-container consolidation-container-dropdown">
            <v-select
              ref="containerSize"
              v-model="form.containerSizeId"
              :items="sortedContainerSizeList"
              @change="CCIDContainerChange($event, consolidateData, 'container_size_id')"
              item-value="id"
              item-text="concat_desc"
              return-object
              outlined
              append-icon="fa-angle-down"
              :menu-props="{ offsetY: true, maxHeight: '345px' }"
              background-color="white"
              placeholder=""
              :disabled="checkDisabled"
              @click:append="closeSelectDropdown('containerSize')"
            />
          </div>
        </div>
      </v-col>
    </form>
    <v-col
      class="width-384 padding-10"
    >
      <label class="ccid-list-title mb-1 d-block">
        CCID Description
      </label>
      <p class="ccid-list-value">
        {{ consolidateData.approval.description }}
      </p>
    </v-col>
    <v-col
      class="width-125 padding-10 ccid-list-mark-div"
    >
      <template>
        <div
          class="ccid-delete-box"
        >
          <div
            @click.stop
          >
            <button
              class="duplicate-ccid-copy-btn"
              @click="createDuplicateCCID(consolidateData)"
            >
              Copy
            </button>
          </div>
          <div
            class="float-right margin-top-10"
            v-if="checkStopApprovalID()"
            @click.prevent="deleteCCIDPopup()"
          >
            <img
              class="height-16px"
              src="src/assets/images/minus-orange.svg"
              alt="minus"
            >
            <span class="ccid-delete-label">
              Delete
            </span>
          </div>
        </div>
      </template>
    </v-col>
    <delete-approval-confirmation-dialog
      :open-dialog="dialog"
      :ccid-object="ccidObject"
      @close="closeDialog()"
    />
  </v-row>
</template>
<script>
import { mapGetters } from 'vuex';
import VSelectArrow from '../../utils/v-select-arrow';
import DeleteApprovalConfirmationDialog from '../delete-approval/deleteApproval.vue';

export default {
  name: 'ConsolidationCard',
  components: {
    DeleteApprovalConfirmationDialog,
  },
  computed: {
    ...mapGetters({
      containerSizeList: 'consolidate/getContainerSizeList',
      containerTypeList: 'consolidate/getContainerTypeList',
      editableCCID: 'consolidate/getEditableCCID',
      stopDetail: 'getStopDetail',
    }),
    changeData() {
      const { checkBtnClick, isSelectedCcid } = this;
      return {
        checkBtnClick,
        isSelectedCcid,
      };
    },
    sortedContainerSizeList() {
      const order = ['DM05',
        'DM10',
        'DM20',
        'DM30',
        'DM55',
        'BOX',
        'CYB'];
      const filteredArray = this.containerSizeList
        .filter((size) => order.includes(size.container_size))
        .sort((a, b) => order.indexOf(a.container_size) - order.indexOf(b.container_size));
      const remainingArray = this.containerSizeList
        .filter((size) => !order.includes(size.container_size));
      return filteredArray.concat(remainingArray);
    },
    ccidObject() {
      return {
        popupTitle: 'title',
        ccid: this.consolidateData.ccid,
        approvalDescription: this.consolidateData.approval.description,
        consolidateDataid: this.consolidateData.id,
      };
    },
    checkDisabled() {
      let returnValue = false;
      if (this.consolidateData.container_id && this.consolidateData.container_size_id) {
        returnValue = true;
      }
      if (this.editableCCID.includes(this.consolidateData.id)) {
        returnValue = false;
      }
      if (this.stopDetail.id === this.consolidateData.stop_id) {
        returnValue = false;
      }
      if (this.stopDetail.is_completed) {
        returnValue = true;
      }
      return returnValue;
    },
  },
  data() {
    return {
      form: {
        containerId: '',
        containerSizeId: '',
      },
      ccidErrorMessage: '',
      errorCCIDCard: false,
      containerError: false,
      containerSizeError: false,
      errorCardClass: '',
      stopId: '',
      dialog: false,
    };
  },
  extends: VSelectArrow,
  beforeMount() {
    this.form.containerId = this.consolidateData.container_id;
    this.form.containerSizeId = this.consolidateData.container_size_id;
  },
  methods: {
    changeSelect() {
      this.$store.commit('consolidate/changeCCIDSelection', {
        container: this.consolidateData,
        stopApprovalId: this.stopApprovalId,
      });
      this.$forceUpdate();
    },
    getImgUrl(consolidateData) {
      let imgUrl = 'src/assets/images/tick-outline-grey.svg';
      if (consolidateData.is_full) {
        imgUrl = 'src/assets/images/trash.svg';
      } else if (consolidateData.is_selected) {
        imgUrl = 'src/assets/images/tick-circle-green.svg';
      }
      return imgUrl;
    },
    getSelectLabel(consolidateData) {
      let label = 'Select';
      if (consolidateData.is_selected) {
        label = 'Selected';
      }
      if (consolidateData.is_full) {
        label = 'Full';
      }
      return label;
    },
    getClass(consolidateData) {
      let className = '';
      if (consolidateData.is_selected) {
        className = 'ccid-select-active';
      }
      if (consolidateData.is_full) {
        className = 'ccid-is-full';
      }
      return className;
    },
    CCIDContainerChange(event, consolidateData, fieldName) {
      if (!consolidateData.is_create) {
        const params = {};
        params[fieldName] = +event.id;
        this.$store.dispatch('consolidate/CCIDContainerChangeAction', {
          consolidationContainerId: consolidateData.id,
          params,
        });
      }
    },
    checkStopApprovalID() {
      const stopApprovalId = this.$store.state.consolidate.consolidateApprovalId;
      return stopApprovalId === this.consolidateData.created_stop_approval_id;
    },
    checkContainerTypeAndSize() {
      let isEmpty = false;
      this.setToDefault();
      if (this.checkBtnClick && this.consolidateData.is_selected) {
        const container = this.checkNum(this.consolidateData.container_id);
        const containerSize = this.checkNum(this.consolidateData.container_size_id);
        isEmpty = container && containerSize;
        if (isEmpty) {
          this.assignEverythingIsEmpty();
        }
        if (!isEmpty && (container || containerSize)) {
          this.errorCCIDCard = true;
          const errorText = container ? 'type' : 'size';
          if (container) {
            this.containerError = true;
            this.errorCardClass = 'container-error';
          }
          if (containerSize) {
            this.containerSizeError = true;
            this.errorCardClass = 'container-size-error';
          }
          this.ccidErrorMessage = `Please enter container ${errorText}`;
        }
      }
    },
    checkNum(val) {
      return Number.isNaN(parseInt(val, 10));
    },
    setToDefault() {
      this.errorCCIDCard = false;
      this.containerError = false;
      this.containerSizeError = false;
      this.ccidErrorMessage = '';
      this.errorCardClass = '';
    },
    assignEverythingIsEmpty() {
      this.errorCCIDCard = true;
      this.containerError = true;
      this.containerSizeError = true;
      this.ccidErrorMessage = 'Please enter container type and size.';
      this.errorCardClass = 'ccid-card-error';
    },
    createDuplicateCCID(data) {
      const { stopApprovalId } = this;
      this.$store.dispatch('consolidate/createAndSaveDuplicateCCID', { containerId: data.id, stopApprovalId });
    },
    deleteCCIDPopup() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  props: {
    consolidateData: {
      type: Object,
      default: () => ({
        is_create: false,
        is_selected: false,
        ccid: '',
        container_size_id: '',
        container_id: '',
        approval: {
          description: '',
        },
      }),
    },
    consolidateIndex: {
      type: Number,
      default: -1,
    },
    stopApprovalId: {
      type: Number,
      default: null,
    },
    checkBtnClick: {
      type: Boolean,
      default: false,
    },
    isSelectedCcid: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    consolidateData(newVal) {
      this.checkContainerTypeAndSize();
      this.form.containerId = newVal.container_id;
      this.form.containerSizeId = newVal.container_size_id;
    },
    changeData() {
      this.checkContainerTypeAndSize();
    },
  },
};
</script>
