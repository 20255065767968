
function initialState() {
  const loadingStatus = {
    status: true,
    loadingName: '',
  };
  return {
    loadingStatus,
  };
}

const state = initialState();

const getters = {
  getLoadingStatus(stateControl) {
    return stateControl.loadingStatus;
  },
};
const mutations = {
  loadingRequest(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.loadingStatus = payload;
  },
};
const actions = {

};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
