import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function addTrip(params, techID, tripID) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}trips/add/${techID}/${tripID}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getAllTrips(techID) {
  const requestOptions = {
    method: 'GET',
  };

  const url = `${API_BASE_URL}trips/list/${techID}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getCompletedTrips(techID) {
  const requestOptions = {
    method: 'GET',
  };

  const url = `${API_BASE_URL}trips/completed_trips/${techID}`;
  return fetchService.getFetchData(url, requestOptions);
}

function updateTripStatus(params) {
  const requestOptions = {
    method: 'PUT',
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}trips/${params.tripId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function removeCompletedTrip(tripId) {
  const requestOptions = {
    method: 'DELETE',
  };
  return fetch(`${API_BASE_URL}trips/remove_trip/${tripId}`, requestOptions);
}

function validateTrip(tripId, techId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}trips/validate/${tripId}/${techId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function updateTrip(params, techID, tripID) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}trips/update/${techID}/${tripID}`;
  return fetchService.getFetchData(url, requestOptions);
}
function getActiveTrip(techId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}trips/active_trip/${techId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function syncTripFromUSE(params) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}trips/sync_trip`;
  return fetchService.getFetchData(url, requestOptions);
}
function tripSyncStatus(params) {
  const requestOptions = {
    method: 'POST',
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}trips/trip_sync_status`;
  return fetchService.getFetchData(url, requestOptions);
}
function getAllTripCCID(params) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}ccid/${params}/view_all`;
  return fetchService.getFetchData(url, requestOptions);
}

const tripService = {
  addTrip,
  getAllTrips,
  getActiveTrip,
  updateTripStatus,
  validateTrip,
  updateTrip,
  getCompletedTrips,
  removeCompletedTrip,
  syncTripFromUSE,
  tripSyncStatus,
  getAllTripCCID,
};
export default tripService;
