//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ConsolidationBuilder from '../../helpers/component/consolidation/consolidationBuilder.vue';

export default {
  name: 'Consolidate',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const obj = vm;
      obj.routePath = {
        from: from.path,
        to: to.path,
      };
    });
  },
  components: { ConsolidationBuilder },
  computed: {
    ...mapGetters({
      addedCCIDs: 'consolidate/getAddedCCIDs',
      addToTruckInventorySuccess: 'consolidate/getAddToTruckInventorySuccess',
      rxApprovalList: 'rxApprovalList/getRxApprovalList',
    }),
  },
  data() {
    return {
      consolidateApproval: {
        id: '',
        consolidateApprovalId: '',
        description: '',
      },
      showCCIDSuccess: false,
      showFadeIn: false,
      addToInventoryCompleted: false,
      routePath: {},
    };
  },
  beforeMount() {
    const consolidateDetail = this.$store.state.approvalList.consolidateApproval;
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'setConsolidateApproval' && consolidateDetail) {
        this.getConsolidatesCCIDList();
      }
    });
    this.getConsolidatesCCIDList();
  },
  methods: {
    gotoStopDetailWorkArea() {
      this.$router.push({ name: 'stopDetailWorkArea' });
      this.showCCIDSuccess = false;
    },
    getConsolidatesCCIDList() {
      const consolidateDetail = this.$store.state.approvalList.consolidateApproval;
      if (consolidateDetail && consolidateDetail.approval_id) {
        this.consolidateApproval = consolidateDetail.approval_detail;
        this.consolidateApproval.consolidateApprovalId = consolidateDetail.id;
        this.consolidateApproval.waste_type = consolidateDetail.waste_type;
        this.$store.dispatch('consolidate/getConsolidateLists', {
          approvalId: this.consolidateApproval.consolidateApprovalId,
        });
        this.$store.dispatch('consolidate/getContainerSizeListAction');
        this.$store.dispatch('consolidate/getContainerTypeListAction');
      } else {
        this.gotoStopDetailWorkArea();
      }
    },
  },
  props: {
    rxConsolidate: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    addToTruckInventorySuccess(newVal) {
      if (newVal) {
        this.showCCIDSuccess = true;
        this.showFadeIn = true;
      } else {
        this.showFadeIn = newVal;
        setTimeout(() => {
          if (this.rxConsolidate) {
            this.addToInventoryCompleted = true;
            this.$store.dispatch('rxApprovalList/getRxApprovalList', +this.$route.params.stopId);
          } else {
            this.gotoStopDetailWorkArea();
          }
        }, 200);
      }
    },
    rxApprovalList(newVal) {
      if (newVal.length > 0) {
        if (!this.$route.params.draftRxConsolidate && this.addToInventoryCompleted) {
          this.$store.dispatch('container/containerTypeListAction');
          this.$router.push({ name: 'Add Rx Items' });
          this.addToInventoryCompleted = false;
        }
        this.$route.params.draftRxConsolidate = false;
        this.showCCIDSuccess = false;
      } else if (!this.$route.params.draftRxConsolidate) {
        this.gotoStopDetailWorkArea();
      }
    },
  },
};
