//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import connectedStopConstant from '../../../constants/connected-stop';

export default {
  name: 'ExpectedPickupInventoryCard',
  computed: {
    ...mapGetters({ useCCIDStorage: 'getUseCCIDStorage', stopDetails: 'getStopDetail' }),
  },
  data() {
    return {
      approvalAdded: false,
    };
  },
  methods: {
    addApproval(approvalData) {
      this.$emit('addToInventory');
      const approval = approvalData;
      if (typeof approval.bill_units !== 'object') {
        approval.bill_units = JSON.parse(approval.bill_units);
      }
      this.$store.dispatch('assignApprovalAction', approval);
      this.$router.push({ name: 'Add Approval' });
    },

    editApproval(stopApprovalData) {
      this.$store.commit('setApprovalStatusType', true);
      const stopApproval = {};
      let id = null;
      let wasteType = null;
      if (this.useCCIDStorage) {
        id = stopApprovalData.stop_approval.id;
        wasteType = stopApprovalData.stop_approval.waste_type;
      } else {
        id = stopApprovalData.id;
        wasteType = stopApprovalData.waste_type;
      }
      Object.assign(stopApproval, { id, waste_type: wasteType });
      if (stopApproval.id && stopApproval.waste_type === 'retail') {
        this.$store.dispatch('editApproval', stopApproval).then(() => {
          this.$router.push({ name: 'Add Approval' });
          setTimeout(() => {
            this.$store.dispatch('container/setSelectedContainerTypeLists');
          }, 0);
        });
      } else {
        this.$store.dispatch('rxApproval/editRxApproval', { stop_approval: stopApproval });
        this.$store.commit('rxApprovalList/hideRxApprovalConsolidationList', true);
        this.$router.push({ name: 'Rx Approval List' });
      }
    },

    profileActiveStatus(status) {
      if (this.stopDetails.connected_status
        !== connectedStopConstant.status.ACCUMULATION_DATA_PRESENT) {
        const storedData = JSON.parse(localStorage.getItem('profileActiveStatus'));
        const profileObj = storedData
          .find((localData) => this.profile.manifest_profile_id
              === localData.manifestProfileId);
        Object.assign(profileObj, { profilePresentFlag: status });
        localStorage.setItem('profileActiveStatus', JSON.stringify(storedData));
      } else {
        const storedData = JSON.parse(localStorage.getItem('accumulatedProfileActiveStatus'));
        const profileObj = storedData
          .find((localData) => this.profile.manifest_profile_id
              === localData.manifestProfileId
              && this.container.container_id === localData.containerId);
        Object.assign(profileObj, { profilePresentFlag: status });
        localStorage.setItem('accumulatedProfileActiveStatus', JSON.stringify(storedData));
      }

      this.profile.profile_present_flag = status;
    },
  },
  props: {
    profile: {
      type: Object,
      default() {
        return { };
      },
    },
    container: {
      type: Object,
      default() {
        return { };
      },
    },
  },
};
