<template>
  <div class="container">
    <v-overlay
      :value="dialog || stateDialog"
      opacity="0.94"
      z-index="3"
    >
      <div class="logout-container">
        <div class="logout-content-box">
          <div class="logout-header">
            <label>Are you sure you want to cancel current Rx item?</label>
          </div>
          <div class="logout-confirmation-btn">
            <div>
              <button
                class="Cancel"
                @click.prevent="closeDialog('no')"
              >
                No
              </button>
            </div>
            <div>
              <button
                class="logout"
                @click.prevent="closeDialog('yes')"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CancelConfirmation',
  computed: {
    ...mapGetters({ stateDialog: 'rxApprovalList/getRxCancelConfirmationDialog' }),
  },
  data() {
    return {
      dialog: true,
    };
  },
  created() {
    this.dialog = this.rxCancelDialog;
  },
  methods: {
    closeDialog(type) {
      if (type === 'yes') {
        this.$store.dispatch('rxApproval/resetAllContentData');
        this.$store.commit('rxContent/enablePreviousMonthBtn', true);
        this.$store.commit('rxApproval/resetApprovalDetail');
      }
      this.$emit('close', type);
    },
  },
  props: {
    rxCancelDialog: Boolean,
  },
  watch: {
    rxCancelDialog(newVal) {
      this.dialog = newVal;
    },
  },
};
</script>
