import api from '../service/approval.service';

function defaultApprovalDetail() {
  return {
    id: '',
    approval_code: 'none',
    description: 'none',
    bill_units: [],
  };
}

function pListedWeightWarn(commit, state, payload) {
  let approval = state.approvalDetail;
  if (payload.approval) {
    approval = payload.approval;
  }
  const approvalId = approval.id;
  let stopApprovalId;
  if (payload.type === 'save' || payload.type === 'reconsolidate') {
    stopApprovalId = payload.id;
  }
  commit('consolidate/setOverWeightApproval', {
    weight: payload.weight, description: approval.description, stopApprovalId, approvalId,
  });
  commit('consolidate/setStopPListedWeightError', true);
}

function setDrawerDetails(approvalId, manifestId, commit) {
  if (approvalId) {
    api.getApproval(approvalId, manifestId).then((res) => {
      const approvalDetail = res.approval;
      approvalDetail.bill_units = JSON.parse(approvalDetail.bill_units);
      approvalDetail.waste_codes = JSON.parse(approvalDetail.waste_codes);
      if (approvalDetail.waste_codes) {
        approvalDetail.waste_codes = approvalDetail.waste_codes.waste_codes;
      }
      commit('setApprovalDrawerDetails', approvalDetail);
    });
  }
}

const stateObj = {
  approvalList: {},
  approvalDetail: defaultApprovalDetail(),
  manifestUnits: [],
  consolidateApproval: null,
  approvalStatusType: false,
  approvalDrawerDetails: {},
  nonCCIDApprovalAdded: false,
};
const getters = {
  getApprovalLists(context) {
    return context.approvalList;
  },
  getSelectedApproval(context) {
    return context.approvalDetail;
  },
  getConsolidateApproval(context) {
    return context.consolidateApproval;
  },
  getManiFestUnits(context) {
    return context.manifestUnits;
  },
  getApprovalDrawerDetails(context) {
    return context.approvalDrawerDetails;
  },
  getNonCCIDApprovalAdded(context) {
    return context.nonCCIDApprovalAdded;
  },
};
const mutations = {
  setApprovalList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.approvalList = payload;
  },
  setApprovalDetail(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.approvalDetail = payload;
  },
  resetApprovalDetail(stateControl) {
    const stateControld = stateControl;
    stateControld.approvalDetail = defaultApprovalDetail();
  },
  setConsolidateApproval(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.consolidateApproval = payload;
  },
  setManifestUnits(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.manifestUnits = payload;
  },
  resetConsolidateApproval(stateControl) {
    const stateControld = stateControl;
    stateControld.consolidateApproval = null;
  },
  increaseBillUnitQuantity(stateControl, payload) {
    const stateControld = stateControl;
    const qty = stateControld.approvalDetail.bill_units[payload].quantity;
    stateControld.approvalDetail.bill_units[payload].quantity = qty + 1;
  },
  decreaseBillUnitQuantity(stateControl, payload) {
    const stateControld = stateControl;
    const qty = stateControld.approvalDetail.bill_units[payload].quantity;
    stateControld.approvalDetail.bill_units[payload].quantity = qty - 1;
  },
  setBillUnitQuantity(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.approvalDetail.bill_units[payload.index].quantity = parseInt(payload.value, 10);
  },
  setApprovalStatusType(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.approvalStatusType = payload;
  },
  setApprovalDrawerDetails(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.approvalDrawerDetails = payload;
  },
  setNonCCIDApprovalAdded(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.nonCCIDApprovalAdded = payload;
  },
};
const actions = {
  setApprovalList(context, generatorId) {
    context.commit('rxApproval/resultEmpty', false);
    api.getApprovals(generatorId, '').then((res) => {
      context.commit('setApprovalList', res);
      if (res && res.approvals && res.approvals.length === 0) {
        context.commit('rxApproval/resultEmpty', true);
      }
    });
  },
  assignApprovalAction({
    commit, dispatch, state, rootState,
  }, payload) {
    const approval = payload;
    if (approval.id !== state.approvalDetail.id) {
      commit('container/resetContainerType');
      commit('resetScaleWeight');
      commit('resetScaleWeightUnit');
    }
    commit('setApprovalDetail', approval);
    if (approval.id) {
      const manifestId = rootState.approvalList.approvalDetail.manifest_id;
      setDrawerDetails(approval.id, manifestId, commit);
    }
    dispatch('container/containerTypeListAction');
  },
  searchApprovals(context, obj) {
    context.commit('rxApproval/resultEmpty', false);
    api.getApprovals(obj.id, obj.value).then((res) => {
      context.commit('setApprovalList', res);
      if (res && res.approvals.length === 0) {
        context.commit('rxApproval/setSearchValue', obj.value);
        context.commit('rxApproval/resultEmpty', true);
      }
    });
  },
  consolidateApproval({
    commit, dispatch, state, rootState,
  }, payloadData) {
    commit('consolidate/setOverWeightApproval', {});
    commit('consolidate/setStopPListedWeightError', false);
    const containers = [];
    const payload = payloadData;
    payload.containers.forEach((obj) => {
      const params = {};
      params.container_id = obj.type.id;
      params.quantity = obj.quantity;
      containers.push(params);
    });
    payload.weight = parseFloat(payload.weight);
    payload.containers = containers;

    return new Promise((resolve, reject) => {
      if (payload && payload.id) {
        api.updateConsolidate(payload.id, payload).then((res) => {
          if (res) {
            if (payload.type === 'save') {
              dispatch('getInventoryLists', payload.stopId);
            } else {
              res.stop_approval.approval_detail = payload.approval;
              commit('setConsolidateApproval', res.stop_approval);
            }
          }
          resolve(res);
        }, (err) => {
          if (err.match(/p listed weight is more than 2.2 lb/i)) {
            pListedWeightWarn(commit, state, payload);
          }
          reject(err);
        });
      } else {
        api.consolidateApprovals(payload).then((res) => {
          if (res) {
            res.stop_approval.approval_detail = state.approvalDetail;
            if (rootState.stopDetail.useCCIDStorage) {
              commit('setConsolidateApproval', res.stop_approval);
            } else {
              commit('setNonCCIDApprovalAdded', true);
            }
            dispatch('getStopDetailAction', { stopID: payload.stop_id, type: '' }, { root: true });
          }
          resolve(res);
        }, (err) => {
          if (err.match(/p listed weight is more than 2.2 lb/i)) {
            pListedWeightWarn(commit, state, payload);
          }
          reject(err);
        });
      }
    });
  },
  getManiFestUnits(context) {
    api.getManiFestUnits().then((response) => {
      if (response && response.measure_units) {
        context.commit('setManifestUnits', response.measure_units);
      }
    });
  },
  increaseBillUnitQuantity(context, index) {
    context.commit('increaseBillUnitQuantity', index);
  },
  decreaseBillUnitQuantity(context, index) {
    context.commit('decreaseBillUnitQuantity', index);
  },
  setBillUnitQuantity(context, payload) {
    context.commit('setBillUnitQuantity', payload);
  },
  getApprovalDetailForDrawer({ commit, rootState }, approvalId) {
    const manifestId = rootState.rxApproval.rxEditApproval.manifest_id;
    setDrawerDetails(approvalId, manifestId, commit);
  },
};
export default {
  state: stateObj,
  getters,
  actions,
  mutations,
};
