<template>
  <div>
    <div>
      <contentBuilder

        v-for="(content, index) in contentList"
        :key="index"
        :content-detail="content"
        :content-index="index"
        :first-index="index === 0"
        :last-index="index === contentList.length - 1"
        :approval-detail="checkRxApprovalData"
      />
    </div>
    <div
      :class="rxEmptyBottle ? '' : 'margin-top-40'"
      v-if="contentList.length < 12"
    >
      <button
        :disabled="previousMonthBtn"
        class="rx-items-add-previous-month-btn"
        @click="addNewContentDetails()"
      >
        <img
          :src="getPreviousMonthImageUrl()"
          alt="add-month"
        >
        <span class=""> Add a previous month </span>
      </button>
    </div>
    <div
      v-if="rxEditApproval && !rxEditApproval.id && !checkRxApprovalData.editType"
      class="margin-top-50 rx-items-float-right"
    >
      <button
        class="rx-items-cancel-entry-btn"
        @click="resetRxItem()"
      >
        <span class="back-btn-consolidate" /> <span class="margin-left-5"> Cancel entry</span>
      </button>
      <button
        class="rx-items-add-to-apporval-btn"
        @click="saveRxItem('create')"
        :disabled="addToApprovalListBtn"
      >
        Add to approval list
      </button>
    </div>
    <div
      v-else
      class="rx-save-btn"
    >
      <button
        @click="saveRxItem('update')"
        :class="{'rx-save-btn-disable': previousMonthBtn}"
        :disabled="previousMonthBtn"
      >
        Save
      </button>
    </div>
    <pListedWeightWarning
      @proceedToConsolidate="rxItemAddToApproval"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import contentBuilder from './rxContentBuilder.vue';
import pListedWeightWarning from '../pListedWeightWarning/index.vue';

export default {
  name: 'RxContentList',
  components: { contentBuilder, pListedWeightWarning },
  computed: {
    ...mapGetters({
      contentChanges: 'rxContent/getContentList',
      checkRxApprovalData: 'rxApproval/getRxApprovalDetail',
      rxEditApproval: 'rxApproval/getRxEditApproval',
      previousMonthButton: 'rxContent/getPreviousMonthButtonStatus',
      rxEmptyBottle: 'rxApproval/getRxEmptyBottle',
    }),
  },
  data: () => ({
    contentList: [],
    addToApprovalListBtn: true,
    previousMonthBtn: true,
  }),
  created() {
    this.$scaleSocketsConnect();
    this.contentList = this.$store.state.rxContent.contentList;
    if (!this.$store.state.rxContent.previousMonthButton) {
      this.previousMonthBtn = this.$store.state.rxContent.previousMonthButton;
    }
  },
  beforeDestroy() {
    this.$scaleSocketsDisconnect();
  },
  methods: {
    addNewContentDetails() {
      if (this.contentList.length < 12) {
        this.$store.dispatch('rxContent/addAnotherContentAction');
        this.$store.commit('rxContent/setContentValue', {
          index: this.contentList.length - 1,
          field: 'residualWeight',
          value: this.contentList[0].residualWeight,
        }, { root: true });
        if (this.rxEmptyBottle) {
          this.$store.commit('rxContent/setContentValue', {
            index: this.contentList.length - 1,
            field: 'billUnits',
            value: this.$store.state.rxContent.billUnitList,
          }, { root: true });
        }
      }
    },
    saveRxItem(type, validate = false) {
      const rxApprovalData = this.$store.state.rxApproval.rxApprovalDetail;
      rxApprovalData.stopId = this.$store.state.stopDetail.stopDetail.id;
      rxApprovalData.contentList = this.contentList;
      rxApprovalData.rxEmptyBottle = this.rxEmptyBottle;
      rxApprovalData.allow_consolidate = validate;
      if (rxApprovalData.id && type === 'create') {
        this.$store.dispatch('rxApprovalList/setRxApprovalDataAction', rxApprovalData);
      } else {
        this.$store.dispatch('rxApprovalList/updateRxApprovalDataAction', rxApprovalData).then(() => {
          this.$router.push({ name: 'Rx Approval List' });
        });
      }
    },
    resetRxItem() {
      this.$store.commit('resetConsolidateApproval');
      this.$router.push({ name: 'stopDetailWorkArea' });
    },
    getPreviousMonthImageUrl() {
      let imageUrl = 'src/assets/images/plus-circle-icon.svg';
      if (this.previousMonthBtn) {
        imageUrl = 'src/assets/images/plus-circle-grey-icon.svg';
      }
      return imageUrl;
    },
    rxItemAddToApproval() {
      if (this.rxEditApproval && !this.rxEditApproval.id && !this.checkRxApprovalData.editType) {
        this.saveRxItem('create', true);
      } else {
        this.saveRxItem('update', true);
      }
    },

  },
  watch: {
    contentChanges(newVal) {
      this.contentList = newVal;
    },
    previousMonthButton(newVal) {
      this.addToApprovalListBtn = newVal;
      this.previousMonthBtn = newVal;
    },
  },
};
</script>
