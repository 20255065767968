import store from '../store/index';

function captureKey(e) {
  // keyCode 13 is Enter key
  if (e.keyCode === 13) {
    // NOTE: I am not fond on how this condition is coupled to the
    // div class name, but I didn't want to overwrite too much logic.
    // Maybe we re-adress this after, unless there is a better approach now?
    if (e.srcElement !== undefined && e.srcElement.className === 'small-diagnostic-button') {
      store.dispatch('masterScan/setTestUpc');
    } else {
      store.dispatch('masterScan/upcScanComplete');
    }
  } else {
    store.dispatch('masterScan/addCharacterToScannedUpc', String.fromCharCode(e.keyCode));
  }
}

// Previously we would ignore keyboard input to prevent the trailing enter key from affecting
// the ui if a tech scanned a barcode while not on the barcode scanning screen.
// Techs now need to use USB 10-key keyboards so for now we are disabling this feature
// to allow them to work
//
// function ignoreKeyboard(e) {
//   e.stopPropagation();
//   e.preventDefault();
// }

function startMonitoringScanGunInput() {
  if (!store.state.masterScan.isMonitoringScanGunInput) {
    store.dispatch('masterScan/resetUpc');
    store.dispatch('masterScan/startMonitoringScanGunInput');
    window.addEventListener('keypress', this.captureKey);
    // window.removeEventListener('keypress', this.ignoreKeyboard);
  }
}

function stopMonitoringScanGunInput() {
  store.dispatch('masterScan/resetUpc');
  store.dispatch('masterScan/stopMonitoringScanGunInput');
  window.removeEventListener('keypress', this.captureKey);
  // window.addEventListener('keypress', this.ignoreKeyboard);
}

const barcodeScannerService = {
  startMonitoringScanGunInput,
  stopMonitoringScanGunInput,
  captureKey,
  // ignoreKeyboard,
};
export default barcodeScannerService;
