//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import RxItemListCard from '../rx-item-list-card/index.vue';
import SearchLoader from '../../../helpers/component/search-loader/search-loader.vue';

export default {
  name: 'RxItemLists',
  components: {
    RxItemListCard,
    SearchLoader,
  },
  computed: {
    ...mapGetters({ getRxLists: 'rxApproval/getRxLists' }),
  },
  methods: {
    gotoAddRxItem() {
      this.$router.push({ name: 'Add Rx Items' });
    },
    add(item) {
      this.$store.dispatch('rxApproval/fetchApprovalDetail', item).then(() => {
        this.$router.push({ name: 'Add Rx Items' });
      });
    },
  },
};
