import stopService from '../service/stops.service';
import documentConstants from '../constants/document';
import toastMessage from './toast-message';

const state = {
  hazManifestsTableData: [],
  hazManifestsTableHeaders: {},
  nonHazManifestsTableData: [],
  nonHazManifestsTableHeaders: {},
  manifestsContinuationTableData: [],
  manifestsContinuationTableHeaders: {},
  additionalDocumentsTableData: [],
  additionalDocumentsTableHeaders: {},
  documentTypesPrintingState: null,
  currentSDSUrl: '',
  isUniqueNumber: {},
  documentReprintStatus: false,
};

const documentTypes = documentConstants.types;

const getters = {
  getHazManifestsTableData(stateControl) {
    return stateControl.hazManifestsTableData;
  },
  getCurrentSDSUrl(stateControl) {
    return stateControl.currentSDSUrl;
  },
  getHazManifestsTableHeaders(stateControl) {
    return stateControl.hazManifestsTableHeaders;
  },
  getNonHazManifestsTableData(stateControl) {
    return stateControl.nonHazManifestsTableData;
  },
  getNonHazManifestsTableHeaders(stateControl) {
    return stateControl.nonHazManifestsTableHeaders;
  },
  getManifestsContinuationTableData(stateControl) {
    return stateControl.manifestsContinuationTableData;
  },
  getManifestsContinuationTableHeaders(stateControl) {
    return stateControl.manifestsContinuationTableHeaders;
  },
  getAdditionalDocumentsTableData(stateControl) {
    return stateControl.additionalDocumentsTableData;
  },
  getAdditionalDocumentsTableHeaders(stateControl) {
    return stateControl.additionalDocumentsTableHeaders;
  },
  getDocumentTypesPrintingState(stateControl) {
    return stateControl.documentTypesPrintingState;
  },
  getIsUniqueNumber(stateControl) {
    return stateControl.isUniqueNumber;
  },
  getDocumentReprintStatus(stateControl) {
    return stateControl.documentReprintStatus;
  },
};

const mutations = {
  addHazManifestsTableData(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.hazManifestsTableData.push(payload);
  },
  setCurrentSDSUrl(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.currentSDSUrl = payload;
  },
  setHazManifestsTableHeaders(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.hazManifestsTableHeaders = payload.tableHeaders;
  },
  addNonHazManifestsTableData(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.nonHazManifestsTableData.push(payload);
  },
  setNonHazManifestsTableHeaders(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.nonHazManifestsTableHeaders = payload.tableHeaders;
  },
  addManifestsContinuationTableData(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.manifestsContinuationTableData.push(payload);
  },
  setManifestsContinuationTableHeaders(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.manifestsContinuationTableHeaders = payload.tableHeaders;
  },
  addAdditionalDocumentsTableData(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.additionalDocumentsTableData.push(payload);
  },
  setAdditionalDocumentsTableHeaders(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.additionalDocumentsTableHeaders = payload.tableHeaders;
  },
  updateItemSelectionStatus(stateControl, payload) {
    const stateControld = stateControl;
    // Documents are a combination of entries from the Manifest table as well
    // as other documents not represented in the backend
    let switchedDocument = stateControld.hazManifestsTableData.find((item) => {
      const typeMatches = item.type === payload.type;
      const uniqueNameMatches = item.cloud_id === payload.cloud_id;
      return typeMatches && uniqueNameMatches;
    });
    if (!switchedDocument) {
      switchedDocument = stateControld.nonHazManifestsTableData.find((item) => {
        const typeMatches = item.type === payload.type;
        const uniqueNameMatches = item.cloud_id === payload.cloud_id;
        return typeMatches && uniqueNameMatches;
      });
    }
    if (!switchedDocument) {
      switchedDocument = stateControld.manifestsContinuationTableData.find(
        (item) => item.type === payload.type && item.cloud_id === payload.cloud_id,
      );
    }
    if (!switchedDocument) {
      switchedDocument = stateControld.additionalDocumentsTableData.find(
        (item) => item.type === payload.type && item.cloud_id === payload.cloud_id,
      );
    }
    switchedDocument.selected = !switchedDocument.selected;
  },
  switchAllHazManifestsDocumentsSelection(stateControl, isSelected) {
    const stateControld = stateControl;
    stateControld.hazManifestsTableData.forEach((document) => {
      const documentd = document;
      documentd.selected = isSelected;
      Object.assign(document, documentd);
    });
  },
  switchAllNonHazManifestsDocumentsSelection(stateControl, isSelected) {
    const stateControld = stateControl;
    stateControld.nonHazManifestsTableData.forEach((document) => {
      const documentd = document;
      documentd.selected = isSelected;
      Object.assign(document, documentd);
    });
  },
  switchAllManifestsContinuationPagesSelection(stateControl, isSelected) {
    const stateControld = stateControl;
    stateControld.manifestsContinuationTableData.forEach((document) => {
      const documentd = document;
      documentd.selected = isSelected;
      Object.assign(document, documentd);
    });
  },
  switchAllAdditionalDocumentsSelection(stateControl, isSelected) {
    const stateControld = stateControl;
    stateControld.additionalDocumentsTableData.forEach((document) => {
      const documentd = document;
      documentd.selected = isSelected;
      Object.assign(document, documentd);
    });
  },
  setDocumentTypesPrintingState(stateControl, printingDocumentTypeState) {
    const stateControld = stateControl;
    stateControld.documentTypesPrintingState = printingDocumentTypeState;
  },
  updateManifestDocument(stateControl, document) {
    const stateControld = stateControl;
    const hazData = stateControld.hazManifestsTableData;
    let documentd = hazData.find((item) => item.cloud_id === document.cloud_id);
    if (!documentd) {
      const nonHazData = stateControld.nonHazManifestsTableData;
      documentd = nonHazData.find(
        (item) => item.cloud_id === document.cloud_id,
      );
    }
    if (documentd) {
      Object.assign(documentd, document);
    }
  },
  updateManifestContinuationDocument(stateControl, document) {
    const stateControld = stateControl;
    Object.assign(stateControld.manifestsContinuationTableData, document);
  },
  updateAdditionalDocument(stateControl, document) {
    const stateControld = stateControl;
    Object.assign(stateControld.additionalDocumentsTableData, document);
  },
  setIsUniqueNumber(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.isUniqueNumber = payload;
  },
  resetTableData(stateControl) {
    const stateControld = stateControl;
    stateControld.hazManifestsTableData = [];
    stateControld.nonHazManifestsTableData = [];
    stateControld.manifestsContinuationTableData = [];
    stateControld.additionalDocumentsTableData = [];
  },
  updateDocumentReprintStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.documentReprintStatus = payload;
  },
  setAdditionalDocumentPrintData(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.additionalDocumentPrintData = payload;
  },
};

const actions = {
  setDocumentsData({ commit }, stopID) {
    commit('resetTableData');
    let apiResults = [];
    stopService.getPrintDocuments(stopID).then((res) => {
      apiResults = res;
      apiResults.forEach((document) => {
        const documentWithSelection = {
          selected: false,
        };
        Object.assign(document, documentWithSelection);
        switch (document.type) {
          case documentTypes.HAZ_MANIFEST: {
            commit('addHazManifestsTableData', document);
            break;
          }
          case documentTypes.NON_HAZ_MANIFEST: {
            commit('addNonHazManifestsTableData', document);
            break;
          }
          case documentTypes.HAZ_CONTINUATION: {
            commit('addManifestsContinuationTableData', document);
            break;
          }
          case documentTypes.NON_HAZ_CONTINUATION: {
            commit('addManifestsContinuationTableData', document);
            break;
          }
          default: {
            commit('addAdditionalDocumentsTableData', document);
            break;
          }
        }
      });
    });
  },
  setTableHeaders({ commit }) {
    const payload = {
      tableHeaders: {
        description: 'Description',
        name: 'Report group',
        tsdf: 'TSDF',
      },
    };
    commit('setHazManifestsTableHeaders', payload);
    commit('setNonHazManifestsTableHeaders', payload);
    commit('setManifestsContinuationTableHeaders', payload);
    commit('setAdditionalDocumentsTableHeaders', payload);
  },
  updateDocumentSelectionStatus({ commit }, documentId) {
    commit('updateItemSelectionStatus', documentId);
  },
  setCurrentSDSUrl({ commit }, url) {
    commit('setCurrentSDSUrl', url);
  },
  setCurrentSDSApiFail({ commit }) {
    toastMessage.showToastErrorMessage(
      commit,
      'There was a problem generating the PDF',
      'error',
    );
  },
  updateDocumentsSelectionStatus({ commit }, payload) {
    switch (payload.documentType) {
      case documentTypes.HAZ_MANIFEST: {
        commit('switchAllHazManifestsDocumentsSelection', payload.status);
        break;
      }
      case documentTypes.NON_HAZ_MANIFEST: {
        commit('switchAllNonHazManifestsDocumentsSelection', payload.status);
        break;
      }
      case documentTypes.HAZ_CONTINUATION: {
        commit('switchAllManifestsContinuationPagesSelection', payload.status);
        break;
      }
      case documentTypes.NON_HAZ_CONTINUATION: {
        commit('switchAllManifestsContinuationPagesSelection', payload.status);
        break;
      }
      default: {
        commit('switchAllAdditionalDocumentsSelection', payload.status);
        break;
      }
    }
  },
  updateAllDocumentsSelectionStatus({ commit }, selectedStatus) {
    commit('switchAllHazManifestsDocumentsSelection', selectedStatus);
    commit('switchAllNonHazManifestsDocumentsSelection', selectedStatus);
    commit('switchAllManifestsContinuationPagesSelection', selectedStatus);
    commit('switchAllAdditionalDocumentsSelection', selectedStatus);
  },
  updateDocumentTypesPrintingState({ commit }, printingState) {
    commit('setDocumentTypesPrintingState', printingState);
  },
  updateManifestDocument({ commit, dispatch }, update) {
    const payload = {
      original_name: update.doc.original_name,
      name: update.doc.name,
    };
    stopService.updateManifestName(payload, update.stopId).then(() => {
      commit('updateManifestDocument', update.doc);
      dispatch('stopLabels/updateLabelReprintStatus', update.stopId, { root: true });
    });
  },
  updateManifestContinuationDocument({ commit }, document) {
    commit('updateManifestContinuationDocument', document);
  },
  updateLandDisposalDocument({ commit }, document) {
    commit('updateAdditionalDocument', document);
  },
  checkUniqueManifestNumber({ commit }, params) {
    const { stopId, manifestNumber, printableDocuments } = params;
    const { cloud_id: cloudId } = printableDocuments[0];
    return stopService.checkUniqueManifestNumber({ stopId, manifestNumber, cloudId })
      .then((response) => {
        commit('setIsUniqueNumber', { manifestNumber: params.manifestNumber, isUnique: response });
      });
  },
  getDocumentReprintStatus({ commit }, stopId) {
    stopService.getDocumentReprintStatus(stopId).then((res) => {
      commit('updateDocumentReprintStatus', res);
    });
  },
  updatePrintedDocumentStatus({ commit, state: stateObj }, params) {
    switch (params.printedDoc.type) {
      case documentTypes.HAZ_MANIFEST: {
        const document = stateObj.hazManifestsTableData
          .find((doc) => doc.cloud_id === params.printedDoc.cloud_id);
        document.need_reprinting = false;
        commit('updateManifestDocument', document);
        break;
      }
      case documentTypes.NON_HAZ_MANIFEST: {
        const document = stateObj.nonHazManifestsTableData
          .find((doc) => doc.cloud_id === params.printedDoc.cloud_id);
        document.need_reprinting = false;
        commit('updateManifestDocument', document);
        break;
      }
      case documentTypes.HAZ_CONTINUATION:
      case documentTypes.NON_HAZ_CONTINUATION: {
        const document = stateObj.manifestsContinuationTableData
          .find((doc) => doc.cloud_id === params.printedDoc.cloud_id);
        document.need_reprinting = false;
        commit('updateManifestContinuationDocument', document);
        break;
      }
      case documentTypes.LAND_DISPOSAL:
      case documentTypes.PICKUP_REPORT: {
        const document = stateObj.additionalDocumentsTableData
          .find((doc) => doc.cloud_id === params.printedDoc.cloud_id);
        document.need_reprinting = false;
        commit('updateAdditionalDocument', document);
        break;
      }
      default: {
        break;
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
