//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import containerList from '../../../../helpers/component/container/containerList.vue';
import KeyboardDialog from '../../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import DeleteApprovalConfirmationDialog from '../../../../helpers/component/delete-approval/deleteApproval.vue';
import billUnitList from '../../../../helpers/component/billUnits/billUnitList.vue';
import VSelectArrow from '../../../../helpers/utils/v-select-arrow';
import pListedWeightWarning from '../../../../helpers/component/pListedWeightWarning/index.vue';
import ApprovalDetailDrawer from './ApprovalDetailDrawer.vue';

export default {
  name: 'AddApproval',
  components: {
    containerList,
    billUnitList,
    KeyboardDialog,
    DeleteApprovalConfirmationDialog,
    pListedWeightWarning,
    ApprovalDetailDrawer,
  },
  computed: {
    ...mapGetters({
      selectedApproval: 'getSelectedApproval',
      weightData: 'getWeightData',
      containerList: 'container/getContainerList',
      getStopDetail: 'getStopDetail',
      editApproval: 'getSelectedInventoryApproval',
      manifestUnitsList: 'getManiFestUnits',
      consolidateApproval: 'getConsolidateApproval',
      weightUnitData: 'getWeightUnit',
      consolidationMessage: 'consolidate/getConsolidationSuccessMessage',
      approvalDrawerDetails: 'getApprovalDrawerDetails',
      useCCIDStorage: 'getUseCCIDStorage',
    }),
    hideEditApproval() {
      return this.editApproval && !this.editApproval.approval;
    },
    showEditApproval() {
      return this.editApproval && this.editApproval.approval;
    },
    editApprovalDescription() {
      return !(
        this.editApproval && this.editApproval.approval.description !== 'none'
      );
    },
    selectedApprovalDescription() {
      return !(
        this.selectedApproval && this.selectedApproval.description !== 'none'
      );
    },
    editApprovalCode() {
      return !(
        this.editApproval && this.editApproval.approval.approval_code !== 'none'
      );
    },
    selectedApprovalCode() {
      return !(
        this.selectedApproval && this.selectedApproval.approval_code !== 'none'
      );
    },
    billUnitsComplete() {
      const billUnits = this.$store.state.approvalList.approvalDetail.bill_units.filter(
        (bu) => bu.unit !== 'LBS',
      );
      return billUnits.some((bu) => bu.quantity > 0);
    },
    consolidateBtnDisabled() {
      return (
        !this.containerList[0].type.id
        || (this.type === 'manual'
          && (!this.weight || !this.weightUnit || this.weight <= 0))
        || (this.hasCustomerSpecificBilling && !this.billUnitsComplete)
      );
    },
    hasCustomerSpecificBilling() {
      if (!this.selectedApproval && !this.editApproval) {
        return false;
      }
      if (this.showEditApproval) {
        return (
          this.filterOutLbsBillUnit(this.editApproval.bill_units).length > 0
        );
      }
      return (
        this.filterOutLbsBillUnit(this.selectedApproval.bill_units).length > 0
      );
    },
    hasDotSpecialPermit() {
      let res = '';
      if (this.showEditApproval) {
        res = this.editApproval.approval.dot_sp_number;
      } else {
        res = this.selectedApproval.id && this.selectedApproval.dot_sp_number;
      }
      return res;
    },
  },
  data: () => ({
    type: 'manual',
    weight: '',
    weightUnit: '',
    manualWeightUnit: '4',
    dotSpPermit: '',
    keyBoardType: '',
    modelName: '',
    labelName: '',
    modelValue: '',
    pinDialogIsOpen: false,
    saveBtnDisable: true,
    dialog: false,
    pListConsolidateType: '',
    totalQuantity: 0,
    drawer: false,
  }),
  extends: VSelectArrow,
  created() {
    this.getManiFestUnits();
    this.setDefaultWeightUnit();
    this.weight = this.weightData;
    this.weightUnit = this.weightUnitData;
    this.saveBtnDisabled(this.editApproval.weight);
    this.setDotSpPermit();
    if (this.editApproval.approval) {
      const approvalDetail = this.editApproval.approval;
      approvalDetail.bill_units = this.convertStringToJson(approvalDetail.bill_units);
      this.$store.commit('setApprovalDrawerDetails', approvalDetail);
    }
    if (!this.useCCIDStorage) {
      this.$store.commit('setNonCCIDApprovalAdded', false);
    }
  },
  destroyed() {
    this.$store.commit('setApprovalDrawerDetails', {});
  },
  methods: {
    convertStringToJson(str) {
      try {
        return JSON.parse(str);
      } catch (e) {
        return str;
      }
    },
    setDotSpPermit() {
      if (this.selectedApproval.id && this.selectedApproval.dot_sp_number) {
        this.dotSpPermit = 'T';
      } else {
        this.dotSpPermit = 'F';
      }
    },
    cancelApproval() {
      this.clearApprovalAndRedirect(false);
    },
    goToLists() {
      this.$router.push({ name: 'Approval List' });
    },
    filterOutLbsBillUnit(billUnits) {
      return billUnits.filter(
        (bu) => bu.unit !== 'LBS' && bu.billing_flag === 'T',
      );
    },
    showScaleType(val) {
      this.type = val;
      if (val === 'floor' || val === 'bench') {
        this.manualWeightUnit = this.weightUnit;
        if (this.showEditApproval) {
          this.manualWeightUnit = this.editApproval.weight_unit;
        }
        this.setDefaultWeightUnit();
      } else {
        this.weightUnit = this.manualWeightUnit;
        if (this.showEditApproval) {
          this.editApproval.weight_unit = this.manualWeightUnit;
        }
      }
    },
    tareScale() {
      this.$store.dispatch('weight/setTareWeight');
    },
    consolidate(validate = false) {
      const params = {};
      if (this.type === 'manual') {
        params.weight = this.weight;
        params.weight_unit = this.weightUnit;
      } else {
        params.weight = this.$store.getters['weight/getWeight'];
        params.weight_unit = 4;
      }
      params.stop_id = this.getStopDetail.id;
      params.approval_id = this.selectedApproval.id;
      params.waste_type = 'retail';
      params.dot_sp_flag = this.dotSpPermit;
      params.manifest_id = this.selectedApproval.manifest_id;
      switch (this.type) {
        case 'floor':
          params.scale_type = 2;
          break;
        case 'bench':
          params.scale_type = 3;
          break;
        case 'manual':
          params.scale_type = 1;
          break;
        default:
      }
      params.containers = this.containerList;
      params.bill_units = this.$store.state.approvalList.approvalDetail.bill_units;
      params.allow_consolidate = validate;
      this.$store.commit('consolidate/resetEditableCCID');
      this.$store.dispatch('consolidateApproval', params).then((stopApprovalData) => {
        if (!this.useCCIDStorage) {
          this.$router.push({ name: 'stopDetailWorkArea' });
          this.resetApprovalBuilder();
          this.$store.commit('setApprovalAddedSuccessfully', true);
          this.$store.dispatch('updateAccumulationData', { id: stopApprovalData.stop_approval.id, waste_type: stopApprovalData.stop_approval.waste_type, approval_id: stopApprovalData.stop_approval.approval.id });
        }
      });
    },
    handleSavingByConsolidationType(type, validate = false) {
      this.pListConsolidateType = type;
      const params = this.formatParamsObject(type);
      params.allow_consolidate = validate;
      this.$store.dispatch('consolidateApproval', params).then(() => {
        if (type === 'save') {
          this.$store.commit('setApprovalDetail', {});
          this.$router.push({ name: 'stopDetailWorkArea' });
          this.$store.commit('consolidate/resetEditableCCID');
          this.resetApprovalBuilder();
        }
      });
    },
    getManiFestUnits() {
      this.$store.dispatch('getManiFestUnits');
    },
    clearApprovalAndRedirect(pageName) {
      if (pageName) {
        this.$router.push({ name: 'Consolidate' });
      } else {
        this.$router.push({ name: 'stopDetailWorkArea' });
      }
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      const weight = value.input;
      if (value.modelName === 'editApproval.weight') {
        this.editApproval.weight = weight;
        this.saveBtnDisabled(weight);
      } else {
        this.weight = weight;
      }
    },
    setDefaultWeightUnit() {
      if (this.editApproval && this.editApproval.id) {
        this.editApproval.weight_unit = parseInt(
          this.editApproval.weight_unit,
          10,
        );
        this.weightUnit = this.editApproval.weight_unit;
      } else {
        this.weightUnit = 4;
      }
    },
    rxEditApprovalSequenceCCIDs(index) {
      return index === this.editApproval.stop_approval_ccids.length - 1
        ? ''
        : ', ';
    },
    formatParamsObject(type) {
      const params = {};
      if (this.type === 'manual') {
        params.weight = this.editApproval.weight;
        params.weight_unit = this.editApproval.weight_unit;
      } else {
        params.weight = this.weightData;
        params.weight_unit = 4;
      }
      params.dot_sp_flag = this.editApproval.dot_sp_flag;
      params.stop_id = this.editApproval.stop_id;
      params.approval_id = this.editApproval.approval_id;
      params.bill_units = this.$store.state.inventoryApproval.selectedInventoryApproval.bill_units;
      switch (this.type) {
        case 'floor':
          params.scale_type = 2;
          break;
        case 'bench':
          params.scale_type = 3;
          break;
        case 'manual':
          params.scale_type = 1;
          break;
        default:
      }
      params.containers = this.containerList;
      params.waste_type = 'retail';
      params.id = this.editApproval.id;
      params.approval = this.editApproval.approval;
      params.approval.consolidateApprovalId = this.editApproval.id;
      params.type = type;
      params.stopId = this.$route.params.stopId;
      return params;
    },
    saveBtnDisabled(weight) {
      this.saveBtnDisable = false;
      const editApprovalWeight = +weight;
      if (editApprovalWeight <= 0) {
        this.saveBtnDisable = true;
      }
    },
    resetApprovalBuilder() {
      this.$store.commit('resetApprovalDetail');
      this.$store.commit('container/resetContainerType');
      this.$store.commit('resetScaleWeight');
      this.$store.commit('resetScaleWeightUnit');
      this.$store.commit('setSelectedInventoryApproval', {});
    },
    deleteApproval() {
      this.$store.commit('consolidate/setApprovalDelete', {
        description: this.editApproval.approval.description,
        type: 'retail',
        stopApprovalId: this.editApproval.id,
        ccids: this.editApproval.stop_approval_ccids,
        stopRxItemId: '',
        deleteCCIDS: this.editApproval.last_approval_ccid_containers,
        stopId: this.editApproval.stop_id,
        approvalId: this.editApproval.approval_id,
      });
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    retailApprovalConsolidate(consolidateType) {
      if (this.editApproval && !this.editApproval.approval) {
        this.consolidate(true);
      } else if (consolidateType) {
        this.handleSavingByConsolidationType(consolidateType, true);
      }
    },
    closeApprovalDrawer() {
      this.drawer = false;
    },
  },
  watch: {
    editApproval(newVal) {
      if (newVal) {
        switch (newVal.scale_type) {
          case '2':
            this.type = 'floor';
            break;
          case '3':
            this.type = 'bench';
            break;
          case '1':
            this.type = 'manual';
            break;
          default:
        }
      }
    },
    clearApprovalAndRedirect(pageName) {
      if (pageName) {
        this.$router.push({ name: 'Consolidate' });
      } else {
        this.$router.push({ name: 'stopDetailWorkArea' });
      }
    },
    consolidateApproval(newVal) {
      if (newVal.id) {
        this.clearApprovalAndRedirect(true);
      }
    },
    weight(newVal) {
      this.$store.commit('setScaleWeight', +newVal);
    },
    weightUnit(newVal) {
      this.$store.commit('setScaleWeightUnit', +newVal);
    },
    consolidationMessage() {
      if (this.dialog) {
        this.dialog = false;
        this.$store.commit('setSelectedInventoryApproval', {});
        this.$router.push({ name: 'stopDetailWorkArea' });
      }
    },
  },
};
