//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import convertTextToTitleCase from '../../utils/convert-text-to-titlecase';

export default {
  name: 'AccumulationPickupItem',
  computed: {
    containers() {
      return this.accumulationData.containers.map((container) => convertTextToTitleCase(container)).join(',\n');
    },
    percentageOfStopWeight() {
      return ((this.accumulationData.weight / this.expectedWeight) * 100).toFixed(2);
    },
  },
  props: {
    accumulationData: {
      type: Object,
      default() {
        return { };
      },
    },
    expectedWeight: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
};
