//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'CancelConfirmation',
  computed: {
    ...mapGetters({ stateDialog: 'rxApprovalList/getRxCancelConfirmationDialog' }),
  },
  data() {
    return {
      dialog: true,
    };
  },
  created() {
    this.dialog = this.rxCancelDialog;
  },
  methods: {
    closeDialog(type) {
      if (type === 'yes') {
        this.$store.dispatch('rxApproval/resetAllContentData');
        this.$store.commit('rxContent/enablePreviousMonthBtn', true);
        this.$store.commit('rxApproval/resetApprovalDetail');
      }
      this.$emit('close', type);
    },
  },
  props: {
    rxCancelDialog: Boolean,
  },
  watch: {
    rxCancelDialog(newVal) {
      this.dialog = newVal;
    },
  },
};
