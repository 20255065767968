<template>
  <div class="main-header">
    <UnAuthHeader />
    <div
      class="bg-image"
    >
      <div class="d-flex justify-end auth-header-bg-image">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import UnAuthHeader from './header.vue';

export default {
  name: 'EmptyContainerLayout',
  components: {
    UnAuthHeader,
  },
};
</script>
