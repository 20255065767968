import firestoreDB from './firestoreDB.service';

function getAllStops(commit, tripDocId) {
  firestoreDB.db.collection('stops')
    .where('trip_id', '==', tripDocId).onSnapshot((result) => {
      const docs = firestoreDB.queryToArray(result);
      if (docs.length <= 0) {
        commit('stopListError', 'Failed to fetch');
      } else {
        commit('setStopListsData', docs);
      }
    });
}

async function updateStopStatus(params) {
  const { stopId, userId } = params;
  const stopRef = firestoreDB.db.collection('stops').doc(stopId);
  const userRef = firestoreDB.db.collection('users').doc(userId);
  stopRef.update({ start_time: new Date(), is_active: true, synced: false });
  userRef.update({ active_stop_id: stopId, synced: false });
}

async function fetchActiveStopDetail(context, stopId) {
  const stopRef = firestoreDB.db.collection('stops').doc(stopId);
  let stopData = await stopRef.get();
  stopData = stopData.data();
  if (stopData) {
    const tripRef = firestoreDB.db.collection('trips').doc(String(stopData.trip_id));
    let tripData = await tripRef.get();
    tripData = tripData.data();
    context.commit('setMobileActiveStopDetails', {
      stopName: stopData.generator.name,
      stopNumber: stopData.stop_number,
      tripCode: tripData.trip_code,
    });
  }
}

const stopService = {
  getAllStops,
  updateStopStatus,
  fetchActiveStopDetail,
};

export default stopService;
