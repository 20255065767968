//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import alertsService from '../../../service/alerts.service';

export default {
  name: 'LogoutConfirmation',
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('authentication/logoutAction');
      this.$store.commit('attachment/resetPrintedDocumentList');
      this.$store.commit('attachment/resetPrintedDocumentAndAttachmentMap');
      this.$store.commit('stopLabels/updateLabelReprintStatus', false);
      this.$store.commit('stopDocuments/updateDocumentReprintStatus', false);
      alertsService.stopAlertsUpdate();
    },
    cancelLogout() {
      this.$emit('cancel-dialog', false);
    },
  },
  props: {
    openDialog: Boolean,
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
  },
};
