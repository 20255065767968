<template>
  <div
    class="d-flex flex-column justify-center align-center scan-page-container"
  >
    <img
      class="barcode-icon"
      src="src/assets/images/icon-barcode.svg"
    >
    <div class="scan-instruction-label">
      All systems go.<br>Ready to scan when you are.
    </div>
    <div class="normal-label">
      or
    </div>
    <v-btn
      class="scan-btn"
      @click="checkDialog('numeric','manifestnumber', 'Enter UPC')"
    >
      Enter UPC manually
    </v-btn>
    <KeyboardDialog
      :open-dialog="numericDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :confirm-button-text="confirmButtonText"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @enterClicked="enterClicked"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KeyboardDialog from '../virtual-keyboard/virtualKeyboardDialog.vue';

export default {
  name: 'ReadyToScanItem',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({
      getStopDetail: 'getStopDetail',
      fullUpc: 'masterScan/getFullUpc',
    }),
  },
  data() {
    return {
      numericDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      barcode: '',
      confirmButtonText: 'Identify item',
    };
  },
  methods: {
    checkDialog(KeyboardType, modelName, labelName) {
      this.numericDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
    },
    dialogInputChanges(changes) {
      this.numericDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.$emit('scanBarcode', value);
    },
  },
};
</script>
