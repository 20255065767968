//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'ScanItemNotFound',
  computed: {
    ...mapGetters({
      photoUrl: 'masterScan/getPhotoUrl',
      master: 'masterScan/getMasterScanned',
    }),
  },
  data() {
    return {
      isLabelVisibleClicked: false,
      isSendingAnimationComplete: false,
      sendingAnimationTimeout: setTimeout(() => {}),
      sentAnimationTimeout: setTimeout(() => {}),
      isImageCapture: false,
      unknownItemLog: {},
    };
  },
  created() {
    this.$camSocketsConnect();
    this.resetStreaming();
    this.$store.commit('masterScan/setPhotoUrl', '');
  },
  beforeDestroy() {
    this.$camSocketsDisconnect();
  },
  destroyed() {
    if (this.sendingAnimationTimeout) {
      clearTimeout(this.sendingAnimationTimeout);
    }
    if (this.sentAnimationTimeout) {
      clearTimeout(this.sentAnimationTimeout);
    }
  },
  methods: {
    labelVisibleClicked() {
      this.isLabelVisibleClicked = true;
      this.isSendingAnimationComplete = false;
      this.createUnknownItemLog();
      this.sendingAnimationTimeout = setTimeout(() => {
        this.completeSendingAnimation();
      }, 2000);
    },
    completeSendingAnimation() {
      this.isSendingAnimationComplete = true;
      this.sentAnimationTimeout = setTimeout(() => {
        this.backToScanPage();
      }, 500);
    },
    backToScanPage() {
      this.$emit('backToScanPage');
    },
    captureImage() {
      this.isImageCapture = true;
      this.$camSocketsSend('save_images');
      this.$store.dispatch('masterScan/setIsStreaming', false);
    },
    retakeImage() {
      this.isImageCapture = false;
      this.resetStreaming();
    },
    resetStreaming() {
      this.$store.dispatch('masterScan/setIsStreaming', true);
      this.$store.commit('masterScan/setPhotoUrl', '');
    },
    createUnknownItemLog() {
      this.buildUnknownItemLog();
      const params = {
        log: this.unknownItemLog,
        stopId: this.$store.state.stopDetail.stopDetail.id,
      };
      this.$store.dispatch('masterScan/createUnknownItemLog', params);
    },
    buildUnknownItemLog() {
      this.unknownItemLog = {
        bar_code: this.master.upc,
        master_id: this.master.cloud_id,
        weight_in_lbs: this.master.system_weight,
        scanned_at: new Date().toJSON(),
        source: '',
        package_id: '',
        quantity: 0,
        itemNumber: this.master.sku,
        component_number: 0,
      };
    },
  },
};
