//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import KeyboardNumeric from './keypad.vue';

export default {
  name: 'ArrivalTimeUpdate',
  components: {
    KeyboardNumeric,
  },
  computed: {
    timeStr() {
      let { hours, minutes } = this.arrivedTimeObject;
      const { meridiem } = this.arrivedTimeObject;
      if (hours === '12') {
        if (meridiem === 'pm') {
          hours = 12;
        } else if (meridiem === 'am') {
          hours = 0;
        }
      } else if (meridiem === 'pm') {
        hours = Number(hours) + 12;
      }
      if (!minutes) minutes = '00';
      return `${hours}:${minutes}`;
    },
    updatedTime() {
      const date = moment.parseZone(this.arrivedTime).format('YYYY-MM-DD');
      return moment.parseZone(`${date} ${this.timeStr}${this.utcOffset}`, 'YYYY-MM-DD HH:mmZ');
    },
    timeError() {
      const currentTime = moment().utcOffset(this.utcOffset);
      return currentTime.isBefore(this.updatedTime);
    },
  },
  data() {
    return {
      dialog: false,
      selectedInput: 'hours',
      input: '',
      arrivedTimeObject: {},
      button: '',
      clean: true,
      utcOffset: '',
    };
  },
  created() {
    this.initiateArrivedTimeObj();
  },
  methods: {
    initiateArrivedTimeObj() {
      const arrivedTime = moment.parseZone(this.arrivedTime);
      this.utcOffset = this.utcToHoursAndMinutes(arrivedTime);
      const hours = arrivedTime.format('h');
      const minutes = arrivedTime.format('m');
      const meridiem = arrivedTime.format('a');
      this.arrivedTimeObject = { hours, minutes, meridiem };
      this.clean = true;
    },
    utcToHoursAndMinutes(arrivedTime) {
      const offset = arrivedTime.utcOffset();
      const time = `${(offset / 60)}:${Math.abs(offset % 60)}`;
      let plusOrMinus = '+';
      if (time[0] === '-') plusOrMinus = '-';
      const newOffsetTime = `${plusOrMinus}${moment(time, 'hh:mm').format('hh:mm')}`;
      return newOffsetTime;
    },
    closeDialog() {
      this.dialog = false;
    },
    clickOutside() {
      this.onKeyPress('outside');
    },
    changeInputSelection(type, value = '') {
      this.selectedInput = type;
      if (type === 'meridiem') {
        this.arrivedTimeObject.meridiem = value;
      } else {
        this.arrivedTimeObject[type] = '';
        this.input = this.arrivedTimeObject[type];
        this.$refs[type].focus();
      }
    },
    onChange() {
      const initialZeroAddition = this.arrivedTimeObject[this.selectedInput] === '' && this.button === '0';
      let switchFocusToMinutes = false;
      if (this.button === '{bksp}' || initialZeroAddition) {
        return;
      }
      if (this.clean) {
        this.arrivedTimeObject[this.selectedInput] = '';
        this.clean = false;
      }
      if (this.selectedInput === 'hours') {
        if (this.arrivedTimeObject[this.selectedInput] === '') {
          this.arrivedTimeObject[this.selectedInput] = this.button;
        } else if (this.arrivedTimeObject[this.selectedInput] === '1' && ['0', '1', '2'].includes(this.button)) {
          this.arrivedTimeObject[this.selectedInput] += this.button;
          switchFocusToMinutes = true;
        } else {
          this.changeInputSelection('minutes');
          this.onChange();
        }
      } else if (this.selectedInput === 'minutes') {
        if (this.arrivedTimeObject[this.selectedInput] === '') {
          this.arrivedTimeObject[this.selectedInput] = this.button;
        } else if (this.arrivedTimeObject[this.selectedInput].length === 1 && ['0', '1', '2', '3', '4', '5'].includes(this.arrivedTimeObject[this.selectedInput])) {
          this.arrivedTimeObject[this.selectedInput] += this.button;
        } else if (this.arrivedTimeObject[this.selectedInput].length === 2) {
          return;
        }
      }
      this.input = this.arrivedTimeObject[this.selectedInput];
      if (switchFocusToMinutes) this.changeInputSelection('minutes');
    },
    onKeyPress(button) {
      this.button = button;
      if (button === '{bksp}') {
        if (this.selectedInput === 'hours' || this.selectedInput === 'minutes') {
          this.arrivedTimeObject[this.selectedInput] = '';
        }
      }
    },
    resetDefault() {
      this.selectedInput = 'hours';
    },
    cancel() {
      this.resetDefault();
      this.closeDialog();
    },
    confirm() {
      if (this.arrivedTimeObject.hours !== '' && !this.timeError) {
        const { timeStr } = this;
        const timeAndDate = this.updatedTime.format();
        this.$nextTick(() => {
          this.$emit('enterClicked', { timeStr, timeAndDate });
          const self = this;
          setTimeout(() => {
            self.closeDialog();
            self.resetDefault();
          }, 300);
        });
      }
    },
  },
  props: {
    openDialog: Boolean,
    arrivedTime: {
      type: String,
      default: '',
    },
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
      if (newVal) {
        this.input = moment.parseZone(this.arrivedTime).format('h');
        this.initiateArrivedTimeObj();
      }
    },
    dialog(newVal) {
      this.$emit('arrivedTimeDialogInputChanged', newVal);
    },
  },
};
