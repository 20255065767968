import moment from 'moment';
import rxItemMobile from '../service/firestore/rx-items.service';

function getFormattedMonth(monthName) {
  const date1 = moment(new Date()).format('MMM');
  if (monthName === 'currentMonth') {
    return date1;
  }

  const monthNum = moment().month(monthName).format('M');
  const dateFrom = moment.monthsShort(monthNum - 2);
  return dateFrom;
}
function getContentList(contentLists, residualWeight) {
  const contentListArray = [];
  contentLists.forEach((contentData) => {
    contentListArray.push({
      contentQuantity: parseInt(contentData.quantity, 10),
      weightOunces: parseInt(contentData.ounces, 10),
      weightPounds: parseInt(contentData.pounds, 10),
      containers: parseInt(contentData.no_of_containers, 10),
      contentID: String(contentData.content_detail_id),
      weightType: 0,
      totalWeight: 0.00,
      month: contentData.month,
      noOfEmptyBottles: parseInt(contentData.no_of_bottle, 10),
      bottleWeight: parseFloat(contentData.bottle_weight),
      residualWeight: parseFloat(residualWeight),
      weightUnit: parseInt(contentData.weight_unit, 10),
      billUnits: JSON.parse(contentData.bill_units),
    });
  });
  return contentListArray;
}
function setEmptyBottleStatus(payload, context) {
  if (payload.is_empty_bottle) {
    context.commit('rxApproval/setRxEmptyBottle', true, { root: true });
    context.commit('rxApproval/enableShowContent', false, { root: true });
  } else if (payload.rx_item) {
    context.commit('rxApproval/enableShowContent', true, { root: true });
    context.commit('rxApproval/setRxEmptyBottle', false, { root: true });
  }
}
function setContentList(context, payload) {
  if (payload && payload.rxEditApproval
    && payload.rxEditApproval.approval_rx_item
    && payload.rxEditApproval.approval_rx_item.stop_rx_items.length > 0) {
    const contentLists = payload.rxEditApproval
      .approval_rx_item.stop_rx_items[payload.index].stop_content_details;
    setEmptyBottleStatus(payload.rxEditApproval.approval_rx_item
      .stop_rx_items[payload.index], context);
    context.commit('setContentList', getContentList(contentLists, payload.rxEditApproval
      .approval_rx_item.stop_rx_items[payload.index].rx_item.empty_bottle_residue_factor));
  } else if (payload && payload.rxApprovedList && payload.rxApprovedList.stop_rx_items.length > 0) {
    const contentLists = payload.rxApprovedList.stop_rx_items[payload.index].stop_content_details;
    setEmptyBottleStatus(payload.rxApprovedList.stop_rx_items[payload.index], context);
    context.commit('setContentList', getContentList(contentLists, payload.rxApprovedList.stop_rx_items[payload.index].rx_item.empty_bottle_residue_factor));
  }
}
function previousMonthButtonStatusChange(stateControl) {
  const stateControld = stateControl;
  stateControld.previousMonthButton = false;
  const btnState = !stateControld.contentList.every((contentDetail) => {
    const contentData = contentDetail;
    let returnResp = false;
    let weightStatus = false;
    if (contentData.weightType === 0
      && (contentData.weightPounds > 0 || contentData.weightOunces > 0)) {
      weightStatus = true;
    }
    const totalWeight = +contentData.totalWeight;
    if (contentData.weightType === 1 && totalWeight > 0.0) {
      weightStatus = true;
    }
    if (contentData.contentID && contentData.contentQuantity > 0
    && contentData.containers > 0 && weightStatus) {
      returnResp = true;
    }
    if (contentData.noOfEmptyBottles > 0) {
      if (contentData.weightType === 0) {
        if (parseFloat(contentData.bottleWeight) >= 0.0) {
          returnResp = true;
        }
      } else {
        returnResp = true;
      }
    }
    if (!contentData.billUnits) return returnResp;
    const buWithoutLBS = contentData.billUnits.filter((bu) => bu.unit !== 'LBS');
    if (buWithoutLBS.length > 0) {
      const bUnit = buWithoutLBS.some((bu) => bu.quantity);
      returnResp = bUnit && returnResp;
    }
    return returnResp;
  });
  stateControld.previousMonthButton = btnState;
}
function setTotalWeight(stateControl, payload) {
  const stateControld = stateControl;
  const pounds = +stateControld.contentList[payload.index].weightPounds;
  const ounces = +stateControld.contentList[payload.index].weightOunces;
  const totalWeight = parseFloat(pounds) + parseFloat(ounces / 16);
  stateControld.contentList[payload.index].totalWeight = totalWeight;
  previousMonthButtonStatusChange(stateControl);
}

function rxNotes() {
  return [
    {
      note: 'Broken pills',
    },
    {
      note: 'Partial pills',
    },
    {
      note: 'Crushed pills',
    },
    {
      note: 'Quantity by Rx',
    },
    {
      note: 'Leaking container',
    },
  ];
}

function rxMobileContentDetails() {
  return [
    {
      id: 1,
      contentDetail: 'Capsules',
    },
    {
      id: 2,
      contentDetail: 'Doses',
    },
    {
      id: 3,
      contentDetail: 'Fluid Oz',
    },
    {
      id: 4,
      contentDetail: 'Grams',
    },
    {
      id: 5,
      contentDetail: 'mL',
    },
    {
      id: 6,
      contentDetail: 'Patches',
    },
    {
      id: 7,
      contentDetail: 'Syringes',
    },
    {
      id: 8,
      contentDetail: 'Tablets',
    },
  ];
}

function rxMobileContainers() {
  return [
    { type: 'BA', desc: 'Burlap, cloth, paper, or plastic bags' },
    { type: 'DT', desc: 'Dump truck' },
    { type: 'CF', desc: 'Fiber or plastic boxes, cartons, cases' },
    { type: 'DW', desc: 'Wooden drums, barrels, kegs' },
    { type: 'CM', desc: 'Metal boxes, cartons, cases (including roll-offs)' },
    { type: 'HG', desc: 'Hopper or gondola cars' },
    { type: 'CW', desc: 'Wooden boxes, cartons, cases' },
    { type: 'TC', desc: 'Tank cars' },
    { type: 'CY', desc: 'Cylinders' },
    { type: 'TP', desc: 'Portable tanks' },
    { type: 'DF', desc: 'Fiberboard or plastic drums, barrels, kegs' },
    { type: 'TT', desc: 'Cargo tanks (tank trucks)' },
    { type: 'DM', desc: 'Metal drums, barrels, kegs' },
  ];
}

function measureUnitList() {
  return [
    {
      id: 1, unit: 'CMTR', description: 'Cubic Meters', printed_unit: 'C',
    },
    {
      id: 2, unit: 'GAL', description: 'Gallons', printed_unit: 'G',
    },
    {
      id: 3, unit: 'KG', description: 'Kilograms', printed_unit: 'K',
    },
    {
      id: 4, unit: 'LB', description: 'Pounds', printed_unit: 'P',
    },
    {
      id: 5, unit: 'LITR', description: 'Liters', printed_unit: 'L',
    },
    {
      id: 6, unit: 'MTON', description: 'Metric Tons', printed_unit: 'M',
    },
    {
      id: 7, unit: 'TONS', description: 'Tons', printed_unit: 'T',
    },
    {
      id: 8, unit: 'YARD', description: 'Cubic Yard', printed_unit: 'Y',
    },
  ];
}

function defaultContent(monthName) {
  return {
    contentID: '',
    contentQuantity: 0,
    weightType: 0,
    weightPounds: 0,
    weightOunces: 0,
    containers: 1,
    totalWeight: 0.00,
    month: getFormattedMonth(monthName),
    noOfEmptyBottles: 1,
    residualWeight: 0.01,
    bottleWeight: '',
    weightUnit: 4,
    billUnits: null,
  };
}


const state = {
  contentList: [defaultContent('currentMonth')],
  previousMonthButton: true,
  isCheckEmptyBottle: false,
  billUnitList: [],
  rxItemNotes: rxNotes(),
  contentDetails: rxMobileContentDetails(),
  containerTypes: rxMobileContainers(),
  mobileMeasureUnitList: measureUnitList(),
};

const getters = {
  getContentList(stateControl) {
    return stateControl.contentList;
  },
  getPreviousMonthButtonStatus(stateControl) {
    return stateControl.previousMonthButton;
  },
  getBillUnitList(stateControl) {
    return stateControl.billUnitList;
  },
  getRxItemNotes(stateControl) {
    return stateControl.rxItemNotes;
  },
  getRxContentDetails(stateControl) {
    return stateControl.contentDetails;
  },
  getRxContainerTypes(stateControl) {
    return stateControl.containerTypes;
  },
  getMeasureUnitList(stateControl) {
    return stateControl.mobileMeasureUnitList;
  },
};
const mutations = {
  addAnotherContent(stateControl) {
    const stateControld = stateControl;
    stateControld.contentList
      .push(defaultContent(stateControld.contentList[stateControld.contentList.length - 1].month));
    previousMonthButtonStatusChange(stateControl);
  },
  removeContent(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.contentList.splice(payload);
    previousMonthButtonStatusChange(stateControl);
  },
  increaseContent(stateControl, payload) {
    const stateControld = stateControl;
    const contentData = stateControld.contentList[payload.index];
    stateControld.contentList[payload.index][payload.field] = contentData[payload.field] + 1;
    setTotalWeight(stateControl, payload);
  },
  decreaseContent(stateControl, payload) {
    const stateControld = stateControl;
    const contentData = stateControld.contentList[payload.index];
    stateControld.contentList[payload.index][payload.field] = contentData[payload.field] - 1;
    setTotalWeight(stateControl, payload);
  },
  resetWeight(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.contentList[payload.index].totalWeight = 0.00;
    previousMonthButtonStatusChange(stateControl);
  },
  setContentValue(stateControl, payload) {
    const params = payload;
    const stateControld = stateControl;
    if (params.field === 'billUnits') {
      params.value = JSON.parse(params.value);
    }
    stateControld.contentList[params.index][params.field] = params.value;
    setTotalWeight(stateControl, params);
  },
  resetContent(stateControl) {
    const stateControld = stateControl;
    stateControld.contentList = [defaultContent('currentMonth')];
  },
  setContentList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.contentList = payload;
  },
  setContentWeight(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.contentList[payload.index].totalWeight = parseFloat(payload.weight);
    previousMonthButtonStatusChange(stateControl);
  },
  setEmptyBottleStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.isCheckEmptyBottle = payload;
  },
  enablePreviousMonthBtn(stateControl) {
    const stateControld = stateControl;
    previousMonthButtonStatusChange(stateControld);
  },
  setIncBillUnitQuantity(stateControl, payload) {
    const stateControld = stateControl;
    const { index, contentIndex } = payload;
    stateControld.contentList[contentIndex].billUnits[index].quantity += 1;
    previousMonthButtonStatusChange(stateControld);
  },
  setDecBillUnitQuantity(stateControl, payload) {
    const stateControld = stateControl;
    const { index, contentIndex } = payload;
    stateControld.contentList[contentIndex].billUnits[index].quantity -= 1;
    previousMonthButtonStatusChange(stateControld);
  },
  setRxBillUnitQuantity(stateControl, payload) {
    const { contentIndex, index, value } = payload;
    const stateControld = stateControl;
    stateControld.contentList[contentIndex].billUnits[index].quantity = value;
    previousMonthButtonStatusChange(stateControld);
  },
  setBillUnitList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.billUnitList = payload;
    stateControld.contentList[0].billUnits = JSON.parse(payload);
  },
  setApprovalBillUnit(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.billUnitList = payload;
  },
  setPreviousMonthButtonStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.previousMonthButton = payload;
  },
};

const actions = {
  addAnotherContentAction({ commit }) {
    commit('addAnotherContent');
  },
  setContentList(context, payload) {
    setContentList(context, payload);
  },
  fetchApproval(context, approvalId) {
    rxItemMobile.getApproval(approvalId).then((approval) => {
      context.commit('setApprovalBillUnit', JSON.stringify(approval.bill_units));
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
