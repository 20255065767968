//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import stopInfoHeader from './stopInformationHeader.vue';
import stopInformation from './stopInformation.vue';
import rxDrawer from '../../rx-items/rx-drawer/index.vue';

export default {
  name: 'StopInformationWorkArea',
  components: {
    stopInfoHeader,
    stopInformation,
    rxDrawer,
  },
  computed: {
    ...mapGetters(['getComponentName']),
  },
  methods: {
    openInventoryList() {
      this.$emit('openInventoryList');
    },
  },
};
