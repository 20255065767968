<template>
  <div class="scan-view-sds">
    <button
      type="button"
      class="btn-back"
      @click="goToBackPage"
    >
      <img
        src="src/assets/images/left-arrow.svg"
        alt="Back icon"
      >
      Back
    </button>

    <div class="sds-iframe">
      <iframe
        :src="sdsUrlLink"
        height="851px"
        width="100%"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ScanViewSDS',
  methods: {
    goToBackPage() {
      this.$emit('goBack');
    },
  },
  props: {
    sdsUrlLink: {
      type: String,
      default: '',
    },
  },
};
</script>
