<template>
  <v-navigation-drawer
    v-model="rightDrawer"
    fixed
    temporary
    right
    stateless
    class="right-drawer-container"
    :class="[{'right-drawer-active': rightDrawer}, {'right-drawer-inactive': !rightDrawer}]"
  >
    <div
      class="drawer-header-container"
      v-if="!viewPdf"
    >
      <div class="d-flex justify-space-between drawer-header-bar">
        <div class="location-container">
          <div class="location-header">
            Location
          </div>
          <div class="location-label">
            {{ activeStopLocation }}
          </div>
        </div>
        <div class="d-flex align-center close-drawer-container">
          <img
            src="src/assets/images/close.svg"
            @click="$emit('closeDrawer')"
          >
          <div
            class="close-label"
            @click="$emit('closeDrawer')"
          >
            Close
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="viewPdf"
      class="right-drawer-pdf"
    >
      <button
        class="close-btn"
        @click="closePDF"
      >
        <img
          class="close-img"
          src="src/assets/images/close-circle.svg"
          alt="close"
        >
        Close
      </button>
      <template v-if="fileType && fileType.toLowerCase() === 'pdf'">
        <object
          class="pdf-object"
          width="100%"
          height="91.5%"
          type="application/pdf"
          :data="pdfUrl"
        >
          <embed
            type="application/pdf"
            :src="pdfUrl"
            width="100%"
            height="100%"
          >
        </object>
      </template>
      <template v-if="fileType==='jpg'">
        <object
          class="pdf-object"
          width="100%"
          height="91.5%"
          type="image/jpeg"
          :data="pdfUrl"
        >
          <embed
            type="image/jpeg"
            :src="pdfUrl"
            width="100%"
            height="100%"
          >
        </object>
      </template>
    </div>
    <scan-page
      v-if="scanComponent"
      @closeDrawer="closeRightDrawer"
    />
    <alert-page
      v-if="alertComponent"
      @closeDrawer="closeRightDrawer"
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from 'vuex';
import ScanPage from '../../helpers/component/scan-item/scanPage.vue';
import AlertPage from '../../helpers/component/alert-page/alertPage.vue';

export default {
  name: 'RightDrawer',
  components: {
    ScanPage, AlertPage,
  },
  computed: {
    ...mapGetters([
      'getStopDetail', 'getStopVoidStatus', 'getVoidedStopsInTrip',
    ]),
    activeStopLocation() {
      if (this.getStopDetail && this.getStopDetail.generator) {
        return this.getStopDetail.generator.name;
      }
      return '';
    },
  },
  methods: {
    closePDF() {
      this.$emit('closePDFView');
    },
    closeRightDrawer() {
      this.$emit('closeDrawer');
    },
  },
  props: {
    rightDrawer: {
      type: Boolean,
      default: false,
    },
    scanComponent: {
      type: Boolean,
      default: false,
    },
    viewPdf: {
      type: Boolean,
      default: false,
    },
    pdfUrl: {
      type: String,
      default: '',
    },
    alertComponent: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
      default: 'pdf',
    },
  },
  watch: {
    getStopVoidStatus() {
      this.closeRightDrawer();
      this.closePDF();
    },
    getVoidedStopsInTrip() {
      if (this.getVoidedStopsInTrip.includes(this.getStopDetail.id)) {
        this.closeRightDrawer();
        this.closePDF();
      }
    },
  },
};
</script>
