<template>
  <div>
    <technician-last-email />
    <form
      v-if="!emailSendStatus"
      @submit.prevent="submit"
    >
      <div class="forgotpin-content-col2">
        <div class="col2-box">
          <div class="verification-text">
            <label>
              We'll send you an email to
              <br>reset your PIN.
            </label>
          </div>
          <div class="forgot-pin-button-center">
            <div class="forgotpin-formlabel">
              <div
                v-if="errorMessage"
                class="email-error"
              >
                <span class="forgot-font-weight">!</span>
                &nbsp; &nbsp;Email not on file. Try again?
              </div>
              <label>Email address</label>
              <input
                v-model="form.email"
                type="text"
                name="email"
                class="input"
                @selectstart.prevent
                @paste.prevent
                @copy.prevent
                @cut.prevent
                @drag.prevent
                @drop.prevent
                v-unSelect
              >
            </div>
            <div class="pt-7">
              <button
                type="submit"
                class="btn-submit"
              >
                Reset PIN
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div
      v-if="emailSendStatus"
      class="forgotpin-content-col2"
    >
      <div class="success-column">
        <div
          class="success-text"
          v-if="resendPinEmail === false"
        >
          <label>
            A PIN reset email has been sent to
            <br>{{ loginEmail }}
          </label>
        </div>
        <div
          class="h1-heading"
          v-if="resendPinEmail === true"
        >
          <label>
            A new PIN reset email has been sent to
            <br>{{ loginEmail }}
          </label>
        </div>
        <div>
          <label>Once you've reset your PIN, enter it here.</label>
        </div>
        <div>
          <button
            type="submit"
            class="btn-submit"
            @click.prevent="enterNewPin()"
          >
            Enter new PIN
          </button>
        </div>
        <div>
          <a
            href="#"
            @click.prevent="resendEmail('second')"
          >Resend email</a>
        </div>
      </div>
    </div>
    <div class="back margin-top-110">
      <div class="flex-back">
        <div class="arrow">
          <i class="fas fa-angle-left" />
        </div>
        <div
          class="text"
          @click.prevent="backNav()"
        >
          Back
        </div>
      </div>
    </div>
    <pinDialog
      :open-dialog="pinDialogIsOpen"
      :email="loginEmail"
      @dialogInputChanged="dialogInputChanges"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import pinDialog from '../../helpers/component/pin-dialog/pinDialog.vue';
import technicianLastEmail from '../../helpers/component/technician-last-email/technicianLastEmail.vue';

export default {
  name: 'ForgotPin',
  components: {
    technicianLastEmail,
    pinDialog,
  },
  computed: {
    ...mapGetters({ loginEmail: 'authentication/getLoggedEmail' }),
    ...mapGetters({ loginStatus: 'authentication/getloginStatus' }),
  },
  data() {
    return {
      form: {
        email: '',
      },
      emailSendStatus: true,
      hasError: false,
      errorMessage: '',
      pinDialogIsOpen: false,
      resendPinEmail: false,
    };
  },
  beforeMount() {
    this.resendEmail('first');
    this.$store.dispatch('authentication/updateRecentTechnician', '');
  },
  methods: {
    submit() {
      this.$v.form.email.$touch();
      if (this.$v.form.email.required) {
        this.$store.dispatch('addActionEmail', this.form.email).then((res) => {
          this.errorMessage = '';
          if (res && res.length > 0) {
            this.emailSendStatus = true;
          } else {
            this.errorMessage = 'Email does not exist';
          }
        });
      }
    },
    backNav() {
      this.$router.go(-1);
    },
    enterNewPin() {
      this.pinDialogIsOpen = true;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    resendEmail(type) {
      if (this.loginEmail) {
        const data = {
          email: this.loginEmail,
        };
        this.$store.dispatch('resendPinEmail', data).then((res) => {
          this.errorMessage = '';
          if (res && res.user && type !== 'first') {
            this.resendPinEmail = true;
          } else {
            this.resendPinEmail = false;
            this.errorMessage = 'Email does not exist';
          }
        });
      }
    },
  },
  validations: {
    form: {
      email: { required },
    },
  },
  watch: {
    loginStatus(newVal) {
      if (newVal === true) {
        this.$router.push('logged-in-home');
      }
    },
  },
};
</script>
