//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import containerQuantity from './containerQuantity.vue';
import containerType from './containerType.vue';

export default {
  name: 'ContainerBuilder',
  components: { containerQuantity, containerType },
  methods: {
    removeContainer() {
      this.$store.commit('container/resetContainerIndex', { typeID: this.containerDetail.type.id });
      this.$store.dispatch('container/removeContainerAction', this.containerIndex);
      this.$store.commit('container/triggerReOrderContainerIndex', this.containerIndex);
    },
  },
  props: {
    containerDetail: {
      type: Object,
      default: () => ({
        type: {
          id: '',
          container_type: '',
        },
        quantity: 0,
      }),
    },
    lastIndex: {
      type: Boolean,
      default: false,
    },
    firstIndex: {
      type: Boolean,
      default: false,
    },
    containerIndex: {
      type: Number,
      default: 0,
    },
    hasCustomerSpecificBilling: {
      default: false,
      type: Boolean,
    },
  },
};
