<template>
  <div class="keyboardContainer">
    <div class="simple-keyboard-main" />

    <div class="numPad">
      <div class="simple-keyboard-numpad" />
    </div>
  </div>
</template>

<script>
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';

export default {
  name: 'SimpleKeyboard',
  data: () => ({
    keyboard: null,
    keyboardNumPad: null,
    index: 1,
    checkCaps: false,
  }),
  mounted() {
    const commonKeyboardOptions = {
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      theme: 'simple-keyboard hg-theme-default hg-layout-default',
      syncInstanceInputs: true,
      mergeDisplay: true,
    };
    this.keyboard = new Keyboard('.simple-keyboard-main', {
      ...commonKeyboardOptions,
      layout: {
        default: [
          '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
          '{tab} q w e r t y u i o p [ ] \\',
          "{capslock} a s d f g h j k l ; ' {enter}",
          '{shiftleft} z x c v b n m , . / {shiftright}',
          '{space} @ .com',
        ],
        shift: [
          '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
          '{tab} Q W E R T Y U I O P { } |',
          '{capslock} A S D F G H J K L : " {enter}',
          '{shiftleft} Z X C V B N M < > ? {shiftright}',
          '{space} @ .com',
        ],
        caps: [
          '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
          '{tab} Q W E R T Y U I O P [ ] \\',
          '{capslock} A S D F G H J K L ; \' {enter}',
          '{shiftleft} Z X C V B N M , . / {shiftright}',
          '{space} @ .com',
        ],
      },
    });
    this.keyboardNumPad = new Keyboard('.simple-keyboard-numpad', {
      ...commonKeyboardOptions,
      layout: {
        default: [
          '{numpad7} {numpad8} {numpad9}',
          '{numpad4} {numpad5} {numpad6}',
          '{numpad1} {numpad2} {numpad3}',
          '{numpad0}',
        ],
      },
    });
  },
  methods: {
    onChange(input) {
      if (this.index === 1) {
        const val = this.input + input;
        this.$emit('onChange', val);
        this.index = this.index + 1;
      } else {
        this.$emit('onChange', input);
      }
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button);
      if (button === '{bksp}') {
        this.inputCheck = this.input.substring(0, this.input.length - 1);
        this.index = this.index + 1;
        this.onChange(this.inputCheck);
      }
      if (button === '{enter}') {
        this.index = 1;
      }
      /* If you want to handle the shift and caps lock buttons */
      if (button === '{capslock}') {
        this.handleLock();
        this.checkCaps = true;
      } else if (button === '{shift}' || button === '{shiftright}' || button === '{shiftleft}') {
        this.handleShift();
        this.checkCaps = false;
      } else if (this.checkCaps === false) {
        this.handleDefault();
      }
    },
    handleLock() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === 'default' ? 'caps' : 'default';
      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    handleDefault() {
      const shiftToggle = 'default';
      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  props: {
    input: {
      default: '',
      type: String,
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
