<template>
  <div>
    <technician-last-email />
    <div
      class="width-100p mt-10"
      align="center"
    >
      <div class="text-center">
        <h1 class="h1-heading">
          Account creation
        </h1>
      </div>
      <form
        class="width-349 text-left margin-top-20"
        @submit.prevent="submit"
      >
        <div>
          <div
            :class="{ 'hasError': submitted
              && ($v.form.firstname.$error || $v.form.lastname.$error) }"
          >
            <span
              class="label"
            >
              Name
            </span>
          </div>
          <div
            v-if="submitted && $v.form.firstname.$error"
            class="email-error-firstname"
          >
            <span
              v-if="submitted && !$v.form.firstname.required"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Firstname is required
            </span>
            <span
              v-if="submitted && !$v.form.firstname.minLength"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Minimum character should be 2
            </span>
            <span
              v-if="submitted && !$v.form.firstname.maxLength"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Maximum character should be 25
            </span>
          </div>
          <input
            v-model="form.firstname"
            type="text"
            name="First Name"
            placeholder="First"
            class="input firstName-input"
            minlength="2"
            maxlength="25"
            autocomplete="off"
            @click="checkDialog('alphaNumeric','firstname','First name',form.firstname)"
            :class="{ 'hasInputError': submitted && $v.form.firstname.$error}"
            @selectstart.prevent
            @paste.prevent
            @copy.prevent
            @cut.prevent
            @drag.prevent
            @drop.prevent
            v-unSelect
          >
          <div
            v-if="submitted && $v.form.lastname.$error"
            class="email-error-lastname"
          >
            <span
              v-if="submitted && !$v.form.lastname.required"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Lastname is required
            </span>
            <span
              v-if="submitted && !$v.form.lastname.minLength"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Minimum character should be 2
            </span>
            <span
              v-if="submitted && !$v.form.lastname.maxLength"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Maximum character should be 25
            </span>
          </div>
          <input
            v-model="form.lastname"
            type="text"
            name="Last Name"
            placeholder="Last"
            class="input lastName-input"
            minlength="2"
            maxlength="25"
            autocomplete="off"
            @click="checkDialog('alphaNumeric','lastname','Last name',form.lastname)"
            :class="{ 'hasInputError': submitted && $v.form.lastname.$error}"
            @selectstart.prevent
            @paste.prevent
            @copy.prevent
            @cut.prevent
            @drag.prevent
            @drop.prevent
            v-unSelect
          >
        </div>
        <div
          class="margin-top-20"
          :class="{ 'hasError': submitted && $v.form.email.$error }"
        >
          <span class="label">
            Work email address
          </span><br>
          <div
            v-if="submitted && $v.form.email.$error"
            class="email-error-email"
          >
            <span
              v-if="submitted && !$v.form.email.required"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Email is required
            </span>
            <span
              v-if="submitted && !$v.form.email.email"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Email is invalid
            </span>
            <span
              v-if="submitted && $v.form.email.required
                && $v.form.email.email && !$v.form.email.emailPatternValidation"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Email domain is not acceptable
            </span>
            <span
              v-if="submitted && !$v.form.email.maxLength"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Maximum character should be 50
            </span>
          </div>
          <input
            v-model="form.email"
            type="text"
            name="email"
            class="input"
            maxlength="50"
            autocomplete="off"
            @click="checkDialog('alphaNumeric','email','Work email address',form.email)"
            :class="{ 'hasError': submitted && $v.form.email.$error}"
            @selectstart.prevent
            @paste.prevent
            @copy.prevent
            @cut.prevent
            @drag.prevent
            @drop.prevent
            v-unSelect
          >
        </div>
        <div
          class="margin-top-20"
          :class="{ 'hasError': submitted && $v.form.phone1.$error }"
        >
          <span class="label">Phone number</span><br>
          <div
            v-if="submitted && $v.form.phone1.$error"
            class="phone-number-error"
          >
            <span
              v-if="submitted && !$v.form.phone1.required"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Phone number is required
            </span>
            <span
              v-if="submitted && (!$v.form.phone1.minLength || !$v.form.phone1.maxLength)"
            >
              <span class="forgot-font-weight">!</span>
              &nbsp; &nbsp;Please enter 10 digit number
            </span>
          </div>
          <input
            v-model="form.phone1"
            :maxlength="10"
            type="text"
            name="Phone1"
            autocomplete="off"
            @click.prevent="checkDialog('numeric','phone1','Phone number',
                                        form.phone1)"
            class="input"
            :class="{ 'hasError': submitted && $v.form.phone1.$error}"
            @selectstart.prevent
            @paste.prevent
            @copy.prevent
            @cut.prevent
            @drag.prevent
            @drop.prevent
            v-unSelect
          >
        </div>
        <br>
        <div class="text-center width-220">
          <button
            type="submit"

            class="btn-submit green-btn"
          >
            Next: Create PIN
          </button>
          <p
            v-if="accountStatus === 'failed'"
            class="error-msg position-absolute"
          >
            Email is already in use
          </p>
        </div>
      </form>
      <div
        class="float-left"
      >
        <button
          type="button"
          class="btn-back margin-top-50 create-account-back-btn d-flex align-center"
          @click="goToBackPage()"
        >
          <div class="back-btn-confirm back-btn-create" />
          <span class="back-btn-create-span">Back</span>
        </button>
      </div>
      <KeyboardDialog
        :open-dialog="pinDialogIsOpen"
        :keyboard-type="keyBoardType"
        :model-name="modelName"
        :label-name="labelName"
        :model-value="modelValue"
        @dialogInputChanged="dialogInputChanges"
        name="keyboard"
        @enterClicked="enterClicked"
      />
      <pinDialog
        :open-dialog="showPinDialog"
        :email="getEmail"
        @dialogInputChanged="dialogPinInputChanges"
      />
    </div>
  </div>
</template>

<script>
import {
  required, email, minLength, maxLength,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import KeyboardDialog from '../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import emailPattern from '../../helpers/util';
import pinDialog from '../../helpers/component/pin-dialog/pinDialog.vue';
import technicianLastEmail from '../../helpers/component/technician-last-email/technicianLastEmail.vue';

const emailPatternValidation = (value) => emailPattern.test(value);
export default {
  name: 'CreateAccount',
  components: {
    KeyboardDialog,
    technicianLastEmail,
    pinDialog,
  },
  computed: {
    ...mapGetters({
      accountStatus: 'createAccount/getAccountStatus',
      checkEmailExists: 'createAccount/getExistingEmail',
      getEmail: 'createAccount/getEmail',
      getConfirmedPin: 'createAccount/getConfirmedPinValue',
    }),
  },
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phone1: '',
      },
      userExist: false,
      input: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      firstnameError: '',
      lastnameError: '',
      emailError: '',
      firstNameIfError: false,
      lastNameIfError: false,
      emailIfError: false,
      phoneNumberIfError: false,
      modelValue: '',
      submitted: false,
      showPinDialog: false,
    };
  },
  beforeMount() {
    this.$store.commit('createAccount/setStatus');
    this.$store.dispatch('authentication/updateRecentTechnician', '');
  },
  methods: {
    submit() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.enterNewPin();
      this.$store.dispatch('createAccount/setCreateAccountValue', {
        first_name: this.form.firstname,
        last_name: this.form.lastname,
        email: this.form.email,
        phone_number: this.form.phone1,
      });
    },
    goToBackPage() {
      this.$router.go(-1);
    },
    onChangeEmail(ev) {
      if (ev && this.checkEmailExists && ev === this.checkEmailExists) {
        this.$store.commit('createAccount/failed');
      } else if (this.accountStatus === 'failed') {
        this.$store.commit('createAccount/setStatus');
      }
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.form[value.modelName] = value.input;
      if (value.modelName === 'email') {
        this.onChangeEmail(value.input);
      }
    },
    enterNewPin() {
      this.$store.dispatch('createAccount/setDialogValue', true);
      this.showPinDialog = true;
    },
    dialogPinInputChanges(changes) {
      if (changes === false) {
        this.$store.dispatch('createAccount/setDialogValue', false);
      }
      this.showPinDialog = changes;
    },
  },
  validations: {
    form: {
      firstname: { required, minLength: minLength(2), maxLength: maxLength(25) },
      lastname: { required, minLength: minLength(2), maxLength: maxLength(25) },
      email: {
        required, email, emailPatternValidation, maxLength: maxLength(50),
      },
      phone1: { required, minLength: minLength(10), maxLength: maxLength(10) },
    },
  },
};
</script>
