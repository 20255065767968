//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CompleteStopWeightInfo',
  computed: {
    formattedRetailSiTotalWeight() {
      return Number(this.retailSisTotalWeight).toFixed(2);
    },
  },
  props: {
    truckSisTotalWeight: {
      type: Number,
      default() {
        return {};
      },
    },
    retailSisTotalWeight: {
      type: Number,
      default() {
        return {};
      },
    },
    displayOverweightWarning: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
