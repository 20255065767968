const colorNames = {
  red: 'red',
  blue: 'blue',
  white: 'white',
  black: 'black',
  yellow: 'yellow',
};

module.exports = {
  colorNames,
};
