<template>
  <div class="d-flex notice-container">
    <div class="d-flex flex-column notice-side-menu">
      <div class="release-notes-left-drawer flex-column">
        Successfully updated to
        <div class="d-flex flex-column juesify-center">
          <span>F: {{ versions.frontend }}</span>
          <span>B: {{ versions.backend }}</span>
          <span>P: {{ versions.prod }}</span>
        </div>
      </div>
      <div class="d-flex mt-auto">
        <img
          src="src/assets/images/software-updated.svg"
          alt="release notes"
        >
      </div>
    </div>
    <div class="d-flex flex-column w-1207px">
      <div class="d-flex notice-iframe-container">
        <iframe
          title="Software update notification"
          width="100%"
          src="https://sis.help.smartersorting.com/hc/en-us/articles/360045186631-TruckSIS-Release-Notes"
        />
      </div>
      <div class="d-flex justify-center">
        <button
          class="btn-submit notice-got-it-btn"
          @click="gotoLogin()"
        >
          Got it
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import updateCheckService from '../../service/updateCheck.service';

export default {
  name: 'UpdateNotice',
  computed: {
    ...mapGetters({
      versions: 'getSoftwareVersions',
    }),
  },
  methods: {
    gotoLogin() {
      updateCheckService.updateReleaseNotesStatus().then(() => {
        const { query } = this.$route;
        if (query && query.redirect) {
          this.$router.push(query.redirect);
        } else {
          this.$router.push('/bootUp');
        }
      });
    },
  },
};
</script>
