<template>
  <div>
    <technician-last-email />
    <div class="text-center confirm-account">
      <h1
        class="h1-heading"
        v-if="resendEmailSend === false"
      >
        Almost done. We've sent an email to {{ getEmail }} to create your PIN.
      </h1>
      <h1
        class="h1-heading"
        v-if="resendEmailSend === true"
      >
        We've resent an email to {{ getEmail }} to create your PIN.
      </h1>
      <p
        class="you-ll-recieve-an-em mt-2"
      >
        You’ll receive an email to create your PIN within minutes.
        If you don’t see the email in your inbox, check your spam folder or try resending the email.
      </p>
      <div class="margin-top-30">
        <button
          class="btn-submit width-349"
          @click="enterNewPin()"
        >
          Enter new PIN
        </button>
      </div>
      <div class="margin-top-30">
        <a
          class="resend-email"
          @click="resendEmail()"
        >
          Resend email
        </a>
      </div>
    </div>
    <div
      class="create-account-back-btn"
    >
      <button
        type="button"
        class="btn-back d-flex align-center"
        @click="goToBackPage()"
      >
        <div class="back-btn-confirm back-btn-create" />
        <span class="back-btn-create-span">Back</span>
      </button>
    </div>
    <pinDialog
      :open-dialog="pinDialogIsOpen"
      :email="getEmail"
      @dialogInputChanged="dialogInputChanges"
    />
  </div>
</template>

<script>
import { required, email, numeric } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import pinDialog from '../../helpers/component/pin-dialog/pinDialog.vue';
import technicianLastEmail from '../../helpers/component/technician-last-email/technicianLastEmail.vue';

export default {
  name: 'ConfirmAccount',
  components: {
    technicianLastEmail,
    pinDialog,
  },
  computed: {
    ...mapGetters({
      getEmail: 'createAccount/getEmail',
    }),
    ...mapGetters({ loginStatus: 'authentication/getloginStatus' }),
  },
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phone1: '',
        phone2: '',
        phone3: '',
        error: '',
      },
      userExist: false,
      pinDialogIsOpen: false,
      resendEmailSend: false,
    };
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.form.error = true;
      } else {
        this.$store.dispatch('createAccount/createAccountAction', {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          email: this.form.email,
          phonenumber: this.form.phone1 + this.form.phone2 + this.form.phone3,
        });
      }
    },
    goToBackPage() {
      this.$router.go(-1);
    },
    enterNewPin() {
      this.pinDialogIsOpen = true;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    resendEmail() {
      this.$store.dispatch('createAccount/resendEmailAction', {
        email: this.getEmail,
      }).then((res) => {
        if (res === 'resendEmailSuccess') {
          this.resendEmailSend = true;
        }
      });
    },
  },
  validations: {
    form: {
      firstname: { required },
      lastname: { required },
      email: { required, email },
      phone1: { required, numeric },
      phone2: { required, numeric },
      phone3: { required, numeric },
    },
  },
  watch: {
    loginStatus(newVal) {
      if (newVal === true) {
        this.$router.push('logged-in-home');
      }
    },
  },
};
</script>
