<template>
  <div class="container document-type-table">
    <table cellspacing="0">
      <caption>{{ tableTitle }}</caption>
      <col
        v-if="!printingDocListTable"
        width="100"
      >
      <col
        v-if="!printingDocListTable"
        width="300"
      >
      <col
        v-else
        width="400"
      >
      <col width="300">
      <col width="300">
      <col width="80">
      <tr
        class="table-header-row"
        @click="switchDocumentItemsSelection"
      >
        <th v-if="!printingDocListTable">
          <div class="d-flex justify-center img-div">
            <img
              class="check-img"
              src="src/assets/images/check-circle.svg"
              alt="checkCircle"
              v-if="isAllDocumentItemsSelected"
            >
            <img
              class="check-img"
              src="src/assets/images/check-circle-grey.svg"
              alt="checkCircleGrey"
              v-else
            >
          </div>
        </th>
        <th
          v-for="(header, index) in headerKeys"
          :key="index"
        >
          {{ tableHeaders[header] }}
        </th>
        <th />
      </tr>
      <tr
        is="documentTableItem"
        name="DocumentTableItem"
        :row-data="rowData"
        :printing-doc-list-table="printingDocListTable"
        v-for="(rowData, index) in tableRows"
        :key="index"
        :headers="headerKeys"
      />
    </table>
  </div>
</template>

<script>
import documentTableItem from '../document-table-item/documentTableItem.vue';

export default {
  name: 'DocumentTypeTable',
  components: {
    documentTableItem,
  },
  computed: {
    headerKeys() {
      return Object.keys(this.tableHeaders);
    },
    isAllDocumentItemsSelected() {
      for (let index = 0; index < this.tableRows.length; index += 1) {
        const item = this.tableRows[index];
        if (!item.selected) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    switchDocumentItemsSelection() {
      const documentTypeAndStatus = {
        documentType: this.tableRows[0].type,
        status: false,
      };
      if (!this.isAllDocumentItemsSelected) {
        documentTypeAndStatus.status = true;
      }
      this.$store.dispatch('stopDocuments/updateDocumentsSelectionStatus', documentTypeAndStatus);
    },
  },
  props: {
    tableHeaders: {
      type: Object,
      default() {
        return [];
      },
    },
    tableRows: {
      type: Array,
      default() {
        return [];
      },
    },
    tableTitle: {
      type: String,
      default() {
        return '';
      },
    },
    printingDocListTable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>
