<template>
  <div class="width-100p stop-instruction-content height-100p">
    <div class="d-flex flex-column width-100p height-100p">
      <div class="d-flex justify-center align-center fs-3em m28">
        <em class="fas fa-exclamation-triangle" />
      </div>
      <div class="mobile-active-stop-warning">
        <div class="mobile-active-stop-warning-info">
          <label>
            You have an active stop
          </label>
        </div>
        <div class="mobile-active-stop-warning-title">
          You must complete this stop on the back of the truck before beginning another stop.
        </div>
        <div class="mobile-active-stop-detail-group">
          <div class="pa-4">
            <div class="d-inline-flex flex-column">
              <div class="mobile-stop-list-title">
                <label>Trip</label>
              </div>
              <div class="mobile-stop-detail">
                {{ activeStopDetail.tripCode }}
              </div>
            </div>
            <div class="d-inline-flex flex-column ml-4">
              <div class="mobile-stop-list-title">
                <label>Stop number</label>
              </div>
              <div class="mobile-stop-detail">
                {{ activeStopDetail.stopNumber }}
              </div>
            </div>
          </div>
          <v-divider :inset="false" />
          <div class="pa-4">
            <div class="d-inline-flex flex-column">
              <div class="mobile-stop-list-title">
                <label>Stop name</label>
              </div>
              <div class="mobile-stop-detail">
                {{ activeStopDetail.stopName }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center align-center mt-auto">
        <button
          block
          dark
          class="mobile-stop-warn-got-it"
          @click="gotIt"
        >
          Got it
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MobileActiveStopWarning',
  computed: {
    ...mapGetters({
      activeStopDetail: 'getMobileActiveStopDetails',
      user: 'authentication/getUser',
    }),
  },
  created() {
    this.$store.dispatch('fetchActiveStopDetail', String(this.user.active_stop_id));
  },
  methods: {
    gotIt() {
      this.$router.go(-1);
      this.$store.commit('setMobileActiveStopDetails', {});
    },
  },
};
</script>
