//
//
//
//
//
//

import Keyboard from 'simple-keyboard';

export default {
  name: 'SimpleKeyboard',
  data: () => ({
    keyboard: null,
    alphaKeyType: false,
    numKeyType: false,
    checkCaps: false,
    index: 1,
    inputCheck: '',
  }),
  mounted() {
    this.keyboard = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: {
        default: [
          '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
          '{tab} q w e r t y u i o p [ ] \\',
          '{lock} a s d f g h j k l ; \' {enter}',
          '{shift} z x c v b n m , . / {shift}',
          '{space} @ .com',
        ],
        shift: [
          '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
          '{tab} Q W E R T Y U I O P { } |',
          '{lock} A S D F G H J K L : " {enter}',
          '{shift} Z X C V B N M < > ? {shift}',
          '{space} @ .com',
        ],
        caps: [
          '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
          '{tab} Q W E R T Y U I O P [ ] \\',
          '{lock} A S D F G H J K L ; \' {enter}',
          '{shift} Z X C V B N M , . / {shift}',
          '{space} @ .com',
        ],
      },
      display: {
        '{enter}': '< enter',
        '{shift}': 'shift',
        '{tab}': 'tab',
        '{lock}': 'caps',
        '{space}': ' ',
        '{bksp}': 'backspace',
      },
    });
    if (this.capsLock === true || this.labelName === 'Generator Sign Name'
       || this.labelName === 'First name' || this.labelName === 'Last name') {
      const currentLayout = 'caps';
      this.keyboard.setOptions({
        layoutName: currentLayout,
      });
    } else {
      this.handleDefault();
    }
  },
  methods: {
    onChange(input) {
      if (this.index === 1) {
        const val = this.input + input;
        this.$emit('onChange', val);
        this.index = this.index + 1;
      } else {
        this.$emit('onChange', input);
      }
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button);
      if (button === '{bksp}' && this.index === 1) {
        this.inputCheck = this.input.substring(0, this.input.length - 1);
        this.index = this.index + 1;
        this.onChange(this.inputCheck);
      }
      /* If you want to handle the shift and caps lock buttons */
      if ((button === '{space}' && this.labelName === 'Generator Sign Name')
          || (button === '{space}' && this.labelName === 'First name')
          || (button === '{space}' && this.labelName === 'Last name')) {
        const shiftToggle = 'caps';
        this.keyboard.setOptions({
          layoutName: shiftToggle,
        });
        this.checkCaps = false;
      } else if (button === '{lock}') {
        this.handleLock();
        this.checkCaps = true;
      } else if (button === '{shift}') {
        this.handleShift();
        this.checkCaps = false;
      } else if (this.checkCaps === false && this.capsLock === false) {
        this.handleDefault();
      }
    },
    handleLock() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === 'default' ? 'caps' : 'default';
      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';
      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
    handleDefault() {
      const shiftToggle = 'default';
      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String,
    },
    input: {
      default: '',
      type: String,
    },
    capsLock: {
      default: false,
      type: Boolean,
    },
    labelName: {
      default: '',
      type: String,
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
    labelName(newVal) {
      if ((newVal === 'Generator Sign Name'
       || newVal === 'First name' || newVal === 'Last name') && this.input === '') {
        const currentLayout = 'caps';
        this.keyboard.setOptions({
          layoutName: currentLayout,
        });
      } else {
        this.handleDefault();
      }
    },
  },
};
