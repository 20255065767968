<template>
  <div class="d-flex flex-column justify-center mr-3">
    <div class="icon-set">
      <div :class="getClass" />
      <div
        v-if="connected"
        class="connected-icon"
      >
        <div class="connected-trip" />
      </div>
    </div>
    <div
      v-if="connected"
      class="connected-label"
    >
      Connected
    </div>
  </div>
</template>
<script>
export default {
  name: 'StopIcon',
  computed: {
    getClass() {
      let className = 'dea-active';
      if (this.stopType.toLowerCase() === 'st') {
        className = 'st-active';
      }
      if (this.stopType.toLowerCase() === 'fs') {
        className = 'fs-active';
      }
      if (this.stopType.toLowerCase() === 'rx') {
        className = 'rx-active';
      }
      if (this.stopType.toLowerCase() === 'dc') {
        className = 'dc-active';
      }
      if (this.stopType.toLowerCase() === 'cf') {
        className = 'cf-active';
      }
      return className;
    },
  },
  props: {
    stopType: {
      type: String,
      default: '',
    },
    connected: Boolean,
  },
};
</script>
