function getDocumentTypes() {
  return {
    HAZ_MANIFEST: 'haz_first',
    HAZ_CONTINUATION: 'haz_continuation',
    NON_HAZ_MANIFEST: 'non_haz_first',
    NON_HAZ_CONTINUATION: 'non_haz_continuation',
    CONSOLIDATED_LOAD_COVER: 'consolidated_load',
    LAND_DISPOSAL: 'land_disposal',
    BOL: 'bol',
    PICKUP_REPORT: 'pickup_report',
  };
}
function getAttachmentTypes() {
  return [
    { text: 'LDR' },
    { text: 'BOL' },
    { text: 'Pickup Report' },
    { text: 'DEA-41' },
    { text: 'DEA-222' },
    { text: 'PRD' },
    { text: 'Packing List' },
    { text: 'Photo' },
    { text: 'Generator Initial Manifest' },
    { text: 'Attachment' },
    { text: 'Customer Document' },
    { text: 'Other' },
  ];
}
const types = getDocumentTypes();
const documentAttachmentTypes = {
  haz_first: 'Haz Manifest',
  non_haz_first: 'Non Haz Manifest',
  consolidated_load: 'Consolidated Load Cover',
  land_disposal: 'Land Disposal',
  bol: 'BOL',
  pickup_report: 'Pickup Report',
  no_FE_waste_retain_form: 'No FE waste retrain form',
  no_Rx_waste_retain_form: 'No Rx waste retrain form',
  chain_of_custody_form: 'Chain of custody form',
  prd: 'Pharmaceutical Record of Distribution',
};

module.exports = {
  types,
  documentAttachmentTypes,
  attachmentsType: getAttachmentTypes(),
};
