import firestoreDB from './firestoreDB.service';

function addAttachments(request) {
  const { attachmentList } = request.body;
  const { stopId } = request.body;
  const batch = firestoreDB.db.batch();
  let isNewItem = false;
  return new Promise((resolve) => {
    attachmentList.map((attachment) => {
      const attachmentDetail = attachment;
      if (!attachmentDetail.isNew) {
        delete attachmentDetail.doc_id;
        const updateRef = firestoreDB.db.collection('stops').doc(stopId).collection('attachments').doc(attachmentDetail.id);
        attachmentDetail.synced = false;
        batch.update(updateRef, attachmentDetail);
      } else {
        delete attachmentDetail.isNew;
        delete attachmentDetail.doc_id;
        isNewItem = true;
        const addRef = firestoreDB.db.collection('stops').doc(stopId).collection('attachments').doc(attachmentDetail.id);
        attachmentDetail.synced = false;
        batch.set(addRef, attachmentDetail, { merge: true });
      }
      return 0;
    });
    batch.commit().catch((error) => {
      resolve(firestoreDB.errorResponse(error));
    });
    if (!isNewItem) {
      resolve(firestoreDB.okResponse({ message: 'Attachments added successfully' }));
    }
  });
}

function getAttachmentsByStopId(commit, state, stopId) {
  firestoreDB.db.collection('stops').doc(stopId).collection('attachments')
    .onSnapshot((result) => {
      let docs = firestoreDB.queryToArray(result);
      docs = docs.map((doc) => ({ ...doc, from_firestore: true }));
      commit('setAttachments', []);
      commit('setAttachments', docs);
      if (state.saveAttachmentSuccessCheck) {
        commit('setAttachmentSuccessMessage', 'success');
        setTimeout(() => commit('setAttachmentSuccessMessage', ''), 3000);
      }
      if (docs.length > 0) {
        commit('updateNavListValue', {
          index: 1,
          field: 'edited',
          value: true,
        }, { root: true });
      } else {
        commit('updateNavListValue', {
          index: 1,
          field: 'edited',
          value: false,
        }, { root: true });
      }
    });
}

function deleteAttachment(attachment) {
  return new Promise((resolve, reject) => {
    firestoreDB.db.collection('stops').doc(attachment.stop_id)
      .collection('attachments').doc(attachment.id)
      .delete()
      .then(() => {
        resolve({ message: 'Attachments deleted successfully' });
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const stopAttachmentService = {
  addAttachments,
  getAttachmentsByStopId,
  deleteAttachment,
};
export default stopAttachmentService;
