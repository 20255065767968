const scaleSocketConnection = {};

scaleSocketConnection.install = (vue, options) => {
  const Vue = vue;
  let ws = new WebSocket(options.url);

  function handleMessage(params) {
    const scaleData = JSON.parse(params.data);
    if (!options.store.state.weight.calConstantLoaded) {
      options.store.dispatch('weight/setCalConstant', scaleData.cal_constant);
      options.store.commit('weight/setCalConstantLoaded', true);
    }
    options.store.dispatch('weight/setRawWeight', scaleData.raw_scale_value);
    options.store.dispatch('weight/setScaledWeight');
  }

  Vue.prototype.$scaleSocketsConnect = () => {
    ws = new WebSocket(options.url);
    options.store.commit('weight/setConnectionStatus', true);

    ws.onmessage = (event) => {
      handleMessage(event);
    };

    ws.onclose = () => {
      // options.store.dispatch('weight/resetWeightAction', false);
    };


    ws.onerror = () => {
      ws.close();
    };
  };

  Vue.prototype.$scaleSocketsDisconnect = () => {
    ws.close();
  };

  Vue.prototype.$scaleSocketsSend = (data) => {
    ws.send(JSON.stringify(data));
  };
};

function getUrl() {
  const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const wsHostname = window.location.hostname;
  const wsPort = 13256;
  return `${wsProtocol}://${wsHostname}:${wsPort}`;
}

export default {
  scaleSocketConnection,
  getUrl,
};
