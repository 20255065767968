//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import KeyboardDialog from '../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import { API_BASE_URL } from '../../constants/index';
import scanBackendService from '../../service/scan.backend.service';
import printBackendService from '../../service/print.backend.service';
import connectivityService from '../../service/connectivity.service';
import barcodeScannerService from '../../service/barcodeScanner.service';
import settingService from '../../service/setting.service';

export default {
  name: 'SystemDiagnostic',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({
      testUpc: 'masterScan/getTestUpc',
      photoUrl: 'masterScan/getPhotoUrl',
      tareWeight: 'weight/getTareWeight',
      calConstant: 'weight/getCalConstant',
      rawTareWeight: 'weight/getRawTareWeight',
      rawWeight: 'weight/getRawWeight',
    }),
    hideConnectivityResults() {
      const noResults = this.connectivityCheckInProgress || !this.connectivityCheckHasExecuted;
      return noResults || this.displayConnectivitySpinner;
    },
    displayCalibrateWeightTextAndButton() {
      const atCalStep = this.form.providedWeight !== '' && this.weightAtZero;
      const calIncomplete = this.form.scaleWeight === 0.0;
      return atCalStep && calIncomplete;
    },
  },
  data() {
    return {
      // FOR KEYBOARD
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      capsLock: '',

      displayConnectivitySpinner: false,

      connectivityCheckInProgress: false,
      lastConnectivityCheckWasSuccessful: false,
      connectivityCheckHasExecuted: false,

      disableSubmit: false,
      disableZebraScan: false,
      zebraScanStarted: false,
      zebraPrintStarted: false,

      dotMatrixPrintStarted: false,

      disableEpsonScan: false,
      epsonScanStarted: false,
      scannedDocumentUrl: '',
      epsonScanErrorText: '',

      waitingOnWeight: false,
      weightAtZero: false,
      scaleCalFailed: false,

      cameraIssue: false,

      toggleLightButtonVisible: true,
      lightTestInProgress: false,
      lightResultsButtonsVisible: false,
      lightResultsTextVisible: false,

      formComplete: false,

      form: {
        cradlePointSerial: '',
        verizonSimSerial: '',
        cradlePointWiFiSSID: '',
        cradlePointWiFiPassword: '',
        cradlePointConfigued: false,
        zebraTestLabelPrinted: false,
        zebraScanned: false,
        zebraScannedValue: '',
        dotMatrixPrinted: false,
        documentScanned: false,
        documentScanVerified: false,
        connectivityTestResults: '',
        scaleWeight: 0.0,
        providedWeight: '',
        weightScaled: false,
        cameraVisible: false,
        lightStripWorks: false,
      },

      weightTared: false,
      submitted: false,
    };
  },
  created() {
    // NOTE: Clear out any cached UPC codes
    this.$store.state.masterScan.testUpc = '';

    settingService.fetchSetting('system_diagnostics')
      .then((res) => {
        const formValuesPresent = res.setting !== undefined
                                    && res.setting.details !== undefined
                                    && res.setting.details !== null;

        if (formValuesPresent) {
          const { details } = res.setting;

          this.form.cradlePointConfigued = true;
          this.form.cradlePointSerial = details.cradlePointSerial;
          this.form.verizonSimSerial = details.verizonSimSerial;
          this.form.cradlePointWiFiSSID = details.cradlePointWiFiSSID;
          this.form.cradlePointWiFiPassword = details.cradlePointWiFiPassword;
        }
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      });

    this.$camSocketsConnect();
    this.$scaleSocketsConnect();
    this.$store.dispatch('masterScan/setIsStreaming', true);
  },
  destroyed() {
    this.$scaleSocketsSend('off');
    this.$camSocketsDisconnect();
    this.$scaleSocketsDisconnect();
  },
  methods: {
    checkConnectivity() {
      this.connectivityCheckHasExecuted = true;
      this.connectivityCheckInProgress = true;
      this.toggleVariable('displayConnectivitySpinner');
      connectivityService.connectivityCheck().then(
        (res) => {
          this.lastConnectivityCheckWasSuccessful = res.connected;
          this.form.connectivityTestResults = res.connected ? 'Success' : 'No connection';
          this.connectivityCheckInProgress = false;
        },
        () => {
          this.connectivityCheckInProgress = false;
          this.lastConnectivityCheckWasSuccessful = false;
          this.form.connectivityTestResults = 'No connection';
        },
      );
    },
    toggleVariable(toBeToggled, duration = 1000, startingVal = false) {
      this.$data[toBeToggled] = !startingVal;
      setTimeout(() => {
        this.$data[toBeToggled] = startingVal;
      }, duration);
    },
    generateScannedDocumentUrl() {
      const cacheBuster = Date.now();
      this.scannedDocumentUrl = `${API_BASE_URL}stops/attachment/pdf/01#toolbar=0&navpanes=0&timestamp=${cacheBuster}`;
      this.$refs.scannedDocumentIframe.src = this.scannedDocumentUrl;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.form[value.modelName] = value.input;
    },
    triggerLEDFlash() {
      this.toggleLightButtonVisible = false;
      this.lightResultsTextVisible = false;
      this.toggleVariable('lightTestInProgress', 1500);
      this.lightResultsButtonsVisible = true;
      this.$scaleSocketsSend('on');
      setTimeout(() => { this.$scaleSocketsSend('off'); }, 1500);
    },
    setLightStripWorks(val) {
      this.lightResultsButtonsVisible = false;
      this.lightResultsTextVisible = true;
      if (!val) {
        this.toggleLightButtonVisible = true;
      }
      this.form.lightStripWorks = val;
    },
    openKeyboard(modelName, labelName, modelValue) {
      const localKeyboardType = (modelName === 'providedWeight' ? 'numeric' : 'alphaNumeric');

      this.pinDialogIsOpen = true;
      this.keyBoardType = localKeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    formErrors() {
      const errors = [];

      if (this.form.cradlePointSerial === '') {
        errors.push('Cradlepoint Serial');
      }

      if (this.form.verizonSimSerial === '') {
        errors.push('Verizon SIM Serial');
      }

      if (this.form.cradlePointWiFiSSID === '') {
        errors.push('Cradlepoint WiFi SSID');
      }

      if (this.form.cradlePointWiFiPassword === '') {
        errors.push('Cradlepoint Wifi Password');
      }

      return errors;
    },
    submit() {
      this.submitted = true;

      const errors = this.formErrors();
      if (errors.length > 0) {
        const errorsAsString = errors.join(', ');
        const errorMessageData = {
          message: `Missing required fields:<br/>${errorsAsString}`,
          type: 'error',
          isCustom: false,
        };
        this.$store.dispatch('toastMessage/showMessage', errorMessageData);

        return false;
      }

      return settingService.createSetting('system_diagnostics', this.form)
        .then(
          () => {
            this.form.cradlePointConfigued = true;
            this.disableSubmit = true;

            this.fireSuccessToast();
          },
        ).catch((error) => {
          const errorMessageData = {
            message: `Something went wrong while saving:<br/>${error}`,
            type: 'error',
            isCustom: false,
          };
          this.$store.dispatch('toastMessage/showMessage', errorMessageData);
        });
    },
    fireSuccessToast() {
      const successMessageData = {
        message: '<div class="toast-container-div">'
          + '<p class="toast-container-div-title">System Diagnostics Complete'
          + '</p><p class="toast-container-div-message">You can now leave '
          + 'this page and begin sorting!</p></div>',
        type: 'success',
      };
      this.$store.dispatch('toastMessage/showAppUpdatedMessage', successMessageData);
    },
    printTestLabel() {
      this.zebraPrintStarted = true;
      printBackendService.printTestLabel().then(
        () => {
          // Do nothing. The User selects a button let us know if it didn't print.
        },
      ).catch((error) => {
        const errorMessageData = {
          message: `Something went wrong while printing:<br/>${error}`,
          type: 'error',
          isCustom: false,
        };
        this.$store.dispatch('toastMessage/showMessage', errorMessageData);
      });
    },
    printSerialNumber() {
      printBackendService.printSerialNumber().then(
        () => {
          // Do nothing. The User selects a button let us know if it didn't print.
        },
      ).catch((error) => {
        const errorMessageData = {
          message: `Something went wrong while printing:<br/>${error}`,
          type: 'error',
          isCustom: false,
        };
        this.$store.dispatch('toastMessage/showMessage', errorMessageData);
      });
    },
    zebraPrintFailed() {
      this.zebraPrintStarted = false;
    },
    zebraPrintVerified() {
      this.form.zebraTestLabelPrinted = true;
    },
    startZebraScan() {
      this.zebraScanStarted = true;
      barcodeScannerService.startMonitoringScanGunInput();
    },
    printDotMatrix() {
      this.disableEpsonScan = false;
      this.dotMatrixPrintStarted = true;
      printBackendService.printTestDocument().then(() => {
        // Do nothing as print should have worked
      }).catch((error) => {
        const errorMessageData = {
          message: `Something went wrong while printing:<br/>${error}`,
          type: 'error',
          isCustom: false,
        };
        this.$store.dispatch('toastMessage/showMessage', errorMessageData);
      });
    },
    dotMatrixPrintFailed() {
      this.dotMatrixPrintStarted = false;
    },
    dotMatrixPrintVerified() {
      this.form.dotMatrixPrinted = true;
    },
    startEpsonScan() {
      const errorText = 'Make sure the wand scanner is powered with a solid white light and a document is loaded';

      this.form.documentScanned = false;
      this.epsonScanErrorText = '';
      this.epsonScanStarted = true;
      const scanPage = { page: 1 };
      scanBackendService.scanAttachment(scanPage).then(
        (res) => {
          setTimeout(this.generateScannedDocumentUrl, 1000);
          this.epsonScanStarted = false;
          if (res.success) {
            this.form.documentScanned = true;// When scan complete
          } else {
            this.epsonScanErrorText = errorText;
          }
        },
        () => {
          this.epsonScanStarted = false;
          this.epsonScanErrorText = errorText;
        },
      );
    },
    documentScanVerified() {
      this.form.documentScanVerified = true;
    },
    documentScanFailed() {
      this.form.documentScanned = false;
      this.epsonScanStarted = false;
    },
    zeroWeight() {
      this.scaleCalFailed = false;
      this.waitingOnWeight = true;
      setTimeout(() => {
        this.waitingOnWeight = false;
        this.weightAtZero = true;
      }, 1500);
      setTimeout(() => {
        this.$store.commit('weight/setRawTareWeight');
      }, 750);
    },
    calibrateWeight() {
      // TODO: Refactor this ugly method
      this.waitingOnWeight = true;
      setTimeout(() => {
        this.waitingOnWeight = false;
      }, 1500);
      setTimeout(() => {
        const providedWeight = parseFloat(this.form.providedWeight);
        const newCalConstant = this.calculateNewCalConstant(providedWeight);
        const calConstantIsValid = this.validateNewCalConstant(newCalConstant, providedWeight);

        if (calConstantIsValid) {
          // This causes the Success text to show up
          setTimeout(() => { this.form.scaleWeight = 1.2; }, 750);
          this.$store.dispatch('weight/setCalConstant', newCalConstant);
          this.$store.dispatch('weight/calculateScaledTareWeight', newCalConstant);
          this.$scaleSocketsSend(`new_cal_constant:${newCalConstant.toString()}`);
        } else {
          setTimeout(() => {
            this.scaleCalFailed = true;
            // This resets the test
            this.weightAtZero = false;
          }, 750);
        }
      }, 750);
    },
    calculateNewCalConstant(providedWeight) {
      let newConstant = (this.rawWeight - this.rawTareWeight) / providedWeight;
      newConstant = Math.round(newConstant);
      const newConstantIsANumber = !Number.isNaN(newConstant);
      if (newConstantIsANumber && newConstant > 0) {
        return newConstant;
      }
      return -1;
    },
    validateNewCalConstant(calConstant, providedWeight) {
      if (calConstant === -1) {
        return false;
      }
      const scaledTareWeight = this.rawTareWeight / calConstant;
      const errWindow = 0.1;
      let scaledWeight = this.rawWeight / calConstant;
      scaledWeight -= scaledTareWeight;
      const lessThanUpperBound = scaledWeight <= providedWeight + errWindow;
      const greaterThanLowerBound = scaledWeight >= providedWeight - errWindow;
      return lessThanUpperBound && greaterThanLowerBound;
    },
    cameraVisbilty(visible) {
      if (visible) {
        this.cameraIssue = false;
        this.form.cameraVisible = true;
      } else {
        this.cameraIssue = true;
      }
    },
  },
  watch: {
    testUpc(newVal) {
      if (newVal) {
        barcodeScannerService.stopMonitoringScanGunInput();
        this.zebraScanStarted = false;
        this.form.zebraScanned = true;
        this.form.zebraScannedValue = newVal;
      }
    },
    form: {
      handler() {
        const allValuesSuccess = this.form.cameraVisible
                              && this.lastConnectivityCheckWasSuccessful
                              && this.form.zebraTestLabelPrinted
                              && this.form.zebraScanned
                              && this.form.dotMatrixPrinted
                              && this.form.documentScanVerified
                              && this.form.lightStripWorks
                              && this.form.scaleWeight !== 0.0;

        if (allValuesSuccess) {
          this.formComplete = true;
          this.fireSuccessToast();
        }
      },
      deep: true,
    },
  },
};
