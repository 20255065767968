//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import pinToolTip from '../../../helpers/component/tool-tip/pinToolTip.vue';
import KeyboardDialog from '../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';

export default {
  name: 'AddTrip',
  components: {
    pinToolTip,
    KeyboardDialog,
  },
  computed: {
    ...mapGetters(
      {
        tripCodeErrorMessage: 'trip/getTripCodeError',
        tripCodeValidate: 'trip/getTripCodeValid',
        tripPasswordErrorMessage: 'trip/getTripPasswordError',
        tripAddStatusChange: 'trip/getTripAddStatus',
      },
    ),
  },
  data() {
    return {
      disablePassword: true,
      disableTruckNumber: true,
      disableTrailerNumber: true,
      disableSubmit: true,
      form: {
        password: '',
        trip_code: '',
        truck_number: '',
        trailer_number: '',
      },
      submitted: false,
      input: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      capsLock: '',
    };
  },
  beforeMount() {
    this.$store.commit('trip/clearStatus');
  },
  methods: {
    submit() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$store.dispatch('trip/addTripAction', this.form);
      this.resetForm();
    },
    tripCodeOnBlur() {
      this.$store.dispatch('trip/tripValidateAction', this.form.trip_code);
    },
    passwordChanges() {
      this.disableSubmit = true;
      if (this.form.password) {
        this.disableSubmit = false;
      }
    },
    resetForm() {
      this.disablePassword = true;
      this.disableSubmit = true;
      this.form.trip_code = '';
      this.form.password = '';
      this.form.truck_number = '';
      this.form.trailer_number = '';
      this.submitted = false;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue, capsLock) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
      this.capsLock = capsLock;
    },
    enterClicked(value) {
      this.form[value.modelName] = value.input;
      if (value.modelName === 'trip_code') {
        this.tripCodeOnBlur();
      }
      if (value.modelName === 'password') {
        this.passwordChanges();
      }
    },
  },
  validations: {
    form: {
      password: { required },
      trip_code: { required },
    },
  },
  watch: {
    tripCodeValidate(newVal) {
      if (newVal) {
        this.disablePassword = false;
        this.disableTruckNumber = false;
        this.disableTrailerNumber = false;
      }
    },
  },


};
