//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MainStopPageNavList',
  computed: {
    disableIdentifyItem() {
      return this.navItem.name === 'Identify an item' && !this.hasNetwork;
    },
  },
  methods: {
    goToPage() {
      this.$emit('go-to-page', this.navItem);
    },
  },
  props: {
    navItem: {
      type: Object,
      default: () => ({
        name: '',
        borderColor: '',
        icon: '',
        edited: false,
      }),
    },
    hasNetwork: {
      type: Boolean,
      default: false,
    },
  },
};
