import firestoreService from './firestore/firestore.service';

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        this.logout();
        window.location.reload(true);
      }
      const error = (data && data.message) || data || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
function getFetchData(url, requestOptions) {
  if (url.includes('firestore')) {
    return firestoreService.fetch(url, requestOptions)
      .then(handleResponse);
  }
  return fetch(url, requestOptions)
    .then(handleResponse);
}

const fetchService = {
  getFetchData,

};
export default fetchService;
