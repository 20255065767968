import api from '../service/stops.service';
import toastMessage from './toast-message';
import tripApi from '../service/trip.service';
import fireStoreStopService from '../service/firestore/stops.service';
import scanService from '../service/scan.backend.service';
import connectedStopConstant from '../constants/connected-stop';
import tripStorage from '../constants/trip-storage';
import apiResponse from '../constants/apiResponseStatus';

const connectedStatus = connectedStopConstant.status;

function setDefaultStopDetail() {
  return {
    start_time: '',
    generator: {
      name: '',
      address: '',
      phone_number: '',
      epaid: '',
      site_code: '',
    },
  };
}
function getNavList() {
  return [
    {
      name: 'Identify an item',
      borderColor: 'nav-item1',
      icon: 'scan-active',
      edited: false,
      synced: false,
      disabled: false,
      hide: true,
      routerPath: 'Mobile UPC Scan',
    },
    {
      name: 'Add attachments',
      borderColor: 'nav-item2',
      icon: 'attachment-icon',
      edited: false,
      synced: false,
      disabled: false,
      routerPath: 'Mobile Attachments',
    },
    {
      name: 'Add Rx items',
      borderColor: 'nav-item3',
      icon: 'rx-active',
      edited: false,
      synced: false,
      disabled: false,
      hide: true,
      routerPath: 'Mobile Add RX Items',
    },
    {
      name: 'Q&A',
      borderColor: 'nav-item4',
      icon: 'QandA-icon',
      edited: false,
      synced: false,
      disabled: false,
      routerPath: 'Mobile Q And A Page',
    },
  ];
}
function setDefaultLastStopData() {
  return {
    name: null,
    actual_departure_time: null,
    generator_sign_name: null,
    number_of_approvals: null,
    number_of_ccid: null,
    last_stop_inventory: [],
  };
}
function updateProfilesWithStopApproval(profiles, stopApprovalData, useCCIDStorage, deletedStopApproval, removeStopApproval) {
  let updateSAData = null;
  if (!removeStopApproval) {
    if (useCCIDStorage) {
      updateSAData = {
        stop_approval: {
          id: stopApprovalData.id,
          waste_type: stopApprovalData.waste_type,
          weight: stopApprovalData.weight,
          weight_unit: stopApprovalData.weight_unit,
        },
      };
    } else {
      updateSAData = {
        id: stopApprovalData.id,
        waste_type: stopApprovalData.waste_type,
        weight: stopApprovalData.weight,
        weight_unit: stopApprovalData.weight_unit,
      };
    }
  }
  const matchedProfiles = profiles.filter((prof) => prof.approval_data.id === stopApprovalData.approval_id);
  matchedProfiles.forEach((prof) => {
    const profile = prof;
    if (removeStopApproval) {
      profile.stop_approval_data = null;
    } else if (!profile.stop_approval_data || deletedStopApproval) {
      profile.stop_approval_data = updateSAData;
    }
  });
}
function updateAccumulationContainerWithStopApproval(containers, stopApprovalData, useCCIDStorage, deletedStopApproval = false, removeStopApproval = false) {
  containers.forEach((container) => {
    updateProfilesWithStopApproval(container.known_profiles, stopApprovalData, useCCIDStorage, deletedStopApproval, removeStopApproval);
    updateProfilesWithStopApproval(container.potential_profiles, stopApprovalData, useCCIDStorage, deletedStopApproval, removeStopApproval);
  });
  return containers;
}
function stopApprovalPresentInContainers(containers, stopApprovalId, useCCIDStorage) {
  if (useCCIDStorage) {
    return containers.some(
      (container) => container.known_profiles.some(
        (prof) => prof.stop_approval_data && prof.stop_approval_data.stop_approval.id === stopApprovalId,
      )
        || container.potential_profiles.some(
          (prof) => prof.stop_approval_data && prof.stop_approval_data.stop_approval.id === stopApprovalId,
        ),
    );
  }
  return containers.some(
    (container) => container.known_profiles.some(
      (prof) => prof.stop_approval_data && prof.stop_approval_data.id === stopApprovalId,
    )
      || container.potential_profiles.some(
        (prof) => prof.stop_approval_data && prof.stop_approval_data.id === stopApprovalId,
      ),
  );
}

function findNextStopApproval(inventoryList, approvalId, useCCIDStorage) {
  let stopApprovals = [];
  if (useCCIDStorage) {
    stopApprovals = inventoryList.filter((sa) => sa.approval.id === approvalId).sort((a, b) => a.stop_approval.id - b.stop_approval.id);
  } else {
    stopApprovals = inventoryList.filter((sa) => sa.approval.id === approvalId).sort((a, b) => a.id - b.id);
  }
  if (stopApprovals.length > 1) {
    return stopApprovals[1];
  }
  return null;
}

function updateOnDeleteApproval(allContainers, approval, useCCIDStorage, inventoryList) {
  let containers = allContainers;
  const { approvalId } = approval;
  if (containers && stopApprovalPresentInContainers(containers, approval.stopApprovalId, useCCIDStorage)) {
    const nextStopApproval = findNextStopApproval(inventoryList, approvalId, useCCIDStorage);
    if (nextStopApproval) {
      if (useCCIDStorage) {
        containers = updateAccumulationContainerWithStopApproval(containers, { ...nextStopApproval.stop_approval, approval_id: approvalId }, useCCIDStorage, true);
      } else {
        containers = updateAccumulationContainerWithStopApproval(containers, { ...nextStopApproval, approval_id: approvalId }, useCCIDStorage, true);
      }
    } else {
      containers = updateAccumulationContainerWithStopApproval(containers, { approval_id: approvalId }, useCCIDStorage, false, true);
    }
  }
  return containers;
}
const state = {
  hasPickupData: {},
  stopListsArray: [],
  stopActive: {},
  stopType: false,
  tripDetail: {
    trip_name: '',
  },
  showStopActiveDialog: false,
  beginStopInstructionDialog: false,
  stopDetail: setDefaultStopDetail(),
  currentStopActive: false,
  stopArriveCancelled: false,
  enableAddRxBtn: false,
  stopArrived: false,
  stopActiveStatus: false,
  completeStop: {
    completed: false,
    qa_completed: false,
    sorting_completed: false,
    generator_signed: false,
  },
  stopListError: '',
  navList: getNavList(),
  activeTripAndStopName: {
    activeTrip: '',
    activeStop: '',
    stopName: '',
    activeStopId: '',
  },
  activeTripDetail: {},
  mobileActiveStopDetails: {},
  upcScanResponse: {
    status: '',
    productInfo: {
      product_name: '',
      bin_name: '',
      upc: '',
    },
  },
  useCCIDStorage: null,
  incompletedStopList: [],
  showCompleteTripModal: false,
  completeTripModalType: '',
  beginStopAction: false,
  lastStopData: setDefaultLastStopData(),
  expectedPickupList: [],
  accumulationPickupList: [],
  mostOccurringProfiles: [],
  accumulationContainerList: [],
  stopInventoryActiveTab: null,
  stopVoidStatus: false,
  voidedStopsInTrip: [],
  retailSisTotalWeight: null,
  truckSisTotalWeight: null,
  knownProfiles: [],
  potentialProfiles: [],
  stopSummaryKnownProfiles: [],
  stopSummaryPotentialProfiles: [],
  showFirstTimeNoticeFlag: false,
  accumulationApiCallStatus: '',
  accumulationContainerApiCallStatus: '',
  openedContainerId: null,
  approvalAddedSuccessfully: false,
};

function updateStopListForActiveStop(stateObj, stopId) {
  stateObj.stopListsArray.forEach((stop) => {
    if (stopId === stop.id) {
      const activeStop = {
        is_active: true,
      };
      Object.assign(stop, activeStop);
    }
  });
}

function stopArriveSuccessAction(commit, dispatch, stateObj, stopId, res) {
  updateStopListForActiveStop(stateObj, stopId);
  const messageData = 'Stop arrived successfully';
  toastMessage.showToastErrorMessage(commit, messageData, 'success');
  commit('setStopActiveStatus', true);
  commit('setStopArrive', true);
  dispatch('getActiveTripAction', res.stop.trip.user_id);
}

function stopArriveFailAction(commit, error) {
  toastMessage.showToastErrorMessage(commit, error, 'error');
  commit('setStopArrive', false);
  commit('setStopActiveStatus', false);
}

function calculateCombineKnownProfiles(profiles) {
  const combineData = [];
  profiles.forEach((profile) => {
    const prof = combineData.find((data) => data.manifestProfileId === profile.manifest_profile_id);
    if (prof) {
      prof.retailSisWeight += profile.weight;
      Object.assign(combineData, prof);
    } else {
      combineData.push({
        manifestProfileId: profile.manifest_profile_id,
        retailSisWeight: profile.weight,
        approvalDescription: profile.profile,
        masterId: profile.master_id,
        approvalId: profile.approval_data && profile.approval_data.id,
      });
    }
  });
  return combineData;
}

function mergeRepeatedProfileData(profiles) {
  const mergedData = [];
  profiles.forEach((prof) => {
    const rowData = mergedData.find((data) => data.approvalDescription === prof.approvalDescription);
    if (rowData) {
      rowData.retailSisWeight += prof.retailSisWeight;
    } else {
      mergedData.push({
        approvalDescription: prof.approvalDescription,
        retailSisWeight: prof.retailSisWeight,
        masterId: prof.masterId,
      });
    }
  });
  return mergedData;
}

function calculateCombinePotentialProfiles(profs) {
  const combineData = [];
  profs.forEach((profile) => {
    const prof = combineData.find((data) => data.masterId === profile.master_id);
    if (prof) {
      if (!prof.approvalDescription.split(' (OR)\n').includes(profile.profile)) {
        prof.approvalDescription = `${prof.approvalDescription} (OR)\n${profile.profile}`;
      }
    } else {
      combineData.push({
        approvalDescription: profile.profile,
        retailSisWeight: profile.weight,
        masterId: profile.master_id,
      });
    }
  });
  return mergeRepeatedProfileData(combineData);
}

function calculateTotalPotentialWeight(containers) {
  let totalWeight = 0.0;
  containers.forEach((container) => {
    const masterIds = new Set();
    container.potential_profiles.forEach((profile) => {
      if (!masterIds.has(profile.master_id)) {
        masterIds.add(profile.master_id);
        totalWeight += profile.weight;
      }
    });
  });
  return totalWeight;
}

function calculateTotalKnownWeight(profiles) {
  return profiles.reduce((total, currentProfile) => total + currentProfile.weight, 0.0);
}

function calculateUnknownWeight(containers) {
  return containers.reduce((total, container) => total + container.unknown_item_weight, 0.0);
}

function updateProfilesTruckSisWeight(res, commit, knownProfiles, potentialProfiles) {
  res.forEach((profile) => {
    const knownProfile = knownProfiles.find((prof) => prof.approvalId === profile.id);
    const profileWeight = parseFloat(profile.weight).toFixed(1);
    if (knownProfile) {
      const prof = knownProfile;
      prof.truckSisWeight = profileWeight;
      Object.assign(knownProfile, prof);
    } else {
      knownProfiles.push({
        approvalDescription: profile.description,
        truckSisWeight: profileWeight,
        approvalId: profile.id,
      });
    }
  });
  commit('setStopSummaryKnownProfiles', knownProfiles);
  commit('setStopSummaryPotentialProfiles', potentialProfiles);
}

function getUniqueProfilesList(profiles) {
  return [...new Map(profiles.map((profile) => [profile.manifest_profile_id, profile])).values()];
}

function filterOutProfilesPresentInKnownProfiles(profs, profilePresentInKnownProfiles) {
  let profiles = profs;
  profiles = getUniqueProfilesList(profiles);
  profiles = profiles.filter((profile) => !profilePresentInKnownProfiles.includes(profile.manifest_profile_id));
  return profiles;
}

function filterProfileOnContainer(containers) {
  const filteredContainer = JSON.parse(JSON.stringify(containers));
  filteredContainer.forEach((container) => {
    const cntr = container;
    cntr.known_profiles = getUniqueProfilesList(container.known_profiles);
    const knownProfilesId = container.known_profiles.map((profile) => profile.manifest_profile_id);
    cntr.potential_profiles = filterOutProfilesPresentInKnownProfiles(container.potential_profiles, knownProfilesId);
  });
  return filteredContainer;
}

const getters = {
  getStopLists(context) {
    return context.stopListsArray.filter(
      (stop) => stop.is_completed === context.stopType,
    );
  },
  getStopListsArray(context) {
    return context.stopListsArray;
  },
  getPickUpData: (context) => (id) => ({ ...context.hasPickupData[id] }),
  getStopActive: (context) => ({ ...context.stopActive }),
  getTripDetail(stateControl) {
    return stateControl.tripDetail;
  },
  getStopActiveWarning(context) {
    return context.showStopActiveWarning;
  },
  getStopArriveCancel(stateControl) {
    return stateControl.stopArriveCancelled;
  },
  getStopDetail(stateControl) {
    return stateControl.stopDetail;
  },
  getEnableAddRxBtn(stateControl) {
    return stateControl.enableAddRxBtn;
  },
  getStopActiveStatus(stateControl) {
    return stateControl.stopActiveStatus;
  },
  getCompleteStop(stateControl) {
    return stateControl.completeStop;
  },
  getStopNavList(stateControl) {
    return stateControl.navList;
  },
  getActiveTripAndStopName(stateControl) {
    return stateControl.activeTripAndStopName;
  },
  getActiveTripDetail(stateControl) {
    return stateControl.activeTripDetail;
  },
  getMobileActiveStopDetails(stateControl) {
    return stateControl.mobileActiveStopDetails;
  },
  getUpcScanResponse(stateControl) {
    return stateControl.upcScanResponse;
  },
  getUseCCIDStorage(stateControl) {
    return stateControl.useCCIDStorage;
  },
  getIncompletedStopList(stateControl) {
    return stateControl.incompletedStopList.filter((stop) => !stop.is_active && !stop.is_completed);
  },
  getActiveStopList(stateControl) {
    return stateControl.incompletedStopList.filter((stop) => stop.is_active && !stop.is_completed);
  },
  getShowCompleteTripModal(stateControl) {
    return stateControl.showCompleteTripModal;
  },
  getCompleteTripModalType(stateControl) {
    return stateControl.completeTripModalType;
  },
  getBeginStopAction(stateControl) {
    return stateControl.beginStopAction;
  },
  getLastStopData(stateControl) {
    return stateControl.lastStopData;
  },
  getExpectedPickupList(stateControl) {
    return stateControl.expectedPickupList;
  },
  getAccumulationContainerList(stateControl) {
    return stateControl.accumulationContainerList;
  },
  getAccumulationPickupList(stateControl) {
    return stateControl.accumulationPickupList;
  },
  getMostOccurringProfiles(stateControl) {
    return stateControl.mostOccurringProfiles;
  },
  getStopVoidStatus(stateControl) {
    return stateControl.stopVoidStatus;
  },
  getVoidedStopsInTrip(stateControl) {
    return stateControl.voidedStopsInTrip;
  },
  getStopInventoryActiveTab(stateControl) {
    return stateControl.stopInventoryActiveTab;
  },
  getRetailSisTotalWeight(stateControl) {
    return stateControl.retailSisTotalWeight;
  },
  getTruckSisTotalWeight(stateControl) {
    return stateControl.truckSisTotalWeight;
  },
  getKnownProfiles(stateControl) {
    return stateControl.knownProfiles;
  },
  getPotentialProfiles(stateControl) {
    return stateControl.potentialProfiles;
  },
  getFirstTimeNoticeFlag(stateControl) {
    return stateControl.showFirstTimeNoticeFlag;
  },
  getStopSummaryKnownProfiles(stateControl) {
    return stateControl.stopSummaryKnownProfiles;
  },
  getStopSummaryPotentialProfiles(stateControl) {
    return stateControl.stopSummaryPotentialProfiles;
  },
  getAccumulationApiCallStatus(stateControl) {
    return stateControl.accumulationApiCallStatus;
  },
  getAccumulationContainerApiCallStatus(stateControl) {
    return stateControl.accumulationContainerApiCallStatus;
  },
  getOpenedContainerId(stateControl) {
    return stateControl.openedContainerId;
  },
  getApprovalAddedSuccessfully(stateControl) {
    return stateControl.approvalAddedSuccessfully;
  },
};
const mutations = {
  createPickupData(stateControl, pickupData) {
    const stateControld = stateControl;
    if (pickupData.stopId) {
      stateControld.hasPickupData[pickupData.stopId] = pickupData;
    } else {
      stateControld.hasPickupData = pickupData;
    }
  },
  setStopListsData(stateControl, stopLists) {
    const stateControld = stateControl;
    stateControld.stopListsArray = stopLists;
  },
  createActiveStop(stateControl, stopActiveData) {
    const stateControld = stateControl;
    stateControld.stopActive[stopActiveData.id] = stopActiveData;
  },
  setStopTypes(stateControl, type) {
    const stateControld = stateControl;
    stateControld.stopType = type;
  },
  setTripDetail(stateControl, tripDetail) {
    const stateControld = stateControl;
    stateControld.tripDetail = tripDetail;
  },

  showStopActiveWarning(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.showStopActiveDialog = payload;
  },
  showBeginStopInstruction(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.beginStopInstructionDialog = payload;
  },
  setCurrentStopActive(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.currentStopActive = payload;
  },
  setStopDetail(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopDetail = payload;
  },
  setStopArriveCancel(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopArriveCancelled = payload;
  },
  setStopArrive(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopArrived = payload;
  },
  setStopActiveStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopActiveStatus = payload;
  },
  setAddRxButtonActive(stateControl) {
    const stateControld = stateControl;
    stateControld.enableAddRxBtn = true;
  },
  setAddRxButtonInactive(stateControl) {
    const stateControld = stateControl;
    stateControld.enableAddRxBtn = false;
  },
  setCompleteStop(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.completeStop = payload;
  },
  stopListError(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopListError = payload;
  },
  updateNavListValue(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.navList[payload.index][payload.field] = payload.value;
  },
  setActiveTripAndStopName(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.activeTripAndStopName = payload;
  },
  setActiveTripDetail(stateControl, trip) {
    const stateControld = stateControl;
    stateControld.activeTripDetail = trip;
  },
  setMobileActiveStopDetails(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.mobileActiveStopDetails = payload;
  },
  setUpcScanResponse(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.upcScanResponse = payload;
  },
  updateUseCCIDStorage(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.useCCIDStorage = payload;
  },
  setIncompletedStopList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.incompletedStopList = payload;
  },
  setShowCompleteTripModal(stateControl, payload) {
    const stateControld = stateControl;
    const type = payload.anyActiveStop ? 'ActiveStop' : 'CompleteStop';
    stateControld.showCompleteTripModal = payload.openModal;
    stateControld.completeTripModalType = type;
  },
  updateBeginStopAction(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.beginStopAction = payload;
  },
  updateLastStopData(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.lastStopData = payload;
  },
  resetLastStopData(stateControl) {
    const stateControld = stateControl;
    stateControld.lastStopData = setDefaultLastStopData();
  },
  setExpectedPickupList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.expectedPickupList = payload;
  },
  resetExpectedPickupList(stateControl) {
    const stateControld = stateControl;
    stateControld.expectedPickupList = [];
  },
  setAccumulationContainerList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.accumulationContainerList = payload;
  },
  resetAccumulationContainerList(stateControl) {
    const stateControld = stateControl;
    stateControld.accumulationContainerList = [];
  },
  setAccumulationPickupList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.accumulationPickupList = payload;
  },
  resetAccumulationPickupList(stateControl) {
    const stateControld = stateControl;
    stateControld.accumulationPickupList = [];
  },
  setMostOccurringProfiles(stateControl, profiles) {
    const stateControld = stateControl;
    stateControld.mostOccurringProfiles = profiles;
  },
  resetMostOccuringProfiles(stateControl) {
    const stateControld = stateControl;
    stateControld.mostOccurringProfiles = [];
  },
  setStopVoidStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopVoidStatus = payload;
  },
  setVoidedStopsInTrip(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.voidedStopsInTrip = payload;
  },
  setStopInventoryActiveTab(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopInventoryActiveTab = payload;
  },
  setRetailSisTotalWeight(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.retailSisTotalWeight = payload;
  },
  setTruckSisTotalWeight(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.truckSisTotalWeight = payload;
  },
  setKnownProfiles(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.knownProfiles = payload;
  },
  setPotentialProfiles(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.potentialProfiles = payload;
  },
  setFirstTimeNoticeFlag(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.showFirstTimeNoticeFlag = payload;
  },
  setStopSummaryPotentialProfiles(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopSummaryPotentialProfiles = payload;
  },
  setStopSummaryKnownProfiles(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopSummaryKnownProfiles = payload;
  },
  resetStopSummaryPotentialProfiles(stateControl) {
    const stateControld = stateControl;
    stateControld.stopSummaryPotentialProfiles = [];
  },
  resetStopSummaryKnownProfiles(stateControl) {
    const stateControld = stateControl;
    stateControld.stopSummaryKnownProfiles = [];
  },
  setAccumulationApiCallStatus(stateControl, status) {
    const stateControld = stateControl;
    stateControld.accumulationApiCallStatus = status;
  },
  setAccumulationContainerApiCallStatus(stateControl, status) {
    const stateControld = stateControl;
    stateControld.accumulationContainerApiCallStatus = status;
  },
  setOpenedContainerId(stateControl, containerId) {
    const stateControld = stateControl;
    stateControld.openedContainerId = containerId;
  },
  setApprovalAddedSuccessfully(stateControl, flag) {
    const stateControld = stateControl;
    if (stateControld.openedContainerId) {
      stateControld.approvalAddedSuccessfully = flag;
    }
  },
};
const actions = {
  stopArriveAction({ commit, dispatch, state: stateObj }, stopId) {
    api
      .stopArrive(stopId)
      .then((res) => {
        stopArriveSuccessAction(commit, dispatch, stateObj, stopId, res);
      })
      .catch((err) => {
        stopArriveFailAction(commit, err);
      });
  },
  pickupDataAction(context, stopId) {
    return new Promise((resolve, reject) => {
      const payload = {};
      payload.pickupData = 'pickupdata';
      payload.stopId = stopId;
      api
        .addStopPickUpData(payload, stopId)
        .then((res) => {
          const datas = res.json();
          datas.then((data) => {
            context.commit('createPickupData', data);
            resolve(res);
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  cancelArriveAction({ commit }, checkPickupData) {
    return new Promise((resolve, reject) => {
      const stopId = checkPickupData.id;
      if (checkPickupData.status) {
        api
          .removePickUpData(checkPickupData.pickupId)
          .then(() => {
            api
              .cancelStopArrivel(stopId)
              .then((res) => {
                commit('createPickupData', {});
                resolve(res.json());
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        api
          .cancelStopArrivel(stopId)
          .then((res) => {
            resolve(res.json());
          })
          .catch((err) => {
            reject(err);
          });
      }
    });
  },
  getStopDetailsAction({ commit, dispatch }, tripId) {
    return new Promise((resolve, reject) => {
      api
        .getStopsByTrip(tripId)
        .then((res) => {
          let stopsList = res.json();
          stopsList.then((stops) => {
            dispatch('filterActiveStop', stops);
            stopsList = stops.trip ? stops.trip.stops : [];
            commit('setStopListsData', stopsList);
            commit('setTripDetail', stops.trip);

            resolve(stops);
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  changeStopActive({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .stopActive(payload, payload.id)
        .then((res) => {
          commit('createActiveStop', payload);
          dispatch('getStopDetailsAction', payload.tripId)
            .then(() => {
              resolve(res.json());
            })
            .catch((err) => {
              reject(err);
            });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  filterActiveStop({ commit }, stops) {
    if (stops && stops.stops && stops.stops.length > 0) {
      stops.stops.forEach((stop) => {
        if (stop.stop_active) {
          commit('createActiveStop', stop);
        }
      });
    }
  },
  setStopListsData(context, payload) {
    context.commit('setStopListsData', payload);
  },
  updateActiveStop({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      api
        .stopActive(payload, payload.id)
        .then((res) => {
          dispatch('getStopDetailsAction', payload.tripId);
          commit('createActiveStop', {});
          resolve(res.json());
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  setStopTypesAction(context, type) {
    context.commit('setStopTypes', type);
  },
  showStopActiveWarning(context, payload) {
    context.commit('showStopActiveWarning', payload);
  },
  showBeginStopInstruction(context, payload) {
    context.commit('showBeginStopInstruction', payload);
  },
  setStopVoidStatus(context, payload) {
    api.getStopVoidStatus(payload).then((res) => {
      context.commit('setStopVoidStatus', res);
    });
  },
  setVoidedStopsInTrip(context, payload) {
    api.getVoidedStopsInTrip(payload).then((res) => {
      const stopIds = context.state.voidedStopsInTrip;
      const isSame = (stopIds.length === res.length)
        && stopIds.every((element) => res.includes(element));
      if (!isSame) {
        context.commit('setVoidedStopsInTrip', res);
      }
    });
  },
  getStopDetailAction({ commit, dispatch, state: stateObj }, payload) {
    const { stopID, type } = payload;
    api.getStopByID(stopID).then((stopDetail) => {
      if (
        stopDetail.active_stop
        && stopDetail.active_stop.id !== stopDetail.stop.id
      ) {
        const stopNameTripName = {
          activeTrip: stopDetail.active_stop.trip.trip_code,
          activeStop: stopDetail.active_stop.stop_number,
          stopName: stopDetail.active_stop.generator.name,
          activeStopId: stopDetail.active_stop.id,
        };
        commit('setActiveTripAndStopName', stopNameTripName);
      }
      commit('setStopDetail', stopDetail.stop);
      stopDetail.stop.stop_type.forEach((value) => {
        if (value.toLowerCase() === 'fs') {
          commit('setAddRxButtonInactive');
        } else {
          commit('setAddRxButtonActive');
        }
      });
      if (type === 'popup') {
        if (stopDetail.active_stop && !stopDetail.stop.is_completed) {
          if (stopDetail.active_stop.id === stopDetail.stop.id) {
            commit('setCurrentStopActive', true);
            commit('setStopActiveStatus', true);
            updateStopListForActiveStop(stateObj, stopID);
          } else {
            commit('createActiveStop', stopDetail.active_stop);
            commit('setStopDetail', stopDetail.active_stop);
            dispatch('showStopActiveWarning', true);
            commit('setCurrentStopActive', false);
            commit('setStopActiveStatus', true);
          }
        } else if (stopDetail.stop && stopDetail.stop.is_completed) {
          commit('setCurrentStopActive', true);
          commit('setStopActiveStatus', true);
        } else if (!stopDetail.stop.connected_status
          || stopDetail.stop.connected_status === connectedStatus.NOT_CONNECTED) {
          dispatch('showBeginStopInstruction', {
            status: true,
            stopId: stopID,
          });
        } else {
          commit('updateBeginStopAction', true);
        }
      }
    });
  },
  stopArriveCancelAction({ commit }, stopId) {
    api
      .cancelArrive(stopId)
      .then(() => {
        const messageData = 'Stop arrive cancelled successfully';
        toastMessage.showToastErrorMessage(commit, messageData, 'success');
        commit('setStopArriveCancel', true);
        commit('alerts/setAlertsData', []);
      })
      .catch((err) => {
        toastMessage.showToastErrorMessage(commit, err, 'error');
        commit('setStopArriveCancel', false);
      });
  },
  updateTrip({ dispatch }, payload) {
    return new Promise((resolve) => {
      tripApi
        .updateTrip(payload, payload.userId, payload.tripCode)
        .then((res) => {
          if (res && res.trip) {
            resolve('tripUpdateSuccess');
            dispatch('getStopDetailsAction', res.trip.id);
          } else {
            resolve('tripUpdateFailed');
          }
        });
    });
  },
  getActiveTripAction({ commit }, techId) {
    tripApi.getActiveTrip(techId).then((response) => {
      if (response && response.active_trip) {
        commit('setActiveTripDetail', response.active_trip);
        if (
          !response.active_trip.storage_method
          || response.active_trip.storage_method === tripStorage.storageMethods.not_selected
        ) {
          commit('trip/setStorageSelectedForTrip', false);
        } else {
          const useCCIDStorage = response.active_trip.storage_method === tripStorage
            .storageMethods.use_ccid;
          commit('trip/setStorageSelectedForTrip', true);
          commit('updateUseCCIDStorage', useCCIDStorage);
        }
        if (response.active_trip.stop) {
          commit('setStopDetail', response.active_trip.stop);
          commit('setStopActiveStatus', true);
        } else {
          commit('setStopActiveStatus', false);
          commit('setStopDetail', setDefaultStopDetail);
        }
      } else {
        commit('setActiveTripDetail', {});
        commit('setStopActiveStatus', false);
        commit('setStopDetail', setDefaultStopDetail);
      }
    });
  },
  checkStopCompleteStatus(context, payload) {
    return new Promise((resolve) => {
      api
        .checkStopCompleteStatusByStopId(payload)
        .then((res) => {
          resolve();
          context.commit('setCompleteStop', res);
        })
        .catch((err) => {
          toastMessage.showToastErrorMessage(context.commit, err, 'error');
        });
    });
  },
  completeStopAction(context, payload) {
    return new Promise((resolve) => {
      api
        .stopCompleteByStopId(payload)
        .then(() => {
          resolve();
        })
        .catch((err) => {
          toastMessage.showToastErrorMessage(context.commit, err, 'error');
        });
    });
  },
  updateGeneratorSignNameAction({ commit, dispatch }, payload) {
    api
      .updateGeneratorSignName(payload)
      .then(() => {
        dispatch('getStopDetailAction', { stopID: payload.stopID, type: '' });
      })
      .catch((err) => {
        toastMessage.showToastErrorMessage(commit, err, 'error');
      });
  },
  mobileStopList({ commit }, payload) {
    fireStoreStopService.getAllStops(commit, payload);
  },
  resetPreviousStopDetails(context, tripId) {
    if (context.state.tripId !== tripId) {
      context.commit('rxApproval/setSelectedEditApproval', {}, { root: true });
      context.commit('rxContent/resetContent', { root: true });
      context.commit('rxApproval/resetApprovalDetail', {}, { root: true });
    }
    context.commit('setSelectedInventoryApproval', {}, { root: true });
    context.commit('resetApprovalDetail');
    context.commit('container/resetContainerType', { root: true });
    context.commit('previousTripId', tripId);
  },
  updateStopStatus(context, payload) {
    fireStoreStopService.updateStopStatus(payload);
  },
  fetchActiveStopDetail(context, stopId) {
    fireStoreStopService.fetchActiveStopDetail(context, stopId);
  },
  getUPCDetail({ commit }, payload) {
    return new Promise((resolve) => {
      scanService.mobileBarCodeScan(payload).then(
        (resp) => {
          const approval = resp.master_bin_rule.bin_name.split('-');
          const status = approval.length !== 0 ? 'success' : 'noApproval';
          commit('setUpcScanResponse', {
            status,
            productInfo: {
              productName: resp.product_name,
              approvalName: approval[0].trim(),
              approvalCode: approval[1].trim(),
              upc: resp.upc,
            },
          });
          resolve(resp);
        },
        (error) => {
          commit('setUpcScanResponse', {
            status: 'unknownItem',
            productInfo: {
              productName: '',
              approvalName: '',
              upc: '',
            },
          });
          resolve(error);
        },
      );
    });
  },
  firstStopArriveAction({ commit, dispatch, state: stateObj }, params) {
    api
      .firstStopArrive(params)
      .then((res) => {
        stopArriveSuccessAction(commit, dispatch, stateObj, params.stopId, res);
        const useCCIDStorage = res.stop.trip.storage_method === tripStorage.storageMethods.use_ccid;
        commit('updateUseCCIDStorage', useCCIDStorage);
        commit('trip/setStorageSelectedForTrip', true);
      })
      .catch((err) => {
        stopArriveFailAction(commit, err);
      });
  },
  openCompletedTripModal({ commit }, tripId) {
    api.getIncomopletedStops(tripId).then((res) => {
      commit('setIncompletedStopList', res.stops);
      const anyActiveStop = res.stops.some((stop) => stop.is_active);
      commit('setShowCompleteTripModal', { openModal: true, anyActiveStop });
    });
  },
  completeCurrentTrip(context, payload) {
    return new Promise((resolve) => {
      tripApi.updateTripStatus(payload).then((res) => resolve(res));
    });
  },
  tripStatusChangeToaster({ commit }, payload) {
    let message;
    if (payload.type === 'completed') {
      message = `<div class="custom-toast-inprogress-text-div"><p class="trip-completed-toast-content">Trip successfully completed.</p><p class="custom-toast-content" style="margin-top: 8px">Trip id: ${payload.tripCode}</p></div>`;
    } else {
      message = `<div class="custom-toast-inprogress-text-div"><p class="trip-completed-toast-content">Trip changed to 'In progress'.</p><p class="custom-toast-content" style="margin-top: 8px">Trip id: ${payload.tripCode}</p></div>`;
    }
    toastMessage.showToastErrorMessage(commit, message, 'inProgress', true, 5);
  },
  updateStopArrivedTime({ dispatch }, payload) {
    api.updateStopStartTime(payload).then(() => {
      dispatch('getStopDetailAction', { stopID: payload.stop_id, type: '' });
    });
  },
  getLastStopData({ commit }, stopId) {
    api.getLastStopData(stopId).then((res) => {
      commit('updateLastStopData', res);
    });
  },
  getExpectedPickupList({ commit }, stopId) {
    api.getExpectedPickupList(stopId).then((res) => {
      commit('setExpectedPickupList', res);
    });
  },
  getAccumulationContainerList({ commit, dispatch, state: stateObj }, stopId) {
    if (stateObj.accumulationContainerList.length > 1 || stateObj.accumulationContainerApiCallStatus === apiResponse.status.SUCCESS || stateObj.accumulationContainerApiCallStatus === apiResponse.status.IN_PROGRESS) {
      return;
    }
    commit('setAccumulationContainerApiCallStatus', apiResponse.status.IN_PROGRESS);
    api.getAccumulationContainerList(stopId).then((res) => {
      const accumulationContainerArray = [];
      const storedData = JSON.parse(localStorage.getItem('accumulatedProfileActiveStatus'));
      const filteredContainers = filterProfileOnContainer(res);
      if (storedData) {
        filteredContainers.forEach((container) => {
          container.known_profiles.forEach((profile) => {
            const profileObj = storedData.find((localData) => profile.manifest_profile_id
            === localData.manifestProfileId
            && container.container_id === localData.containerId);
            if (profileObj) {
              Object.assign(profile, { profile_present_flag: profileObj.profilePresentFlag });
            } else {
              Object.assign(profile, { profile_present_flag: true });
            }
            accumulationContainerArray
              .push({
                manifestProfileId: profile.manifest_profile_id,
                containerId: container.container_id,
                profilePresentFlag: true,
              });
          });
          container.potential_profiles.forEach((profile) => {
            const profileObj = storedData.find((localData) => profile.manifest_profile_id
            === localData.manifestProfileId
            && container.container_id === localData.containerId);
            if (profileObj) {
              Object.assign(profile, { profile_present_flag: profileObj.profilePresentFlag });
            } else {
              Object.assign(profile, { profile_present_flag: true });
            }
            accumulationContainerArray
              .push({
                manifestProfileId: profile.manifest_profile_id,
                containerId: container.container_id,
                profilePresentFlag: true,
              });
          });
        });
      } else {
        res.forEach((container) => {
          container.known_profiles.forEach((profile) => {
            accumulationContainerArray
              .push({
                manifestProfileId: profile.manifest_profile_id,
                containerId: container.container_id,
                profilePresentFlag: true,
              });
            Object.assign(profile, { profile_present_flag: true });
          });
          container.potential_profiles.forEach((profile) => {
            accumulationContainerArray
              .push({
                manifestProfileId: profile.manifest_profile_id,
                containerId: container.container_id,
                profilePresentFlag: true,
              });
            Object.assign(profile, { profile_present_flag: true });
          });
        });
      }
      localStorage.setItem('accumulatedProfileActiveStatus', JSON.stringify(accumulationContainerArray));
      commit('setAccumulationContainerList', filteredContainers);
      commit('setAccumulationContainerApiCallStatus', apiResponse.status.SUCCESS);
      dispatch('updateRetailSisSummaryData', res);
    }).catch(() => {
      commit('setAccumulationContainerApiCallStatus', apiResponse.status.FAILED);
    });
  },
  getAccumulationPickupList({ commit }, stopId) {
    commit('setAccumulationApiCallStatus', apiResponse.status.IN_PROGRESS);
    api.getAccumulationPickupList(stopId).then((res) => {
      commit('setAccumulationApiCallStatus', apiResponse.status.SUCCESS);
      commit('setAccumulationPickupList', res);
    }).catch(() => {
      commit('setAccumulationApiCallStatus', apiResponse.status.FAILED);
    });
  },
  getMostOccurringProfiles({ commit }, stopId) {
    api.getMostOccurringProfiles(stopId).then((res) => {
      const profileActiveStatusArray = [];
      let data = {};
      const storedData = JSON.parse(localStorage.getItem('profileActiveStatus'));
      if (storedData) {
        res.forEach((obj) => {
          const profileObj = storedData
            .find((localData) => obj.manifest_profile_id === localData.manifestProfileId);
          if (profileObj) {
            Object.assign(obj, { profile_present_flag: profileObj.profilePresentFlag });
          } else {
            Object.assign(obj, { profile_present_flag: true });
          }
          data = {
            manifestProfileId: obj.manifest_profile_id,
            profilePresentFlag: obj.profile_present_flag,
          };
          profileActiveStatusArray.push(data);
        });
      } else {
        res.forEach((obj) => {
          data = {
            manifestProfileId: obj.manifest_profile_id,
            profilePresentFlag: true,
          };
          Object.assign(obj, { profile_present_flag: true });
          profileActiveStatusArray.push(data);
        });
      }
      localStorage.setItem('profileActiveStatus', JSON.stringify(profileActiveStatusArray));
      commit('setMostOccurringProfiles', res);
    });
  },
  updateRetailSisSummaryData({ commit }, res) {
    let knownProfiles = res.map((container) => container.known_profiles).flat();
    let potentialProfiles = res.map((container) => container.potential_profiles).flat();
    const knownWeight = Number(calculateTotalKnownWeight(knownProfiles).toFixed(2));
    const potentialWeight = Number(calculateTotalPotentialWeight(res).toFixed(2));
    const unknownWeight = Number(calculateUnknownWeight(res).toFixed(2));
    knownProfiles = calculateCombineKnownProfiles(knownProfiles);
    potentialProfiles = calculateCombinePotentialProfiles(potentialProfiles);
    if (unknownWeight > 0) {
      potentialProfiles.push({
        approvalDescription: 'Unknown items',
        retailSisWeight: unknownWeight,
      });
    }
    commit('setRetailSisTotalWeight', knownWeight + potentialWeight + unknownWeight);
    commit('setKnownProfiles', knownProfiles);
    commit('setPotentialProfiles', potentialProfiles);
  },

  getAllStopApprovalsWithWeight({ commit, state: stateObj }, stopId) {
    commit('resetStopSummaryPotentialProfiles');
    commit('resetStopSummaryKnownProfiles');
    api.getTotalStopApprovalWeight(stopId).then((res) => {
      const totalTruckSisWeight = res.reduce((total, data) => parseFloat(data.weight) + total, 0.0);
      commit('setTruckSisTotalWeight', totalTruckSisWeight);
      updateProfilesTruckSisWeight(res, commit, stateObj.knownProfiles, stateObj.potentialProfiles);
    });
  },

  setFirstTimeNoticeFlag({ commit }, stopId) {
    api.getFirstPickup(stopId).then((res) => {
      commit('setFirstTimeNoticeFlag', res.is_first_pickup);
    });
  },
  resetApprovalAddedFromContainer({ commit }) {
    commit('setOpenedContainerId', null);
    commit('setApprovalAddedSuccessfully', false);
  },
  updateAccumulationData({ commit, state: stateObj }, stopApprovalData) {
    const updatedData = updateAccumulationContainerWithStopApproval(stateObj.accumulationContainerList, stopApprovalData, stateObj.useCCIDStorage);
    commit('setAccumulationContainerList', updatedData);
  },
  updateAccumulationDataOnApprovalDelete({ commit, state: stateObj, rootState: rootStateObj }, stopApprovalData) {
    const { stopApprovalId, approvalId } = stopApprovalData;
    const updatedContainers = updateOnDeleteApproval(stateObj.accumulationContainerList, { stopApprovalId, approvalId }, stateObj.useCCIDStorage, rootStateObj.inventoryApproval.stopInventoryLists);
    commit('setAccumulationContainerList', updatedContainers);
  },

};
export default {
  state,
  getters,
  actions,
  mutations,
};
