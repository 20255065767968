<template>
  <div class="mobile-login-header">
    <div class="text-center margin-top-10 hand-held-logo">
      <img src="src/assets/images/logo-lockup.png">
    </div>
    <div>
      <router-view />
    </div>
  </div>
</template>

<script>

export default {
  name: 'AuthHeaderMobile',
};
</script>
