import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';
import { version } from '../../package.json';
import store from '../store/index';

function getReleaseNotesStatus() {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}release_notes_status/${version}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getDownloadStatusOfUpdate() {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}update_status/${version}`;
  return fetchService.getFetchData(url, requestOptions);
}

function displayUpdateDownloadedToast() {
  const messageData = {
    message:
      '<div class="toast-container-div"> <p class="toast-container-div-title"> Software update ready </p> <p class="toast-container-div-message"> Please restart the TruckSIS to apply the update </p></div>',
    type: 'success',
  };
  store.dispatch('toastMessage/showAppUpdatedMessage', messageData);
  store.dispatch('setUpdateReadyToastDisplayed', true);
}

function checkIfUpdateHasFinishedDownloading() {
  if (!store.state.session.updateReadyToastDisplayed) {
    if (store.state.session.updateHasFinishedDownloading) {
      displayUpdateDownloadedToast();
    } else {
      getDownloadStatusOfUpdate().then((res) => {
        if (res.updated) {
          store.dispatch('setUpdateHasFinishedDownloading', true);
          store.dispatch('setDownloadInProgress', false);
          displayUpdateDownloadedToast();
        }
      });
    }
  }
}

function checkIfUpdateIsAvailable() {
  if (!store.state.session.updateIsAvailableForDownload) {
    const requestOptions = {
      method: 'GET',
    };
    const url = `${API_BASE_URL}update_check/${version}`;
    fetchService.getFetchData(url, requestOptions).then((res) => {
      if (res.update) {
        store.dispatch('setUpdateIsAvailableForDownload', true);
        store.dispatch('setDownloadInProgress', true);
        // Remove flag after 25 minutes in case the docker pull fails
        setTimeout(() => {
          store.dispatch('setDownloadInProgress', false);
        }, 1000 * 60 * 25);
        setTimeout(checkIfUpdateHasFinishedDownloading, 1000 * 60 * 3);
        setInterval(checkIfUpdateHasFinishedDownloading, 1000 * 60 * 10);
      }
    });
  }
}

function startUpdateChecks() {
  // First check to see if an update needs to be downloaded
  // occurs at 3 minutes to give cradlepoint time to connect
  setTimeout(checkIfUpdateIsAvailable, 1000 * 60 * 3);
  // The check occurs once every 10 minutes after that
  setInterval(checkIfUpdateIsAvailable, 1000 * 60 * 10);
}

function getVersion() {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}version`;
  return fetchService.getFetchData(url, requestOptions);
}

function getSerialNumber() {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}systems/serial_number`;
  return fetchService.getFetchData(url, requestOptions);
}

function updateReleaseNotesStatus() {
  const requestOptions = {
    method: 'POST',
  };
  const url = `${API_BASE_URL}update_release_notes_status`;
  return fetchService.getFetchData(url, requestOptions);
}

const updateCheckService = {
  getReleaseNotesStatus,
  startUpdateChecks,
  getVersion,
  getSerialNumber,
  getDownloadStatusOfUpdate,
  updateReleaseNotesStatus,
};
export default updateCheckService;
