<template>
  <div>
    <div
      class="stop-infos"
      v-if="stopDetail.generator"
    >
      <div class="cols d-flex flex-column mt-6">
        <div class="col-header">
          <label>Name</label>
        </div>
        <div class="val mt-1">
          <label>{{ stopDetail.generator.name }}</label>
        </div>
      </div>
      <div class="cols d-flex flex-column mt-6">
        <div class="col-header">
          <label>Address</label>
        </div>
        <div class="val mt-1">
          {{ stopDetail.generator.site_address }}
        </div>
      </div>
      <div class="cols-row d-flex flex-row mt-6">
        <div class="d-flex flex-column">
          <div class="col-header">
            <label>Phone</label>
          </div>
          <div class="val mt-1">
            <label> {{ stopDetail.generator.phone_number | phoneNumberFormat }}</label>
          </div>
        </div>
      </div>
      <div class="cols-row  d-flex flex-row mt-6">
        <div class="d-flex flex-column">
          <div class="col-header">
            <label>EPA ID</label>
          </div>
          <div class="val mt-1">
            <label>{{ stopDetail.generator.epaid }}</label>
          </div>
        </div>
        <div class="d-flex flex-column ml-6">
          <div class="col-header">
            <label>Site code</label>
          </div>
          <div class="val mt-1">
            <label>{{ stopDetail.generator.site_code }}</label>
          </div>
        </div>
      </div>
      <div
        class="mt-6 d-flex flex-column"
        v-if="stopDetail.has_data_created"
      >
        <div class="col-header">
          <label>Generator sign name</label>
        </div>
        <div class="val mt-1">
          <div
            v-if="maximumCharacterError"
            class="generator-signer-maximum-character-error"
          >
            <span class="forgot-font-weight">!</span>
            &nbsp; &nbsp; Maximum should be 20 character
          </div>
          <input
            v-if="checkProxyOfferFlag() && !stopDetail.is_completed"
            class="input"
            type="text"
            v-model="generatorSigner"
            :disabled="true"
          >
          <input
            v-else
            class="input"
            type="text"
            v-model="generatorSigner"
            autocomplete="off"
            :disabled="stopDetail.is_completed"
            @click="checkDialog('alphaNumeric','Generator signer',
                                'Generator Sign Name',generatorSigner)"
            @selectstart.prevent
            @paste.prevent
            @copy.prevent
            @cut.prevent
            @drag.prevent
            @drop.prevent
            v-unSelect
          >
        </div>
      </div>
      <div
        class="mt-6 d-flex flex-column"
        v-if="stopDetail.has_data_created"
      >
        <div class="col-header">
          <label>Generator sign date</label>
        </div>
        <div class="val mt-1">
          <label v-if="!stopDetail.generator_sign_date">-</label>
          <label v-else>{{ stopDetail.generator_sign_date | arrivedDate }}</label>
        </div>
      </div>
      <div class="mt-6 d-flex flex-column">
        <div class="col-header">
          <label>Arrived</label>
        </div>
        <div class="d-flex flex-row mt-1">
          <div>
            <input
              class="input val"
              type="text"
              :value="stopDetail.start_time | arrivedDate"
              @selectstart.prevent
              @paste.prevent
              @copy.prevent
              @cut.prevent
              @drag.prevent
              @drop.prevent
              v-unSelect
            >
          </div>
          <div class="ml-4">
            <input
              class="input val"
              type="text"
              :value="stopDetail.start_time | arrivedTime"
              @click="openArrivedTimeUpdateDialog"
              @selectstart.prevent
              @paste.prevent
              @copy.prevent
              @cut.prevent
              @drag.prevent
              @drop.prevent
              v-unSelect
            >
          </div>
          <div class="ml-4 undo">
            <button
              v-if="!stopDetail.has_data_created && !stopDetail.is_completed"
              class="btn-submit"
              @click="undoStop(stopDetail.id)"
            >
              Undo
            </button>
          </div>
        </div>
      </div>
      <div class="mt-6 d-flex flex-column complete-stop">
        <button
          class="btn-submit"
          @click="completeStop()"
          :class="{'complete-stop-disabled' : stopDetail.is_completed}"
          :disabled="stopDetail.is_completed"
        >
          Complete stop
        </button>
      </div>
      <complete-stop-confirmation-dialog
        :open-dialog="dialog"
        @close="closeDialog()"
      />
      <KeyboardDialog
        :open-dialog="pinDialogIsOpen"
        :keyboard-type="keyBoardType"
        :model-name="modelName"
        :label-name="labelName"
        :model-value="modelValue"
        @dialogInputChanged="dialogInputChanges"
        name="keyboard"
        @enterClicked="enterClicked"
      />
      <ArrivedTimeUpdate
        :open-dialog="arrivedTimeDialogIsOpen"
        :arrived-time="stopDetail.start_time"
        @arrivedTimeDialogInputChanged="arrivedTimeDialogInputChanged"
        @enterClicked="enterClicked"
      />
    </div>
    <div
      class="stop-detail-container"
      v-if="stopDetail.generator"
    >
      <div
        class="d-flex flex-column"
      >
        <div
          class="stop-detail-label"
        >
          <label>Address</label>
        </div>
        <div
          class="mobile-stop-detail"
        >
          {{ stopDetail.generator.site_address }}
        </div>
      </div>
      <div
        class="d-flex flex-column ma-tb-8"
      >
        <div
          class="stop-detail-label"
        >
          <label>Phone</label>
        </div>
        <div
          class="mobile-stop-detail"
        >
          <label>{{ stopDetail.generator.phone_number | phoneNumberFormat }}</label>
        </div>
      </div>
      <div
        class="d-flex ma-tb-8"
      >
        <div class="flex-w-1">
          <div
            class="stop-detail-label"
          >
            <label>EPA ID</label>
          </div>
          <div
            class="mobile-stop-detail"
          >
            <label>{{ stopDetail.generator.epa_id }}</label>
          </div>
        </div>
        <div class="flex-w-1">
          <div
            class="stop-detail-label"
          >
            <label>Site code</label>
          </div>
          <div
            class="mobile-stop-detail"
          >
            <label>{{ stopDetail.generator.site_code }}</label>
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-column ma-tb-8"
        v-if="stopDetail.start_time"
      >
        <div
          class="stop-detail-label"
        >
          <label>Arrived</label>
        </div>
        <div
          class="mobile-stop-detail"
        >
          <label>{{ stopDetail.start_time | arrivedDate }}
            {{ stopDetail.start_time | arrivedTime }}</label>
        </div>
      </div>
      <div
        class="d-flex flex-column ma-tb-8"
      >
        <div
          class="stop-detail-label"
        >
          <label>Notes</label>
        </div>
        <div
          class="mobile-stop-detail"
        >
          {{ stopDetail.comments ? stopDetail.comments : 'None.' }}
        </div>
      </div>
      <div
        class="d-flex flex-column ma-b-8"
      >
        <div
          class="stop-detail-label"
        >
          <label>Directions</label>
        </div>
        <div
          class="mobile-stop-detail"
        >
          {{ stopDetail.site_directions ? stopDetail.site_directions : 'None.' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import completeStopConfirmationDialog from '../../../helpers/component/complete-stop-confirmation-dialog/completeStopConfirmationDialog.vue';
import KeyboardDialog from '../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import clearAndSaveModalService from '../../../helpers/utils/clearAndSaveModalService';
import ArrivedTimeUpdate from '../../../helpers/component/virtual-keyboard/arrivalTimeUpdate.vue';
import connectedStopConstant from '../../../constants/connected-stop';

export default {
  name: 'StopInfoContent',
  components: {
    completeStopConfirmationDialog,
    KeyboardDialog,
    ArrivedTimeUpdate,
  },
  computed: {
    ...mapGetters({
      stopDetailChange: 'getStopDetail',
      modalType: 'leaveApproval/getModalType',
      technicianDetails: 'authentication/getUser',
    }),
  },
  data() {
    return {
      stopDetail: {
        start_time: '',
        generator: {
          name: '',
          site_address: '',
          phone_number: '',
          epa_id: '',
          site_code: '',
        },
      },
      dialog: false,
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      generatorSigner: '',
      modelValue: '',
      maximumCharacterError: false,
      arrivedTimeDialogIsOpen: false,
    };
  },
  created() {
    this.stopDetail = this.$store.state.stopDetail.stopDetail;
    this.generatorSigner = this.stopDetailChange.generator_sign_name;
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setStopArriveCancel') {
        if (state.stopDetail.stopArriveCancelled) {
          this.$router.push(`/stops/list/${this.stopDetail.trip_id}`);
        }
      }
    });
  },
  methods: {
    recordContact(stop) {
      const params = {};
      params.stopID = stop.id;
      params.tripID = stop.trip_id;
      params.type = 'view';
      this.$store.dispatch('contactTimestamp/recordContactAction', params);
    },
    removeContact(stop) {
      const params = {};
      params.stopID = stop.id;
      params.tripID = stop.trip_id;
      params.type = 'view';
      this.$store.dispatch('contactTimestamp/removeContactAction', params);
    },
    undoStop(stopID) {
      this.$store.dispatch('stopArriveCancelAction', stopID);
    },
    checkDialog(KeyboardType, modelName, labelName, value) {
      let modelValue = value;
      modelValue = modelValue || '';
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      if (value.modelName === 'Generator signer') {
        this.generatorSigner = value.input;
        if (value.input.length > 20) {
          this.maximumCharacterError = true;
        } else {
          this.maximumCharacterError = false;
          this.$store.dispatch('updateGeneratorSignNameAction',
            { stopID: this.stopDetail.id, generator_sign_name: this.generatorSigner });
        }
      } else {
        this.stopDetail.start_time = value.timeAndDate;
        this.$store.dispatch('updateStopArrivedTime', { stop_id: this.stopDetail.id, start_time: value.timeStr });
      }
    },
    completeStop() {
      const { approvalDetail } = this.$store.state.approvalList;
      const { rxApprovalDetail } = this.$store.state.rxApproval;
      if (approvalDetail.id
      && this.$route.name === 'Add Approval') {
        clearAndSaveModalService(this.$store, 'approval', '', '', 'complete-stop');
      } else if (this.$route.name === 'Consolidate') {
        clearAndSaveModalService(this.$store, 'consolidate', '', '', 'complete-stop');
      } else if (rxApprovalDetail.id
        && !rxApprovalDetail.editType
        && this.$route.name === 'Add Rx Items') {
        clearAndSaveModalService(this.$store, 'rx-approval', '', '', 'complete-stop');
      } else if (rxApprovalDetail.id
        && !rxApprovalDetail.editType
        && this.$route.name === 'RxConsolidate') {
        clearAndSaveModalService(this.$store, 'rxConsolidate', '', '', 'complete-stop');
      } else {
        if (this.stopDetailChange.connected_status
          === connectedStopConstant.status.ACCUMULATION_DATA_PRESENT) {
          this.$store.dispatch('getAllStopApprovalsWithWeight', this.stopDetailChange.id);
        }
        this.showCompleteStopDialog();
        this.resetLeaveApprovalModal();
      }
    },
    closeDialog() {
      this.dialog = false;
      this.resetLeaveApprovalModal();
    },
    resetLeaveApprovalModal() {
      this.$store.commit('leaveApproval/setModalType', '');
    },
    showCompleteStopDialog() {
      this.$store.dispatch('checkStopCompleteStatus', this.stopDetail.id).then(() => {
        this.dialog = true;
      });
    },
    checkProxyOfferFlag() {
      // eslint-disable-next-line max-len
      if (this.stopDetail.approved_offerer_flag && this.stopDetail.approved_offerer_effective_date !== null) {
        if (this.checkDocumentsForGeneratorSignature()) {
          return true;
        }
      }
      return false;
    },
    checkDocumentsForGeneratorSignature() {
      let flag = false;
      if (this.generatorSigner) {
        flag = true;
      } else {
        this.generatorSigner = `${this.technicianDetails.first_name} ${this.technicianDetails.last_name}`;
        this.$store.dispatch('updateGeneratorSignNameAction',
          { stopID: this.stopDetail.id, generator_sign_name: this.generatorSigner });
        flag = true;
      }
      return flag;
    },
    openArrivedTimeUpdateDialog() {
      this.arrivedTimeDialogIsOpen = true;
    },
    arrivedTimeDialogInputChanged(changes) {
      this.arrivedTimeDialogIsOpen = changes;
    },
  },
  watch: {
    stopDetailChange(newVal) {
      this.stopDetail = newVal;
      this.generatorSigner = newVal.generator_sign_name;
    },
    modalType(newVal) {
      if (newVal === 'complete-stop') {
        this.showCompleteStopDialog();
      }
    },
  },
};
</script>
