//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  required, email, minLength, maxLength,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import KeyboardDialog from '../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import emailPattern from '../../helpers/util';
import pinDialog from '../../helpers/component/pin-dialog/pinDialog.vue';
import technicianLastEmail from '../../helpers/component/technician-last-email/technicianLastEmail.vue';

const emailPatternValidation = (value) => emailPattern.test(value);
export default {
  name: 'CreateAccount',
  components: {
    KeyboardDialog,
    technicianLastEmail,
    pinDialog,
  },
  computed: {
    ...mapGetters({
      accountStatus: 'createAccount/getAccountStatus',
      checkEmailExists: 'createAccount/getExistingEmail',
      getEmail: 'createAccount/getEmail',
      getConfirmedPin: 'createAccount/getConfirmedPinValue',
    }),
  },
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phone1: '',
      },
      userExist: false,
      input: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      firstnameError: '',
      lastnameError: '',
      emailError: '',
      firstNameIfError: false,
      lastNameIfError: false,
      emailIfError: false,
      phoneNumberIfError: false,
      modelValue: '',
      submitted: false,
      showPinDialog: false,
    };
  },
  beforeMount() {
    this.$store.commit('createAccount/setStatus');
    this.$store.dispatch('authentication/updateRecentTechnician', '');
  },
  methods: {
    submit() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.enterNewPin();
      this.$store.dispatch('createAccount/setCreateAccountValue', {
        first_name: this.form.firstname,
        last_name: this.form.lastname,
        email: this.form.email,
        phone_number: this.form.phone1,
      });
    },
    goToBackPage() {
      this.$router.go(-1);
    },
    onChangeEmail(ev) {
      if (ev && this.checkEmailExists && ev === this.checkEmailExists) {
        this.$store.commit('createAccount/failed');
      } else if (this.accountStatus === 'failed') {
        this.$store.commit('createAccount/setStatus');
      }
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.form[value.modelName] = value.input;
      if (value.modelName === 'email') {
        this.onChangeEmail(value.input);
      }
    },
    enterNewPin() {
      this.$store.dispatch('createAccount/setDialogValue', true);
      this.showPinDialog = true;
    },
    dialogPinInputChanges(changes) {
      if (changes === false) {
        this.$store.dispatch('createAccount/setDialogValue', false);
      }
      this.showPinDialog = changes;
    },
  },
  validations: {
    form: {
      firstname: { required, minLength: minLength(2), maxLength: maxLength(25) },
      lastname: { required, minLength: minLength(2), maxLength: maxLength(25) },
      email: {
        required, email, emailPatternValidation, maxLength: maxLength(50),
      },
      phone1: { required, minLength: minLength(10), maxLength: maxLength(10) },
    },
  },
};
