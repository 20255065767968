//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import stopCard from './tripStopCard.vue';
import tripMap from './tripStopMap.vue';
import activeStopWarning from '../../helpers/component/active-stop-warning-dialog/activeStopWarningDialog.vue';
import beginStopInstruction from '../../helpers/component/begin-stop-instruction-dialog/beginStopInstructionDialog.vue';
import TripSummaryComponent from '../../helpers/component/trip-summary/trip-summary.vue';
import StorageMethodDialog from '../../helpers/component/storage-method-dialog/storageMethodDialog.vue';
import ActiveStopsForTrip from './stop-details/activeStopsForTrip.vue';

export default {
  name: 'StopLists',
  components: {
    stopCard,
    tripMap,
    activeStopWarning,
    beginStopInstruction,
    TripSummaryComponent,
    StorageMethodDialog,
    ActiveStopsForTrip,
  },
  computed: {
    ...mapGetters(['getStopLists', 'getStopListsArray', 'getTripDetail']),
    ...mapGetters({ voidedStopsInTrip: 'getVoidedStopsInTrip' }),
  },
  data() {
    return {
      stopLists: [],
      activeStop: {},
      showType: false,
      showStopDetailClicked: false,
      showStorageMethodDialog: false,
      stopToActivate: null,
      voidedStopsCheckInterval: null,
    };
  },
  created() {
    this.getStopDetails();
    this.setFirstLogin();
    this.stopsFilter(false);
    this.goToStopDetail();
    this.getVoidedStopIds();
  },
  beforeDestroy() {
    clearInterval(this.voidedStopsCheckInterval);
  },
  methods: {
    getStopDetails() {
      this.$store.dispatch('getStopDetailsAction', this.tripId).then((res) => {
        if (res && res.stops && res.stops.length > 0) {
          this.stopLists = res.stops;
        }
      });
    },
    stopsFilter(type) {
      this.showType = type;
      this.$store.dispatch('setStopTypesAction', type);
    },
    setFirstLogin() {
      this.$store.commit('trip/setFirstLogin', false);
    },
    goToStopDetail() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'setCurrentStopActive') {
          if (state.stopDetail.currentStopActive
          || state.stopDetail.stopDetail.is_completed) {
            this.$router.push(`/stops/detail/${state.stopDetail.stopDetail.id}`);
          }
        }
        if (mutation.type === 'setStopArrive') {
          if (state.stopDetail.stopArrived) {
            this.$store.dispatch('showBeginStopInstruction', {
              status: false,
              stopId: state.stopDetail.stopDetail.id,
            });
            this.$router.push(`/stops/detail/${state.stopDetail.stopDetail.id}`);
          }
        }
      });
    },
    openStorageModel(stopId) {
      this.stopToActivate = stopId;
      this.showStorageMethodDialog = true;
    },
    cancelStorageDialog() {
      this.showStorageMethodDialog = false;
    },
    showStopDetail() {
      this.showStopDetailClicked = true;
      const payload = {
        preview: true,
        tripId: this.tripId,
      };
      this.$store.dispatch('trip/printTripsDetail', payload);
    },
    closeStopDetail() {
      this.showStopDetailClicked = false;
    },
    goToViewAllCcid() {
      this.$router.push(`/view-all-ccid/${this.tripId}`);
    },
    getVoidedStopIds() {
      this.voidedStopsCheckInterval = setInterval(() => {
        this.$store.dispatch('setVoidedStopsInTrip', this.tripId);
      }, 5000);
    },
    getTripStopData() {
      this.$store.dispatch('getStopDetailsAction', this.tripId);
    },
  },
  props: {
    tripId: {
      type: String,
      required: true,
    },
  },
  watch: {
    $route: 'getStopDetails',
    voidedStopsInTrip() {
      this.getTripStopData();
    },
  },
};
