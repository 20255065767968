//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'StopInventoryCard',
  computed: {
    ...mapGetters({ useCCIDStorage: 'getUseCCIDStorage' }),
    isFullInventory() {
      const consolidationContainer = this.inventory.consolidation_container;
      const isFull = consolidationContainer && consolidationContainer.is_full;
      return !this.lastInventoryList && this.useCCIDStorage && isFull;
    },
  },
  methods: {
    editApproval() {
      this.$store.commit('setApprovalStatusType', true);
      const stopApproval = {};
      let id = null;
      let wasteType = null;
      if (this.inventory) {
        if (this.useCCIDStorage) {
          id = this.inventory.stop_approval.id;
          wasteType = this.inventory.stop_approval.waste_type;
        } else {
          id = this.inventory.id;
          wasteType = this.inventory.waste_type;
        }
      }
      Object.assign(stopApproval, { id, waste_type: wasteType });
      if (stopApproval.id && stopApproval.waste_type === 'retail') {
        this.$store.dispatch('editApproval', stopApproval).then(() => {
          this.$router.push({ name: 'Add Approval' });
          setTimeout(() => {
            this.$store.dispatch('container/setSelectedContainerTypeLists');
          }, 0);
        });
      } else {
        this.$store.dispatch('rxApproval/editRxApproval', { stop_approval: stopApproval });
        this.$store.commit('rxApprovalList/hideRxApprovalConsolidationList', true);
        this.$router.push({ name: 'Rx Approval List' });
      }
    },
    reopen() {
      this.$store.dispatch('reopenApproval', { containerId: this.inventory.consolidation_container.id, stopId: this.stopId });
    },
    markasFull() {
      this.$store.dispatch('markasFull', { containerId: this.inventory.consolidation_container.id, stopId: this.stopId });
    },
    checkInventoryCcidStorage() {
      const thisStopUsesCCID = !this.lastInventoryList && this.useCCIDStorage;
      const lastStopUsedCCID = this.lastInventoryList && this.inventoryUseCcid;
      return thisStopUsesCCID || lastStopUsedCCID;
    },
  },
  props: {
    inventory: {
      type: Object,
      required: true,
    },
    stopCompleted: {
      type: Boolean,
      default() {
        return false;
      },
    },
    stopId: {
      type: Number,
      default: 0,
    },
    lastInventoryList: {
      type: Boolean,
      default: false,
    },
    inventoryUseCcid: {
      type: Boolean,
      default: false,
    },
  },
};
