//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import benchScale from './benchScale.vue';
import closeContent from './closeContent.vue';
import contentContainer from './contentContainer.vue';
import contentSelect from './contentSelect.vue';
import contentQuantity from './contentQuantity.vue';
import weightOunces from './weightOunces.vue';
import weightPounds from './weightPounds.vue';
import rxEmptyBottleContainer from './emptyBottleContainer.vue';
import KeyboardDialog from '../virtual-keyboard/virtualKeyboardDialog.vue';
import VSelectArrow from '../../utils/v-select-arrow';
import billUnitList from '../billUnits/billUnitList.vue';

export default {
  name: 'RxContentBuilder',
  components: {
    billUnitList,
    closeContent,
    contentSelect,
    contentQuantity,
    contentContainer,
    weightOunces,
    weightPounds,
    benchScale,
    rxEmptyBottleContainer,
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({
      getRxDetail: 'rxApproval/getRxApprovalDetail',
      showContent: 'rxApproval/getShowContent',
      rxEmptyBottle: 'rxApproval/getRxEmptyBottle',
      manifestUnitsList: 'getManiFestUnits',
    }),
    totalResidualWeight() {
      // The conversion to string and back to float allows us to limit the # decimal points
      // while preventing printing unnecessary trailing zeros.
      const calculatedVal = this.contentDetail.residualWeight * this.contentDetail.noOfEmptyBottles;
      const fixedVal = calculatedVal.toFixed(this.precisionValue);

      return parseFloat(fixedVal);
    },
    precisionValue() {
      const originalVal = this.contentDetail.residualWeight;

      if (originalVal === 0) {
        return 0;
      }

      const decimalPlaces = originalVal.toString().split('.')[1]
        ? originalVal.toString().split('.')[1].length
        : 0;

      return decimalPlaces;
    },
    hasCustomerSpecificBilling() {
      if (!this.checkBillUnitAvail) return false;
      return this.filterOutLbsBillUnit(this.contentDetail.billUnits).length > 0;
    },
    checkBillUnitAvail() {
      return this.contentDetail && this.contentDetail.billUnits;
    },
  },
  data() {
    return {
      selectedWeightType: 0,
      weight: '',
      weightUnit: 4,
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
    };
  },
  extends: VSelectArrow,
  created() {
    this.getManiFestUnits();
    this.weight = this.contentDetail.bottleWeight;
    this.weightUnit = this.contentDetail.weightUnit;
    if (this.$store.state.rxApproval.rxEmptyBottle) {
      this.$store.commit('rxContent/enablePreviousMonthBtn');
      this.$store.commit('rxContent/setEmptyBottleStatus', true);
    }
  },
  destroyed() {
    this.resetData();
  },
  methods: {
    resetData() {
      this.$store.commit('rxContent/setEmptyBottleStatus', false);
    },
    weightTypeChange(input) {
      if (this.getRxDetail.id) {
        this.selectedWeightType = input;
        this.setContentValue(input, 'weightType');
      }
    },
    getManiFestUnits() {
      this.$store.dispatch('getManiFestUnits');
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      const weight = value.input;
      this.weight = weight;
      this.setContentValue(this.weight, 'bottleWeight');
    },
    setContentValue(input, fieldName) {
      this.$store.commit('rxContent/setContentValue', {
        index: this.contentIndex,
        field: fieldName,
        value: input,
      });
    },
    changeWeightUnit(ev) {
      this.setContentValue(ev, 'weightUnit');
    },
    filterOutLbsBillUnit(billUnits) {
      return billUnits.filter((bu) => bu.unit !== 'LBS');
    },
    calculatedResidualWeight() {
      const total = this.totalResidualWeight + Number(this.weight);

      return total.toFixed(this.precisionValue);
    },
  },
  props: {
    contentDetail: {
      type: Object,
      default: () => ({
        contentID: '',
        contentQuantity: 0,
        weightType: 0,
        weightPounds: 0,
        weightOunces: 0,
        containers: 0,
        totalWeight: 0.0,
        month: '',
        billUnits: null,
      }),
    },
    lastIndex: {
      type: Boolean,
      default: false,
    },
    firstIndex: {
      type: Boolean,
      default: false,
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    rxEmptyBottle(newVal) {
      if (newVal) {
        this.weight = this.contentDetail.bottleWeight;
        this.$store.commit('rxContent/enablePreviousMonthBtn');
        this.$store.commit('rxContent/setEmptyBottleStatus', true);
      }
    },
    contentDetail() {
      this.selectedWeightType = 0;
    },
  },
};
