//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import AccumulationPickupItem from '../accumulation-pickup-item/accumulationPickupItem.vue';
import customerTypesConstant from '../../../constants/customer-type';
import apiResponse from '../../../constants/apiResponseStatus';

export default {
  name: 'AccumulationPickupData',
  components: { AccumulationPickupItem },
  computed: {
    ...mapGetters(['getStopDetail', 'getAccumulationApiCallStatus', 'getHasNetworkConnection']),
    totalProfiles() {
      if (this.isSuccessfulApiResponse) {
        return this.accumulationPickupList[0].total_num_profiles_on_stop;
      }
      return '-';
    },
    expectedWeight() {
      if (this.isSuccessfulApiResponse) {
        return Number(this.accumulationPickupList.reduce((total, current) => total + current.weight, 0).toFixed(2));
      }

      return '0';
    },
    totalItems() {
      if (this.isSuccessfulApiResponse) {
        return this.accumulationPickupList.reduce((total, current) => total + current.quantity, 0);
      }
      return '-';
    },
    showNotes() {
      return this.getStopDetail.customer.name === customerTypesConstant.customerTypes.is_costco;
    },
    errorHeader() {
      if (this.getHasNetworkConnection) {
        return 'We’re having some trouble fetching the stop data.';
      }
      return 'It looks like we can’t connect to the internet.';
    },
    errorDescription() {
      return 'We’ll keep trying, but you may go ahead and begin the stop. We’ll show the stop data once it’s available.';
    },
    isSuccessfulApiResponse() {
      return this.getAccumulationApiCallStatus === apiResponse.status.SUCCESS;
    },
  },
  data() {
    return {
      apiResponseStatus: apiResponse.status,
    };
  },
  props: {
    accumulationPickupList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
