<template>
  <div
    class="add-rx-sub-div-list b-color-grey"
  >
    <div class="add-rx-page-div b-color-grey add-rx-list-back">
      <button
        class="rx-items-cancel-entry-btn width-112p"
        @click="gotoAddRxItem()"
      >
        <span class="back-btn-consolidate" /> <span class="span-back">Back</span>
      </button>
    </div>
    <div class="b-color-grey">
      <v-row class="bb-grey mr-0p">
        <v-col
          cols="12"
          lg="4"
        >
          <label class="add-rx-items-details-sub-title ml-54p mb-1">
            Description
          </label>
        </v-col>
        <v-col
          cols="12"
          lg="3"
        >
          <label class="add-rx-items-details-sub-title ml-20p mb-1">
            Approval
          </label>
        </v-col>
        <v-col
          cols="12"
          lg="5"
        >
          <label class="add-rx-items-details-sub-title ml-20p mb-1">
            NDC / Code
          </label>
        </v-col>
      </v-row>
      <div class="loading-rx-items">
        <SearchLoader />
      </div>
      <rx-item-list-card
        @add="add"
        @gotoAddRxItem="gotoAddRxItem"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RxItemListCard from '../rx-item-list-card/index.vue';
import SearchLoader from '../../../helpers/component/search-loader/search-loader.vue';

export default {
  name: 'RxItemLists',
  components: {
    RxItemListCard,
    SearchLoader,
  },
  computed: {
    ...mapGetters({ getRxLists: 'rxApproval/getRxLists' }),
  },
  methods: {
    gotoAddRxItem() {
      this.$router.push({ name: 'Add Rx Items' });
    },
    add(item) {
      this.$store.dispatch('rxApproval/fetchApprovalDetail', item).then(() => {
        this.$router.push({ name: 'Add Rx Items' });
      });
    },
  },
};
</script>
