//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import stopIcon from '../../helpers/component/stop-icon/stopIcon.vue';
import connectedStopConstant from '../../constants/connected-stop';

export default {
  name: 'StopCard',
  components: { stopIcon },
  computed: {
    ...mapGetters(['getBeginStopAction', 'getStopDetail']),
    stopCardButtonText() {
      let text = this.stop.is_active || this.stop.is_completed ? 'Continue' : 'Begin stop';
      if (this.trip.is_completed) {
        text = 'Trip complete';
      }
      return text;
    },
  },
  methods: {
    startStop(stop) {
      const date = new Date().toUTCString();
      const stopObj = stop;
      stopObj.stop_active = true;
      stopObj.stop_active_time = date;
      this.$store.dispatch('changeStopActive', stopObj).then(() => {
      });
    },
    gotoStopDetail(stop) {
      this.$store.dispatch('getStopDetailAction', {
        stopID: stop.id,
        type: 'popup',
      });
      this.$store.commit('setStopInventoryLists', []);
      this.$store.commit('resetMostOccuringProfiles');
      this.$store.commit('resetAccumulationContainerList');
      this.$store.commit('attachment/resetPrintedDocumentList');
      this.$store.commit('attachment/resetPrintedDocumentAndAttachmentMap');
      this.$store.commit('setStopInventoryActiveTab', null);
      this.$store.commit('resetExpectedPickupList');
      this.$store.commit('resetAccumulationPickupList');
      this.$store.commit('setAccumulationContainerApiCallStatus', null);
      this.$store.dispatch('resetApprovalAddedFromContainer');
    },
    recordContact(stop) {
      if (!this.checkForVoidedStop(stop)) {
        const params = {};
        params.stopID = stop.id;
        params.tripID = stop.trip_id;
        params.type = 'list';
        this.$store.dispatch('contactTimestamp/recordContactAction', params);
      }
    },
    removeContact(stop) {
      if (!this.checkForVoidedStop(stop)) {
        const params = {};
        params.stopID = stop.id;
        params.tripID = stop.trip_id;
        params.type = 'list';
        this.$store.dispatch('contactTimestamp/removeContactAction', params);
      }
    },
    undoStop(stopID) {
      this.$store.dispatch('stopArriveCancelAction', stopID);
    },
    getStopButtonLabel(stop) {
      let labelName = 'Begin stop';
      if (stop.is_active) {
        labelName = 'Continue';
      }
      if (stop.is_completed) {
        labelName = 'View stop';
      }
      return labelName;
    },
    checkConnectedStopStatus(stop) {
      if (stop.connected_status) {
        if (stop.connected_status === connectedStopConstant.status.NOT_CONNECTED) {
          return false;
        }
        return true;
      }
      return false;
    },
    checkForVoidedStop(stop) {
      return (stop.voided || stop.use_received_completed_stop) && !stop.start_time;
    },
  },
  props: {
    stop: {
      type: Object,
      required: true,
    },
    trip: {
      type: Object,
      required: true,
    },
  },
  watch: {
    getBeginStopAction(newVal) {
      if (newVal) {
        this.$router.push(`/stop/pickup-data/${this.getStopDetail.id}`);
      }
    },
  },
};
