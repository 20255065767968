import api from '../service/consolidate.service';
import toastMessage from './toast-message';

function sendErrorMessage(commit, errorMessage, errorType) {
  let messageData = '';
  messageData = errorMessage.split(':');
  messageData = messageData[messageData.length - 1];
  toastMessage.showToastErrorMessage(commit, messageData, errorType);
}

const stateObj = {
  consolidateList: [],
  containerSizeList: [],
  containerTypeList: [],
  isCreateCCID: false,
  addToTruckInventorySuccess: false,
  addedCCIDs: [],
  consolidationSuccessMessage: '',
  approvalDelete: {
    description: '',
    stopApprovalId: '',
    ccids: [],
    stopId: '',
    rxItemDescription: '',
    stopRxItemId: '',
    type: '',
    lastRxItem: false,
    deleteCCIDS: [],
    approvalId: '',
  },
  availableRxItem: '',
  stopPListedWeightError: false,
  pListedApprovalList: {},
  overWeightApproval: {},
  editableCCID: [],
};
const getters = {
  getConsolidateList(context) {
    return context.consolidateList;
  },
  getContainerSizeList(stateControl) {
    return stateControl.containerSizeList;
  },
  getContainerTypeList(stateControl) {
    return stateControl.containerTypeList;
  },
  getIsCreateCCID(stateControl) {
    return stateControl.isCreateCCID;
  },
  getIsSelectedCCID(stateControl) {
    return stateControl.isSelectedCCID;
  },
  getAddToTruckInventorySuccess(stateControl) {
    return stateControl.addToTruckInventorySuccess;
  },
  getAddedCCIDs(stateControl) {
    return stateControl.addedCCIDs;
  },
  getConsolidationSuccessMessage(stateControl) {
    return stateControl.consolidationSuccessMessage;
  },
  getApprovalDelete(stateControl) {
    return stateControl.approvalDelete;
  },
  getAvailableRxItem(stateControl) {
    return stateControl.availableRxItem;
  },
  getStopPListedWeightError(stateControl) {
    return stateControl.stopPListedWeightError;
  },
  getPListedApprovalList(stateControl) {
    return stateControl.pListedApprovalList;
  },
  getEditableCCID(stateControl) {
    return stateControl.editableCCID;
  },
};

const mutations = {
  success(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.consolidateList = payload;
  },
  removeCCIDByID(stateControl, id) {
    const stateControld = stateControl;
    stateControld.consolidateList = stateControld.consolidateList
      .filter((container) => container.id !== id);
  },
  setContainerSizeList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.containerSizeList = payload;
  },
  resetContainerSizeList(stateControl) {
    const stateControld = stateControl;
    stateControld.containerSizeList = [];
  },
  setContainerTypeList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.containerTypeList = payload;
  },
  resetContainerTypeList(stateControl) {
    const stateControld = stateControl;
    stateControld.containerTypeList = [];
  },
  setIsCreateCCID(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.isCreateCCID = payload;
  },
  changeCCIDSelection(stateControl, payload) {
    const stateControld = stateControl;
    const { container, stopApprovalId } = payload;
    stateControld.consolidateList = stateControld.consolidateList.map((list) => {
      const ccidList = list;
      if (container.id === ccidList.id
        && ccidList.created_stop_approval_id !== stopApprovalId) {
        ccidList.is_selected = !ccidList.is_selected;
      }
      return ccidList;
    });
  },
  resetConsolidation(stateControl) {
    const stateControld = stateControl;
    stateControld.isCreateCCID = false;
    stateControld.consolidateList = [];
    stateControld.containerSizeList = [];
    stateControld.containerTypeList = [];
  },
  setAddToTruckInventorySuccess(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.addToTruckInventorySuccess = payload;
  },
  setAddedCCIDs(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.addedCCIDs = payload;
  },
  setRemoveConsolidationSuccess(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.consolidationSuccessMessage = payload;
  },
  setApprovalDelete(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.approvalDelete = payload;
  },
  setAvailableRxItem(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.availableRxItem = payload;
  },
  removeCCIDById(stateControl, id) {
    const stateControld = stateControl;
    stateControld.consolidateList = stateControld.consolidateList.filter((list) => list.id !== id);
  },
  setStopPListedWeightError(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopPListedWeightError = payload;
  },
  setPListedApprovalList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.pListedApprovalList = payload;
  },
  setOverWeightApproval(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.overWeightApproval = payload;
  },
  setConsolidateApprovalId(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.consolidateApprovalId = payload;
  },
  setEditableCCID(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.editableCCID.push(payload);
  },
  resetEditableCCID(stateControl) {
    const stateControld = stateControl;
    stateControld.editableCCID = [];
  },
};

const actions = {
  getConsolidateLists(context, res) {
    let existingConsolidateList = context.state.consolidateList;
    existingConsolidateList = existingConsolidateList.map((container) => {
      const dupContainer = container;
      if (dupContainer.is_selected) {
        return dupContainer.id;
      }
      return 0;
    }).filter(Boolean);
    context.commit('setConsolidateApprovalId', res.approvalId);
    api.getConsolidateListByApprovalID(res.approvalId).then((consolidation) => {
      const existingContainerIds = consolidation.existing_ccids;
      const consolidationContainers = consolidation.consolidation_containers
        .map((containerDetail) => {
          const containerData = containerDetail;
          containerData.is_selected = false;
          if (containerData.created_stop_approval_id === res.approvalId
            || (res.type === undefined && existingContainerIds.length > 0
                && existingContainerIds.includes(containerData.id))
            || (existingConsolidateList.length > 0
                && existingConsolidateList.includes(containerData.id))) {
            containerData.is_selected = true;
          }
          return containerData;
        });
      context.commit('success', consolidationContainers);
    }, (errorMessage) => {
      sendErrorMessage(context.commit, errorMessage, 'error');
    });
  },
  getContainerSizeListAction({ commit }) {
    api.getContainerSizeList().then((response) => {
      commit('setContainerSizeList', response.container_sizes);
    }, (errorMessage) => {
      sendErrorMessage(commit, errorMessage, 'error');
    });
  },
  getContainerTypeListAction({ commit }) {
    api.getContainerTypeList().then((response) => {
      commit('setContainerTypeList', response.container_types);
    }, (errorMessage) => {
      sendErrorMessage(commit, errorMessage, 'error');
    });
  },
  removeRealCCIDAction({ commit }, id) {
    api.removeRealCCID(id).then((response) => {
      if (response) {
        commit('setIsCreateCCID', false);
        commit('removeCCIDByID', id);
      }
    });
  },
  addToTruckInventoryAction({ commit, dispatch }, res) {
    const params = {
      consolidation_container_ids: res.consolidationContainerIds,
      stop_approval_id: res.stopApprovalId,
    };
    api.addToTruckInventory(params).then((response) => {
      if (response.stop_approval) {
        dispatch('getStopDetailAction', { stopID: response.stop_approval.stop_id, type: '' }, { root: true });
        commit('resetConsolidation');
        commit('resetConsolidateApproval', '', { root: true });
        commit('setAddToTruckInventorySuccess', true);
        commit('resetEditableCCID');
        const addedCCIDs = response.stop_approval.consolidation_containers
          .map((consolidation) => consolidation.ccid);
        commit('setAddedCCIDs', addedCCIDs);
        dispatch('updateAccumulationData', { id: response.stop_approval.id, waste_type: response.stop_approval.waste_type, approval_id: response.stop_approval.approval_id }, { root: true });
        setTimeout(() => {
          commit('setAddToTruckInventorySuccess', false);
          commit('setApprovalAddedSuccessfully', true, { root: true });
        }, 1000);
      }
    }, (errorMessage) => {
      sendErrorMessage(commit, errorMessage, 'error');
    });
  },
  CCIDContainerChangeAction({ commit, dispatch, state }, res) {
    api.CCIDContainerChangeByConsolidationContainerId(res.consolidationContainerId, res.params)
      .then((response) => {
        if (response.consolidation_container) {
          dispatch('getConsolidateLists', {
            approvalId: state.consolidateApprovalId,
            type: 'update',
          });
        }
      }, (errorMessage) => {
        sendErrorMessage(commit, errorMessage, 'error');
      });
  },
  removeConsolidationSavedData({ commit, dispatch }, options) {
    commit('setRemoveConsolidationSuccess', '');
    api.removeConsolidateData(options.id)
      .then((response) => {
        commit('setRemoveConsolidationSuccess', response.message);
        dispatch('rxApprovalList/getRxApprovalListbyType', options.stopId, { root: true });
      }, (errorMessage) => {
        sendErrorMessage(commit, errorMessage, 'error');
      });
  },
  deleteRxItems({ commit, dispatch }, payload) {
    api.removeRxConsolidateData(payload.id)
      .then((response) => {
        const stopApproval = response.stop_approval;
        const approvalRxItem = response.stop_approval
          ? response.stop_approval.approval_rx_item
          : response.approval_rx_item;
        const isConsolidated = approvalRxItem.consolidation_container_ids.length > 0;
        if (approvalRxItem && approvalRxItem.stop_rx_items.length > 0) {
          if (stopApproval && isConsolidated) {
            const options = {
              stop_approval: {
                id: stopApproval.id,
                waste_type: 'rx',
              },
            };
            commit('setAvailableRxItem', 'success');
            dispatch('rxApproval/editRxApproval', options, { root: true });
            commit('rxApprovalList/hideRxApprovalConsolidationList', true, { root: true });
          } else {
            commit('setRemoveConsolidationSuccess', 'successRx');
            dispatch('rxApprovalList/getRxApprovalListbyType', approvalRxItem.stop_id, { root: true });
          }
        } else {
          dispatch('updateAccumulationDataOnApprovalDelete', { stopApprovalId: response.stop_approval.id, approvalId: response.stop_approval.approval_id }, { root: true });
          commit('rxApproval/resetApprovalDetail', null, { root: true });
          commit('setRemoveConsolidationSuccess', 'success');
        }
        dispatch('rxApprovalList/getRxApprovalListForTransition', payload.stopId, { root: true });
        dispatch('getStopDetailAction', { stopID: payload.stopId, type: '' }, { root: true });
      }, (errorMessage) => {
        sendErrorMessage(commit, errorMessage, 'error');
      });
  },
  deleteFinalApproval({ commit, dispatch }, payload) {
    commit('setRemoveConsolidationSuccess', '');
    api.deleteFinalApprovalWithCCID(payload.stopApprovalId)
      .then((response) => {
        dispatch('updateAccumulationDataOnApprovalDelete', { stopApprovalId: payload.stopApprovalId, approvalId: payload.approvalId }, { root: true });
        dispatch('getInventoryLists', payload.stopId, { root: true });
        dispatch('stopLabels/updateLabelReprintStatus', payload.stopId, { root: true });
        dispatch('getStopDetailAction', { stopID: payload.stopId, type: '' }, { root: true });
        commit('setRemoveConsolidationSuccess', response.message);
      }, (errorMessage) => {
        sendErrorMessage(commit, errorMessage, 'error');
      });
  },
  createAndSaveNewCCID({ commit, dispatch }, res) {
    const params = {
      trip_id: res.tripId,
      stop_approval_id: res.consolidateApprovalId,
    };
    api.createAndSaveNewCCID(params).then((response) => {
      commit('setIsCreateCCID', false);
      dispatch('getConsolidateLists', {
        approvalId: res.consolidateApprovalId,
        selectedId: response.consolidation_container.id,
        type: 'create',
      });
      commit('setEditableCCID', response.consolidation_container.id);
    }, (errorMessage) => {
      sendErrorMessage(commit, errorMessage, 'error');
    });
  },
  createAndSaveDuplicateCCID({ commit, dispatch }, res) {
    const { stopApprovalId } = res;
    const params = {
      consolidate_container_id: res.containerId,
      stop_approval_id: stopApprovalId,
    };
    api.createAndSaveDuplicateCCID(params).then((response) => {
      commit('setIsCreateCCID', false);
      dispatch('getConsolidateLists', {
        approvalId: stopApprovalId,
        selectedId: response.consolidation_container.id,
        type: 'duplicate',
      });
      commit('setEditableCCID', response.consolidation_container.id);
    }, (errorMessage) => {
      sendErrorMessage(commit, errorMessage, 'error');
    });
  },
  getPListedApprovalList({ commit, state }, stopId) {
    const {
      description, weight, stopApprovalId, approvalId, approvalRxItemId, type, stopRxItemId,
    } = state.overWeightApproval;
    api.getPListedApprovalList({
      stopId, weight, approvalId, description, stopApprovalId, approvalRxItemId, type, stopRxItemId,
    }).then((response) => {
      const { approvals } = response;
      commit('setPListedApprovalList', { approvals, totalWeight: response.total_weight });
    });
  },
};
export default {
  namespaced: true,
  state: stateObj,
  getters,
  actions,
  mutations,
};
