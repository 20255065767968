import firestoreDB from './firestoreDB.service';
import userService from './auth.service';
import stopAttachmentService from './stop-attachment.service';

function fetchResponseFromRequest(url, options) {
  const request = firestoreDB.parseRequest(url, options);
  switch (request.route) {
    case (request.route.match('/login') || {}).input:
      return userService.login(request);
    case (request.route.match('/checkEmail') || {}).input:
      return userService.checkEmail(request);
    case (request.route.match('/stops/attachment') || {}).input:
      return stopAttachmentService.addAttachments(request);
    default:
      return new Promise((resolve) => {
        resolve(firestoreDB.errorResponse(`${request.route} is not recognized by the firestore api`));
      });
  }
}

function constructFetchResponse(response) {
  return {
    ok: response.ok,
    text: () => new Promise((resolve) => {
      resolve(JSON.stringify(response.data));
    }),
  };
}

function fetch(url, options) {
  return new Promise((resolve) => {
    resolve(
      fetchResponseFromRequest(url, options)
        .then(constructFetchResponse),
    );
  });
}

const firestoreService = {
  fetch,
};
export default firestoreService;
