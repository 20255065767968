<template>
  <div class="power-off-screen">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'EmptyLayout',
};
</script>
