<template>
  <div class="width-100p stop-label-component">
    <div class="d-flex flex-column ptb-30plr-40">
      <div class="d-flex justify-space-between width-100p print-label-header">
        <div class="print-label">
          <label>Print labels</label>
        </div>
        <button
          class="print-button"
          :class="{ disabled: !printIsValid }"
          @click="checkIfManifestIsPrinted"
        >
          <img src="src/assets/images/printer-white.svg">
          <div class="print-button-label">
            Print selected labels
          </div>
        </button>
      </div>
      <div
        class="reprint-alert-box"
        v-if="reprintLabelStatus"
      >
        <img
          class="alert-image"
          src="src/assets/images/alert.svg"
        >
        <div class="reprint-alert-info">
          You've added additional approvals after printing a manifest. You may also need to reprint
          your labels if they reference the previous manifest.
        </div>
      </div>
      <div class="labels-selector">
        <div
          v-for="(item, index) in labelGroupKeys"
          :key="index"
          @click="selectLabelGroup(labelGroups[item])"
          :class="{'active-selector' : selectedLabelGroup === labelGroups[item]}"
        >
          {{ labelGroups[item] }}
        </div>
      </div>
      <label-group-table
        :table-headers="nonCCIDTableHeaders"
        :table-rows="labelTableData"
        v-if="nonCCIDTrip && !generatorGroupSelected && !customerGroupSelected"
      />
      <label-group-table
        :table-headers="labelTableHeaders"
        :table-rows="labelTableData"
        v-if="!nonCCIDTrip && !generatorGroupSelected && !customerGroupSelected"
      />
      <label-group-table
        :table-headers="generatorLabelTableHeaders"
        :table-rows="labelTableData"
        v-if="generatorGroupSelected"
      />
      <label-group-table
        :table-headers="customerLabelTableHeaders"
        :table-rows="labelTableData"
        v-if="customerGroupSelected"
      />
    </div>

    <confirm-dialog
      v-if="showAlertDialog"
      :dialog-data="modalData"
      :button-data="modalButtons"
      @confirm="printAnyway"
      @cancel="closeErrorModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import labelGroupTable from '../../../helpers/component/label-group-table/labelGroupTable.vue';
import confirmDialog from '../../../helpers/component/confirmation-dialog/confirmation.vue';

export default {
  name: 'StopLabel',
  components: {
    labelGroupTable,
    confirmDialog,
  },
  computed: {
    ...mapGetters({
      labelTableData: 'stopLabels/getLabels',
      reprintLabelStatus: 'stopLabels/getLabelReprintStatus',
      labelPrintingComplete: 'stopLabels/getLabelPrintingComplete',
    }),
    labelGroupKeys() {
      return Object.keys(this.labelGroups);
    },
    nonCCIDTrip() {
      return this.$store.getters.getTripDetail.storage_method === 'do_not_use_ccid';
    },
    printIsValid() {
      return this.selectedDocumentsCount > 0 && this.labelPrintingComplete;
    },
    generatorGroupSelected() {
      return this.selectedLabelGroup === this.labelGroups.generatorLabels;
    },
    customerGroupSelected() {
      return this.selectedLabelGroup === this.labelGroups.customerLabels;
    },
  },
  data() {
    return {
      labelTableHeaders: {
        ccid: 'CCID',
        displayedType: 'Type',
        manifest: 'Manifest/BOL',
        line: 'Line',
        quantity: 'Quantity',
      },
      nonCCIDTableHeaders: {
        approval_code: 'Approval',
        displayedType: 'Type',
        manifest: 'Manifest/BOL',
        line: 'Line',
        quantity: 'Quantity',
      },
      generatorLabelTableHeaders: {
        displayedType: 'Type',
        quantity: 'Quantity',
      },
      customerLabelTableHeaders: {
        customer: 'Customer',
        customer_label_type: 'Type',
        quantity: 'Quantity',
      },
      labelGroups: {
        newLabels: 'New labels',
        allLabels: 'All labels',
        generatorLabels: 'Generator labels',
        customerLabels: 'Customer labels',
      },
      selectedLabelGroup: '',
      selectedDocumentsCount: 0,
      showAlertDialog: false,
      confirmModal: false,
      modalData: {
        heading: 'Print labels with no manifest number?',
        description:
          'You have not printed a manifest for this stop. Print your manifest from the Print documents '
          + 'page to enter the manifest tracking number.',
      },
      modalButtons: {
        buttonConfirmText: 'Print selected labels without ID',
        buttonConfirmType: 'btn-primary',
        buttonConfirmVisibility: true,
        buttonCancelText: 'Back',
        buttonCancelVisibility: true,
      },
    };
  },
  created() {
    this.selectedLabelGroup = this.labelGroups.newLabels;
    this.initalizeCustomerLabel();
  },
  methods: {
    selectLabelGroup(labelGroup) {
      this.selectedLabelGroup = labelGroup;
    },
    printSelectedLabels() {
      const tripId = this.$store.getters.getTripDetail.id;
      const stopDetail = this.$store.getters.getStopDetail;
      if (this.printIsValid) {
        this.$store.dispatch('stopLabels/printSelectedLabels', {
          labels: this.getSelectedLabels(),
          tripId,
          stopId: stopDetail.id,
        });
      }
    },
    getSelectedLabels() {
      return this.labelTableData.filter((label) => label.selected);
    },
    closeErrorModal() {
      this.showAlertDialog = false;
    },
    printAnyway() {
      this.showAlertDialog = false;
      this.printSelectedLabels();
    },
    checkIfManifestIsPrinted() {
      let manifestIsPrintedForSelected = true;
      this.getSelectedLabels().forEach((label) => {
        if (manifestIsPrintedForSelected && !label.manifest) {
          manifestIsPrintedForSelected = false;
        }
      });
      if (manifestIsPrintedForSelected || this.selectedLabelGroup !== this.labelGroups.newLabels) {
        this.printSelectedLabels();
      } else {
        this.showAlertDialog = true;
      }
    },
    initalizeCustomerLabel() {
      if (this.$route.query.initializeLabel) {
        this.selectedLabelGroup = this.$route.query.initializeLabel;
        this.$router.push({ query: { } });
      }
    },
  },
  watch: {
    selectedLabelGroup() {
      const stopDetail = this.$store.getters.getStopDetail;
      const stopDetails = {
        stopID: stopDetail.id,
        labelGroup: this.selectedLabelGroup.split(' ')[0].toLowerCase(),
      };
      this.$store.dispatch('stopLabels/setLabelsData', stopDetails);
    },
    labelTableData: {
      deep: true,
      handler(labels) {
        this.selectedDocumentsCount = 0;
        labels.forEach((label) => {
          if (label.selected) {
            this.selectedDocumentsCount += 1;
          }
        });
      },
    },
    '$route.query.initializeLabel': {
      deep: true,
      handler() {
        this.initalizeCustomerLabel();
      },
    },
  },
};
</script>
