<template>
  <div>
    <div class="mobile-help-sub-header-div">
      <img
        src="src/assets/images/left-chevron-white.svg"
        class="mobile-help-sub-header-div-img"
        @click="goToBack()"
      >
      <span class="mobile-help-sub-header-title">Feedback</span>
    </div>
    <div class="feedback-contact-main-div-mobile">
      <img
        class="feedback-image-mobile"
        src="src/assets/images/ss-logo.svg"
      >
      <p
        class="please-contact-text-mobile"
      >
        Please contact Smarter Sorting Support if you have feedback or
        feature requests for the TruckSiS hardware and software.
      </p>
      <div>
        <p class="please-contact-title-mobile">
          Email:
        </p>
        <p class="please-contact-id-email">
          support@smartersorting.com
        </p>
      </div>
      <div>
        <p class="please-contact-title-mobile">
          Hours:
        </p>
        <p class="please-contact-id-mobile">
          7 days a week
        </p>
        <p class="please-contact-id-mobile">
          7am-8pm Central Time
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FeedbackMobile',
  methods: {
    goToBack() {
      this.$router.go(-1);
    },
  },
};

</script>
