<template>
  <div class="mobile-rx-item-control-group">
    <div
      class="rx-item-border-1px rx-items-controls-symbols mobile-rx-item-control"
    >
      <button
        class="width-100p"
        @click="decreaseValue"
      >
        <img
          src="src/assets/images/minus-outline-icon.svg"
          alt="minus"
        >
      </button>
    </div>
    <div
      class="rx-item-border-1px rx-items-controls-symbols mobile-rx-item-control"
    >
      <input
        type="number"
        :value="value"
        @input="updateValue($event.target.value)"
        class="mobile-rx-approval-detail-input"
      >
    </div>
    <div
      class="rx-item-border-1px rx-items-controls-symbols mobile-rx-item-control"
    >
      <button
        class="width-100p"
        @click="increaseValue"
      >
        <img
          src="src/assets/images/plus-outline-icon.svg"
          alt="plus"
        >
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RxItemIncrementDecrementInput',
  data() {
    return {
      inputVal: this.value,
    };
  },
  methods: {
    increaseValue() {
      if (this.inputVal === 99) {
        this.updateValue(99);
      } else {
        this.updateValue(this.inputVal + 1);
      }
    },
    decreaseValue() {
      if (this.inputVal === 0) {
        this.updateValue(0);
      } else {
        this.updateValue(this.inputVal - 1);
      }
    },
    updateValue(value) {
      const clampedValue = String(value).slice(0, 2);
      this.inputVal = +clampedValue;
      this.$emit('input', +clampedValue);
      this.$forceUpdate();
    },
  },
  props: {
    value: {
      default: 0,
      type: Number,
    },
  },
};
</script>
