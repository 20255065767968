//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import VSelectArrow from '../../../helpers/utils/v-select-arrow';
import RxItemIncrementDecrementInput from '../../../helpers/component/mobile-rx-inc-dec-input/mobileRxIncDecInput.vue';
import MobileBillUnits from './billUnits.vue';


export default {
  name: 'AddRxItemContentDetail',
  components: {
    RxItemIncrementDecrementInput,
    MobileBillUnits,
  },
  computed: {
    ...mapGetters({
      rxContentDetails: 'rxContent/getRxContentDetails',
      rxMeasureUnitList: 'rxContent/getMeasureUnitList',
    }),
    showRemoveIcon() {
      return !this.firstIndex && this.lastIndex;
    },
  },
  extends: VSelectArrow,
  created() {
    this.contentDetail.bottleWeight = parseInt(this.contentDetail.bottleWeight, 10) || 0;
    this.contentDetail.totalWeight = this.contentDetail.residualWeight
      + this.contentDetail.bottleWeight;
  },
  methods: {
    removeLastMonth() {
      this.$store.commit('rxContent/removeContent', this.contentIndex);
    },
    setContentValue(input, fieldName) {
      this.$store.commit('rxContent/setContentValue', {
        index: this.contentIndex,
        field: fieldName,
        value: input,
      });
    },
  },
  props: {
    contentDetail: {
      type: Object,
      default: () => ({
        contentID: '',
        contentQuantity: 0,
        weightType: 0,
        weightPounds: 0,
        weightOunces: 0,
        containers: 1,
        month: '',
        billUnits: [],
        totalWeight: 0.0,
      }),
    },
    lastIndex: {
      type: Boolean,
      default: false,
    },
    firstIndex: {
      type: Boolean,
      default: false,
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
    emptyBottle: {
      default: false,
      type: Boolean,
    },
    billUnitsAvailable: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    contentDetail: {
      deep: true,
      handler(val) {
        if (this.emptyBottle) {
          this.contentDetail.totalWeight = val.residualWeight + val.bottleWeight;
        }
      },
    },
  },
};
