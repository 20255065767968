//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import lastEmailButton from '../last-email-button/lastEmailButton.vue';

export default {
  name: 'TechnicianLastEmail',
  components: { lastEmailButton },
  computed: {
    ...mapGetters({ getSelectedTech: 'authentication/getSelectedTechnician' }),
  },
  data() {
    return {
      emailLists: localStorage.getItem('lastEmail') ? JSON.parse(localStorage.getItem('lastEmail')) : [],
      activeIndex: null,
    };
  },
  methods: {
    selectedEmailChanges(input) {
      this.activeIndex = input;
      this.$store.dispatch('authentication/updateRecentTechnician', input);
      this.$router.push('login');
    },
    checkChild2Res(data) {
      this.$emit('checkChild1Res', data);
    },
  },
  props: {
    checkHighlight: {
      default: true,
      type: Boolean,
    },
  },

  watch: {
    checkHighlight(newVal) {
      if (newVal === false) {
        this.activeIndex = null;
      }
    },
  },
};
