//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'ScanResponse',
  computed: {
    ...mapGetters({
      stopDetail: 'getStopDetail',
      upcScanResult: 'getUpcScanResponse',
    }),
    responseType() {
      const respType = {};
      const { status } = this.upcScanResult;
      if (status === 'success') {
        respType.label = 'Scan success.';
      } else if (status === 'unknownItem') {
        respType.label = 'Unknown item';
        respType.content = 'We don’t have this item in our system yet. Scan it on the TruckSiS or call your National Account Representative for next steps.';
      } else {
        respType.label = 'No approval found.';
        respType.content = 'No qualifying approval for this generator please call your national account rep';
      }
      return respType;
    },
  },
  methods: {
    done() {
      this.$router.push({ path: 'stop-detail' });
    },
  },
};
