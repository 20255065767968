import scaleService from '../service/approvalScale.service';

const state = {
  weightData: 0.00,
  weightUnit: 4,
  containerTypeList: [],
};

const getters = {
  getWeightData(context) {
    return context.weightData;
  },
  getWeightUnit(context) {
    return context.weightUnit;
  },
};

const mutations = {
  setScaleWeight(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.weightData = payload;
  },
  resetScaleWeight(stateControl) {
    const stateControld = stateControl;
    stateControld.weightData = 0.00;
  },
  setScaleWeightUnit(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.weightUnit = payload;
  },
  resetScaleWeightUnit(stateControl) {
    const stateControld = stateControl;
    stateControld.weightUnit = 4;
  },
  setScaleContainerTypeList(stateControl) {
    const stateControld = stateControl;
    stateControld.containerTypeList = [];
  },
};
const actions = {
  getFloorAction({ commit }) {
    commit('setScaleContainerTypeList');
    scaleService.getFloorScale().then((response) => {
      commit('setScaleWeight', response[0].weight);
    });
  },
  getBenchAction({ commit }) {
    commit('setScaleContainerTypeList');
    scaleService.getBenchScale().then((response) => {
      commit('setScaleWeight', response[0].weight);
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
