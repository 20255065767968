<template>
  <div class="text-center">
    <img
      src="src/assets/images/emblem-white.svg"
      alt="emblem"
    >
    <p class="restarting-text">
      Restarting...
    </p>
  </div>
</template>

<script>

export default {
  name: 'Restart',
};
</script>
