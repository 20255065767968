//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'PastStopContent',
  computed: {
    ...mapGetters(['getLastStopData', 'getStopDetail']),
  },
  data() {
    return {
      unsubscribe: () => {},
    };
  },
  methods: {
    openLastStopInventory() {
      if (this.$store.state.approvalList.approvalDetail.id && (this.$route.name === 'Add Approval' || this.$route.name === 'Consolidate')) {
        this.openModalAndSubscribe();
      } else {
        this.$emit('openInventoryList');
      }
    },
    openModalAndSubscribe() {
      const pageName = this.$route.name === 'Add Approval' ? 'approval' : 'consolidate';
      this.$store.commit('leaveApproval/setLeaveApprovalDialog', {
        isLeaveApprovalDialog: true,
        pageType: pageName,
        fromUrl: this.$route.path,
        redirectUrl: `/stops/detail/${this.getStopDetail.id}`,
        triggerDialog: '',
      });
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === 'leaveApproval/resetLeaveApprovalDialog') {
          this.unsubscribe();
          if (this.$route.name === 'stopDetailWorkArea') {
            this.$emit('openInventoryList');
          }
        }
      });
    },
  },
};
