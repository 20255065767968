
const state = {
  componentName: null,
};
const getters = {
  getComponentName(context) {
    return context.componentName ? context.componentName : 'stop-information';
  },
};
const mutations = {
  setStopInfoWorkArea(stateControl, componentName) {
    const stateControld = stateControl;
    stateControld.componentName = componentName;
  },
};
const actions = {
  setStopInfoWorkArea(context, componentName) {
    context.commit('setStopInfoWorkArea', componentName);
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
