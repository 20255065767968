import TripList from '../views/trips/list/index-mobile.vue';
import StopList from '../views/trip-stops/stopList-mobile.vue';
import StopDetail from '../views/trip-stops/stop-details/stopDetailMobile.vue';
import stopAttachments from '../views/trip-stops/stop-details/stopAttachmentsMobile.vue';
import AddRxItems from '../views/rx-items/add-rx-items/index-mobile.vue';
import QAndA from '../views/q-a-page/index-mobile.vue';
import ViewRxItemDetail from '../views/rx-items/view-approval/index-mobile.vue';
import AddRxItemDetail from '../views/rx-items/add-rx-approval/index-mobile.vue';
import AddRxItemApprovalDetail from '../views/rx-items/approval-details/index-mobile.vue';
import MobileStopInstruction from '../helpers/component/stop-card/beginStopInstruction.vue';
import MobileActiveStopWarning from '../helpers/component/stop-card/activeStopWarning.vue';
import MobileUPCScan from '../views/upc-scan/index.vue';
import MobileScanResponse from '../views/upc-scan/scanResponse.vue';
import MobileDocumentation from '../views/help-page/help-document/help-document-mobile.vue';
import MobileReleaseNotes from '../views/help-page/release-notes/release-notes-mobile.vue';
import MobileContactExamples from '../views/help-page/contact-examples/contact-examples-mobile.vue';
import MobileHelpFeedback from '../views/help-page/feedback/feedback-mobile.vue';

export default [
  {
    path: 'trip-list',
    name: 'Mobile Trip List',
    component: TripList,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'stop-list/:tripDocId/:tripCode',
    name: 'Mobile Stop List',
    component: StopList,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
    props: true,
  },
  {
    path: 'stop-detail',
    name: 'Mobile Stop Detail',
    component: StopDetail,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'attachment',
    name: 'Mobile Attachments',
    component: stopAttachments,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'add-rx-items',
    name: 'Mobile Add RX Items',
    component: AddRxItems,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'q-a-page',
    name: 'Mobile Q And A Page',
    component: QAndA,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'view-rx-item-details',
    name: 'Mobile View Item Details',
    component: ViewRxItemDetail,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'add-rx_approval-card',
    name: 'Mobile Add Rx Item Card',
    component: AddRxItemDetail,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'add-rx_approval-details',
    name: 'Mobile Add Rx Item Details',
    component: AddRxItemApprovalDetail,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'mobile-stop-instruction',
    name: 'Mobile Stop Instruction',
    component: MobileStopInstruction,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'mobile-active-stop-warning',
    name: 'Mobile Active Stop Warning',
    component: MobileActiveStopWarning,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'mobile-upc-scan',
    name: 'Mobile UPC Scan',
    component: MobileUPCScan,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'mobile-upc-scan-response',
    name: 'Mobile UPC Scan Response',
    component: MobileScanResponse,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'mobile-help-documentation',
    name: 'Mobile Help Documentation',
    component: MobileDocumentation,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'mobile-release-notes',
    name: 'Mobile Release Notes',
    component: MobileReleaseNotes,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'mobile-contact-examples',
    name: 'Mobile Contact Examples',
    component: MobileContactExamples,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
  {
    path: 'mobile-help-feedback',
    name: 'Mobile Help Feedback',
    component: MobileHelpFeedback,
    meta: { requiresAuth: true, layout: 'mobile-layout' },
  },
];
