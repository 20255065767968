<template>
  <v-card
    class="mx-auto width-pc-100 height-pc-100 mobile-nav-bar"
    :class="{ 'mobile-bg-color' : !showHeader }"
  >
    <v-app-bar
      dark
      v-if="showHeader"
      class="mobile-navbar-div"
    >
      <img
        src="src/assets/images/mobile-header-help-icon.svg"
        @click="dialog = true"
      >

      <v-spacer />
      <v-toolbar-title>
        {{ pageTitle }}
      </v-toolbar-title>

      <v-spacer />
      <div
        class="mobile-nav-link"
        v-if="pageUrl"
        @click.prevent="gotoPage()"
      >
        {{ urlTitle }}
      </div>
    </v-app-bar>
    <div
      class="mobile-background"
      v-if="showHeader"
    >
      <v-navigation-drawer
        v-model="dialog"
        fixed
        temporary
        left
        stateless
        class="left-drawer-container"
        :class="[{'left-drawer-active': dialog}, {'left-drawer-inactive': !dialog}]"
      >
        <div class="mobile-main-contact-div">
          <span class="mobile-main-contact-text">
            Contact
          </span>
          <img
            class="mobile-side-navbar-close"
            src="src/assets/images/close_help_icon.svg"
            @click="dialog = false"
          >
        </div>
        <div class="mobile-sub-contact-div">
          <v-row no-gutters>
            <v-col class="width-35 mobile-sub-contact-icon">
              <img src="src/assets/images/mobile-header-phone-icon.svg">
            </v-col>
            <v-col>
              <p class="mobile-sub-contact-text1">
                Field Service Support
              </p>
              <p class="mobile-sub-contact-text2">
                (866) 900-3762
              </p>
              <p class="mobile-sub-contact-text1">
                7:00am – 9:00pm Eastern M–F (Saturdays as needed)
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col class="width-35 mobile-sub-contact-icon">
              <img src="src/assets/images/mobile-header-phone-icon.svg">
            </v-col>
            <v-col>
              <p class="mobile-sub-contact-text1">
                Republic Services I.T. Support
              </p>
              <p class="mobile-sub-contact-text2">
                (734) 521-8057
              </p>
            </v-col>
          </v-row>
        </div>
        <v-card
          class="mx-auto no-shadow"
          max-width="100%"
          tile
        >
          <v-list-item
            class="mobile-side-menu-list"
            @click="goToTraining()"
          >
            <v-list-item-content>
              <v-list-item-title>
                Documentation and training
                <img
                  class="mobile-side-menu-icon"
                  src="src/assets/images/left-chevron.svg"
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-side-menu-list"
            @click="goToReleaseNotes()"
          >
            <v-list-item-content>
              <v-list-item-title>
                Release notes
                <img
                  class="mobile-side-menu-icon"
                  src="src/assets/images/left-chevron.svg"
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-side-menu-list"
            @click="goToContactExamples()"
          >
            <v-list-item-content>
              <v-list-item-title>
                Contact examples
                <img
                  class="mobile-side-menu-icon"
                  src="src/assets/images/left-chevron.svg"
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            class="mobile-side-menu-list"
            @click="goToFeedback()"
          >
            <v-list-item-content>
              <v-list-item-title>
                Feedback
                <img
                  class="mobile-side-menu-icon"
                  src="src/assets/images/left-chevron.svg"
                >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <p class="mobile-side-menu-version">
          v1.0.01
        </p>
      </v-navigation-drawer>
      <div class="pt-4 mt-12">
        <router-view />
      </div>
    </div>
    <template v-else>
      <router-view />
    </template>
  </v-card>
</template>
<script>


export default {
  name: 'MobileHeader',
  data() {
    return {
      pageTitle: '',
      pageUrl: '',
      urlTitle: '',
      showHeader: true,
      dialog: false,
    };
  },
  created() {
    this.getPageHeader();
  },
  methods: {
    getPageHeader() {
      const routeArray = this.$route.path.substring(1).split('/');
      const routePath = `${routeArray[0]}/${routeArray[1]}`;
      const noHeaderArray = [
        'Mobile View Item Details',
        'Mobile Add Rx Item Details',
        'Mobile Active Stop Warning',
        'Mobile UPC Scan',
      ];
      if (routePath === 'mobile/trip-list') {
        this.pageTitle = 'Select a trip';
        this.pageUrl = '';
        this.urlTitle = '';
      }
      if (routePath === 'mobile/stop-list') {
        this.pageTitle = 'Select a stop';
        this.pageUrl = '/mobile/trip-list';
        this.urlTitle = 'View trips';
      }
      if (routePath === 'mobile/stop-detail'
        || routePath === 'mobile/mobile-stop-instruction') {
        const { stopDetail } = this.$store.state.stopDetail;
        this.pageTitle = `Stop ${stopDetail.stop_number}`;
        this.pageUrl = `/mobile/stop-list/${stopDetail.trip_id}/${stopDetail.tripCode}`;
        this.urlTitle = 'Stop list';
      }
      if (routePath === 'mobile/mobile-help-documentation' || routePath === 'mobile/mobile-release-notes' || routePath === 'mobile/mobile-contact-examples' || routePath === 'mobile/mobile-help-feedback') {
        this.pageTitle = 'Help & Support';
      }
      if (noHeaderArray.includes(this.$route.name)) {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
    },
    gotoPage() {
      this.$router.push({ path: this.pageUrl });
    },
    goToTraining() {
      this.dialog = false;
      this.$router.push({ path: '/mobile/mobile-help-documentation' });
    },
    goToReleaseNotes() {
      this.dialog = false;
      this.$router.push({ path: '/mobile/mobile-release-notes' });
    },
    goToContactExamples() {
      this.dialog = false;
      this.$router.push({ path: '/mobile/mobile-contact-examples' });
    },
    goToFeedback() {
      this.dialog = false;
      this.$router.push({ path: '/mobile/mobile-help-feedback' });
    },
  },
  watch: {
    $route() {
      this.getPageHeader();
    },
  },
};
</script>
