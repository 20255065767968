import Vue from 'vue';
import documentConstants from '../../constants/document';

Vue.filter('readableAttachmentType', (value) => {
  const attachmentType = documentConstants.documentAttachmentTypes;
  const hasProperty = Object.prototype.hasOwnProperty.call(attachmentType, value);
  if (hasProperty) {
    return attachmentType[value];
  }
  return value;
});
