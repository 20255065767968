//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import RxApprovalListMobile from '../rx-item-lists/index-mobile.vue';

export default {
  name: 'MobileAddRxItems',
  components: {
    RxApprovalListMobile,
  },
  computed: {
    ...mapGetters({
      rxItemMainProp: 'rxApproval/getRxItemMainProp',
    }),
  },
  data() {
    return {
      search: this.search || '',
      stopId: '',
    };
  },
  created() {
    this.$store.commit('rxApproval/setSelectedRxItem', {});
    this.stopId = this.$store.state.stopDetail.stopDetail.id;
    this.$store.commit('loadingSearchIcon/loadingRequest', { status: false, loadingName: 'Loading search results' }, { root: true });
    // this.$store.commit('rxApproval/setRxItemListMobile', [], { root: true });
  },
  methods: {
    searchRxApproval() {
      if (this.search) {
        this.$store.dispatch('rxApproval/mobileSearchRxItem',
          { search: this.search, stopId: this.stopId });
        // this.search = '';
      }
    },
    goToApprovalDetails() {
      this.$router.push('/mobile/stop-detail');
    },
    goBack() {
      this.$router.push('/mobile/stop-detail');
    },
  },
  watch: {
    rxItemMainProp: {
      deep: true,
      handler(val) {
        if (val.showApprovalDetails) this.search = '';
      },
    },
  },
};
