//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'ResetPin',
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  validations: {},
};
