<template>
  <div class="container">
    <v-overlay
      :value="dialog"
      opacity="0.94"
      z-index="3"
    >
      <div class="cancel-content-box">
        <div>
          <h2>Are you sure?</h2>
          <h3>Pickup data will be deleted.</h3>
        </div>
        <div>
          <button @click.prevent="confirmArrivalCancellationAction()">
            Yes
          </button>
          <button @click.prevent="closeArrivalCancellationAction()">
            No
          </button>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>

export default {
  name: 'CancelConfirmation',
  components: { },
  computed: {
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeArrivalCancellationAction() {
      this.$emit('close', false);
    },
    confirmArrivalCancellationAction() {
      const { stopId } = this.$route.params;
      this.$store.dispatch('cancelArriveAction', { id: stopId, status: true, pickupId: this.pickupId }).then(() => {
        this.$emit('close', false);
      });
    },
  },
  props: {
    openDialog: Boolean,
    name: {
      type: String,
      required: true,
    },
    pickupId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
  },
};
</script>
