//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'RxItemIncrementDecrementInput',
  data() {
    return {
      inputVal: this.value,
    };
  },
  methods: {
    increaseValue() {
      if (this.inputVal === 99) {
        this.updateValue(99);
      } else {
        this.updateValue(this.inputVal + 1);
      }
    },
    decreaseValue() {
      if (this.inputVal === 0) {
        this.updateValue(0);
      } else {
        this.updateValue(this.inputVal - 1);
      }
    },
    updateValue(value) {
      const clampedValue = String(value).slice(0, 2);
      this.inputVal = +clampedValue;
      this.$emit('input', +clampedValue);
      this.$forceUpdate();
    },
  },
  props: {
    value: {
      default: 0,
      type: Number,
    },
  },
};
