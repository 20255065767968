<template>
  <div class="col container-select quantity-selector">
    <div class="row">
      <div class="container-qty-group">
        <v-btn
          class="pa-2 qty-group-prepend"
          tile
          outlined
          color="blue-grey lighten-4"
          @click="decreaseQuantity()"
        >
          <img
            src="src/assets/images/minus-outline-icon.svg"
            class="change-icon"
            alt="minus"
          >
        </v-btn>
        <div
          type="text"
          class="input fs-16em qty-input text-center"
        >
          {{ quantityValue }}
        </div>
        <v-btn
          class="pa-2 qty-group-append"
          tile
          outlined
          color="blue-grey lighten-4"
          @click="increaseQuantity()"
        >
          <img
            src="src/assets/images/plus-outline-icon.svg"
            class="change-icon"
            alt="+"
          >
        </v-btn>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContainerQuantity',
  data() {
    return {
      quantityValue: JSON.parse(this.quantity),
    };
  },
  methods: {
    increaseQuantity() {
      if (this.quantityValue < this.maximum) {
        this.quantityValue += 1;
        this.$emit('quantityChanged', this.quantityValue);
      }
    },
    decreaseQuantity() {
      if (this.quantityValue > this.minimum) {
        this.quantityValue -= 1;
        this.$emit('quantityChanged', this.quantityValue);
      }
    },
  },
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    minimum: {
      type: Number,
      default: 0,
    },
    maximum: {
      type: Number,
      default: 100,
    },
  },
};
</script>
