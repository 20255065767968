import userService from '../service/auth.service';


function initialState() {
  const loggedIn = false;
  const user = {};
  const error = '';
  const loginEmail = '';
  const emailExist = Boolean;
  const mobileLoginStatus = Boolean;
  const selectedTechnician = '';
  const restartNow = Boolean;
  const shutdownNow = Boolean;
  return {
    loggedIn,
    user,
    error,
    loginEmail,
    emailExist,
    mobileLoginStatus,
    selectedTechnician,
    restartNow,
    shutdownNow,
  };
}

const state = initialState();

const getters = {
  getloginStatus(stateControl) {
    return stateControl.loggedIn;
  },
  getUser(stateControl) {
    return stateControl.user;
  },
  getError(stateControl) {
    return stateControl.error;
  },
  getToken(stateControl) {
    return stateControl;
  },
  getLoggedEmail(stateControl) {
    return stateControl.loginEmail;
  },
  getEmailExist(stateControl) {
    return stateControl.emailExist;
  },
  getMobileLoginStatus(stateControl) {
    return stateControl.mobileLoginStatus;
  },
  getSelectedTechnician(stateControl) {
    return stateControl.selectedTechnician;
  },
  getRestartValue(stateControl) {
    return stateControl.restartNow;
  },
  getShutdownValue(stateControl) {
    return stateControl.shutdownNow;
  },
};
const mutations = {
  loginRequest(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.loggedIn = true;
    stateControld.user = payload;
  },
  loginSuccess(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.loggedIn = true;
    stateControld.user = payload;
  },
  setUserDetail(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.user = payload.userDetail;
  },
  loginFailure(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.status = {};
    stateControld.error = payload.errorMsg;
  },
  logout(stateControl) {
    const stateControld = stateControl;
    stateControld.loggedIn = false;
    stateControld.user = null;
  },
  resetError(stateControl) {
    const stateControld = stateControl;
    stateControld.status = {};
    stateControld.error = '';
  },
  setLoginEmail(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.loginEmail = payload;
  },
  setEmailExist(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.emailExist = payload;
  },
  loginMobileSuccess(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.mobileLoginStatus = payload;
  },
  updateTechnician(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.selectedTechnician = payload;
  },
  setRestartNow(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.restartNow = payload;
  },
  setShutdownNow(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.shutdownNow = payload;
  },
};
const actions = {
  loginAction({ commit, dispatch }, res) {
    commit('loginMobileSuccess', Boolean);
    userService.login(res.email, res.password)
      .then(
        (response) => {
          commit('loginMobileSuccess', true);
          commit('loginSuccess');
          localStorage.setItem('user', JSON.stringify(response.user).toString());
          dispatch('saveLastEmail', response.user, { root: true });
          commit({
            type: 'trip/setTechID', tripID: response.user.id,
          },
          { root: true });
          commit(
            {
              type: 'setUserDetail',
              userDetail: response.user,
            },
          );
        },
        (errorMessage) => {
          if (process.env.NODE_ENV.includes('handheld')) {
            commit('loginMobileSuccess', false);
          } else {
            commit(
              {
                type: 'loginFailure',
                errorMsg: errorMessage,
              },
            );
            setTimeout(() => commit('resetError'), 3000);
          }
        },
      );
  },
  logoutAction({ commit }) {
    commit('logout');
    commit({
      type: 'trip/setFirstLogin', message: true,
    },
    { root: true });

    commit({
      type: 'trip/setTripCodeError',
      message: '',
    }, { root: true });

    userService.logout();
  },
  setLoginEmailAction({ commit }, res) {
    commit('setLoginEmail', res);
  },
  checkEmailAction({ commit }, res) {
    userService.checkEmail(res.email)
      .then(
        (response) => {
          commit('setEmailExist', true, response);
        },
        (errorMessage) => {
          commit('setEmailExist', false, errorMessage);
        },
      );
  },
  updateRecentTechnician({ commit }, res) {
    commit('updateTechnician', res);
  },
  shutdown({ commit, dispatch }) {
    dispatch('logoutAction');
    return userService.shutdown().then(
      (response) => {
        if (response) {
          commit('setShutdownNow', true);
        }
      },
    );
  },
  restart({ commit, dispatch }) {
    dispatch('logoutAction');
    return userService.restart().then(
      (response) => {
        if (response) {
          commit('setRestartNow', true);
        }
      },
    );
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
