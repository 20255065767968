<template>
  <div>
    <div class="side-navbar side-nav-drawer">
      <v-card height="690px">
        <v-navigation-drawer
          absolute
          permanent
          left
        >
          <button
            class="d-flex side-nav-list"
            @click="gotoTrips"
            :class="{'active-nav-ele': getSideNavBarStates.myTrips }"
          >
            <i class="fas fa-map-marker-alt" />
            <span class="side-nav-label">
              My Trips
            </span>
          </button>
        </v-navigation-drawer>
      </v-card>

      <v-card
        height="430px"
        class="side-nav-bottom-menu"
      >
        <v-navigation-drawer
          absolute
          permanent
          left
        >
          <button
            class="d-flex side-nav-list"
            @click="gotoHelpPage"
            :class="{'active-nav-ele': getSideNavBarStates.helpPage}"
          >
            <div class="side-help-icon" />
            <span class="side-nav-label side-nav-bottom-label">
              Help
            </span>
          </button>
          <button
            class="d-flex side-nav-list mt-5"
            @click="logOut"
          >
            <div class="side-logout-icon" />
            <span class="side-nav-label side-nav-bottom-label m-l-5">
              Logout
            </span>
          </button>
          <div
            class="side-nav-list mt-5 side-nav-power-div"
            @click="powerOffDialog()"
          >
            <img
              alt="power"
              class=""
              src="src/assets/images/power-button-orange.svg"
            >
            <p class="shut-down-restart">
              Power off/restart
            </p>
          </div>
        </v-navigation-drawer>
      </v-card>
    </div>
    <power-off-confirmation
      :open-dialog="isPowerDialog"
      @close-dialog="cancelPowerDialog"
    />
    <log-out-confirmation
      :open-dialog="isOpenDialog"
      @cancel-dialog="cancelDialog"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LogOutConfirmation from '../../helpers/component/logout-confirmation/logoutConfirmation.vue';
import PowerOffConfirmation from '../../helpers/component/power-off-comfirmation/index.vue';

export default {
  name: 'SideMenu',
  components: {
    LogOutConfirmation,
    PowerOffConfirmation,
  },
  computed: {
    ...mapGetters({ modalType: 'leaveApproval/getModalType' }),
    ...mapGetters(['getSideNavBarStates']),
  },
  data() {
    return {
      drawer: true,
      clipped: false,
      isOpenDialog: false,
      isPowerDialog: false,
    };
  },
  methods: {
    gotoTrips() {
      this.$router.push('/trip-list');
    },
    logOut() {
      const { approvalDetail } = this.$store.state.approvalList;
      const { rxApprovalDetail } = this.$store.state.rxApproval;

      if (approvalDetail.id
      && this.$route.name === 'Add Approval') {
        this.$store.commit('leaveApproval/setLeaveApprovalDialog', {
          isLeaveApprovalDialog: true,
          pageType: 'approval',
          fromUrl: '',
          redirectUrl: '',
          triggerDialog: 'logout',
        });
      } else if (this.$route.name === 'Consolidate') {
        this.$store.commit('leaveApproval/setLeaveApprovalDialog', {
          isLeaveApprovalDialog: true,
          pageType: 'consolidate',
          fromUrl: '',
          redirectUrl: '',
          triggerDialog: 'logout',
        });
      } else if (this.$route.name === 'RxConsolidate') {
        this.$store.commit('leaveApproval/setLeaveApprovalDialog', {
          isLeaveApprovalDialog: true,
          pageType: 'rxconsolidate',
          fromUrl: '',
          redirectUrl: '',
          triggerDialog: 'logout',
        });
      } else if (rxApprovalDetail.id
        && !rxApprovalDetail.editType
        && this.$route.name === 'Add Rx Items') {
        this.$store.commit('leaveApproval/setLeaveApprovalDialog', {
          isLeaveApprovalDialog: true,
          pageType: 'rx-approval',
          fromUrl: '',
          redirectUrl: '',
          triggerDialog: 'logout',
        });
      } else {
        this.showLogoutDialog();
        this.resetLeaveApprovalModal();
      }
    },
    cancelPowerDialog(value) {
      this.isPowerDialog = value;
    },
    cancelDialog(value) {
      this.isOpenDialog = value;
      this.resetLeaveApprovalModal();
    },
    gotoHelpPage() {
      this.$router.push('/help');
    },
    showLogoutDialog() {
      this.isOpenDialog = true;
    },
    powerOffDialog() {
      this.isPowerDialog = true;
    },
    resetLeaveApprovalModal() {
      this.$store.commit('leaveApproval/setModalType', '');
    },
  },
  watch: {
    modalType(newVal) {
      if (newVal === 'logout') {
        this.showLogoutDialog();
      }
    },
  },
};
</script>
