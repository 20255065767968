import firestoreDB from './firestoreDB.service';

function getQuestionList(commit, dispatch, stop) {
  firestoreDB.db.collection('stops').doc(stop.stopId).collection('stop_questions').get()
    .then((result) => {
      const docs = firestoreDB.queryToArray(result);
      if (docs.length <= 0) {
        commit('noQuestionFound', 'Failed to fetch');
      } else {
        dispatch('getList', docs);
      }
    });
}

function updateQuestionnaire(stop) {
  return new Promise((resolve) => {
    const batch = firestoreDB.db.batch();
    const updateRef = firestoreDB.db.collection('stops').doc(stop.stop_id)
      .collection('stop_questions').doc(stop.id);
    batch.update(updateRef, { answer_text: stop.answer_text, synced: false });
    batch.commit().then(() => {
      resolve({ stop_id: stop.stop_id });
    }).catch(() => {
      resolve('failed');
    });
  });
}
const stopService = {
  getQuestionList,
  updateQuestionnaire,
};
export default stopService;
