//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import documentTableItem from '../document-table-item/documentTableItem.vue';

export default {
  name: 'DocumentTypeTable',
  components: {
    documentTableItem,
  },
  computed: {
    headerKeys() {
      return Object.keys(this.tableHeaders);
    },
    isAllDocumentItemsSelected() {
      for (let index = 0; index < this.tableRows.length; index += 1) {
        const item = this.tableRows[index];
        if (!item.selected) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    switchDocumentItemsSelection() {
      const documentTypeAndStatus = {
        documentType: this.tableRows[0].type,
        status: false,
      };
      if (!this.isAllDocumentItemsSelected) {
        documentTypeAndStatus.status = true;
      }
      this.$store.dispatch('stopDocuments/updateDocumentsSelectionStatus', documentTypeAndStatus);
    },
  },
  props: {
    tableHeaders: {
      type: Object,
      default() {
        return [];
      },
    },
    tableRows: {
      type: Array,
      default() {
        return [];
      },
    },
    tableTitle: {
      type: String,
      default() {
        return '';
      },
    },
    printingDocListTable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
