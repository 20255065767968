<template>
  <div
    class="stop-card"
    :class="{ 'stop-card-connected': checkConnectedStopStatus(stop)}"
  >
    <div
      class="flex-stop-card"
      :class="{'active-stop': stop.is_active}"
    >
      <div :class="checkForVoidedStop(stop) ? 'divide1-voided' : 'divide1'">
        <div class="d-flex flex-column justify-center divide-space-card">
          <div class="mb-1 stop-card-labelh">
            <label>Stop number</label>
          </div>
          <div>
            <label class="card-value">{{ stop.stop_number }}</label>
          </div>
        </div>
        <div class="d-flex flex-column justify-center">
          <div class="mb-2 stop-card-labelh">
            <label>Type of stop</label>
          </div>
          <div class="d-flex">
            <div class="stopIcon">
              <stopIcon
                v-for="(stop_type, index) in stop.stop_type"
                :key="index"
                :stop-type="stop_type"
                :connected="checkConnectedStopStatus(stop)"
              />
            </div>
          </div>
        </div>
      </div>
      <div :class="checkForVoidedStop(stop) === true ? 'divide2-voided' : 'divide2'">
        <div class="d-flex flex-column justify-space-around">
          <div class="divide-space-card">
            <div class="mb-1 stop-card-labelh">
              <label>Stop name</label>
            </div>
            <div>
              <label class="card-value">{{ stop.generator.name }}</label>
            </div>
          </div>
          <div>
            <div class="mb-1 stop-card-labelh">
              <label>Address</label>
            </div>
            <div>
              <label class="card-value">{{ stop.generator.site_address }}</label>
            </div>
          </div>
        </div>
        <div class="pl-3">
          <div class="d-flex flex-column">
            <label class="mb-1 stop-card-labelh">Phone number</label>
            <label class="card-value divide-space-card">
              {{ stop.generator.phone_number | phoneNumberFormat }}</label>
          </div>
          <div class="margin-top-6">
            <div
              class="record-contact-detail d-flex align-center justify-center"
              v-if="!stop.contacted_at"
            >
              <a
                @click.prevent="recordContact(stop)"
              >Record contact</a>
            </div>
            <div
              v-if="stop.contacted_at"
              class="record-contacted d-flex flex-column"
            >
              <div class="mb-1 align-contact-label">
                Contacted
              </div>
              <div class="d-flex align-center">
                <div class="contacted-timestamp">
                  {{ stop.contacted_at | contactDateFormat }}
                </div>
                <div class="fs-16px">
                  <a
                    class="bb-5"
                    @click.prevent="removeContact(stop)"
                  >Undo</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="divide3">
        <div
          class="d-flex card-voided"
          v-if="checkForVoidedStop(stop)"
        >
          <div>
            <label>Status</label>
          </div>
          <div class="ml-5 voided-status-circle" />
          <div class="ml-2">
            <label>
              <template>
                <span>Voided</span>
              </template>
            </label>
          </div>
        </div>
        <div
          class="d-flex"
          v-else-if="!stop.is_completed"
          :class=" stop.is_active === true ? 'card-active' : 'card-deactive'"
        >
          <div>
            <label>Status</label>
          </div>
          <div
            :style="[stop.is_active ? {'background': '#77BC1F'} : {'background': '#51585D'}]"
            class="ml-5 status-circle"
          />
          <div class="ml-2">
            <label>
              <template v-if="stop.is_active">
                Active
              </template>
              <template v-else>
                <span class="todo-grey-color">To do</span>
              </template>

            </label>
          </div>
        </div>
        <div class="card-btns">
          <div
            v-if="checkForVoidedStop(stop)"
            class="button-voided"
          >
            <button
              class="button-submit"
            >
              Begin stop
            </button>
          </div>
          <div
            v-else
            :class="{'stop-btn-inactive' : trip.is_completed}"
          >
            <button
              class="button-submit"
              :disabled="trip.is_completed"
              @click="gotoStopDetail(stop)"
            >
              {{ stopCardButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import stopIcon from '../../helpers/component/stop-icon/stopIcon.vue';
import connectedStopConstant from '../../constants/connected-stop';

export default {
  name: 'StopCard',
  components: { stopIcon },
  computed: {
    ...mapGetters(['getBeginStopAction', 'getStopDetail']),
    stopCardButtonText() {
      let text = this.stop.is_active || this.stop.is_completed ? 'Continue' : 'Begin stop';
      if (this.trip.is_completed) {
        text = 'Trip complete';
      }
      return text;
    },
  },
  methods: {
    startStop(stop) {
      const date = new Date().toUTCString();
      const stopObj = stop;
      stopObj.stop_active = true;
      stopObj.stop_active_time = date;
      this.$store.dispatch('changeStopActive', stopObj).then(() => {
      });
    },
    gotoStopDetail(stop) {
      this.$store.dispatch('getStopDetailAction', {
        stopID: stop.id,
        type: 'popup',
      });
      this.$store.commit('setStopInventoryLists', []);
      this.$store.commit('resetMostOccuringProfiles');
      this.$store.commit('resetAccumulationContainerList');
      this.$store.commit('attachment/resetPrintedDocumentList');
      this.$store.commit('attachment/resetPrintedDocumentAndAttachmentMap');
      this.$store.commit('setStopInventoryActiveTab', null);
      this.$store.commit('resetExpectedPickupList');
      this.$store.commit('resetAccumulationPickupList');
      this.$store.commit('setAccumulationContainerApiCallStatus', null);
      this.$store.dispatch('resetApprovalAddedFromContainer');
    },
    recordContact(stop) {
      if (!this.checkForVoidedStop(stop)) {
        const params = {};
        params.stopID = stop.id;
        params.tripID = stop.trip_id;
        params.type = 'list';
        this.$store.dispatch('contactTimestamp/recordContactAction', params);
      }
    },
    removeContact(stop) {
      if (!this.checkForVoidedStop(stop)) {
        const params = {};
        params.stopID = stop.id;
        params.tripID = stop.trip_id;
        params.type = 'list';
        this.$store.dispatch('contactTimestamp/removeContactAction', params);
      }
    },
    undoStop(stopID) {
      this.$store.dispatch('stopArriveCancelAction', stopID);
    },
    getStopButtonLabel(stop) {
      let labelName = 'Begin stop';
      if (stop.is_active) {
        labelName = 'Continue';
      }
      if (stop.is_completed) {
        labelName = 'View stop';
      }
      return labelName;
    },
    checkConnectedStopStatus(stop) {
      if (stop.connected_status) {
        if (stop.connected_status === connectedStopConstant.status.NOT_CONNECTED) {
          return false;
        }
        return true;
      }
      return false;
    },
    checkForVoidedStop(stop) {
      return (stop.voided || stop.use_received_completed_stop) && !stop.start_time;
    },
  },
  props: {
    stop: {
      type: Object,
      required: true,
    },
    trip: {
      type: Object,
      required: true,
    },
  },
  watch: {
    getBeginStopAction(newVal) {
      if (newVal) {
        this.$router.push(`/stop/pickup-data/${this.getStopDetail.id}`);
      }
    },
  },
};
</script>
