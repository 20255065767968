<template>
  <div
    class="approval-list-row d-flex"
    @click.prevent="assignApproval()"
  >
    <div class="flex-5 approval-list-description">
      <div class="mleft">
        <label>{{ approvalDetail.description }} </label>
      </div>
    </div>
    <div class="flex-5 approval-list-val ml-2">
      <label>{{ approvalDetail.approval_code }} </label>
    </div>
    <div class="flex-2 approval-list-val ml-2">
      <label>{{ approvalDetail.manifest_type }}</label>
    </div>
    <div class="flex-4 d-flex align-end justify-center ml-1">
      <button
        class="btn-submit"
      >
        Add approval
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ApprovalListCard',
  methods: {
    assignApproval() {
      this.approvalDetail.bill_units = JSON.parse(this.approvalDetail.bill_units);
      this.$store.dispatch('assignApprovalAction', this.approvalDetail);
      this.$router.push({ name: 'Add Approval' });
    },
  },
  props: {
    approvalDetail: {
      type: Object,
      required: true,
    },
  },
};
</script>
