<template>
  <div>
    <v-card class="enter-manifest-number-card">
      <v-card-title>Enter the manifest number once the document finishes printing.</v-card-title>
      <v-card-text>
        <label class="manifest-label">Manifest number</label>
        <v-row>
          <v-col
            cols="4"
            class="manifest-input"
          >
            <div
              v-if="manifestNumberErrorMsg"
              class="manifest-number-error"
              :class="isManifestForHazItem ? 'haz-manifest-error' : 'non-haz-manifest-error'"
            >
              {{ manifestNumberErrorMsg }}
            </div>
            <div
              v-else-if="previousManifestNumberErrorMsg"
              class="manifest-number-error previous-manifest-number-error"
            >
              {{ previousManifestNumberErrorMsg }}
            </div>
            <input
              class="input"
              type="text"
              v-model="manifestNumber"
              @click="openManifestNumberKeyboard"
              @selectstart.prevent
              @paste.prevent
              @copy.prevent
              @cut.prevent
              @drag.prevent
              @drop.prevent
              v-unSelect
            >
          </v-col>
          <v-col
            cols="2"
            class="manifest-select-col"
            v-if="isManifestForHazItem"
          >
            <v-select
              ref="printerType"
              outlined
              :items="printerTypes"
              height="49"
              v-model="currentPrinterType"
              class="manifest-select"
              append-icon="fa-angle-down"
              :menu-props="{ offsetY: true }"
              @click:append="closeSelectDropdown('printerType')"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="cancel-btn"
          @click="openCancelModal"
        >
          Cancel print queue
        </v-btn>
        <v-btn
          @click="continuePrintFlow"
          class="next-btn"
          :class="{ disabled: (!manifestNumber) }"
        >
          {{ completeButtonText }}
        </v-btn>
        <v-btn
          class="redo-btn"
          @click="$emit('reprintDocument')"
        >
          <img
            src="src/assets/images/refresh-icon.svg"
            alt="recycle"
          >
          Reprint document
        </v-btn>
      </v-card-actions>
    </v-card>
    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="modelValue"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @enterClicked="enterClicked"
    />
    <confirm-modal
      v-if="shouldShowConfirmationModal"
      :dialog-data="modalData"
      :button-data="modalButtons"
      :dialog-property="dialogProperty"
      @cancel="closeCancelModal"
      @confirm="resetPrintFlow"
    />
    <uniqueManifestNumberWarning />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KeyboardDialog from '../virtual-keyboard/virtualKeyboardDialog.vue';
import ConfirmModal from '../confirmation-dialog/confirmation.vue';
import VSelectArrow from '../../utils/v-select-arrow';
import uniqueManifestNumberWarning from '../uniqueManifestNumber/uniqueManifestNumberWarning.vue';

export default {
  components: {
    KeyboardDialog,
    ConfirmModal,
    uniqueManifestNumberWarning,
  },
  computed: {
    ...mapGetters({ manifestUniqueNumber: 'stopDocuments/getIsUniqueNumber' }),
    completeButtonText() {
      if (this.nextDocumentToPrint) {
        return 'Next document set';
      }
      return 'Finished printing';
    },
  },
  data() {
    return {
      printerTypes: [{ text: 'JJK' }, { text: 'FLE' }, { text: 'GBF' }],
      currentPrinterType: this.setCurrentPrinterType(),
      manifestNumber: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      shouldShowConfirmationModal: false,
      modalData: {
        heading: 'Cancel print queue',
        subHeading: 'Instructions',
        description:
          'To cancel the current print job, you must press the Cancel '
          + 'button on the printer itself. Would you like to cancel the current print queue?',
      },
      modalButtons: {
        buttonConfirmText: 'Cancel print queue',
        buttonCancelText: 'Back',
        buttonConfirmType: 'btn-primary',
        buttonConfirmVisibility: true,
        buttonCancelVisibility: true,
      },
      manifestNumberErrorMsg: '',
      previousManifestNumberErrorMsg: '',
      dialogProperty: {
        modalWidth: 840,
        modalHeight: 350,
      },
    };
  },
  extends: VSelectArrow,
  methods: {
    hazManifestNumberIsValid() {
      if (this.manifestNumber.length === 9) {
        this.manifestNumberErrorMsg = '';
        return true;
      }
      this.manifestNumberErrorMsg = 'Haz manifest # must be 9 digits';
      return false;
    },
    nonHazManifestNumberIsValid() {
      if (this.manifestNumber.length >= 5 && this.manifestNumber.length <= 10) {
        this.manifestNumberErrorMsg = '';
        return true;
      }
      this.manifestNumberErrorMsg = 'Non-Haz manifest # must be between 5-10 characters';
      return false;
    },
    checkHazManifestNumber() {
      return this.setErrorMessage(this.manifestNumber + this.currentPrinterType);
    },
    checkNonHazManifestNumber() {
      return this.setErrorMessage(this.manifestNumber);
    },
    setErrorMessage(newManifestNumber) {
      const previousManifestNumber = this.printableDocuments[0].name;
      if (newManifestNumber === previousManifestNumber) {
        this.previousManifestNumberErrorMsg = 'Manifest # already exists. Please use another number.';
        return false;
      }
      this.previousManifestNumberErrorMsg = '';
      return true;
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    setCurrentPrinterType() {
      if (localStorage.getItem('manifestSuffix')) {
        return localStorage.getItem('manifestSuffix');
      }

      return 'JJK';
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.manifestNumberErrorMsg = '';
      this.manifestNumber = value.input;
    },
    openCancelModal() {
      this.shouldShowConfirmationModal = true;
    },
    closeCancelModal() {
      this.shouldShowConfirmationModal = false;
    },
    resetPrintFlow() {
      this.$emit('reset', true);
    },
    continuePrintFlow() {
      let printManifestNumber = '';
      if (this.manifestNumber && this.isManifestForHazItem && this.hazManifestNumberIsValid()) {
        if (this.checkHazManifestNumber()) {
          let combinedManifestNumber = '';
          combinedManifestNumber = this.manifestNumber + this.currentPrinterType;
          printManifestNumber = combinedManifestNumber;
        }
      }
      if (this.manifestNumber && !this.isManifestForHazItem && this.nonHazManifestNumberIsValid()) {
        if (this.checkNonHazManifestNumber()) {
          printManifestNumber = this.manifestNumber;
        }
      }
      if (printManifestNumber !== '') {
        const stopId = this.$store.getters.getStopDetail.id;
        this.$store.dispatch('stopDocuments/checkUniqueManifestNumber', {
          stopId,
          manifestNumber: printManifestNumber,
          printableDocuments: this.printableDocuments,
        }).then(() => {
          if (this.manifestUniqueNumber.isUnique) {
            localStorage.setItem('manifestSuffix', this.currentPrinterType);
            this.$emit('printNextDoc', printManifestNumber);
          } else {
            this.manifestNumber = '';
          }
        });
      }
    },
    openManifestNumberKeyboard() {
      if (this.isManifestForHazItem) {
        this.checkDialog('numeric', 'manifestnumber', 'Manifest Number', this.manifestNumber);
      } else {
        this.checkDialog('alphaNumeric', 'manifestnumber', 'Manifest Number', this.manifestNumber);
      }
    },
  },
  props: {
    isManifestForHazItem: {
      type: Boolean,
      default: false,
    },

    nextDocumentToPrint: {
      type: Boolean,
      default: false,
    },

    printableDocuments: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
