<template>
  <div class="notes-main-div">
    <v-row>
      <v-col
        cols="12"
        lg="6"
      >
        <p class="notes-title">
          Notes
        </p>
      </v-col>
      <v-col
        cols="12"
        lg="6"
      >
        <p
          class="common-notes"
        >
          Common Notes
        </p>
        <v-select
          ref="contentType"
          :items="getCommonNotesList"
          item-value="id"
          item-text="notes"
          v-model="selectNotes"
          @change="getNotesDetail()"
          return-object
          outlined
          append-icon="fa-angle-down"
          :menu-props="{ offsetY: true }"
          background-color="white"
          placeholder="Please select..."
          :disabled="stopDetail.is_completed"
          @click:append="closeSelectDropdown('contentType')"
          class="notes-select-field resize-v-select"
        />
      </v-col>
    </v-row>
    <div
      class="notes-textarea-div"
      :class="{'textareaError' : newNotes.length > 500 }"
    >
      <div
        v-if="notesError"
        class="textareaErrorTooltip"
      >
        <strong class="forgot-font-weight">!</strong>
        &nbsp; &nbsp;Notes should not exceed 500 characters.
      </div>
      <textarea
        class="notes-textarea"
        rows="3"
        placeholder="Enter notes here."
        v-model="newNotes"
        :disabled="stopDetail.is_completed"
        @click="checkDialog('alphaNumericNotes','notes','Add a new note',newNotes)"
        @selectstart.prevent
        @paste.prevent
        @copy.prevent
        @cut.prevent
        @drag.prevent
        @drop.prevent
      />
      <span
        class="notes-textarea-count"
      >
        {{ newNotes.length }}/500
      </span>
    </div>
    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="modelValue"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @keyPressed="keyPressed"
      @enterClicked="enterClicked"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import KeyboardDialog from '../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import VSelectArrow from '../../helpers/utils/v-select-arrow';

export default {
  name: 'Notes',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({
      getCommonNotesList: 'notes/getCommonNotes',
      getStopNotesDetail: 'notes/getStopNotes',
      stopDetail: 'getStopDetail',
    }),
  },
  data() {
    return {
      newNotes: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      commonNotesList: '',
      selectNotes: '',
      notesError: '',
    };
  },
  extends: VSelectArrow,
  beforeMount() {
    const { stopId } = this.$route.params;
    this.$store.dispatch('notes/getAllCommonNotes');
    this.$store.dispatch('notes/getStopNotes', stopId);
  },
  methods: {
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    keyPressed(value) {
      this.newNotes = value.input;
      if (this.newNotes.length > 500) {
        this.notesError = true;
      } else {
        this.notesError = false;
      }
    },
    enterClicked(value) {
      this.newNotes = value.input;
      const { stopId } = this.$route.params;
      const data = {
        stopsId: stopId,
        notes: this.newNotes,
      };
      if (this.newNotes.length <= 500) {
        this.$store.dispatch('notes/saveStopNotes', data);
      }
    },
    getNotesDetail() {
      this.newNotes = `${this.newNotes} ${this.selectNotes.notes}`;
      if (this.newNotes.length <= 500) {
        const { stopId } = this.$route.params;
        const data = {
          stopsId: stopId,
          notes: this.newNotes,
        };
        this.$store.dispatch('notes/saveStopNotes', data);
      }
      this.selectNotes = '';
      if (this.newNotes.length > 500) {
        this.notesError = true;
      } else {
        this.notesError = false;
      }
    },
  },
  watch: {
    getCommonNotesList(newVal) {
      this.commonNotesList = newVal;
    },
    getStopNotesDetail(newVal) {
      if (newVal.notes) {
        this.newNotes = newVal.notes;
      }
    },
  },
};
</script>
