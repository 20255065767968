<template>
  <div class="container stop-summary-table">
    <table cellspacing="0">
      <col width="900">
      <col width="270">
      <col width="270">
      <tr
        class="summary-table-header-row"
      >
        <th class="label profile-label">
          {{ tableHeader }}
        </th>
        <th class="label weight-label">
          RetailSiS Weight
        </th>
        <th class="label weight-label">
          Manifest Weight
        </th>
      </tr>
      <tr
        is="stopSummaryItem"
        name="stopSummaryItem"
        v-for="(summaryData, index) in profileList"
        :summary-data="summaryData"
        :key="index"
      />
    </table>
  </div>
</template>

<script>
import stopSummaryItem from '../stop-summary-item/stopSummaryItem.vue';

export default {
  name: 'StopSummaryTable',
  components: { stopSummaryItem },
  props: {
    profileList: {
      type: Array,
      default() {
        return [];
      },
    },
    tableHeader: {
      type: String,
      default: '',
    },
  },
};
</script>
