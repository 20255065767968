//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import KeyboardDialog from '../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import rxSearchItems from '../rx-search-items/index.vue';
import rxContentList from '../../../helpers/component/rx-content/rxContentList.vue';
import ApprovalDetailDrawer from '../../trip-stops/stop-details/approvals/ApprovalDetailDrawer.vue';

export default {
  name: 'AddRxItems',
  components: {
    KeyboardDialog,
    rxContentList,
    rxSearchItems,
    ApprovalDetailDrawer,
  },
  computed: {
    ...mapGetters({
      getRxDetail: 'rxApproval/getRxApprovalDetail',
      getApprovalNotes: 'rxApproval/getApprovalNotes',
      rxApprovalDetail: 'rxApproval/getRxApprovalDetail',
      rxEditApproval: 'rxApproval/getRxEditApproval',
      addedNotesChanges: 'rxApproval/getAddedNotes',
      showContent: 'rxApproval/getShowContent',
      rxEmptyBottle: 'rxApproval/getRxEmptyBottle',
      approvalDrawerDetails: 'getApprovalDrawerDetails',
    }),
  },
  data() {
    return {
      input: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      search: '',
      modelName: '',
      labelName: '',
      clearApprovalChoice: '',
      keypadIndex: 0,
      modelValue: '',
      showNotesList: false,
      selectedNotes: '',
      rxCancelDialog: false,
      searchClick: false,
      showEmptyBottles: false,
      allowEmpty: false,
      drawer: false,
    };
  },
  created() {
    let rxItemId = 0;
    if (this.getRxDetail.rx_item && this.getRxDetail.rx_item.id) {
      rxItemId = this.getRxDetail.rx_item.id;
      this.selectedNotes = this.getRxDetail.selected_notes;
    }
    this.$store.dispatch('rxApproval/getApprovalNotes', { id: rxItemId });
    this.getContentDetailsList(this.getRxDetail);
    if (this.rxEditApproval) {
      this.$store.dispatch('getApprovalDetailForDrawer', this.rxEditApproval.approval_id, { root: true });
    }
  },
  methods: {
    checkDialog(KeyboardType, modelName, labelName, index,
      clearApprovalChoice, allowEmpty = false) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.keypadIndex = index;
      this.clearApprovalChoice = clearApprovalChoice;
      this.allowEmpty = allowEmpty;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    addEmptyBottles() {
      this.showEmptyBottles = true;
      this.$store.dispatch('rxApproval/getEmptyBottles', this.$route.params.stopId);
      this.$store.commit('rxContent/enablePreviousMonthBtn');
    },
    removeEmptyBottles() {
      this.$store.dispatch('rxApproval/resetAllContentData');
      this.clearSearch();
    },
    enterClicked(value) {
      if (value.modelName === 'search') {
        this.search = value.input;
        this.searchClick = true;
      } else if (value.modelName === 'note') {
        this.selectedNotes = value.input;
        this.$store.commit('rxApproval/setSelectedNotes', this.selectedNotes);
      }
    },
    checkDialogSearch(ev) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = ev.alphaNumeric;
      this.modelName = ev.search;
      this.labelName = ev.labelName;
      this.clearApprovalChoice = ev.clearApprovalChoice;
    },
    getContentDetailsList(rxApprovalDetail) {
      if (rxApprovalDetail.rx_item && rxApprovalDetail.rx_item.id) {
        this.$store.dispatch('rxApproval/getContentDetailsList');
      }
    },
    viewNotesList() {
      this.showNotesList = !this.showNotesList;
    },
    selectNotes(notes, id) {
      this.$store.commit('rxApproval/setSelectedNotes', notes);
      this.selectedNotes = this.getRxDetail.selected_notes;
      if (id) {
        this.getRxDetail.selected_notes = notes;
      }
    },
    clearSearch() {
      this.search = '';
    },
    getRxItemValue(rxDetail, actualField, fieldName) {
      let returnValue = 'Search to add...';
      if (rxDetail && actualField && actualField[fieldName]) {
        returnValue = actualField[fieldName];
      } else if (rxDetail.id) {
        returnValue = 'N/A';
      }
      return returnValue;
    },
    editNote() {
      if (this.getRxDetail.id) {
        if (this.selectedNotes) {
          this.checkDialog('alphaNumeric', 'note', 'Edit note', 0, this.selectedNotes.toString(), true);
        } else {
          this.viewNotesList();
        }
      }
    },
    keyPressed(value) {
      this.search = value.input;
    },
    closeApprovalDrawer() {
      this.drawer = false;
    },
  },
  watch: {
    getRxDetail(newVal) {
      const rxItemId = newVal.rx_item.id || 0;
      this.$store.dispatch('rxApproval/getApprovalNotes', { id: rxItemId });
      this.getContentDetailsList(newVal);
      if (!newVal.selected_notes) {
        this.selectedNotes = '';
      } else {
        this.selectedNotes = newVal.selected_notes;
      }
    },
    addedNotesChanges(newVal) {
      this.selectNotes(newVal);
    },
  },
};
