//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'LeaveApprovalDialog',
  computed: {
    ...mapGetters({ leaveApproval: 'leaveApproval/getLeaveApprovalDetail' }),
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    stayPage() {
      if (this.leaveApproval.pageType === 'rx-approval'
      || this.leaveApproval.pageType === 'rxConsolidate') {
        this.$store.dispatch('setStopInfoWorkArea', this.$route.meta.componentName);
        this.$store.dispatch('rxApprovalList/getRxApprovalList', this.$route.params.stopId);
      }
      if (this.leaveApproval.triggerDialog !== 'goPreviousRoute') {
        this.$router.push({ path: this.leaveApproval.fromUrl });
      }
      this.$store.commit('leaveApproval/resetLeaveApprovalDialog');
    },
    resetApprovalBuilder() {
      this.$store.dispatch('rxApproval/resetAllContentData');
      if (this.leaveApproval.pageType === 'consolidate' || this.leaveApproval.pageType === 'rxConsolidate') {
        const consolidateDetail = this.$store.state.approvalList.consolidateApproval;
        if (consolidateDetail && consolidateDetail.id) {
          const options = {
            id: consolidateDetail.id,
            stopId: consolidateDetail.stop_id,
          };
          this.$store.dispatch('consolidate/removeConsolidationSavedData', options);
          this.$store.commit('resetConsolidateApproval');
          this.$store.commit('consolidate/resetConsolidation');
        }
        this.$store.commit('rxApproval/resetApprovalDetail');
        this.$store.commit('rxContent/resetContent');
      }
      if (this.leaveApproval.pageType === 'consolidate'
      && this.leaveApproval.triggerDialog) {
        if (this.leaveApproval.triggerDialog === 'goPreviousRoute') {
          this.$router.go(-1);
        } else {
          this.$router.push({ name: 'Add Approval' });
        }
      }
      if (this.leaveApproval.pageType === 'rxConsolidate'
      && this.leaveApproval.triggerDialog) {
        if (this.leaveApproval.triggerDialog === 'goPreviousRoute') {
          this.$store.dispatch('setStopInfoWorkArea', this.$route.meta.componentName);
          this.$store.commit('rxApprovalList/setRxApprovalList', []);
          this.$store.dispatch('rxApprovalList/getRxApprovalList', this.$route.params.stopId);
          this.$router.go(-1);
        } else {
          this.$router.push({ name: 'RxConsolidate' });
        }
      }
      if (this.leaveApproval.pageType === 'rx-approval') {
        this.$store.commit('rxApproval/resetApprovalDetail');
      }
      if (this.leaveApproval.triggerDialog) {
        this.$store.commit('leaveApproval/setModalType', this.leaveApproval.triggerDialog);
      }
      if (this.leaveApproval.triggerDialog !== 'goPreviousRoute') {
        this.$store.commit('resetApprovalDetail');
        this.$store.commit('resetScaleWeight');
        this.$store.commit('resetScaleWeightUnit');
      }
      this.$router.push({ path: this.leaveApproval.redirectUrl });
      this.$store.commit('leaveApproval/resetLeaveApprovalDialog');
    },
    getPageTitle() {
      let pageTitle = 'Leave this approval?';
      if (this.leaveApproval.pageType === 'consolidate') {
        pageTitle = 'Leave consolidation?';
      } else if (this.leaveApproval.pageType === 'rx-approval') {
        pageTitle = 'Leave this item?';
      } else if (this.leaveApproval.pageType === 'rxConsolidate') {
        pageTitle = 'Leave this consolidation?';
      }
      return pageTitle;
    },
    getPageContent() {
      let pageContent = 'The changes you’ve made to this entry will not be saved.';
      if (this.leaveApproval.pageType === 'consolidate') {
        pageContent = 'Consolidation changes you’ve made will not be saved.';
      } else if (this.leaveApproval.pageType === 'rx-approval') {
        pageContent = 'Changes you’ve made to this item will not be saved';
      } else if (this.leaveApproval.pageType === 'rxConsolidate') {
        pageContent = 'Consolidation changes will not be saved.';
      }
      return pageContent;
    },
    getButtonLabel() {
      let buttonLabel = 'Leave approval';
      let buttonLabel2 = 'Stay';
      if (this.leaveApproval.pageType === 'consolidate') {
        buttonLabel = 'Leave consolidation';
        buttonLabel2 = 'Stay on page';
      } else if (this.leaveApproval.pageType === 'approval') {
        buttonLabel2 = 'Stay on page';
      } else {
        buttonLabel = 'Leave';
      }
      return { stayPage: buttonLabel2, leavePage: buttonLabel };
    },
  },

  watch: {
    leaveApproval(newVal) {
      this.dialog = newVal.showApprovalDialog;
    },
  },
};
