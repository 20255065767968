<template>
  <div>
    <div class="w100-imp h48">
      <p class="mobile-rx-approval-item-details-title float-left">
        {{ emptyBottle ? 'Weight and quantity' : 'Content details' }}
      </p>
      <div class="post">
        <p class="mobile-month-name">
          {{ contentDetail.month }}
        </p>
        <img
          v-if="showRemoveIcon"
          class="mbl-remove-icon"
          alt="remove"
          src="src/assets/images/minus-circle-icon.svg"
          @click="removeLastMonth()"
        >
      </div>
    </div>
    <div v-if="!emptyBottle">
      <div class="mobile-rx-item-notes-dropdown">
        <v-select
          ref="contentDetail"
          class="input-type-select dropdown-color-grey"
          v-model="contentDetail.contentID"
          :items="rxContentDetails"
          item-value="id"
          item-text="contentDetail"
          outlined
          append-icon="fa-angle-down"
          :menu-props="{ offsetY: true }"
          background-color="white"
          placeholder="Select..."
          @click:append="closeSelectDropdown('contentDetail')"
        />
      </div>
      <div class="w48">
        <p class="mobile-rx-approval-item-details-sub-title">
          Quantity
        </p>
        <rxItemIncrementDecrementInput
          v-model="contentDetail.contentQuantity"
        />
      </div>
      <p class="mobile-rx-approval-weight-quantity">
        Weight and quantity
      </p>
      <div class="add-rxitem-row">
        <div class="w48 float-left">
          <p class="mobile-rx-approval-item-details-sub-title">
            Pounds
          </p>
          <rxItemIncrementDecrementInput
            v-model="contentDetail.weightPounds"
          />
        </div>
        <div class="w48 rx-items-float-right">
          <p class="mobile-rx-approval-item-details-sub-title">
            Ounces
          </p>
          <rxItemIncrementDecrementInput
            v-model="contentDetail.weightOunces"
          />
        </div>
      </div>
      <div class="w48">
        <p class="mobile-rx-approval-item-details-sub-title">
          # of containers
        </p>
        <rxItemIncrementDecrementInput
          v-model="contentDetail.containers"
        />
      </div>
    </div>
    <div v-else>
      <div class="add-rxitem-row">
        <div class="w60 float-left">
          <p class="mobile-rx-approval-item-details-sub-title">
            Number of empty bottles
          </p>
          <rxItemIncrementDecrementInput
            v-model="contentDetail.noOfEmptyBottles"
            class="w80"
          />
        </div>
        <div class="w40 rx-items-float-right">
          <p class="mobile-rx-approval-item-details-sub-title">
            Residual weight
          </p>
          <p>
            {{ contentDetail.residualWeight }} lb
          </p>
        </div>
      </div>
      <div class="add-rxitem-row">
        <div class="w48 float-left">
          <p class="mobile-rx-approval-item-details-sub-title">
            Bottle weight
          </p>
          <rxItemIncrementDecrementInput
            v-model="contentDetail.bottleWeight"
          />
        </div>
        <div class="mobile-measure-unit-dropdown">
          <v-select
            ref="weightUnit"
            class="input-type-select dropdown-color-grey"
            v-model="contentDetail.weightUnit"
            :items="rxMeasureUnitList"
            item-value="id"
            item-text="unit"
            outlined
            append-icon="fa-angle-down"
            :menu-props="{ offsetY: true }"
            background-color="white"
            @click:append="closeSelectDropdown('weightUnit')"
          />
        </div>
      </div>
      <div class="add-rxitem-row">
        <div>
          <p class="mobile-rx-approval-item-details-sub-title">
            Residual weight + bottle weight
          </p>
          <p>
            {{ contentDetail.totalWeight }} lb
          </p>
        </div>
      </div>
      <mobileBillUnits
        v-if="billUnitsAvailable"
        :bill-units="contentDetail.billUnits"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import VSelectArrow from '../../../helpers/utils/v-select-arrow';
import RxItemIncrementDecrementInput from '../../../helpers/component/mobile-rx-inc-dec-input/mobileRxIncDecInput.vue';
import MobileBillUnits from './billUnits.vue';


export default {
  name: 'AddRxItemContentDetail',
  components: {
    RxItemIncrementDecrementInput,
    MobileBillUnits,
  },
  computed: {
    ...mapGetters({
      rxContentDetails: 'rxContent/getRxContentDetails',
      rxMeasureUnitList: 'rxContent/getMeasureUnitList',
    }),
    showRemoveIcon() {
      return !this.firstIndex && this.lastIndex;
    },
  },
  extends: VSelectArrow,
  created() {
    this.contentDetail.bottleWeight = parseInt(this.contentDetail.bottleWeight, 10) || 0;
    this.contentDetail.totalWeight = this.contentDetail.residualWeight
      + this.contentDetail.bottleWeight;
  },
  methods: {
    removeLastMonth() {
      this.$store.commit('rxContent/removeContent', this.contentIndex);
    },
    setContentValue(input, fieldName) {
      this.$store.commit('rxContent/setContentValue', {
        index: this.contentIndex,
        field: fieldName,
        value: input,
      });
    },
  },
  props: {
    contentDetail: {
      type: Object,
      default: () => ({
        contentID: '',
        contentQuantity: 0,
        weightType: 0,
        weightPounds: 0,
        weightOunces: 0,
        containers: 1,
        month: '',
        billUnits: [],
        totalWeight: 0.0,
      }),
    },
    lastIndex: {
      type: Boolean,
      default: false,
    },
    firstIndex: {
      type: Boolean,
      default: false,
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
    emptyBottle: {
      default: false,
      type: Boolean,
    },
    billUnitsAvailable: {
      default: false,
      type: Boolean,
    },
  },
  watch: {
    contentDetail: {
      deep: true,
      handler(val) {
        if (this.emptyBottle) {
          this.contentDetail.totalWeight = val.residualWeight + val.bottleWeight;
        }
      },
    },
  },
};
</script>
