<template>
  <div class="container expected-pickup-table">
    <table cellspacing="0">
      <col width="90">
      <col width="950">
      <tr
        class="expected-table-header-row"
      >
        <th class="label seen-label">
          Seen on
        </th>
        <th class="label profile-label">
          Most common profiles historically seen at this generator
        </th>
      </tr>
      <tr
        is="expectedPickupItem"
        name="expectedPickupItem"
        v-for="(expectedData, index) in expectedPickupList"
        :expected-data="expectedData"
        :key="index"
      />
    </table>
  </div>
</template>

<script>
import expectedPickupItem from '../expected-pickup-item/expectedPickupItem.vue';

export default {
  name: 'ExpectedPickupTable',
  components: { expectedPickupItem },
  props: {
    expectedPickupList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
