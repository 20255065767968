import contactService from '../service/stopContact.service';
import toastMessage from './toast-message';

const actions = {
  recordContactAction({ commit, dispatch }, res) {
    return contactService.createContact(res.stopID)
      .then(
        () => {
          const messageData = 'Contact recorded successfully';
          toastMessage.showToastErrorMessage(commit, messageData, 'success');
          if (res.type === 'list') {
            dispatch('getStopDetailsAction', res.tripID, { root: true });
          }
          if (res.type === 'view') {
            dispatch('getStopDetailAction', {
              stopID: res.stopID,
              type: '',
            }, { root: true });
          }
        },
        (errorMessage) => {
          toastMessage.showToastErrorMessage(commit, errorMessage, 'error');
          return errorMessage;
        },
      );
  },

  removeContactAction({ commit, dispatch }, res) {
    return contactService.deleteContact(res.stopID)
      .then(
        () => {
          const messageData = 'Contact removed successfully';
          toastMessage.showToastErrorMessage(commit, messageData, 'success');
          if (res.type === 'list') {
            dispatch('getStopDetailsAction', res.tripID, { root: true });
          }
          if (res.type === 'view') {
            dispatch('getStopDetailAction', {
              stopID: res.stopID,
              type: '',
            }, { root: true });
          }
        },
        (errorMessage) => {
          toastMessage.showToastErrorMessage(commit, errorMessage, 'error');
          return errorMessage;
        },
      );
  },

};
export default {
  namespaced: true,
  actions,
};
