import Vue from 'vue';

Vue.directive('unSelect', {
  bind(el) {
    el.addEventListener('select', (event) => {
      const ev = event;
      ev.target.selectionStart = ev.target.selectionEnd;
    }, false);
  },
});
