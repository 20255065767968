<template>
  <div
    class="loader-section"
    v-if="loadingStatus.status"
  >
    <img
      src="src/assets/images/loader.png"
      alt="loading-icon"
      class="loading-spinner"
    >
    <span class="pl-4 loading-description">
      {{ loadingStatus.loadingName }}
    </span>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  name: 'SearchLoader',
  computed: {
    ...mapGetters({ loadingStatus: 'loadingSearchIcon/getLoadingStatus' }),
  },
  methods: {
    goToBackPage() {
      this.$emit('goBack');
    },
  },
  props: {
    sdsUrlLink: {
      type: String,
      default: '',
    },
  },
};
</script>
