//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'NoResultsFound',
  computed: {
    ...mapGetters({ getUserValue: 'rxApproval/getSearchValue' }),
  },
};
