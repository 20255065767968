/* eslint-disable no-console */
import firestoreDB from './firestoreDB.service';

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

function searchResponse(rxItems, commit, type) {
  if (type === 'emptyBottle') {
    const rxItem = rxItems[0];
    if (rxItem) {
      rxItem.viaEmptyBottleButton = true;
      commit('rxApproval/setMobileRxEmptyBottleDetails', rxItem, { root: true });
    }
    commit('setPListedBottleStatus', !rxItems.length > 0);
  } else {
    const props = {};
    props.showApprovalDetails = false;
    props.showSearchIcon = false;
    props.noRecordsFound = rxItems.length === 0;
    props.showSearchResult = true;

    commit('rxApproval/setRxItemListMobile', rxItems, { root: true });
    commit('rxApproval/setRxItemMainProp', props, { root: true });
    commit(
      'loadingSearchIcon/loadingRequest',
      { status: false, loadingName: 'Loading search results' },
      { root: true },
    );
  }
}

function docWithApproval(formattedApproval, rxItems, originalStopId, commit, type) {
  let allRxItems = rxItems;
  allRxItems = allRxItems.map((rxItem) => {
    const rxItemObj = rxItem;
    const stopIds = Object.keys(rxItem.stop_approval).map(Number);
    const finalApproval = formattedApproval
      .find((apvl) => apvl.stop_id.some((stopId) => stopIds.includes(stopId)));
    const approvalObj = {
      approval_code: finalApproval.approval_code,
      approval_description: finalApproval.approval_description,
      manifest_id: finalApproval.manifest_id,
      stop_id: originalStopId,
      approval_id: finalApproval.approval_id,
      bill_units: finalApproval.bill_units,
    };
    delete rxItemObj.stop_approval;
    delete rxItemObj.stop_id;
    rxItemObj.stop_id = originalStopId;
    const finalRxItem = { ...rxItemObj, ...approvalObj };
    return finalRxItem;
  });
  searchResponse(allRxItems, commit, type);
}

async function allDocsWithApproval(allRxItems, stopId, commit, type = '') {
  const rxItems = allRxItems;
  let approvalIds = allRxItems.map((rxItem) => rxItem.stop_approval[stopId]);
  approvalIds = approvalIds.flat().map(Number);
  approvalIds = approvalIds.filter(onlyUnique);
  const allApprovals = firestoreDB.db
    .collection('approvals')
    .where('stop_id', 'array-contains', stopId);
  let formattedApproval = await allApprovals.get()
    .then((approval) => approval.docs.map((doc) => doc.data()));
  formattedApproval = formattedApproval
    .filter((approval) => approvalIds.includes(approval.approval_id));
  docWithApproval(formattedApproval, rxItems, stopId, commit, type);
}

async function parseResponse(ndc, itemName, stopId, commit) {
  Promise.all([ndc.get(), itemName.get()])
    .then(([ndcResp, itemNameResp]) => {
      const ndcArray = firestoreDB.queryToArray(ndcResp);
      const itemNameArray = firestoreDB.queryToArray(itemNameResp);
      return ndcArray.concat(itemNameArray);
    })
    .then((docs) => {
      if (docs.length > 0) {
        const allDocs = docs.filter(onlyUnique);
        allDocsWithApproval(allDocs, stopId, commit);
      } else {
        searchResponse([], commit);
      }
    });
}

function getRxItemList(commit, params) {
  const { stopId, search } = params;
  const searchKey = search.toLowerCase();
  const rxItems = firestoreDB.db
    .collection('rx_items')
    .where('stop_id', 'array-contains', stopId);

  const ndc = rxItems
    .where('ndc_code', '>=', searchKey)
    .where('ndc_code', '<=', `${searchKey}\uf8ff`);

  const itemName = rxItems
    .where('search_description', '>=', searchKey)
    .where('search_description', '<=', `${searchKey}\uf8ff`);

  parseResponse(ndc, itemName, stopId, commit);
}

async function getEmptyBottle(commit, stopId) {
  const emptyBottle = await firestoreDB.db.collection('rx_items')
    .where('stop_id', 'array-contains', stopId)
    .where('empty_bottle_flag', '==', true)
    .get();
  commit('setPListedBottleStatus', true);
  const emptyBottleValue = firestoreDB.queryToArray(emptyBottle);
  allDocsWithApproval(emptyBottleValue, stopId, commit, 'emptyBottle');
}

function getRxApproval(commit, id) {
  const stopId = parseInt(id, 10);
  getEmptyBottle(commit, stopId);
  firestoreDB.db.collection('rx_approvals')
    .where('stopId', '==', stopId)
    .onSnapshot((querySnapshot) => {
      const docs = [];
      querySnapshot.forEach((doc) => {
        docs.push({ ...doc.data(), doc_id: doc.id });
      });
      commit('setRxApprovalList', docs);
      if (docs.length > 0) {
        commit('updateNavListValue', {
          index: 2,
          field: 'edited',
          value: true,
        }, { root: true });
      } else {
        commit('updateNavListValue', {
          index: 2,
          field: 'edited',
          value: false,
        }, { root: true });
      }
    });
}

function calculateTotalWeight(finalApproval) {
  let totalWeight = finalApproval.rxItems.reduce((response, rxItem) => {
    const dupRxItem = rxItem;
    const contentWeight = dupRxItem.contentList.reduce((res, content) => {
      if (finalApproval.empty_bottle_flag) {
        const weight = parseFloat(content.bottleWeight) + content.residualWeight;
        return res + weight;
      }
      const calculate = parseFloat(content.weightPounds + (content.weightOunces / 16));
      return res + calculate;
    }, 0.0);
    return response + contentWeight;
  }, 0.0);
  totalWeight = Math.round((totalWeight + Number.EPSILON) * 100) / 100;
  return totalWeight;
}

function saveApprovalDetails(commit, params) {
  const { stopId, rxApproval } = params;
  rxApproval.totalWeight = calculateTotalWeight(rxApproval);
  const rxApprovalDB = firestoreDB.db.collection('rx_approvals');
  const updateValue = { ...rxApproval, synced: false };
  rxApprovalDB.doc(`${stopId}|${rxApproval.approvalId}`).set(updateValue);
}

function updateRxApproval(commit, params) {
  const { approval, change } = params;
  const docId = approval.doc_id;
  const rxApprovalDB = firestoreDB.db.collection('rx_approvals');
  const updateValue = { ...change, synced: false };
  rxApprovalDB.doc(`${docId}`).update(updateValue);
}

function deleteRxItemFromApproval(commit, params) {
  const { index, docId } = params;
  const rxApprovalDB = firestoreDB.db.collection('rx_approvals');
  rxApprovalDB.doc(`${docId}`).get().then((doc) => {
    if (doc.exists) {
      const rxApproval = doc.data();
      const { rxItems } = rxApproval;
      const filteredRxItems = rxItems.filter((rxItem, indx) => indx !== index);
      rxApproval.rxItems = filteredRxItems;
      if (filteredRxItems.length > 0) {
        const totalWeight = calculateTotalWeight(rxApproval);
        rxApprovalDB.doc(`${docId}`).update({
          rxItems: filteredRxItems,
          totalWeight,
          synced: false,
        }).catch((error) => {
          console.error('Error removing document: ', error);
        });
      } else {
        rxApprovalDB.doc(`${docId}`).delete().then(() => {
          commit('rxApproval/setSelectedViewApproval', {}, { root: true });
        }).catch((error) => {
          console.error('Error removing document: ', error);
        });
      }
    }
  }).catch((error) => {
    console.error('Error removing document: ', error);
  });
}

async function getApproval(approvalId) {
  const approval = await firestoreDB.db
    .collection('approvals')
    .where('approval_id', '==', parseInt(approvalId, 10)).get();
  return firestoreDB.queryToArray(approval)[0];
}

export default {
  getRxItemList,
  getRxApproval,
  saveApprovalDetails,
  updateRxApproval,
  deleteRxItemFromApproval,
  getApproval,
};
