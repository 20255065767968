//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'MobileRxApprovalDetail',
  computed: {
    ...mapGetters({ approval: 'rxApproval/getSelectedViewApproval' }),
  },
  data() {
    return {
      approvalObj: {},
      stopId: '',
      removeApprovalDialog: false,
      indexData: null,
      rxItemData: {},
    };
  },
  created() {
    this.stopId = this.$store.state.stopDetail.stopDetail.id;
    // this is needed because when i changed something on edit details page and clicked cancel
    // the original object values are getting updated.
    this.approvalObj = JSON.parse(JSON.stringify(this.approval));
  },
  methods: {
    calculateWeight() {
      let totalWeight = this.approvalObj.rxItems.reduce((response, rxItem) => {
        const dupRxItem = rxItem;
        const contentWeight = dupRxItem.contentList.reduce((res, content) => {
          if (this.approvalObj.empty_bottle_flag) {
            const weight = parseFloat(content.bottleWeight) + content.residualWeight;
            return res + weight;
          }
          const calculate = parseFloat(content.weightPounds + (content.weightOunces / 16));
          return res + calculate;
        }, 0.0);
        return response + contentWeight;
      }, 0.0);
      totalWeight = Math.round((totalWeight + Number.EPSILON) * 100) / 100;
      this.approvalObj.totalWeight = totalWeight;
    },
    editRxItem(index) {
      const rxItem = this.approvalObj.rxItems[index];
      const rxItemObj = {
        ...rxItem,
        edit: 1,
        stop_id: this.stopId,
        approval_id: this.approvalObj.approvalId,
        index,
        empty_bottle_flag: this.approvalObj.empty_bottle_flag,
      };
      this.$store.commit('rxApproval/setRxItemObject', rxItemObj);
      this.$store.commit('rxContent/setContentList', rxItem.contentList);
      this.$router.push({ path: 'add-rx_approval-card' });
    },
    deleteRxItem() {
      this.approvalObj.rxItems.splice(this.indexData, 1);
      this.calculateWeight();
      this.$store.dispatch('rxApproval/deleteRxItemFromApproval', {
        rxItem: this.rxItemData,
        index: this.indexData,
        docId: this.approvalObj.doc_id,
      });
      this.removeApprovalDialog = false;
      if (this.approvalObj.rxItems.length <= 0) {
        this.$store.commit('rxApproval/setRxItemMainPropDefault');
        this.$router.push({ name: 'Mobile Add RX Items' });
      }
    },
    openDialog(rxItem, index) {
      this.rxItemData = rxItem;
      this.indexData = index;
      this.removeApprovalDialog = true;
    },
    closeDialog() {
      this.removeApprovalDialog = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
