//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import VSelectArrow from '../../../helpers/utils/v-select-arrow';
import AddRxItemContentDetail from './addRxItemContentDetails.vue';

export default {
  name: 'AddRxItemDetail',
  components: {
    AddRxItemContentDetail,
  },
  computed: {
    ...mapGetters({
      rxItem: 'rxApproval/getRxItemObject',
      rxItemNotes: 'rxContent/getRxItemNotes',
    }),
    checkAnyEmptyFields() {
      const checkContentList = this.contentList.every((content) => {
        const {
          contentID, contentQuantity, weightPounds, weightOunces, containers,
          noOfEmptyBottles, bottleWeight,
        } = content;
        if (this.rxItem.empty_bottle_flag) {
          const emptyBottle = noOfEmptyBottles !== 0 && bottleWeight >= 0;
          if (this.isCustomerSpecificBillUnitAvailable) {
            return emptyBottle && this.checkBillUnitsAnswered;
          }
          return emptyBottle;
        }
        return contentID !== '' && contentQuantity !== 0 && (weightPounds !== 0 || weightOunces !== 0) && containers !== 0;
      });
      return checkContentList;
    },
    isCustomerSpecificBillUnitAvailable() {
      if (!this.contentList[0].billUnits) return false;
      return this.contentList[0].billUnits.some((bu) => bu.BillUnitCode !== 'LBS' && bu.BillingFlag === 'T');
    },
    checkBillUnitsAnswered() {
      return this.contentList.every((content) => content.billUnits.some((bu) => bu.BillUnitCode !== 'LBS' && bu.quantity > 0));
    },
  },
  data() {
    return {
      contentList: [],
      showNotesList: false,
      search: '',
      arrowChange: false,
      approvalList: {},
    };
  },
  extends: VSelectArrow,
  created() {
    this.contentList = this.$store.state.rxContent.contentList;
    if (!this.rxItem.edit) {
      const billUnit = this.rxItem.bill_units;
      if (billUnit) {
        this.$store.commit('rxContent/setBillUnitList', JSON.stringify(billUnit), {
          root: true,
        });
      }
    } else {
      const approvalId = this.rxItem.approval_id;
      this.$store.dispatch('rxContent/fetchApproval', approvalId);
    }
  },
  methods: {
    goToBack() {
      this.$router.go(-1);
    },
    buildApprovalList() {
      this.approvalList = {
        rxItem: this.rxItem,
        contentList: this.contentList,
      };
    },
    addToApprovalList() {
      this.approvalList = {};
      this.buildApprovalList();
      this.$store.dispatch('rxApprovalList/addToApprovalListMobile', this.approvalList);
      this.$store.commit('rxContent/resetContent');
      this.$store.commit('rxApproval/setRxItemListMobile', []);
      this.$store.commit('rxApproval/setRxItemMainPropDefault');
      this.$router.push({ name: 'Mobile Add RX Items' });
    },
    getPreviousMonthImageUrl() {
      let imageUrl = 'src/assets/images/plus-circle-icon.svg';
      if (!this.checkAnyEmptyFields) {
        imageUrl = 'src/assets/images/plus-circle-grey-icon.svg';
      }
      return imageUrl;
    },
    addPreviousMonth() {
      if (this.contentList.length < 12) {
        this.$store.dispatch('rxContent/addAnotherContentAction');
        if (this.rxItem.empty_bottle_flag) {
          this.$store.commit('rxContent/setContentValue', {
            index: this.contentList.length - 1,
            field: 'residualWeight',
            value: this.contentList[0].residualWeight,
          }, { root: true });
          this.$store.commit('rxContent/setContentValue', {
            index: this.contentList.length - 1,
            field: 'billUnits',
            value: this.$store.state.rxContent.billUnitList,
          }, { root: true });
        }
      }
    },
    viewNotesList() {
      this.showNotesList = !this.showNotesList;
    },
    closeSelectDropdown(refType) {
      const containerRef = this.$refs[refType];
      if (containerRef.isMenuActive) {
        this.search = false;
        containerRef.blur();
      } else {
        this.search = true;
        containerRef.isMenuActive = true;
        containerRef.focus();
      }
    },
    closeSelectDropdownBlur(refType) {
      this.search = false;
      const containerRef = this.$refs[refType];
      containerRef.isMenuActive = false;
      containerRef.blur();
    },
    keydownDropDown() {
      this.search = false;
    },
    checkArrow(refType) {
      const containerRef = this.$refs[refType];
      containerRef.isMenuActive = false;
      // v-select--is-menu-active
    },
  },
};
