<template>
  <div class="past-stop-content-container">
    <div class="past-stop-info-container">
      <div class="past-stop-content-info">
        <div class="title-text">
          Name
        </div>
        <div class="label-text">
          {{
            getLastStopData.name ? getLastStopData.name : '-'
          }}
        </div>
      </div>
      <div class="past-stop-content-info">
        <div class="title-text">
          Last stop completed
        </div>
        <div
          class="label-text"
          v-if="getLastStopData.actual_departure_time"
        >
          {{
            getLastStopData.actual_departure_time | dateFormat
          }}
        </div>
        <div
          class="label-text"
          v-else
        >
          -
        </div>
      </div>
      <div class="past-stop-content-info">
        <div class="title-text">
          Technician
        </div>
        <div class="label-text">
          {{
            getLastStopData.generator_sign_name ? getLastStopData.generator_sign_name : '-'
          }}
        </div>
      </div>
      <div class="d-flex approval-container">
        <div class="past-stop-content-info flex-1">
          <div class="title-text">
            Number of approvals
          </div>
          <div class="label-text">
            {{
              getLastStopData.number_of_approvals ? getLastStopData.number_of_approvals : '-'
            }}
          </div>
        </div>
        <div class="past-stop-content-info flex-1">
          <div class="title-text">
            Number of CCIDs
          </div>
          <div class="label-text">
            {{ getLastStopData.number_of_ccid ? getLastStopData.number_of_ccid : '-' }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex button-container"
      v-if="getLastStopData.last_stop_inventory.length > 0"
    >
      <v-btn
        class="btn inventory-btn"
        @click="openLastStopInventory"
      >
        Last stop inventory
      </v-btn>
      <!-- <v-btn class="btn note-btn">
        Last stop notes
      </v-btn> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PastStopContent',
  computed: {
    ...mapGetters(['getLastStopData', 'getStopDetail']),
  },
  data() {
    return {
      unsubscribe: () => {},
    };
  },
  methods: {
    openLastStopInventory() {
      if (this.$store.state.approvalList.approvalDetail.id && (this.$route.name === 'Add Approval' || this.$route.name === 'Consolidate')) {
        this.openModalAndSubscribe();
      } else {
        this.$emit('openInventoryList');
      }
    },
    openModalAndSubscribe() {
      const pageName = this.$route.name === 'Add Approval' ? 'approval' : 'consolidate';
      this.$store.commit('leaveApproval/setLeaveApprovalDialog', {
        isLeaveApprovalDialog: true,
        pageType: pageName,
        fromUrl: this.$route.path,
        redirectUrl: `/stops/detail/${this.getStopDetail.id}`,
        triggerDialog: '',
      });
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === 'leaveApproval/resetLeaveApprovalDialog') {
          this.unsubscribe();
          if (this.$route.name === 'stopDetailWorkArea') {
            this.$emit('openInventoryList');
          }
        }
      });
    },
  },
};
</script>
