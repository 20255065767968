<template>
  <div>
    <div class="mobile-help-sub-header-div">
      <img
        src="src/assets/images/left-chevron-white.svg"
        class="mobile-help-sub-header-div-img"
        @click="goToBack()"
      >
      <span class="mobile-help-sub-header-title">Republic Services Contacts</span>
    </div>
    <div class="contact-examples-main-div-mobile">
      <p class="republic-services-contacts-text-mobile">
        If you are unsuccessful with obtaining a response from the
        primary contact please reach out to your supervisor for immediate assistance.
      </p>
      <div class="regulator-div-mobile">
        <v-row no-gutters>
          <div
            class="regulator-div-1"
          >
            <img
              class="regulator-icon-mobile"
              src="src/assets/images/regulator-icon.svg"
              alt="image"
            >
            <span class="regulator-text-1-mobile">
              Regulatory incident reporting
            </span>
          </div>
          <div
            class="regulator-div-2"
          >
            <p class="regulator-text-2-mobile">
              Contact your supervisor if there is a regulatory incident. If your
              supervisor is unavailable, contact your EHS Representative.
            </p>
          </div>
        </v-row>
      </div>
      <v-row
        class="margin-top-15 mobile-contact-row"
        no-gutters
      >
        <v-col>
          <div>
            <p class="regulator-example-title-mobile">
              Issues related to your trips or stops
            </p>
            <p class="regulator-example-subtitle-mobile">
              Field Service Support
            </p>
            <p class="regulator-example-contacts-mobile">
              (866) 900-3762
            </p>
            <p class="regulator-example-title1-mobile">
              Examples
            </p>
            <ul class="regulator-example-list-mobile">
              <li>
                Unable to open a trip
              </li>
              <li>
                Trip issues
              </li>
              <li>
                Selected wrong stop on trip
              </li>
              <li>
                Missing stops on trip
              </li>
              <li>
                Missing approvals at a stop
              </li>
              <li>
                Missing NDC numbers
              </li>
              <li>
                Service concerns
              </li>
              <li>
                Retail SOP questions
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="margin-top-15 mobile-contact-row"
      >
        <v-col>
          <div>
            <p class="regulator-example-title-mobile">
              Hardware and/or software issues
            </p>
            <p class="regulator-example-subtitle-mobile">
              Contact I.T. Support
            </p>
            <p class="regulator-example-contacts-mobile">
              (734) 521-8057
            </p>
            <p class="regulator-example-title1-mobile">
              Examples
            </p>
            <ul class="regulator-example-list-mobile">
              <li>
                TruckSiS equipment issues
              </li>
              <li>
                Trouble printing
              </li>
              <li>
                Unable to sync
              </li>
              <li>
                Error messages
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="margin-top-15"
      >
        <v-col>
          <div>
            <p class="regulator-example-title-mobile">
              Contact your supervisor for:
            </p>
            <p class="regulator-example-title1-mobile">
              Examples
            </p>
            <ul class="regulator-example-list-mobile">
              <li>
                Request change in route order
              </li>
              <li>
                Credit card (fleet card)/lodging
              </li>
              <li>
                Missing supplies
              </li>
              <li>
                Field Service Support is not available
              </li>
            </ul>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactExamplesMobile',
  methods: {
    goToBack() {
      this.$router.go(-1);
    },
  },
};
</script>
