<template>
  <div>
    <v-overlay
      :value="showStorageMethodDialog"
      opacity="0.94"
      z-index="4"
    >
      <div class="d-flex justify-start align-start flex-column storage-method-container">
        <div class="header-dialog">
          <div><label>Select a storage method for the trip</label></div>
        </div>
        <div>
          <div
            class="description"
          >
            Does this trip use consolidated containers (CCIDs) or will the containers from
            the generators be stored in the truck?
            (This selection will apply to the entire trip).
            <img
              class="help-icon"
              src="src/assets/images/help_icon.svg"
              @click="showInfo(true)"
            >
            <div
              class="storage-info-container"
              v-if="showInfoBox"
            >
              <div class="header">
                Pickup storage
              </div>
              <div class="info-label">
                CCIDs are the most common type of storage.
                Transporting containers from the generator directly on the truck is a common
                practice for LTL stops and certain retailers (ex: Amazon).
              </div>
              <v-btn
                class="got-it-btn"
                @click="showInfo(false)"
              >
                Got it
              </v-btn>
            </div>
          </div>
        </div>
        <div class="width-pc-100 storage-selection">
          <div
            class="d-flex selection-group"
          >
            <div
              class="flex-w-1 selection-value"
              :class=" {'selected-value': selectedStorage === storageMethods.use_ccid}"
              @click="selectStorage(storageMethods.use_ccid)"
            >
              Use CCIDs
            </div>
            <div
              class="flex-w-1 selection-value"
              :class=" {'selected-value': selectedStorage === storageMethods.do_not_use_ccid}"
              @click="selectStorage(storageMethods.do_not_use_ccid)"
            >
              Do not use CCIDs
            </div>
          </div>
        </div>
        <div class="d-flex">
          <v-btn
            @click="back()"
            class="btn cancel-btn d-flex align-center justify-center cursor-pointer"
          >
            <img
              src="src/assets/images/left-chevron.svg"
              class="arrow-icon"
            >
            Back
          </v-btn>
          <div
            class="error-container"
            v-if="showError"
          >
            <i
              class="fa fa-exclamation error-exclamation-mark"
              aria-hidden="true"
            />
            <span class="error-message">
              Please select a storage method above
            </span>
          </div>
          <v-btn
            @click="continueStop()"
            class="btn continue-stop-btn d-flex align-center justify-center cursor-pointer"
          >
            Continue to stop
          </v-btn>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import tripStorage from '../../../constants/trip-storage';

export default {
  name: 'StorageMethodDialog',
  computed: {
    ...mapGetters(['getStopDetail']),
  },
  data() {
    return {
      storageMethods: tripStorage.storageMethods,
      selectedStorage: null,
      showInfoBox: false,
      showError: false,
    };
  },
  methods: {
    back() {
      this.$emit('cancelStorageDialog');
    },
    continueStop() {
      if (this.selectedStorage) {
        const params = {
          stopId: this.stopToActivate,
          storageMethod: this.selectedStorage,
        };
        this.$store.dispatch('firstStopArriveAction', params);
      } else {
        this.showError = true;
      }
    },
    selectStorage(value) {
      this.showError = false;
      this.selectedStorage = value;
    },
    showInfo(value) {
      this.showInfoBox = value;
    },
  },
  props: {
    showStorageMethodDialog: {
      type: Boolean,
      default: false,
    },
    stopToActivate: {
      type: Number,
      default: null,
    },
  },
  watch: {
    showStorageMethodDialog() {
      this.selectedStorage = null;
      this.showError = false;
      this.showInfoBox = false;
    },
  },
};
</script>
