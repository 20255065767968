//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ScanViewSDS',
  methods: {
    goToBackPage() {
      this.$emit('goBack');
    },
  },
  props: {
    sdsUrlLink: {
      type: String,
      default: '',
    },
  },
};
