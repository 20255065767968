//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex';
import stopDrawer from './sideMenu.vue';
import LogOutConfirmation from '../../helpers/component/logout-confirmation/logoutConfirmation.vue';
import RightDrawer from './rightDrawer.vue';
import alertsService from '../../service/alerts.service';
import barcodeScannerService from '../../service/barcodeScanner.service';
import LeaveApprovalDialog from '../../helpers/component/dialog/leaveApprovalDialog.vue';

const itemIdentificationStates = {
  identifyItems: 'Identify items',
  noActiveStop: 'No active stop',
  noInternetConnection: 'No signal',
  stopComplete: 'Stop completed. Scanning disabled.',
};

export default {
  name: 'LoggedInHeader',
  components: {
    LeaveApprovalDialog,
    stopDrawer,
    LogOutConfirmation,
    RightDrawer,
  },
  computed: {
    ...mapGetters({ getUser: 'authentication/getUser' }),
    ...mapGetters({ alertsCount: 'alerts/getAlertsCount' }),
    ...mapGetters({ voidedStops: 'getVoidedStopsInTrip' }),
    ...mapState({ stateDetail: 'authentication/loggedIn' }),
    ...mapGetters([
      'getShowTopNavBar',
      'getStopDetail',
      'getStopListsArray',
      'getHasNetworkConnection',
      'getActiveTripDetail',
      'getStopActiveStatus',
      'getTripDetail',
    ]),
    ...mapGetters({ tripSyncStatus: 'trip/getTripSyncStatus' }),
    alertExists() {
      return this.alertsCount > 0;
    },
    hasActiveTrip() {
      if (['Loggedin Home', 'Trip List'].includes(this.$route.name)) return false;
      if (this.$route.params.tripId) {
        this.checkSyncButton();
        return true;
      }
      return !!this.getActiveTripDetail.id;
    },
    syncBtnWarn() {
      return this.hasActiveTrip && !this.getHasNetworkConnection;
    },
    disableSyncIcon() {
      if (this.hasActiveTrip) {
        return !this.getHasNetworkConnection;
      }
      return !this.hasActiveTrip;
    },
  },
  data() {
    return {
      isOpenDialog: false,
      rightDrawerActive: false,
      isScanComponent: true,
      isButtonEnabled: true,
      itemIdentificationText: itemIdentificationStates.identifyItems,
      connectivityInterval: {},
      isAlertComponent: false,
      stopListActive: false,
      stopDetailActive: false,
      enableSyncButton: false,
      syncClicked: false,
      syncInterval: {},
      syncRequestCount: 0,
      syncRequestLimit: 10,
      isCompletedStop: false,
      tripSyncText: 'Sync Trip',
      getScanningEnabled: true,
    };
  },
  created() {
    this.getActiveTrip();
    this.changeActiveHeader();
    this.checkIfItemScanningEnabled();
    this.initiateCellularConnectionCheck();
  },
  mounted() {
    this.computeItemIdentificationState();
    this.updateAlertService();
  },
  methods: {
    logOut() {
      this.isOpenDialog = true;
    },
    cancelDialog(value) {
      this.isOpenDialog = value;
    },
    checkIfItemScanningEnabled() {
      this.$store.dispatch('masterScan/checkIfItemScanningEnabled');
    },
    goToStopLists() {
      const tripDetail = this.getActiveTripDetail;
      if (tripDetail && tripDetail.id) {
        this.$router.push({
          name: 'Stop List',
          params: { tripId: tripDetail.id.toString() },
        });
      }
    },
    computeItemIdentificationState() {
      if (this.getStopActiveStatus && this.getHasNetworkConnection) {
        if (this.getStopDetail.is_completed) {
          this.isButtonEnabled = false;
          this.itemIdentificationText = itemIdentificationStates.stopComplete;
        } else {
          this.isButtonEnabled = true;
          this.itemIdentificationText = itemIdentificationStates.identifyItems;
        }
      } else if (!this.getStopActiveStatus) {
        this.isButtonEnabled = false;
        this.itemIdentificationText = itemIdentificationStates.noActiveStop;
      } else {
        this.isButtonEnabled = false;
        this.itemIdentificationText = itemIdentificationStates.noInternetConnection;
      }
    },
    updateAlertService(stopIsActive) {
      if (stopIsActive && this.getHasNetworkConnection) {
        alertsService.startAlertsUpdate(this.getStopDetail.id);
      } else {
        alertsService.stopAlertsUpdate();
      }
    },
    checkSyncButton() {
      if (this.getHasNetworkConnection) {
        this.enableSyncButton = true;
      }
    },
    initiateCellularConnectionCheck() {
      this.$store.dispatch('setNetworkConnection');
      if (this.connectivityInterval) {
        clearInterval(this.connectivityInterval);
      }
      this.connectivityInterval = setInterval(() => {
        this.$store.dispatch('setNetworkConnection');
      }, 60000);
    },
    openScanComponent() {
      barcodeScannerService.startMonitoringScanGunInput();
      this.rightDrawerActive = true;
      this.isScanComponent = true;
      this.isAlertComponent = false;
    },
    closeRightDrawer() {
      this.rightDrawerActive = false;
      this.isScanComponent = false;
      this.isAlertComponent = false;
    },
    openAlertComponent() {
      this.rightDrawerActive = true;
      this.isAlertComponent = true;
      this.isScanComponent = false;
    },
    gotoActiveStop() {
      if (this.getStopActiveStatus) {
        this.$router.push({ path: `/stops/detail/${this.getStopDetail.id}` });
      }
    },
    changeActiveHeader() {
      this.stopListActive = false;
      this.stopDetailActive = false;
      if (this.$route.meta.stopList
        && this.getActiveTripDetail.id === parseInt(this.$route.params.tripId, 10)) {
        this.getActiveTrip();
        this.stopListActive = true;
      }
      if (this.$route.meta.pickupData) {
        this.stopListActive = true;
      } else if (this.$route.params.stopId) {
        this.stopDetailActive = true;
      }
    },
    getActiveTrip() {
      this.$store.dispatch('getActiveTripAction', this.$store.state.authentication.user.id);
    },
    syncTrip() {
      if (!this.enableSyncButton) {
        this.$store.dispatch('trip/syncTripFromUSEToast', 'error');
      } else if (!this.syncClicked) {
        let tripId = '';
        if (this.$route.params.tripId) {
          tripId = this.$route.params.tripId;
        } else {
          tripId = this.getActiveTripDetail.id;
        }
        this.$store.dispatch('trip/syncTripFromUSE', tripId);
        this.tripSyncText = 'Searching';
        this.syncClicked = true;
        this.syncRequestCount = 0;
        this.syncInterval = setInterval(() => {
          if (this.syncRequestCount === this.syncRequestLimit) {
            // this.syncCompleted('timeout');
            this.syncCompleted('success');
          } else {
            this.checkTripSyncStatus();
            this.syncRequestCount += 1;
          }
        }, 1000 * 5);
      }
    },
    checkTripSyncStatus() {
      this.$store.dispatch('trip/checkTripSyncedStatus');
    },
    syncCompleted(status = 'success') {
      if (status !== 'inProgress') {
        clearInterval(this.syncInterval);
        this.syncClicked = false;
        this.tripSyncText = 'Sync Trip';
      }
      this.$store.dispatch('trip/syncTripFromUSEToast', status);
    },
  },
  watch: {
    getStopActiveStatus() {
      this.computeItemIdentificationState(this.getStopActiveStatus);
      this.updateAlertService(this.getStopActiveStatus);
      if (this.getStopActiveStatus) {
        this.initiateCellularConnectionCheck();
      } else if (this.connectivityInterval) {
        clearInterval(this.conenctivityInterval);
      }
    },
    getHasNetworkConnection() {
      this.computeItemIdentificationState(this.getStopActiveStatus);
      this.updateAlertService(this.getStopActiveStatus);
    },
    $route() {
      this.changeActiveHeader();
    },
    getActiveTripDetail: {
      deep: true,
      handler(val) {
        if (val.id) {
          this.checkSyncButton();
        }
      },
    },
    tripSyncStatus(val) {
      switch (val) {
        case 'success':
          this.$toasted.clear();
          this.syncCompleted();
          break;
        case 'inProgress':
          this.tripSyncText = 'Syncing';
          if (this.syncRequestLimit >= 60) this.syncRequestLimit += 1;
          this.syncCompleted('inProgress');
          break;
        case 'failed':
          this.syncRequestCount = 60;
          this.syncRequestLimit = 60;
          break;
        default:
          break;
      }
    },
    getStopDetail() {
      this.computeItemIdentificationState(this.getStopActiveStatus);
      if (this.getStopDetail && this.getStopDetail.is_completed) {
        this.isCompletedStop = true;
      } else {
        this.isCompletedStop = false;
      }
    },
    voidedStops() {
      this.getStopActiveStatus = false;
      this.isButtonEnabled = false;
      this.itemIdentificationText = itemIdentificationStates.noActiveStop;
    },
  },
};
