//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'MobileActiveStopWarning',
  computed: {
    ...mapGetters({
      activeStopDetail: 'getMobileActiveStopDetails',
      user: 'authentication/getUser',
    }),
  },
  created() {
    this.$store.dispatch('fetchActiveStopDetail', String(this.user.active_stop_id));
  },
  methods: {
    gotIt() {
      this.$router.go(-1);
      this.$store.commit('setMobileActiveStopDetails', {});
    },
  },
};
