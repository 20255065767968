<template>
  <div>
    <div
      class="added-rx-page-div"
    >
      <div class="rx-drawer-approval-added-div">
        <p class="added-rx-title">
          Rx approvals added
        </p>
        <p class="add-rx-hint">
          Add each type of Rx item individually. The items will be grouped into the
          appropriate approval below. Once finished, add the container from generator
          information and consolidate.
        </p>
      </div>
      <div class="rx-item-added-slide">
        <transition name="slide">
          <div
            class="rx-item-alert-content"
            v-if="isNewRxItem"
          >
            <div class="rx-item-alert-container">
              <img
                src="src/assets/images/plus-circle-green-icon.svg"
                alt="rx-item-added"
              >
              <span>Rx item added</span>
            </div>
          </div>
        </transition>
      </div>
      <transition-group
        :name="transitionType"
        tag="div"
      >
        <div
          v-if="getRxApprovalList.length == 0 && rxApprovalAddedIds.length == 0"
          key="0"
        >
          <p class="added-rx-nil">
            No Rx approvals yet.
          </p>
        </div>
        <div
          class="rx-drawer-box"
          v-for="(list, index) in getRxApprovalList"
          :key="list.id"
        >
          <div
            class="rx-drawer-box-content"
          >
            <div
              class="rx-items-added-list-div margin-top-15"
              :class="{'card-active-trip': activeIndex === index }"
            >
              <div class="number-of-rx-items text-right">
                <span class="number-of-items"> Number of items: &nbsp;</span>
                <span class="color-blue-h1 font-weight-600 fs-20">
                  {{ list.stop_rx_items.length }}
                </span>
              </div>
              <div class="number-of-items-hr" />
              <v-row>
                <v-col
                  cols="12"
                  lg="7"
                >
                  <div class="d-flex flex-column">
                    <label class="add-rx-items-details-sub-title mb-1">
                      Approval
                    </label>
                    <label class="rx-items-added-list-approval">
                      {{ list.approval.description }}
                    </label>
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  lg="5"
                  class="text-right"
                >
                  <div class="d-flex flex-column">
                    <label class="add-rx-items-details-sub-title mb-1">
                      Total weight
                    </label>
                    <label class="rx-items-added-list-approval text-uppercase">
                      {{ list.total_weight }} {{ list.manifest_unit.unit }}
                    </label>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt--8px">
                <RxContainerSelect
                  :content-index="index"
                  :container-list="containerTypeList"
                  :container-id="getRxApprovalList[index].containerId"
                />
                <v-col
                  cols="12"
                  lg="5"
                  class="pb-0px"
                >
                  <label class="add-rx-items-details-sub-title mb-1 d-block">
                    Quantity
                  </label>
                  <v-row
                    no-gutters
                    class="height-48"
                  >
                    <v-col
                      cols="12"
                      lg="4"
                      class="rx-item-border-1px rx-items-controls-symbols"
                    >
                      <button
                        class="width-100p"
                        @click="decreaseRxContainerQuantity(index)"
                        :disabled="containerQuantityDisabled(list)"
                      >
                        <img
                          src="src/assets/images/minus-outline-icon.svg"
                          alt="minus"
                          class="rx-minus-icon"
                        >
                      </button>
                    </v-col>
                    <v-col
                      cols="12"
                      lg="4"
                      class="rx-item-border-1px rx-items-controls-values fs-24 padding-top-5"
                      @click="checkDialog(
                        'numeric','containerQuantity','Quantity',
                        index, getRxApprovalList[index].containerQuantity ?
                          getRxApprovalList[index].containerQuantity.toString(): '0')"
                    >
                      {{ getRxApprovalList[index].containerQuantity }}
                    </v-col>
                    <v-col
                      cols="12"
                      lg="4"
                      class="rx-item-border-1px rx-items-controls-symbols"
                    >
                      <button
                        class="width-100p padding-top-7"
                        @click="increaseRxContainerQuantity(index)"
                        :disabled="list.containerQuantity === 99"
                      >
                        <img
                          src="src/assets/images/plus-outline-icon.svg"
                          alt="plus"
                        >
                      </button>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="mtl-0p mt--8px">
                <button
                  class="rx-approvals-details-btn"
                  @click="redirectToCard(index)"
                >
                  Details
                </button>
                <button
                  @click="consolidate(list)"
                  :disabled="consolidateBtnDisabled(list) || disableAddToInventoryBtn(list.id)"
                  class="added-rx-items-consolidate-btn"
                >
                  {{ buttonLable(list.id) }}
                </button>
              </v-row>
            </div>
          </div>
        </div>
      </transition-group>
    </div>
    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="modelValue"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @enterClicked="enterClicked"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import KeyboardDialog from '../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import RxContainerSelect from '../../../helpers/component/rx-content/containerSelect.vue';

export default {
  name: 'AddedRxItems',
  components: {
    KeyboardDialog,
    RxContainerSelect,
  },
  computed: {
    ...mapGetters({
      activeIndex: 'rxApprovalList/getSelectedIndex',
      consolidateApproval: 'getConsolidateApproval',
      containerTypeList: 'container/getContainerTypeList',
      getRxApprovalList: 'rxApprovalList/getRxApprovalList',
      rxApprovalAddedStatus: 'rxApprovalList/getRxApprovalAddedStatus',
      useCCIDStorage: 'getUseCCIDStorage',
    }),
    getRxApprovalListLength() {
      return this.getRxApprovalList.length;
    },
  },
  data() {
    return {
      transitionType: '',
      approvalLength: 0,
      isNewRxItem: false,
      text: '',
      rxApprovedList: [],
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      mutationTypeList: [
        'rxApprovalList/setRxApprovalList',
        'rxApprovalList/increaseRxContainerQuantity',
        'rxApprovalList/decreaseRxContainerQuantity',
        'rxApprovalList/setRxContainerValue',
      ],
      rxApprovalAddedIds: [],
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      const findMutationType = this.mutationTypeList
        .find((mutationType) => mutationType === mutation.type);
      if (findMutationType === 'rxApprovalList/setRxApprovalList') {
        const approvalList = state.rxApprovalList.rxApprovalList;
        if (this.approvalLength !== 0) {
          if (this.approvalLength < approvalList.length) {
            this.transitionType = 'bounce';
          } else {
            this.transitionType = 'flip';
          }
        }
        this.approvalLength = approvalList.length;
      }
    });
    this.$store.commit('rxApprovalList/setRxApprovalAddedStatus', false);
    this.$store.dispatch('container/containerTypeListAction');
  },
  methods: {
    redirectToCard(index) {
      this.$store.commit('rxApproval/setRxEmptyBottle', false, { root: true });
      this.$store.commit('rxApproval/setSelectedEditApproval', {});
      this.$store.dispatch('rxApprovalList/setSelectedApprovalAction', index);
      this.$router.push('rx-approval-list');
    },
    increaseRxContainerQuantity(currentIndex) {
      this.$store.commit('rxApprovalList/increaseRxContainerQuantity', {
        index: currentIndex,
        field: 'containerQuantity',
      });
    },
    decreaseRxContainerQuantity(currentIndex) {
      this.$store.commit('rxApprovalList/decreaseRxContainerQuantity', {
        index: currentIndex,
        field: 'containerQuantity',
      });
    },
    checkDialog(KeyboardType, modelName, labelName, index, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.keypadIndex = index;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      const model = value.modelName;
      this.$store.commit('rxApprovalList/setRxContainerValue', {
        index: this.keypadIndex,
        field: model,
        value: +value.input,
      });
    },
    consolidate(list) {
      const params = {
        weight: list.total_weight,
        stop_id: +this.$route.params.stopId,
        approval_id: list.approval_id,
        waste_type: 'rx',
        containers: [],
        approval: list.approval,
      };
      const containerObj = {};
      if (!list.containerQuantity && !list.containerId && list.stop_approval
      && list.stop_approval.stop_containers.length > 0) {
        containerObj.id = list.stop_approval.stop_containers[0].id;
        containerObj.container_id = list.stop_approval.stop_containers[0].container_id;
        containerObj.quantity = list.stop_approval.stop_containers[0].quantity;
      } else {
        containerObj.id = '';
        if (list.stop_approval && list.stop_approval.stop_containers.length > 0) {
          containerObj.id = list.stop_approval.stop_containers[0].id;
        } else {
          containerObj.id = '';
        }
        containerObj.container_id = list.containerId;
        containerObj.quantity = list.containerQuantity;
      }
      params.containers.push(
        containerObj,
      );
      if (list.stop_approval) {
        params.stopApprovalId = list.stop_approval.id;
        this.$store.dispatch('rxApprovalList/rxReConsolidate', params);
      } else {
        params.approval_rx_item_id = list.id;
        this.$store.dispatch('rxApprovalList/rxConsolidate', params);
      }
      if (!this.useCCIDStorage) {
        this.addApprovalIdToList(list.id);
      }
    },
    getRxApprovalCode(approvalDetail) {
      return `${approvalDetail.tsdf}/${approvalDetail.approval.approval_code}`;
    },
    consolidateBtnDisabled(list) {
      if (!list.stop_approval || !list.stop_approval.stop_containers.length) {
        return !list.containerId || !list.containerQuantity;
      }
      return ((list.stop_approval && !list.stop_approval.stop_containers[0].container_id)
      || !list.containerQuantity);
    },
    containerQuantityDisabled(list) {
      return ((list.stop_approval && list.stop_approval.stop_containers.length
      && list.stop_approval.stop_containers[0].quantity === 0)
      || (list.containerQuantity === 0));
    },
    changeNewCard() {
      this.isNewRxItem = true;
      setTimeout(() => {
        this.isNewRxItem = false;
      }, 2000);
    },
    changeNewCardTransition() {
      const time = this.transitionType === 'bounce' ? 1300 : 800;
      setTimeout(() => {
        this.changeNewCard();
      }, time);
    },
    buttonLable(id) {
      if (this.useCCIDStorage) {
        return 'Consolidate';
      }
      if (this.rxApprovalAddedIds.includes(id)) {
        return 'Added to truck inventory';
      }
      return 'Add Rx to truck inventory';
    },
    addApprovalIdToList(id) {
      this.rxApprovalAddedIds.push(id);
      setTimeout(() => {
        this.rxApprovalAddedIds.shift();
      }, 500);
    },
    disableAddToInventoryBtn(id) {
      if (!this.useCCIDStorage && this.rxApprovalAddedIds.includes(id)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    consolidateApproval(newVal) {
      if (newVal && newVal.id) {
        this.$router.push({ name: 'RxConsolidate' });
      }
    },
    getRxApprovalListLength: {
      deep: true,
      handler(newVal) {
        if (newVal === 0) this.transitionType = 'bounce';
      },
    },
    rxApprovalAddedStatus(val) {
      if (val) {
        this.changeNewCardTransition();
        this.$store.commit('rxApprovalList/setRxApprovalAddedStatus', false);
        this.$store.dispatch('rxApprovalList/setSelectedApprovalAction', -1);
      }
    },
  },
};
</script>
