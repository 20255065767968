<template>
  <div class="container-header">
    <div class="navbar-header-logo">
      <img
        src="src/assets/images/logo-lockup.png"
        alt="logo"
      >
    </div>
    <div
      class="navbar-help-div navbar-close-help-div "
      @click="closeHelpPage"
      v-if="helpOpen"
    >
      <img
        alt="Close help"
        class="navbar-help-image"
        src="src/assets/images/close_help_icon.svg"
      >
      <p class="navbar-label">
        Close help
      </p>
    </div>
    <div
      class="navbar-help-div"
      @click="gotoHelpPage"
      v-if="!helpOpen"
    >
      <img
        alt="Help & Support"
        class="navbar-help-image"
        src="src/assets/images/help_icon.svg"
      >
      <p class="navbar-label">
        Help & Support
      </p>
    </div>
    <div
      class="navbar-power-div"
      @click="powerOffDialog()"
    >
      <img
        alt="power"
        class="navbar-power-image"
        src="src/assets/images/power-button-orange.svg"
      >
      <p class="shut-down-restart">
        Power off/restart
      </p>
    </div>
    <power-off-confirmation
      :open-dialog="isPowerDialog"
      @close-dialog="cancelPowerDialog"
    />
  </div>
</template>

<script>
import PowerOffConfirmation from '../../helpers/component/power-off-comfirmation/index.vue';

export default {
  name: 'UnAuthHeader',
  components: {
    PowerOffConfirmation,
  },
  data() {
    return {
      isPowerDialog: false,
      helpOpen: false,
    };
  },
  methods: {
    powerOffDialog() {
      this.isPowerDialog = true;
    },
    cancelPowerDialog(value) {
      this.isPowerDialog = value;
    },
    gotoHelpPage() {
      this.helpOpen = true;
      this.$router.push('/boot-help');
    },
    closeHelpPage() {
      this.helpOpen = false;
      this.$router.push('/login');
    },
  },
};
</script>
