<template>
  <div>
    <ready-to-scan-item
      v-if="currentScanState === scanStates.READY_TO_SCAN"
      @scanBarcode="scanBarcode"
    />
    <scan-success-item
      v-if="currentScanState === scanStates.MASTER_FOUND"
      :master="master"
      @backToScanPage="backToScanPage"
      @closeDrawer="closeRightDrawer"
    />
    <scan-item-not-found
      v-if="currentScanState === scanStates.MASTER_NOT_FOUND"
      @backToScanPage="backToScanPage"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScanSuccessItem from '../scan-success-item/scan-success-item.vue';
import ScanItemNotFound from '../scan-item-not-found/scan-item-not-found.vue';
import ReadyToScanItem from '../ready-to-scan-item/readyToScanItem.vue';
import validateBarcode from '../../utils/validate-barcode';
import barcodeScannerService from '../../../service/barcodeScanner.service';

const scanState = {
  READY_TO_SCAN: 'ready to scan',
  MASTER_FOUND: 'master found',
  MASTER_NOT_FOUND: 'master not found',
};

export default {
  name: 'ScanPage',
  components: {
    ScanSuccessItem,
    ReadyToScanItem,
    ScanItemNotFound,
  },
  computed: {
    ...mapGetters({
      master: 'masterScan/getMasterScanned',
      getStopDetail: 'getStopDetail',
      fullUpc: 'masterScan/getFullUpc',
    }),
  },
  data() {
    return {
      scanStates: scanState,
      currentScanState: scanState.READY_TO_SCAN,
    };
  },
  created() {
    this.getMasterChanged();
  },
  destroyed() {
    barcodeScannerService.stopMonitoringScanGunInput();
  },
  methods: {
    isValidMaster() {
      if (this.master.master_bin_rule && this.master.master_bin_rule.id) {
        this.currentScanState = scanState.MASTER_FOUND;
      } else {
        this.currentScanState = scanState.MASTER_NOT_FOUND;
      }
    },
    getMasterChanged() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'masterScan/setMasterScanned') {
          this.isValidMaster();
        }
      });
    },
    backToScanPage() {
      this.currentScanState = scanState.READY_TO_SCAN;
    },
    closeRightDrawer() {
      this.$emit('closeDrawer');
    },
    scanBarcode(value) {
      this.$store.dispatch('masterScan/resetUpc');
      if (value.input) {
        const barcode = value.input;
        if (validateBarcode(barcode)) {
          const params = {
            barcode,
            stopId: this.getStopDetail.id,
          };
          this.$store.dispatch('masterScan/getMasterFromBarcode', params);
        } else {
          const errorMessageData = {
            message: 'Invalid barcode',
            type: 'error',
            isCustom: false,
          };
          this.$store.dispatch('toastMessage/showMessage', errorMessageData);
        }
      }
    },
  },
  watch: {
    fullUpc(newVal) {
      if (newVal) {
        this.scanBarcode({ input: newVal });
      }
    },
  },
};
</script>
