//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import KeyboardDialog from '../virtual-keyboard/virtualKeyboardDialog.vue';

export default {
  name: 'ReadyToScanItem',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({
      getStopDetail: 'getStopDetail',
      fullUpc: 'masterScan/getFullUpc',
    }),
  },
  data() {
    return {
      numericDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      barcode: '',
      confirmButtonText: 'Identify item',
    };
  },
  methods: {
    checkDialog(KeyboardType, modelName, labelName) {
      this.numericDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
    },
    dialogInputChanges(changes) {
      this.numericDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.$emit('scanBarcode', value);
    },
  },
};
