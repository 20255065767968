//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ConsolidationCard from './consolidationCard.vue';
import clearAndSaveModalService from '../../utils/clearAndSaveModalService';

export default {
  name: 'ConsolidationBuilder',
  components: { ConsolidationCard },
  computed: {
    ...mapGetters({
      getConsolidateList: 'consolidate/getConsolidateList',
      isCreateCCID: 'consolidate/getIsCreateCCID',
      isSeletedCCID: 'consolidate/getIsSelectedCCID',
      addToTruckInventorySuccess: 'consolidate/getAddToTruckInventorySuccess',
      editApproval: 'getSelectedInventoryApproval',
      rxEditApproval: 'rxApproval/getRxEditApproval',
    }),
  },
  data() {
    return {
      isEligibleCCIDs: false,
      addToTruckInventoryBtnClicked: false,
    };
  },
  methods: {
    createNewCCID() {
      this.consolidateApproval.tripId = this.$store.state.stopDetail.stopDetail.trip_id;
      this.$store.dispatch('consolidate/createAndSaveNewCCID', this.consolidateApproval);
      this.$forceUpdate();
    },
    goToBackPage() {
      let approvalType;
      if (this.consolidateApproval.waste_type === 'retail') {
        approvalType = 'consolidate';
      } else {
        approvalType = 'rxConsolidate';
      }
      if (this.$store.state.approvalList.approvalStatusType) {
        this.$store.commit('consolidate/resetConsolidation');
        this.$store.commit('resetConsolidateApproval');
        this.$router.go(-1);
      } else {
        clearAndSaveModalService(this.$store, approvalType, this.routePath.from, this.routePath.to, 'goPreviousRoute');
      }
    },
    addToTruckInventory() {
      if (this.checkAnythingIsSelected()) {
        this.triggerErrorMessage();
      } else {
        const consolidationContainerIds = [];
        this.getConsolidateList.forEach((consolidateData) => {
          if (consolidateData.is_selected) {
            consolidationContainerIds.push(consolidateData.id);
          }
        });
        const stopApprovalId = this.$store.state.approvalList.consolidateApproval.id;
        this.$store.commit('resetConsolidateApproval');
        this.$store.commit('rxApproval/resetApprovalDetail');
        this.$store.commit('rxContent/resetContent');
        this.$store.dispatch('consolidate/addToTruckInventoryAction',
          {
            consolidationContainerIds,
            stopApprovalId,
          });
        this.$store.commit('resetApprovalDetail');
        this.$store.commit('container/resetContainerType');
        this.$store.commit('resetScaleWeight');
        this.$store.commit('resetScaleWeightUnit');
        this.$store.commit('setSelectedInventoryApproval', {});
      }
    },
    showEligibleCCIDs() {
      this.isEligibleCCIDs = !this.isEligibleCCIDs;
    },
    checkAnythingIsSelected() {
      let resp = false;
      const containers = this.getConsolidateList;
      resp = containers
        .every((ccid) => ccid.container_id && ccid.container_size_id);
      if (resp) {
        resp = this.getConsolidateList.some((ccid) => ccid.is_selected);
      }
      return !resp;
    },
    triggerErrorMessage() {
      this.addToTruckInventoryBtnClicked = true;
    },
    isCCIDAvailable() {
      return !this.getConsolidateList.some((ccid) => ccid.is_selected);
    },
  },
  props: {
    consolidateApproval: {
      type: Object,
      default: () => ({ }),
    },
    routePath: {
      type: Object,
      default: () => ({ }),
    },
  },
};
