//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TripCardComponent',
  data() {
    return {
      removeTripDialog: false,
      removeTripId: null,
      tripSyncInterval: {},
    };
  },
  methods: {
    removeTrip() {
      this.$store.dispatch('trip/hideTripAction', {
        tripId: this.tripDetail.id,
      });
    },
    viewTrip() {
      this.$store.dispatch('trip/updateStorageSetForTrip', this.tripDetail);
      this.$router.push(`stops/list/${this.tripDetail.id}`);
    },
    removeTripDailogOpen(id) {
      this.removeTripId = id;
      this.removeTripDialog = true;
    },
    closeDialog() {
      this.removeTripDialog = false;
    },
    removeTripConfirm() {
      const data = this.removeTripId;
      this.$store.dispatch('trip/removeTripAction', data);
    },
  },
  props: {
    tripDetail: {
      type: Object,
      default() {
        return {
          trip_name: '',
          use_trip_id: '',
          profit_center: '',
        };
      },
    },
    completedTrip: {
      type: Boolean,
      default: false,
    },
  },
};
