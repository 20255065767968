<template>
  <div>
    <v-overlay :value="dialog">
      <div class="p-listed-weight-warn-container">
        <div class="d-flex flex-column align-center justify-center p-listed-weight-warn-box">
          <div class="d-flex justify-center align-center fs-3em m28">
            <em class="fas fa-exclamation-triangle" />
          </div>
          <div class="p-listed-weight-warn-info">
            <label>Approvals with P-listed waste codes cannot exceed 2.2 lb per stop</label>
          </div>
          <div class="p-listed-weight-warn-info-child">
            <label>Please call the retail helpline to continue pickup at (866) 900-3762.</label>
          </div>
          <div class="p-listed-approval-box">
            <div class="p-listed-approval-contentainer">
              <div class="d-flex p-listed-approval-header">
                <div class="approval-description-header">
                  <p class="p-listed-approval-sub-title">
                    P-listed approvals on this stop
                  </p>
                </div>
                <div class="p-listed-weight-header d-flex align-center">
                  <p class="p-listed-approval-sub-title">
                    Weight
                  </p>
                </div>
              </div>
              <div
                class="d-flex p-listed-approval-details"
                v-for="(approval, index) in pListedApprovals.approvals"
                :key="index"
              >
                <div class="approval-description-header">
                  <p class="p-listed-approval-details-title">
                    {{ approval.approval_description }}
                  </p>
                </div>
                <div class="p-listed-weight-header d-flex align-center">
                  <p class="p-listed-approval-details-title">
                    {{ approval.weight }} lb
                  </p>
                </div>
              </div>
            </div>
            <div class="p-listed-shadow">
              <div class="d-flex p-listed-total-weight-div">
                <p class="p-listed-approval-details-title mr-20p">
                  Total weight:
                </p>
                <p class="p-listed-approval-details-title p-listed-total-weight">
                  {{ pListedApprovals.totalWeight }} lb
                </p>
              </div>
            </div>
          </div>
          <div class="btn-group-p-listed-weight-warn">
            <button
              @click="gotIt"
              class="btn-submit"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PListedWeightWarning',
  computed: {
    ...mapGetters({
      pListedApprovals: 'consolidate/getPListedApprovalList',
      stopPListedWeightError: 'consolidate/getStopPListedWeightError',
    }),
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    gotIt() {
      this.dialog = false;
      this.$store.commit('consolidate/setStopPListedWeightError', false);
      this.$emit('proceedToConsolidate', this.consolidateType);
    },
  },
  props: {
    consolidateType: {
      type: String,
      default: '',
    },
  },
  watch: {
    stopPListedWeightError: {
      deep: true,
      handler(val) {
        if (val) {
          const stopId = this.$store.getters.getStopDetail.id;
          this.$store.dispatch('consolidate/getPListedApprovalList', stopId);
        }
        this.dialog = val;
      },
    },
  },
};
</script>
