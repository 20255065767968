<template>
  <div class="stop-check-list-container">
    <div class="mx-4 stop-checklist-inner-container">
      <PageSequence
        v-for="(pageSequence, index) in pageSequenceList"
        :key="index"
        :sequence-detail="pageSequence"
        :first-index="index === 0"
      />
    </div>
    <v-divider />
    <div class="scroll-body position-relative">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import PageSequence from '../../../helpers/component/page-sequence/pageSequence.vue';

export default {
  name: 'StopCheckList',
  components: {
    PageSequence,
  },
  computed: {
    ...mapGetters({
      pageSequenceList: 'pageSequence/getPageSequenceList',
      nextPage: 'pageSequence/getNextPage',
      inventoryList: 'getStopInventoryLists',
    }),
  },
  created() {
    this.sequenceChanges();
    this.updateReprintStatus();
  },
  methods: {
    gotoPage() {
      if (this.nextPage) {
        const nextPath = this.nextPage.path.replace(
          'stopID',
          this.$route.params.stopId,
        );
        this.$router.push({ path: nextPath });
      }
    },
    goToSorting() {
      this.$router.push({ name: 'stopDetailWorkArea' });
    },
    sequenceChanges() {
      this.$store.commit('pageSequence/setPageSequenceValue', {
        currentPath: this.$route.path,
        stopId: this.$route.params.stopId,
      });
    },
    updateReprintStatus() {
      const stopDetail = this.$store.getters.getStopDetail;
      this.$store.dispatch(
        'stopDocuments/getDocumentReprintStatus',
        stopDetail.id,
      );
      this.$store.dispatch(
        'stopLabels/updateLabelReprintStatus',
        stopDetail.id,
      );
    },
  },
  watch: {
    $route() {
      this.sequenceChanges();
    },
    goToPrintLabel() {
      this.$router.push({ name: 'Print Label' });
    },
    inventoryList() {
      const stopDetail = this.$store.getters.getStopDetail;
      this.$store.dispatch(
        'stopDocuments/getDocumentReprintStatus',
        stopDetail.id,
      );
    },
  },
};
</script>
