//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MobileTripCard',
  methods: {
    gotoStopList() {
      this.$router.push(`/mobile/stop-list/${this.tripDetail.doc_id}/${this.tripDetail.trip_code}`);
    },
  },
  props: {
    tripDetail: {
      type: Object,
      default() {
        return {
          trip_name: '',
          use_trip_id: '',
        };
      },
    },
  },
};
