import { API_BASE_URL, SS_CLOUD_URL } from '../constants/index';
import fetchService from './fetch.service';

function connectivityCheck() {
  const requestOptions = {
    method: 'GET',
  };

  const url = `${API_BASE_URL}connectivity_check`;
  return fetchService.getFetchData(url, requestOptions);
}

function mobileConnectivityCheck() {
  const requestOptions = {
    method: 'GET',
  };

  return fetchService.getFetchData(`${SS_CLOUD_URL}/connection_check`, requestOptions);
}

const connectivityService = {
  connectivityCheck,
  mobileConnectivityCheck,
};
export default connectivityService;
