//
//
//
//
//
//
//
//
//
//
//
//
//

import UnAuthHeader from './header.vue';

export default {
  name: 'EmptyContainerLayout',
  components: {
    UnAuthHeader,
  },
};
