import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function getQuestionList(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stop_questions/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function updateQuestionnaire(params) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}stop_questions`;
  return fetchService.getFetchData(url, requestOptions);
}
const approvalService = {
  getQuestionList,
  updateQuestionnaire,
};
export default approvalService;
