<template>
  <div>
    <div class="mobile-help-sub-header-div">
      <img
        src="src/assets/images/left-chevron-white.svg"
        class="mobile-help-sub-header-div-img"
        @click="goToBack()"
      >
      <span class="mobile-help-sub-header-title">Documentaion and training</span>
    </div>
    <div>
      <iframe
        src="https://sis.help.smartersorting.com/hc/en-us/sections/360009362671-Help-and-training"
        title="Help and training"
        frameborder="0"
        width="100%"
        height="1370"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'HelpDocumentMobile',
  methods: {
    goToBack() {
      this.$router.go(-1);
    },
  },
};
</script>
