<template>
  <div>
    <v-row
      class="width-pc-100"
      no-gutters
    >
      <v-col class="col-md-3 col-xl-3 px-6 card-shadow stop-map-vh card-detail-bg col">
        <tripCode />
      </v-col>
      <v-col
        class="col-md-8 col-xl-9 top-144p stop-list-top"
        v-if="!showStopDetailClicked"
      >
        <div class="ml-3 active-trips">
          <div class="d-flex">
            <v-card
              class="pa-4 stop-category border1-r-4 my-trips-label"
              outlined
              tile
              :class="!showCompletedTrip ? 'scaleActive' : 'scaleInactive'"
              @click.prevent="stopsFilter(false)"
            >
              My trips
            </v-card>
            <v-card
              class="pa-4 stop-category border2-r-4 my-trips-label"
              outlined
              tile
              :class="!showCompletedTrip ? 'scaleInactive' : 'scaleActive'"
              @click.prevent="stopsFilter(true)"
            >
              Completed trips
            </v-card>
            <button
              class="stop-detail-btn view-all-stop-details-btn"
              @click.prevent="showStopDetail()"
              :disabled="!trips.length"
            >
              View all trip details
            </button>
          </div>
        </div>
        <div class="mb-2">
          <SearchLoader />
        </div>
        <tripCard
          v-for="(trip, index) in trips"
          :key="index"
          :trip-detail="trip"
          :completed-trip="showCompletedTrip"
        />
        <template v-if="showCompletedTrip === false && !trips.length">
          <h1 class="label ml-3 mt-2 no-active-trips">
            No assigned trips yet. Add a new trip to begin.
          </h1>
        </template>
        <template v-if="showCompletedTrip === true && !trips.length">
          <h1 class="label ml-3 mt-2 no-active-trips">
            No trips found
          </h1>
        </template>
      </v-col>
      <v-col
        v-else
        class="col-md-8 col-xl-9 stop-summary-section"
      >
        <trip-summary-component @closeStopDetail="closeStopDetail()" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import tripCard from '../../../helpers/component/trip-card/tripCard.vue';
import tripCode from '../add-code/index.vue';
import SearchLoader from '../../../helpers/component/search-loader/search-loader.vue';
import TripSummaryComponent from '../../../helpers/component/trip-summary/trip-summary.vue';

export default {
  name: 'TripListComponent',
  components: {
    tripCode, tripCard, SearchLoader, TripSummaryComponent,
  },
  computed: {
    trips: {
      get() {
        return this.$store.state.trip.trips;
      },
      set(value) {
        return value;
      },
    },
  },
  data() {
    return {
      showStopDetailClicked: false,
      showCompletedTrip: false,
      timer: 5000,
    };
  },
  created() {
    this.$store.dispatch('trip/tripListAction');
    this.$store.dispatch('getActiveTripAction', this.$store.state.authentication.user.id);
    this.checkTripSyncStatus();
  },
  destroyed() {
    clearInterval(this.tripSyncInterval);
  },
  methods: {
    showStopDetail() {
      this.showStopDetailClicked = true;
      const payload = {
        preview: true,
      };
      this.$store.dispatch('trip/printTripsDetail', payload);
    },
    closeStopDetail() {
      this.showStopDetailClicked = false;
    },
    stopsFilter(type) {
      this.$store.dispatch('trip/emptyTripList');
      this.showCompletedTrip = type;
      if (this.showCompletedTrip === true) {
        this.$store.dispatch('trip/completedTripListAction');
      } else {
        this.$store.dispatch('trip/tripListAction');
        this.$store.dispatch('getActiveTripAction', this.$store.state.authentication.user.id);
      }
    },
    getCurrentUserTripsStatus() {
      this.$store.dispatch('trip/getUserTripsStatus', this.$store.state.authentication.user.id).then((resp) => {
        this.updateSyncTimer(resp);
        this.trips = this.trips.map((trip) => {
          const tripObj = trip;
          tripObj.stops_synced = resp.find((curr) => curr.id === tripObj.id).stops_synced;
          return tripObj;
        });
      });
    },
    updateSyncTimer(syncTripList) {
      const tripStatus = syncTripList;
      const status = tripStatus.some((trip) => !trip.stops_synced);
      let changeTimer = false;
      if (status && this.timer !== 1000) {
        changeTimer = true;
        this.timer = 1000;
      } else if (!status && this.timer !== 5000) {
        changeTimer = true;
        this.timer = 5000;
      }
      if (changeTimer) {
        this.getCurrentUserTripsStatus();
        clearInterval(this.tripSyncInterval);
        this.checkTripSyncStatus();
      }
    },
    checkTripSyncStatus() {
      this.tripSyncInterval = setInterval(() => {
        this.getCurrentUserTripsStatus();
      }, this.timer);
    },
  },
};
</script>
