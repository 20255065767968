//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  required, email,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'LoginMobileComponent',
  computed: {
    ...mapGetters({ emailExist: 'authentication/getEmailExist', mobileLoginStatus: 'authentication/getMobileLoginStatus' }),
  },
  data() {
    return {
      form: {
        email: '',
      },
      submitted: false,
      enterPin: false,
      pin1: '',
      pin2: '',
      pin3: '',
      pin4: '',
      emailExistStatus: true,
      loginStatusMobile: Boolean,
    };
  },
  beforeMount() {
    if (localStorage.getItem('mobileLoginEmail')) {
      this.enterPin = true;
      this.form.email = localStorage.getItem('mobileLoginEmail');
    }
  },
  created() {
    if (this.$route.query.redirect) {
      const currentEmail = localStorage.getItem('mobileLoginEmail');
      this.form.email = currentEmail;
      this.enterPin = true;
    }
  },
  methods: {
    submit() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$store.dispatch('authentication/checkEmailAction', {
        email: this.form.email,
      });
    },
    login() {
      this.$store.dispatch('authentication/loginAction', {
        email: this.form.email,
        password: this.pin1 + this.pin2 + this.pin3 + this.pin4,
      });
    },
    focusToPin2() {
      this.$refs.pin2.focus();
    },
    focusToPin3() {
      this.$refs.pin3.focus();
    },
    focusToPin4() {
      this.$refs.pin4.focus();
    },
    focusToEnd() {
      this.$refs.pin4.blur();
    },
    clearPin1() {
      this.pin1 = '';
    },
    clearPin2() {
      this.pin2 = '';
    },
    clearPin3() {
      this.pin3 = '';
    },
    clearPin4() {
      this.pin4 = '';
    },
    goBack() {
      this.enterPin = false;
    },
  },
  validations: {
    form: {
      email: {
        required, email,
      },
    },
  },
  watch: {
    emailExist(newVal) {
      this.emailExistStatus = newVal;
      this.enterPin = newVal;
    },
    mobileLoginStatus(newVal) {
      this.loginStatusMobile = newVal;
      if (newVal === false) {
        this.pin1 = ''; this.pin2 = ''; this.pin3 = ''; this.pin4 = '';
      }
      if (newVal === true) {
        localStorage.setItem('mobileLoginEmail', this.form.email);
        this.$router.push({ name: 'Mobile Trip List' });
      }
    },
  },
};
