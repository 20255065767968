import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function getContainerTypeList(approvalID) {
  const requestOptions = {
    method: 'GET',
  };

  const url = `${API_BASE_URL}approvals/${approvalID}/containers`;
  return fetchService.getFetchData(url, requestOptions);
}

const containerService = {
  getContainerTypeList,
};
export default containerService;
