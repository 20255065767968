<template>
  <div>
    <div class="mobile-rx-approval-item-details-div">
      <p class="mobile-rx-approval-item-details-sub-title">
        Description
      </p>
      <p class="mobile-rx-approval-item-details-sub-details">
        {{ rxItem.rx_item_description }}
      </p>

      <p class="mobile-rx-approval-item-details-sub-title">
        Notes
      </p>
      <div class="mobile-rx-item-notes-dropdown">
        <vCombobox
          ref="notes"
          class="input-type-select dropdown-color-grey"
          v-model="rxItem.note"
          :items="rxItemNotes"
          item-text="note"
          outlined
          :append-icon="arrowChange ? 'fa-angle-up' : 'fa-angle-down'"
          :menu-props="{
            ...(!search && {value: false}),
            contentClass: 'mobile-combo-box',
            closeOnClick: true,
            openOnClick: false,
            offsetY: true }"
          background-color="white"
          placeholder="Select..."
          :no-filter="true"
          @click="checkArrow('notes')"
          @keydown.native="keydownDropDown"
          @blur="closeSelectDropdownBlur('notes')"
          @click:append="closeSelectDropdown('notes')"
        />
      </div>
      <addRxItemContentDetail
        v-for="(content, index) in contentList"
        :key="index"
        :content-detail="content"
        :content-index="index"
        :first-index="index === 0"
        :last-index="index === contentList.length - 1"
        :empty-bottle="rxItem.empty_bottle_flag"
        :bill-units-available="isCustomerSpecificBillUnitAvailable"
      />
      <div
        class="mb16"
      >
        <button
          :disabled="!checkAnyEmptyFields"
          class="mobile-rx-items-add-previous-month-btn w100-imp"
          @click="addPreviousMonth()"
        >
          <img
            :src="getPreviousMonthImageUrl()"
            alt="add-month"
          >
          <span class="margin-left-15px"> Add previous month </span>
        </button>
      </div>
      <div class="mobile-group-confirm-btn">
        <button
          class="mobile-rx-approval-item-details-back-btn"
          @click="goToBack"
          v-if="rxItem.edit && rxItem.edit === 1"
        >
          Cancel
        </button>
        <button
          class="mobile-rx-approval-item-details-back-btn"
          @click="goToBack"
          v-else
        >
          <em class="fas fa-chevron-left" /> &nbsp;
          Back
        </button>
        <button
          class="mobile-rx-add-to-approval-btn"
          @click="addToApprovalList"
          :disabled="!checkAnyEmptyFields"
        >
          {{ rxItem.edit && rxItem.edit === 1 ? 'Save Changes' : 'Add to approval list' }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import VSelectArrow from '../../../helpers/utils/v-select-arrow';
import AddRxItemContentDetail from './addRxItemContentDetails.vue';

export default {
  name: 'AddRxItemDetail',
  components: {
    AddRxItemContentDetail,
  },
  computed: {
    ...mapGetters({
      rxItem: 'rxApproval/getRxItemObject',
      rxItemNotes: 'rxContent/getRxItemNotes',
    }),
    checkAnyEmptyFields() {
      const checkContentList = this.contentList.every((content) => {
        const {
          contentID, contentQuantity, weightPounds, weightOunces, containers,
          noOfEmptyBottles, bottleWeight,
        } = content;
        if (this.rxItem.empty_bottle_flag) {
          const emptyBottle = noOfEmptyBottles !== 0 && bottleWeight >= 0;
          if (this.isCustomerSpecificBillUnitAvailable) {
            return emptyBottle && this.checkBillUnitsAnswered;
          }
          return emptyBottle;
        }
        return contentID !== '' && contentQuantity !== 0 && (weightPounds !== 0 || weightOunces !== 0) && containers !== 0;
      });
      return checkContentList;
    },
    isCustomerSpecificBillUnitAvailable() {
      if (!this.contentList[0].billUnits) return false;
      return this.contentList[0].billUnits.some((bu) => bu.BillUnitCode !== 'LBS' && bu.BillingFlag === 'T');
    },
    checkBillUnitsAnswered() {
      return this.contentList.every((content) => content.billUnits.some((bu) => bu.BillUnitCode !== 'LBS' && bu.quantity > 0));
    },
  },
  data() {
    return {
      contentList: [],
      showNotesList: false,
      search: '',
      arrowChange: false,
      approvalList: {},
    };
  },
  extends: VSelectArrow,
  created() {
    this.contentList = this.$store.state.rxContent.contentList;
    if (!this.rxItem.edit) {
      const billUnit = this.rxItem.bill_units;
      if (billUnit) {
        this.$store.commit('rxContent/setBillUnitList', JSON.stringify(billUnit), {
          root: true,
        });
      }
    } else {
      const approvalId = this.rxItem.approval_id;
      this.$store.dispatch('rxContent/fetchApproval', approvalId);
    }
  },
  methods: {
    goToBack() {
      this.$router.go(-1);
    },
    buildApprovalList() {
      this.approvalList = {
        rxItem: this.rxItem,
        contentList: this.contentList,
      };
    },
    addToApprovalList() {
      this.approvalList = {};
      this.buildApprovalList();
      this.$store.dispatch('rxApprovalList/addToApprovalListMobile', this.approvalList);
      this.$store.commit('rxContent/resetContent');
      this.$store.commit('rxApproval/setRxItemListMobile', []);
      this.$store.commit('rxApproval/setRxItemMainPropDefault');
      this.$router.push({ name: 'Mobile Add RX Items' });
    },
    getPreviousMonthImageUrl() {
      let imageUrl = 'src/assets/images/plus-circle-icon.svg';
      if (!this.checkAnyEmptyFields) {
        imageUrl = 'src/assets/images/plus-circle-grey-icon.svg';
      }
      return imageUrl;
    },
    addPreviousMonth() {
      if (this.contentList.length < 12) {
        this.$store.dispatch('rxContent/addAnotherContentAction');
        if (this.rxItem.empty_bottle_flag) {
          this.$store.commit('rxContent/setContentValue', {
            index: this.contentList.length - 1,
            field: 'residualWeight',
            value: this.contentList[0].residualWeight,
          }, { root: true });
          this.$store.commit('rxContent/setContentValue', {
            index: this.contentList.length - 1,
            field: 'billUnits',
            value: this.$store.state.rxContent.billUnitList,
          }, { root: true });
        }
      }
    },
    viewNotesList() {
      this.showNotesList = !this.showNotesList;
    },
    closeSelectDropdown(refType) {
      const containerRef = this.$refs[refType];
      if (containerRef.isMenuActive) {
        this.search = false;
        containerRef.blur();
      } else {
        this.search = true;
        containerRef.isMenuActive = true;
        containerRef.focus();
      }
    },
    closeSelectDropdownBlur(refType) {
      this.search = false;
      const containerRef = this.$refs[refType];
      containerRef.isMenuActive = false;
      containerRef.blur();
    },
    keydownDropDown() {
      this.search = false;
    },
    checkArrow(refType) {
      const containerRef = this.$refs[refType];
      containerRef.isMenuActive = false;
      // v-select--is-menu-active
    },
  },
};
</script>
