//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import connectedStopConstant from '../../../constants/connected-stop';

export default {
  name: 'StopInformationHeader',
  computed: {
    ...mapGetters({ stopDetailChange: 'getStopDetail' }),
  },
  data() {
    return {
      stopDetail: {},
    };
  },
  created() {
    this.$store.dispatch('getStopDetailAction', {
      stopID: +this.$route.params.stopId,
      type: '',
    });
  },
  methods: {
    getClass(stopType) {
      let className = 'dea-active';
      if (stopType.toLowerCase() === 'st') {
        className = 'st-active';
      }
      if (stopType.toLowerCase() === 'fs') {
        className = 'fs-active';
      }
      if (stopType.toLowerCase() === 'rx') {
        className = 'rx-active';
      }
      if (stopType.toLowerCase() === 'dc') {
        className = 'dc-active';
      }
      if (stopType.toLowerCase() === 'cf') {
        className = 'cf-active';
      }
      return className;
    },
    checkConnectedStopStatus() {
      if (this.stopDetailChange.connected_status) {
        if (this.stopDetailChange.connected_status === connectedStopConstant.status.NOT_CONNECTED) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  watch: {
    stopDetailChange(newVal) {
      this.stopDetail = newVal;
    },
  },
};
