// The following line loads the standalone build of Vue instead of the runtime-only build,
// so you don't have to do: import Vue from 'vue/dist/vue'
// This is done with the browser options. For the config, see package.json
import Vue from 'vue';
import Vuelidate from 'vuelidate';
import Vuetify from 'vuetify';
import Toasted from 'vue-toasted';
import IdleVue from 'idle-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import scaleSocketService from './service/scale-socket.service';
import 'simple-keyboard/build/css/index.css';
import './helpers/pipes/date-format';
import './helpers/pipes/phone-number-format';
import './helpers/pipes/eligible-ccid';
import './helpers/pipes/weight-unit';
import './helpers/pipes/rx-container-quantity';
import './helpers/pipes/readable-attachment-type';
import './helpers/directives/unselect';
import camSocketService from './service/cam-socket.service';

Vue.use(scaleSocketService.scaleSocketConnection, {
  store,
  url: scaleSocketService.getUrl(),
});
Vue.use(camSocketService.camSocketConnection, {
  store,
  url: camSocketService.getUrl(),
});
Vue.use(Toasted);
Vue.use(Vuetify);
Vue.use(Vuelidate);
// options to the toast
const options = {
  type: 'success',
  position: 'top-right',
  theme: '',
  duration: 5000,
  className: '',
  containerClass: '',
  action: {},
  icon: {},
  iconPack: 'custom-class',
};

// register the toast with the custom message
Vue.toasted.register(
  'sis_toast_message',
  (payload) => {
    if (payload.duration) {
      options.duration = payload.duration;
    }
    if (payload.type) {
      options.type = payload.type;
    }
    if (payload.position) {
      options.position = payload.position;
    }
    if (payload.theme) {
      options.theme = payload.theme;
    }
    if (payload.customToast && payload.flagType === 0) {
      options.className = 'custom-toast';
      options.containerClass = 'custom-toast-container';
      options.duration = 3000;
      options.action = {
        icon: 'custom-toast-action-icon',
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      };
      options.icon = 'custom-toast-icon';
    } else if (payload.customToast && payload.flagType === 1) {
      options.className = 'custom-toast-with-flag';
      options.containerClass = 'custom-toast-container-with-flag';
      options.duration = 3000;
      options.action = {
        icon: 'custom-toast-action-icon-with-flag',
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      };
      options.icon = 'custom-toast-icon-with-flag';
    } else if (payload.customToast && payload.flagType === 2) {
      options.className = 'custom-toast-with-flag-error';
      options.containerClass = 'custom-toast-container-with-flag';
      options.duration = 3000;
      options.action = {
        icon: 'custom-toast-action-icon-with-flag',
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      };
      options.icon = 'custom-toast-icon-with-flag-error';
    } else if (payload.customToast && payload.flagType === 3) {
      options.className = 'custom-toast-for-app-update';
      options.containerClass = 'custom-toast-container-with-flag';
      options.duration = null;
      options.action = {
        icon: 'custom-toast-action-icon-with-flag',
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
          store.dispatch('setUpdateReadyToastDisplayed', false);
        },
      };
      options.icon = 'custom-toast-for-app-update-icon';
    } else if (payload.customToast && [4, 5].includes(payload.flagType)) {
      options.className = 'custom-toast';
      options.containerClass = 'custom-toast-container-inprogress';
      if (payload.flagType === 4) {
        options.duration = 1000000;
      } else {
        options.duration = 5000;
      }
      options.action = {
        icon: 'custom-toast-action-icon',
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
        },
      };
      options.icon = 'custom-toast-icon';
    } else {
      options.className = '';
      options.containerClass = '';
      options.action = '';
      options.icon = '';
      options.duration = 5000;
    }

    return `${payload.message}`;
  },
  options,
);

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1000 * 60 * 30,
  startAtIdle: false,
});

Vue.config.devtools = false;

/* eslint-disable no-new */
new Vue({
  components: { App },
  el: '#app',
  render: (h) => h(App),
  router,
  store,
  vuetify: new Vuetify(),
});
