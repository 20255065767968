<template>
  <div class="width-100p stop-document-component">
    <div
      v-if="!printButtonClicked"
      class="d-flex flex-column ptb-30plr-40"
    >
      <div class="d-flex justify-space-between width-100p print-doc-header">
        <div class="print-label">
          <label>Print stop documents</label>
        </div>
        <button
          class="print-button"
          :class="{ disabled: !printIsValid }"
          @click="printSelectedDocuments"
        >
          <img src="src/assets/images/printer-white.svg">
          <div class="print-button-label">
            {{ printButtonLabel }}
          </div>
        </button>
      </div>
      <div
        class="reprint-alert-box"
        v-if="showReprintAlert"
      >
        <img
          class="alert-image"
          src="src/assets/images/alert.svg"
        >
        <div class="reprint-alert-info">
          You've added additional approvals after printing a manifest. Look for the alert symbol/s
          below and reprint the specified documents. You may also need to reprint
          your labels if they reference the previous manifest.
        </div>
      </div>
      <div class="d-flex justify-end button-group">
        <button
          class="outline-btn"
          @click="selectAllDocuments"
        >
          Select all
        </button>
        <button
          class="outline-btn"
          @click="deselectAllDocuments"
        >
          Clear all
        </button>
      </div>
      <document-type-table
        v-if="hazManifestsTableData.length > 0"
        :table-rows="hazManifestsTableData"
        :table-headers="hazManifestsTableHeaders"
        table-title="Haz manifest first page(s)"
      />
      <document-type-table
        v-if="nonHazManifestsTableData.length > 0"
        :table-rows="nonHazManifestsTableData"
        :table-headers="nonHazManifestsTableHeaders"
        table-title="Non-haz manifest first page(s)"
      />
      <document-type-table
        v-if="manifestsContinuationTableData.length > 0"
        :table-rows="manifestsContinuationTableData"
        :table-headers="manifestsContinuationTableHeaders"
        table-title="Manifests continuation page(s)"
      />
      <document-type-table
        v-if="additionalDocumentsTableData.length > 0"
        :table-rows="additionalDocumentsTableData"
        :table-headers="additionalDocumentsTableHeaders"
        table-title="Additional documents"
      />
    </div>
    <div
      v-else
      class="d-flex flex-column ptb-30plr-40"
    >
      <div class="d-flex width-100p print-doc-header">
        <div class="print-label">
          <label>{{ printingDocumentsHeader }}</label>
        </div>
      </div>

      <div
        class="d-flex flex-column document-table"
        :class="{'flex-column-reverse': !isFirstPage }"
      >
        <document-type-table
          :class="{'print-document-table': isFirstPage }"
          :table-rows="printableDocuments"
          :table-headers="additionalDocumentsTableHeaders"
          :printing-doc-list-table="printableDocumentListTable"
          :table-title="printTableHeading"
        />
        <div
          class="print-instruction-field"
          :class="{'instruction-below-table': isFirstPage }"
        >
          <print-instruction
            v-if="!printingComplete || !isFirstPage"
            :print-description="printInstructionDescription"
            :printing-status-complete="printingComplete"
            :multiple-documents="multipleDocumentsToPrint"
            :next-document-to-print="nextDocumentToPrint"
            @printDocuments="printDocuments"
            @printNextDoc="printNextDocumentSet"
            @reset="resetPrintingDocument"
            :has-printed-once="hasPrintedOnce"
          />
          <div
            v-else
            class="enter-manifest-card"
          >
            <enter-manifest-number
              @printNextDoc="printNextDocumentSet"
              :is-manifest-for-haz-item="isManifestForHazItem"
              @reprintDocument="reprintDocument"
              :next-document-to-print="nextDocumentToPrint"
              @reset="resetPrintingDocument"
              :printable-documents="printableDocuments"
            />
          </div>
        </div>
      </div>
    </div>
    <error-modal
      v-if="shouldShowErrorModal"
      :dialog-data="modalData"
      :dialog-property="dialogProperty"
      :button-data="modalButtons"
      @cancel="closeErrorModal"
      @confirm="printAnyway"
    />
    <error-modal
      v-if="shouldShowReprintErrorModal"
      :dialog-data="reprintErrorModalData"
      :dialog-property="dialogProperty"
      :button-data="reprintErrorModalButtons"
      @confirm="closeReprintErrorModal"
      @cancel="closeReprintErrorModal"
    />
    <right-drawer
      :right-drawer="openPDFDrawer"
      :scan-component="false"
      :view-pdf="true"
      :pdf-url="sdsUrl"
      @closePDFView="removeSDSUrl"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import documentTypeTable from '../../../helpers/component/document-type-table/documentTypeTable.vue';
import printInstruction from '../../../helpers/component/print-instruction/printInstruction.vue';
import enterManifestNumber from '../../../helpers/component/enter-manifest-number/enterManifestNumber.vue';
import errorModal from '../../../helpers/component/confirmation-dialog/confirmation.vue';
import printBackendService from '../../../service/print.backend.service';
import documentConstants from '../../../constants/document';
import rightDrawer from '../../../layout/logged-in-header/rightDrawer.vue';

const printInstructionsDescriptions = {
  HAZ_MANIFEST_PRINT_INSTRUCTION:
    'Please load manifest template into the printer...',
  NON_HAZ_MANIFEST_PRINT_INSTRUCTION:
    'Please load manifest template into the printer...',
  CONTINUATION_MANIFEST_PRINT_INSTRUCTION:
    'Please load manifest continuation paper into the printer...',
  ADDITIONAL_DOCUMENT_PRINT_INSTRUCTION:
    'Please load blank paper into the printer...',
};

const printingDocumentsTypeState = {
  HAZ_MANIFEST: 'HAZ_MANIFEST',
  NON_HAZ_MANIFEST: 'NON_HAZ_MANIFEST',
  MANIFEST_CONTINUATION: 'MANIFEST_CONTINUATION',
  ADDITIONAL_DOCUMENTS: 'ADDITIONAL_DOCUMENTS',
};

const printingDocumentsTypeLabel = {
  HAZ_MANIFEST_PRINT_LABEL: 'Haz manifest printing',
  NON_HAZ_MANIFEST_PRINT_LABEL: 'Non-Haz manifest printing',
  MANIFEST_CONTINUATION_PRINT_LABEL: 'Manifest continuation pages',
  ADDITIONAL_DOCUMENTS_PRINT_LABEL: 'Print additional documents',
};

const documentTypes = documentConstants.types;

export default {
  name: 'StopDocument',
  components: {
    documentTypeTable,
    printInstruction,
    enterManifestNumber,
    errorModal,
    rightDrawer,
  },
  computed: {
    ...mapGetters({
      hazManifestsTableData: 'stopDocuments/getHazManifestsTableData',
      hazManifestsTableHeaders: 'stopDocuments/getHazManifestsTableHeaders',
      nonHazManifestsTableData: 'stopDocuments/getNonHazManifestsTableData',
      additionalDocumentsTableData:
        'stopDocuments/getAdditionalDocumentsTableData',
      nonHazManifestsTableHeaders:
        'stopDocuments/getNonHazManifestsTableHeaders',
      manifestsContinuationTableData:
        'stopDocuments/getManifestsContinuationTableData',
      manifestsContinuationTableHeaders:
        'stopDocuments/getManifestsContinuationTableHeaders',
      additionalDocumentsTableHeaders:
        'stopDocuments/getAdditionalDocumentsTableHeaders',
      documentTypesPrintingState: 'stopDocuments/getDocumentTypesPrintingState',
      sdsUrl: 'stopDocuments/getCurrentSDSUrl',
      reprintStatus: 'stopDocuments/getDocumentReprintStatus',
    }),
    printIsValid() {
      return this.selectedDocumentsCount > 0;
    },
    isManifestForHazItem() {
      if (
        this.printableDocuments
        && this.printableDocuments[0]
        && this.printableDocuments[0].type === documentTypes.HAZ_MANIFEST
      ) {
        return true;
      }
      return false;
    },
    showReprintAlert() {
      return this.reprintStatus;
    },
  },
  data() {
    return {
      selectedDocumentsCount: 0,
      printButtonLabel: 'Print selected document(s)',
      printButtonClicked: false,
      printingDocumentsHeader: null,
      allSelectedDocuments: null,
      printingComplete: false,
      printInstructionDescription: null,
      printableDocuments: [],
      printableFirstPageDocumentsIndex: 0,
      printableDocumentListTable: null,
      printTableHeading: null,
      isFirstPage: true,
      multipleDocumentsToPrint: false,
      nextDocumentToPrint: false,
      hasPrintedOnce: false,
      shouldShowErrorModal: false,
      shouldShowReprintErrorModal: false,
      modalData: {
        heading: 'No manifest number created',
        subHeading: 'Instructions',
        description:
          'To print a Manifest Continuation Page, you must have first printed and entered the'
          + ' manifest number. Please print the Manifest First Page.',
      },
      reprintErrorModalData: {
        heading: 'Print Manifest first page',
        subHeading: 'Instructions',
        description:
          'To print a Manifest Continuation Page, you must have first reprinted the Manifest First Page.',
      },
      modalButtons: {
        buttonConfirmText: 'Print anyway',
        buttonCancelText: 'Cancel print',
        buttonConfirmVisibility: true,
        buttonCancelVisibility: true,
      },
      reprintErrorModalButtons: {
        buttonConfirmText: 'OK',
        buttonConfirmVisibility: true,
      },
      dialogProperty: {
        modalHeight: 350,
        modalWidth: 840,
      },
      openPDFDrawer: false,
    };
  },
  created() {
    const stopDetail = this.$store.getters.getStopDetail;
    this.$store.dispatch('stopDocuments/setDocumentsData', stopDetail.id);
    this.$store.dispatch('stopDocuments/setTableHeaders');
  },
  methods: {
    selectAllDocuments() {
      this.$store.dispatch(
        'stopDocuments/updateAllDocumentsSelectionStatus',
        true,
      );
    },

    deselectAllDocuments() {
      this.$store.dispatch(
        'stopDocuments/updateAllDocumentsSelectionStatus',
        false,
      );
    },

    countNumberOfSelectedDocuments() {
      this.selectedDocumentsCount = 0;
      this.selectedDocumentsCount += this.getSelectedDocumentsCount(this.hazManifestsTableData);
      this.selectedDocumentsCount += this.getSelectedDocumentsCount(this.nonHazManifestsTableData);
      this.selectedDocumentsCount += this
        .getSelectedDocumentsCount(this.manifestsContinuationTableData);
      this.selectedDocumentsCount += this
        .getSelectedDocumentsCount(this.additionalDocumentsTableData);
    },

    getSelectedDocumentsCount(documents) {
      let selectedDocsCount = 0;
      documents.forEach((document) => {
        if (document.selected) {
          selectedDocsCount += 1;
        }
      });
      return selectedDocsCount;
    },

    setPrintButtonLabel() {
      this.printButtonLabel = 'Print selected document(s)';
    },

    printSelectedDocuments() {
      this.printButtonClicked = true;
      this.printableDocumentListTable = true;
      this.printingDocumentsHeader = this.hazManifestPrintLabel;
      this.setAllSelectedDocuments();
      if (!this.canReprintContinuationDocuments()) {
        this.printButtonClicked = false;
        this.shouldShowReprintErrorModal = true;
      } else if (this.canPrintContinuationDocuments() && this.canPrintOtherDocuments()) {
        this.updatePrintingTypeDocumentState('');
      } else {
        this.printButtonClicked = false;
        this.shouldShowErrorModal = true;
      }
    },

    printAnyway() {
      this.printButtonClicked = true;
      this.printableDocumentListTable = true;
      this.printingDocumentsHeader = this.hazManifestPrintLabel;
      this.setAllSelectedDocuments();
      this.updatePrintingTypeDocumentState('');
      this.shouldShowErrorModal = false;
      this.shouldShowReprintErrorModal = false;
    },

    setAllSelectedDocuments() {
      this.allSelectedDocuments = {
        hazManifestDocuments: [],
        nonHazManifestDocuments: [],
        manifestContinuationDocuments: [],
        additionalDocuments: [],
      };
      this.allSelectedDocuments.hazManifestDocuments = this.hazManifestsTableData
        .filter(
          (doc) => doc.selected,
        );
      this.allSelectedDocuments.nonHazManifestDocuments = this.nonHazManifestsTableData
        .filter(
          (doc) => doc.selected,
        );
      this.allSelectedDocuments.manifestContinuationDocuments = this.manifestsContinuationTableData
        .filter(
          (doc) => doc.selected,
        );
      this.allSelectedDocuments.additionalDocuments = this.additionalDocumentsTableData
        .filter(
          (doc) => doc.selected,
        );
    },

    canPrintContinuationDocuments() {
      let hasAssociatedManifestNumber = true;
      this.allSelectedDocuments.manifestContinuationDocuments.forEach((doc) => {
        if (doc.name === '') {
          const hazParentDoc = this.allSelectedDocuments.hazManifestDocuments
            .findIndex((parentDocument) => parentDocument.cloud_id === doc.cloud_id);
          const nonHazParentDoc = this.allSelectedDocuments.nonHazManifestDocuments
            .findIndex((parentDocument) => parentDocument.cloud_id === doc.cloud_id);
          if (hazParentDoc === -1 && nonHazParentDoc === -1) {
            hasAssociatedManifestNumber = false;
          }
        }
      });
      if (!hasAssociatedManifestNumber) {
        this.modalData.description = 'To print a Manifest continuation page, '
          + 'you must have first printed and entered the'
          + ' manifest number. Please print the Manifest first page.';
      }
      return hasAssociatedManifestNumber;
    },

    canReprintContinuationDocuments() {
      let firstPageReprinted = true;
      this.allSelectedDocuments.manifestContinuationDocuments.forEach((doc) => {
        const hazFirstPageReprinted = this.hazManifestsTableData
          .find((hazFirstDoc) => hazFirstDoc.cloud_id === doc.cloud_id);
        if (hazFirstPageReprinted) {
          firstPageReprinted = !hazFirstPageReprinted.need_reprinting;
        } else {
          const nonHazFirstPageReprinted = this.hazManifestsTableData
            .find((hazFirstDoc) => hazFirstDoc.cloud_id === doc.cloud_id);
          if (nonHazFirstPageReprinted) {
            firstPageReprinted = !nonHazFirstPageReprinted.need_reprinting;
          }
        }
        if (!firstPageReprinted) {
          const hazParentDoc = this.allSelectedDocuments.hazManifestDocuments
            .findIndex((parentDocument) => parentDocument.cloud_id === doc.cloud_id);
          const nonHazParentDoc = this.allSelectedDocuments.nonHazManifestDocuments
            .findIndex((parentDocument) => parentDocument.cloud_id === doc.cloud_id);
          if (hazParentDoc >= 0 || nonHazParentDoc >= 0) {
            firstPageReprinted = true;
          }
        }
      });
      return firstPageReprinted;
    },

    canPrintOtherDocuments() {
      let hasAssociatedManifestNumber = true;
      this.allSelectedDocuments.additionalDocuments.forEach((doc) => {
        if (doc.type === documentConstants.types.PICKUP_REPORT) {
          if (!(this.checkIfManifestNumberIsEntered(this.hazManifestsTableData)
            && this.checkIfManifestNumberIsEntered(this.nonHazManifestsTableData)
            && this.checkIfManifestNumberIsEntered(this.manifestsContinuationTableData))) {
            hasAssociatedManifestNumber = false;
          }
        }
        if (doc.type === documentConstants.types.LAND_DISPOSAL) {
          if (doc.name === '') {
            const hazParentDoc = this.allSelectedDocuments.hazManifestDocuments
              .findIndex((parentDocument) => parentDocument.cloud_id === doc.cloud_id);
            const nonHazParentDoc = this.allSelectedDocuments.nonHazManifestDocuments
              .findIndex((parentDocument) => parentDocument.cloud_id === doc.cloud_id);
            if (hazParentDoc === -1 && nonHazParentDoc === -1) {
              hasAssociatedManifestNumber = false;
            }
          }
        }
      });
      if (!hasAssociatedManifestNumber) {
        this.modalData.description = 'To print this document, '
          + 'you must have first printed and entered the'
          + ' manifest number(s). Please print the manifest first page.';
      }
      return hasAssociatedManifestNumber;
    },

    checkIfManifestNumberIsEntered(documents) {
      let isEntered = true;
      documents.forEach((doc) => {
        if (doc.name === '') {
          const hazParentDoc = this.allSelectedDocuments.hazManifestDocuments
            .findIndex((parentDocument) => parentDocument.cloud_id === doc.cloud_id);
          const nonHazParentDoc = this.allSelectedDocuments.nonHazManifestDocuments
            .findIndex((parentDocument) => parentDocument.cloud_id === doc.cloud_id);
          if (hazParentDoc === -1 && nonHazParentDoc === -1) {
            isEntered = false;
          }
        }
      });
      return isEntered;
    },

    updatePrintingTypeDocumentState(printingTypeState) {
      switch (printingTypeState) {
        case printingDocumentsTypeState.HAZ_MANIFEST:
          this.printingDocumentsHeader = printingDocumentsTypeLabel
            .NON_HAZ_MANIFEST_PRINT_LABEL;
          this.printInstructionDescription = printInstructionsDescriptions
            .NON_HAZ_MANIFEST_PRINT_INSTRUCTION;
          this.$store.dispatch(
            'stopDocuments/updateDocumentTypesPrintingState',
            printingDocumentsTypeState.NON_HAZ_MANIFEST,
          );
          break;
        case printingDocumentsTypeState.NON_HAZ_MANIFEST:
          this.printingDocumentsHeader = printingDocumentsTypeLabel
            .MANIFEST_CONTINUATION_PRINT_LABEL;
          this.printInstructionDescription = printInstructionsDescriptions
            .CONTINUATION_MANIFEST_PRINT_INSTRUCTION;
          this.$store.dispatch(
            'stopDocuments/updateDocumentTypesPrintingState',
            printingDocumentsTypeState.MANIFEST_CONTINUATION,
          );
          break;
        case printingDocumentsTypeState.MANIFEST_CONTINUATION:
          this.printingDocumentsHeader = printingDocumentsTypeLabel
            .ADDITIONAL_DOCUMENTS_PRINT_LABEL;
          this.printInstructionDescription = printInstructionsDescriptions
            .ADDITIONAL_DOCUMENT_PRINT_INSTRUCTION;
          this.$store.dispatch(
            'stopDocuments/updateDocumentTypesPrintingState',
            printingDocumentsTypeState.ADDITIONAL_DOCUMENTS,
          );
          break;
        default:
          this.printingDocumentsHeader = printingDocumentsTypeLabel.HAZ_MANIFEST_PRINT_LABEL;
          this.printInstructionDescription = printInstructionsDescriptions
            .HAZ_MANIFEST_PRINT_INSTRUCTION;
          this.$store.dispatch(
            'stopDocuments/updateDocumentTypesPrintingState',
            printingDocumentsTypeState.HAZ_MANIFEST,
          );
      }
      this.getPrintableDocuments();
    },

    getPrintableDocuments() {
      this.printableDocuments = [];
      this.isFirstPage = false;
      switch (this.documentTypesPrintingState) {
        case printingDocumentsTypeState.HAZ_MANIFEST:
          if (this.allSelectedDocuments.hazManifestDocuments.length > 0) {
            this.printTableHeading = `Print manifest ${this.printableFirstPageDocumentsIndex + 1}`
              + ` of ${this.allSelectedDocuments.hazManifestDocuments.length}`;
            this.printableDocuments.push(
              this.allSelectedDocuments.hazManifestDocuments[
                this.printableFirstPageDocumentsIndex
              ],
            );
            this.multipleDocumentsToPrint = false;
            this.isFirstPage = true;
          } else {
            this.updatePrintingTypeDocumentState(
              this.documentTypesPrintingState,
            );
          }
          break;
        case printingDocumentsTypeState.NON_HAZ_MANIFEST:
          if (this.allSelectedDocuments.nonHazManifestDocuments.length > 0) {
            this.printTableHeading = `Print manifest ${this.printableFirstPageDocumentsIndex + 1}`
              + ` of ${this.allSelectedDocuments.nonHazManifestDocuments.length}`;
            this.printableDocuments.push(
              this.allSelectedDocuments.nonHazManifestDocuments[
                this.printableFirstPageDocumentsIndex
              ],
            );
            this.multipleDocumentsToPrint = false;
            this.isFirstPage = true;
          } else {
            this.updatePrintingTypeDocumentState(
              this.documentTypesPrintingState,
            );
          }
          break;
        case printingDocumentsTypeState.MANIFEST_CONTINUATION:
          if (this.allSelectedDocuments.manifestContinuationDocuments.length > 0) {
            this.printTableHeading = 'Documents in queue';
            this.multipleDocumentsToPrint = this.allSelectedDocuments
              .manifestContinuationDocuments.length > 1;
            this.allSelectedDocuments.manifestContinuationDocuments.forEach((doc) => {
              this.printableDocuments.push(doc);
            });
          } else {
            this.updatePrintingTypeDocumentState(
              this.documentTypesPrintingState,
            );
          }
          break;
        default:
          if (this.allSelectedDocuments.additionalDocuments.length > 0) {
            this.printTableHeading = 'Documents in queue';
            this.multipleDocumentsToPrint = this.allSelectedDocuments
              .additionalDocuments.length > 1;
            this.allSelectedDocuments.additionalDocuments.forEach((doc) => {
              this.printableDocuments.push(doc);
            });
          } else {
            this.resetPrintingDocument();
          }
      }
      this.checkNextDocumentToPrint();
    },

    printDocuments() {
      const stopId = this.$store.getters.getStopDetail.id;
      let printableDocumentsCount = this.printableDocuments.length;
      this.printableDocuments.forEach((document) => {
        const payload = {
          doc_type: document.type,
          cloud_id: document.cloud_id,
          original_name: document.original_name,
          tsdf: document.tsdf,
        };
        this.$store.dispatch('attachment/updatePrintedDocumentList', document);
        printBackendService.printDocument(payload, stopId).then(
          (response) => {
            const printedDoc = {
              cloud_id: response.cloud_id,
              type: response.doc_type,
            };
            const payloadData = {
              stopId,
              printedDoc,
            };
            this.$store.dispatch('stopDocuments/updatePrintedDocumentStatus', payloadData);
            printableDocumentsCount -= 1;
            if (printableDocumentsCount === 0) {
              this.printingComplete = true;
            }
          },
        );
      });
    },

    printNextDocumentSet(manifestNumber) {
      this.hasPrintedOnce = true;
      this.printingComplete = false;
      switch (this.documentTypesPrintingState) {
        case printingDocumentsTypeState.HAZ_MANIFEST:
          this.updateManifestNumberOfFirstPageDocuments(manifestNumber);
          if (
            this.allSelectedDocuments.hazManifestDocuments.length - 1
            > this.printableFirstPageDocumentsIndex
          ) {
            this.printableFirstPageDocumentsIndex = this.printableFirstPageDocumentsIndex + 1;
            this.getPrintableDocuments();
          } else {
            this.printableFirstPageDocumentsIndex = 0;
            this.updatePrintingTypeDocumentState(
              this.documentTypesPrintingState,
            );
          }
          break;
        case printingDocumentsTypeState.NON_HAZ_MANIFEST:
          this.updateManifestNumberOfFirstPageDocuments(manifestNumber);
          if (
            this.allSelectedDocuments.nonHazManifestDocuments.length - 1
            > this.printableFirstPageDocumentsIndex
          ) {
            this.printableFirstPageDocumentsIndex = this.printableFirstPageDocumentsIndex + 1;
            this.getPrintableDocuments();
          } else {
            this.printableFirstPageDocumentsIndex = 0;
            this.updatePrintingTypeDocumentState(
              this.documentTypesPrintingState,
            );
          }
          break;
        case printingDocumentsTypeState.MANIFEST_CONTINUATION:
          this.updatePrintingTypeDocumentState(this.documentTypesPrintingState);
          break;
        default:
          this.resetPrintingDocument();
      }
    },

    updateManifestNumberOfFirstPageDocuments(manifestNumber) {
      const manifestIntegratedDoc = this.printableDocuments[0];
      const stopId = this.$store.getters.getStopDetail.id;
      manifestIntegratedDoc.name = manifestNumber;
      Object.assign(this.printableDocuments[0], manifestIntegratedDoc);
      this.updateManifestNumberOfRelatedDocuments(manifestIntegratedDoc);
      this.$store.dispatch('stopDocuments/updateManifestDocument', {
        doc: this.printableDocuments[0],
        stopId,
      });
      this.$store.dispatch('attachment/updatePrintedDocumentList', this.printableDocuments[0]);
    },

    updateManifestNumberOfRelatedDocuments(manifestFirstPage) {
      this.updateManifestNumberOfContinuationDocument(manifestFirstPage);
      this.updateManifestNumberOfLandDisposal(manifestFirstPage);
    },

    updateManifestNumberOfContinuationDocument(manifestFirstPage) {
      const continuationDocuments = Object.assign(this.manifestsContinuationTableData);
      continuationDocuments.forEach((continuationDocument) => {
        if (continuationDocument.original_name === manifestFirstPage.original_name) {
          const document = continuationDocument;
          document.name = manifestFirstPage.name;
          Object.assign(continuationDocument, document);
          this.$store.dispatch('stopDocuments/updateManifestContinuationDocument', continuationDocument);
        }
      });
    },

    updateManifestNumberOfLandDisposal(manifestFirstPage) {
      const additionalDocs = Object.assign(this.additionalDocumentsTableData);
      additionalDocs.forEach((additionalDoc) => {
        if (additionalDoc.type === 'land_disposal' && additionalDoc.original_name === manifestFirstPage.original_name) {
          const document = additionalDoc;
          document.name = manifestFirstPage.name;
          Object.assign(additionalDoc, document);
          this.$store.dispatch('stopDocuments/updateLandDisposalDocument', additionalDoc);
        }
      });
    },

    resetPrintingDocument() {
      this.hasPrintedOnce = false;
      this.printButtonClicked = false;
      this.printingComplete = false;
      this.deselectAllDocuments();
      this.updateDocumentsReprintStatus();
      this.$store.dispatch('stopDocuments/updateDocumentTypesPrintingState', '');
    },

    updateDocumentsReprintStatus() {
      const stopId = this.$store.getters.getStopDetail.id;
      this.$store.dispatch('stopDocuments/getDocumentReprintStatus', stopId);
    },

    reprintDocument() {
      this.printingComplete = false;
    },

    checkNextDocumentToPrint() {
      this.nextDocumentToPrint = true;
      switch (this.documentTypesPrintingState) {
        case printingDocumentsTypeState.HAZ_MANIFEST:
          if (this.allSelectedDocuments.hazManifestDocuments.length - 1
            === this.printableFirstPageDocumentsIndex) {
            this.nextDocumentToPrint = this.allSelectedDocuments.nonHazManifestDocuments.length > 0
                || this.allSelectedDocuments.manifestContinuationDocuments.length > 0
                || this.allSelectedDocuments.additionalDocuments.length > 0;
          }
          break;
        case printingDocumentsTypeState.NON_HAZ_MANIFEST:
          if (this.allSelectedDocuments.nonHazManifestDocuments.length - 1
            === this.printableFirstPageDocumentsIndex) {
            this.nextDocumentToPrint = this.allSelectedDocuments.manifestContinuationDocuments
              .length > 0 || this.allSelectedDocuments.additionalDocuments.length > 0;
          }
          break;
        case printingDocumentsTypeState.MANIFEST_CONTINUATION:
          this.nextDocumentToPrint = this.allSelectedDocuments
            .additionalDocuments.length > 0;
          break;
        default:
          this.nextDocumentToPrint = false;
      }
    },

    closeErrorModal() {
      this.shouldShowErrorModal = false;
    },

    closeReprintErrorModal() {
      this.shouldShowReprintErrorModal = false;
    },

    openView() {
      this.openPDFDrawer = true;
    },

    removeSDSUrl() {
      this.$store.dispatch('stopDocuments/setCurrentSDSUrl', '');
    },

    closePDFView() {
      this.openPDFDrawer = false;
    },
  },
  watch: {
    hazManifestsTableData: {
      deep: true,
      handler() {
        this.countNumberOfSelectedDocuments();
        this.setPrintButtonLabel();
      },
    },
    nonHazManifestsTableData: {
      deep: true,
      handler() {
        this.countNumberOfSelectedDocuments();
        this.setPrintButtonLabel();
      },
    },
    manifestsContinuationTableData: {
      deep: true,
      handler() {
        this.countNumberOfSelectedDocuments();
        this.setPrintButtonLabel();
      },
    },
    additionalDocumentsTableData: {
      deep: true,
      handler() {
        this.countNumberOfSelectedDocuments();
        this.setPrintButtonLabel();
      },
    },
    sdsUrl: {
      deep: true,
      handler(url) {
        if (url) {
          this.openView();
        } else {
          this.closePDFView();
        }
      },
    },
  },
};
</script>
