import containerService from '../service/container.service';
import rxApprovalService from '../service/rx-approval.service';
import rxItemMobile from '../service/firestore/rx-items.service';
import sum from '../helpers/utils/sum';

function pListedWeightWarn(commit, state, payload, type = 'add', rootState = null) {
  const stopApprovalId = rootState ? rootState.rxApproval.rxEditApproval.id : null;
  let approvalId;
  let stopRxItemId;
  if (type === 'add') {
    approvalId = payload.id;
  } else {
    approvalId = payload.approvalId;
    stopRxItemId = payload.id;
  }
  const description = payload.approval_description;
  let weight;
  if (payload.rxEmptyBottle) {
    weight = payload.contentList.reduce((resp, content) => {
      const contentObj = content;
      let resWeight = parseFloat(contentObj.residualWeight)
        * parseInt(contentObj.noOfEmptyBottles, 10);
      if (contentObj.bottleWeight) {
        resWeight += parseFloat(content.bottleWeight);
      }
      return resp + resWeight;
    }, 0.0);
  } else {
    weight = payload.contentList
      .reduce((resp, content) => resp + parseFloat(content.totalWeight), 0.0);
  }
  let approvalRxItemId;
  if (type === 'edit') {
    approvalRxItemId = payload.approval_rx_item_id;
  }
  commit('consolidate/setOverWeightApproval', {
    weight, description, approvalRxItemId, approvalId, stopApprovalId, stopRxItemId, type: 'rxItem',
  }, { root: true });
  commit('consolidate/setStopPListedWeightError', true, { root: true });
}

function buildContentList(contentList, emptyBottleFlag) {
  const contentListArray = contentList;
  return contentListArray.map((content) => {
    const params = {};
    params.containers = emptyBottleFlag ? 0 : content.containers;
    params.contentID = content.contentID;
    params.contentQuantity = content.contentQuantity;
    params.month = content.month;
    params.weightPounds = content.weightPounds;
    params.weightOunces = content.weightOunces;
    params.weightUnit = content.weightUnit;
    params.totalWeight = emptyBottleFlag ? (content.bottleWeight + content.residualWeight) : 0.0;
    params.noOfEmptyBottles = content.noOfEmptyBottles;
    params.residualWeight = content.residualWeight;
    params.bottleWeight = content.bottleWeight;
    params.billUnits = content.billUnits;

    return params;
  });
}

function buildRxItem(rxItem, contentList, emptyBottleFlag) {
  const params = {};
  params.dea_schedule = rxItem.dea_schedule;
  params.ndc_code = rxItem.ndc_code;
  params.note = rxItem.note;
  params.rx_item_description = rxItem.rx_item_description;
  params.rx_item_id = rxItem.rx_item_id;
  params.manifest_id = rxItem.manifest_id;
  params.contentList = buildContentList(contentList, emptyBottleFlag);

  return params;
}
function rxItemApprovalBuilder(approval, approvalList, index) {
  const finalApproval = approval;
  let { note: rxItemNote } = approvalList.rxItem;
  const { rxItem: approvalObj, contentList } = approvalList;
  rxItemNote = rxItemNote.note ? rxItemNote.note : rxItemNote;
  approvalObj.note = rxItemNote;
  const rxItemObj = buildRxItem(approvalObj, contentList, finalApproval.empty_bottle_flag);
  if (index === -1) {
    finalApproval.rxItems.push(rxItemObj);
  } else {
    finalApproval.rxItems[index] = rxItemObj;
  }
  finalApproval.noOfRxItems = finalApproval.rxItems.length;
  return finalApproval;
}

function changeQuantityType(rxApprovalList) {
  return rxApprovalList.map((resp) => {
    const returnValue = resp;
    const stopApproval = resp.stop_approval;
    if (stopApproval && stopApproval.stop_containers[0]) {
      const quantity = stopApproval.stop_containers[0].quantity || 0;
      returnValue.containerQuantity = parseInt(quantity, 10);
      returnValue.containerId = stopApproval.stop_containers[0].container_id || 0;
    } else {
      returnValue.containerQuantity = 0;
      returnValue.containerId = 0;
    }
    return returnValue;
  });
}

const stateObj = {
  rxApprovalList: [],
  rxSelectedApproval: {},
  selectedIndex: -1,
  hideAndShowRxApprovalList: false,
  showRxCancelConfirmationDialog: false,
  pListedBottleStatus: true,
  mobileRxApprovalList: [],
  rxApprovalAddedStatus: false,
};
const getters = {
  getRxApprovalList(stateControl) {
    return stateControl.rxApprovalList;
  },
  getParticularList(stateControl) {
    return stateControl.rxSelectedApproval;
  },
  getSelectedIndex(stateControl) {
    return stateControl.selectedIndex;
  },
  getRxCancelConfirmationDialog(stateControl) {
    return stateControl.showRxCancelConfirmationDialog;
  },
  getPListedBottleStatus(stateControl) {
    return stateControl.pListedBottleStatus;
  },
  getMobileRxApprovalList(stateControl) {
    return stateControl.mobileRxApprovalList;
  },
  getRxApprovalAddedStatus(stateControl) {
    return stateControl.rxApprovalAddedStatus;
  },
};
const mutations = {
  setSelectedApproval(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxSelectedApproval = stateControld.rxApprovalList[payload];
  },
  addRxApprovalData(stateControl, payload) {
    const stateControld = stateControl;
    const rxApproval = payload;
    let contentTotalWeight = '0.00';
    if (rxApproval.contentList && rxApproval.contentList.length > 0) {
      contentTotalWeight = sum(rxApproval.contentList, 'totalWeight');
    }
    let rxItem = {
      id: '',
    };
    if (rxApproval.rx_item) {
      rxItem = rxApproval.rx_item;
    }
    const { dispatch } = rxApproval;
    rxItem.totalWeight = contentTotalWeight;
    rxItem.contentList = [];
    rxItem.contentList = rxApproval.contentList;
    delete rxApproval.dispatch;
    delete rxApproval.rx_item;
    delete rxApproval.contentList;
    rxApproval.total_weight = contentTotalWeight;
    const rxApprovalIndex = stateControld.rxApprovalList.findIndex(
      (approval) => approval.id === payload.id,
    );
    if (rxApprovalIndex > -1) {
      stateControld.rxApprovalList[rxApprovalIndex].rx_item.push(rxItem);
      stateControld.rxApprovalList[rxApprovalIndex].total_weight
        += rxApproval.total_weight;
    } else {
      rxApproval.containerId = '';
      rxApproval.containerQuantity = 0;
      rxApproval.rx_item = [];
      rxApproval.rx_item.push(rxItem);
      if (!rxApproval.approval_code) {
        stateControld.rxApprovalList.unshift(rxApproval);
      }
      dispatch('getApprovalContainerAction', {
        rxApprovalId: rxApproval.id,
        stateControl: stateControld,
        rxApprovalData: rxApproval,
      });
    }
  },
  setSelectedIndex(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.selectedIndex = payload;
  },
  setContainerTypeList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxApprovalList[payload.index].containerList = payload.containers;
  },
  setRxContainerValue(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxApprovalList[payload.index][payload.field] = payload.value;
  },
  increaseRxContainerQuantity(stateControl, payload) {
    const stateControld = stateControl;
    const contentData = stateControld.rxApprovalList[payload.index];
    stateControld.rxApprovalList[payload.index][payload.field] = contentData[
      payload.field
    ]
      ? contentData[payload.field] + 1
      : 1;
  },
  decreaseRxContainerQuantity(stateControl, payload) {
    const stateControld = stateControl;
    const contentData = stateControld.rxApprovalList[payload.index];
    stateControld.rxApprovalList[payload.index][payload.field] = contentData[payload.field] - 1;
  },
  setRxApprovalList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxApprovalList = payload;
  },
  hideRxApprovalConsolidationList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.hideAndShowRxApprovalList = payload;
  },
  setRxApprovedList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxSelectedApproval = payload;
    stateControld.rxSelectedApproval.editType = 'consolidate';
  },
  confirmDelete(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxSelectedApproval.stop_rx_items[
      payload.index
    ].deleteRxStatus = payload.value;
  },
  showRxCancelConfirmationDialog(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.showRxCancelConfirmationDialog = payload;
  },
  setPListedBottleStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.pListedBottleStatus = payload;
  },
  setMobileRxApprovalList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.mobileRxApprovalList = payload;
  },
  setRxApprovalAddedStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxApprovalAddedStatus = payload;
  },
};
const actions = {
  setSelectedApprovalAction({ commit }, res) {
    commit('setSelectedApproval', res);
    commit('setSelectedIndex', res);
  },
  setRxApprovalDataAction({ dispatch, commit, state }, res) {
    res.dispatch = dispatch;
    const params = {};
    params.approval_id = res.id;
    params.rx_item_id = res.rx_item ? res.rx_item.id : null;
    params.rx_code_id = res.rx_item ? res.rx_item.rx_code_id : null;
    params.rx_item_note = res.selected_notes;
    params.manifest_id = res.manifest_id;
    params.content_details = [];
    if (res.rxEmptyBottle) {
      res.contentList.forEach((contentDetail) => {
        let totalWeight = 0;
        const contentDetailsObj = {
          no_of_bottle: contentDetail.noOfEmptyBottles,
          residual_weight: contentDetail.residualWeight,
          bottle_weight: contentDetail.bottleWeight,
          weight_unit: contentDetail.weightUnit,
          scale_type: contentDetail.weightType === 0 ? 'manual' : 'bench',
          month: contentDetail.month,
          bill_units: contentDetail.billUnits,
        };
        if (contentDetail.weightType === 1) {
          contentDetailsObj.scale_type = 'bench';
          contentDetailsObj.bottle_weight = 0;
          contentDetailsObj.weight_unit = 4;
        } else {
          contentDetailsObj.scale_type = 'manual';
          totalWeight = contentDetail.residualWeight
            * parseInt(contentDetail.noOfEmptyBottles, 10);
          if (contentDetail.bottleWeight) {
            totalWeight += parseFloat(contentDetail.bottleWeight);
          }
        }
        contentDetailsObj.total_weight = totalWeight;
        params.content_details.push(contentDetailsObj);
      });
    } else {
      res.contentList.forEach((contentDetail) => {
        params.content_details.push({
          content_detail_id: contentDetail.contentID,
          quantity: contentDetail.contentQuantity,
          pounds: contentDetail.weightPounds,
          ounces: contentDetail.weightOunces,
          no_of_containers: contentDetail.containers,
          scale_type: contentDetail.weightType,
          month: contentDetail.month,
          bill_units: contentDetail.billUnits,
        });
      });
    }
    params.allow_consolidate = res.allow_consolidate || false;
    return new Promise((resolve, reject) => {
      rxApprovalService.addToApprovalList(params, res.stopId).then((response) => {
        if (response) {
          dispatch('getRxApprovalList', res.stopId);
          dispatch('rxApproval/resetAllContentData', '', { root: true });
          commit('rxApproval/resetApprovalDetail', {}, { root: true });
          commit('rxApproval/setRxEmptyBottle', false, { root: true });
          commit('rxApproval/enableShowContent', true, { root: true });
          commit('rxContent/enablePreviousMonthBtn', {}, { root: true });
          commit('rxContent/setEmptyBottleStatus', false, { root: true });
          commit('setRxApprovalAddedStatus', true);
        }
        resolve(response);
      }, (error) => {
        const isString = typeof error === 'string';
        if (isString && error.match(/p listed weight is more than 2.2 lb/i)) {
          pListedWeightWarn(commit, state, res);
        }
        reject(error);
      });
    });
  },
  getApprovalContainerAction({ commit }, res) {
    containerService.getContainerTypeList(res.rxApprovalId).then((response) => {
      res.rxApprovalData.containerList = response.containers;
      res.stateControl.rxApprovalList.unshift(res.rxApprovalData);
      commit('setContainerTypeList', {
        containers: response.containers,
        index: 0,
      });
    });
  },
  getRxApprovalList({ commit, state }, stopId) {
    if (!state.hideAndShowRxApprovalList) {
      rxApprovalService.getRxApprovalList(stopId).then((response) => {
        if (response && response.rx_approval_list) {
          const resp = changeQuantityType(response.rx_approval_list);
          commit('setRxApprovalList', resp);
        }
      });
    } else {
      commit('setRxApprovalList', []);
    }
  },
  rxConsolidate(context, payload) {
    rxApprovalService.rxConsolidate(payload).then((response) => {
      if (context.rootState.stopDetail.useCCIDStorage) {
        response.stop_approval.approval_detail = payload.approval;
        context.commit('setConsolidateApproval', response.stop_approval, {
          root: true,
        });
      } else {
        context.dispatch('getRxApprovalList', payload.stop_id);
        context.dispatch('getStopDetailAction', { stopID: payload.stop_id, type: '' }, { root: true });
        const stopApprovalData = {
          id: response.stop_approval.id,
          waste_type: response.stop_approval.waste_type,
          approval_id: response.stop_approval.approval_id,
          weight: response.stop_approval.weight,
          weight_unit: response.stop_approval.weight_unit,
        };
        context.dispatch('updateAccumulationData', stopApprovalData, { root: true });
      }
    });
  },
  updateRxApprovalDataAction({
    commit, dispatch, state, rootState,
  }, payload) {
    const params = {};
    params.approval_id = payload.approvalId;
    params.rx_item_id = payload.rx_item.id;
    params.rx_code_id = payload.rx_code.id;
    params.rx_item_note = payload.selected_notes;
    params.stopRxItemId = payload.id;
    params.content_details = [];
    if (payload.rxEmptyBottle) {
      payload.contentList.forEach((obj) => {
        let totalWeight = 0.0;
        const contentDetailsObj = {
          no_of_bottle: obj.noOfEmptyBottles,
          residual_weight: obj.residualWeight,
          bottle_weight: obj.bottleWeight,
          weight_unit: obj.weightUnit,
          total_weight: obj.residualWeight * obj.noOfEmptyBottles,
          scale_type: obj.weightType === 0 ? 'manual' : 'bench',
          month: obj.month,
          bill_units: obj.billUnits,
        };
        if (obj.weightType === 1) {
          contentDetailsObj.scale_type = 'bench';
          contentDetailsObj.bottle_weight = 0;
          contentDetailsObj.weight_unit = 4;
        } else {
          contentDetailsObj.scale_type = 'manual';
          totalWeight = obj.residualWeight * parseInt(obj.noOfEmptyBottles, 10);
          if (obj.bottleWeight) {
            totalWeight += parseFloat(obj.bottleWeight);
          }
        }
        contentDetailsObj.total_weight = totalWeight;
        params.content_details.push(contentDetailsObj);
      });
    } else {
      payload.contentList.forEach((obj) => {
        params.content_details.push({
          content_detail_id: obj.contentID,
          quantity: obj.contentQuantity,
          pounds: obj.weightPounds,
          ounces: obj.weightOunces,
          no_of_containers: obj.containers,
          scale_type: obj.weightType,
          month: obj.month,
          bill_units: obj.billUnits,
        });
      });
    }
    params.allow_consolidate = payload.allow_consolidate || false;
    return new Promise((resolve, reject) => {
      rxApprovalService.updateRxApproval(payload.id, params).then((res) => {
        if (res) {
          const param = {
            stop_approval: {
              id: res.rx_approval_item.stop_approval_id,
              waste_type: 'rx',
              editType: payload.editType,
              stopId: payload.stopId,
            },
            res,
          };
          dispatch('rxApproval/editRxApproval', param, { root: true });
        }
        resolve(res);
      }, (error) => {
        const isString = typeof error === 'string';
        if (isString && error.match(/p listed weight is more than 2.2 lb/i)) {
          pListedWeightWarn(commit, state, payload, 'edit', rootState);
        }
        reject(error);
      });
    });
  },
  rxReConsolidate(context, payload) {
    rxApprovalService.rxReConsolidate(payload).then((response) => {
      if (context.rootState.stopDetail.useCCIDStorage) {
        response.stop_approval.approval_detail = payload.approval;
        context.commit('setConsolidateApproval', response.stop_approval, {
          root: true,
        });
      }
    });
  },
  saveAndExit(context, payload) {
    return new Promise((resolve) => {
      rxApprovalService.rxReConsolidate(payload).then((response) => {
        resolve(response);
      });
    });
  },
  getRxApprovalListbyType({ commit, state }, stopId) {
    rxApprovalService.getRxApprovalList(stopId).then((response) => {
      if (response && response.rx_approval_list) {
        const resp = changeQuantityType(response.rx_approval_list);
        commit('setRxApprovalList', resp);
        commit('setSelectedApproval', state.selectedIndex);
      }
    });
  },
  storeApprovalList({ commit }, params) {
    rxItemMobile.saveApprovalDetails(commit, params);
  },
  addToApprovalListMobile({ dispatch, state }, approvalList) {
    const { rxItem: approvalObj } = approvalList;
    const { rxApprovalList } = state;
    let findApprovalIndex = rxApprovalList
      .findIndex((approval) => approval.approvalId === approvalObj.approval_id);
    let finalApproval;
    const { stop_id: stopId } = approvalObj;
    if (findApprovalIndex !== -1) {
      finalApproval = rxApprovalList[findApprovalIndex];
    } else {
      finalApproval = {
        approvalDescription: approvalObj.approval_description,
        containerType: '',
        containerQuantity: 0,
        rxItems: [],
        approvalId: approvalObj.approval_id,
        stopId,
        empty_bottle_flag: approvalObj.empty_bottle_flag || false,
      };
      findApprovalIndex = 0;
    }
    const indx = approvalObj.index >= 0 ? approvalObj.index : -1;
    const approval = rxItemApprovalBuilder(finalApproval, approvalList, indx);
    rxApprovalList[findApprovalIndex] = approval;
    const params = {
      stopId,
      rxApproval: approval,
    };
    dispatch('storeApprovalList', params);
  },
  getMobileRxApprovalList({ commit }, stopId) {
    rxItemMobile.getRxApproval(commit, stopId);
  },
  getRxApprovalListForTransition({ commit }, stopId) {
    return new Promise((resolve) => {
      rxApprovalService.getRxApprovalList(stopId).then((response) => {
        const resp = changeQuantityType(response.rx_approval_list);
        commit('setRxApprovalList', resp);
        resolve(response.rx_approval_list);
      });
    });
  },
};
export default {
  namespaced: true,
  state: stateObj,
  getters,
  actions,
  mutations,
};
