//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import NoResultsFound from '../../../helpers/component/no-results-found/no-results-found.vue';

export default {
  name: 'RxItemListCard',
  components: { NoResultsFound, DynamicScroller, DynamicScrollerItem },
  computed: {
    ...mapGetters({
      getRxLists: 'rxApproval/getRxLists',
      getSearchResult: 'rxApproval/getSearchResult',
    }),
  },
  methods: {
    gotoAddRxItem() {
      this.$emit('gotoAddRxItem');
    },
    add(item) {
      this.$emit('add', item);
    },
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
