//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import labelGroupTable from '../../../helpers/component/label-group-table/labelGroupTable.vue';
import confirmDialog from '../../../helpers/component/confirmation-dialog/confirmation.vue';

export default {
  name: 'StopLabel',
  components: {
    labelGroupTable,
    confirmDialog,
  },
  computed: {
    ...mapGetters({
      labelTableData: 'stopLabels/getLabels',
      reprintLabelStatus: 'stopLabels/getLabelReprintStatus',
      labelPrintingComplete: 'stopLabels/getLabelPrintingComplete',
    }),
    labelGroupKeys() {
      return Object.keys(this.labelGroups);
    },
    nonCCIDTrip() {
      return this.$store.getters.getTripDetail.storage_method === 'do_not_use_ccid';
    },
    printIsValid() {
      return this.selectedDocumentsCount > 0 && this.labelPrintingComplete;
    },
    generatorGroupSelected() {
      return this.selectedLabelGroup === this.labelGroups.generatorLabels;
    },
    customerGroupSelected() {
      return this.selectedLabelGroup === this.labelGroups.customerLabels;
    },
  },
  data() {
    return {
      labelTableHeaders: {
        ccid: 'CCID',
        displayedType: 'Type',
        manifest: 'Manifest/BOL',
        line: 'Line',
        quantity: 'Quantity',
      },
      nonCCIDTableHeaders: {
        approval_code: 'Approval',
        displayedType: 'Type',
        manifest: 'Manifest/BOL',
        line: 'Line',
        quantity: 'Quantity',
      },
      generatorLabelTableHeaders: {
        displayedType: 'Type',
        quantity: 'Quantity',
      },
      customerLabelTableHeaders: {
        customer: 'Customer',
        customer_label_type: 'Type',
        quantity: 'Quantity',
      },
      labelGroups: {
        newLabels: 'New labels',
        allLabels: 'All labels',
        generatorLabels: 'Generator labels',
        customerLabels: 'Customer labels',
      },
      selectedLabelGroup: '',
      selectedDocumentsCount: 0,
      showAlertDialog: false,
      confirmModal: false,
      modalData: {
        heading: 'Print labels with no manifest number?',
        description:
          'You have not printed a manifest for this stop. Print your manifest from the Print documents '
          + 'page to enter the manifest tracking number.',
      },
      modalButtons: {
        buttonConfirmText: 'Print selected labels without ID',
        buttonConfirmType: 'btn-primary',
        buttonConfirmVisibility: true,
        buttonCancelText: 'Back',
        buttonCancelVisibility: true,
      },
    };
  },
  created() {
    this.selectedLabelGroup = this.labelGroups.newLabels;
    this.initalizeCustomerLabel();
  },
  methods: {
    selectLabelGroup(labelGroup) {
      this.selectedLabelGroup = labelGroup;
    },
    printSelectedLabels() {
      const tripId = this.$store.getters.getTripDetail.id;
      const stopDetail = this.$store.getters.getStopDetail;
      if (this.printIsValid) {
        this.$store.dispatch('stopLabels/printSelectedLabels', {
          labels: this.getSelectedLabels(),
          tripId,
          stopId: stopDetail.id,
        });
      }
    },
    getSelectedLabels() {
      return this.labelTableData.filter((label) => label.selected);
    },
    closeErrorModal() {
      this.showAlertDialog = false;
    },
    printAnyway() {
      this.showAlertDialog = false;
      this.printSelectedLabels();
    },
    checkIfManifestIsPrinted() {
      let manifestIsPrintedForSelected = true;
      this.getSelectedLabels().forEach((label) => {
        if (manifestIsPrintedForSelected && !label.manifest) {
          manifestIsPrintedForSelected = false;
        }
      });
      if (manifestIsPrintedForSelected || this.selectedLabelGroup !== this.labelGroups.newLabels) {
        this.printSelectedLabels();
      } else {
        this.showAlertDialog = true;
      }
    },
    initalizeCustomerLabel() {
      if (this.$route.query.initializeLabel) {
        this.selectedLabelGroup = this.$route.query.initializeLabel;
        this.$router.push({ query: { } });
      }
    },
  },
  watch: {
    selectedLabelGroup() {
      const stopDetail = this.$store.getters.getStopDetail;
      const stopDetails = {
        stopID: stopDetail.id,
        labelGroup: this.selectedLabelGroup.split(' ')[0].toLowerCase(),
      };
      this.$store.dispatch('stopLabels/setLabelsData', stopDetails);
    },
    labelTableData: {
      deep: true,
      handler(labels) {
        this.selectedDocumentsCount = 0;
        labels.forEach((label) => {
          if (label.selected) {
            this.selectedDocumentsCount += 1;
          }
        });
      },
    },
    '$route.query.initializeLabel': {
      deep: true,
      handler() {
        this.initalizeCustomerLabel();
      },
    },
  },
};
