<template>
  <div>
    <div
      class="mobile-qa-sub-header-blocker"
      @click="goBack()"
    >
      <span> <i class="fas fa-chevron-left" /> &nbsp; Add Rx items</span>
    </div>
    <div class="rx-item-background">
      <v-card
        class="add-rx-search-div"
        outlined
        tile
      >
        <em class="fas fa-search add-rx-search-icon" />
        <input
          type="number"
          class="add-rx-search-input-mbl"
          name="search"
          placeholder="Add an item or NDC code"
          v-model="search"
          @keyup.enter="searchRxApproval($event)"
          autocomplete="off"
        >
      </v-card>
      <rxApprovalListMobile
        class="approval-list-cards"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RxApprovalListMobile from '../rx-item-lists/index-mobile.vue';

export default {
  name: 'MobileAddRxItems',
  components: {
    RxApprovalListMobile,
  },
  computed: {
    ...mapGetters({
      rxItemMainProp: 'rxApproval/getRxItemMainProp',
    }),
  },
  data() {
    return {
      search: this.search || '',
      stopId: '',
    };
  },
  created() {
    this.$store.commit('rxApproval/setSelectedRxItem', {});
    this.stopId = this.$store.state.stopDetail.stopDetail.id;
    this.$store.commit('loadingSearchIcon/loadingRequest', { status: false, loadingName: 'Loading search results' }, { root: true });
    // this.$store.commit('rxApproval/setRxItemListMobile', [], { root: true });
  },
  methods: {
    searchRxApproval() {
      if (this.search) {
        this.$store.dispatch('rxApproval/mobileSearchRxItem',
          { search: this.search, stopId: this.stopId });
        // this.search = '';
      }
    },
    goToApprovalDetails() {
      this.$router.push('/mobile/stop-detail');
    },
    goBack() {
      this.$router.push('/mobile/stop-detail');
    },
  },
  watch: {
    rxItemMainProp: {
      deep: true,
      handler(val) {
        if (val.showApprovalDetails) this.search = '';
      },
    },
  },
};
</script>
