//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import MobileAttachmentsCard from '../../../helpers/component/attachments/mobileAttachmentsCard.vue';
import generateId from '../../../helpers/utils/generate-unique-id';

export default {
  name: 'StopAttachmentMobile',
  components: { MobileAttachmentsCard },
  computed: {
    ...mapGetters({
      attachmentList: 'attachment/getAttachments',
      attachmentMessage: 'attachment/getAttachmentSuccessMessage',
    }),
  },
  data() {
    return {
      submitted: false,
      stopId: this.$store.state.stopDetail.stopDetail.doc_id,
      isTriggerSaveAndExit: false,
    };
  },
  methods: {
    onFileChange(e) {
      const file = e.target.files[0];
      this.resizeImage(file);
    },
    saveAttachment(type) {
      if (type === 'save') {
        this.isTriggerSaveAndExit = false;
      }
      this.$v.attachmentList.$touch();
      if (this.$v.attachmentList.$invalid) {
        return;
      }
      const attachmentsToSave = this.attachmentList.filter((att) => att.from_firestore !== true);
      this.$store.dispatch('attachment/addAttachmentsData', {
        attachmentList: attachmentsToSave,
        stopId: this.stopId,
      });
    },
    goToStopDetails() {
      this.$router.push('/mobile/stop-detail');
    },
    resizeImage(file) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // limit the image to 1280x853 maximum size
      const maxW = 1280;
      const maxH = 853;
      const img = new Image();
      const vm = this;
      img.onload = () => {
        const iw = img.width;
        const ih = img.height;
        const scale = Math.min((maxW / iw), (maxH / ih));
        const iwScaled = iw * scale;
        const ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        const imageUrl = canvas.toDataURL('image/jpeg', 9);
        const attachmentDetail = {
          id: '',
          image_url: imageUrl,
          description: '',
          attachment_type: 'Photo',
          stop_id: vm.stopId,
          isNew: true,
        };
        const attachmentId = generateId();
        attachmentDetail.id = attachmentId;
        vm.$store.commit('attachment/addAttachment', attachmentDetail);
        vm.isTriggerSaveAndExit = true;
      };
      img.src = URL.createObjectURL(file);
    },
  },
  validations: {
    attachmentList: {
      $each: {
        image_url: { required },
        attachment_type: { required },
        description: { minLength: minLength(0), maxLength: maxLength(20) },
        $trackBy: 'id',
      },
    },
  },
  watch: {
    attachmentMessage(newVal) {
      if (newVal) {
        this.$store.commit('attachment/saveAttachmentSuccessCheck', false);
        if (!this.isTriggerSaveAndExit) {
          this.goToStopDetails();
        }
      }
    },
  },
};
