export default {
  methods: {
    closeSelectDropdown(refType) {
      const containerRef = this.$refs[refType];
      if (containerRef.isMenuActive) {
        containerRef.blur();
      } else {
        containerRef.isMenuActive = true;
        containerRef.focus();
      }
    },
    closeSelectDropdownBlur(refType) {
      const containerRef = this.$refs[refType];
      containerRef.blur();
    },
  },
};
