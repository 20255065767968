<template>
  <div class="help-documentation-main-div">
    <p class="help-documentation-title">
      Release Notes
    </p>
    <div class="help-doc-sub-div">
      <iframe
        src="https://sis.help.smartersorting.com/hc/en-us/articles/360045186631-TruckSIS-Release-Notes"
        title="Release notes"
        frameborder="0"
        width="1074"
        height="807"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReleaseNotes',
};
</script>
