<template>
  <div>
    <div class="stop-info-detail-container">
      <div class="nav-btns">
        <v-row
          no-gutters
          class="pb-10 width-100p"
        >
          <v-col
            :cols="dynamicCols"
          >
            <v-card
              class="pa-4 stop-category blr-4"
              outlined
              tile
              :class="stopInfoActive ? 'scaleActive' : 'scaleInactive'"
              @click.prevent="stopDetail('stop-info')"
            >
              {{ stopInfoText }}
            </v-card>
          </v-col>
          <v-col
            :cols="dynamicCols"
          >
            <v-card
              class="pa-4 stop-category"
              outlined
              tile
              :class="{'scaleActive': stopInstructionsActive,
                       'scaleInactive': !stopInstructionsActive,
                       'brl-4': !showPastStopTab
              }"
              @click.prevent="stopDetail('stop-direction')"
            >
              {{ stopInstructionText }}
            </v-card>
          </v-col>
          <v-col
            v-if="showPastStopTab"
            :cols="dynamicCols"
          >
            <v-card
              class="pa-4 stop-category brl-4"
              outlined
              tile
              :class="pastStopActive ? 'scaleActive' : 'scaleInactive'"
              @click.prevent="stopDetail('past-stop')"
            >
              {{ pastInfoText }}
            </v-card>
          </v-col>
        </v-row>
      </div>
      <component
        :is="componentType"
        @openInventoryList="openInventoryList"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import stopInfoContent from './stopInfoContent.vue';
import stopDirectionContent from './stopDirectionContent.vue';
import pastStopContent from './pastStopContent.vue';
import connectedStopConstant from '../../../constants/connected-stop';

export default {
  name: 'StopInformation',
  components: {
    stopInfoContent,
    stopDirectionContent,
    pastStopContent,
  },
  computed: {
    ...mapGetters({ stopDetailChange: 'getStopDetail' }),
    isCompletedStop() {
      return this.stopDetailChange.is_completed;
    },
    showPastStopTab() {
      return this.checkConnectedStopStatus() && !this.isCompletedStop;
    },
  },
  data() {
    return {
      componentType: 'stop-info-content',
      stopInfoActive: true,
      stopInstructionsActive: false,
      pastStopActive: false,
      dynamicCols: '6 text-align',
      stopInfoText: 'Stop Info',
      stopInstructionText: 'Stop Instructions',
      pastInfoText: 'Past Stop',
    };
  },
  created() {
    this.isConnectedStop();
  },
  methods: {
    stopDetail(val) {
      this.resetActiveClass();
      switch (val) {
        case 'stop-info':
          this.componentType = 'stop-info-content';
          this.stopInfoActive = true;
          break;
        case 'stop-direction':
          this.componentType = 'stop-direction-content';
          this.stopInstructionsActive = true;
          break;
        case 'past-stop':
          this.componentType = 'past-stop-content';
          this.pastStopActive = true;
          break;
        default:
          this.componentType = 'stop-info-content';
          this.stopInfoActive = true;
      }
    },
    isConnectedStop() {
      if (this.checkConnectedStopStatus() && !this.isCompletedStop) {
        this.dynamicCols = 'auto text-align';
      }
    },
    resetActiveClass() {
      this.stopInfoActive = false;
      this.stopInstructionsActive = false;
      this.pastStopActive = false;
    },
    openInventoryList() {
      this.$emit('openInventoryList');
    },
    checkConnectedStopStatus() {
      if (this.stopDetailChange.connected_status) {
        if (this.stopDetailChange.connected_status === connectedStopConstant.status.NOT_CONNECTED) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
};
</script>
