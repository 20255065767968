//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VSelectArrow from '../../utils/v-select-arrow';

export default {
  name: 'ContainerSelect',
  data() {
    return {
      selectedID: '',
    };
  },
  extends: VSelectArrow,
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'rxApprovalList/setRxApprovalList') {
        this.selectedID = '';
        if (mutation.payload.length
        && mutation.payload[this.contentIndex]
        && mutation.payload[this.contentIndex].stop_approval
        && mutation.payload[this.contentIndex].stop_approval.stop_containers.length
        && mutation.payload[this.contentIndex].stop_approval.stop_containers[0].container_id) {
          this.selectedID = mutation.payload[this.contentIndex]
            .stop_approval.stop_containers[0].container_id;
        }
      }
    });
    this.selectedID = this.containerId;
  },
  methods: {
    containerTypeChange(event) {
      this.selectedID = event.id;
      this.$store.commit('rxApprovalList/setRxContainerValue', {
        index: this.contentIndex,
        field: 'containerId',
        value: +event.id,
      });
    },
  },

  props: {

    containerList: {
      type: Array,
      default: () => ([]),
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
    containerId: {
      type: Number,
      default: 0,
    },
  },

};
