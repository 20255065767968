<template>
  <div>
    <div
      v-if="emailLists.length === 0"
      class="height120"
    />
    <div
      class="forgotpin-content-col11"
      v-if="emailLists.length>0"
    >
      <div class="col1-header">
        <label>Recent technicians:</label>
      </div>
      <div
        class="last-email-boxes1 tech-email-display"
      >
        <div class="tech-display">
          <div
            class="carousel-containers"
          >
            <template :v-if="emailLists.length>0">
              <lastEmailButton
                v-for="(loggedEmail, index) in emailLists"
                :key="index"
                :email-index="index"
                :email-detail="loggedEmail"
                :selected-email="index === getSelectedTech"
                @selectedEmailChange="selectedEmailChanges"
                @checkChild2Res="checkChild2Res"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import lastEmailButton from '../last-email-button/lastEmailButton.vue';

export default {
  name: 'TechnicianLastEmail',
  components: { lastEmailButton },
  computed: {
    ...mapGetters({ getSelectedTech: 'authentication/getSelectedTechnician' }),
  },
  data() {
    return {
      emailLists: localStorage.getItem('lastEmail') ? JSON.parse(localStorage.getItem('lastEmail')) : [],
      activeIndex: null,
    };
  },
  methods: {
    selectedEmailChanges(input) {
      this.activeIndex = input;
      this.$store.dispatch('authentication/updateRecentTechnician', input);
      this.$router.push('login');
    },
    checkChild2Res(data) {
      this.$emit('checkChild1Res', data);
    },
  },
  props: {
    checkHighlight: {
      default: true,
      type: Boolean,
    },
  },

  watch: {
    checkHighlight(newVal) {
      if (newVal === false) {
        this.activeIndex = null;
      }
    },
  },
};
</script>
