import Vue from 'vue';

Vue.filter('weightUnitFormat', (manifestUnitsList, weightUnitId) => {
  let unitName = '';
  if (manifestUnitsList.length > 0) {
    const unitDetail = manifestUnitsList
      .find((unitData) => (unitData.id === +weightUnitId));
    if (unitDetail) {
      unitName = unitDetail.unit;
    }
  }
  return unitName;
});
