//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import updateCheckService from '../../service/updateCheck.service';

export default {
  name: 'UpdateNotice',
  computed: {
    ...mapGetters({
      versions: 'getSoftwareVersions',
    }),
  },
  methods: {
    gotoLogin() {
      updateCheckService.updateReleaseNotesStatus().then(() => {
        const { query } = this.$route;
        if (query && query.redirect) {
          this.$router.push(query.redirect);
        } else {
          this.$router.push('/bootUp');
        }
      });
    },
  },
};
