//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'StopVoidedWarning',
  computed: {
    ...mapGetters({ stopDetailChange: 'getStopDetail', voidedStop: 'getStopVoidStatus', leaveApproval: 'leaveApproval/getLeaveApprovalDetail' }),
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    goToStopLists() {
      this.resetApprovalBuilder();
      this.$router.push({
        name: 'Stop List',
        params: { tripId: this.stopDetailChange.trip_id.toString() },
      });
    },
    resetApprovalBuilder() {
      const currentRoute = this.$route.name;
      this.$store.dispatch('rxApproval/resetAllContentData');
      if (currentRoute === 'Consolidate' || currentRoute === 'RxConsolidate') {
        this.resetConsolidate();
      }
      if (currentRoute === 'Add Rx Items') {
        this.$store.commit('rxApproval/resetApprovalDetail');
      }
      this.$store.commit('resetApprovalDetail');
      this.$store.commit('resetScaleWeight');
      this.$store.commit('resetScaleWeightUnit');
      this.$store.commit('leaveApproval/resetLeaveApprovalDialog');
    },
    resetConsolidate() {
      const consolidateDetail = this.$store.state.approvalList.consolidateApproval;
      if (consolidateDetail && consolidateDetail.id) {
        const options = { id: consolidateDetail.id, stopId: consolidateDetail.stop_id };
        this.$store.dispatch('consolidate/removeConsolidationSavedData', options);
        this.$store.commit('resetConsolidateApproval');
        this.$store.commit('consolidate/resetConsolidation');
      }
      this.$store.commit('rxApproval/resetApprovalDetail');
      this.$store.commit('rxContent/resetContent');
    },
  },
  watch: {
    voidedStop() {
      this.dialog = this.voidedStop;
    },
  },
};
