<template>
  <v-col
    cols="12"
    lg="4"
  >
    <label class="add-rx-items-details-sub-title mb-1 d-block">
      Ounces
    </label>
    <div>
      <v-row class="rx-items-row-margin">
        <v-col
          cols="12"
          lg="4"
          class="rx-item-border-1px rx-items-controls-symbols"
        >
          <button
            class="width-100p"
            @click="decreaseWeightOunces() || !rxApprovalData.id"
            :disabled="weightOunces == 0"
          >
            <img src="src/assets/images/minus-outline-icon.svg">
          </button>
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="rx-item-border-1px rx-items-controls-values"
        >
          <input
            type="text"
            v-model="weightOunces"
            class="rx-items-controls-input"
            name="quantity"
            maxlength="4"
            readonly
            @click="checkDialog('numeric','weightOunces','Ounces',
                                contentIndex, weightOunces.toString())"
            @selectstart.prevent
            @paste.prevent
            @copy.prevent
            @cut.prevent
            @drag.prevent
            @drop.prevent
            v-unSelect
          >
        </v-col>
        <v-col
          cols="12"
          lg="4"
          class="rx-item-border-1px rx-items-controls-symbols"
        >
          <button
            class="width-100p"
            @click="increaseWeightOunces()"
            :disabled="!rxApprovalData.id"
          >
            <img
              src="src/assets/images/plus-outline-icon.svg"
              class="margin-top-10"
            >
          </button>
        </v-col>
      </v-row>
    </div>
    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="modelValue"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @enterClicked="enterClicked"
    />
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import KeyboardDialog from '../virtual-keyboard/virtualKeyboardDialog.vue';

export default {
  name: 'WeightOunces',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({
      rxApprovalData: 'rxApproval/getRxApprovalDetail',
    }),
  },
  data() {
    return {
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
    };
  },
  methods: {
    increaseWeightOunces() {
      this.$store.commit('rxContent/increaseContent', {
        index: this.contentIndex,
        field: 'weightOunces',
      });
    },
    decreaseWeightOunces() {
      this.$store.commit('rxContent/decreaseContent', {
        index: this.contentIndex,
        field: 'weightOunces',
      });
    },
    checkDialog(KeyboardType, modelName, labelName, index, modelValue) {
      if (this.rxApprovalData.id) {
        this.pinDialogIsOpen = true;
        this.keyBoardType = KeyboardType;
        this.modelName = modelName;
        this.labelName = labelName;
        this.keypadIndex = index;
        this.modelValue = modelValue;
      }
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      if (value.modelName === 'search') {
        this.search = value.input;
      } else {
        const model = value.modelName;
        this.$store.commit('rxContent/setContentValue', {
          index: this.contentIndex,
          field: model,
          value: Number.isNaN(+value.input) ? 0 : +value.input,
        });
      }
    },

  },
  props: {

    weightOunces: {
      type: Number,
      default: 0,
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
  },
};
</script>
