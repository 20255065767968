//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import MobileTripCard from '../../../helpers/component/trip-card/tripCard-mobile.vue';

export default {
  name: 'MobileTripList',
  components: { MobileTripCard },
  computed: {
    ...mapGetters({ tripList: 'trip/getTripList' }),
  },
  created() {
    this.$store.commit('trip/setFirstLogin', false);
    this.$store.dispatch('trip/mobileTripListAction');
  },

};
