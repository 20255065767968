
<template>
  <div class="font-face-source-opnsans">
    <v-row
      class="width-pc-100 width-100p"
      no-gutters
    >
      <v-col class="col-md-3 col-xl-3 px-6 card-shadow stop-map-vh card-detail-bg">
        <trip-map />
      </v-col>
      <v-col
        class="col-md-8 col-xl-9 pl-4 stop-list-top"
        v-if="!showStopDetailClicked"
      >
        <div class="d-flex justify-space-between">
          <v-row
            no-gutters
            class="pb-10 complete-ic-w p-relative flex-3"
          >
            <v-col
              cols="12 text-align"
              sm="4"
            >
              <v-card
                class="pa-4 stop-category border1-r-4"
                outlined
                tile
                :class="!showType ? 'scaleActive' : 'scaleInactive'"
                @click.prevent="stopsFilter(false)"
              >
                Incomplete stops
              </v-card>
            </v-col>
            <v-col
              cols="12 text-align"
              sm="4"
            >
              <v-card
                class="pa-4 stop-category border2-r-4"
                outlined
                tile
                @click.prevent="stopsFilter(true)"
                :class="!showType ? 'scaleInactive' : 'scaleActive'"
              >
                Completed stops
              </v-card>
            </v-col>
          </v-row>
          <div class="flex-1 p-relative stop-detail-btn-container">
            <button
              class="stop-detail-btn"
              @click.prevent="showStopDetail()"
              :disabled="!getStopListsArray.length"
            >
              View all stop details
            </button>
          </div>
          <div class="flex-1 p-relative stop-detail-btn-container">
            <button
              class="stop-detail-btn"
              @click="goToViewAllCcid()"
              :disabled="!getStopListsArray.length"
            >
              View trip inventory
            </button>
          </div>
        </div>
        <template v-if="getStopLists.length">
          <div class="p-relative">
            <stop-card
              v-for="(stop, index) in getStopLists"
              :key="index"
              :stop="stop"
              :trip="getTripDetail"
            />
          </div>
        </template>
      </v-col>
      <v-col
        v-else
        class="col-md-8 col-xl-9 stop-summary-section"
      >
        <trip-summary-component
          @closeStopDetail="closeStopDetail()"
          :trip-id="tripId"
        />
      </v-col>
    </v-row>
    <active-stop-warning />
    <begin-stop-instruction
      @openStorageModel="openStorageModel"
    />
    <storage-method-dialog
      :show-storage-method-dialog="showStorageMethodDialog"
      :stop-to-activate="stopToActivate"
      @cancelStorageDialog="cancelStorageDialog"
    />
    <active-stops-for-trip />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import stopCard from './tripStopCard.vue';
import tripMap from './tripStopMap.vue';
import activeStopWarning from '../../helpers/component/active-stop-warning-dialog/activeStopWarningDialog.vue';
import beginStopInstruction from '../../helpers/component/begin-stop-instruction-dialog/beginStopInstructionDialog.vue';
import TripSummaryComponent from '../../helpers/component/trip-summary/trip-summary.vue';
import StorageMethodDialog from '../../helpers/component/storage-method-dialog/storageMethodDialog.vue';
import ActiveStopsForTrip from './stop-details/activeStopsForTrip.vue';

export default {
  name: 'StopLists',
  components: {
    stopCard,
    tripMap,
    activeStopWarning,
    beginStopInstruction,
    TripSummaryComponent,
    StorageMethodDialog,
    ActiveStopsForTrip,
  },
  computed: {
    ...mapGetters(['getStopLists', 'getStopListsArray', 'getTripDetail']),
    ...mapGetters({ voidedStopsInTrip: 'getVoidedStopsInTrip' }),
  },
  data() {
    return {
      stopLists: [],
      activeStop: {},
      showType: false,
      showStopDetailClicked: false,
      showStorageMethodDialog: false,
      stopToActivate: null,
      voidedStopsCheckInterval: null,
    };
  },
  created() {
    this.getStopDetails();
    this.setFirstLogin();
    this.stopsFilter(false);
    this.goToStopDetail();
    this.getVoidedStopIds();
  },
  beforeDestroy() {
    clearInterval(this.voidedStopsCheckInterval);
  },
  methods: {
    getStopDetails() {
      this.$store.dispatch('getStopDetailsAction', this.tripId).then((res) => {
        if (res && res.stops && res.stops.length > 0) {
          this.stopLists = res.stops;
        }
      });
    },
    stopsFilter(type) {
      this.showType = type;
      this.$store.dispatch('setStopTypesAction', type);
    },
    setFirstLogin() {
      this.$store.commit('trip/setFirstLogin', false);
    },
    goToStopDetail() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'setCurrentStopActive') {
          if (state.stopDetail.currentStopActive
          || state.stopDetail.stopDetail.is_completed) {
            this.$router.push(`/stops/detail/${state.stopDetail.stopDetail.id}`);
          }
        }
        if (mutation.type === 'setStopArrive') {
          if (state.stopDetail.stopArrived) {
            this.$store.dispatch('showBeginStopInstruction', {
              status: false,
              stopId: state.stopDetail.stopDetail.id,
            });
            this.$router.push(`/stops/detail/${state.stopDetail.stopDetail.id}`);
          }
        }
      });
    },
    openStorageModel(stopId) {
      this.stopToActivate = stopId;
      this.showStorageMethodDialog = true;
    },
    cancelStorageDialog() {
      this.showStorageMethodDialog = false;
    },
    showStopDetail() {
      this.showStopDetailClicked = true;
      const payload = {
        preview: true,
        tripId: this.tripId,
      };
      this.$store.dispatch('trip/printTripsDetail', payload);
    },
    closeStopDetail() {
      this.showStopDetailClicked = false;
    },
    goToViewAllCcid() {
      this.$router.push(`/view-all-ccid/${this.tripId}`);
    },
    getVoidedStopIds() {
      this.voidedStopsCheckInterval = setInterval(() => {
        this.$store.dispatch('setVoidedStopsInTrip', this.tripId);
      }, 5000);
    },
    getTripStopData() {
      this.$store.dispatch('getStopDetailsAction', this.tripId);
    },
  },
  props: {
    tripId: {
      type: String,
      required: true,
    },
  },
  watch: {
    $route: 'getStopDetails',
    voidedStopsInTrip() {
      this.getTripStopData();
    },
  },
};
</script>
