function setDefaultData() {
  return {
    showApprovalDialog: false,
    pageType: '',
    fromUrl: '',
    redirectUrl: '',
    triggerDialog: '',
  };
}
const state = {
  leaveApproval: setDefaultData(),
  modalType: '',
};

const getters = {
  getLeaveApprovalDetail(stateControl) {
    return stateControl.leaveApproval;
  },
  getModalType(stateControl) {
    return stateControl.modalType;
  },

};

const mutations = {
  setLeaveApprovalDialog(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.leaveApproval.showApprovalDialog = payload.isLeaveApprovalDialog;
    stateControld.leaveApproval.pageType = payload.pageType;
    stateControld.leaveApproval.fromUrl = payload.fromUrl;
    stateControld.leaveApproval.redirectUrl = payload.redirectUrl;
    stateControld.leaveApproval.triggerDialog = payload.triggerDialog;
  },
  resetLeaveApprovalDialog(stateControl) {
    const stateControld = stateControl;
    stateControld.leaveApproval = setDefaultData();
  },
  setModalType(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.modalType = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
