<template>
  <div class="notfound-wrapper">
    <img
      src="src/assets/images/not-found.svg"
      alt="not-found"
      class="notfound-image mb-2 mt-8"
    >
    <h1 class="notfound-result-title mb-2">
      We’re sorry.
    </h1>
    <p class="notfound-result-description mb-4">
      We tried, but we can’t seem to find any results that match
      <span class="font-weight-bold">{ {{ getUserValue }} }</span>.
    </p>
    <p class="notfound-result-description">
      Try your search again or clear your search to view all items in this list.
    </p>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NoResultsFound',
  computed: {
    ...mapGetters({ getUserValue: 'rxApproval/getSearchValue' }),
  },
};
</script>
