//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import tripMap from './tripStopMap.vue';
import viewAllCCIDCard from './stop-details/viewAllCCIDCard.vue';

export default {
  name: 'ViewAllCCID',
  components: {
    tripMap,
    viewAllCCIDCard,
  },
  computed: {
    ...mapGetters(['getStopLists', 'getStopListsArray']),
    ...mapGetters({
      getAllCcidList: 'trip/getAllCcidList',
    }),
  },
  data() {
    return {
      stopLists: [],
    };
  },
  created() {
    this.getStopDetails();
    this.getAllCCID();
  },
  methods: {
    getStopDetails() {
      this.$store.dispatch('getStopDetailsAction', this.tripId).then((res) => {
        if (res && res.stops && res.stops.length > 0) {
          this.stopLists = res.stops;
        }
      });
    },
    getAllCCID() {
      this.$store.dispatch('trip/viewAllCcid', this.tripId);
    },
    gotoStopPage() {
      this.$router.go(-1);
    },
  },
  props: {
    tripId: {
      type: String,
      required: true,
    },
  },
};
