<template>
  <v-col
    cols="12"
    lg="7"
    class="pb-0px"
  >
    <label class="add-rx-items-details-sub-title mb-1 d-block">
      Container type
    </label>
    <v-select
      ref="containerList"
      v-model="selectedID"
      :items="containerList"
      @change="containerTypeChange"
      item-value="id"
      item-text="concat_desc"
      return-object
      outlined
      append-icon="fa-angle-down"
      :menu-props="{ offsetY: true }"
      background-color="white"
      placeholder="Please select..."
      class="m-t-1"
      @click:append="closeSelectDropdown('containerList')"
    />
  </v-col>
</template>
<script>
import VSelectArrow from '../../utils/v-select-arrow';

export default {
  name: 'ContainerSelect',
  data() {
    return {
      selectedID: '',
    };
  },
  extends: VSelectArrow,
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'rxApprovalList/setRxApprovalList') {
        this.selectedID = '';
        if (mutation.payload.length
        && mutation.payload[this.contentIndex]
        && mutation.payload[this.contentIndex].stop_approval
        && mutation.payload[this.contentIndex].stop_approval.stop_containers.length
        && mutation.payload[this.contentIndex].stop_approval.stop_containers[0].container_id) {
          this.selectedID = mutation.payload[this.contentIndex]
            .stop_approval.stop_containers[0].container_id;
        }
      }
    });
    this.selectedID = this.containerId;
  },
  methods: {
    containerTypeChange(event) {
      this.selectedID = event.id;
      this.$store.commit('rxApprovalList/setRxContainerValue', {
        index: this.contentIndex,
        field: 'containerId',
        value: +event.id,
      });
    },
  },

  props: {

    containerList: {
      type: Array,
      default: () => ([]),
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
    containerId: {
      type: Number,
      default: 0,
    },
  },

};
</script>
