//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email, numeric } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import pinDialog from '../../helpers/component/pin-dialog/pinDialog.vue';
import technicianLastEmail from '../../helpers/component/technician-last-email/technicianLastEmail.vue';

export default {
  name: 'ConfirmAccount',
  components: {
    technicianLastEmail,
    pinDialog,
  },
  computed: {
    ...mapGetters({
      getEmail: 'createAccount/getEmail',
    }),
    ...mapGetters({ loginStatus: 'authentication/getloginStatus' }),
  },
  data() {
    return {
      form: {
        firstname: '',
        lastname: '',
        email: '',
        phone1: '',
        phone2: '',
        phone3: '',
        error: '',
      },
      userExist: false,
      pinDialogIsOpen: false,
      resendEmailSend: false,
    };
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        this.form.error = true;
      } else {
        this.$store.dispatch('createAccount/createAccountAction', {
          firstname: this.form.firstname,
          lastname: this.form.lastname,
          email: this.form.email,
          phonenumber: this.form.phone1 + this.form.phone2 + this.form.phone3,
        });
      }
    },
    goToBackPage() {
      this.$router.go(-1);
    },
    enterNewPin() {
      this.pinDialogIsOpen = true;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    resendEmail() {
      this.$store.dispatch('createAccount/resendEmailAction', {
        email: this.getEmail,
      }).then((res) => {
        if (res === 'resendEmailSuccess') {
          this.resendEmailSend = true;
        }
      });
    },
  },
  validations: {
    form: {
      firstname: { required },
      lastname: { required },
      email: { required, email },
      phone1: { required, numeric },
      phone2: { required, numeric },
      phone3: { required, numeric },
    },
  },
  watch: {
    loginStatus(newVal) {
      if (newVal === true) {
        this.$router.push('logged-in-home');
      }
    },
  },
};
