//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'AlertItem',
  computed: {
    ...mapGetters([
      'getStopDetail',
    ]),
  },
  methods: {
    scanAlert() {
      this.$emit('scanAlert', this.alert.cloud_id);
    },
    imageUrl() {
      if (this.alert.image.length > 0) {
        if (this.alert.image[0].image && this.alert.image[0].image.image) {
          return this.alert.image[0].image.image.url;
        }
      }
      return '';
    },
  },
  props: {
    alert: {
      type: Object,
      default() {
        return { };
      },
    },
  },
};
