import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';
import pollingInterval from '../constants/pollingInterval';
import store from '../store/index';

let fetchInterval;

function getAlertsFromApi(activeStopId) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = `${API_BASE_URL}alerts/${activeStopId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function startAlertsUpdate(activeStopId) {
  if (store.state.masterScan.scanningEnabled) {
    getAlertsFromApi(activeStopId).then((res) => {
      store.dispatch('alerts/setAlertsData', res);
    });
    if (fetchInterval) {
      clearInterval(fetchInterval);
    }
    fetchInterval = setInterval(() => {
      getAlertsFromApi(activeStopId).then((res) => {
        store.dispatch('alerts/setAlertsData', res);
      });
    }, pollingInterval.alertPollingInterval);
  }
}
function stopAlertsUpdate() {
  clearInterval(fetchInterval);
}
function updateAlertState(alertId) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = `${API_BASE_URL}alerts/${alertId}/update`;
  return fetchService.getFetchData(url, requestOptions);
}
function updateAlert(alertId, activeStopId) {
  updateAlertState(alertId).then(() => {
    startAlertsUpdate(activeStopId);
  });
}
const alertsService = {
  getAlertsFromApi,
  startAlertsUpdate,
  stopAlertsUpdate,
  updateAlertState,
  updateAlert,
};
export default alertsService;
