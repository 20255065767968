<template>
  <div class="main-header">
    <UnAuthHeader />
    <div
      class="bg-image"
    >
      <div class="auth-header-bg-image">
        <div class="auth-header-content-box">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnAuthHeader from './header.vue';

export default {
  name: 'AuthHeader',
  components: {
    UnAuthHeader,
  },
};
</script>
