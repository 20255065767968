function getAPIURL() {
  if (process.env.NODE_ENV === 'live') {
    return 'http://192.168.1.191:8080/api/';
  }
  if (process.env.NODE_ENV === 'staging') {
    return 'http://192.168.1.191:8080/api/';
  }
  if (process.env.NODE_ENV === 'mock') {
    return 'http://localhost:3000/api/';
  }
  if (process.env.NODE_ENV.includes('handheld')) {
    return 'http://firestore/api/';
  }
  return 'http://0.0.0.0:8080/api/';
}

function getFirestoreCredentials() {
  if (process.env.NODE_ENV === 'handheld-prod') {
    return {
      apiKey: 'AIzaSyBd0SOmzc877-e9SUFpklAIsIS5_HMWsNY',
      authDomain: 'sis-platform-258221.firebaseapp.com',
      databaseURL: 'https://sis-platform-258221.firebaseio.com',
      projectId: 'sis-platform-258221',
      storageBucket: 'sis-platform-258221.appspot.com',
      messagingSenderId: '427826075992',
      appId: '1:427826075992:web:9b77dc15e112670f6bad2d',
      measurementId: 'G-3Q3TEFYTR2',
    };
  }
  return {
    apiKey: 'AIzaSyAcJjY40dJKyGaJwGkR1xGReikVvEs3DAw',
    authDomain: 'sis-platform-staging.firebaseapp.com',
    databaseURL: 'https://sis-platform-staging.firebaseio.com',
    projectId: 'sis-platform-staging',
    storageBucket: 'sis-platform-staging.appspot.com',
    messagingSenderId: '245216858918',
    appId: '1:245216858918:web:88847fcdc4aa514741e7e7',
  };
}

function getSSCloudURL() {
  if (process.env.NODE_ENV === 'handheld-prod') {
    return 'https://ss-cloud.production.sorting.tech/api/v2/mobile_sis';
  }
  return 'https://ss-cloud.staging.sorting.tech/api/v2/mobile_sis';
}

const API_BASE_URL = getAPIURL();
const FIRESTORE_CREDENTIALS = getFirestoreCredentials();
const SS_CLOUD_URL = getSSCloudURL();
const USERNAME = 'account_setup_device';
const PASSWORD = 'account_setup_device';

module.exports = {
  API_BASE_URL,
  FIRESTORE_CREDENTIALS,
  SS_CLOUD_URL,
  USERNAME,
  PASSWORD,
};
