<template>
  <div class="expected-inventory-container">
    <div v-if="isAccumulationPickupData || mostOccurringPickupList.length > 0">
      <v-container
        class="expected-pickup-inventory-container"
        v-show="isPriorPickupData"
      >
        <div class="d-flex justify-center align-center info-container">
          <div class="icon-container">
            <img
              class="bulb-img"
              src="src/assets/images/bulb.svg"
              alt="bulb"
            >
          </div>
          <div
            class="label-container"
          >
            <div>
              The most common waste profiles for {{ customerName }}
              {{ stopTypeStingValue }} are listed below.
            </div>
            <div>To see all waste profiles, select <span>Add approval</span> above.</div>
          </div>
        </div>
      </v-container>

      <div
        class="d-flex justify-start"
        v-if="isAccumulationPickupData && isSuccessfulResponse"
      >
        <v-container
          class="resizable-container"
          id="resizableContainer"
          @click.stop="expandContainer"
        >
          <div class="d-flex justify-center  info-container">
            <div class="icon-container">
              <img
                class="bulb-img2"
                src="src/assets/images/bulb.svg"
                alt="bulb"
              >
            </div>
            <div
              id="container-Content"
              class="label-container d-flex align-center"
            >
              <div class="align-self-left">
                <div class="resizable-container-content">
                  {{ content1 }}
                </div>
                <div class="resizable-container-content">
                  {{ content2 }}
                </div>
                <div
                  class="note-content"
                  v-show="expandInfo && showNotes"
                >
                  <div class="resizable-container-content ">
                    {{ noteContent }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-container>
        <img
          v-show="expandInfo"
          src="src/assets/images/close.svg"
          alt="close-icon"
          class="cross-icon"
          @click.stop="reduceContainer"
        >
      </div>

      <div v-if="isPriorPickupData">
        <expected-pickup-inventory-card
          v-for="(profile, index) in mostOccurringPickupList"
          :key="index"
          :profile="profile"
        />
      </div>
      <div v-if="isAccumulationPickupData">
        <div
          v-if="isSuccessfulResponse"
          class="accumulation-data-container"
        >
          <accumulation-expected-container
            v-for="(container, index) in accumulationContainerList"
            :key="index"
            :container="container"
            :class="{'container-detail-active': container.container_id === activeContainerId}"
            :customer-name="customerName"
            @toggleContainer="openContainer($event.id)"
          />
        </div>
        <div v-else>
          <div
            v-if="apiStatus===apiResponseStatus.FAILED"
            class="d-flex justify-center align-center flex-column fetch-error"
          >
            <div class="header-label">
              We’re having some trouble fetching the stop data.
            </div>
            <div class="description-label">
              We’ll keep trying, but you may go ahead and adding your approvals. We’ll show the stop data once it’s available.
            </div>
          </div>
          <div
            v-else
            class="d-flex justify-center align-center flex-column loading-container"
          >
            <img
              src="src/assets/images/loader.png"
              alt="loading-icon"
              class="loading-spinner"
            >
            <div class="loading-label">
              Syncing pickup data from RetailSiS…
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="no-expected-pickup-data"
      v-else
    >
      Expected pickup data is not available at this time.<br>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import expectedPickupInventoryCard from '../expected-pickup-inventory-card/expectedPickupInventoryCard.vue';
import connectedStopConstant from '../../../constants/connected-stop';
import customerTypesConstant from '../../../constants/customer-type';
import accumulationExpectedContainer from '../accumulation-expected-container/accumulationExpectedContainer.vue';
import apiResponse from '../../../constants/apiResponseStatus';

export default {
  name: 'ExpectedPickupInventory',
  components: {
    expectedPickupInventoryCard,
    accumulationExpectedContainer,
  },
  computed: {
    ...mapGetters({
      mostOccurringPickupList: 'getMostOccurringProfiles',
      accumulationContainerList: 'getAccumulationContainerList',
      stopDetail: 'getStopDetail',
      apiStatus: 'getAccumulationContainerApiCallStatus',
    }),
    customerName() {
      return this.stopDetail.customer.name;
    },
    stopTypeStingValue() {
      return this.stopDetail.stop_type.join(', ');
    },
    isPriorPickupData() {
      return this.stopDetail.connected_status === connectedStopConstant.status.PRIOR_DATA_PRESENT;
    },
    isAccumulationPickupData() {
      return this.stopDetail.connected_status === connectedStopConstant
        .status.ACCUMULATION_DATA_PRESENT;
    },
    showNotes() {
      return this.stopDetail.customer.name === customerTypesConstant.customerTypes.is_costco;
    },
    isSuccessfulResponse() {
      return this.apiStatus === apiResponse.status.SUCCESS;
    },
  },
  data() {
    return {
      activeContainerId: null,
      expandInfo: true,
      content1: '',
      content2: '',
      noteContent: '',
      apiResponseStatus: apiResponse.status,
    };
  },
  mounted() {
    this.checkConnectedStatus();
    this.contentReset(true);
  },
  methods: {
    checkConnectedStatus() {
      if (this.isPriorPickupData) {
        this.$store.dispatch('getMostOccurringProfiles', this.$store.state.stopDetail.stopDetail.id);
      } else if (this.isAccumulationPickupData) {
        this.$store.dispatch('getAccumulationContainerList', this.stopDetail.id);
      }
    },
    openContainer(id) {
      this.activeContainerId = id;
      // this.reduceContainer();
    },
    contentReset(resetFlag) {
      if (resetFlag) {
        this.content1 = 'This generator has logged all of their items in every container with the RetailSiS. Select a';
        this.content2 = 'container below to view and add the possible approvals based on the items you’re picking up.';
        this.noteContent = 'Pharmacy/Rx items are not included in this expected weight or profiles listed below.';
      } else {
        this.content1 = 'Information';
        this.content2 = '';
        this.noteContent = '';
      }
    },
    reduceContainer() {
      this.contentReset(false);
      this.expandInfo = false;
      const element = document.getElementById('resizableContainer');
      element.classList.add('resizable-container-active');
    },
    expandContainer() {
      this.contentReset(true);
      this.expandInfo = true;
      const element = document.getElementById('resizableContainer');
      element.classList.remove('resizable-container-active');
    },
  },
};
</script>
