<template>
  <div class="stop-infos">
    <div class="cols d-flex flex-column mt-6">
      <div class="col-header">
        <label>Name</label>
      </div>
      <div class="val mt-1">
        <label>{{ getStopDetail.generator.name }}</label>
      </div>
    </div>
    <div class="cols d-flex flex-column mt-6">
      <div class="col-header">
        <label>Notes</label>
      </div>
      <div
        class="site-directions mt-1"
        :class="{'none': !getStopDetail.comments}"
      >
        {{ getStopDetail.comments ? getStopDetail.comments : 'none' }}
      </div>
    </div>
    <div class="cols d-flex flex-column mt-6">
      <div class="col-header">
        <label>Directions</label>
      </div>
      <div
        class="mt-1 site-directions"
        :class="{'none': !getStopDetail.site_directions}"
      >
        {{ getStopDetail.site_directions ? getStopDetail.site_directions : 'none' }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StopDirectionContent',
  computed: {
    ...mapGetters(['getStopDetail']),
  },
};
</script>
