<template>
  <tr
    v-if="expectedData"
    class="expected-table-data"
  >
    <td class="d-flex flex-column justify-center align-center item-data">
      <span>{{ expectedData.percentage }}</span>
      of stops
    </td>
    <td class="profile-item-data">
      {{ expectedData.profile }}
    </td>
  </tr>
</template>

<script>
export default {
  name: 'ExpectedPickupItem',
  props: {
    expectedData: {
      type: Object,
      default() {
        return { };
      },
    },
  },
};
</script>
