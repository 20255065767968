<template>
  <div>
    <v-overlay :value="dialog">
      <div class="manifest-number-warn-container">
        <div class="d-flex flex-column align-center justify-center manifest-number-warn-box">
          <div class="d-flex justify-center align-center fs-3em m28">
            <i class="fas fa-exclamation-triangle" />
          </div>
          <div class="manifest-number-warn-info">
            <label>
              Manifest number {{ manifestNumber }} Replace has been used on a different stop
            </label>
          </div>
          <div class="manifest-number-warn-info-child">
            <label>
              Each manifest number must be unique for every stop. Please double check the
              manifest number you entered is correct, or use a different manifest.
            </label>
          </div>
          <div class="btn-group-manifest-number-warn mt-10">
            <button
              @click="gotIt"
              class="btn-submit"
            >
              Got it
            </button>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: 'UniqueManifestNumberWarning',
  data() {
    return {
      dialog: false,
      manifestNumber: '',
    };
  },
  created() {
    this.checkStatusWarningDialog();
  },
  methods: {
    checkStatusWarningDialog() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'stopDocuments/setIsUniqueNumber') {
          this.dialog = !mutation.payload.isUnique;
          this.manifestNumber = mutation.payload.manifestNumber;
        }
      });
    },
    gotIt() {
      this.dialog = false;
    },
  },
};
</script>
