<template>
  <div
    class="box1"
    :class="selectedEmail === true ? 'last-email-active' : 'last-email-deactive'"
    @click.prevent="activeteEmail(emailDetail)"
  >
    <label class="cursor-pointer">{{ emailDetail.first_name }} {{ emailDetail.last_name }}</label>
  </div>
</template>
<script>
export default {
  name: 'LastEmailButton',

  methods: {
    activeteEmail(techDetail) {
      const local = {
        name: `${techDetail.first_name} ${techDetail.last_name}`,
        email: techDetail.email,
      };
      localStorage.setItem('selectedRecentTechnician', JSON.stringify(local));
      this.$emit('selectedEmailChange', this.emailIndex);
      this.$emit('checkChild2Res', true);
      this.$store.dispatch('checkSelectedTech', true);
    },

  },

  props: {

    emailIndex: {
      type: Number,
      default: 0,
    },

    selectedEmail: {
      type: Boolean,
      default: false,
    },

    emailDetail: {
      type: Object,
      default: () => ({
        email: '',
      }),
    },
  },

};
</script>
