//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KeyboardDialog from '../virtual-keyboard/virtualKeyboardDialog.vue';

export default {
  name: 'BillUnitList',
  components: {
    KeyboardDialog,
  },
  computed: {
    nonLbsBillUnits() {
      return this.billUnitList.filter((bu) => bu.unit !== 'LBS');
    },
  },
  data: () => ({
    billUnitList: [],
    input: '',
    pinDialogIsOpen: false,
    keyBoardType: '',
    modelName: '',
    labelName: '',
    maxLength: '2',
    modelValue: '',
    keyboardIndex: null,
  }),
  created() {
    if (this.rxItem) {
      this.billUnitList = this.$store.state.rxContent.contentList[
        this.contentIndex
      ].billUnits;
    } else if (this.edit) {
      this.billUnitList = this.$store.state.inventoryApproval.selectedInventoryApproval.bill_units;
    } else {
      this.billUnitList = this.$store.state.approvalList.approvalDetail.bill_units;
    }
  },
  methods: {
    increaseQuantity(billUnit) {
      if (billUnit.quantity < 99) {
        const index = this.getBillUnitIndex(billUnit);
        this.billUnitList.splice(index, 1, { ...billUnit });
        if (this.rxItem) {
          const indexVal = {
            index,
            contentIndex: this.contentIndex,
          };
          this.$store.commit('rxContent/setIncBillUnitQuantity', indexVal);
        } else if (this.edit) {
          this.$store.dispatch('increaseInventoryBillUnitQuantity', index);
        } else {
          this.$store.dispatch('increaseBillUnitQuantity', index);
        }
      }
    },
    decreaseQuantity(billUnit) {
      if (billUnit.quantity > 0) {
        const index = this.getBillUnitIndex(billUnit);
        this.billUnitList.splice(index, 1, { ...billUnit });
        if (this.rxItem) {
          const indexVal = {
            index,
            contentIndex: this.contentIndex,
          };
          this.$store.commit('rxContent/setDecBillUnitQuantity', indexVal);
        } else if (this.edit) {
          this.$store.dispatch('decreaseInventoryBillUnitQuantity', index);
        } else {
          this.$store.dispatch('decreaseBillUnitQuantity', index);
        }
      }
    },
    getBillUnitIndex(billUnit) {
      return this.billUnitList.findIndex((bu) => bu.unit === billUnit.unit);
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue, billUnit) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
      this.keyboardIndex = this.getBillUnitIndex(billUnit);
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      if (value.input > 0 && value.input < 99) {
        const payload = {
          index: this.keyboardIndex,
          value: value.input,
        };
        if (this.rxItem) {
          const indexVal = {
            contentIndex: this.contentIndex,
            ...payload,
          };
          this.$store.commit('rxContent/setRxBillUnitQuantity', indexVal);
        } else if (this.edit) {
          this.$store.dispatch('setInventoryBillUnitQuantity', payload);
        } else {
          this.$store.dispatch('setBillUnitQuantity', payload);
        }
      }
    },
  },
  props: {
    edit: {
      type: Boolean,
    },
    rxItem: {
      default: false,
      type: Boolean,
    },
    contentIndex: {
      default: -1,
      type: Number,
    },
  },
  watch: {
    nonLbsBillUnits: {
      deep: true,
      handler(val) {
        const totalQuantity = val.reduce((sum, bu) => sum + bu.quantity, 0);
        this.$emit('update:totalQuantity', totalQuantity);
      },
    },
  },
};
