import stopService from '../service/stops.service';
import toastMessage from './toast-message';
import fireStoreAttachmentService from '../service/firestore/stop-attachment.service';
import documentConstants from '../constants/document';

const documentTypes = documentConstants.types;

const stateObj = {
  attachments: [],
  currentAttachmentPdfUrl: '',
  attachmentSuccessMessage: '',
  saveAttachmentSuccessCheck: false,
  attachmentWithPrePopulatedData: [],
  printedDocumentList: [],
  selectedPrePopulatedAttachment: {},
  mappedPrePopulateDataWithAttachment: [],
  currentAttachmentType: '',
};

function findMappedAttachment(state, document) {
  const result = state.mappedPrePopulateDataWithAttachment
    .find((mapData) => mapData.documentIdentifier === document.documentIdentifier);
  if (result) {
    return result.attachmentId;
  }
  return null;
}

function isAttachmentInMappedData(state, attachmentId) {
  const result = state.mappedPrePopulateDataWithAttachment
    .find((mappedData) => mappedData.attachmentId === attachmentId);
  return result;
}

function isAttachmentInPrintDoc(state, manifestName, attachmentType) {
  if (manifestName) {
    const manifestDocPresent = state.printedDocumentList.find((doc) => doc.name === manifestName
      && doc.type === attachmentType);
    return manifestDocPresent;
  }
  return null;
}

function removePreviouslyPrintedDoc(state, payload) {
  const mappedIndex = state.mappedPrePopulateDataWithAttachment
    .findIndex((mapData) => mapData.documentIdentifier === payload.documentIdentifier);
  if (mappedIndex > -1) {
    state.mappedPrePopulateDataWithAttachment.splice(mappedIndex, 1);
  }
  const previousDocIndex = state.printedDocumentList
    .findIndex((doc) => doc === payload);
  if (previousDocIndex > -1) {
    state.printedDocumentList.splice(previousDocIndex, 1);
  }
}

const getters = {
  getAttachments(stateControl) {
    return stateControl.attachments;
  },
  getCurrentPdfUrl(stateControl) {
    return stateControl.currentAttachmentPdfUrl;
  },
  getAttachmentSuccessMessage(stateControl) {
    return stateControl.attachmentSuccessMessage;
  },
  getAttachmentSuccess(stateControl) {
    return stateControl.saveAttachmentSuccessCheck;
  },
  getAttachmentWithPrePopulatedData(stateControl) {
    return stateControl.attachmentWithPrePopulatedData;
  },
  getCurrentAttachmentType(stateControl) {
    return stateControl.currentAttachmentType;
  },
};

const mutations = {
  setAttachments(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.attachments = payload;
  },
  addAttachment(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.attachments.push(payload);
  },
  deleteAttachment(stateControl, payload) {
    const stateControld = stateControl;
    const attachmentIndex = stateControld.attachments
      .findIndex((attachment) => attachment.id === payload);
    stateControld.attachments.splice(attachmentIndex, 1);
  },
  setCurrentAttachmentPdfUrl(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.currentAttachmentPdfUrl = payload;
  },
  removeAttachmentByIndex(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.attachments.splice(payload, 1);
  },
  setAttachmentSuccessMessage(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.attachmentSuccessMessage = payload;
  },
  saveAttachmentSuccessCheck(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.saveAttachmentSuccessCheck = payload;
  },
  setCombineAttachment(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.attachmentWithPrePopulatedData = payload;
  },
  updatePrintedDocumentList(stateControl, payload) {
    const stateControld = stateControl;
    const printedDoc = {
      type: payload.type,
      name: payload.name,
      cloud_id: payload.cloud_id,
    };
    if (printedDoc.type === documentTypes.HAZ_MANIFEST
      || printedDoc.type === documentTypes.NON_HAZ_MANIFEST) {
      const previousDoc = stateControld.printedDocumentList
        .find((doc) => doc.cloud_id === printedDoc.cloud_id && (
          doc.type === documentTypes.HAZ_MANIFEST
          || doc.type === documentTypes.NON_HAZ_MANIFEST
        ));
      if (previousDoc && previousDoc.name !== printedDoc.name) {
        removePreviouslyPrintedDoc(stateControld, previousDoc);
      }
      printedDoc.documentIdentifier = payload.name;
    } else {
      if (printedDoc.type === documentTypes.LAND_DISPOSAL) {
        const previousDoc = stateControld.printedDocumentList
          .find((doc) => doc.cloud_id === printedDoc.cloud_id
            && doc.type === documentTypes.LAND_DISPOSAL);
        if (previousDoc && previousDoc.name !== printedDoc.name) {
          removePreviouslyPrintedDoc(stateControld, previousDoc);
        }
      }
      printedDoc.documentIdentifier = payload.cloud_id + payload.type;
    }
    if (printedDoc.documentIdentifier) {
      const documentd = stateControld.printedDocumentList
        .find((doc) => doc.documentIdentifier === printedDoc.documentIdentifier);
      if (documentd) {
        Object.assign(documentd, printedDoc);
      } else {
        printedDoc.isPrePopulated = true;
        stateControld.printedDocumentList.push(printedDoc);
      }
    }
  },
  setSelectedPrePopulatedAttachment(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.selectedPrePopulatedAttachment = payload;
  },
  addPrePopulateDataAndAttachmentMapping(stateControl, payload) {
    const stateControld = stateControl;
    if (!isAttachmentInMappedData(stateControl, payload.attachmentId)) {
      stateControld.mappedPrePopulateDataWithAttachment.push(payload);
    }
  },
  deleteAttachmentMapData(stateControl, payload) {
    const stateControld = stateControl;
    const mappedIndex = stateControld.mappedPrePopulateDataWithAttachment
      .findIndex((mappedData) => mappedData.attachmentId === payload);
    if (mappedIndex > -1) {
      stateControld.mappedPrePopulateDataWithAttachment.splice(mappedIndex, 1);
    }
  },
  resetPrintedDocumentList(stateControl) {
    const stateControld = stateControl;
    stateControld.printedDocumentList = [];
  },
  resetPrintedDocumentAndAttachmentMap(stateControl) {
    const stateControld = stateControl;
    stateControld.mappedPrePopulateDataWithAttachment = [];
  },
  setCurrentAttachmentType(stateControl, fileType) {
    const stateControld = stateControl;
    stateControld.currentAttachmentType = fileType;
  },
};

const actions = {
  setAttachmentsDataFromDb({ commit, dispatch }, stopId) {
    stopService.getAttachments(stopId).then((res) => {
      commit('setAttachments', res);
      dispatch('updatePrePopulatedAttachment', { res, stopId });
    });
  },
  addAttachmentsData({ commit, dispatch, state }, attachment) {
    commit('saveAttachmentSuccessCheck', true);
    stopService.addAttachment(attachment).then((res) => {
      if (res && res.message) {
        commit('setAttachmentSuccessMessage', 'success');
        setTimeout(() => commit('setAttachmentSuccessMessage', ''), 3000);
      } else {
        commit('addAttachment', res);
        if (state.selectedPrePopulatedAttachment
          && state.selectedPrePopulatedAttachment.documentIdentifier) {
          const documentWithAttachmentMap = {
            attachmentId: res.id,
            documentIdentifier: state.selectedPrePopulatedAttachment.documentIdentifier,
          };
          commit('addPrePopulateDataAndAttachmentMapping', documentWithAttachmentMap);
        }
        dispatch('combinePrePopulatedDataWithAttachment');
      }
      if (state.selectedPrePopulatedAttachment) {
        commit('setSelectedPrePopulatedAttachment', null);
      }
    }, () => {
      toastMessage.showToastErrorMessage(commit, 'Failed to add attachment', 'error');
    });
  },
  deleteAttachmentsData({ commit, dispatch, state }, attachmentId) {
    stopService.deleteAttachment(attachmentId).then(() => {
      commit('deleteAttachment', attachmentId);
      if (isAttachmentInMappedData(state, attachmentId)) {
        commit('deleteAttachmentMapData', attachmentId);
      }
      dispatch('combinePrePopulatedDataWithAttachment');
    });
  },
  showToastErrorMessage({ commit }, message) {
    toastMessage.showToastErrorMessage(commit, message, 'error');
  },
  setCurrentAttachmentPdfUrl({ commit }, url) {
    commit('setCurrentAttachmentPdfUrl', url);
  },
  getAttachmentsDataFromFirestore({ commit, state }, stopId) {
    fireStoreAttachmentService.getAttachmentsByStopId(commit, state, stopId);
  },
  deleteAttachmentFromFirestore({ commit }, attachment) {
    fireStoreAttachmentService.deleteAttachment(attachment).then((response) => {
      toastMessage.showToastErrorMessage(commit, response.message, 'success');
    }).catch((error) => {
      toastMessage.showToastErrorMessage(commit, error.message, 'error');
    });
  },
  combinePrePopulatedDataWithAttachment({ commit, state }) {
    const combinedAttachment = [];
    state.printedDocumentList.forEach((doc) => {
      const attachmentId = findMappedAttachment(state, doc);
      if (attachmentId) {
        const attachment = state.attachments.find((att) => att.id === attachmentId);
        combinedAttachment.push(attachment);
      } else {
        combinedAttachment.push(doc);
      }
    });
    state.attachments.forEach((attachment) => {
      if (!isAttachmentInMappedData(state, attachment.id)) {
        combinedAttachment.push(attachment);
      }
    });
    commit('setCombineAttachment', combinedAttachment);
  },
  updatePrintedDocumentList({ commit }, params) {
    if (
      params.type !== documentTypes.HAZ_CONTINUATION
      && params.type !== documentTypes.NON_HAZ_CONTINUATION
    ) {
      commit('updatePrintedDocumentList', params);
    }
  },
  setSelectedPrePopulatedAttachment({ commit }, payload) {
    commit('setSelectedPrePopulatedAttachment', payload);
  },
  updatePrePopulatedAttachment({ commit, dispatch }, payload) {
    stopService.getPrintDocuments(payload.stopId).then((res) => {
      const apiResults = res.filter((doc) => (doc.type === documentTypes.HAZ_MANIFEST
      || doc.type === documentTypes.NON_HAZ_MANIFEST));
      apiResults.forEach((doc) => {
        if (doc.name) {
          commit('updatePrintedDocumentList', doc);
        }
      });
      dispatch('updatePrePopulateDataAndAttachmentMapping', payload.res);
    });
  },
  updatePrePopulateDataAndAttachmentMapping({ commit, dispatch, state }, payload) {
    payload.forEach((attachment) => {
      if (isAttachmentInPrintDoc(state, attachment.manifest_name, attachment.attachment_type)) {
        const documentWithAttachmentMap = {
          attachmentId: attachment.id,
          documentIdentifier: attachment.manifest_name,
        };
        commit('addPrePopulateDataAndAttachmentMapping', documentWithAttachmentMap);
      }
    });
    dispatch('combinePrePopulatedDataWithAttachment');
  },
};

export default {
  namespaced: true,
  state: stateObj,
  getters,
  actions,
  mutations,
};
