<template>
  <div class="scan-success-container">
    <div v-if="!viewSDS">
      <v-container
        v-if="!isDispositionFlagClicked"
        class="scan-success-item"
      >
        <img
          class="cursor-pointer check-icon"
          src="src/assets/images/check-full-green.svg"
          alt="check"
          v-if="masterHasApprovals && master.has_approval_in_stop"
        >
        <img
          class="cursor-pointer alert-icon"
          src="src/assets/images/alert.svg"
          alt="alert"
          v-else
        >
        <div class="scan-results">
          <span v-if="scanFromAlert()">
            <span v-if="masterHasApprovals && master.has_approval_in_stop">
              Item identified. Scan again or cancel.
            </span>
            <span v-else-if="masterHasApprovals && !master.has_approval_in_stop">
              Item approval classified
            </span>
            <span v-else>Item identified.</span>
          </span>
          <span v-else>
            <span v-if="masterHasApprovals && !master.has_approval_in_stop">
              Item approval classified
            </span>
            <span v-else>Scan success. Scan again or cancel.</span>
          </span>
        </div>
        <div
          v-if="masterHasApprovals"
          class="approval-container"
        >
          <div
            v-for="hauler_bin in master.master_bin_rule.hauler_bins"
            :key="hauler_bin.bin"
          >
            <div class="scanned-item">
              {{ hauler_bin.bin_name }}
            </div>
          </div>
          <v-btn
            v-if="master.has_approval_in_stop"
            class="flag-disposition-btn"
            :ripple="false"
            @click="dispositionFlagClicked"
          >
            Flag disposition
          </v-btn>
          <div
            v-else
            class="no-approval-in-stop"
          >
            <div class="alert-label">
              This item is classified, but the approval is not included in the current stop.
            </div>
            <div class="info-label">
              Please call the
              <span>Retail Helpline (866) 900-3762</span>
              to have the approval added to the stop.
            </div>
          </div>
        </div>
        <div v-else>
          <div class="no-approval-product-label">
            Item is not eligible for any of this customer’s approvals.
            Please call your National Account Specialist.
          </div>
        </div>
        <div class="container product-details">
          <v-row>
            <v-col
              cols="12"
              class="product-info-header"
            >
              Product info
            </v-col>
          </v-row>
          <v-row class="product-description-rows">
            <v-col cols="4">
              <div>
                <label>
                  Product name
                </label>
                <p>{{ master.product_name }}</p>
              </div>
            </v-col>
            <v-col cols="6">
              <div>
                <label>
                  UPC
                </label>
                <p>{{ master.upc }}</p>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-btn
                class="view-sds-btn"
                :ripple="false"
                @click="showViewSDS"
                :class="{ 'btn-disabled': !master.sds_url }"
              >
                <img
                  class="sds-icon"
                  src="src/assets/images/sds-icon.svg"
                  alt="sdsicon"
                >
                View SDS
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <div class="action-btn-div">
          <v-btn
            class="create-btn"
            @click="scanSuccess"
          >
            Done
          </v-btn>
        </div>
      </v-container>
      <v-container
        v-else
        class="disposition-container"
      >
        <div class="disposition-information">
          <div class="disposition-header">
            Flag disposition
          </div>
          <div class="disposition-description">
            If you believe that this approval or description is incorrect. Please call
            Smarter Sorting Support at 512-593-2594.
          </div>
        </div>
        <div class="cancel-btn-div">
          <v-btn
            class="cancel-btn"
            @click="cancelFlagDisposition"
          >
            Cancel
          </v-btn>
        </div>
      </v-container>
    </div>
    <div v-else>
      <ScanViewSDS
        @goBack="goBack"
        :sds-url-link="master.sds_url + '#toolbar=0&navpanes=0'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ScanViewSDS from '../scan-viewSDS/scan-viewSDS.vue';
import alertsService from '../../../service/alerts.service';

export default {
  name: 'ScanSuccessItem',
  components: { ScanViewSDS },
  computed: {
    ...mapGetters([
      'getStopDetail',
    ]),
    masterHasApprovals() {
      if (this.scanFromAlert()) {
        return this.alert.state === 'verified';
      }
      const haulerBins = this.master.master_bin_rule.hauler_bins;
      return haulerBins && haulerBins.length > 0;
    },
  },
  data() {
    return {
      isDispositionFlagClicked: false,
      viewSDS: false,
    };
  },
  methods: {
    dispositionFlagClicked() {
      this.isDispositionFlagClicked = true;
    },
    cancelFlagDisposition() {
      this.isDispositionFlagClicked = false;
    },
    showViewSDS() {
      this.viewSDS = true;
    },
    goBack() {
      this.viewSDS = false;
    },
    scanFromAlert() {
      return this.alert && this.alert.cloud_id;
    },
    scanSuccess() {
      if (this.scanFromAlert()) {
        this.updateAlert();
      }
      this.backToPreviousPage();
    },
    updateAlert() {
      alertsService.updateAlert(this.alert.cloud_id, this.getStopDetail.id);
    },
    closeScanDrawer() {
      this.$emit('closeDrawer');
    },
    navigateToApprovalList() {
      // Bin names will always be in <Approval description> - <Approval Code format>
      const ind = this.master.master_bin_rule.bin_name.lastIndexOf('- ');
      const approvalCode = this.master.master_bin_rule.bin_name.substr(ind + 2);
      this.$router.push(`/stops/detail/${this.getStopDetail.id}/approval/list/${approvalCode}`);
    },
    backToPreviousPage() {
      if (this.scanFromAlert()) {
        this.$emit('backToAlert');
      } else {
        this.$emit('backToScanPage');
      }
    },
  },
  props: {
    master: {
      type: Object,
      default() {
        return {};
      },
    },
    alert: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
