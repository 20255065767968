<template>
  <div class="last-stop-inventory-container">
    <v-btn
      class="back-btn"
      @click="$emit('closeInventoryList')"
    >
      <img
        src="src/assets/images/left-arrow.svg"
        alt="Back icon"
      >
      Back
    </v-btn>
    <div class="inventory-title">
      Last stop inventory
      <span v-if="lastStopCompletedDate">({{ lastStopCompletedDate | dateFormat }})</span>
    </div>
    <div class="inventory-container">
      <stop-inventory-card
        class="mb-4"
        v-for="(inventory, index) in lastStopInventoryList"
        :key="index"
        :inventory="inventory"
        :last-inventory-list="true"
        :inventory-use-ccid="inventoryUseCcid"
      />
    </div>
  </div>
</template>

<script>
import StopInventoryCard from '../../helpers/component/stop-inventory-card/stopInventoryCard.vue';

export default {
  name: 'LastStopInventoryList',
  components: { StopInventoryCard },
  props: {
    lastStopInventoryList: {
      type: Array,
      default() {
        return [];
      },
    },
    lastStopCompletedDate: {
      type: String,
      default: null,
    },
    inventoryUseCcid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
