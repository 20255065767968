//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import KeyboardDialog from '../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import RxContainerSelect from '../../../helpers/component/rx-content/containerSelect.vue';

export default {
  name: 'AddedRxItems',
  components: {
    KeyboardDialog,
    RxContainerSelect,
  },
  computed: {
    ...mapGetters({
      activeIndex: 'rxApprovalList/getSelectedIndex',
      consolidateApproval: 'getConsolidateApproval',
      containerTypeList: 'container/getContainerTypeList',
      getRxApprovalList: 'rxApprovalList/getRxApprovalList',
      rxApprovalAddedStatus: 'rxApprovalList/getRxApprovalAddedStatus',
      useCCIDStorage: 'getUseCCIDStorage',
    }),
    getRxApprovalListLength() {
      return this.getRxApprovalList.length;
    },
  },
  data() {
    return {
      transitionType: '',
      approvalLength: 0,
      isNewRxItem: false,
      text: '',
      rxApprovedList: [],
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      mutationTypeList: [
        'rxApprovalList/setRxApprovalList',
        'rxApprovalList/increaseRxContainerQuantity',
        'rxApprovalList/decreaseRxContainerQuantity',
        'rxApprovalList/setRxContainerValue',
      ],
      rxApprovalAddedIds: [],
    };
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      const findMutationType = this.mutationTypeList
        .find((mutationType) => mutationType === mutation.type);
      if (findMutationType === 'rxApprovalList/setRxApprovalList') {
        const approvalList = state.rxApprovalList.rxApprovalList;
        if (this.approvalLength !== 0) {
          if (this.approvalLength < approvalList.length) {
            this.transitionType = 'bounce';
          } else {
            this.transitionType = 'flip';
          }
        }
        this.approvalLength = approvalList.length;
      }
    });
    this.$store.commit('rxApprovalList/setRxApprovalAddedStatus', false);
    this.$store.dispatch('container/containerTypeListAction');
  },
  methods: {
    redirectToCard(index) {
      this.$store.commit('rxApproval/setRxEmptyBottle', false, { root: true });
      this.$store.commit('rxApproval/setSelectedEditApproval', {});
      this.$store.dispatch('rxApprovalList/setSelectedApprovalAction', index);
      this.$router.push('rx-approval-list');
    },
    increaseRxContainerQuantity(currentIndex) {
      this.$store.commit('rxApprovalList/increaseRxContainerQuantity', {
        index: currentIndex,
        field: 'containerQuantity',
      });
    },
    decreaseRxContainerQuantity(currentIndex) {
      this.$store.commit('rxApprovalList/decreaseRxContainerQuantity', {
        index: currentIndex,
        field: 'containerQuantity',
      });
    },
    checkDialog(KeyboardType, modelName, labelName, index, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.keypadIndex = index;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      const model = value.modelName;
      this.$store.commit('rxApprovalList/setRxContainerValue', {
        index: this.keypadIndex,
        field: model,
        value: +value.input,
      });
    },
    consolidate(list) {
      const params = {
        weight: list.total_weight,
        stop_id: +this.$route.params.stopId,
        approval_id: list.approval_id,
        waste_type: 'rx',
        containers: [],
        approval: list.approval,
      };
      const containerObj = {};
      if (!list.containerQuantity && !list.containerId && list.stop_approval
      && list.stop_approval.stop_containers.length > 0) {
        containerObj.id = list.stop_approval.stop_containers[0].id;
        containerObj.container_id = list.stop_approval.stop_containers[0].container_id;
        containerObj.quantity = list.stop_approval.stop_containers[0].quantity;
      } else {
        containerObj.id = '';
        if (list.stop_approval && list.stop_approval.stop_containers.length > 0) {
          containerObj.id = list.stop_approval.stop_containers[0].id;
        } else {
          containerObj.id = '';
        }
        containerObj.container_id = list.containerId;
        containerObj.quantity = list.containerQuantity;
      }
      params.containers.push(
        containerObj,
      );
      if (list.stop_approval) {
        params.stopApprovalId = list.stop_approval.id;
        this.$store.dispatch('rxApprovalList/rxReConsolidate', params);
      } else {
        params.approval_rx_item_id = list.id;
        this.$store.dispatch('rxApprovalList/rxConsolidate', params);
      }
      if (!this.useCCIDStorage) {
        this.addApprovalIdToList(list.id);
      }
    },
    getRxApprovalCode(approvalDetail) {
      return `${approvalDetail.tsdf}/${approvalDetail.approval.approval_code}`;
    },
    consolidateBtnDisabled(list) {
      if (!list.stop_approval || !list.stop_approval.stop_containers.length) {
        return !list.containerId || !list.containerQuantity;
      }
      return ((list.stop_approval && !list.stop_approval.stop_containers[0].container_id)
      || !list.containerQuantity);
    },
    containerQuantityDisabled(list) {
      return ((list.stop_approval && list.stop_approval.stop_containers.length
      && list.stop_approval.stop_containers[0].quantity === 0)
      || (list.containerQuantity === 0));
    },
    changeNewCard() {
      this.isNewRxItem = true;
      setTimeout(() => {
        this.isNewRxItem = false;
      }, 2000);
    },
    changeNewCardTransition() {
      const time = this.transitionType === 'bounce' ? 1300 : 800;
      setTimeout(() => {
        this.changeNewCard();
      }, time);
    },
    buttonLable(id) {
      if (this.useCCIDStorage) {
        return 'Consolidate';
      }
      if (this.rxApprovalAddedIds.includes(id)) {
        return 'Added to truck inventory';
      }
      return 'Add Rx to truck inventory';
    },
    addApprovalIdToList(id) {
      this.rxApprovalAddedIds.push(id);
      setTimeout(() => {
        this.rxApprovalAddedIds.shift();
      }, 500);
    },
    disableAddToInventoryBtn(id) {
      if (!this.useCCIDStorage && this.rxApprovalAddedIds.includes(id)) {
        return true;
      }
      return false;
    },
  },
  watch: {
    consolidateApproval(newVal) {
      if (newVal && newVal.id) {
        this.$router.push({ name: 'RxConsolidate' });
      }
    },
    getRxApprovalListLength: {
      deep: true,
      handler(newVal) {
        if (newVal === 0) this.transitionType = 'bounce';
      },
    },
    rxApprovalAddedStatus(val) {
      if (val) {
        this.changeNewCardTransition();
        this.$store.commit('rxApprovalList/setRxApprovalAddedStatus', false);
        this.$store.dispatch('rxApprovalList/setSelectedApprovalAction', -1);
      }
    },
  },
};
