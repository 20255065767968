import stopService from '../service/stops.service';
import printService from '../service/print.backend.service';
import toastMessage from './toast-message';


function printComplete(commit, dispatch, errorMessages, stopId) {
  if (errorMessages.length > 0) {
    const printStatusMessage = `Failed to print labels ${errorMessages}`;
    toastMessage.showToastErrorMessage(commit, printStatusMessage, 'error');
  } else {
    const printStatusMessage = 'Label printed';
    toastMessage.showToastErrorMessage(commit, printStatusMessage, 'success', true);
  }
  commit('updateLabelPrintingComplete', true);
  dispatch('deselectAllLabels');
  dispatch('updateLabelReprintStatus', stopId);
}

const state = {
  labels: [],
  labelReprintStatus: false,
  labelPrintingComplete: true,
};

const getters = {
  getLabels(stateControl) {
    return stateControl.labels;
  },
  getLabelReprintStatus(stateControl) {
    return stateControl.labelReprintStatus;
  },
  getLabelPrintingComplete(stateControl) {
    return stateControl.labelPrintingComplete;
  },
};

const mutations = {
  setLabels(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.labels = payload;
  },
  selectLabel(stateControl, labelCcidAndType) {
    const stateControld = stateControl;
    const labelIndex = stateControld.labels.findIndex(((label) => label.ccid + label.displayedType
    === labelCcidAndType));
    stateControld.labels[labelIndex].selected = true;
  },
  deselectLabel(stateControl, labelCcidAndType) {
    const stateControld = stateControl;
    const labelIndex = stateControld.labels.findIndex(((label) => label.ccid + label.displayedType
     === labelCcidAndType));
    stateControld.labels[labelIndex].selected = false;
  },
  updateQuantity(stateControl, data) {
    const stateControld = stateControl;
    const labelIndex = stateControld.labels.findIndex(((label) => label.ccid + label.displayedType
    === data.ccid));
    stateControld.labels[labelIndex].quantity = data.qty;
  },
  updateLabelReprintStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.labelReprintStatus = payload;
  },
  updateLabelPrintingComplete(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.labelPrintingComplete = payload;
  },
};

const actions = {
  setLabelsData({ commit }, labelDetails) {
    const { stopID, labelGroup } = labelDetails;
    const labelList = [];
    stopService.getPrintLabels(stopID, labelGroup).then((res) => {
      res.forEach((label) => {
        const labelState = label.is_full ? 'Closed' : 'Open';
        const displayedTypes = {
          H: `${labelState} Haz`,
          N: `${labelState} Non-Haz`,
          R: `${labelState} Non-RCRA`,
          U: `${labelState} Universal`,
          BH: 'Haz',
          BN: 'Non-Haz',
          BR: 'Non-RCRA',
          BU: 'Universal',
          CU: 'Customer',
        };
        const labelAdditions = {
          selected: false,
          displayedType: displayedTypes[label.type],
          quantity: 1,
        };
        Object.assign(label, labelAdditions);
        labelList.push(label);
        if (label.is_overpack) {
          const labelObjectCopy = { ...label };
          labelObjectCopy.displayedType = 'OVERPACK';
          labelList.push(labelObjectCopy);
        }
      });
      commit('setLabels', labelList);
    });
  },
  selectLabel({ commit }, labelCcidAndType) {
    commit('selectLabel', labelCcidAndType);
  },
  deselectLabel({ commit }, labelCcidAndType) {
    commit('deselectLabel', labelCcidAndType);
  },
  selectAllLabels({ commit, state: stateObj }) {
    stateObj.labels.forEach((label) => {
      commit('selectLabel', label.ccid + label.displayedType);
    });
  },
  deselectAllLabels({ commit, state: stateObj }) {
    stateObj.labels.forEach((label) => {
      commit('deselectLabel', label.ccid + label.displayedType);
    });
  },
  printSelectedLabels({ commit, dispatch }, data) {
    commit('updateLabelPrintingComplete', false);
    let errorMessages = [];
    printService.printLabel(data.labels, data.tripId).then((res) => {
      errorMessages = res.failed;
      printComplete(commit, dispatch, errorMessages, data.stopId);
    }).catch((err) => {
      errorMessages = [err];
      printComplete(commit, dispatch, errorMessages, data.stopId);
    });
  },
  updateQuantity({ commit }, data) {
    commit('updateQuantity', data);
  },
  updateLabelReprintStatus({ commit }, stopId) {
    stopService.getLabelReprintingStatus(stopId).then((res) => {
      commit('updateLabelReprintStatus', res);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
