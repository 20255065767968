//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import document from '../../../constants/document';
import VSelectArrow from '../../utils/v-select-arrow';

export default {
  name: 'MobileAttachmentsCard',
  data() {
    return {
      attachmentTypes: document.attachmentsType,
    };
  },
  extends: VSelectArrow,
  methods: {
    removeAttachment() {
      if (this.attachmentDetail.$model.isNew) {
        this.$store.commit('attachment/removeAttachmentByIndex', this.attachmentIndex);
      } else {
        const attachmentDetail = this.attachmentDetail.$model;
        attachmentDetail.stop_id = this.$store.state.stopDetail.stopDetail.doc_id;
        this.$store.dispatch('attachment/deleteAttachmentFromFirestore', attachmentDetail);
      }
    },
  },
  props: {
    attachmentDetail: {
      type: Object,
      default: () => ({
        id: '',
        image_url: '',
        description: '',
        attachment_type: 'Photo',
        stop_id: '',
      }),
    },
    attachmentIndex: {
      type: String,
      default: '',
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
  },
};
