//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import attachmentTypeTable from '../../../helpers/component/attachment-type-table/attachmentTypeTable.vue';
import KeyboardDialog from '../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import scanBackendService from '../../../service/scan.backend.service';
import { API_BASE_URL } from '../../../constants/index';
import rightDrawer from '../../../layout/logged-in-header/rightDrawer.vue';
import vSelectArrow from '../../../helpers/utils/v-select-arrow';

export default {
  name: 'StopAttachments',
  components: {
    attachmentTypeTable,
    KeyboardDialog,
    rightDrawer,
  },
  computed: {
    attachmentIsValid() {
      return this.currentDescription && this.currentAttachmentType;
    },
    shouldShowAddAttachmentsDialog() {
      return (
        [
          this.scanStates.enterIntoScanner,
          this.scanStates.scanningDocument,
          this.scanStates.scanAnotherPage,
          this.scanStates.scanError,
        ].indexOf(this.currentScanState) > -1
      );
    },
    ...mapGetters({
      attachments: 'attachment/getAttachments',
      pdfUrl: 'attachment/getCurrentPdfUrl',
      stopDetail: 'getStopDetail',
      fileType: 'attachment/getCurrentAttachmentType',
    }),
    attachmentExists() {
      return this.attachments.length > 0;
    },
    decodedUrl() {
      let dataType = 'data:application/pdf;base64';
      if (this.fileType === 'jpg') {
        dataType = 'data:image/jpeg;base64';
      }
      return `${dataType},${this.pdfUrl}`;
    },
  },
  data() {
    return {
      attachmentTypes: [
        { text: 'LDR' },
        { text: 'BOL' },
        { text: 'Pickup Report' },
        { text: 'DEA-41' },
        { text: 'DEA-222' },
        { text: 'PRD' },
        { text: 'Packing List' },
        // { text: 'Photo' },
        { text: 'Generator Initial Manifest' },
        { text: 'Attachment' },
        { text: 'Customer Document' },
        { text: 'Other' },
      ],
      currentAttachmentType: '',
      currentManifestName: '',
      scanCancelled: false,
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      currentDescription: '',
      currentScanState: '',
      currentPageState: 'first',
      scanStates: {
        addAttachment: 'Add attachments',
        enterIntoScanner: 'Please insert first page into scanner.',
        scanningDocument: 'Scanning document...',
        scanAnotherPage: 'Scanned document',
        scanError: '',
      },
      descriptionErrorMsg: '',
      dialog: true,
      currentPage: 1,
      currentPdfUrl: '',
      scanTimeout: null,
      openPdfDrawer: false,
      dataTypeForDrawer: 'pdf',
    };
  },
  extends: vSelectArrow,
  mounted() {
    this.currentScanState = this.scanStates.addAttachment;
    this.$store.dispatch(
      'attachment/setAttachmentsDataFromDb',
      this.$store.getters.getStopDetail.id,
    );
  },
  beforeDestroy() {
    if (this.scanTimeout) {
      clearTimeout(this.scanTimeout);
    }
  },
  methods: {
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.descriptionErrorMsg = '';
      this.currentDescription = value.input;
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    openKeyboard() {
      this.checkDialog(
        'alphaNumeric',
        'currentDescription',
        'Description',
        this.currentDescription,
      );
    },
    addAttachment() {
      if (this.attachmentIsValid) {
        if (this.currentDescription.length < 16) {
          this.scanCancelled = false;
          this.currentScanState = this.scanStates.enterIntoScanner;
        } else {
          this.descriptionErrorMsg = 'Description should have a maximum of 15 characters.';
        }
      }
    },
    scanPage() {
      this.currentScanState = this.scanStates.scanningDocument;
      this.scanTimeout = setTimeout(() => {
        this.scanAttachment();
      }, 1000);
    },
    scanAttachment() {
      const attachment = {
        page: this.currentPage,
      };
      scanBackendService.scanAttachment(attachment).then(
        (res) => {
          if (!this.scanCancelled) {
            if (res.success) {
              this.showScanSuccessComponentWithPdf();
            } else {
              this.showErrorComponent(res.error);
            }
          }
        },
        () => {
          this.$store.dispatch(
            'attachment/showToastErrorMessage',
            'There was a problem communicating with the scanner',
          );
          this.returnToAttachmentsScreen();
        },
      );
    },
    showErrorComponent(errorMessage) {
      this.scanStates.scanError = errorMessage;
      this.currentScanState = this.scanStates.scanError;
    },
    showScanSuccessComponentWithPdf() {
      const cacheBuster = Date.now();
      let zeroKey = '';
      if (this.currentPage < 10) {
        zeroKey = '0';
      }
      this.currentPdfUrl = `${API_BASE_URL}stops/attachment/pdf/${zeroKey}${this.currentPage}#toolbar=0&navpanes=0&timestamp=${cacheBuster}`;
      this.showScanSuccessComponent();
    },
    showScanSuccessComponent() {
      this.currentScanState = this.scanStates.scanAnotherPage;
    },
    scanAnotherPage() {
      this.currentPage += 1;
      this.currentScanState = this.scanStates.enterIntoScanner;
    },
    completeScanning() {
      this.addAttachmentInStore();
      this.returnToAttachmentsScreen();
    },
    setupRescanPage() {
      this.currentScanState = this.scanStates.enterIntoScanner;
    },
    returnToAttachmentsScreen() {
      this.scanCancelled = true;
      this.currentAttachmentType = '';
      this.currentDescription = '';
      this.currentPage = 1;
      this.currentScanState = this.scanStates.addAttachment;
      this.currentManifestName = '';
    },
    addAttachmentInStore() {
      const attachmentData = {
        id: 0,
        attachment_type: this.currentAttachmentType,
        description: this.currentDescription,
        stop: this.$store.getters.getStopDetail.id,
        manifest_name: this.currentManifestName,
        file_type: this.getFileType(this.currentAttachmentType), // TODO: Fill with actual file type
      };
      if (this.attachments.length > 0) {
        attachmentData.id = this.attachments[this.attachments.length - 1].id + 1;
      }
      this.$store.dispatch('attachment/addAttachmentsData', attachmentData);
    },
    getFileType(attachmentType) {
      if (attachmentType) {
        return 'pdf';
      }
      return '';
    },
    removePdfUrl() {
      this.$store.dispatch('attachment/setCurrentAttachmentPdfUrl', '');
    },
    openPdfView() {
      this.openPdfDrawer = true;
    },
    closePDFView() {
      this.openPdfDrawer = false;
    },
    scanPrePopulatedAttachment(attachment) {
      this.scanCancelled = false;
      this.currentScanState = this.scanStates.enterIntoScanner;
      this.currentAttachmentType = attachment.attachment_type;
      this.currentManifestName = attachment.manifest_name;
      this.currentDescription = attachment.description;
    },
  },
  watch: {
    currentPage(page) {
      if (page === 1) {
        this.currentPageState = 'first';
        this.scanStates.enterIntoScanner = 'Please insert first page into scanner.';
      } else {
        this.currentPageState = 'next';
        this.scanStates.enterIntoScanner = 'Please insert the next page into scanner.';
        this.currentScanState = this.scanStates.enterIntoScanner;
      }
    },
    pdfUrl: {
      deep: true,
      handler(url) {
        if (url) {
          this.openPdfView();
        } else {
          this.closePDFView();
        }
      },
    },
    fileType: {
      deep: true,
      handler(val) {
        this.dataTypeForDrawer = val;
      },
    },
  },
};
