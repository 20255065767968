import tripService from '../service/trip.service';
import toastMessage from './toast-message';
import fireStoreTripService from '../service/firestore/trips.service';
import printService from '../service/print.backend.service';
import { API_BASE_URL } from '../constants/index';
import userService from '../service/auth.service';
import tripStorage from '../constants/trip-storage';

function getLoggedTechID() {
  const userDetail = JSON.parse(localStorage.getItem('user'));
  return userDetail ? userDetail.id : '';
}

const stateObj = {
  trips: [],
  completedTrips: [],
  tripListerror: '',
  message: '',
  tripCodeErrorMsg: '',
  tripPasswordErrorMsg: '',
  tripCodeValid: false,
  isFirstLogin: true,
  techID: getLoggedTechID(),
  tripAddStatus: false,
  tripDetailPdfUrl: '',
  syncRequestedTrip: '',
  tripSyncStatus: '',
  tripSyncStartedAt: '',
  allTripCcid: [],
  userTripsSyncStatus: [],
  storageSelectedForTrip: true,
};

const getters = {
  getTripList(stateControl) {
    return stateControl.trips;
  },
  getCompletedTripList(stateControl) {
    return stateControl.completedTrips;
  },
  getError(stateControl) {
    return stateControl.error;
  },
  getTripCodeError(stateControl) {
    return stateControl.tripCodeErrorMsg;
  },
  getTripCodeValid(stateControl) {
    return stateControl.tripCodeValid;
  },
  getTechID(stateControl) {
    return stateControl.techID;
  },
  getTripPasswordError(stateControl) {
    return stateControl.tripPasswordErrorMsg;
  },
  getTripAddStatus(stateControl) {
    return stateControl.tripAddStatus;
  },
  getTripDetailPdfUrl(stateControl) {
    return stateControl.tripDetailPdfUrl;
  },
  getSyncRequestedTrip(stateControl) {
    return stateControl.syncRequestedTrip;
  },
  getTripSyncStatus(stateControl) {
    return stateControl.tripSyncStatus;
  },
  getTripSyncStartedAt(stateControl) {
    return stateControl.tripSyncStartedAt;
  },
  getAllCcidList(stateControl) {
    return stateControl.allTripCcid;
  },
  getUserTripsSyncStatus(stateControl) {
    return stateControl.userTripsSyncStatus;
  },
  getStorageSelectedForTrip(stateControl) {
    return stateControl.storageSelectedForTrip;
  },
};

const mutations = {
  setTripList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.trips = payload.tripList;
  },
  setCompletedTripList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.trips = payload.tripList;
  },
  tripListFailure(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.trips = [];
    stateControld.error = payload.errorMsg;
  },
  setTripCodeError(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.tripCodeErrorMsg = payload.message;
  },
  setTripCodeValid(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.tripCodeValid = payload;
  },
  resetTripCodeValid(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.tripCodeValid = payload;
  },
  setFirstLogin(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.isFirstLogin = payload;
  },
  setTechID(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.techID = payload.tripID;
  },
  setTripPasswordError(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.tripPasswordErrorMsg = payload;
  },
  setTripAddStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.tripAddStatus = payload;
  },
  clearStatus(stateControl) {
    const stateControld = stateControl;
    stateControld.trips = [];
    stateControld.tripListerror = '';
    stateControld.message = '';
    stateControld.tripCodeErrorMsg = '';
    stateControld.tripPasswordErrorMsg = '';
    stateControld.tripCodeValid = false;
    stateControld.techID = getLoggedTechID();
    stateControld.tripAddStatus = false;
  },
  clearTripList(stateControl) {
    const stateControld = stateControl;
    stateControld.trips = [];
    stateControld.tripListerror = '';
  },
  setTripDetailPdfUrl(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.tripDetailPdfUrl = payload;
  },
  setSyncRequestedTrip(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.syncRequestedTrip = payload;
  },
  setTripSyncStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.tripSyncStatus = payload;
  },
  setTripSyncStartedAt(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.tripSyncStartedAt = payload;
  },
  setAllCcidList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.allTripCcid = payload;
  },
  setUserTripsSyncStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.userTripsSyncStatus = payload;
  },
  setStorageSelectedForTrip(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.storageSelectedForTrip = payload;
  },
};

const actions = {
  addTripAction({ commit, dispatch, state }, res) {
    const params = {
      password: res.password.toUpperCase(),
      truck_number: res.truck_number,
      trailer_number: res.trailer_number,
    };

    const tripCode = res.trip_code;
    tripService.addTrip(params, state.techID, tripCode).then(
      () => {
        dispatch('tripListAction');
        const messageData = 'Trip added successfully';
        toastMessage.showToastErrorMessage(commit, messageData, 'success');
        commit('setTripPasswordError', '');
        commit('setTripCodeValid', false);
        commit('setTripAddStatus', true);
      },
      (errorMessage) => {
        let messageData = '';
        if (
          Object.keys(errorMessage).length === 0
          && errorMessage.constructor === Object
        ) {
          messageData = 'Trip or Tech not found';
        } else {
          messageData = errorMessage;
        }
        commit('setTripCodeValid', false);
        if (errorMessage === 'Operation failed: Password mismatch') {
          commit('setTripPasswordError', 'Password is incorrect');
          commit('setTripAddStatus', false);
        } else {
          toastMessage.showToastErrorMessage(commit, messageData, 'error');
        }
      },
    );
  },

  emptyTripList({ commit }) {
    commit('clearTripList');
  },

  completedTripListAction({ commit, state }) {
    commit(
      'loadingSearchIcon/loadingRequest',
      { status: true, loadingName: 'Loading active trips' },
      { root: true },
    );
    tripService.getCompletedTrips(state.techID).then(
      (response) => {
        commit({
          type: 'setTripList',
          tripList: response.trips,
        });
        commit(
          'loadingSearchIcon/loadingRequest',
          { status: false, loadingName: 'Loading active trips' },
          { root: true },
        );
      },
      (errorMessage) => {
        let messageData = '';
        if (
          Object.keys(errorMessage).length === 0
          && errorMessage.constructor === Object
        ) {
          messageData = 'Trip list not found';
        } else {
          messageData = errorMessage;
        }
        toastMessage.showToastErrorMessage(commit, messageData, 'error');
        commit({
          type: 'tripListFailure',
          errorMsg: errorMessage,
        });
      },
    );
  },

  tripListAction({ commit, state }) {
    commit(
      'loadingSearchIcon/loadingRequest',
      { status: true, loadingName: 'Loading active trips' },
      { root: true },
    );
    tripService.getAllTrips(state.techID).then(
      (trips) => {
        commit({
          type: 'setTripList',
          tripList: trips.trips,
        });
        commit(
          'loadingSearchIcon/loadingRequest',
          { status: false, loadingName: 'Loading active trips' },
          { root: true },
        );
      },
      (errorMessage) => {
        let messageData = '';
        if (
          Object.keys(errorMessage).length === 0
          && errorMessage.constructor === Object
        ) {
          messageData = 'Trip list not found';
        } else {
          messageData = errorMessage;
        }
        toastMessage.showToastErrorMessage(commit, messageData, 'error');
        commit({
          type: 'tripListFailure',
          errorMsg: errorMessage,
        });
      },
    );
  },

  hideTripAction({ commit, dispatch }, res) {
    tripService.removeTrip(res.tripId).then(
      () => {
        dispatch('tripListAction');
        const messageData = 'Trip hided successfully';
        toastMessage.showToastErrorMessage(commit, messageData, 'success');
      },
      (errorMessage) => {
        let messageData = '';
        if (
          Object.keys(errorMessage).length === 0
          && errorMessage.constructor === Object
        ) {
          messageData = 'Trip not found';
        } else {
          messageData = errorMessage;
        }
        toastMessage.showToastErrorMessage(commit, messageData, 'error');
      },
    );
  },

  removeTripAction({ commit, dispatch }, tripId) {
    if (tripId) {
      tripService.removeCompletedTrip(tripId).then(
        () => {
          dispatch('completedTripListAction');
        },
        (errorMessage) => {
          let messageData = '';
          if (
            Object.keys(errorMessage).length === 0
            && errorMessage.constructor === Object
          ) {
            messageData = 'Trip not found';
          } else {
            messageData = errorMessage;
          }
          toastMessage.showToastErrorMessage(commit, messageData, 'error');
        },
      );
    }
  },

  tripValidateAction({ commit, state }, res) {
    let tripCodeMsg = '';
    if (res) {
      if (res.length < 4 || res.length > 10) {
        tripCodeMsg = 'Code should be min 4 & max 10 digits';
        commit('setTripCodeError', { message: tripCodeMsg });
        commit('setTripCodeValid', false);
        return;
      }
    } else {
      tripCodeMsg = 'Trip Code is Required';
      commit('setTripCodeError', { message: tripCodeMsg });
      commit('setTripCodeValid', false);
      return;
    }
    tripService.validateTrip(res, state.techID).then(
      () => {
        if (tripCodeMsg === '') {
          commit('setTripCodeValid', true);
        } else {
          commit('setTripCodeValid', false);
        }
        commit('setTripCodeError', { message: tripCodeMsg });
      },
      (errorMessage) => {
        commit('setTripCodeError', { message: errorMessage });
        commit('setTripCodeValid', false);
      },
    );
  },
  mobileTripListAction({ commit }) {
    fireStoreTripService.getAllTrips(commit);
  },
  printTripsDetail({ commit, state }, payload) {
    printService
      .printTripsDetail(state.techID, payload.preview, payload.tripId)
      .then(() => {
        const tripDetailUrl = `${API_BASE_URL}trips/trips_details/pdf#toolbar=0&navpanes=0`;
        commit('setTripDetailPdfUrl', tripDetailUrl);
        if (!payload.preview) {
          const messageData = 'Trips details printed';
          toastMessage.showToastErrorMessage(
            commit,
            messageData,
            'success',
            true,
          );
        }
      });
  },
  syncTripFromUSEToast({ commit }, type = 'error') {
    if (type === 'error') {
      const message = '<div class="custom-toast-text-div"><p class="custom-toast-header">No internet connection</p><p class="custom-toast-content">Your data will sync automatically when a connection is found.</p><div>';
      toastMessage.showToastErrorMessage(commit, message, type, true, 1);
    } else if (type === 'success') {
      const message = 'Trip sync successful. All changes have been applied.';
      toastMessage.showToastErrorMessage(commit, message, type);
      // Disabling reload to address state issues
      // setTimeout(() => {
      // window.location.reload();
      // }, 3000);
    } else if (type === 'inProgress') {
      const message = '<div class="custom-toast-inprogress-text-div"><p class="custom-toast-content">Trip update found. Sync in progress.</p><div>';
      toastMessage.showToastErrorMessage(commit, message, type, true, 4);
    } else {
      const message = '<div class="custom-toast-text-div"><p class="custom-toast-header">Sync failed</p><p class="custom-toast-content">Try again later. If it persists, please contact support.</p><div>';
      toastMessage.showToastErrorMessage(commit, message, type, true, 2);
    }
  },
  syncTripFromUSE({ commit }, tripId) {
    tripService.syncTripFromUSE({ trip_id: tripId }).then((response) => {
      commit('setTripSyncStartedAt', response);
    });
    commit('setSyncRequestedTrip', tripId);
    commit('setTripSyncStatus', 'pending');
  },
  checkTripSyncedStatus({ commit, state }) {
    const { syncRequestedTrip: tripId, tripSyncStartedAt: dataSyncedAt } = state;
    tripService
      .tripSyncStatus({ id: tripId, data_synced_at: dataSyncedAt })
      .then((response) => {
        if (response.message === 'started') {
          commit('setTripSyncStatus', 'inProgress');
        } else {
          commit('setTripSyncStatus', response.message);
        }
      });
  },
  viewAllCcid({ commit }, tripId) {
    tripService.getAllTripCCID(tripId).then((response) => {
      commit('setAllCcidList', response);
    });
  },
  getUserTripsStatus(context, userId) {
    return new Promise((resolve) => {
      userService.getUserTripsStatus(userId).then((response) => {
        context.commit('setUserTripsSyncStatus', response);
        resolve(response);
      });
    });
  },
  updateStorageSetForTrip(context, trip) {
    const storageSelected = trip.storage_method === tripStorage.storageMethods.use_ccid
      || trip.storage_method === tripStorage.storageMethods.do_not_use_ccid;
    context.commit('setStorageSelectedForTrip', storageSelected);
    if (storageSelected) {
      const useCCIDStorage = trip.storage_method === tripStorage.storageMethods.use_ccid;
      context.commit('updateUseCCIDStorage', useCCIDStorage, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state: stateObj,
  getters,
  actions,
  mutations,
};
