//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BootUp',

  methods: {
    goToSignIn() {
      this.$router.push({ name: 'Login' });
    },
    goToCreateAccount() {
      this.$router.push({ name: 'Create Account' });
    },
  },
};
