//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ContainerQuantity',
  data() {
    return {
      quantityValue: JSON.parse(this.quantity),
    };
  },
  methods: {
    increaseQuantity() {
      if (this.quantityValue < this.maximum) {
        this.quantityValue += 1;
        this.$emit('quantityChanged', this.quantityValue);
      }
    },
    decreaseQuantity() {
      if (this.quantityValue > this.minimum) {
        this.quantityValue -= 1;
        this.$emit('quantityChanged', this.quantityValue);
      }
    },
  },
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    minimum: {
      type: Number,
      default: 0,
    },
    maximum: {
      type: Number,
      default: 100,
    },
  },
};
