<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="1200"
    >
      <div class="complete-stop-container">
        <div class="d-flex width-100p height-80p">
          <div class="section1">
            <div class="d-flex stop-time-detail pl-6">
              <div class="d-flex flex-column pt-3 pr-4 vertical-border-right">
                <label>
                  Time completed
                </label>
                <div>
                  {{ completeStop.stop_complete_time }}
                </div>
              </div>
              <div class="d-flex flex-column pt-3 px-4">
                <label>
                  Total stop time
                </label>
                <div>
                  {{ completeStop.total_time }}
                </div>
              </div>
            </div>
            <div class="width-100p section1-container">
              <div
                class="first-pickup-alert"
                v-if="isAccumulationPickupData && showFirstTimeNoticeFlag"
              >
                <div class="alert-container">
                  <img
                    src="src/assets/images/alert.svg"
                    alt="alert-icon"
                  >
                  <div class="label">
                    This is the first pickup at this generator using Smart Pickup.
                    The expected packages may not match as accurately as they will on
                    future pickups at this location.
                  </div>
                </div>
              </div>
              <div class="complete-stop-header d-flex flex-column">
                <div class="header-container">
                  <div class="left-section">
                    <div class="header-label">
                      <label
                        v-if="completeStop.no_waste"
                      >No waste on site?</label>
                      <label
                        v-else-if="checkSortingAndGeneratorSignNameCompleteStatus
                          && !isAccumulationPickupData"
                      >Complete the stop?</label>
                      <label
                        v-else-if="isAccumulationPickupData"
                      >Stop summary</label>
                      <label v-else>Stop summary</label>
                    </div>
                    <div class="header-description">
                      <label
                        v-if="completeStop.no_waste"
                        class="no-waste-pickup"
                      >
                        Make sure to print all required documents. After
                        a stop is completed, All fields are locked and
                        changes are no longer allowed.

                      </label>
                      <label
                        v-else-if="checkSortingAndGeneratorSignNameCompleteStatus
                          || isAccumulationPickupData"
                      >
                        After a stop is completed, all fields are locked
                        and changes are no longer allowed.
                      </label>
                      <label
                        class="w-450p"
                        v-else
                      >
                        To complete the stop, check the to-dos on the right.
                      </label>
                    </div>
                  </div>
                  <div
                    class="right-section"
                    v-if="isAccumulationPickupData && !completeStop.no_waste"
                  >
                    <CompleteStopWeightInfo
                      :truck-sis-total-weight="getTruckSisTotalWeight"
                      :retail-sis-total-weight="getRetailSisTotalWeight"
                      :display-overweight-warning="weightDifferenceOver10Percent()"
                    />
                  </div>
                </div>
                <div
                  class="waste-pickup-btns d-flex"
                  v-if="completeStop.no_waste"
                >
                  <div>
                    <button
                      class="no-waste-pickup-btn border-top-bottom-left-radius"
                      @click="confirmCompleteStop('generator-declined')"
                      :class="{'active-btn': btnActiveGenerator}"
                    >
                      Generator declined pickup
                    </button>
                  </div>
                  <div>
                    <button
                      @click="confirmCompleteStop('no-waste')"
                      class="no-waste-pickup-btn bl-none border-top-bottom-right-radius"
                      :class="{'active-btn': btnActiveOnSite}"
                    >
                      No waste on-site
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="!completeStop.no_waste && isAccumulationPickupData"
                class="stop-summary-container"
              >
                <stop-summary-table
                  v-if="knownProfiles.length > 0"
                  :profile-list="knownProfiles"
                  :table-header="expectedProfileLabel"
                />
                <stop-summary-table
                  v-if="potentialProfiles.length>0"
                  :profile-list="potentialProfiles"
                  :table-header="sisUndeterminedLabel"
                />
                <div class="weight-summary-container">
                  <div class="top-section">
                    <CompleteStopWeightInfo
                      :truck-sis-total-weight="getTruckSisTotalWeight"
                      :retail-sis-total-weight="getRetailSisTotalWeight"
                      :display-overweight-warning="weightDifferenceOver10Percent()"
                    />
                  </div>
                  <div
                    class="bottom-section"
                    v-if="weightDifferenceOver10Percent()"
                  >
                    <div class="note-label">
                      There is a weight discrepancy over 10%. You may still
                      complete the stop if you believe all waste was entered properly.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="section2">
            <div
              class="status-col1"
              v-if="completeStop.no_waste"
            >
              <div
                class="incomplete-section"
                :class="{'incomplete-waste-section': !completeStop.customer_labels_printed}"
              >
                <div
                  class="center-section2-label d-flex align-center"
                >
                  <i class="fas fa-exclamation-triangle no-waste-icon" />
                  <label class="no-waste-label">No waste</label>
                </div>
              </div>

              <div
                class="incomplete-section-list"
                v-if="!completeStop.customer_labels_printed"
              >
                <div class="center-section2-label d-flex align-center">
                  <div class="qa-alert-img" />
                  <label
                    @click="navigateToCustomerLabels"
                  >Customer labels not printed</label>
                </div>
              </div>
            </div>
            <div
              class="status-col1"
              v-else-if="completeStop.completed"
            >
              <div class="complete-section">
                <div class="center-section2-label d-flex align-center">
                  <i
                    class="fa fa-check-circle section-complete-icon"
                    aria-hidden="true"
                  />
                  <label>All sections complete</label>
                </div>
              </div>
            </div>
            <div
              class="status-col1"
              v-else
            >
              <div class="incomplete-section">
                <div
                  class="center-section2-label d-flex align-center"
                >
                  <div class="close-icons" />
                  <label>Missing information</label>
                </div>
              </div>
              <div
                class="incomplete-section-list"
                v-if="!completeStop.generator_signed"
              >
                <div
                  class="center-section2-label d-flex"
                >
                  <div class="close-sm-icon" />
                  <label>
                    You need a Generator Sign Name.
                  </label>
                </div>
              </div>
              <div
                class="incomplete-section-list"
                v-if="!completeStop.sorting_completed"
              >
                <div
                  class="center-section2-label d-flex"
                >
                  <div class="close-sm-icon" />
                  <label>
                    You have unconsolidated approvals or items
                  </label>
                </div>
              </div>
              <div
                class="incomplete-section-list"
                v-if="!completeStop.manifest_number"
              >
                <div
                  class="center-section2-label d-flex"
                >
                  <div class="close-sm-icon" />
                  <label>
                    There are manifests with unassigned ID numbers
                  </label>
                </div>
              </div>
              <div
                class="incomplete-section-list"
                v-if="!completeStop.qa_completed"
              >
                <div
                  class="center-section2-label d-flex align-center"
                >
                  <div class="qa-alert-img" /><label>Q&A</label>
                </div>
              </div>

              <div
                class="incomplete-section-list"
                v-if="!completeStop.customer_labels_printed"
              >
                <div class="center-section2-label d-flex align-center">
                  <div class="qa-alert-img" />
                  <label
                    @click="navigateToCustomerLabels"
                  >Customer labels not printed</label>
                </div>
              </div>
              <div class="status-col2" />
            </div>
          </div>
        </div>
        <div class="complete-stop-btn-groups d-flex">
          <div>
            <button
              class="cancel"
              @click="closeDialog()"
            >
              Cancel
            </button>
          </div>
          <div class="complete-stop-btn">
            <button
              class="complete-stop"
              :class="{
                'complete-stop-disabled':
                  disableCompleteStopButton && disableBtnWastePickUp,
              }"
              :disabled="disableCompleteStopButton && disableBtnWastePickUp"
              @click="complete()"
            >
              Complete stop
            </button>
          </div>
          <div
            class="inc-customer-lbl"
            v-if="!completeStop.customer_labels_printed && customerLabelPrintWarning"
          >
            Are you sure you want to Complete stop without printing customer
            labels?
            <br>
            Select "Complete stop" again to complete this pickup without printing any customer labels.
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import stopSummaryTable from '../stop-summary-table/stopSummaryTable.vue';
import connectedStopConstant from '../../../constants/connected-stop';
import CompleteStopWeightInfo from './completeStopWeightInfo.vue';

export default {
  name: 'CompleteStopConfirmationDialog',
  components: {
    stopSummaryTable,
    CompleteStopWeightInfo,
  },
  computed: {
    ...mapGetters({
      completeStop: 'getCompleteStop',
      stopDetail: 'getStopDetail',
      voidedStop: 'getStopVoidStatus',
      showFirstTimeNoticeFlag: 'getFirstTimeNoticeFlag',
      getRetailSisTotalWeight: 'getRetailSisTotalWeight',
      getTruckSisTotalWeight: 'getTruckSisTotalWeight',
    }),
    knownProfiles: {
      get() {
        return this.$store.state.stopDetail.stopSummaryKnownProfiles;
      },
      set(value) {
        return value;
      },
    },
    potentialProfiles: {
      get() {
        return this.$store.state.stopDetail.stopSummaryPotentialProfiles;
      },
      set(value) {
        return value;
      },
    },
    checkSortingAndGeneratorSignNameCompleteStatus() {
      return this.completeStop.sorting_completed
             && this.completeStop.generator_signed && this.completeStop.manifest_number
             && this.completeStop.qa_completed;
    },
    disableCompleteStopButton() {
      return !this.completeStop.sorting_completed
            || !this.completeStop.generator_signed
            || !this.completeStop.manifest_number;
    },
    disableBtnWastePickUp() {
      return !this.btnActiveGenerator && !this.btnActiveOnSite;
    },
    isAccumulationPickupData() {
      return this.stopDetail.connected_status === connectedStopConstant
        .status.ACCUMULATION_DATA_PRESENT;
    },
  },
  data() {
    return {
      dialog: false,
      btnActiveGenerator: false,
      btnActiveOnSite: false,
      customerLabelPrintWarning: false,
      expectedProfileLabel: 'Expected profiles',
      sisUndeterminedLabel: 'Profiles where an exact determination could not be made by the SiS',
    };
  },
  created() {
    this.$store.dispatch('setFirstTimeNoticeFlag', this.stopDetail.id);
  },
  methods: {
    closeDialog() {
      this.customerLabelPrintWarning = false;
      this.$emit('close', false);
    },
    // eslint-disable-next-line consistent-return
    complete() {
      if (!this.completeStop.customer_labels_printed && !this.customerLabelPrintWarning) {
        this.customerLabelPrintWarning = true;
        return true;
      }
      const params = {
        stopId: this.stopDetail.id,
        reason: null,
      };
      if (this.btnActiveGenerator) {
        params.reason = 'generator_declined';
      } else {
        params.reason = 'no_waste_on_site';
      }
      this.$store.dispatch('completeStopAction', params).then(() => {
        this.$store.commit('setStopInventoryLists', []);
        this.$store.commit('setApprovalList', {});
        this.$store.commit('resetApprovalDetail');
        this.$store.commit('consolidate/resetConsolidation');
        this.$store.commit('resetApprovalDetail');
        this.$store.commit('resetScaleWeight');
        this.$store.commit('resetConsolidateApproval');
        this.$store.commit('alerts/setAlertsData', []);
        this.$store.commit('trip/setFirstLogin', true);
        this.$store.commit('attachment/resetPrintedDocumentList');
        this.$store.commit('attachment/resetPrintedDocumentAndAttachmentMap');
        this.$store.commit('stopLabels/updateLabelReprintStatus', false);
        this.$store.commit('stopDocuments/updateDocumentReprintStatus', false);
        this.$router.push({
          name: 'Loggedin Home',
        });
      });
    },
    confirmCompleteStop(type) {
      if (type === 'generator-declined') {
        this.btnActiveGenerator = true;
        this.btnActiveOnSite = false;
      } else {
        this.btnActiveOnSite = true;
        this.btnActiveGenerator = false;
      }
    },
    navigateToCustomerLabels() {
      this.closeDialog();
      this.$router.push({
        path: `/stops/detail/${this.stopDetail.id}/print-label`,
        query: { initializeLabel: 'Customer labels' },
      });
    },
    retailSISTotalWeight() {
      return this.expectedProfileList
        .reduce((total, currentProfile) => total + currentProfile.retailSisWeight, 0.0)
          + this.sisUndeterminedProfileList
            .reduce((total, currentProfile) => total + currentProfile.retailSisWeight, 0.0);
    },
    truckSISTotalWeight() {
      return this.expectedProfileList
        .reduce((total, currentProfile) => total + currentProfile.truckSisWeight, 0.0)
          + this.sisUndeterminedProfileList
            .reduce((total, currentProfile) => total + currentProfile.truckSisWeight, 0.0);
    },
    weightDifferenceOver10Percent() {
      return (Math.abs(this.getTruckSisTotalWeight - this.getRetailSisTotalWeight)
      / this.getRetailSisTotalWeight) > 0.1;
    },
  },
  props: {
    openDialog: Boolean,
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
    voidedStop(newVal) {
      if (newVal) {
        this.dialog = false;
      }
    },
  },
};
</script>
