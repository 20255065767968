<template>
  <span

    class="tooltip-top"
  >
    {{ errorMessage }}
  </span>
</template>

<script>


export default {
  name: 'PinToolTip',

  props: {
    errorMessage: {
      type: String,
      default: '',
    },
  },


};
</script>
