const camSocketConnection = {};
let cam1Image;

function getUrl() {
  const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
  const wsHostname = window.location.hostname;
  const wsPort = 13254;
  return `${wsProtocol}://${wsHostname}:${wsPort}`;
}

camSocketConnection.install = (vue, options) => {
  const Vue = vue;
  let ws = new WebSocket(options.url);


  Vue.prototype.$camSocketsConnect = () => {
    ws = new WebSocket(options.url);

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (options.store.state.masterScan.isStreaming) {
        cam1Image = data.cam0;
        options.store.commit('masterScan/setPhotoUrl', cam1Image);
      }
    };

    ws.onerror = () => {
      ws.close();
    };
  };

  Vue.prototype.$camSocketsDisconnect = () => {
    ws.close();
  };

  Vue.prototype.$camSocketsSend = (data) => {
    ws.send(JSON.stringify(data));
  };
};

export default {
  camSocketConnection,
  getUrl,
};
