//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'TripSummaryComponent',
  computed: {
    ...mapGetters({
      url: 'trip/getTripDetailPdfUrl',
    }),
  },
  methods: {
    printTripSummary() {
      const payload = {
        preview: false,
        tripId: this.tripId,
      };
      this.$store.dispatch('trip/printTripsDetail', payload);
    },
  },
  props: {
    tripId: {
      type: String,
      default: '',
    },
  },
};
