//
//
//
//
//
//
//
//

export default {
  name: 'PinButton',
  methods: {
    pinButtonClick() {
      this.$emit('inputButtonChange', this.inputDigit);
    },
  },
  props: {
    inputDigit: {
      type: String,
      default: '',
    },

  },
};
