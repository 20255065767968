<template>
  <div class="col ml-2 container-select">
    <div class="row container-sub-head margin-bottom-4">
      <label>
        Quantity
      </label>
    </div>
    <div class="row">
      <div class="container-qty-group">
        <v-btn
          class="pa-2 qty-group-prepend"
          :disabled="disableQuantityField"
          tile
          outlined
          color="blue-grey lighten-4"
          @click="decreaseQuantity()"
        >
          <em
            class="fas fa-minus"
            :class="{
              'color-grey-medium': !disableQuantityField,
              'color-light-grey6': disableQuantityField
            }"
          />
        </v-btn>
        <input
          type="text"
          class="input fs-16em qty-input text-center"
          v-model="quantity"
          :disabled="disableQuantityField"
          @click.prevent="checkDialog('numeric','quantity','quantity',quantity.toString());"
          @selectstart.prevent
          @paste.prevent
          @copy.prevent
          @cut.prevent
          @drag.prevent
          @drop.prevent
        >
        <v-btn
          class="pa-2 qty-group-append"
          :disabled="disableQuantityField"
          tile
          outlined
          color="blue-grey lighten-4"
          @click="increaseQuantity()"
        >
          <em
            class="fas fa-plus"
            :class="{
              'color-grey-medium': !disableQuantityField,
              'color-light-grey6': disableQuantityField
            }"
          />
        </v-btn>
      </div>
    </div>
    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :max-length="maxLength"
      :model-value="modelValue"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @enterClicked="enterClicked"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import KeyboardDialog from '../virtual-keyboard/virtualKeyboardDialog.vue';

export default {
  name: 'ContainerQuantity',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters(['getSelectedApproval', 'getSelectedInventoryApproval']),
    disableQuantityField() {
      return (!this.getSelectedInventoryApproval.id && !this.getSelectedApproval.id)
            || this.hasCustomerSpecificBilling;
    },
  },
  data() {
    return {
      input: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      quantityItem: this.quantity,
      maxLength: '2',
      modelValue: '',
    };
  },
  methods: {
    increaseQuantity() {
      if (this.quantity < 100) {
        this.$store.dispatch('container/increaseQuantityAction', { index: this.containerIndex });
      }
    },
    decreaseQuantity() {
      if (this.quantity > 1) {
        this.$store.dispatch('container/decreaseQuantityAction', { index: this.containerIndex });
      }
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.quantityItem = value.input;
      if (this.quantityItem > 0 && this.quantityItem < 100) {
        this.$store.dispatch('container/setContainerQuantityTypeAction', { index: this.containerIndex, quantity: parseInt(this.quantityItem, 10) });
      }
    },
  },
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    lastIndex: {
      type: Boolean,
      default: false,
    },
    containerIndex: {
      type: Number,
      default: 0,
    },
    hasCustomerSpecificBilling: {
      default: false,
      type: Boolean,
    },
  },
};
</script>
