//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import MobileRxApprovalCard from '../rx-approval-card/index-mobile.vue';

export default {
  name: 'MobileRxApprovalList',
  components: {
    MobileRxApprovalCard,
  },
  computed: {
    ...mapGetters({
      approvalList: 'rxApprovalList/getRxApprovalList',
      pListedBottleStatus: 'rxApprovalList/getPListedBottleStatus',
      emptyBottleDetails: 'rxApproval/getMobileRxEmptyBottleDetails',
    }),
  },

  methods: {
    goToApprovalDetails() {
      this.$store.commit('rxApproval/setSelectedRxItem', this.emptyBottleDetails);
      this.$router.push({ name: 'Mobile View Item Details' });
    },
  },
};
