//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'PListedWeightWarning',
  computed: {
    ...mapGetters({
      pListedApprovals: 'consolidate/getPListedApprovalList',
      stopPListedWeightError: 'consolidate/getStopPListedWeightError',
    }),
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    gotIt() {
      this.dialog = false;
      this.$store.commit('consolidate/setStopPListedWeightError', false);
      this.$emit('proceedToConsolidate', this.consolidateType);
    },
  },
  props: {
    consolidateType: {
      type: String,
      default: '',
    },
  },
  watch: {
    stopPListedWeightError: {
      deep: true,
      handler(val) {
        if (val) {
          const stopId = this.$store.getters.getStopDetail.id;
          this.$store.dispatch('consolidate/getPListedApprovalList', stopId);
        }
        this.dialog = val;
      },
    },
  },
};
