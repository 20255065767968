function showToastErrorMessage(
  commit, messageData, messageType, isCustomToast = false, flagType = 0,
) {
  commit(
    {
      type: 'toastMessage/setMessage',
      message: {
        message: messageData,
        type: messageType,
        customToast: isCustomToast,
        flagType,
      },
    },
    { root: true },
  );
}

function showToastAppUpdateMessage(
  commit, messageData, messageType, isCustomToast = true, flagType = 3,
) {
  commit(
    {
      type: 'toastMessage/setMessage',
      message: {
        message: messageData,
        type: messageType,
        customToast: isCustomToast,
        flagType,
      },
    },
    { root: true },
  );
}

const state = {
  message: {},
};

const getters = {
  getMessage(stateControl) {
    return stateControl.message;
  },
};
const mutations = {
  setMessage(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.message = payload.message;
  },
};
const actions = {
  showMessage({ commit }, messageData) {
    showToastErrorMessage(commit, messageData.message, messageData.type, messageData.isCustom);
  },
  showAppUpdatedMessage({ commit }, messageData) {
    showToastAppUpdateMessage(commit, messageData.message, messageData.type);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  showToastErrorMessage,
  showToastAppUpdateMessage,
  actions,
};
