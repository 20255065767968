import firestoreDB from './firestoreDB.service';

function getAllTrips(commit) {
  const user = JSON.parse(localStorage.getItem('user'));
  const thresholdTime = new Date();
  thresholdTime.setUTCDate(thresholdTime.getUTCDate() - 30);

  firestoreDB.db.collection('trips')
    .where('user_cloud_id', '==', user.doc_id)
    .where('created_at', '>=', thresholdTime)
    .orderBy('created_at', 'desc')
    .onSnapshot((result) => {
      const docs = firestoreDB.queryToArray(result);

      if (docs.length <= 0) {
        commit('tripListFailure', 'Failed to fetch');
      } else {
        commit('setTripList', { tripList: docs });
      }
    });
}
const tripService = {
  getAllTrips,
};
export default tripService;
