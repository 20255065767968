//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import pinDialog from '../../helpers/component/pin-dialog/pinDialog.vue';
import technicianLastEmail from '../../helpers/component/technician-last-email/technicianLastEmail.vue';

export default {
  name: 'ForgotPin',
  components: {
    technicianLastEmail,
    pinDialog,
  },
  computed: {
    ...mapGetters({ loginEmail: 'authentication/getLoggedEmail' }),
    ...mapGetters({ loginStatus: 'authentication/getloginStatus' }),
  },
  data() {
    return {
      form: {
        email: '',
      },
      emailSendStatus: true,
      hasError: false,
      errorMessage: '',
      pinDialogIsOpen: false,
      resendPinEmail: false,
    };
  },
  beforeMount() {
    this.resendEmail('first');
    this.$store.dispatch('authentication/updateRecentTechnician', '');
  },
  methods: {
    submit() {
      this.$v.form.email.$touch();
      if (this.$v.form.email.required) {
        this.$store.dispatch('addActionEmail', this.form.email).then((res) => {
          this.errorMessage = '';
          if (res && res.length > 0) {
            this.emailSendStatus = true;
          } else {
            this.errorMessage = 'Email does not exist';
          }
        });
      }
    },
    backNav() {
      this.$router.go(-1);
    },
    enterNewPin() {
      this.pinDialogIsOpen = true;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    resendEmail(type) {
      if (this.loginEmail) {
        const data = {
          email: this.loginEmail,
        };
        this.$store.dispatch('resendPinEmail', data).then((res) => {
          this.errorMessage = '';
          if (res && res.user && type !== 'first') {
            this.resendPinEmail = true;
          } else {
            this.resendPinEmail = false;
            this.errorMessage = 'Email does not exist';
          }
        });
      }
    },
  },
  validations: {
    form: {
      email: { required },
    },
  },
  watch: {
    loginStatus(newVal) {
      if (newVal === true) {
        this.$router.push('logged-in-home');
      }
    },
  },
};
