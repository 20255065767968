<template>
  <div class="mobile-login-main-div">
    <div v-if="enterPin === false">
      <div class="text-center mobile-margin-top-45p">
        <p class="mobile-login-h1">
          Welcome, sign in.
        </p>
      </div>
      <div>
        <p class="mobile-form-label">
          Work email address
        </p>
        <div
          v-if="submitted && $v.form.email.$error"
          class="mobile-email-error"
        >
          <span
            v-if="submitted && !$v.form.email.required"
          >
            <span class="forgot-font-weight">!</span>
            &nbsp; &nbsp;Email is required
          </span>
          <span
            v-if="submitted && !$v.form.email.email"
          >
            <span class="forgot-font-weight">!</span>
            &nbsp; &nbsp;Email is invalid
          </span>
        </div>
        <input
          type="text"
          class="mobile-form-input-field"
          v-model="form.email"
          :class="{ 'hasError': (submitted && $v.form.email.$error || emailExistStatus === false)}"
          @keyup.enter="submit"
        >
      </div>
      <div class="text-center mt-8">
        <button
          class="mobile-form-submit-btn-valid"
          @click="submit()"
        >
          Next
        </button>
        <p
          class="mobile-email-exist-error"
          v-if="emailExistStatus === false"
        >
          Email not found
        </p>
      </div>
    </div>
    <div v-if="enterPin === true">
      <div class="text-center mobile-margin-top-45p mobile-container">
        <p class="mobile-email-name">
          {{ form.email }}
        </p>
        <p class="mobile-enter-your-pin">
          Enter your PIN.
        </p>
        <div class="margin-top-80">
          <form>
            <input
              type="number"
              class="mobile-login-pin-input1"
              v-model="pin1"
              autofocus
              @focus="clearPin1"
              @input="focusToPin2"
              ref="pin1"
            >
            <input
              type="number"
              class="mobile-login-pin-input2"
              v-model="pin2"
              @focus="clearPin2"
              @input="focusToPin3"
              ref="pin2"
            >
            <input
              type="number"
              class="mobile-login-pin-input3"
              v-model="pin3"
              @focus="clearPin3"
              @input="focusToPin4"
              ref="pin3"
            >
            <input
              type="number"
              class="mobile-login-pin-input4"
              v-model="pin4"
              @focus="clearPin4"
              @input="focusToEnd"
              ref="pin4"
            >
            <br>
            <br>
            <button
              class="mobile-form-submit-btn-valid"
              type="button"
              ref="enterButton"
              :disabled="pin1 === '' || pin2 === '' || pin3 === '' || pin4 === ''"
              @click.prevent="login()"
            >
              Login
            </button>
            <p
              class="mobile-email-exist-error"
              v-if="loginStatusMobile === false"
            >
              Incorrect PIN
            </p>
          </form>
        </div>
      </div>
      <button
        class="mobile-login-back"
        @click="goBack()"
      >
        Back
      </button>
    </div>
  </div>
</template>
<script>
import {
  required, email,
} from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

export default {
  name: 'LoginMobileComponent',
  computed: {
    ...mapGetters({ emailExist: 'authentication/getEmailExist', mobileLoginStatus: 'authentication/getMobileLoginStatus' }),
  },
  data() {
    return {
      form: {
        email: '',
      },
      submitted: false,
      enterPin: false,
      pin1: '',
      pin2: '',
      pin3: '',
      pin4: '',
      emailExistStatus: true,
      loginStatusMobile: Boolean,
    };
  },
  beforeMount() {
    if (localStorage.getItem('mobileLoginEmail')) {
      this.enterPin = true;
      this.form.email = localStorage.getItem('mobileLoginEmail');
    }
  },
  created() {
    if (this.$route.query.redirect) {
      const currentEmail = localStorage.getItem('mobileLoginEmail');
      this.form.email = currentEmail;
      this.enterPin = true;
    }
  },
  methods: {
    submit() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$store.dispatch('authentication/checkEmailAction', {
        email: this.form.email,
      });
    },
    login() {
      this.$store.dispatch('authentication/loginAction', {
        email: this.form.email,
        password: this.pin1 + this.pin2 + this.pin3 + this.pin4,
      });
    },
    focusToPin2() {
      this.$refs.pin2.focus();
    },
    focusToPin3() {
      this.$refs.pin3.focus();
    },
    focusToPin4() {
      this.$refs.pin4.focus();
    },
    focusToEnd() {
      this.$refs.pin4.blur();
    },
    clearPin1() {
      this.pin1 = '';
    },
    clearPin2() {
      this.pin2 = '';
    },
    clearPin3() {
      this.pin3 = '';
    },
    clearPin4() {
      this.pin4 = '';
    },
    goBack() {
      this.enterPin = false;
    },
  },
  validations: {
    form: {
      email: {
        required, email,
      },
    },
  },
  watch: {
    emailExist(newVal) {
      this.emailExistStatus = newVal;
      this.enterPin = newVal;
    },
    mobileLoginStatus(newVal) {
      this.loginStatusMobile = newVal;
      if (newVal === false) {
        this.pin1 = ''; this.pin2 = ''; this.pin3 = ''; this.pin4 = '';
      }
      if (newVal === true) {
        localStorage.setItem('mobileLoginEmail', this.form.email);
        this.$router.push({ name: 'Mobile Trip List' });
      }
    },
  },
};
</script>
