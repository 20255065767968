<template>
  <div class="width-100p stop-instruction-content height-100p">
    <div class="d-flex flex-column width-100p height-100p mt-10 position-relative">
      <div class="d-flex justify-center align-center fs-3em">
        <img
          class="success-response mt-13 mb-6"
          src="src/assets/images/check-full-green.svg"
          alt="checkfullgreen"
          v-if="upcScanResult.status === 'success'"
        >
        <em
          class="fas fa-exclamation-triangle mt-13 mb-6"
          v-else
        />
      </div>
      <div>
        <div
          class="d-flex flex-column justify-center upc-response-label"
          :class="upcScanResult.status === 'success' ? 'mb-8' : 'mb-4'"
        >
          <label>{{ responseType.label }}</label>
          <template
            v-if="upcScanResult.productInfo.approvalName"
          >
            {{ upcScanResult.productInfo.approvalName }}
          </template>
        </div>
        <div
          class="mobile-active-stop-warning-title mx-4 text-center"
          v-if="upcScanResult.status !== 'success'"
        >
          {{ responseType.content }}
        </div>
        <div
          class="upc-product mt-13"
          v-if="upcScanResult.status !== 'unknownItem'"
        >
          <div class="mb-4">
            <label>Product info</label>
          </div>
          <div class="d-flex flex-column mb-4">
            <label class="upc-label-title">Product name</label>
            <span>{{ upcScanResult.productInfo.productName }}</span>
          </div>
          <template v-if="upcScanResult.productInfo.approvalName">
            <div class="d-flex flex-column mb-4">
              <label class="upc-label-title">Approval</label>
              <span>{{ upcScanResult.productInfo.approvalCode }}</span>
            </div>
          </template>
          <div class="d-flex flex-column mb-4">
            <label class="upc-label-title">UPC</label>
            <span>{{ upcScanResult.productInfo.upc }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="upcScanResult.status === 'unknownItem'"
        class="d-flex justify-center align-center mt-16 mb-6"
      >
        <button
          block
          dark
          class="mobile-stop-warn-got-it btn-green"
          @click="done"
        >
          Done
        </button>
      </div>
      <div
        class="d-flex justify-space-between align-center upc-btn-group"
        v-else
      >
        <button
          block
          dark
          class="view-tds"
          @click="done"
        >
          <img
            class="sds-icon mr-1 mb-n1"
            src="src/assets/images/sds-icon.svg"
            alt="sds"
          >
          View SDS
        </button>
        <button
          block
          dark
          class="btn-green"
          @click="done"
        >
          Done
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ScanResponse',
  computed: {
    ...mapGetters({
      stopDetail: 'getStopDetail',
      upcScanResult: 'getUpcScanResponse',
    }),
    responseType() {
      const respType = {};
      const { status } = this.upcScanResult;
      if (status === 'success') {
        respType.label = 'Scan success.';
      } else if (status === 'unknownItem') {
        respType.label = 'Unknown item';
        respType.content = 'We don’t have this item in our system yet. Scan it on the TruckSiS or call your National Account Representative for next steps.';
      } else {
        respType.label = 'No approval found.';
        respType.content = 'No qualifying approval for this generator please call your national account rep';
      }
      return respType;
    },
  },
  methods: {
    done() {
      this.$router.push({ path: 'stop-detail' });
    },
  },
};
</script>
