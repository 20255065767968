//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Confirmation',
  components: {},

  computed: {
    dialogProps() {
      return {
        '--modal-height': `${this.dialogProperty.modalHeight}px`,
        '--modal-width': `${this.dialogProperty.modalWidth}px`,
      };
    },
  },

  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    closeCancellationAction() {
      this.$emit('cancel');
    },
    confirmAction() {
      this.$emit('confirm');
    },
  },
  props: {
    openDialog: Boolean,
    dialogData: {
      type: Object,
      heading: String,
      subHeading: String,
      description: String,
      default() {
        return {};
      },
    },
    buttonData: {
      type: Object,
      buttonConfirmText: String,
      buttonCancelText: String,
      buttonConfirmType: String,
      buttonCancelType: String,
      buttonCancelVisibility: Boolean,
      buttonConfirmVisibility: Boolean,
      default() {
        return {};
      },
    },

    dialogProperty: {
      type: Object,
      default() {
        return {
          modalWidth: 936,
        };
      },
    },
  },

  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
  },
};
