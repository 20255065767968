<template>
  <div>
    <div
      class="mobile-qa-sub-header-blocker"
      @click="goBack()"
    >
      <span> <i class="fas fa-chevron-left" /> &nbsp; Q & A</span>
    </div>
    <div class="mobile-stop-questionnaire">
      <div class="">
        <label class="mobile-stop-questionaire">Stop questionnaire</label>
      </div>
      <div
        class="margin-top-20"
        v-if="survey"
      >
        <survey :survey="survey" />
      </div>
      <div>
        <button
          class="mobile-complete-qa-btn"
          @click="goBack()"
        >
          Complete Q&A
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import SurveyVue from 'survey-vue';
import { mapGetters } from 'vuex';


const { Survey } = SurveyVue;

export default {
  name: 'MobileQAndAPage',
  components: {
    Survey,
  },
  computed: {
    ...mapGetters({
      stopDetailData: 'getStopDetail',
      questionDetail: 'questionnaire/getActiveQuestionDetails',
      questionList: 'questionnaire/getQuestionAndAnswerList',
    }),
  },
  data() {
    return {
      survey: null,
      stopCompleted: false,
    };
  },
  created() {
    const { stopDetail } = this.$store.state.stopDetail;
    this.stopCompleted = stopDetail.is_completed;
    this.$store.dispatch('questionnaire/getQuestionAndNumbersMobile', {
      stopId: this.stopDetailData.doc_id,
    });
  },
  methods: {
    questionOnValueChanged() {
      this.survey
        .onAfterRenderPage.add(() => {
          const node = document.querySelectorAll('.sv_row input');
          for (let i = 0; i < node.length; i += 1) {
            node[i].addEventListener('input', (ev) => {
              let { value } = ev.target;
              if (value > 25) {
                value = value.substring(0, 25);
              }
            });
          }
        });
      this.survey
        .onValueChanged
        .add((result, options) => {
          const stopQuestionId = options.name.match(/question(\d+)/i)[1];
          this.$store.commit('questionnaire/setQuestionNumber', stopQuestionId);
          const questionStatus = result.data;
          if (questionStatus && !this.questionDetail.id) {
            const selectedQuestionValues = this.survey.getValue(options.name);
            if (selectedQuestionValues && selectedQuestionValues.length > 1) {
              this.survey.setValue(options.name, [selectedQuestionValues[1]]);
            }
            if (!selectedQuestionValues
              || (selectedQuestionValues && selectedQuestionValues.length < 2)) {
              let answerType;
              if (selectedQuestionValues && (selectedQuestionValues[0] === 'Yes' || selectedQuestionValues[0] === 'No')) {
                [answerType] = selectedQuestionValues;
              } else {
                answerType = '';
              }
              const params = {
                stop_id: this.stopDetailData.doc_id,
                id: stopQuestionId,
                answer_text: answerType,
              };
              this.updateQuestionAndAnswer(params);
            }
          } else if (questionStatus && this.survey.getValue(options.name)) {
            const params = {
              stop_id: this.stopDetailData.doc_id,
              id: stopQuestionId,
              answer_text: this.survey.getValue(options.name),
            };
            this.updateQuestionAndAnswer(params);
          }
        });
    },
    onAfterPageRender() {
      this.survey.onAfterRenderPage.add(() => {
        const rowElements = this.$el.querySelectorAll('.sv_row');
        rowElements.forEach((el, i) => {
          const newItem = document.createElement('div');
          const textNode = document.createTextNode(`Question ${i + 1} of ${rowElements.length}`);
          newItem.classList.add('mobile-question-number');
          newItem.appendChild(textNode);
          el.insertBefore(newItem, el.childNodes[0]);
        });
      });
    },
    goBack() {
      this.$router.push('/mobile/stop-detail');
    },
    updateQuestionAndAnswer(params) {
      this.$store.dispatch('questionnaire/updateQuestionnaireActionMobile', {
        updateParams: params,
        stopCompleted: this.stopCompleted,
      });
    },
  },
  watch: {
    questionList: {
      deep: true,
      handler(val) {
        this.survey = new SurveyVue.Model(val);
        this.questionOnValueChanged();
        this.onAfterPageRender();
      },
    },
  },
};
</script>
