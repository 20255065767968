<template>
  <div class="card-shadow card-detail-bg card-system-diagnostics">
    <h1>System diagnostics</h1>

    <form
      @submit.prevent="submit"
      class="mt-2"
    >
      <div class="diagnostic-group">
        <h3>Internet connectivity check</h3>
        <div class="diagnostic-instructions">
          <ul>
            <li>
              Press <strong>Check internet connection</strong> and if
              successfully connected you will receive a
              <strong>Success</strong> response.
            </li>
            <li>
              If the connection fails, check that the Cradlepoint has
              connection and has signal strength bars and that the network
              cable is securely plugged in to the monitor.
            </li>
            <li>
              If you're unable to resolve a connection issue, please
              contact Smarter Sorting support.
            </li>
          </ul>
        </div>
        <button
          type="button"
          @click="checkConnectivity()"
          class="small-diagnostic-button"
          v-if="!lastConnectivityCheckWasSuccessful
            && !displayConnectivitySpinner
            && !connectivityCheckInProgress"
        >
          Check internet connection
        </button>
        <div
          id="connectivity-test-feedback"
          :class="{ 'invisible': !connectivityCheckHasExecuted }"
        >
          <div
            :class="{ 'hidden': !(displayConnectivitySpinner || connectivityCheckInProgress) }"
          >
            Checking connection
            <img
              src="src/assets/images/loader.png"
              alt="loading-icon"
              class="loading-spinner mt-2"
            >
          </div>
          <div
            id="connectivity-test-text"
            :class="{
              'error-text': !lastConnectivityCheckWasSuccessful,
              'hidden': hideConnectivityResults,
              'diagnostic-success': lastConnectivityCheckWasSuccessful,
            }"
          >
            {{ form.connectivityTestResults }}
          </div>
        </div>
      </div>

      <div class="diagnostic-group">
        <h3>Zebra label printer</h3>

        <div class="diagnostic-instructions">
          <ul>
            <li>
              Confirm that the <strong>Label Printer</strong> is connected, switched on and
              <strong>check that the LED light is green</strong>.
            </li>
            <li>
              Press the label <strong>feed button</strong>
              on the printer to ensure that labels are aligned.
            </li>
            <li>
              If pressing <strong>Print test label</strong> on the screen
              below doesn't print a test label, check the USB and power
              connections on the printer and try again. If you're unable to successfully
              print a label, please contact Smarter Sorting support.
            </li>
          </ul>
        </div>

        <button
          type="button"
          @click="printTestLabel()"
          class="small-diagnostic-button"
          v-if="!zebraPrintStarted"
        >
          Print test label
        </button>
        <div class="diagnostic-instructions">
          <ul>
            <li>
              Please print out the serial number of your device and <strong>apply the label
                to the back of the monitor</strong>.
            </li>
          </ul>
        </div>
        <button
          type="button"
          @click="printSerialNumber()"
          class="small-diagnostic-button"
          v-if="!zebraPrintStarted"
        >
          Print serial number
        </button>

        <div
          class="confirmation-feedback"
          v-if="zebraPrintStarted && !form.zebraTestLabelPrinted"
        >
          <span>Attempting to print...</span>
          <br>
          <button
            type="button"
            @click="zebraPrintVerified()"
            class="small-diagnostic-button button-success"
          >
            Label printed
          </button>

          <button
            type="button"
            @click="zebraPrintFailed()"
            class="small-diagnostic-button button-failure"
          >
            Nothing printed
          </button>
        </div>

        <div
          class="print-complete diagnostic-success"
          v-if="form.zebraTestLabelPrinted"
        >
          Success
        </div>
      </div>

      <div class="diagnostic-group">
        <h3>Zebra scan gun</h3>

        <div class="diagnostic-instructions">
          <ul>
            <li>
              Locate the <strong>test label</strong> printed from the label printer.
            </li>
            <li>
              Press <strong>Begin scan gun test</strong> on the screen below.
            </li>
            <li>
              Scan the printed UPC barcode on the test label.
            </li>
            <li>
              If scanning the UPC barcode on the test label doesn't result in
              a success message, please contact Smarter Sorting support.
            </li>
          </ul>
        </div>

        <button
          type="button"
          :disabled="disableZebraScan"
          @click="startZebraScan()"
          class="small-diagnostic-button"
          v-if="!form.zebraScanned"
        >
          Begin scan gun test
        </button>

        <div
          id="barcode-scan-loading"
          class="justify-center align-center"
          v-if="zebraScanStarted && !form.zebraScanned"
        >
          <img
            src="src/assets/images/loader.png"
            alt="loading-icon"
            class="loading-spinner"
          >
          <div
            class="d-flex flex-column scan-page-container"
          >
            <div class="scan-instruction-label">
              All systems go.
            </div>
            <img
              class="barcode-icon"
              src="src/assets/images/icon-barcode.svg"
            >
            <div class="scan-instruction-label">
              Ready to scan when you are.
            </div>
          </div>
        </div>

        <div
          class="zebra-scan-complete"
          v-if="form.zebraScanned"
        >
          <p>
            Barcode Read: {{ form.zebraScannedValue }}
          </p>
          <div class="diagnostic-success">
            Success
          </div>
        </div>
      </div>

      <div class="diagnostic-group">
        <h3>Epson dot matrix printer</h3>

        <div class="diagnostic-instructions">
          <ul>
            <li>
              Confirm that the dot matrix printer is connected, powered on and loaded with paper.
            </li>
            <li>
              Press <strong>Print dot matrix test document</strong>
              on the screen below.
            </li>
            <li>
              If pressing the print button results in no response from the printer:
              <ul>
                <li>
                  Check the printer USB and power connections
                </li>
                <li>
                  Confirm that the printer paper is properly loaded and aligned
                </li>
                <li>
                  If you're unable to successfully troubleshoot and print a test document,
                  please contact Smarter Sorting support.
                </li>
              </ul>
            </li>
          </ul>
        </div>

        <button
          type="button"
          @click="printDotMatrix()"
          class="small-diagnostic-button"
          v-if="!form.dotMatrixPrinted"
        >
          Print dot matrix test document
        </button>

        <div
          class="confirmation-feedback"
          v-if="dotMatrixPrintStarted && !form.dotMatrixPrinted"
        >
          <span>Attempting to print...</span>
          <br>
          <button
            type="button"
            @click="dotMatrixPrintVerified()"
            class="small-diagnostic-button  button-success"
          >
            Document printed
          </button>

          <button
            type="button"
            @click="dotMatrixPrintFailed()"
            class="small-diagnostic-button button-failure"
          >
            Nothing printed
          </button>
        </div>

        <div
          class="print-complete diagnostic-success"
          v-if="form.dotMatrixPrinted"
        >
          Success
        </div>
      </div>

      <div class="diagnostic-group">
        <h3>Epson wand document scanner</h3>

        <div class="diagnostic-instructions">
          <ul>
            <li>
              Confirm that the <strong>wand document scanner</strong>
              is powered on and has a
              <strong>solid white LED light</strong>.
            </li>
            <li>
              Gently insert a document into the paper slot.
            </li>
            <li>
              Press <strong>Begin scan test</strong> on the screen below.
            </li>
            <li>
              The scanner should feed the document through the scanner.
            </li>
            <li>
              If a document can't be successfully scanned,
              check the cable connection on the wand scanner.
              If unable to scan a document, please
              contact Smarter Sorting support.
            </li>
          </ul>
        </div>

        <button
          type="button"
          :disabled="disableEpsonScan"
          @click="startEpsonScan()"
          class="small-diagnostic-button"
          :class="{ 'input-disabled': disableEpsonScan }"
          v-if="!form.documentScanned"
        >
          Begin scan test
        </button>

        <div
          id="epson-scan-loading"
          v-if="epsonScanStarted && !form.documentScanned"
        >
          Waiting for scan
          <img
            src="src/assets/images/loader.png"
            alt="loading-icon"
            class="loading-spinner"
          >
        </div>
        <div
          id="epson-scanned-doc"
          v-if="form.documentScanned"
        >
          <iframe
            :src="scannedDocumentUrl"
            ref="scannedDocumentIframe"
            width="200"
            height="300"
          />
        </div>
        <div
          class="error-text"
          id="epson-scanner-error-text"
        >
          {{ epsonScanErrorText }}
        </div>

        <div
          class="confirmation-feedback"
          v-if="form.documentScanned && !form.documentScanVerified"
        >
          <button
            type="button"
            @click="documentScanVerified()"
            class="small-diagnostic-button button-success"
          >
            Document scanned
          </button>

          <button
            type="button"
            @click="documentScanFailed()"
            class="small-diagnostic-button button-failure"
          >
            Unsuccessful scan
          </button>
        </div>

        <div
          class="document-scan-success diagnostic-success"
          v-if="form.documentScanVerified"
        >
          Success
        </div>
      </div>

      <div class="diagnostic-group">
        <h3>Scale calibration</h3>

        <div class="diagnostic-instructions">
          <ul>
            <li>
              Locate the <strong>calibration weight</strong>
              that is included in the TruckSIS installation kit.
            </li>
            <li>
              Locate the <strong>weight label</strong> on the
              calibration weight and enter its value below.
            </li>
          </ul>
        </div>

        <div class="weight-input">
          <div class="sd-input">
            <input
              v-model="form.providedWeight"
              type="text"
              class="input"
              placeholder="Calibration weight (in lb)"
              autocomplete="nope"
              @click="openKeyboard(
                'providedWeight',
                'Provided Weight (in lb)',
                form.providedWeight
              )"
            >
          </div>

          <div class="reset-weight" />
        </div>

        <div
          class="zero-weight"
          v-if="form.providedWeight!=='' && !weightAtZero"
        >
          <div class="diagnostic-instructions">
            <ul>
              <li>
                <strong>Ensure there is nothing on the scale</strong>
                then press <strong>Zero weight</strong>.
              </li>
            </ul>
          </div>
          <button
            type="button"
            @click="zeroWeight()"
            class="small-diagnostic-button"
          >
            Zero weight
          </button>
          <p v-if="scaleCalFailed">
            Something went wrong. Let's try that again.
          </p>
        </div>

        <div
          class="calibrate-weight"
          v-if="displayCalibrateWeightTextAndButton"
        >
          <div class="diagnostic-instructions">
            <ul>
              <li>
                <strong>Place the calibration weight on the scale</strong> and then press
                <strong>Calibrate weight</strong>.
              </li>
            </ul>
          </div>

          <button
            type="button"
            @click="calibrateWeight()"
            class="small-diagnostic-button"
          >
            Calibrate weight
          </button>
        </div>

        <div
          class="waiting-on-weight"
          v-if="waitingOnWeight"
        >
          Working...
          <img
            src="src/assets/images/loader.png"
            alt="loading-icon"
            class="loading-spinner"
          >
        </div>

        <div
          class="calibrate-weight-success diagnostic-success"
          v-if="form.scaleWeight!==0.0"
        >
          Calibration successful
        </div>
      </div>

      <div class="diagnostic-group">
        <h3>Camera</h3>

        <div class="diagnostic-instructions">
          <ul>
            <li>
              <strong>Confirm that an item placed on the scale is visible and in focus</strong>.
            </li>
          </ul>
        </div>
        <div id="diagnostic-cam-image-container">
          <img :src="photoUrl">
        </div>

        <div
          class="camera-issue"
          v-if="cameraIssue"
        >
          <p>
            Please call <strong>(512) 593-2594</strong> for
            technical support.
          </p>
        </div>

        <div
          class="confirmation-feedback"
          v-if="!form.cameraVisible"
        >
          <button
            type="button"
            @click="cameraVisbilty(true)"
            class="small-diagnostic-button  button-success"
          >
            Visible
          </button>

          <button
            type="button"
            @click="cameraVisbilty(false)"
            class="small-diagnostic-button button-failure"
          >
            Nothing visible
          </button>
        </div>

        <div
          class="diagnostic-success"
          v-if="form.cameraVisible"
        >
          Success
        </div>
      </div>

      <div class="diagnostic-group">
        <h3>Light strip</h3>

        <div class="diagnostic-instructions">
          <ul>
            <li>
              <strong>Confirm that the button below toggles the
                LED strip light attached to the column
              </strong>.
            </li>
          </ul>
        </div>
        <button
          type="button"
          @click="triggerLEDFlash()"
          class="small-diagnostic-button"
          v-if="toggleLightButtonVisible"
          id="light-strip-toggle-button"
        >
          Toggle light strip
        </button>
        <div
          class="confirmation-feedback mt-2"
          v-if="lightTestInProgress"
        >
          <span>Triggering camera flash...</span>
          <img
            src="src/assets/images/loader.png"
            alt="loading-icon"
            class="loading-spinner mt-2 ml-2"
          >
        </div>
        <div
          v-if="lightResultsButtonsVisible && !lightTestInProgress"
          class="confirmation-feedback mb-4"
        >
          <br>
          <button
            type="button"
            @click="setLightStripWorks(true)"
            class="small-diagnostic-button button-success"
          >
            Light flashed
          </button>

          <button
            type="button"
            @click="setLightStripWorks(false)"
            class="small-diagnostic-button button-failure"
          >
            Light did not flash
          </button>
        </div>

        <div
          class="diagnostic-success mt-2"
          v-if="lightResultsTextVisible && form.lightStripWorks"
        >
          Success
        </div>
        <div
          class="mt-2 error-text"
          v-if="lightResultsTextVisible && !form.lightStripWorks"
        >
          <p>
            Please call <strong>(512) 593-2594</strong> for
            technical support.
          </p>
        </div>
      </div>

      <div
        class="diagnostic-group"
      >
        <h3>Cradlepoint IBR600c</h3>

        <div
          id="cradlepoint-info"
          v-if="form.cradlePointConfigued"
        >
          <div class="diagnostic-instructions">
            <p>
              Cradlepoint troubleshooting information:
            </p>
          </div>
          <table>
            <tr>
              <td>Cradlepoint Serial:</td>
              <td>{{ form.cradlePointSerial }}</td>
            </tr>
            <tr>
              <td>Verizon SIM Serial:</td>
              <td>{{ form.verizonSimSerial }}</td>
            </tr>
            <tr>
              <td>WiFi Network:</td>
              <td>{{ form.cradlePointWiFiSSID }}</td>
            </tr>
            <tr>
              <td>WiFi Password:</td>
              <td>{{ form.cradlePointWiFiPassword }}</td>
            </tr>
          </table>
        </div>

        <div
          id="cradlepoint-form"
          v-if="!form.cradlePointConfigued"
        >
          <div class="diagnostic-instructions">
            <p>
              Add the cradlepoint information below:
            </p>
          </div>
          <div class="sd-input">
            <input
              v-model="form.cradlePointSerial"
              type="text"
              class="input"
              placeholder="Cradlepoint Serial"
              autocomplete="nope"
              @click="openKeyboard(
                'cradlePointSerial',
                'Cradlepoint Serial',
                form.cradlePointSerial
              )"
            >
          </div>
          <div class="sd-input">
            <input
              v-model="form.verizonSimSerial"
              type="text"
              class="input"
              placeholder="Verizon SIM Serial"
              autocomplete="nope"
              @click="openKeyboard(
                'verizonSimSerial',
                'Verizon SIM Serial',
                form.verizonSimSerial
              )"
            >
          </div>
          <div class="sd-input">
            <input
              v-model="form.cradlePointWiFiSSID"
              type="text"
              class="input"
              placeholder="WiFi Network"
              autocomplete="nope"
              @click="openKeyboard(
                'cradlePointWiFiSSID',
                'WiFi Network',
                form.cradlePointWiFiSSID
              )"
            >
          </div>
          <div class="sd-input">
            <input
              v-model="form.cradlePointWiFiPassword"
              type="text"
              class="input"
              placeholder="WiFi Password"
              autocomplete="nope"
              @click="openKeyboard(
                'cradlePointWiFiPassword',
                'WiFi Password',
                form.cradlePointWiFiPassword
              )"
            >
          </div>

          <div class="diagnostic-group">
            <button
              type="submit"
              :disabled="disableSubmit"
              class="diagnostic-complete-button-submit"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>

    <!-- TODO: cradlepoint config replace form.cradlePointConfigued as a value for v-if -->
    <div
      class="diagnostic-group"
      v-if="formComplete"
    >
      <h3>
        This concludes the system diagnostics test.<br>
        If you have unresloved issues with any of the hardware peripherals,
        please contact Smarter Sorting support.<br><br>
        Thank you!
      </h3>
    </div>

    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="modelValue"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @enterClicked="enterClicked"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import KeyboardDialog from '../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import { API_BASE_URL } from '../../constants/index';
import scanBackendService from '../../service/scan.backend.service';
import printBackendService from '../../service/print.backend.service';
import connectivityService from '../../service/connectivity.service';
import barcodeScannerService from '../../service/barcodeScanner.service';
import settingService from '../../service/setting.service';

export default {
  name: 'SystemDiagnostic',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({
      testUpc: 'masterScan/getTestUpc',
      photoUrl: 'masterScan/getPhotoUrl',
      tareWeight: 'weight/getTareWeight',
      calConstant: 'weight/getCalConstant',
      rawTareWeight: 'weight/getRawTareWeight',
      rawWeight: 'weight/getRawWeight',
    }),
    hideConnectivityResults() {
      const noResults = this.connectivityCheckInProgress || !this.connectivityCheckHasExecuted;
      return noResults || this.displayConnectivitySpinner;
    },
    displayCalibrateWeightTextAndButton() {
      const atCalStep = this.form.providedWeight !== '' && this.weightAtZero;
      const calIncomplete = this.form.scaleWeight === 0.0;
      return atCalStep && calIncomplete;
    },
  },
  data() {
    return {
      // FOR KEYBOARD
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      capsLock: '',

      displayConnectivitySpinner: false,

      connectivityCheckInProgress: false,
      lastConnectivityCheckWasSuccessful: false,
      connectivityCheckHasExecuted: false,

      disableSubmit: false,
      disableZebraScan: false,
      zebraScanStarted: false,
      zebraPrintStarted: false,

      dotMatrixPrintStarted: false,

      disableEpsonScan: false,
      epsonScanStarted: false,
      scannedDocumentUrl: '',
      epsonScanErrorText: '',

      waitingOnWeight: false,
      weightAtZero: false,
      scaleCalFailed: false,

      cameraIssue: false,

      toggleLightButtonVisible: true,
      lightTestInProgress: false,
      lightResultsButtonsVisible: false,
      lightResultsTextVisible: false,

      formComplete: false,

      form: {
        cradlePointSerial: '',
        verizonSimSerial: '',
        cradlePointWiFiSSID: '',
        cradlePointWiFiPassword: '',
        cradlePointConfigued: false,
        zebraTestLabelPrinted: false,
        zebraScanned: false,
        zebraScannedValue: '',
        dotMatrixPrinted: false,
        documentScanned: false,
        documentScanVerified: false,
        connectivityTestResults: '',
        scaleWeight: 0.0,
        providedWeight: '',
        weightScaled: false,
        cameraVisible: false,
        lightStripWorks: false,
      },

      weightTared: false,
      submitted: false,
    };
  },
  created() {
    // NOTE: Clear out any cached UPC codes
    this.$store.state.masterScan.testUpc = '';

    settingService.fetchSetting('system_diagnostics')
      .then((res) => {
        const formValuesPresent = res.setting !== undefined
                                    && res.setting.details !== undefined
                                    && res.setting.details !== null;

        if (formValuesPresent) {
          const { details } = res.setting;

          this.form.cradlePointConfigued = true;
          this.form.cradlePointSerial = details.cradlePointSerial;
          this.form.verizonSimSerial = details.verizonSimSerial;
          this.form.cradlePointWiFiSSID = details.cradlePointWiFiSSID;
          this.form.cradlePointWiFiPassword = details.cradlePointWiFiPassword;
        }
      }).catch((error) => {
        // eslint-disable-next-line no-console
        console.log('error', error);
      });

    this.$camSocketsConnect();
    this.$scaleSocketsConnect();
    this.$store.dispatch('masterScan/setIsStreaming', true);
  },
  destroyed() {
    this.$scaleSocketsSend('off');
    this.$camSocketsDisconnect();
    this.$scaleSocketsDisconnect();
  },
  methods: {
    checkConnectivity() {
      this.connectivityCheckHasExecuted = true;
      this.connectivityCheckInProgress = true;
      this.toggleVariable('displayConnectivitySpinner');
      connectivityService.connectivityCheck().then(
        (res) => {
          this.lastConnectivityCheckWasSuccessful = res.connected;
          this.form.connectivityTestResults = res.connected ? 'Success' : 'No connection';
          this.connectivityCheckInProgress = false;
        },
        () => {
          this.connectivityCheckInProgress = false;
          this.lastConnectivityCheckWasSuccessful = false;
          this.form.connectivityTestResults = 'No connection';
        },
      );
    },
    toggleVariable(toBeToggled, duration = 1000, startingVal = false) {
      this.$data[toBeToggled] = !startingVal;
      setTimeout(() => {
        this.$data[toBeToggled] = startingVal;
      }, duration);
    },
    generateScannedDocumentUrl() {
      const cacheBuster = Date.now();
      this.scannedDocumentUrl = `${API_BASE_URL}stops/attachment/pdf/01#toolbar=0&navpanes=0&timestamp=${cacheBuster}`;
      this.$refs.scannedDocumentIframe.src = this.scannedDocumentUrl;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.form[value.modelName] = value.input;
    },
    triggerLEDFlash() {
      this.toggleLightButtonVisible = false;
      this.lightResultsTextVisible = false;
      this.toggleVariable('lightTestInProgress', 1500);
      this.lightResultsButtonsVisible = true;
      this.$scaleSocketsSend('on');
      setTimeout(() => { this.$scaleSocketsSend('off'); }, 1500);
    },
    setLightStripWorks(val) {
      this.lightResultsButtonsVisible = false;
      this.lightResultsTextVisible = true;
      if (!val) {
        this.toggleLightButtonVisible = true;
      }
      this.form.lightStripWorks = val;
    },
    openKeyboard(modelName, labelName, modelValue) {
      const localKeyboardType = (modelName === 'providedWeight' ? 'numeric' : 'alphaNumeric');

      this.pinDialogIsOpen = true;
      this.keyBoardType = localKeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    formErrors() {
      const errors = [];

      if (this.form.cradlePointSerial === '') {
        errors.push('Cradlepoint Serial');
      }

      if (this.form.verizonSimSerial === '') {
        errors.push('Verizon SIM Serial');
      }

      if (this.form.cradlePointWiFiSSID === '') {
        errors.push('Cradlepoint WiFi SSID');
      }

      if (this.form.cradlePointWiFiPassword === '') {
        errors.push('Cradlepoint Wifi Password');
      }

      return errors;
    },
    submit() {
      this.submitted = true;

      const errors = this.formErrors();
      if (errors.length > 0) {
        const errorsAsString = errors.join(', ');
        const errorMessageData = {
          message: `Missing required fields:<br/>${errorsAsString}`,
          type: 'error',
          isCustom: false,
        };
        this.$store.dispatch('toastMessage/showMessage', errorMessageData);

        return false;
      }

      return settingService.createSetting('system_diagnostics', this.form)
        .then(
          () => {
            this.form.cradlePointConfigued = true;
            this.disableSubmit = true;

            this.fireSuccessToast();
          },
        ).catch((error) => {
          const errorMessageData = {
            message: `Something went wrong while saving:<br/>${error}`,
            type: 'error',
            isCustom: false,
          };
          this.$store.dispatch('toastMessage/showMessage', errorMessageData);
        });
    },
    fireSuccessToast() {
      const successMessageData = {
        message: '<div class="toast-container-div">'
          + '<p class="toast-container-div-title">System Diagnostics Complete'
          + '</p><p class="toast-container-div-message">You can now leave '
          + 'this page and begin sorting!</p></div>',
        type: 'success',
      };
      this.$store.dispatch('toastMessage/showAppUpdatedMessage', successMessageData);
    },
    printTestLabel() {
      this.zebraPrintStarted = true;
      printBackendService.printTestLabel().then(
        () => {
          // Do nothing. The User selects a button let us know if it didn't print.
        },
      ).catch((error) => {
        const errorMessageData = {
          message: `Something went wrong while printing:<br/>${error}`,
          type: 'error',
          isCustom: false,
        };
        this.$store.dispatch('toastMessage/showMessage', errorMessageData);
      });
    },
    printSerialNumber() {
      printBackendService.printSerialNumber().then(
        () => {
          // Do nothing. The User selects a button let us know if it didn't print.
        },
      ).catch((error) => {
        const errorMessageData = {
          message: `Something went wrong while printing:<br/>${error}`,
          type: 'error',
          isCustom: false,
        };
        this.$store.dispatch('toastMessage/showMessage', errorMessageData);
      });
    },
    zebraPrintFailed() {
      this.zebraPrintStarted = false;
    },
    zebraPrintVerified() {
      this.form.zebraTestLabelPrinted = true;
    },
    startZebraScan() {
      this.zebraScanStarted = true;
      barcodeScannerService.startMonitoringScanGunInput();
    },
    printDotMatrix() {
      this.disableEpsonScan = false;
      this.dotMatrixPrintStarted = true;
      printBackendService.printTestDocument().then(() => {
        // Do nothing as print should have worked
      }).catch((error) => {
        const errorMessageData = {
          message: `Something went wrong while printing:<br/>${error}`,
          type: 'error',
          isCustom: false,
        };
        this.$store.dispatch('toastMessage/showMessage', errorMessageData);
      });
    },
    dotMatrixPrintFailed() {
      this.dotMatrixPrintStarted = false;
    },
    dotMatrixPrintVerified() {
      this.form.dotMatrixPrinted = true;
    },
    startEpsonScan() {
      const errorText = 'Make sure the wand scanner is powered with a solid white light and a document is loaded';

      this.form.documentScanned = false;
      this.epsonScanErrorText = '';
      this.epsonScanStarted = true;
      const scanPage = { page: 1 };
      scanBackendService.scanAttachment(scanPage).then(
        (res) => {
          setTimeout(this.generateScannedDocumentUrl, 1000);
          this.epsonScanStarted = false;
          if (res.success) {
            this.form.documentScanned = true;// When scan complete
          } else {
            this.epsonScanErrorText = errorText;
          }
        },
        () => {
          this.epsonScanStarted = false;
          this.epsonScanErrorText = errorText;
        },
      );
    },
    documentScanVerified() {
      this.form.documentScanVerified = true;
    },
    documentScanFailed() {
      this.form.documentScanned = false;
      this.epsonScanStarted = false;
    },
    zeroWeight() {
      this.scaleCalFailed = false;
      this.waitingOnWeight = true;
      setTimeout(() => {
        this.waitingOnWeight = false;
        this.weightAtZero = true;
      }, 1500);
      setTimeout(() => {
        this.$store.commit('weight/setRawTareWeight');
      }, 750);
    },
    calibrateWeight() {
      // TODO: Refactor this ugly method
      this.waitingOnWeight = true;
      setTimeout(() => {
        this.waitingOnWeight = false;
      }, 1500);
      setTimeout(() => {
        const providedWeight = parseFloat(this.form.providedWeight);
        const newCalConstant = this.calculateNewCalConstant(providedWeight);
        const calConstantIsValid = this.validateNewCalConstant(newCalConstant, providedWeight);

        if (calConstantIsValid) {
          // This causes the Success text to show up
          setTimeout(() => { this.form.scaleWeight = 1.2; }, 750);
          this.$store.dispatch('weight/setCalConstant', newCalConstant);
          this.$store.dispatch('weight/calculateScaledTareWeight', newCalConstant);
          this.$scaleSocketsSend(`new_cal_constant:${newCalConstant.toString()}`);
        } else {
          setTimeout(() => {
            this.scaleCalFailed = true;
            // This resets the test
            this.weightAtZero = false;
          }, 750);
        }
      }, 750);
    },
    calculateNewCalConstant(providedWeight) {
      let newConstant = (this.rawWeight - this.rawTareWeight) / providedWeight;
      newConstant = Math.round(newConstant);
      const newConstantIsANumber = !Number.isNaN(newConstant);
      if (newConstantIsANumber && newConstant > 0) {
        return newConstant;
      }
      return -1;
    },
    validateNewCalConstant(calConstant, providedWeight) {
      if (calConstant === -1) {
        return false;
      }
      const scaledTareWeight = this.rawTareWeight / calConstant;
      const errWindow = 0.1;
      let scaledWeight = this.rawWeight / calConstant;
      scaledWeight -= scaledTareWeight;
      const lessThanUpperBound = scaledWeight <= providedWeight + errWindow;
      const greaterThanLowerBound = scaledWeight >= providedWeight - errWindow;
      return lessThanUpperBound && greaterThanLowerBound;
    },
    cameraVisbilty(visible) {
      if (visible) {
        this.cameraIssue = false;
        this.form.cameraVisible = true;
      } else {
        this.cameraIssue = true;
      }
    },
  },
  watch: {
    testUpc(newVal) {
      if (newVal) {
        barcodeScannerService.stopMonitoringScanGunInput();
        this.zebraScanStarted = false;
        this.form.zebraScanned = true;
        this.form.zebraScannedValue = newVal;
      }
    },
    form: {
      handler() {
        const allValuesSuccess = this.form.cameraVisible
                              && this.lastConnectivityCheckWasSuccessful
                              && this.form.zebraTestLabelPrinted
                              && this.form.zebraScanned
                              && this.form.dotMatrixPrinted
                              && this.form.documentScanVerified
                              && this.form.lightStripWorks
                              && this.form.scaleWeight !== 0.0;

        if (allValuesSuccess) {
          this.formComplete = true;
          this.fireSuccessToast();
        }
      },
      deep: true,
    },
  },
};
</script>
