<template>
  <div>
    <technician-last-email
      @checkChild1Res="checkChild1Res"
      :check-highlight="highlightVal"
    />
    <div
      class="container container-top"
      v-if="storeRecentTech == false"
    >
      <div class="text-center pb-8">
        <h1 class="h1-heading">
          Sign in
        </h1>
      </div>
      <form
        class="width-349"
        @submit.prevent="submit"
      >
        <div :class="{ 'hasError': $v.form.email.$error }">
          <p class="login label">
            Work email address
            <pinToolTip
              v-if="$v.form.email.$error"
              :error-message="getEmailError()"
            />
          </p>
          <input
            v-model="form.email"
            type="text"
            name="email"
            class="input"
            autocomplete="nope"
            @click="checkDialog('alphaNumeric','email','Work email address',form.email)"
            @selectstart.prevent
            @paste.prevent
            @copy.prevent
            @cut.prevent
            @drag.prevent
            @drop.prevent
            v-unSelect
          >
        </div>
        <div class="pt-10 login-submit">
          <button
            type="submit"
            :disabled="$v.form.$error"
            class="btn-submit"
          >
            Next
          </button>
        </div>
      </form>
      <div class="text-center margin-top-219 create-new-account-link">
        <router-link :to="'/create-account'">
          Create a new account
        </router-link>
      </div>
      <pinDialog
        :open-dialog="pinDialogIsOpen"
        :email="form.email"
        @dialogInputChanged="dialogInputChanges"
      />
    </div>
    <div
      class="container container-top"
      v-if="storeRecentTech == true"
    >
      <div class="text-center pb-8">
        <h1 class="h1-heading">
          Welcome back {{ lastTechnician.name }}.
        </h1>
        <p class="you-ll-recieve-an-em margin-top-10 text-lowercase">
          {{ lastTechnician.email }}
        </p>
      </div>
      <div
        class="width-349 text-center"
      >
        <div class="">
          <button
            type="button"
            class="btn-submit"
            @click="signIn()"
          >
            Sign in
          </button>
        </div>
        <div class="pt-10">
          <button
            type="button"
            class="btn-back width-215p"
            @click="changeUser()"
          >
            Change user
          </button>
        </div>
      </div>
      <div class="text-center margin-top-190">
        <router-link :to="'/create-account'">
          Create a new account
        </router-link>
      </div>
      <pinDialog
        :open-dialog="pinDialogIsOpen"
        :email="form.email"
        @dialogInputChanged="dialogInputChanges"
      />
    </div>
    <KeyboardDialog
      :open-dialog="keyboardDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="modelValue"
      @dialogInputChanged="dialogInputChangesKeyboard"
      name="keyboard"
      @enterClicked="enterClicked"
    />
  </div>
</template>

<script>
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters, mapState } from 'vuex';
import pinDialog from '../../helpers/component/pin-dialog/pinDialog.vue';
import pinToolTip from '../../helpers/component/tool-tip/pinToolTip.vue';
import technicianLastEmail from '../../helpers/component/technician-last-email/technicianLastEmail.vue';
import KeyboardDialog from '../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';

export default {
  name: 'LoginComponent',
  components: {
    pinDialog,
    pinToolTip,
    technicianLastEmail,
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({ errorMessage: 'authentication/getError', loginStatus: 'authentication/getloginStatus', storeRecentTech: 'getRecentTech' }),
    ...mapState({ stateDetail: 'authentication/loggedIn' }),
    getLastEmail() {
      return this.$store.getters.getLastLogin;
    },
  },
  data() {
    return {
      form: {
        email: '',
        pin: '',
        lastEmail: 'null',
      },
      pinDialogIsOpen: false,
      welcomeBackTechnician: false,
      highlightVal: '',
      keyboardDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
    };
  },
  beforeMount() {
    this.checkRecentTech();
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$store.dispatch('createAccount/setDialogValue', false);
      this.pinDialogIsOpen = true;
    },

    signIn() {
      this.$store.dispatch('createAccount/setDialogValue', false);
      this.pinDialogIsOpen = true;
    },

    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },

    dialogInputChangesKeyboard(changes) {
      this.keyboardDialogIsOpen = changes;
    },

    getEmailError() {
      let returnMessage = '';
      if (!this.$v.form.email.required) {
        returnMessage = 'Email is required';
      }
      if (!this.$v.form.email.email) {
        returnMessage = 'Email is invalid';
      }
      return returnMessage;
    },

    checkRecentTech() {
      if (localStorage.getItem('selectedRecentTechnician')) {
        this.lastTechnician = JSON.parse(localStorage.getItem('selectedRecentTechnician'));
        this.form.email = this.lastTechnician.email;
        this.welcomeBackTechnician = true;
      } else {
        this.lastTechnician = '';
        this.form.email = '';
        this.welcomeBackTechnician = false;
        this.$store.dispatch('checkSelectedTech', false);
      }
    },

    changeUser() {
      localStorage.removeItem('selectedRecentTechnician');
      this.welcomeBackTechnician = false;
      this.form.email = '';
      this.$store.dispatch('checkSelectedTech', false);
      this.highlightVal = false;
      this.$store.dispatch('authentication/updateRecentTechnician', '');
    },

    checkChild1Res(data) {
      if (data === true) {
        this.checkRecentTech();
      }
    },

    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.keyboardDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },

    enterClicked(value) {
      this.form[value.modelName] = value.input;
    },
  },
  props: {
    storeRecentTech1: {
      default: false,
      type: Boolean,
    },
  },

  validations: {
    form: {
      email: { required, email },
    },
  },

  watch: {
    loginStatus(newVal) {
      if (newVal === true) {
        this.$router.push('logged-in-home');
      }
    },
    storeRecentTech1(val) {
      this.storeRecentTech = val;
      this.checkRecentTech();
    },
  },
};
</script>
