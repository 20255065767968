<template>
  <div>
    <div
      class="margin-16"
      v-if="approvalList.length === 0"
    >
      <p class="no-rx-approvals-added">
        No Rx approvals added
      </p>
    </div>
    <div class="d-flex mobile-add-empty-bottle-btn">
      <button
        class="empty-p-listed-bottle-btn"
        :disabled="pListedBottleStatus"
        @click="goToApprovalDetails"
      >
        Add empty p-listed bottles
      </button>
    </div>
    <div
      class="mt-4"
      v-if="approvalList.length !== 0"
    >
      <p class="rx-approval-with-count">
        Rx approvals: {{ approvalList.length }}
      </p>
      <MobileRxApprovalCard
        v-for="(approval, index) of approvalList"
        :key="index"
        :approval="approval"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import MobileRxApprovalCard from '../rx-approval-card/index-mobile.vue';

export default {
  name: 'MobileRxApprovalList',
  components: {
    MobileRxApprovalCard,
  },
  computed: {
    ...mapGetters({
      approvalList: 'rxApprovalList/getRxApprovalList',
      pListedBottleStatus: 'rxApprovalList/getPListedBottleStatus',
      emptyBottleDetails: 'rxApproval/getMobileRxEmptyBottleDetails',
    }),
  },

  methods: {
    goToApprovalDetails() {
      this.$store.commit('rxApproval/setSelectedRxItem', this.emptyBottleDetails);
      this.$router.push({ name: 'Mobile View Item Details' });
    },
  },
};
</script>
