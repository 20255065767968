//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ExpectedPickup from './expectedPickup.vue';
import LastStopInventoryList from './lastStopInventory.vue';
import connectedStopConstant from '../../constants/connected-stop';
import tripStorage from '../../constants/trip-storage';

export default {
  name: 'PickupData',
  components: { ExpectedPickup, LastStopInventoryList },
  computed: {
    ...mapGetters(['getStopDetail', 'getLastStopData']),
    isCCIDStorage() {
      return this.getStopDetail.trip.storage_method === tripStorage.storageMethods.use_ccid;
    },
    lastStopInventoryExists() {
      return this.getLastStopData.last_stop_inventory.length > 0;
    },
  },
  data() {
    return {
      openLastStopInventoryList: false,
      lastStopInventoryList: [],
      lastStopCompletedDate: null,
    };
  },
  created() {
    this.$store.dispatch('getLastStopData', this.stopId);
    if (this.getStopDetail.connected_status
      === connectedStopConstant.status.ACCUMULATION_DATA_PRESENT) {
      this.$store.dispatch('getAccumulationPickupList', this.stopId);
    } else if (this.getStopDetail.connected_status
      === connectedStopConstant.status.PRIOR_DATA_PRESENT) {
      this.$store.dispatch('getExpectedPickupList', this.stopId);
    }
  },
  beforeDestroy() {
    this.$store.commit('resetLastStopData');
  },
  methods: {
    openLastStopInventory() {
      this.openLastStopInventoryList = true;
    },
    closeInventoryList() {
      this.openLastStopInventoryList = false;
    },
  },
  props: {
    stopId: {
      type: String,
      required: true,
    },
  },
  watch: {
    getLastStopData(newVal) {
      this.lastStopInventoryList = newVal.last_stop_inventory;
      this.lastStopCompletedDate = newVal.actual_departure_time;
      this.inventoryUseCcid = newVal.number_of_ccid > 0;
    },
  },
};
