<template>
  <div class="container label-group-table">
    <table cellspacing="0">
      <col width="80">
      <col width="150">
      <col width="200">
      <col width="180">
      <col width="100">
      <col width="200">
      <tr
        class="table-header-row"
        @click="switchLabelsSelection"
      >
        <th>
          <div class="d-flex img-div ">
            <img
              class="check-img"
              src="src/assets/images/check-circle.svg"
              alt="checkCircle"
              v-if="isAllLabelsSelected"
            >
            <img
              class="check-img"
              src="src/assets/images/check-circle-grey.svg"
              alt="checkCircleGrey"
              v-else
            >
          </div>
        </th>
        <th
          v-for="(header, index) in headerKeys"
          :key="index"
        >
          {{ tableHeaders[header] }}
        </th>
      </tr>
      <tr
        is="labelTableItem"
        name="LabelTableItem"
        :row-data="rowData"
        v-for="(rowData, index) in tableRows"
        :key="index"
        :headers="headerKeys"
      />
    </table>
  </div>
</template>

<script>
import labelTableItem from '../label-table-item/labelTableItem.vue';

export default {
  name: 'LabelGroupTable',
  components: {
    labelTableItem,
  },
  computed: {
    headerKeys() {
      return Object.keys(this.tableHeaders);
    },
    isAllLabelsSelected() {
      for (let index = 0; index < this.tableRows.length; index += 1) {
        const item = this.tableRows[index];
        if (!item.selected) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    switchLabelsSelection() {
      if (this.isAllLabelsSelected) {
        this.$store.dispatch('stopLabels/deselectAllLabels');
      } else {
        this.$store.dispatch('stopLabels/selectAllLabels');
      }
    },
  },
  props: {
    tableHeaders: {
      type: Object,
      default() {
        return [];
      },
    },
    tableRows: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
