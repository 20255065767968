<template>
  <div class="accumulation-pickup-container">
    <div class="d-flex justify-space-between align-start header-container">
      <div class="d-flex flex-column">
        <div class="header-label">
          Expected pickup
        </div>
        <div class="d-flex justify-start summary-container">
          <div class="item">
            <div class="label">
              Expected RetailSIS profiles
            </div>
            <div class="value">
              {{ totalProfiles }}
            </div>
          </div>
          <div class="item">
            <div class="label">
              Expected weight
            </div>
            <div
              v-if="isSuccessfulApiResponse"
              class="value"
            >
              {{ expectedWeight }}lb
            </div>
            <div
              v-else
              class="value"
            >
              -
            </div>
          </div>
          <div class="item">
            <div class="label">
              Items
            </div>
            <div class="value">
              {{ totalItems }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-center warning-box">
        <div class="icon-container">
          <img
            class="alert-icon"
            src="src/assets/images/alert.svg"
            alt="alert-icon"
          >
        </div>
        <div class="label">
          This is the first pickup at the generator using Smart Pickup.
          The expected packages may not match as accurately as they will on
          future pickups at this location.
        </div>
      </div>
    </div>
    <div
      class="d-flex align-center note-container"
      v-if="showNotes"
    >
      <div class="icon-container">
        <img
          class="bulb-icon"
          src="src/assets/images/bulb.svg"
          alt="bulb-icon"
        >
      </div>
      <div class="label">
        Pharmacy/Rx items are not included in this expected weight or profiles listed below.
      </div>
    </div>

    <div
      class="expected-data-container"
      :class="{'update-max-height': !showNotes}"
    >
      <table
        cellspacing="0"
        v-if="isSuccessfulApiResponse && getHasNetworkConnection"
      >
        <col width="410">
        <col width="270">
        <col width="80">
        <col width="90">
        <col width="200">
        <tr class="table-header">
          <th class="label profile-label">
            Expected profiles
          </th>
          <th class="label">
            Container
          </th>
          <th class="label weight-label">
            Weight
          </th>
          <th class="label quantity-label">
            Quantity
          </th>
          <th class="label">
            Percent of stop weight
          </th>
        </tr>
        <tr
          is="accumulationPickupItem"
          name="accumulationPickupItem"
          v-for="(accumulationData, index) in accumulationPickupList"
          :accumulation-data="accumulationData"
          :expected-weight="expectedWeight"
          :key="index"
        />
      </table>
      <div
        v-else
        class="d-flex justify-center align-center flex-column info-container"
      >
        <div
          class="d-flex justify-center align-center flex-column loading-container"
          v-if="getAccumulationApiCallStatus===apiResponseStatus.IN_PROGRESS"
        >
          <img
            src="src/assets/images/loader.png"
            alt="loading-icon"
            class="loading-spinner"
          >
          <div class="loading-label">
            Syncing pickup data from RetailSiS…
          </div>
        </div>
        <div
          class="d-flex justify-center align-center flex-column error-container"
          v-if="getAccumulationApiCallStatus === apiResponseStatus.FAILED || !getHasNetworkConnection"
        >
          <div class="header-label">
            {{ errorHeader }}
          </div>
          <div class="description-label">
            {{ errorDescription }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AccumulationPickupItem from '../accumulation-pickup-item/accumulationPickupItem.vue';
import customerTypesConstant from '../../../constants/customer-type';
import apiResponse from '../../../constants/apiResponseStatus';

export default {
  name: 'AccumulationPickupData',
  components: { AccumulationPickupItem },
  computed: {
    ...mapGetters(['getStopDetail', 'getAccumulationApiCallStatus', 'getHasNetworkConnection']),
    totalProfiles() {
      if (this.isSuccessfulApiResponse) {
        return this.accumulationPickupList[0].total_num_profiles_on_stop;
      }
      return '-';
    },
    expectedWeight() {
      if (this.isSuccessfulApiResponse) {
        return Number(this.accumulationPickupList.reduce((total, current) => total + current.weight, 0).toFixed(2));
      }

      return '0';
    },
    totalItems() {
      if (this.isSuccessfulApiResponse) {
        return this.accumulationPickupList.reduce((total, current) => total + current.quantity, 0);
      }
      return '-';
    },
    showNotes() {
      return this.getStopDetail.customer.name === customerTypesConstant.customerTypes.is_costco;
    },
    errorHeader() {
      if (this.getHasNetworkConnection) {
        return 'We’re having some trouble fetching the stop data.';
      }
      return 'It looks like we can’t connect to the internet.';
    },
    errorDescription() {
      return 'We’ll keep trying, but you may go ahead and begin the stop. We’ll show the stop data once it’s available.';
    },
    isSuccessfulApiResponse() {
      return this.getAccumulationApiCallStatus === apiResponse.status.SUCCESS;
    },
  },
  data() {
    return {
      apiResponseStatus: apiResponse.status,
    };
  },
  props: {
    accumulationPickupList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
