//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import KeyboardDialog from '../virtual-keyboard/virtualKeyboardDialog.vue';

export default {
  name: 'ContainerQuantity',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters(['getSelectedApproval', 'getSelectedInventoryApproval']),
    disableQuantityField() {
      return (!this.getSelectedInventoryApproval.id && !this.getSelectedApproval.id)
            || this.hasCustomerSpecificBilling;
    },
  },
  data() {
    return {
      input: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      quantityItem: this.quantity,
      maxLength: '2',
      modelValue: '',
    };
  },
  methods: {
    increaseQuantity() {
      if (this.quantity < 100) {
        this.$store.dispatch('container/increaseQuantityAction', { index: this.containerIndex });
      }
    },
    decreaseQuantity() {
      if (this.quantity > 1) {
        this.$store.dispatch('container/decreaseQuantityAction', { index: this.containerIndex });
      }
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.quantityItem = value.input;
      if (this.quantityItem > 0 && this.quantityItem < 100) {
        this.$store.dispatch('container/setContainerQuantityTypeAction', { index: this.containerIndex, quantity: parseInt(this.quantityItem, 10) });
      }
    },
  },
  props: {
    quantity: {
      type: Number,
      default: 0,
    },
    lastIndex: {
      type: Boolean,
      default: false,
    },
    containerIndex: {
      type: Number,
      default: 0,
    },
    hasCustomerSpecificBilling: {
      default: false,
      type: Boolean,
    },
  },
};
