<template>
  <div>
    <div class="mobile-help-sub-header-div">
      <img
        src="src/assets/images/left-chevron-white.svg"
        class="mobile-help-sub-header-div-img"
        @click="goToBack()"
      >
      <span class="mobile-help-sub-header-title">Release notes</span>
    </div>
    <div>
      <iframe
        src="https://sis.help.smartersorting.com/hc/en-us/articles/360045186631-TruckSIS-Release-Notes"
        title="Release notes"
        frameborder="0"
        width="100%"
        height="1370"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'ReleaseNotesMobile',
  methods: {
    goToBack() {
      this.$router.go(-1);
    },
  },
};
</script>
