//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'CancelConfirmation',
  components: { },
  computed: {
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    closeArrivalCancellationAction() {
      this.$emit('close', false);
    },
    confirmArrivalCancellationAction() {
      const { stopId } = this.$route.params;
      this.$store.dispatch('cancelArriveAction', { id: stopId, status: true, pickupId: this.pickupId }).then(() => {
        this.$emit('close', false);
      });
    },
  },
  props: {
    openDialog: Boolean,
    name: {
      type: String,
      required: true,
    },
    pickupId: {
      type: Number,
      required: true,
    },
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
  },
};
