<template>
  <div class="trip-inventory-card">
    <div
      class="stop-inventory-card d-flex"
      :class="{'full-inventory': useCCIDStorage && ccidList.is_full}"
    >
      <div class="card-divide1">
        <div class="mb-6">
          <div v-if="useCCIDStorage">
            <div class="header">
              <label>CCID
                <span
                  class="full-text"
                  v-if="ccidList.is_full"
                >(Full)</span>
              </label>
            </div>
            <div class="value">
              <label>{{ ccidList.ccid }}</label>
            </div>
          </div>
          <div v-else>
            <div class="header">
              <label>TSDF</label>
            </div>
            <div class="value tsdf-code">
              <label>{{ ccidList.tsdf.code }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="card-divide2 d-flex">
        <div class="card-sub-divide1">
          <div class="d-flex flex-column description">
            <div class="header">
              <label>Description</label>
            </div>
            <div class="value">
              <label>
                {{ useCCIDStorage ? ccidList.approval_description : ccidList.approval.description }}
              </label>
            </div>
          </div>
        </div>
        <div class="card-sub-divide2">
          <div class="d-flex flex-column approval">
            <div class="value-box">
              <div class="header">
                <label>Approval</label>
              </div>
              <div class="value truncate-text">
                <label>
                  {{ useCCIDStorage ? ccidList.approval_code : ccidList.approval.approval_code }}
                </label>
              </div>
            </div>
            <div class="value-box border-0">
              <div class="header">
                <label>Weight</label>
              </div>
              <div class="value">
                <label>
                  {{ ccidList.weight }} {{ getCCIDUnit }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-divide3 d-flex">
        <div class="card-sub-divide2">
          <div class="d-flex flex-column approval">
            <div class="value-box">
              <div class="header">
                <label>Container</label>
              </div>
              <div class="value">
                <label>{{ ccidList.container_type }}</label>
              </div>
            </div>
            <div
              class="value-box"
              v-if="useCCIDStorage"
            >
              <div class="header">
                <label>Size</label>
              </div>
              <div class="value">
                <label>{{ ccidList.container_size }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card-divide4 d-flex"
      >
        <div v-if="useCCIDStorage">
          <div
            class="d-flex flex-column mleft-4 w-100 align-items-center justify-content-center"
          >
            <div
              class="edit-approval-card btn-box-table"
              v-if="!ccidList.is_full"
            >
              <button
                class="stop-inventory-card-btn"
                @click.prevent="markasFull()"
              >
                Mark as full
              </button>
            </div>
            <div
              v-else
              class="stop-inventory-reopen btn-box-table"
            >
              <button
                class="stop-inventory-card-btn"
                @click.prevent="reopen()"
              >
                Reopen
              </button>
            </div>
            <div class="edit-btn-div">
              <div
                class="width-135 padding-10 show-content-div"
                @click="ccidList.show_contents = !ccidList.show_contents"
              >
                <img
                  :src="getArrow('src')"
                  :alt="getArrow('alt')"
                >
                <span class="show-contents-text">
                  Contents
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="ccid-all-dropdown-div"
      v-if="ccidList.show_contents"
    >
      <v-row
        no-gutters
        class="ccid-all-dropdown-main-row"
        v-if="ccidList.stop_list.length > 0"
      >
        <v-col
          class="width-384 padding-10"
        >
          <label class="ccid-list-title mb-1 d-block">
            Approvals in this CCID
          </label>
        </v-col>
        <v-col
          class="width-135 padding-10"
        >
          <label class="ccid-list-title mb-1 d-block">
            Stop #
          </label>
        </v-col>
        <v-col
          class="width-384 padding-10"
        >
          <label class="ccid-list-title mb-1 d-block">
            Stop
          </label>
        </v-col>
        <v-col
          class="width-210 padding-10"
          v-if="checkNdc(ccidList.stop_list)"
        >
          <label class="ccid-list-title mb-1 d-block">
            NDC#
          </label>
        </v-col>
        <v-col
          class="width-135 padding-10"
        >
          <label class="ccid-list-title mb-1 d-block">
            Weight
          </label>
        </v-col>
      </v-row>
      <div
        v-if="ccidList.stop_list.length === 0"
        class="text-center no-approvals-added-div"
      >
        <p class="ccid-list-value padding-10">
          No Approvals Added
        </p>
      </div>
      <div
        v-for="(approvalList, indexVal) in ccidList.stop_list"
        :key="indexVal"
      >
        <v-row
          no-gutters
          class="ccid-all-dropdown-row"
        >
          <v-col
            class="width-384 padding-10"
          >
            <p class="ccid-list-value">
              {{ approvalList.description }}
            </p>
          </v-col>
          <v-col
            class="width-135 padding-10"
          >
            <p class="ccid-list-value">
              {{ approvalList.stop_number }}
            </p>
          </v-col>
          <v-col
            class="width-384 padding-10"
          >
            <p class="ccid-list-value">
              {{ approvalList.stop_name }}
            </p>
          </v-col>
          <v-col
            class="width-210 padding-10"
            v-if="checkNdc(ccidList.stop_list)"
          >
            <p class="ccid-list-value">
              {{ approvalList.ndc_code }}
            </p>
          </v-col>
          <v-col
            class="width-135 padding-10"
          >
            <p class="ccid-list-value">
              {{ approvalList.weight }} {{ approvalList.weight_unit }}
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ViewAllCCIDCard',
  computed: {
    ...mapGetters({ useCCIDStorage: 'getUseCCIDStorage' }),
    getCCIDUnit() {
      return this.useCCIDStorage ? this.ccidList.weight_unit : this.ccidList.manifest_unit.unit;
    },
  },
  methods: {
    viewContents() {
      this.ccidList.show_contents = true;
    },
    hideContents() {
      this.ccidList.show_contents = false;
    },
    checkNdc(stopList) {
      return stopList.some((approval) => approval.ndc_code);
    },
    reopen() {
      this.$store.dispatch('reopenApproval', { containerId: this.ccidList.id, stopId: this.stopId, onlyTripInventory: this.onlyTripInventory });
    },
    markasFull() {
      this.$store.dispatch('markasFull', { containerId: this.ccidList.id, stopId: this.stopId, onlyTripInventory: this.onlyTripInventory });
    },
    getArrow(type) {
      if (this.ccidList.show_contents) {
        if (type === 'src') return 'src/assets/images/up-arrow-active.svg';
        return 'up-arrow';
      }
      if (type === 'src') return 'src/assets/images/down-arrow-active.svg';
      return 'down-arrow';
    },
  },
  props: {
    ccidList: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    stopId: {
      type: Number,
      default: 0,
    },
    onlyTripInventory: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
