<template>
  <v-container class="stop-summary-container width-100p">
    <div class="d-flex justify-space-between align-center width-100p stop-detail-header">
      <button
        class="print-btn d-flex justify-center align-center"
        @click.prevent="printTripSummary()"
      >
        <img src="src/assets/images/printer-white.svg">
        Print details
      </button>
      <div
        class="close-btn d-flex align-center"
        @click.prevent="$emit('closeStopDetail')"
      >
        <img
          class="close-img"
          src="src/assets/images/close-circle.svg"
        >
        <div class="label">
          close
        </div>
      </div>
    </div>
    <div
      class="i-frame-content"
    >
      <iframe
        :src="url"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TripSummaryComponent',
  computed: {
    ...mapGetters({
      url: 'trip/getTripDetailPdfUrl',
    }),
  },
  methods: {
    printTripSummary() {
      const payload = {
        preview: false,
        tripId: this.tripId,
      };
      this.$store.dispatch('trip/printTripsDetail', payload);
    },
  },
  props: {
    tripId: {
      type: String,
      default: '',
    },
  },
};
</script>
