//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import tripCard from '../../../helpers/component/trip-card/tripCard.vue';
import tripCode from '../add-code/index.vue';
import SearchLoader from '../../../helpers/component/search-loader/search-loader.vue';
import TripSummaryComponent from '../../../helpers/component/trip-summary/trip-summary.vue';

export default {
  name: 'TripListComponent',
  components: {
    tripCode, tripCard, SearchLoader, TripSummaryComponent,
  },
  computed: {
    trips: {
      get() {
        return this.$store.state.trip.trips;
      },
      set(value) {
        return value;
      },
    },
  },
  data() {
    return {
      showStopDetailClicked: false,
      showCompletedTrip: false,
      timer: 5000,
    };
  },
  created() {
    this.$store.dispatch('trip/tripListAction');
    this.$store.dispatch('getActiveTripAction', this.$store.state.authentication.user.id);
    this.checkTripSyncStatus();
  },
  destroyed() {
    clearInterval(this.tripSyncInterval);
  },
  methods: {
    showStopDetail() {
      this.showStopDetailClicked = true;
      const payload = {
        preview: true,
      };
      this.$store.dispatch('trip/printTripsDetail', payload);
    },
    closeStopDetail() {
      this.showStopDetailClicked = false;
    },
    stopsFilter(type) {
      this.$store.dispatch('trip/emptyTripList');
      this.showCompletedTrip = type;
      if (this.showCompletedTrip === true) {
        this.$store.dispatch('trip/completedTripListAction');
      } else {
        this.$store.dispatch('trip/tripListAction');
        this.$store.dispatch('getActiveTripAction', this.$store.state.authentication.user.id);
      }
    },
    getCurrentUserTripsStatus() {
      this.$store.dispatch('trip/getUserTripsStatus', this.$store.state.authentication.user.id).then((resp) => {
        this.updateSyncTimer(resp);
        this.trips = this.trips.map((trip) => {
          const tripObj = trip;
          tripObj.stops_synced = resp.find((curr) => curr.id === tripObj.id).stops_synced;
          return tripObj;
        });
      });
    },
    updateSyncTimer(syncTripList) {
      const tripStatus = syncTripList;
      const status = tripStatus.some((trip) => !trip.stops_synced);
      let changeTimer = false;
      if (status && this.timer !== 1000) {
        changeTimer = true;
        this.timer = 1000;
      } else if (!status && this.timer !== 5000) {
        changeTimer = true;
        this.timer = 5000;
      }
      if (changeTimer) {
        this.getCurrentUserTripsStatus();
        clearInterval(this.tripSyncInterval);
        this.checkTripSyncStatus();
      }
    },
    checkTripSyncStatus() {
      this.tripSyncInterval = setInterval(() => {
        this.getCurrentUserTripsStatus();
      }, this.timer);
    },
  },
};
