<template>
  <div>
    <div class="mobile-rx-approval-item-details-div">
      <p class="mobile-rx-approval-item-details-title">
        Item details
      </p>
      <p class="mobile-rx-approval-item-details-sub-title">
        NDC
      </p>
      <p class="mobile-rx-approval-item-details-sub-details">
        {{ rxItem.ndc_code }}
      </p>

      <p class="mobile-rx-approval-item-details-sub-title">
        Description
      </p>
      <p class="mobile-rx-approval-item-details-sub-details">
        {{ rxItem.rx_item_description }}
      </p>

      <p class="mobile-rx-approval-item-details-sub-title">
        Approval
      </p>
      <p class="mobile-rx-approval-item-details-sub-details">
        {{ rxItem.approval_description }}
      </p>
      <p class="mobile-rx-approval-item-details-sub-title">
        DEA schedule
      </p>
      <p class="mobile-rx-approval-item-details-sub-details">
        {{ rxItem.deaScheduled || 'Not Scheduled' }}
      </p>
      <button
        class="mobile-rx-approval-item-details-back-btn"
        @click="goToBack"
      >
        <em class="fas fa-chevron-left" /> &nbsp;
        Back
      </button>
      <button
        class="mobile-rx-approval-item-details-confirm-btn"
        @click="confirmRxItem"
      >
        Confirm
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ViewRxItemDetail',
  computed: {
    ...mapGetters({
      rxItemObj: 'rxApproval/getSelectedRxItem',
    }),
  },
  data() {
    return {
      rxItem: {},
    };
  },
  created() {
    this.rxItem = this.rxItemObj;
  },
  methods: {
    goToBack() {
      const props = {};
      props.showApprovalDetails = false;
      props.showSearchIcon = false;
      props.noRecordsFound = false;
      props.showSearchResult = true;
      if (this.rxItem && this.rxItem.viaEmptyBottleButton) {
        props.showApprovalDetails = true;
        props.showSearchResult = false;
      }
      this.$store.commit('rxApproval/setRxItemMainProp', props);
      this.$router.push({ name: 'Mobile Add RX Items' });
    },
    confirmRxItem() {
      this.rxItem = { ...this.rxItem, note: '' };
      this.$store.commit('rxContent/resetContent');
      this.$store.commit('rxApproval/setRxItemObject', this.rxItem);
      this.$router.push({ path: 'add-rx_approval-card' });
      this.$store.commit('rxApproval/setRxItemListMobile', [], { root: true });
    },
  },
};
</script>
