//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import quantitySelector from '../quantity-selector/quantitySelector.vue';

export default {
  name: 'LabelTableItem',
  components: {
    quantitySelector,
  },
  data() {
    return {
      ccidHeader: 'ccid',
      non_ccid_approval_header: 'approval_code',
    };
  },
  methods: {
    switchLabelSelection() {
      if (this.rowData.selected) {
        this.$store.dispatch('stopLabels/deselectLabel', this.rowData.ccid + this.rowData.displayedType);
      } else {
        this.$store.dispatch('stopLabels/selectLabel', this.rowData.ccid + this.rowData.displayedType);
      }
    },
    updateQuantity(qty) {
      this.$store.dispatch('stopLabels/updateQuantity', { ccid: this.rowData.ccid + this.rowData.displayedType, qty });
    },
  },
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      },
    },
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
