import api from '../service/consolidate.service';

function defaultContainer() {
  return {
    type: {
      id: '',
      container_type: '',
    },
    quantity: 1,
  };
}

function containerSelectChange(stateControl, payload) {
  const stateControld = stateControl;
  stateControld.containerList[payload.index].type.id = payload.typeID;
  const containerData = stateControld.containerTypeList
    .find((typeData) => typeData.containerIndex === payload.index);
  if (containerData) {
    delete containerData.containerIndex;
    delete containerData.is_selected;
  }
  const containerType = stateControld.containerTypeList
    .find((typeData) => typeData.id === +payload.typeID);
  containerType.containerIndex = payload.index;
  containerType.is_selected = true;
}
function setSelectedContainerTypeListAction(stateControl) {
  let i = -1;
  stateControl.containerTypeListIds.forEach((id) => {
    stateControl.containerTypeList.forEach((container) => {
      const param = container;
      if (id === param.id) {
        i += 1;
        param.containerIndex = i;
        param.is_selected = true;
      } else if (!param.is_selected) {
        param.containerIndex = '';
        param.is_selected = false;
      }
    });
  });
}
function reOrderContainerIndex(stateControl, index) {
  stateControl.containerTypeList.forEach((containerType) => {
    const param = containerType;
    if (index < param.containerIndex && param.is_selected) {
      param.containerIndex -= 1;
    }
  });
}

const state = {
  containerList: [defaultContainer()],
  containerTypeList: [],
  containerTypeListIds: [],
};

const getters = {
  getContainerList(stateControl) {
    return stateControl.containerList;
  },
  getContainerTypeList(stateControl) {
    return stateControl.containerTypeList;
  },
};

const mutations = {
  addAnotherContainer(stateControl) {
    const stateControld = stateControl;
    stateControld.containerList.push(defaultContainer());
  },
  removeContainer(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.containerList.splice(payload, 1);
  },
  increaseQuantity(stateControl, payload) {
    const stateControld = stateControl;
    const containerData = stateControld.containerList[payload.index];
    stateControld.containerList[payload.index].quantity = containerData.quantity + 1;
  },
  decreaseQuantity(stateControl, payload) {
    const stateControld = stateControl;
    const containerData = stateControld.containerList[payload.index];
    stateControld.containerList[payload.index].quantity = containerData.quantity - 1;
  },
  setContainerTypeList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.containerTypeList = payload;
  },
  setContainerTypeID(stateControl, payload) {
    containerSelectChange(stateControl, payload);
  },
  resetContainerType(stateControl) {
    const stateControld = stateControl;
    stateControld.containerTypeList = [];
    stateControld.containerList = [defaultContainer()];
  },
  resetContainerIndex(stateControl, payload) {
    const stateControld = stateControl;
    const containerType = stateControld.containerTypeList
      .find((typeData) => typeData.id === +payload.typeID);
    if (containerType) {
      delete containerType.containerIndex;
      delete containerType.is_selected;
    }
  },
  setContainerQuantity(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.containerList[payload.index].quantity = payload.quantity;
  },
  setContainerLists(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.containerList = payload.containerList;
  },
  setContainerTypeListIds(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.containerTypeListIds = payload;
  },
  triggerContainerTypeList(stateControl) {
    setSelectedContainerTypeListAction(stateControl);
  },
  triggerReOrderContainerIndex(stateControl, payload) {
    reOrderContainerIndex(stateControl, payload);
  },
};

const actions = {
  addAnotherContainerAction({ commit }) {
    commit('addAnotherContainer');
  },
  removeContainerAction({ commit }, res) {
    commit('removeContainer', res);
  },
  increaseQuantityAction({ commit }, res) {
    commit('increaseQuantity', res);
  },
  decreaseQuantityAction({ commit }, res) {
    commit('decreaseQuantity', res);
  },
  containerTypeListAction({ commit }) {
    return new Promise((resolve) => {
      api.getContainerTypeList().then((response) => {
        commit('setContainerTypeList', response.container_types);
        resolve();
      }).catch(() => {
        resolve();
      });
    });
  },
  addContainerTypeAction({ commit }, res) {
    commit('setContainerTypeID', res);
  },
  setContainerQuantityTypeAction({ commit }, res) {
    commit('setContainerQuantity', res);
  },
  setSelectedContainerTypeLists(context) {
    context.commit('triggerContainerTypeList');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
