import Vue from 'vue';
import * as Vuex from 'vuex';
import forgotpin from './forgotpin';
import session from './session';
import authentication from './login';
import createAccount from './createAccount';
import trip from './trips';
import toastMessage from './toast-message';
import stopDetail from './stopDetail';
import stopInformationWorkArea from './stopInformationWorkArea';
import contactTimestamp from './contactTimestamp';
import approvalList from './approval';
import approvalScale from './approvalScale';
import container from './container';
import rxApproval from './rx-approval';
import rxApprovalList from './rx-approval-list';
import rxContent from './rx-content';
import inventoryApproval from './inventoryApproval';
import weight from './weight';
import consolidate from './consolidation';
import stopDocuments from './stop-documents';
import masterScan from './scan-item';
import stopLabels from './stop-labels';
import connectivity from './connectivity';
import pageSequence from './page-sequence';
import alerts from './alerts';
import attachment from './stop-attachment';
import questionnaire from './questionnaire';
import notes from './notes';
import leaveApproval from './leave-approval';
import loadingSearchIcon from './loading-search-icon';

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    forgotpin,
    session,
    authentication,
    createAccount,
    trip,
    toastMessage,
    stopDetail,
    stopInformationWorkArea,
    contactTimestamp,
    approvalList,
    approvalScale,
    container,
    rxApproval,
    rxApprovalList,
    rxContent,
    inventoryApproval,
    weight,
    consolidate,
    stopDocuments,
    masterScan,
    connectivity,
    stopLabels,
    pageSequence,
    alerts,
    attachment,
    questionnaire,
    notes,
    leaveApproval,
    loadingSearchIcon,
  },
});
