//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import tripStorage from '../../../constants/trip-storage';

export default {
  name: 'StorageMethodDialog',
  computed: {
    ...mapGetters(['getStopDetail']),
  },
  data() {
    return {
      storageMethods: tripStorage.storageMethods,
      selectedStorage: null,
      showInfoBox: false,
      showError: false,
    };
  },
  methods: {
    back() {
      this.$emit('cancelStorageDialog');
    },
    continueStop() {
      if (this.selectedStorage) {
        const params = {
          stopId: this.stopToActivate,
          storageMethod: this.selectedStorage,
        };
        this.$store.dispatch('firstStopArriveAction', params);
      } else {
        this.showError = true;
      }
    },
    selectStorage(value) {
      this.showError = false;
      this.selectedStorage = value;
    },
    showInfo(value) {
      this.showInfoBox = value;
    },
  },
  props: {
    showStorageMethodDialog: {
      type: Boolean,
      default: false,
    },
    stopToActivate: {
      type: Number,
      default: null,
    },
  },
  watch: {
    showStorageMethodDialog() {
      this.selectedStorage = null;
      this.showError = false;
      this.showInfoBox = false;
    },
  },
};
