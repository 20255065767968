//
//
//
//
//
//

export default {
  name: 'EmptyLayout',
};
