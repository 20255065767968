<template>
  <div>
    <v-overlay :value="dialog">
      <div class="stop-warn-container">
        <div class="d-flex flex-column align-center justify-center stop-warn-box">
          <div class="d-flex justify-center align-center fs-3em m28">
            <i class="fas fa-exclamation-triangle" />
          </div>
          <div class="stop-warn-info">
            <label>
              The following stop is active:
            </label>
          </div>
          <div class="stop-warn-info-child">
            <label>
              You must complete this service before beginning another stop.
            </label>
          </div>
          <div class="d-flex width-70p mt-10">
            <div class="stop-warn-active-trip">
              <p class="header">
                Active trip
              </p>
            </div>
            <div class="stop-warn-active-stop">
              <p class="header">
                Active stop
              </p>
            </div>
            <div class="stop-warn-stop-name">
              <p class="header">
                Stop name
              </p>
            </div>
          </div>
          <div class="d-flex width-70p">
            <div class="stop-warn-active-trip">
              <p class="value">
                {{ activeTripAndStopName.activeTrip }}
              </p>
            </div>
            <div class="stop-warn-active-stop">
              <p class="value">
                {{ activeTripAndStopName.activeStop }}
              </p>
            </div>
            <div class="stop-warn-stop-name">
              <p class="value">
                {{ activeTripAndStopName.stopName }}
              </p>
            </div>
          </div>
          <div class="btn-group-stop-warn mt-10">
            <button
              @click.prevent="goToStopLists()"
              class="btn-submit back-to-stop-main"
            >
              Cancel
            </button>
            <button
              @click.prevent="goToActiveStopDetail()"
              class="btn-submit goto-active-stop"
            >
              Go to active stop
            </button>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ActiveStopWarning',
  computed: {
    ...mapGetters({ voidedStopsInTrip: 'getVoidedStopsInTrip' }),
  },
  data() {
    return {
      dialog: false,
      activeTripAndStopName: {
        stopName: '',
        activeStop: '',
        activeTrip: '',
        activeStopId: '',
      },
    };
  },
  created() {
    this.checkStatusWarningDialog();
  },
  methods: {
    checkStatusWarningDialog() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'showStopActiveWarning') {
          this.dialog = mutation.payload;
          this.activeTripAndStopName = this.$store.getters.getActiveTripAndStopName;
        }
      });
    },
    goToStopLists() {
      this.dialog = false;
    },
    goToActiveStopDetail() {
      this.$store.dispatch('getStopDetailAction', {
        stopID: this.activeTripAndStopName.activeStopId,
        type: 'popup',
      }, { root: true });
    },
  },
  watch: {
    voidedStopsInTrip() {
      if (this.voidedStopsInTrip.includes(this.activeTripAndStopName.activeStopId)) {
        this.dialog = false;
      }
    },
  },
};
</script>
