<template>
  <div class="approvalListContainer d-flex flex-column align-start justify-start">
    <div class="approval-list-col1">
      <div class="search">
        <div class="flex-back">
          <button
            @click.prevent="goToBack()"
            class="approval-list-back-box"
          >
            <em class="fas fa-chevron-left" />
            <span class="margin-left-5">Back</span>
          </button>
        </div>
        <div class="wrapper">
          <div class="search-wrapper">
            <div class="search-box card">
              <div class="search-button-box-icon">
                <button
                  class="search-button-icon"
                  name="search"
                >
                  <div class="approval-search-icon" />
                </button>
              </div>
              <div class="query-box">
                <input
                  v-model="searchVal"
                  type="text"
                  class="query"
                  placeholder="Search for an approval"
                  @click="checkDialog('alphaNumeric','search','Search for an approval',searchVal)"
                  @selectstart.prevent
                  @paste.prevent
                  @copy.prevent
                  @cut.prevent
                  @drag.prevent
                  @drop.prevent
                  v-unSelect
                >
              </div>
              <div class="search-button-box">
                <button
                  class="search-button"
                  :class="{'searchEnable': getClassEnable}"
                  name="search"
                  @click.prevent="searchApprovals()"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="approval-list-col2">
      <div
        class="approval-list approval-list-scroll"
        id="list-div"
        @scroll="handleScroll"
      >
        <div class="approval-list-header d-flex">
          <div class="flex-5">
            <div class="mleft-56p mb-1">
              <label>Description</label>
            </div>
          </div>
          <div class="flex-5">
            <label class="ml-16p">Approval name</label>
          </div>
          <div class="flex-2">
            <label class="ml-16p">Document type</label>
          </div>
          <div class="flex-4">
            <label />
          </div>
        </div>
        <template
          v-if="getApprovalLists && getApprovalLists.approvals &&
            getApprovalLists.approvals.length > 0"
        >
          <approval-list-card
            v-for="(approvalDetail, index) in getApprovalLists.approvals"
            :key="index"
            :approval-detail="approvalDetail"
          />
        </template>
        <template v-if="getSearchResult">
          <NoResultsFound />
        </template>
      </div>
      <div
        class="float-right margin-top-20"
      >
        <em
          class="fas fa-chevron-circle-up top-arrow-icon width-40"
          v-if="listLength > 20"
          @click="moveToTop()"
        />
      </div>
    </div>
    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :max-length="maxLength"
      :model-value="modelValue"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @enterClicked="enterClicked"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import approvalListCard from './approvalListCard.vue';
import KeyboardDialog from '../../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import NoResultsFound from '../../../../helpers/component/no-results-found/no-results-found.vue';

export default {
  name: 'ApprovalList',
  components: {
    approvalListCard,
    KeyboardDialog,
    NoResultsFound,
  },
  computed: {
    ...mapGetters(['getApprovalLists', 'getStopDetail']),
    ...mapGetters({ getSearchResult: 'rxApproval/getSearchResult' }),
    getClassEnable() {
      return !!(this.searchVal && this.searchVal.length > 0);
    },
  },
  data() {
    return {
      searchVal: '',
      stopDetail: {},
      input: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      maxLength: '30',
      modelValue: '',
      listLength: 0,
      approvalCode: '',
    };
  },
  created() {
    this.getApprovalList();
    this.approvalCode = this.$route.params.approvalCode;
  },
  methods: {
    goToBack() {
      this.$store.dispatch('assignApprovalAction', this.$store.state.approvalList.approvalDetail);
      this.$router.push({ name: 'Add Approval' });
    },
    getApprovalList() {
      this.stopDetail = this.$store.getters.getStopDetail;
      if (this.stopDetail) {
        this.$store.dispatch('setApprovalList', this.stopDetail.id);
      }
    },
    searchApprovals() {
      this.$store.dispatch('searchApprovals', { id: this.stopDetail.id, value: this.searchVal });
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.searchVal = value.input;
      this.searchApprovals();
    },
    moveToTop() {
      const listArea = document.getElementById('list-div');
      listArea.scrollTop = 0;
    },
    handleScroll() {
      const listArea = document.getElementById('list-div');
      this.listLength = listArea.scrollTop;
    },
  },
  watch: {
    getStopDetail(newVal) {
      if (!this.approvalCode) {
        this.stopDetail = newVal;
        this.getApprovalList();
      }
    },
    approvalCode: {
      handler() {
        if (this.approvalCode) {
          this.searchVal = this.approvalCode;
          this.searchApprovals();
        }
      },
      deep: true,
    },
    '$route.params.approvalCode': function detectSearchKeyChange() {
      this.approvalCode = this.$route.params.approvalCode;
    },
  },
};
</script>
