<template>
  <div :class="keyboardClass" />
</template>

<script>
import Keyboard from 'simple-keyboard';

export default {
  name: 'SimpleKeyboard',
  computed: {
    keypadValues() {
      if (this.keypadFor === 'ArrivedTime') {
        return ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 '];
      }
      return ['1 2 3', '4 5 6', '7 8 9', '{bksp} 0 .'];
    },
  },
  data: () => ({
    keyboard: null,
    index: 1,
  }),
  mounted() {
    this.keyboard = new Keyboard({
      onChange: this.onChange,
      onKeyPress: this.onKeyPress,
      layout: {
        default: this.keypadValues,
      },
      theme: 'hg-theme-default hg-layout-numeric numeric-theme',
      buttonTheme: [
        {
          class: 'keyboard-submit-green',
          buttons: 'Confirm',
        },
        {
          class: 'keyboard-cancel-grey',
          buttons: 'Cancel',
        },
        {
          class: 'fas fa-backspace',
          buttons: '{bksp}',
        },
      ],
      display: {
        '{bksp}': 'Delete',
        '{enter}': 'Confirm',
        '{cancel}': 'Cancel',
      },
    });
  },
  methods: {
    onChange(input) {
      if (this.index === 1) {
        const val = this.input ? this.input + input : input;
        this.$emit('onChange', val);
        this.index = this.index + 1;
      } else {
        this.$emit('onChange', input);
      }
    },
    onKeyPress(button) {
      this.$emit('onKeyPress', button);
      /* If you want to handle the shift and caps lock buttons */
      if (button === '{shift}' || button === '{lock}') this.handleShift();
      if (button === '{bksp}') {
        this.inputCheck = this.input.substring(0, this.input.length - 1);
        this.index = this.index + 1;
        this.onChange(this.inputCheck);
      }
    },
    handleShift() {
      const currentLayout = this.keyboard.options.layoutName;
      const shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  props: {
    keyboardClass: {
      default: 'simple-keyboard',
      type: String,
    },
    input: {
      default: '',
      type: String,
    },
    keypadFor: {
      type: String,
      default: '',
    },
  },
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
  },
};
</script>
