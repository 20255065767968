import api from '../service/auth.service';

const state = {
  status: null,
  existingEmail: null,
  email: null,
  resendEmailStatus: null,
  createAccountPinDialog: false,
  confirmedPinValue: null,
  createAccountValue: null,
};

const getters = {
  getAccountStatus(context) {
    return context.status;
  },
  getExistingEmail(context) {
    return context.existingEmail;
  },
  getEmail(context) {
    return context.email;
  },
  getResendEmailStatus(context) {
    return context.resendEmailStatus;
  },
  getPinDialogValue(context) {
    return context.createAccountPinDialog;
  },
  getCreateAccountValue(context) {
    return context.createAccountValue;
  },
};

const mutations = {
  success(stateControl) {
    const stateControld = stateControl;
    stateControld.status = 'Success';
  },
  failed(stateControl) {
    const stateControld = stateControl;
    stateControld.status = 'failed';
  },
  setStatus(stateControl) {
    const stateControld = stateControl;
    stateControld.status = null;
  },
  setExistingEmail(stateControl, email) {
    const stateControld = stateControl;
    stateControld.existingEmail = email;
  },
  setEmail(stateControl, email) {
    const stateControld = stateControl;
    stateControld.email = email;
  },
  setResendEmailSuccess(stateControl) {
    const stateControld = stateControl;
    stateControld.resendEmailStatus = 'Success';
  },
  setResendEmailFailed(stateControl) {
    const stateControld = stateControl;
    stateControld.resendEmailStatus = 'Failed';
  },
  setPinDialogValue(stateControl, value) {
    const stateControld = stateControl;
    stateControld.createAccountPinDialog = value;
  },
  setCreateAccountData(stateControl, value) {
    const stateControld = stateControl;
    stateControld.createAccountValue = value;
  },
};
const actions = {
  createAccountAction({ commit }, payload) {
    return new Promise((resolve) => {
      commit('setExistingEmail', null);
      api.createAccount(payload).then((resData) => {
        if (resData) {
          commit('success');
          commit('setEmail', payload.email);
          resolve(payload);
        }
      }).catch(() => {
        commit('setExistingEmail', payload.email);
        commit('failed');
        resolve('failed');
      });
    });
  },
  resendEmailAction({ commit }, payload) {
    return new Promise((resolve) => {
      api.resendEmail(payload).then((resData) => {
        if (resData) {
          commit('setResendEmailSuccess');
          resolve('resendEmailSuccess');
        }
      }).catch(() => {
        commit('setResendEmailFailed');
        resolve('resendEmailFailed');
      });
    });
  },
  setDialogValue({ commit }, payload) {
    commit('setPinDialogValue', payload);
  },
  setCreateAccountValue({ commit }, payload) {
    commit('setCreateAccountData', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
