<template>
  <div>
    <p class="mobile-rx-approval-weight-quantity">
      Customer specific billing
    </p>
    <div class="mobile-rx-approval-item-details-sub-title w50 float-left">
      Billing units
    </div>
    <div class="mobile-rx-approval-item-details-sub-title w48 float-right">
      Quantity
    </div>
    <div
      v-for="(billUnit, index) in billUnits"
      :key="index"
    >
      <div>
        <p class="mobile-rx-approval-item-details-sub-details bill-unit-text">
          {{ billUnit.BillUnitCode }}
        </p>
        <rxItemIncrementDecrementInput
          v-model="billUnits[index].quantity"
          class="w48 float-right"
        />
      </div>
    </div>
  </div>
</template>
<script>
import RxItemIncrementDecrementInput from '../../../helpers/component/mobile-rx-inc-dec-input/mobileRxIncDecInput.vue';

export default {
  name: 'MobileBillUnits',
  components: {
    RxItemIncrementDecrementInput,
  },
  props: {
    billUnits: {
      default: () => [
        {
          BillUnitCode: '',
          quantity: 0,
        },
      ],
      type: Array,
    },
  },
};
</script>
