import firestoreDB from './firestoreDB.service';

function checkEmail(request) {
  return new Promise((resolve) => {
    firestoreDB.auth.signInAnonymously().then(() => { // Anonymous sign-in as a stop gap to completely un-authenticated endpoint
      firestoreDB.db.collection('users').where('email', '==', request.body.email.toLowerCase()).limit(1).onSnapshot((result) => {
        const docs = firestoreDB.queryToArray(result);
        if (docs.length <= 0) {
          resolve(firestoreDB.errorResponse('User email does not exist'));
        } else {
          resolve(firestoreDB.okResponse(docs[0]));
        }
      });
    }).catch((error) => {
      resolve(firestoreDB.errorResponse(error.code + ': ' + error.message))
    });
  });
}

function login(request) {
  return new Promise((resolve) => {
    firestoreDB.db.collection('users').where('email', '==', request.body.email.toLowerCase()).limit(1).onSnapshot((result) => {
      const docs = firestoreDB.queryToArray(result);
      if (docs.length <= 0) {
        resolve(firestoreDB.errorResponse('User email does not exist'));
      } else if (docs[0].pin_number !== request.body.pin) {
        resolve(firestoreDB.errorResponse('Please enter a valid Pin'));
      } else {
        resolve(firestoreDB.okResponse({ user: docs[0] }));
      }
    });
  });
}

const userService = {
  checkEmail,
  login,
};
export default userService;
