//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex';
import AuthLayout from './layout/auth-header/component.vue';
import LoggedInLayout from './layout/logged-in-header/component.vue';
import MobileHeaderLayout from './layout/mobile-header/component.vue';
import MobileAuthLayout from './layout/auth-header/component-mobile.vue';
import updateCheckService from './service/updateCheck.service';
import EmptyLayout from './layout/empty-layout/component.vue';
import EmptyContainerLayout from './layout/auth-header/emptyContainerLayout.vue';

const defaultLayout = 'auth-layout';
export default {
  name: 'App',
  components: {
    'auth-layout': AuthLayout,
    'logged-in-layout': LoggedInLayout,
    'mobile-layout': MobileHeaderLayout,
    'mobile-auth-layout': MobileAuthLayout,
    'empty-layout': EmptyLayout,
    'empty-container': EmptyContainerLayout,
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    },
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
    ...mapGetters({
      errorMessage: 'toastMessage/getMessage',
      loginStatus: 'authentication/getloginStatus',
    }),
    ...mapMutations({ toastChanges: 'toastMessage/setMessage' }),
  },
  data() {
    return {
      isHandheld: {
        type: Boolean,
        default: false,
      },
    };
  },
  created() {
    if ("handheld-prod".includes('handheld')) {
      this.isHandheld = true;
      this.$router.push({
        path: '/login-mobile',
      });
    }
  },
  mounted() {
    this.startUpdateChecks();
    if (!"handheld-prod".includes('handheld') && !(this.hasCorrectResolution() && this.isChromeBrowser())) {
      const errorMessageData = {
        message: 'Use Google Chrome at 1920x1080 resolution',
        type: 'error',
        isCustom: false,
      };
      this.$store.dispatch('toastMessage/showMessage', errorMessageData);
    }
  },
  methods: {
    showGlobalToast(message) {
      this.$toasted.global.sis_toast_message(message);
    },
    hasCorrectResolution() {
      return (window.innerHeight === 1080 && window.innerWidth === 1920);
    },
    isChromeBrowser() {
      return navigator.userAgent.indexOf('Chrome') !== -1;
    },
    startUpdateChecks() {
      if ("handheld-prod".includes('production') || "handheld-prod".includes('qa') || "handheld-prod".includes('development')) {
        updateCheckService.startUpdateChecks();
      }
    },
  },
  watch: {
    loginStatus(newVal) {
      if (newVal === false) {
        this.$router.push('/bootUp');
      }
    },
    errorMessage() {
      this.showGlobalToast(this.errorMessage);
    },
    isIdle(newVal) {
      if (newVal && this.$route.path !== '/login-mobile' && this.isHandheld === true
      && this.$store.state.authentication.loggedIn === true) {
        this.$store.commit('authentication/logout');
        this.$router.push({
          path: '/login-mobile',
          query: { redirect: this.$route.fullPath },
        });
      }
    },
  },
};
