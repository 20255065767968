<template>
  <div
    class="container"
    :style="dialogProps"
  >
    <v-overlay
      :value="dialog"
      opacity="0.94"
      z-index="3"
    >
      <div class="d-flex justify-start align-start flex-column modal-container">
        <div class="info-modal">
          <div class="modal-header">
            <div>
              <label>{{ dialogData.heading }}</label>
            </div>
          </div>
          <div
            v-if="dialogData.subHeading"
            class="sub-heading"
          >
            <label>{{ dialogData.subHeading }}</label>
          </div>
          <div class="description-font">
            <p>{{ dialogData.description }}</p>
          </div>
        </div>
        <div class="mt-10 d-flex">
          <div
            v-if="buttonData.buttonCancelVisibility"
            :class="[buttonData.buttonCancelType ? buttonData.buttonCancelType : 'btn-normal']"
          >
            <button
              class="btn-cancel cancel d-flex align-center justify-center"
              @click="closeCancellationAction()"
            >
              <i class="fas fa-angle-left" />
              <div>{{ buttonData.buttonCancelText }}</div>
            </button>
          </div>
          <div
            v-if="buttonData.buttonConfirmVisibility"
            :class="[buttonData.buttonConfirmType ? buttonData.buttonConfirmType : 'btn-success']"
          >
            <button
              class="confirm d-flex align-center justify-center"
              @click="confirmAction()"
            >
              {{ buttonData.buttonConfirmText }}
            </button>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<style scoped>
</style>

<script>
export default {
  name: 'Confirmation',
  components: {},

  computed: {
    dialogProps() {
      return {
        '--modal-height': `${this.dialogProperty.modalHeight}px`,
        '--modal-width': `${this.dialogProperty.modalWidth}px`,
      };
    },
  },

  data() {
    return {
      dialog: true,
    };
  },
  methods: {
    closeCancellationAction() {
      this.$emit('cancel');
    },
    confirmAction() {
      this.$emit('confirm');
    },
  },
  props: {
    openDialog: Boolean,
    dialogData: {
      type: Object,
      heading: String,
      subHeading: String,
      description: String,
      default() {
        return {};
      },
    },
    buttonData: {
      type: Object,
      buttonConfirmText: String,
      buttonCancelText: String,
      buttonConfirmType: String,
      buttonCancelType: String,
      buttonCancelVisibility: Boolean,
      buttonConfirmVisibility: Boolean,
      default() {
        return {};
      },
    },

    dialogProperty: {
      type: Object,
      default() {
        return {
          modalWidth: 936,
        };
      },
    },
  },

  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
  },
};
</script>
