//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import AlertItem from '../alert-item/alertItem.vue';

export default {
  name: 'AlertComponent',
  components: {
    AlertItem,
  },
  computed: {
    ...mapGetters({
      alerts: 'alerts/getAlertsData',
    }),
  },
  methods: {
    scanAlert(alertId) {
      this.$emit('scanAlert', alertId);
    },
  },
};
