<template>
  <div>
    <div class="stop-information-header">
      <div
        class="stop-information-header-inner"
      >
        <div class="stop-information-header-label">
          <label>
            Stop {{ stopDetail.stop_number }}
          </label>
        </div>
        <div class="stop-information-header-types">
          <div
            v-for="(stop_type, index) in stopDetail.stop_type"
            :key="index"
            class="box"
          >
            <div
              v-if="checkConnectedStopStatus()"
            >
              <div class="connected-label-stop">
                CONNECTED
              </div>
              <div class="icon-set">
                <div :class="getClass(stop_type)" />
                <div class="connected-icon">
                  <div class="connected-trip" />
                </div>
              </div>
            </div>
            <div
              v-else
            >
              <label>{{ stop_type }}</label>
              <div
                class="ml-2"
                :class="getClass(stop_type)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import connectedStopConstant from '../../../constants/connected-stop';

export default {
  name: 'StopInformationHeader',
  computed: {
    ...mapGetters({ stopDetailChange: 'getStopDetail' }),
  },
  data() {
    return {
      stopDetail: {},
    };
  },
  created() {
    this.$store.dispatch('getStopDetailAction', {
      stopID: +this.$route.params.stopId,
      type: '',
    });
  },
  methods: {
    getClass(stopType) {
      let className = 'dea-active';
      if (stopType.toLowerCase() === 'st') {
        className = 'st-active';
      }
      if (stopType.toLowerCase() === 'fs') {
        className = 'fs-active';
      }
      if (stopType.toLowerCase() === 'rx') {
        className = 'rx-active';
      }
      if (stopType.toLowerCase() === 'dc') {
        className = 'dc-active';
      }
      if (stopType.toLowerCase() === 'cf') {
        className = 'cf-active';
      }
      return className;
    },
    checkConnectedStopStatus() {
      if (this.stopDetailChange.connected_status) {
        if (this.stopDetailChange.connected_status === connectedStopConstant.status.NOT_CONNECTED) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  watch: {
    stopDetailChange(newVal) {
      this.stopDetail = newVal;
    },
  },
};
</script>
