<template>
  <div class="help-documentation-main-div">
    <p class="help-documentation-title">
      Help documentation and training
    </p>
    <div class="help-doc-sub-div">
      <iframe
        src="https://sis.help.smartersorting.com/hc/en-us/sections/360009362671-Help-and-training"
        title="Help and training"
        frameborder="0"
        width="1074"
        height="807"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'HelpDocument',
};
</script>
