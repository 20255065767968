import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function getMasterFromBarcode(barcode, stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}master/master_scan/${barcode}/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}

const masterScanService = {
  getMasterFromBarcode,
};
export default masterScanService;
