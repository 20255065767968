import Vue from 'vue';
import Router from 'vue-router';
import store from '../store/index';
import routeList from './routes';
import clearAndSaveModalService from '../helpers/utils/clearAndSaveModalService';
import updateCheckService from '../service/updateCheck.service';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(Router);
const router = new Router({
  routes: routeList,
});
export default router;

let initializeRenderRxApprovalList = false;
function checkAndChangeSideNavBarActive(to) {
  if (to.name === 'Loggedin Home' || to.name === 'Trip List') {
    store.dispatch('setSideNavBarActive', 'myTrips');
  } else if (to.name === 'Help Page' || to.name === 'Help Page Boot') {
    store.dispatch('setSideNavBarActive', 'helpPage');
  } else {
    store.dispatch('setSideNavBarActive', 'states');
  }
}
router.beforeEach((to, from, next) => {
  if (to.name === 'Boot Up' || to.name === 'Login' || to.name === 'Loggedin Home') {
    updateCheckService.getReleaseNotesStatus().then((res) => {
      store.commit('setSoftwareVersions', res.versions);
      if (res.show_release_notes) {
        next({
          path: '/release-notes',
          query: { redirect: to.fullPath },
        });
      }
    });
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.authentication.loggedIn) {
      next({
        path: '/login',
        query: { redirect: to.fullPath },
      });
    } else {
      if (to && to.meta && to.meta.componentName) {
        store.dispatch('setStopInfoWorkArea', to.meta.componentName);
        if (!initializeRenderRxApprovalList) {
          store.dispatch('rxApprovalList/getRxApprovalList', to.params.stopId);
          initializeRenderRxApprovalList = true;
        }
      } else {
        store.dispatch('setStopInfoWorkArea', null);
        initializeRenderRxApprovalList = false;
      }

      if (to.name === 'Loggedin Home') {
        if (process.env.NODE_ENV.includes('handheld')) {
          next({ path: '/mobile/trip-list' });
        } else {
          store.subscribe((mutation, state) => {
            if (mutation.type === 'trip/setTripList') {
              const trip = state.trip.trips.length > 0
                && state.trip.trips.find(
                  (tripDetail) => tripDetail.is_active === true,
                );
              if (trip && state.trip.isFirstLogin) {
                if (trip.stop && trip.stop.is_active === true) {
                  next({ path: `/stops/detail/${trip.id}/${trip.stop.id}` });
                } else {
                  next({ path: `/stops/list/${trip.id}` });
                }
              } else {
                next();
              }
            }
          });
        }
      }
      const {
        approvalDetail,
        consolidateApproval,
        approvalStatusType,
      } = store.state.approvalList;
      const { rxApprovalDetail } = store.state.rxApproval;
      if (
        approvalDetail.id
        && from.name === 'Add Approval'
        && to.name !== 'Add Approval'
        && to.name !== 'Approval List'
        && to.name !== 'Consolidate'
      ) {
        if (store.state.stopDetail.useCCIDStorage) {
          clearAndSaveModalService(store, 'approval', from.path, to.path, '');
        } else if (to.name === 'stopDetailWorkArea' && store.state.approvalList.nonCCIDApprovalAdded) {
          checkAndChangeSideNavBarActive(to);
          next();
        } else {
          clearAndSaveModalService(store, 'approval', from.path, to.path, '');
        }
      } else if (
        approvalDetail.id
        && from.name === 'Consolidate'
        && to.name !== 'Add Approval'
        && to.name !== 'stopDetailWorkArea'
      ) {
        clearAndSaveModalService(store, 'consolidate', from.path, to.path, '');
      } else if (
        rxApprovalDetail.id
        && from.name === 'Add Rx Items'
        && to.name === 'Rx Items Lists'
      ) {
        store.commit('rxApprovalList/showRxCancelConfirmationDialog', true);
      } else if (
        rxApprovalDetail.id
        && !rxApprovalDetail.editType
        && from.name === 'Add Rx Items'
        && to.name !== 'Add Rx Items'
      ) {
        clearAndSaveModalService(store, 'rx-approval', from.path, to.path, '');
      } else if (
        !approvalStatusType
        && consolidateApproval
        && consolidateApproval.id
        && from.name === 'RxConsolidate'
      ) {
        clearAndSaveModalService(
          store,
          'rxConsolidate',
          from.path,
          to.path,
          '',
        );
      } else {
        checkAndChangeSideNavBarActive(to);
        next();
      }
    }
  } else if (store.state.authentication.loggedIn && to.name === 'Login') {
    next(from.path);
  } else if (store.state.authentication.loggedIn) {
    if (process.env.NODE_ENV.includes('handheld')) {
      next({ path: '/mobile/trip-list' });
    } else {
      next({ path: '/logged-in-home' });
    }
  } else if (
    (to.name === 'Boot Up' || to.name === 'Login')
    && process.env.NODE_ENV.includes('handheld')
  ) {
    next({ path: '/login-mobile' });
  } else {
    next();
  }
});
