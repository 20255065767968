//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import completeStopConfirmationDialog from '../../../helpers/component/complete-stop-confirmation-dialog/completeStopConfirmationDialog.vue';
import KeyboardDialog from '../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import clearAndSaveModalService from '../../../helpers/utils/clearAndSaveModalService';
import ArrivedTimeUpdate from '../../../helpers/component/virtual-keyboard/arrivalTimeUpdate.vue';
import connectedStopConstant from '../../../constants/connected-stop';

export default {
  name: 'StopInfoContent',
  components: {
    completeStopConfirmationDialog,
    KeyboardDialog,
    ArrivedTimeUpdate,
  },
  computed: {
    ...mapGetters({
      stopDetailChange: 'getStopDetail',
      modalType: 'leaveApproval/getModalType',
      technicianDetails: 'authentication/getUser',
    }),
  },
  data() {
    return {
      stopDetail: {
        start_time: '',
        generator: {
          name: '',
          site_address: '',
          phone_number: '',
          epa_id: '',
          site_code: '',
        },
      },
      dialog: false,
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      generatorSigner: '',
      modelValue: '',
      maximumCharacterError: false,
      arrivedTimeDialogIsOpen: false,
    };
  },
  created() {
    this.stopDetail = this.$store.state.stopDetail.stopDetail;
    this.generatorSigner = this.stopDetailChange.generator_sign_name;
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'setStopArriveCancel') {
        if (state.stopDetail.stopArriveCancelled) {
          this.$router.push(`/stops/list/${this.stopDetail.trip_id}`);
        }
      }
    });
  },
  methods: {
    recordContact(stop) {
      const params = {};
      params.stopID = stop.id;
      params.tripID = stop.trip_id;
      params.type = 'view';
      this.$store.dispatch('contactTimestamp/recordContactAction', params);
    },
    removeContact(stop) {
      const params = {};
      params.stopID = stop.id;
      params.tripID = stop.trip_id;
      params.type = 'view';
      this.$store.dispatch('contactTimestamp/removeContactAction', params);
    },
    undoStop(stopID) {
      this.$store.dispatch('stopArriveCancelAction', stopID);
    },
    checkDialog(KeyboardType, modelName, labelName, value) {
      let modelValue = value;
      modelValue = modelValue || '';
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      if (value.modelName === 'Generator signer') {
        this.generatorSigner = value.input;
        if (value.input.length > 20) {
          this.maximumCharacterError = true;
        } else {
          this.maximumCharacterError = false;
          this.$store.dispatch('updateGeneratorSignNameAction',
            { stopID: this.stopDetail.id, generator_sign_name: this.generatorSigner });
        }
      } else {
        this.stopDetail.start_time = value.timeAndDate;
        this.$store.dispatch('updateStopArrivedTime', { stop_id: this.stopDetail.id, start_time: value.timeStr });
      }
    },
    completeStop() {
      const { approvalDetail } = this.$store.state.approvalList;
      const { rxApprovalDetail } = this.$store.state.rxApproval;
      if (approvalDetail.id
      && this.$route.name === 'Add Approval') {
        clearAndSaveModalService(this.$store, 'approval', '', '', 'complete-stop');
      } else if (this.$route.name === 'Consolidate') {
        clearAndSaveModalService(this.$store, 'consolidate', '', '', 'complete-stop');
      } else if (rxApprovalDetail.id
        && !rxApprovalDetail.editType
        && this.$route.name === 'Add Rx Items') {
        clearAndSaveModalService(this.$store, 'rx-approval', '', '', 'complete-stop');
      } else if (rxApprovalDetail.id
        && !rxApprovalDetail.editType
        && this.$route.name === 'RxConsolidate') {
        clearAndSaveModalService(this.$store, 'rxConsolidate', '', '', 'complete-stop');
      } else {
        if (this.stopDetailChange.connected_status
          === connectedStopConstant.status.ACCUMULATION_DATA_PRESENT) {
          this.$store.dispatch('getAllStopApprovalsWithWeight', this.stopDetailChange.id);
        }
        this.showCompleteStopDialog();
        this.resetLeaveApprovalModal();
      }
    },
    closeDialog() {
      this.dialog = false;
      this.resetLeaveApprovalModal();
    },
    resetLeaveApprovalModal() {
      this.$store.commit('leaveApproval/setModalType', '');
    },
    showCompleteStopDialog() {
      this.$store.dispatch('checkStopCompleteStatus', this.stopDetail.id).then(() => {
        this.dialog = true;
      });
    },
    checkProxyOfferFlag() {
      // eslint-disable-next-line max-len
      if (this.stopDetail.approved_offerer_flag && this.stopDetail.approved_offerer_effective_date !== null) {
        if (this.checkDocumentsForGeneratorSignature()) {
          return true;
        }
      }
      return false;
    },
    checkDocumentsForGeneratorSignature() {
      let flag = false;
      if (this.generatorSigner) {
        flag = true;
      } else {
        this.generatorSigner = `${this.technicianDetails.first_name} ${this.technicianDetails.last_name}`;
        this.$store.dispatch('updateGeneratorSignNameAction',
          { stopID: this.stopDetail.id, generator_sign_name: this.generatorSigner });
        flag = true;
      }
      return flag;
    },
    openArrivedTimeUpdateDialog() {
      this.arrivedTimeDialogIsOpen = true;
    },
    arrivedTimeDialogInputChanged(changes) {
      this.arrivedTimeDialogIsOpen = changes;
    },
  },
  watch: {
    stopDetailChange(newVal) {
      this.stopDetail = newVal;
      this.generatorSigner = newVal.generator_sign_name;
    },
    modalType(newVal) {
      if (newVal === 'complete-stop') {
        this.showCompleteStopDialog();
      }
    },
  },
};
