import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function login(email, password) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email, pin: password }),
  };

  const url = `${API_BASE_URL}login`;
  return fetchService.getFetchData(url, requestOptions);
}

function logout() {
// remove user from local storage to log user out
  localStorage.removeItem('selectedRecentTechnician');
  localStorage.removeItem('user');
}

function getAll() {
  const requestOptions = {
    method: 'GET',
  };

  const url = `${API_BASE_URL}660/users`;
  return fetchService.getFetchData(url, requestOptions);
}

function getUserByEmail(email) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}users?email=${email}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getForgotPIN(email) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}users?email=${email}`;
  return fetchService.getFetchData(url, requestOptions);
}

function postSendPinEmail(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };
  const url = `${API_BASE_URL}users/reset_pin`;
  return fetchService.getFetchData(url, requestOptions);
}
function createAccount(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  const url = `${API_BASE_URL}users`;
  return fetchService.getFetchData(url, requestOptions);
}
function resendEmail(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  const url = `${API_BASE_URL}users/resend_email`;
  return fetchService.getFetchData(url, requestOptions);
}
function checkEmail(email) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email }),
  };

  const url = `${API_BASE_URL}checkEmail`;
  return fetchService.getFetchData(url, requestOptions);
}
function shutdown() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };

  const url = `${API_BASE_URL}systems/shutdown_host`;
  return fetchService.getFetchData(url, requestOptions);
}
function restart() {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = `${API_BASE_URL}systems/reboot_host`;
  return fetchService.getFetchData(url, requestOptions);
}

function getUserTripsStatus(userId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}users/${userId}/check_trip_sync_status`;
  return fetchService.getFetchData(url, requestOptions);
}

const userService = {
  login,
  logout,
  getAll,
  getUserByEmail,
  getForgotPIN,
  createAccount,
  resendEmail,
  postSendPinEmail,
  checkEmail,
  shutdown,
  restart,
  getUserTripsStatus,
};
export default userService;
