<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="837"
    >
      <div class="d-flex flex-column delete-approval-confirmation">
        <div v-if="ccidObject.popupTitle === ''">
          <div class="delete-approval-header">
            <div v-if="approvalDetail.type === 'rx'">
              <label
                v-if="approvalDetail.lastRxItem"
              >Delete this Rx Item and approval?</label>
              <label v-else>Delete this Rx Item?</label>
            </div>
            <div v-else>
              <label
                v-if="useCCIDStorage &&
                  approvalDetail.deleteCCIDS &&
                  approvalDetail.deleteCCIDS.length !== 0"
              >Delete this approval and CCID?</label>
              <label v-else>Delete this approval?</label>
            </div>
            <div class="d-flex flex-column delete-description">
              <div v-if="approvalDetail.type === 'rx'">
                <div class="delete-title">
                  <label v-if="approvalDetail.rxItemDescription">
                    {{ approvalDetail.rxItemDescription }}
                  </label>
                </div>
                <div class="delete-info">
                  <label>
                    Deleting this Rx item will remove it from the stop record
                    and approval {{ approvalDetail.description }}.
                  </label>

                  <span v-if="approvalDetail.lastRxItem">
                    This approval will also be deleted.
                  </span>
                </div>
              </div>
              <div v-else>
                <div class="delete-title">
                  <label v-if="approvalDetail.description">{{ approvalDetail.description }}</label>
                </div>
                <div class="delete-info">
                  <label v-if="useCCIDStorage">
                    Deleting this approval will remove it from the stop record
                    and CCID
                    <span>[{{ rxEditApprovalSequenceCCIDs(approvalDetail.ccids) }}].</span>
                  </label>
                  <label v-else>
                    Deleting this approval will remove it from the stop record.
                  </label>

                  <span
                    v-if="useCCIDStorage &&
                      approvalDetail.deleteCCIDS &&
                      approvalDetail.deleteCCIDS.length > 0"
                  >
                    CCID
                    <span>
                      [{{ rxEditApprovalSequenceCCIDs(approvalDetail.deleteCCIDS) }}]
                      will also be deleted.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div><div class="delete-approval-btns">
            <button
              class="delete-approval-cancel"
              @click="closeDialog()"
            >
              Cancel
            </button>
            <button
              class="delete-approval-submit"
              @click="confirmDelete()"
            >
              <em class="fa fa-trash" />
              Delete
            </button>
          </div>
        </div>
        <div v-else>
          <div class="delete-approval-header">
            <label>
              Delete this CCID?
            </label>
          </div>
          <div class="d-flex flex-column delete-description">
            <div class="delete-info">
              <label>
                Are you sure you want to delete [{{ ccidObject.ccid }} {{ ccidObject.approvalDetail }}]?
              </label>
            </div>
          </div>
          <div class="delete-approval-btns">
            <button
              class="delete-approval-cancel"
              @click="closeDialog()"
            >
              No
            </button>
            <button
              class="delete-approval-submit w-110px"
              @click="confirmRemoveCcid()"
            >
              Yes
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DeleteApprovalConfirmationDialog',
  computed: {
    ...mapGetters({
      approvalDetail: 'consolidate/getApprovalDelete',
      useCCIDStorage: 'getUseCCIDStorage',
      voidedStop: 'getStopVoidStatus',
    }),
  },
  data() {
    return {
      dialog: false,
      approvalType: this.$route.name,
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close', false);
    },
    rxEditApprovalSequenceCCIDs(ccids) {
      return ccids.join(', ');
    },
    confirmDelete() {
      if (this.approvalDetail.type === 'retail') {
        const options = {
          stopApprovalId: this.approvalDetail.stopApprovalId,
          stopId: this.approvalDetail.stopId,
          approvalId: this.approvalDetail.approvalId,
        };
        this.$store.dispatch('consolidate/deleteFinalApproval', options);
      } else {
        this.$store.dispatch(
          'consolidate/deleteRxItems', {
            id: this.approvalDetail.stopRxItemId,
            stopId: this.approvalDetail.stopId,
          },
        );
      }
    },
    confirmRemoveCcid() {
      this.$emit('close', false);
      this.$store.dispatch('consolidate/removeRealCCIDAction', this.ccidObject.consolidateDataid);
    },
  },
  props: {
    openDialog: Boolean,
    ccidObject: {
      type: Object,
      default() {
        return {
          popupTitle: '',
        };
      },
    },
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
    voidedStop() {
      this.dialog = false;
    },
  },
};
</script>
