//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'ViewAllCCIDCard',
  computed: {
    ...mapGetters({ useCCIDStorage: 'getUseCCIDStorage' }),
    getCCIDUnit() {
      return this.useCCIDStorage ? this.ccidList.weight_unit : this.ccidList.manifest_unit.unit;
    },
  },
  methods: {
    viewContents() {
      this.ccidList.show_contents = true;
    },
    hideContents() {
      this.ccidList.show_contents = false;
    },
    checkNdc(stopList) {
      return stopList.some((approval) => approval.ndc_code);
    },
    reopen() {
      this.$store.dispatch('reopenApproval', { containerId: this.ccidList.id, stopId: this.stopId, onlyTripInventory: this.onlyTripInventory });
    },
    markasFull() {
      this.$store.dispatch('markasFull', { containerId: this.ccidList.id, stopId: this.stopId, onlyTripInventory: this.onlyTripInventory });
    },
    getArrow(type) {
      if (this.ccidList.show_contents) {
        if (type === 'src') return 'src/assets/images/up-arrow-active.svg';
        return 'up-arrow';
      }
      if (type === 'src') return 'src/assets/images/down-arrow-active.svg';
      return 'down-arrow';
    },
  },
  props: {
    ccidList: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    stopId: {
      type: Number,
      default: 0,
    },
    onlyTripInventory: {
      type: Boolean,
      default: false,
    },
  },
};
