import colorsList from '../../constants/colorConstant';

export default function colorGenerator(containerName) {
  const firstWord = containerName.toLowerCase().split(' ')[0];
  if (firstWord === colorsList.colorNames.red) {
    return 'linear-gradient(180deg, #f5515f 0%, #9f041b 100%)';
  } if (firstWord === colorsList.colorNames.blue) {
    return 'linear-gradient(180deg, #37b4e5 0%, #477cfe 100%)';
  } if (firstWord === colorsList.colorNames.black) {
    return 'linear-gradient(180deg, #b7bdc6 0%, #202528 100%)';
  } if (firstWord === colorsList.colorNames.white) {
    return 'linear-gradient(180deg, #ffffff 0%, #ebebeb 100%)';
  } if (firstWord === colorsList.colorNames.yellow) {
    return 'linear-gradient(180deg, #ffffff 0%, #ffb92e 100%)';
  }
  return null;
}
