//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import documentConstants from '../../../constants/document';
import printService from '../../../service/print.backend.service';
import { API_BASE_URL } from '../../../constants/index';

const documentTypes = documentConstants.types;

export default {
  name: 'DocumentTableItem',
  computed: {
    isReprinted() {
      return this.rowData.reprinted;
    },
    manifestNumberValue() {
      if (this.isManifestDocument() && this.rowData.name === '') return this.rowData.original_name;
      return this.rowData.name;
    },
  },
  methods: {
    switchDocumentItemSelection() {
      this.$store.dispatch(
        'stopDocuments/updateDocumentSelectionStatus',
        this.rowData,
      );
    },
    isManifestDocument() {
      return [
        documentTypes.HAZ_MANIFEST,
        documentTypes.HAZ_CONTINUATION,
        documentTypes.LAND_DISPOSAL,
        documentTypes.NON_HAZ_MANIFEST,
        documentTypes.NON_HAZ_CONTINUATION,
      ].indexOf(this.rowData.type) > -1;
    },
    showSDSPDF() {
      const stopId = this.$store.getters.getStopDetail.id;
      const payload = {
        doc_type: this.rowData.type,
        cloud_id: this.rowData.cloud_id,
        original_name: this.rowData.original_name,
        tsdf: this.rowData.tsdf,
      };
      printService.printDocument(payload, stopId, true).then(
        () => {
          const cacheBuster = Date.now();
          const sdsUrl = `${API_BASE_URL}stops/view/pdf#toolbar=0&navpanes=0&timestamp=${cacheBuster}`;
          this.$store.dispatch('stopDocuments/setCurrentSDSUrl', sdsUrl);
        },
        () => {
          this.$store.dispatch('stopDocuments/setCurrentSDSApiFail');
        },
      );
    },
  },
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      },
    },
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
    printingDocListTable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
