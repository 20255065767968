<template>
  <div class="pickup-data-full-content">
    <div class="pickup-information-container">
      <div class="pickup-data-container">
        <div class="stop-container">
          <div class="header-text">
            Pickup data
          </div>
          <div class="info-label">
            <div class="label-text">
              Stop
            </div>
            <div class="info-text">
              {{ getStopDetail.generator.name }}
            </div>
          </div>
          <div class="info-label">
            <div class="label-text">
              Notes
            </div>
            <div class="info-text">
              {{ getStopDetail.comments ? getStopDetail.comments : 'None.' }}
            </div>
          </div>
          <div class="info-label">
            <div class="label-text">
              Directions
            </div>
            <div class="info-text">
              {{ getStopDetail.site_directions ? getStopDetail.site_directions : 'None.' }}
            </div>
          </div>
        </div>
        <div class="past-stop-container">
          <div class="header-text">
            Past stop info
          </div>
          <div class="past-stop-info-container">
            <div class="past-stop-info">
              <div class="title-text">
                Last stop completed
              </div>
              <div
                class="label-text"
                v-if="getLastStopData.actual_departure_time"
              >
                {{
                  getLastStopData.actual_departure_time | dateFormat
                }}
              </div>
              <div
                class="label-text"
                v-else
              >
                Info unavailable
              </div>
            </div>
            <div class="past-stop-info">
              <div class="title-text">
                Technician
              </div>
              <div class="label-text">
                {{
                  getLastStopData.generator_sign_name ? getLastStopData.generator_sign_name : '-'
                }}
              </div>
            </div>
            <div class="d-flex approval-container">
              <div class="past-stop-info flex-1">
                <div class="title-text">
                  Number of approvals
                </div>
                <div class="label-text">
                  {{
                    getLastStopData.number_of_approvals ? getLastStopData.number_of_approvals : '-'
                  }}
                </div>
              </div>
              <div class="past-stop-info flex-1">
                <div class="title-text">
                  Number of CCIDs
                </div>
                <div class="label-text">
                  {{ getLastStopData.number_of_ccid ? getLastStopData.number_of_ccid : '-' }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex button-container"
            v-if="lastStopInventoryExists"
          >
            <v-btn
              class="btn inventory-btn"
              :class="{'disable-last-stop-btn' : !lastStopInventoryExists}"
              @click="openLastStopInventory"
            >
              Last stop inventory
            </v-btn>
            <!-- <v-btn class="btn note-btn">
              Last stop notes
            </v-btn> -->
          </div>
        </div>
      </div>
    </div>
    <last-stop-inventory-list
      v-if="openLastStopInventoryList"
      :last-stop-inventory-list="lastStopInventoryList"
      :last-stop-completed-date="lastStopCompletedDate"
      :inventory-use-ccid="inventoryUseCcid"
      @closeInventoryList="closeInventoryList"
    />
    <expected-pickup
      :stop-id="stopId"
      v-else
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ExpectedPickup from './expectedPickup.vue';
import LastStopInventoryList from './lastStopInventory.vue';
import connectedStopConstant from '../../constants/connected-stop';
import tripStorage from '../../constants/trip-storage';

export default {
  name: 'PickupData',
  components: { ExpectedPickup, LastStopInventoryList },
  computed: {
    ...mapGetters(['getStopDetail', 'getLastStopData']),
    isCCIDStorage() {
      return this.getStopDetail.trip.storage_method === tripStorage.storageMethods.use_ccid;
    },
    lastStopInventoryExists() {
      return this.getLastStopData.last_stop_inventory.length > 0;
    },
  },
  data() {
    return {
      openLastStopInventoryList: false,
      lastStopInventoryList: [],
      lastStopCompletedDate: null,
    };
  },
  created() {
    this.$store.dispatch('getLastStopData', this.stopId);
    if (this.getStopDetail.connected_status
      === connectedStopConstant.status.ACCUMULATION_DATA_PRESENT) {
      this.$store.dispatch('getAccumulationPickupList', this.stopId);
    } else if (this.getStopDetail.connected_status
      === connectedStopConstant.status.PRIOR_DATA_PRESENT) {
      this.$store.dispatch('getExpectedPickupList', this.stopId);
    }
  },
  beforeDestroy() {
    this.$store.commit('resetLastStopData');
  },
  methods: {
    openLastStopInventory() {
      this.openLastStopInventoryList = true;
    },
    closeInventoryList() {
      this.openLastStopInventoryList = false;
    },
  },
  props: {
    stopId: {
      type: String,
      required: true,
    },
  },
  watch: {
    getLastStopData(newVal) {
      this.lastStopInventoryList = newVal.last_stop_inventory;
      this.lastStopCompletedDate = newVal.actual_departure_time;
      this.inventoryUseCcid = newVal.number_of_ccid > 0;
    },
  },
};
</script>
