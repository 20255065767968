//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import pinToolTip from '../tool-tip/pinToolTip.vue';
import pinDigit from './pinDigit.vue';
import pinButton from './pinButton.vue';


export default {
  name: 'PinDialog',
  components: { pinToolTip, pinDigit, pinButton },
  computed: {
    ...mapGetters({
      errorMessage: 'authentication/getError',
      isCreateAccount: 'createAccount/getPinDialogValue',
      createAccountData: 'createAccount/getCreateAccountValue',
      loginStatus: 'authentication/getloginStatus',
    }),
  },
  data() {
    return {
      dialog: false,
      pin: '',
      inValidPin: false,
      error: '',
      showToolTip: false,
      pinSingleDigit: '',
      confirmPin: false,
      firstPin: '',
      secondPin: '',
    };
  },
  methods: {
    handleInput(pin) {
      if (pin === 'cancel') {
        this.pin = '';
        this.dialog = false;
        this.inValidPin = false;
        this.error = '';
        this.firstPin = '';
        this.secondPin = '';
        this.confirmPin = false;
        return;
      }
      if (this.pin.length === 4) {
        return;
      }
      this.pinSingleDigit = pin;
    },
    gotoForgotPin() {
      this.dialog = false;
      this.$store.dispatch('authentication/setLoginEmailAction', this.email);
      this.$router.push('/forgot-pin');
    },
    deletePin() {
      if (this.pin.length > 0) {
        this.pin = this.pin.slice(0, -1);
      }
    },
    pinSubmit() {
      if (!this.inValidPin && this.pin.length !== 4) {
        this.inValidPin = true;
        this.error = 'Invalid pin. Try again?';
      }
      if (this.inValidPin === false && this.pin.length === 4) {
        this.inValidPin = false;
        this.error = '';
        this.$store.dispatch('authentication/loginAction', {
          email: this.email,
          password: this.pin,
        });
      }
    },
    createPinSubmit() {
      if (!this.inValidPin && this.pin.length !== 4) {
        this.inValidPin = true;
        this.error = 'Invalid pin. Try again?';
      }
      if (this.inValidPin === false && this.pin.length === 4) {
        this.inValidPin = false;
        this.error = '';
        this.firstPin = this.pin;
        this.pin = '';
        this.confirmPin = true;
      }
    },
    confirmPinSubmit() {
      if (!this.inValidPin && this.pin.length !== 4) {
        this.inValidPin = true;
        this.error = 'Invalid pin. Try again?';
      }
      if (this.inValidPin === false && this.pin.length === 4) {
        this.inValidPin = false;
        this.error = '';
        if (this.firstPin === this.pin) {
          this.secondPin = this.pin;
          this.$store.dispatch('createAccount/createAccountAction', {
            first_name: this.createAccountData.first_name,
            last_name: this.createAccountData.last_name,
            email: this.createAccountData.email,
            phone_number: this.createAccountData.phone_number,
            pin: this.secondPin,
          }).then((res) => {
            if (res === 'failed') {
              this.pin = '';
              this.dialog = false;
              this.inValidPin = false;
              this.error = '';
              this.firstPin = '';
              this.secondPin = '';
              this.confirmPin = false;
            } else {
              this.$store.dispatch('authentication/loginAction', {
                email: this.createAccountData.email,
                password: this.secondPin,
              });
            }
          });
        } else {
          this.inValidPin = true;
          this.error = 'PINs do not match. Try again.';
        }
      }
    },
    inputButtonChanges(input) {
      this.handleInput(input);
    },


  },
  props: {
    openDialog: Boolean,
    email: {
      type: String,
      default: '',
    },
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
    dialog(newVal) {
      if (!newVal) {
        this.pin = '';
        this.error = '';
        this.inValidPin = false;
      }
      this.$emit('dialogInputChanged', newVal);
    },
    errorMessage(newVal) {
      if (newVal) {
        this.inValidPin = true;
        this.error = newVal;
        this.showToolTip = true;
      }
    },
    pinSingleDigit(newVal) {
      if (newVal) {
        this.pin += this.pinSingleDigit;
        this.pinSingleDigit = '';
        if (this.inValidPin && this.pin.length === 4) {
          this.inValidPin = false;
          this.error = '';
        }
      }
    },
    loginStatus(newVal) {
      if (newVal === true) {
        this.$router.push('logged-in-home');
      }
    },
  },


};
