import api from '../service/notes.service';

const state = {
  commonNotes: null,
  status: null,
  stopNotes: null,
};

const getters = {
  getCommonNotes(context) {
    return context.commonNotes;
  },
  getStopNotes(context) {
    return context.stopNotes;
  },
};

const mutations = {
  setStopNotes(stateControl, data) {
    const stateControld = stateControl;
    stateControld.stopNotes = data;
  },
  setCommonNotes(stateControl, data) {
    const stateControld = stateControl;
    stateControld.commonNotes = data;
  },
  updateStatus(stateControl, data) {
    const stateControld = stateControl;
    stateControld.status = data;
  },
};

const actions = {
  getStopNotes({ commit }, res) {
    return new Promise((resolve) => {
      api.getStopNotesApi(res).then((resData) => {
        if (resData) {
          commit('setStopNotes', resData);
          commit('updateStatus', 'Success');
          resolve('Success');
        }
      }).catch(() => {
        resolve('Failed');
        commit('updateStatus', 'Failed');
      });
    });
  },
  getAllCommonNotes({ commit }) {
    return new Promise((resolve) => {
      api.getAllCommonNotesApi().then((resData) => {
        if (resData) {
          commit('setCommonNotes', resData);
          commit('updateStatus', 'Success');
          resolve('Success');
        }
      }).catch(() => {
        resolve('Failed');
        commit('updateStatus', 'Failed');
      });
    });
  },
  saveStopNotes({ commit }, data) {
    return new Promise((resolve) => {
      api.saveStopNotesApi(data).then((resData) => {
        if (resData) {
          resolve('Success');
          commit('updateStatus', 'Success');
        }
      }).catch(() => {
        resolve('Failed');
        commit('updateStatus', 'Failed');
      });
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
