<template>
  <div class="container margin-top-215">
    <h1 class="h1-heading">
      Welcome.
    </h1>
    <v-row
      class="width-70p text-center margin-top-20"
    >
      <v-col
        md="6"
        class="border-right-1 height-170"
      >
        <p class="fs-24 color-black-light">
          New to Smarter Sorting?
        </p>
        <button
          class="btn-submit width-70p margin-top-20"
          @click="goToCreateAccount()"
        >
          Create an account
        </button>
      </v-col>
      <v-col
        md="6"
        class="height-170"
      >
        <p class="fs-24 color-black-light">
          Already have an account?
        </p>
        <button
          class="btn-back width-70p margin-top-20"
          @click="goToSignIn()"
        >
          Sign in
        </button>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'BootUp',

  methods: {
    goToSignIn() {
      this.$router.push({ name: 'Login' });
    },
    goToCreateAccount() {
      this.$router.push({ name: 'Create Account' });
    },
  },
};
</script>
