//
//
//
//
//
//


import tripList from '../trips/list/index.vue';

export default {
  name: 'LoggedInHomeComponent',
  components: { tripList },
};
