<template>
  <div>
    <div
      class="mobile-add-image-detail-card my-4"
    >
      <div class="add-attachment-number d-flex align-center justify-center">
        {{ parseInt(attachmentIndex) + 1 }}
      </div>
      <div class="pa-4">
        <img
          :src="attachmentDetail.$model.image_url"
          class="mobile-image-details-preview d-block mb-4"
          alt="Preview-image"
        >

        <label
          class="mobile-image-details-label mb-2"
        >
          Type
        </label>
        <v-select
          ref="mobileAttachment"
          outlined
          :items="attachmentTypes"
          height="49"
          v-model="attachmentDetail.$model.attachment_type"
          class="mobile-manifest-select"
          append-icon="fa-angle-down"
          :menu-props="{ offsetY: true }"
          background-color="white"
          placeholder="Select..."
          @click:append="closeSelectDropdown('mobileAttachment')"
        />

        <label
          class="mobile-image-details-label mb-2"
          :class="{ 'hasError': formSubmitted && attachmentDetail.description.$error}"
        >
          Description
        </label>

        <textarea
          minlength="0"
          maxlength="20"
          rows="3"
          v-model="attachmentDetail.$model.description"
          placeholder="Enter Description"
          class="d-block mobile-add-image-textarea"
          :class="{ 'hasError': formSubmitted && attachmentDetail.description.$error}"
        />


        <div
          class="d-flex mobile-add-image-details-delete
      justify-content-flex-end mt-4 pr-2"
        >
          <div
            @click.prevent="removeAttachment"
          >
            <div class="d-flex align-center">
              <img
                src="src/assets/images/trash-red.svg"
                class="mobile-image-delete-icon mr-2"
                alt="delete-icon"
              >
              <div>Delete</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import document from '../../../constants/document';
import VSelectArrow from '../../utils/v-select-arrow';

export default {
  name: 'MobileAttachmentsCard',
  data() {
    return {
      attachmentTypes: document.attachmentsType,
    };
  },
  extends: VSelectArrow,
  methods: {
    removeAttachment() {
      if (this.attachmentDetail.$model.isNew) {
        this.$store.commit('attachment/removeAttachmentByIndex', this.attachmentIndex);
      } else {
        const attachmentDetail = this.attachmentDetail.$model;
        attachmentDetail.stop_id = this.$store.state.stopDetail.stopDetail.doc_id;
        this.$store.dispatch('attachment/deleteAttachmentFromFirestore', attachmentDetail);
      }
    },
  },
  props: {
    attachmentDetail: {
      type: Object,
      default: () => ({
        id: '',
        image_url: '',
        description: '',
        attachment_type: 'Photo',
        stop_id: '',
      }),
    },
    attachmentIndex: {
      type: String,
      default: '',
    },
    formSubmitted: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
