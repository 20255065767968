//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters } from 'vuex';

export default {
  name: 'SearchLoader',
  computed: {
    ...mapGetters({ loadingStatus: 'loadingSearchIcon/getLoadingStatus' }),
  },
  methods: {
    goToBackPage() {
      this.$emit('goBack');
    },
  },
  props: {
    sdsUrlLink: {
      type: String,
      default: '',
    },
  },
};
