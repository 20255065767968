//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import documentConstants from '../../../constants/document';

const documentTypes = documentConstants.types;

export default {
  name: 'AttachmentTypeTable',
  computed: {
    ...mapGetters({
      attachments: 'attachment/getAttachments',
      combineAttachment: 'attachment/getAttachmentWithPrePopulatedData',
    }),
    attachmentExists() {
      return this.attachments.length > 0;
    },
  },
  methods: {
    deleteAttachment(id) {
      this.$store.dispatch('attachment/deleteAttachmentsData', id);
    },
    viewAttachmentPdf(attachment) {
      this.$store.dispatch('attachment/setCurrentAttachmentPdfUrl', attachment.raw_data);
      this.$store.commit('attachment/setCurrentAttachmentType', attachment.file_type);
    },
    scanAttachment(prePopulatedAttachment) {
      const attachment = {
        attachment_type: prePopulatedAttachment.type,
      };
      if (prePopulatedAttachment.type === documentTypes.HAZ_MANIFEST
        || prePopulatedAttachment.type === documentTypes.NON_HAZ_MANIFEST
        || prePopulatedAttachment.type === documentTypes.BOL) {
        attachment.manifest_name = prePopulatedAttachment.name;
        attachment.description = prePopulatedAttachment.name;
      } else {
        attachment.description = prePopulatedAttachment.name;
        attachment.manifest_name = '';
      }
      this.$store.dispatch('attachment/setSelectedPrePopulatedAttachment', prePopulatedAttachment);
      this.$emit('scanAttachment', attachment);
    },
  },
};
