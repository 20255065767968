//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import approvalListCard from './approvalListCard.vue';
import KeyboardDialog from '../../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import NoResultsFound from '../../../../helpers/component/no-results-found/no-results-found.vue';

export default {
  name: 'ApprovalList',
  components: {
    approvalListCard,
    KeyboardDialog,
    NoResultsFound,
  },
  computed: {
    ...mapGetters(['getApprovalLists', 'getStopDetail']),
    ...mapGetters({ getSearchResult: 'rxApproval/getSearchResult' }),
    getClassEnable() {
      return !!(this.searchVal && this.searchVal.length > 0);
    },
  },
  data() {
    return {
      searchVal: '',
      stopDetail: {},
      input: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      maxLength: '30',
      modelValue: '',
      listLength: 0,
      approvalCode: '',
    };
  },
  created() {
    this.getApprovalList();
    this.approvalCode = this.$route.params.approvalCode;
  },
  methods: {
    goToBack() {
      this.$store.dispatch('assignApprovalAction', this.$store.state.approvalList.approvalDetail);
      this.$router.push({ name: 'Add Approval' });
    },
    getApprovalList() {
      this.stopDetail = this.$store.getters.getStopDetail;
      if (this.stopDetail) {
        this.$store.dispatch('setApprovalList', this.stopDetail.id);
      }
    },
    searchApprovals() {
      this.$store.dispatch('searchApprovals', { id: this.stopDetail.id, value: this.searchVal });
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.searchVal = value.input;
      this.searchApprovals();
    },
    moveToTop() {
      const listArea = document.getElementById('list-div');
      listArea.scrollTop = 0;
    },
    handleScroll() {
      const listArea = document.getElementById('list-div');
      this.listLength = listArea.scrollTop;
    },
  },
  watch: {
    getStopDetail(newVal) {
      if (!this.approvalCode) {
        this.stopDetail = newVal;
        this.getApprovalList();
      }
    },
    approvalCode: {
      handler() {
        if (this.approvalCode) {
          this.searchVal = this.approvalCode;
          this.searchApprovals();
        }
      },
      deep: true,
    },
    '$route.params.approvalCode': function detectSearchKeyChange() {
      this.approvalCode = this.$route.params.approvalCode;
    },
  },
};
