import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function fetchSetting(name) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}setting/${name}`;
  return fetchService.getFetchData(url, requestOptions);
}

function createSetting(name, data) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ details: data }),
  };
  const url = `${API_BASE_URL}setting/${name}`;

  return fetchService.getFetchData(url, requestOptions);
}

const settingService = {
  fetchSetting,
  createSetting,
};
export default settingService;
