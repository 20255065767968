<template>
  <tr
    v-if="summaryData"
    class="stop-summary-table-data"
  >
    <td class="profile-item-data">
      <span><pre>{{ summaryData.approvalDescription }}</pre></span>
    </td>
    <td class="weight-item-data">
      <span v-if="summaryData.retailSisWeight">
        {{ formattedRetailSisWeight }} LB
      </span>
      <span v-else>N/A</span>
    </td>
    <td class="weight-item-data">
      <span v-if="summaryData.truckSisWeight">
        {{ formattedTruckSisWeight }} LB
      </span>
      <span v-else>N/A</span>
    </td>
  </tr>
</template>

<script>
export default {
  name: 'StopSummaryItem',
  computed: {
    formattedRetailSisWeight() {
      return Number(this.summaryData.retailSisWeight).toFixed(2);
    },
    formattedTruckSisWeight() {
      return Number(this.summaryData.truckSisWeight).toFixed();
    },
  },
  props: {
    summaryData: {
      type: Object,
      default() {
        return { };
      },
    },
  },
};
</script>
