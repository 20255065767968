import connectivityApi from '../service/connectivity.service';

const state = {
  hasNetworkConnection: false,
};

const getters = {
  getHasNetworkConnection(stateControl) {
    return stateControl.hasNetworkConnection;
  },
};

const mutations = {
  setHasNetworkConnection(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.hasNetworkConnection = payload;
  },
};

const actions = {
  setNetworkConnection({ commit }) {
    let api = '';
    if (process.env.NODE_ENV.includes('handheld')) {
      api = connectivityApi.mobileConnectivityCheck();
    } else {
      api = connectivityApi.connectivityCheck();
    }
    api
      .then(() => {
        commit('setHasNetworkConnection', true);
      })
      .catch(() => {
        commit('setHasNetworkConnection', false);
      });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
