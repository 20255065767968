<template>
  <div
    class="stop-information-container"
  >
    <div class="height-vh-100">
      <div class="stop-information-inner-container">
        <stop-info-header />
        <component
          :is="getComponentName"
          @openInventoryList="openInventoryList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import stopInfoHeader from './stopInformationHeader.vue';
import stopInformation from './stopInformation.vue';
import rxDrawer from '../../rx-items/rx-drawer/index.vue';

export default {
  name: 'StopInformationWorkArea',
  components: {
    stopInfoHeader,
    stopInformation,
    rxDrawer,
  },
  computed: {
    ...mapGetters(['getComponentName']),
  },
  methods: {
    openInventoryList() {
      this.$emit('openInventoryList');
    },
  },
};
</script>
