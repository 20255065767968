<template>
  <div class="logout-container-main">
    <v-overlay
      :value="dialog"
      opacity="0.94"
      z-index="3"
    >
      <div class="logout-container">
        <div class="logout-content-box">
          <div class="power-off-header">
            <img
              class="power-off-icon"
              src="src/assets/images/alert.svg"
            >
            <p
              class="power-off-popup-title"
            >
              Are you sure you want to shut down or restart now?
            </p>
            <p
              class="power-off-popup-msg"
              v-if="downloadInProgress"
            >
              A software update is currently downloading. <br>
              Please wait until it is complete before you shutdown or restart.
            </p>
          </div>
          <div
            class="logout-confirmation-btn"
            :class="{'no-download': !downloadInProgress}"
          >
            <div>
              <button
                class="Cancel"
                @click="cancelShutdown()"
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                class="shutdown-btn"
                @click="shutdown()"
              >
                <img
                  alt="shutdown"
                  class="margin-right-5"
                  src="src/assets/images/power-button-white.svg"
                > &nbsp;
                Shut down
              </button>
            </div>
            <div>
              <button
                class="restart-btn"
                @click="restart()"
              >
                <img
                  alt="restart"
                  class="margin-right-5"
                  src="src/assets/images/restart-button-white.svg"
                > &nbsp;
                Restart
              </button>
            </div>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PowerOffConfirmation',
  computed: {
    ...mapGetters({
      restartNow: 'authentication/getRestartValue',
      shutdownNow: 'authentication/getShutdownValue',
      downloadInProgress: 'getDownloadInProgress',
    }),
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    shutdown() {
      this.$store.dispatch('authentication/shutdown').then(() => {
        this.$router.push('/shutdown');
      });
    },
    restart() {
      this.$store.dispatch('authentication/restart').then(() => {
        this.$router.push('/restart');
      });
    },
    cancelShutdown() {
      this.$emit('close-dialog', false);
    },
  },
  props: {
    openDialog: Boolean,
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
    restartNow(newVal) {
      if (newVal === true) {
        this.$emit('close-dialog', false);
        this.$router.push('/restart');
      }
    },
    shutdownNow(newVal) {
      if (newVal === true) {
        this.$emit('close-dialog', false);
        this.$router.push('/shutdown');
      }
    },
  },
};
</script>
