<template>
  <div class="mobile-device">
    <div class="reset-pin-container">
      <div class="container-text">
        Reset Your PIN
      </div>
      <div>
        <label>New PIN</label>
        <div class="input-container">
          <i class="icon fas fa-lock" />
          <input
            class="input-field"
            type="password"
            name="resetPin"
          >
        </div>
      </div>
      <div>
        <label>Retype PIN</label>
        <div class="input-container">
          <i class="icon fas fa-lock" />
          <input
            class="input-field"
            type="password"
            name="confirmResetPin"
          >
        </div>
      </div>
      <div class="container-button flex align-item justify-content">
        <a>Show PIN</a>
      </div>
      <div>
        <button
          type="submit"
          class="btn-submit"
        >
          Confirm new PIN
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ResetPin',
  components: {},
  computed: {},
  data() {
    return {};
  },
  methods: {},
  validations: {},
};
</script>
