import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function getStopNotesApi(stopsId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/notes/${stopsId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getAllCommonNotesApi() {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}common_notes_list`;
  return fetchService.getFetchData(url, requestOptions);
}

function saveStopNotesApi(data) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ notes: data.notes }),
  };
  const url = `${API_BASE_URL}stops/notes/${data.stopsId}`;
  return fetchService.getFetchData(url, requestOptions);
}

const NotesService = {
  saveStopNotesApi,
  getAllCommonNotesApi,
  getStopNotesApi,
};
export default NotesService;
