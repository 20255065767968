import masterScanService from '../service/master-scan.service';
import logService from '../service/log.service';
import settingService from '../service/setting.service';

const state = {
  masterScanned: {},
  photoUrl: '',
  isStreaming: true,
  unknownLogCreationInProgress: false,
  isMonitoringScanGunInput: false,
  partialUpc: '',
  fullUpc: '',
  testUpc: '',
  scanningEnabled: true,
};

const getters = {
  getMasterScanned(stateControl) {
    return stateControl.masterScanned;
  },
  getPhotoUrl(stateControl) {
    return stateControl.photoUrl;
  },
  getIsStreaming(stateControl) {
    return stateControl.isStreaming;
  },
  getPartialUpc(stateControl) {
    return stateControl.partialUpc;
  },
  getFullUpc(stateControl) {
    return stateControl.fullUpc;
  },
  getTestUpc(stateControl) {
    return stateControl.testUpc;
  },
  getIsMonitoringScanGunInput(stateControl) {
    return stateControl.isMonitoringScanGunInput;
  },
  getScanningEnabled(stateControl) {
    return stateControl.scanningEnabled;
  },
};

const mutations = {
  setMasterScanned(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.masterScanned = payload;
  },
  setPhotoUrl(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.photoUrl = payload;
  },
  setIsStreaming(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.isStreaming = payload;
  },
  setUnknownLogCreationInProgress(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.unknownLogCreationInProgress = payload;
  },
  setPartialUpc(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.partialUpc = payload;
  },
  setFullUpc(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.fullUpc = payload;
  },
  setTestUpc(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.testUpc = payload;
  },
  setIsMonitoringScanGunInput(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.isMonitoringScanGunInput = payload;
  },
  setScanningEnabled(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.scanningEnabled = payload;
  },
};

const actions = {
  getMasterFromBarcode({ commit }, payload) {
    masterScanService.getMasterFromBarcode(payload.barcode, payload.stopId).then((res) => {
      let scannedProduct;
      if (!res.cloud_id) {
        scannedProduct = {
          upc: payload.barcode,
          cloud_id: '',
          product_name: '',
          product_category: '',
          manufacturer: '',
          sds_link: '',
          item_number: '',
          min_divert_weight: null,
          special_handling: '',
          master_bin_rule: null,
          item_weight: null,
        };
      } else {
        scannedProduct = res;
      }
      commit('setMasterScanned', scannedProduct);
    }).catch(() => {
      // api error handle
    });
  },
  setIsStreaming({ commit }, payload) {
    commit('setIsStreaming', payload);
  },
  createUnknownItemLog({ commit }, payload) {
    commit('setUnknownLogCreationInProgress', true);
    logService.createLog(payload.log, payload.stopId).then(() => {
      commit('setUnknownLogCreationInProgress', false);
    });
  },
  startMonitoringScanGunInput({ commit }) {
    commit('setIsMonitoringScanGunInput', true);
  },
  stopMonitoringScanGunInput({ commit }) {
    commit('setIsMonitoringScanGunInput', false);
  },
  addCharacterToScannedUpc(context, payload) {
    context.commit('setPartialUpc', context.state.partialUpc + payload);
  },
  upcScanComplete(context) {
    context.commit('setFullUpc', context.state.partialUpc);
  },
  resetUpc({ commit }) {
    commit('setPartialUpc', '');
    commit('setFullUpc', '');
  },
  setTestUpc(context) {
    context.commit('setTestUpc', context.state.partialUpc);
  },
  checkIfItemScanningEnabled({ commit }) {
    settingService.fetchSetting('item_scanning_enabled').then((res) => {
      commit('setScanningEnabled', res.setting.value === 'true');
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
