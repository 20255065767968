//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import MainStopPageNavList from '../../../helpers/component/main-stop-page-nav-list/mainStopPageNavList.vue';
import stopInfoContent from './stopInfoContent.vue';

export default {
  name: 'MobileStopDetailPage',
  components: {
    MainStopPageNavList,
    stopInfoContent,
  },
  computed: {
    ...mapGetters({
      stopDetail: 'getStopDetail',
      navList: 'getStopNavList',
      hasNetwork: 'getHasNetworkConnection',
    }),
  },
  data() {
    return {
      show: false,
      syncToTruck: true,
      connectionInterval: {},
    };
  },
  created() {
    this.checkNetworkConnection();
    this.$store.commit('rxApprovalList/setPListedBottleStatus', true);
    this.$store.dispatch('questionnaire/getQuestionAndNumbersMobile', { stopId: this.stopDetail.doc_id });
    this.$store.dispatch('attachment/getAttachmentsDataFromFirestore', this.stopDetail.doc_id);
    this.$store.dispatch('rxApprovalList/getMobileRxApprovalList', this.stopDetail.doc_id);
    this.$store.commit('rxApproval/setRxItemMainPropDefault');
  },
  destroyed() {
    clearInterval(this.connectionInterval);
  },
  methods: {
    showContent(type) {
      if (this.show && type === 'outer') {
        return;
      }
      this.show = !this.show;
    },
    getClass(stopType) {
      let className = 'dea-active';
      if (stopType.toLowerCase() === 'rx') {
        className = 'rx-active';
      }
      if (stopType.toLowerCase() === 'st') {
        className = 'st-active';
      }
      if (stopType.toLowerCase() === 'fs') {
        className = 'fs-active';
      }
      return className;
    },
    goToPage(ev) {
      this.$router.push({ name: ev.routerPath });
    },
    checkAddSync() {
      let status = false;
      this.navList.forEach((list) => {
        if (list.edited) {
          status = true;
        }
      });
      return status;
    },
    checkNetworkConnection() {
      this.$store.dispatch('setNetworkConnection');
      this.connectionInterval = setInterval(() => {
        this.$store.dispatch('setNetworkConnection');
      }, 20000);
    },
  },
  watch: {
    navList: {
      deep: true,
      handler(val) {
        if (val[2].edited) {
          this.syncToTruck = false;
        } else {
          this.syncToTruck = true;
        }
      },
    },
  },
};
