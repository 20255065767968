//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UnAuthHeader from './header.vue';

export default {
  name: 'AuthHeader',
  components: {
    UnAuthHeader,
  },
};
