<template>
  <div>
    <div
      class="mobile-rx-list-item-app"
      @click="goToApprovalDetails(rxItem)"
    >
      <p class="mobile-rx-list-approval-name">
        {{ rxItem.ndc_code }}
      </p>
      <p class="mobile-rx-list-approval-description">
        {{ rxItem.rx_item_description }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MobileRxItemListCard',
  created() {
    this.$store.commit('loadingSearchIcon/loadingRequest', { status: false, loadingName: 'Loading search results' }, { root: true });
  },
  methods: {
    goToApprovalDetails(rxItem) {
      this.$store.commit('rxApproval/setSelectedRxItem', rxItem);
      this.$router.push({ name: 'Mobile View Item Details' });
    },
  },
  props: {
    rxItem: {
      type: Object,
      default: () => { },
    },
  },
};
</script>
