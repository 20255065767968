<template>
  <div class="container">
    <v-dialog
      v-model="dialog"
      width="22%"
      :persistent="true"
      :overlay-opacity="0.5"
      :hide-overlay="false"
      class="box-shadow-none"
      @click:outside="clickOutside"
    >
      <v-card>
        <div
          class="keyboard-input-div"
        >
          <div class="row">
            <div class="col-md-8 pr-0">
              <span
                class="keyboard-span d-block text-center mb-2"
              >
                Edit arrival time
              </span>
              <div class="d-flex time-pick justify-content-center">
                <div class="time-field">
                  <div
                    v-if="timeError"
                    class="wrong-time-error"
                  >
                    <span class="forgot-font-weight">!</span>
                    &nbsp; &nbsp; Arrival time cannot be set to a future value
                  </div>
                  <input
                    :value="arrivedTimeObject.hours.padStart(1, '0')"
                    class="input"
                    :class="{ 'time-inp-active': selectedInput === 'hours' }"
                    placeholder=""
                    @selectstart.prevent
                    @paste.prevent
                    @copy.prevent
                    @cut.prevent
                    @drag.prevent
                    @drop.prevent
                    v-unSelect
                    ref="hours"
                    @click="changeInputSelection('hours')"
                  >
                </div>
                <div class="colon">
                  :
                </div>
                <div class="time-field">
                  <input
                    :value="arrivedTimeObject.minutes.padStart(2, '0')"
                    class="input"
                    :class="{ 'time-inp-active': selectedInput === 'minutes' }"
                    placeholder=""
                    @selectstart.prevent
                    @paste.prevent
                    @copy.prevent
                    @cut.prevent
                    @drag.prevent
                    @drop.prevent
                    v-unSelect
                    ref="minutes"
                    @click="changeInputSelection('minutes')"
                  >
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="ampm-radio-group">
                <div class="ampm-radio-div">
                  <input
                    type="radio"
                    id="am"
                    name="timeperiod"
                    :checked="arrivedTimeObject.meridiem==='am'"
                    @click="changeInputSelection('meridiem', 'am')"
                  >
                  <label
                    class="ampm-label"
                    for="am"
                  >
                    AM
                  </label>
                </div>
                <div class="ampm-radio-div">
                  <input
                    type="radio"
                    id="pm"
                    name="timeperiod"
                    :checked="arrivedTimeObject.meridiem==='pm'"
                    @click="changeInputSelection('meridiem', 'pm')"
                  >
                  <label
                    class="ampm-label"
                    for="pm"
                  >
                    PM
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <KeyboardNumeric
          @onChange="onChange"
          @onKeyPress="onKeyPress"
          :input="input"
          :keypad-for="'ArrivedTime'"
        />
        <div
          class="keypad-button-div"
        >
          <button
            class="keyboard-cancel-grey"
            @click="cancel()"
          >
            Cancel
          </button>
          <button
            :disabled="timeError"
            @click.prevent="confirm()"
            class="keyboard-submit-green"
            :class="{'consolidate-btn-disabled' : timeError}"
            type="button"
          >
            Confirm
          </button>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import KeyboardNumeric from './keypad.vue';

export default {
  name: 'ArrivalTimeUpdate',
  components: {
    KeyboardNumeric,
  },
  computed: {
    timeStr() {
      let { hours, minutes } = this.arrivedTimeObject;
      const { meridiem } = this.arrivedTimeObject;
      if (hours === '12') {
        if (meridiem === 'pm') {
          hours = 12;
        } else if (meridiem === 'am') {
          hours = 0;
        }
      } else if (meridiem === 'pm') {
        hours = Number(hours) + 12;
      }
      if (!minutes) minutes = '00';
      return `${hours}:${minutes}`;
    },
    updatedTime() {
      const date = moment.parseZone(this.arrivedTime).format('YYYY-MM-DD');
      return moment.parseZone(`${date} ${this.timeStr}${this.utcOffset}`, 'YYYY-MM-DD HH:mmZ');
    },
    timeError() {
      const currentTime = moment().utcOffset(this.utcOffset);
      return currentTime.isBefore(this.updatedTime);
    },
  },
  data() {
    return {
      dialog: false,
      selectedInput: 'hours',
      input: '',
      arrivedTimeObject: {},
      button: '',
      clean: true,
      utcOffset: '',
    };
  },
  created() {
    this.initiateArrivedTimeObj();
  },
  methods: {
    initiateArrivedTimeObj() {
      const arrivedTime = moment.parseZone(this.arrivedTime);
      this.utcOffset = this.utcToHoursAndMinutes(arrivedTime);
      const hours = arrivedTime.format('h');
      const minutes = arrivedTime.format('m');
      const meridiem = arrivedTime.format('a');
      this.arrivedTimeObject = { hours, minutes, meridiem };
      this.clean = true;
    },
    utcToHoursAndMinutes(arrivedTime) {
      const offset = arrivedTime.utcOffset();
      const time = `${(offset / 60)}:${Math.abs(offset % 60)}`;
      let plusOrMinus = '+';
      if (time[0] === '-') plusOrMinus = '-';
      const newOffsetTime = `${plusOrMinus}${moment(time, 'hh:mm').format('hh:mm')}`;
      return newOffsetTime;
    },
    closeDialog() {
      this.dialog = false;
    },
    clickOutside() {
      this.onKeyPress('outside');
    },
    changeInputSelection(type, value = '') {
      this.selectedInput = type;
      if (type === 'meridiem') {
        this.arrivedTimeObject.meridiem = value;
      } else {
        this.arrivedTimeObject[type] = '';
        this.input = this.arrivedTimeObject[type];
        this.$refs[type].focus();
      }
    },
    onChange() {
      const initialZeroAddition = this.arrivedTimeObject[this.selectedInput] === '' && this.button === '0';
      let switchFocusToMinutes = false;
      if (this.button === '{bksp}' || initialZeroAddition) {
        return;
      }
      if (this.clean) {
        this.arrivedTimeObject[this.selectedInput] = '';
        this.clean = false;
      }
      if (this.selectedInput === 'hours') {
        if (this.arrivedTimeObject[this.selectedInput] === '') {
          this.arrivedTimeObject[this.selectedInput] = this.button;
        } else if (this.arrivedTimeObject[this.selectedInput] === '1' && ['0', '1', '2'].includes(this.button)) {
          this.arrivedTimeObject[this.selectedInput] += this.button;
          switchFocusToMinutes = true;
        } else {
          this.changeInputSelection('minutes');
          this.onChange();
        }
      } else if (this.selectedInput === 'minutes') {
        if (this.arrivedTimeObject[this.selectedInput] === '') {
          this.arrivedTimeObject[this.selectedInput] = this.button;
        } else if (this.arrivedTimeObject[this.selectedInput].length === 1 && ['0', '1', '2', '3', '4', '5'].includes(this.arrivedTimeObject[this.selectedInput])) {
          this.arrivedTimeObject[this.selectedInput] += this.button;
        } else if (this.arrivedTimeObject[this.selectedInput].length === 2) {
          return;
        }
      }
      this.input = this.arrivedTimeObject[this.selectedInput];
      if (switchFocusToMinutes) this.changeInputSelection('minutes');
    },
    onKeyPress(button) {
      this.button = button;
      if (button === '{bksp}') {
        if (this.selectedInput === 'hours' || this.selectedInput === 'minutes') {
          this.arrivedTimeObject[this.selectedInput] = '';
        }
      }
    },
    resetDefault() {
      this.selectedInput = 'hours';
    },
    cancel() {
      this.resetDefault();
      this.closeDialog();
    },
    confirm() {
      if (this.arrivedTimeObject.hours !== '' && !this.timeError) {
        const { timeStr } = this;
        const timeAndDate = this.updatedTime.format();
        this.$nextTick(() => {
          this.$emit('enterClicked', { timeStr, timeAndDate });
          const self = this;
          setTimeout(() => {
            self.closeDialog();
            self.resetDefault();
          }, 300);
        });
      }
    },
  },
  props: {
    openDialog: Boolean,
    arrivedTime: {
      type: String,
      default: '',
    },
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
      if (newVal) {
        this.input = moment.parseZone(this.arrivedTime).format('h');
        this.initiateArrivedTimeObj();
      }
    },
    dialog(newVal) {
      this.$emit('arrivedTimeDialogInputChanged', newVal);
    },
  },
};
</script>
