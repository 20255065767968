<template>
  <div class="width-100p">
    <div class="d-flex flex-column ptb-30plr-20 ml-56p">
      <div class="btn-groups-main d-flex align-center width-100p">
        <div
          class="d-flex flex-1 align-center justify-center
          add-approval-main text-p mr-8 add-approval-img"
          :class="{'stop-complete-disable': stopCompleted}"
          @click.prevent="addApproval()"
        >
          <img
            src="src/assets/images/circle-plus.png"
            alt="circle-plus"
            class="circle-plus-img"
          >
          <div><label class="cursor-pointer">Add approval</label></div>
        </div>
        <div
          class="d-flex flex-1 align-center justify-center add-rx-main text-p add-approval-img"
          :class="{'stop-complete-disable': stopCompleted}"
          v-if="enableAddRxBtn"
          @click="gotoRXBuilder()"
        >
          <img
            src="src/assets/images/funnel.svg"
            alt="filter"
          >
          <div><label class="cursor-pointer">Add NDCs / empty bottles</label></div>
        </div>
      </div>
    </div>
    <div
      class="ml-56p"
      v-if="0 === stopInventoryLists.length && !checkConnectedStopStatus()"
    >
      <div class="mt-8 ccid-label">
        <label>Stop inventory</label>
      </div>
      <div class="mt-8 no-approvals">
        <label>No approvals added.</label>
      </div>
    </div>
    <div
      class="d-flex flex-column"
      v-else
    >
      <div class="stop-inventory-header">
        <div class="d-flex justify-space-between custom-tab-outline">
          <div
            class="d-flex complete-ic-w flex-3"
          >
            <div v-if="checkConnectedStopStatus() && isActiveStop">
              <v-card
                class="pa-4 stop-category border3-r-4"
                outlined
                tile
                :class="showExpectedPickup ? 'inventoryBtnActive' : 'scaleInactive'"
                @click.prevent="changeSelection('expected pickup')"
              >
                <span class="bottom-line">Expected pickup</span>
              </v-card>
            </div>
            <div>
              <v-card
                class="pa-4 stop-category border3-r-4"
                outlined
                tile
                :class="showStopInventory ? 'inventoryBtnActive' : 'scaleInactive'"
                @click.prevent="changeSelection('stop')"
              >
                <span class="bottom-line">Stop inventory</span>
              </v-card>
            </div>
            <div>
              <v-card
                class="pa-4 stop-category border3-r-4"
                outlined
                tile
                @click.prevent="changeSelection('trip')"
                :class="showTripInventory ? 'inventoryBtnActive' : 'scaleInactive'"
              >
                <span class="bottom-line">Trip inventory</span>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <div class="inventory-card-container">
        <div class="inventory-card">
          <div class="d-flex flex-column position-relative">
            <template v-if="showStopInventory">
              <div
                class="mt-8 no-approvals"
                v-if="stopInventoryLists.length===0"
              >
                <label>No approvals added.</label>
              </div>
              <div v-else>
                <stop-inventory-card
                  class="mb-4"
                  v-for="(inventory, index) in stopInventoryLists"
                  :key="index"
                  :inventory="inventory"
                  :stop-completed="stopDetailChange.is_completed"
                  :stop-id="stopDetailChange.id"
                />
              </div>
            </template>
            <template v-if="showTripInventory">
              <div
                class="mt-8 no-approvals"
                v-if="getAllCcidList.length===0"
              >
                <label>No approvals added.</label>
              </div>
              <div v-else>
                <viewAllCCIDCard
                  class="mb-4"
                  v-for="(ccidList, index) in getAllCcidList"
                  :key="index"
                  :ccid-list="ccidList"
                  :index="index"
                  :stop-id="stopDetailChange.id"
                  :only-trip-inventory="false"
                />
              </div>
            </template>
            <template v-if="showExpectedPickup">
              <expected-pickup-inventory />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import stopInventoryCard from '../../../helpers/component/stop-inventory-card/stopInventoryCard.vue';
import viewAllCCIDCard from './viewAllCCIDCard.vue';
import expectedPickupInventory from '../../../helpers/component/expected-pickup-inventory/expectedPickupInventory.vue';
import connectedStopConstant from '../../../constants/connected-stop';

export default {
  name: 'StopDetailWorkArea',
  components: {
    stopInventoryCard,
    viewAllCCIDCard,
    expectedPickupInventory,
  },
  computed: {
    ...mapGetters({
      enableAddRxBtn: 'getEnableAddRxBtn',
      addToTruckInventorySuccess: 'consolidate/getAddToTruckInventorySuccess',
      addedCCIDs: 'consolidate/getAddedCCIDs',
      stopDetailChange: 'getStopDetail',
      stopInventoryLists: 'getStopInventoryLists',
      useCCIDStorage: 'getUseCCIDStorage',
      getAllCcidList: 'trip/getAllCcidList',
      activeInventoryTab: 'getStopInventoryActiveTab',
      expectedApiCallResponse: 'getAccumulationApiCallStatus',
      accumulationPickupList: 'getAccumulationPickupList',
    }),
    isActiveStop() {
      return this.stopDetailChange.is_active;
    },
    isAccumulationPickupData() {
      return this.stopDetailChange.connected_status === connectedStopConstant
        .status.ACCUMULATION_DATA_PRESENT;
    },
    isCompletedStop() {
      return this.stopDetailChange.is_completed;
    },
  },
  data() {
    return {
      stopDetail: {},
      stopCompleted: false,
      showCCIDSuccess: false,
      showFadeIn: false,
      showStopInventory: true,
      showTripInventory: false,
      showExpectedPickup: false,
    };
  },
  created() {
    const { consolidateApproval } = this.$store.state.approvalList;
    if (consolidateApproval && consolidateApproval.id) {
      this.resetApprovalAndRxApproval();
      if (consolidateApproval.waste_type && consolidateApproval.waste_type === 'rx') {
        this.$router.push({ name: 'RxConsolidate', params: { draftRxConsolidate: true } });
      } else if (this.useCCIDStorage) {
        this.$router.push({ name: 'Consolidate' });
      }
    }
    const { approvalDetail } = this.$store.state.approvalList;
    if (approvalDetail.id) {
      this.$router.push({ name: 'Add Approval' });
    }
    const { rxApprovalDetail } = this.$store.state.rxApproval;
    if (rxApprovalDetail.id) {
      this.$router.push({ name: 'Add Rx Items' });
    }
    this.$store.commit('rxApprovalList/hideRxApprovalConsolidationList', false);
    this.stopCompleted = this.stopDetailChange.is_completed;
    this.updateExpectedPickupActiveTab();
    this.getAccumulationContainers();
  },
  methods: {
    addApproval() {
      if (!this.stopDetailChange.is_completed) {
        this.resetApprovalAndRxApproval();
        this.$store.dispatch('resetApprovalAddedFromContainer');
        this.$store.commit('setApprovalStatusType', false);
        this.$router.push({ name: 'Approval List' });
      }
    },
    gotoRXBuilder() {
      if (!this.stopDetailChange.is_completed) {
        this.resetApprovalAndRxApproval();
        this.$store.dispatch('resetApprovalAddedFromContainer');
        this.$store.commit('rxApproval/setSelectedEditApproval', {});
        this.$store.commit('rxApproval/resetApprovalDetail', {});
        this.$store.commit('rxContent/resetContent', {});
        this.$store.commit('setApprovalStatusType', false);
        this.$router.push({ name: 'Add Rx Items' });
      }
    },
    getInventoryLists() {
      this.$store.dispatch('getInventoryLists', this.stopDetailChange.id);
    },
    getAllCCID() {
      this.$store.dispatch('trip/viewAllCcid', this.stopDetailChange.trip_id);
    },
    resetApprovalAndRxApproval() {
      this.$store.commit('resetApprovalDetail');
      this.$store.commit('container/resetContainerType');
      this.$store.dispatch('rxApproval/resetAllContentData');
      this.$store.commit('resetScaleWeight');
      this.$store.commit('resetScaleWeightUnit');
      this.$store.commit('setSelectedInventoryApproval', {});
    },
    changeSelection(type) {
      this.showStopInventory = false;
      this.showTripInventory = false;
      this.showExpectedPickup = false;
      this.$store.commit('setStopInventoryActiveTab', type);
      switch (type) {
        case 'trip':
          this.getAllCCID();
          this.showTripInventory = true;
          break;
        case 'stop':
          this.showStopInventory = true;
          this.getInventoryLists();
          break;
        case 'expected pickup':
          this.showExpectedPickup = true;
          break;
        default:
      }
    },
    checkConnectedStopStatus() {
      if (this.stopDetailChange.connected_status) {
        if (this.stopDetailChange.connected_status === connectedStopConstant.status.NOT_CONNECTED) {
          return false;
        }
        return true;
      }
      return false;
    },
    updateExpectedPickupActiveTab() {
      if (this.activeInventoryTab) {
        this.changeSelection(this.activeInventoryTab);
      } else if (this.checkConnectedStopStatus() && !this.isCompletedStop) {
        this.switchTabForAccumulationData();
      } else {
        this.changeSelection('stop');
      }
    },
    switchTabForAccumulationData() {
      if (this.isAccumulationPickupData) {
        this.changeSelection('expected pickup');
      } else {
        this.changeSelection('stop');
      }
    },
    getAccumulationContainers() {
      if (this.checkConnectedStopStatus() && this.isActiveStop && this.stopDetailChange.connected_status === connectedStopConstant
        .status.ACCUMULATION_DATA_PRESENT) {
        this.$store.dispatch('getAccumulationContainerList', this.stopDetailChange.id);
      }
    },
  },
  watch: {
    stopDetailChange: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.id !== oldVal.id) {
          this.$store.commit('setStopInventoryActiveTab', null);
          this.updateExpectedPickupActiveTab();
        }
      },
    },
  },
};
</script>
