//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ExpectedPickupTable from '../../helpers/component/expected-pickup-table/expectedPickupTable.vue';
import AccumulationPickupData from '../../helpers/component/accumulation-pickup-data/accumulationPickupData.vue';
import connectedStopConstant from '../../constants/connected-stop';
import StorageMethodDialog from '../../helpers/component/storage-method-dialog/storageMethodDialog.vue';

export default {
  name: 'ExpectedPickupData',
  components: { ExpectedPickupTable, AccumulationPickupData, StorageMethodDialog },
  computed: {
    ...mapGetters({
      stopDetail: 'getStopDetail',
      expectedPickupList: 'getExpectedPickupList',
      accumulationPickupList: 'getAccumulationPickupList',
      storageSelectedForTrip: 'trip/getStorageSelectedForTrip',
    }),
    hasPickupData() {
      return this.expectedPickupList && this.expectedPickupList.length > 0;
    },
    isPriorPickupData() {
      return this.stopDetail.connected_status === connectedStopConstant.status.PRIOR_DATA_PRESENT;
    },
    isAccumulationPickupData() {
      return this.stopDetail.connected_status === connectedStopConstant
        .status.ACCUMULATION_DATA_PRESENT;
    },
  },
  data() {
    return {
      showStorageMethodDialog: false,
    };
  },
  created() {
    this.$store.commit('updateBeginStopAction', false);
  },

  methods: {
    beginStop() {
      if (this.storageSelectedForTrip) {
        this.$store.dispatch('stopArriveAction', this.stopId);
      } else {
        this.openStorageMethodModal();
      }
    },
    openStorageMethodModal() {
      this.showStorageMethodDialog = true;
    },
    cancelStorageDialog() {
      this.showStorageMethodDialog = false;
    },
    cancelClick() {
      this.$router.push(`/stops/list/${this.stopDetail.trip_id}`);
    },
  },
  props: {
    stopId: {
      type: String,
      required: true,
    },
  },
};
