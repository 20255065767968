<template>
  <div class="width-100p stop-instruction-content">
    <div class="stop-instruction-header">
      <span class="sub-header-text d-flex align-center">
        Stop instructions
      </span>
    </div>
    <div class="d-flex flex-column width-100p h684 pt-12">
      <div class="instruction-group">
        <div class="mobile-stop-list-title">
          <label>Notes</label>
        </div>
        <div class="mobile-stop-detail">
          {{ stopDetail.comments ? stopDetail.comments : 'None.' }}
        </div>
        <div class="mobile-stop-list-title mt24px">
          <label>Directions</label>
        </div>
        <div class="mobile-stop-detail">
          {{ stopDetail.site_directions ? stopDetail.site_directions : 'None.' }}
        </div>
      </div>
      <div class="stop-instruction-btn-group">
        <button
          class="cancel-btn"
          @click="goBack"
        >
          Cancel
        </button>
        <button
          class="confirm-btn"
          @click="beginStop"
        >
          Begin stop
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'BeginStopInstruction',
  computed: {
    ...mapGetters({
      stopDetail: 'getStopDetail',
      user: 'authentication/getUser',
    }),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    beginStop() {
      const params = { stopId: this.stopDetail.doc_id, userId: this.user.doc_id };
      this.$store.dispatch('updateStopStatus', params);
      this.$router.push('/mobile/stop-detail');
    },
  },
};
</script>
