<template>
  <v-col
    cols="12"
    lg="8"
  >
    <p class="add-rx-items-details-sub-title">
      Total weight
    </p>
    <div>
      <v-row class="">
        <v-col
          cols="12"
          lg="12"
          class=""
        >
          <span>
            {{ this.$store.getters["weight/getWeight"] }} LB
          </span>
          <button
            class="rx-items-weigth-btn"
            @click="tareScale()"
          >
            Tare scale
          </button>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>
<script>
export default {
  name: 'BenchScale',
  beforeMount() {
  },
  methods: {
    tareScale() {
      this.$store.dispatch('weight/setTareWeight');
    },
  },
  props: {

    totalWeight: {
      type: Number,
      default: 0.0,
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
  },
};
</script>
