//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import stopSummaryTable from '../stop-summary-table/stopSummaryTable.vue';
import connectedStopConstant from '../../../constants/connected-stop';
import CompleteStopWeightInfo from './completeStopWeightInfo.vue';

export default {
  name: 'CompleteStopConfirmationDialog',
  components: {
    stopSummaryTable,
    CompleteStopWeightInfo,
  },
  computed: {
    ...mapGetters({
      completeStop: 'getCompleteStop',
      stopDetail: 'getStopDetail',
      voidedStop: 'getStopVoidStatus',
      showFirstTimeNoticeFlag: 'getFirstTimeNoticeFlag',
      getRetailSisTotalWeight: 'getRetailSisTotalWeight',
      getTruckSisTotalWeight: 'getTruckSisTotalWeight',
    }),
    knownProfiles: {
      get() {
        return this.$store.state.stopDetail.stopSummaryKnownProfiles;
      },
      set(value) {
        return value;
      },
    },
    potentialProfiles: {
      get() {
        return this.$store.state.stopDetail.stopSummaryPotentialProfiles;
      },
      set(value) {
        return value;
      },
    },
    checkSortingAndGeneratorSignNameCompleteStatus() {
      return this.completeStop.sorting_completed
             && this.completeStop.generator_signed && this.completeStop.manifest_number
             && this.completeStop.qa_completed;
    },
    disableCompleteStopButton() {
      return !this.completeStop.sorting_completed
            || !this.completeStop.generator_signed
            || !this.completeStop.manifest_number;
    },
    disableBtnWastePickUp() {
      return !this.btnActiveGenerator && !this.btnActiveOnSite;
    },
    isAccumulationPickupData() {
      return this.stopDetail.connected_status === connectedStopConstant
        .status.ACCUMULATION_DATA_PRESENT;
    },
  },
  data() {
    return {
      dialog: false,
      btnActiveGenerator: false,
      btnActiveOnSite: false,
      customerLabelPrintWarning: false,
      expectedProfileLabel: 'Expected profiles',
      sisUndeterminedLabel: 'Profiles where an exact determination could not be made by the SiS',
    };
  },
  created() {
    this.$store.dispatch('setFirstTimeNoticeFlag', this.stopDetail.id);
  },
  methods: {
    closeDialog() {
      this.customerLabelPrintWarning = false;
      this.$emit('close', false);
    },
    // eslint-disable-next-line consistent-return
    complete() {
      if (!this.completeStop.customer_labels_printed && !this.customerLabelPrintWarning) {
        this.customerLabelPrintWarning = true;
        return true;
      }
      const params = {
        stopId: this.stopDetail.id,
        reason: null,
      };
      if (this.btnActiveGenerator) {
        params.reason = 'generator_declined';
      } else {
        params.reason = 'no_waste_on_site';
      }
      this.$store.dispatch('completeStopAction', params).then(() => {
        this.$store.commit('setStopInventoryLists', []);
        this.$store.commit('setApprovalList', {});
        this.$store.commit('resetApprovalDetail');
        this.$store.commit('consolidate/resetConsolidation');
        this.$store.commit('resetApprovalDetail');
        this.$store.commit('resetScaleWeight');
        this.$store.commit('resetConsolidateApproval');
        this.$store.commit('alerts/setAlertsData', []);
        this.$store.commit('trip/setFirstLogin', true);
        this.$store.commit('attachment/resetPrintedDocumentList');
        this.$store.commit('attachment/resetPrintedDocumentAndAttachmentMap');
        this.$store.commit('stopLabels/updateLabelReprintStatus', false);
        this.$store.commit('stopDocuments/updateDocumentReprintStatus', false);
        this.$router.push({
          name: 'Loggedin Home',
        });
      });
    },
    confirmCompleteStop(type) {
      if (type === 'generator-declined') {
        this.btnActiveGenerator = true;
        this.btnActiveOnSite = false;
      } else {
        this.btnActiveOnSite = true;
        this.btnActiveGenerator = false;
      }
    },
    navigateToCustomerLabels() {
      this.closeDialog();
      this.$router.push({
        path: `/stops/detail/${this.stopDetail.id}/print-label`,
        query: { initializeLabel: 'Customer labels' },
      });
    },
    retailSISTotalWeight() {
      return this.expectedProfileList
        .reduce((total, currentProfile) => total + currentProfile.retailSisWeight, 0.0)
          + this.sisUndeterminedProfileList
            .reduce((total, currentProfile) => total + currentProfile.retailSisWeight, 0.0);
    },
    truckSISTotalWeight() {
      return this.expectedProfileList
        .reduce((total, currentProfile) => total + currentProfile.truckSisWeight, 0.0)
          + this.sisUndeterminedProfileList
            .reduce((total, currentProfile) => total + currentProfile.truckSisWeight, 0.0);
    },
    weightDifferenceOver10Percent() {
      return (Math.abs(this.getTruckSisTotalWeight - this.getRetailSisTotalWeight)
      / this.getRetailSisTotalWeight) > 0.1;
    },
  },
  props: {
    openDialog: Boolean,
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
    voidedStop(newVal) {
      if (newVal) {
        this.dialog = false;
      }
    },
  },
};
