<template>
  <div
    class="mobile-rx-approval-list"
  >
    <p class="mobile-rx-list-approval-name margin-bottom-5">
      Approval
    </p>
    <p class="mobile-rx-list-approval-description mb-24">
      {{ approval.approvalDescription }}
    </p>
    <div class="h48 mb-24">
      <div class="rx-item-drawer-sub-div">
        <p
          class="mobile-rx-list-approval-name margin-bottom-5"
        >
          Total weight
        </p>
        <p class="mobile-rx-list-approval-description">
          {{ approval.totalWeight }} lb
        </p>
      </div>
      <div class="rx-item-drawer-sub-div">
        <p class="mobile-rx-list-approval-name margin-bottom-5">
          Number of items
        </p>
        <p class="mobile-rx-list-approval-description">
          {{ approval.rxItems.length }}
        </p>
      </div>
    </div>
    <p class="mobile-rx-list-approval-name margin-bottom-5">
      Container type
    </p>
    <v-select
      ref="containerType"
      class="input-type-select dropdown-color-grey h-80"
      v-model="approval.containerType"
      :items="rxContainerTypes"
      item-value="type"
      item-text="desc"
      outlined
      append-icon="fa-angle-down"
      :menu-props="{ offsetY: true }"
      background-color="white"
      placeholder="Select..."
      @click:append="closeSelectDropdown('containerType')"
    />
    <div class="quantity-bottom">
      <div class="rx-item-drawer-sub-div">
        <p class="mobile-rx-list-approval-name margin-bottom-5">
          Quantity
        </p>
        <rx-item-increment-decrement-input
          v-model="containerQuantity"
        />
      </div>
      <div class="d-flex mobile-approval-details-btn">
        <button
          class="details-rx-approval"
          @click="viewApprovalDetails"
        >
          Details
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import VSelectArrow from '../../../helpers/utils/v-select-arrow';
import RxItemIncrementDecrementInput from '../../../helpers/component/mobile-rx-inc-dec-input/mobileRxIncDecInput.vue';

export default {
  name: 'MobileRxApprovalCard',
  components: {
    RxItemIncrementDecrementInput,
  },
  computed: {
    ...mapGetters({ rxContainerTypes: 'rxContent/getRxContainerTypes' }),
  },
  data() {
    return {
      containerQuantity: 0,
    };
  },
  extends: VSelectArrow,
  created() {
    this.containerQuantity = this.approval.containerQuantity;
  },
  methods: {
    viewApprovalDetails() {
      this.$store.commit('rxApproval/setSelectedViewApproval', this.approval);
      this.$router.push({ name: 'Mobile Add Rx Item Details' });
    },
    updateApproval(approval, change) {
      this.$store.dispatch('rxApproval/updateApprovalDetails', { approval, change });
    },
  },
  props: {
    approval: {
      type: Object,
      default: () => ({
        approvalDescription: '',
        totalWeight: '0.0',
        noOfRxItems: 0,
        containerType: '',
        containerQuantity: 0,
      }),
    },
  },
  watch: {
    containerQuantity(newVal) {
      this.approval.containerQuantity = newVal;
      this.updateApproval(this.approval, { containerQuantity: newVal });
    },
    approval: {
      deep: true,
      handler(val, newVal) {
        if (newVal.containerType && newVal.containerType !== '') {
          this.updateApproval(this.approval, { containerType: newVal.containerType });
        }
      },
    },
  },
};
</script>
