<template>
  <div>
    <template v-if="getRxLists && getRxLists.length > 0">
      <DynamicScroller
        :items="getRxLists"
        :min-item-size="80"
        class="scroller"
        page-mode
        key-field="idx"
      >
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :data-index="index"
            key-field="idx"
            :min-item-size="80"
          >
            <v-row
              class="border-v-1-grey ptrbl-0-25-0-29 ml-12p mr-0p"
            >
              <v-col
                cols="12"
                lg="4"
              >
                <p
                  v-if="item.approval_id"
                  class="add-rx-items-details-sub-data"
                >
                  {{ item.description }}
                </p>
                <p
                  v-else
                  class="add-rx-items-details-sub-data-italic"
                >
                  {{ item.description }}
                </p>
              </v-col>
              <v-col
                cols="12"
                lg="3"
              >
                <p
                  v-if="item.approval_id"
                  class="add-rx-items-details-sub-data"
                >
                  {{ item.approval_description }}
                  {{ item.approval_code }}
                </p>
                <p
                  v-else
                  class="add-rx-items-details-sub-data"
                >
                  N/A
                </p>
              </v-col>
              <v-col
                cols="12"
                lg="5"
              >
                <span class="add-rx-items-details-sub-data">
                  {{ item.rx_item ? item.rx_item.ndc : '' }}
                </span>
                <button
                  v-if="item.approval_id"
                  class="add-rx-item-search-add"
                  @click="add(item)"
                >
                  Add
                </button>
              </v-col>
            </v-row>
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </template>
    <template v-if="getSearchResult">
      <div class="notfound-result-border-top">
        <NoResultsFound />
      </div>
    </template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { DynamicScroller, DynamicScrollerItem } from 'vue-virtual-scroller';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import NoResultsFound from '../../../helpers/component/no-results-found/no-results-found.vue';

export default {
  name: 'RxItemListCard',
  components: { NoResultsFound, DynamicScroller, DynamicScrollerItem },
  computed: {
    ...mapGetters({
      getRxLists: 'rxApproval/getRxLists',
      getSearchResult: 'rxApproval/getSearchResult',
    }),
  },
  methods: {
    gotoAddRxItem() {
      this.$emit('gotoAddRxItem');
    },
    add(item) {
      this.$emit('add', item);
    },
  },
  props: {
    items: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
