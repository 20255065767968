//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ExpectedPickupItem',
  props: {
    expectedData: {
      type: Object,
      default() {
        return { };
      },
    },
  },
};
