<template>
  <div class="print-instruction-container">
    <div class="header">
      Instructions
    </div>
    <div
      class="description"
      v-if="!printingStatusComplete"
    >
      <p> {{ printDescription }} </p> Once loaded, select <span class="forgot-font-weight">
        {{ printButtonText }}</span> to begin.
    </div>
    <div
      class="description"
      v-else
    >
      Please wait for your documents to finish printing.
      Select <span class="forgot-font-weight">{{ completeButtonText }}</span>
      to return to the main printing screen.
    </div>
    <div class="button-group">
      <v-btn
        class="btn cancel-btn"
        @click="openCancelModal"
      >
        Cancel print queue
      </v-btn>
      <v-btn
        class="btn print-btn"
        v-if="!printingStatusComplete"
        :class="{'print-btn-disable' : disablePrintBtn}"
        @click="printDocuments"
      >
        <img src="src/assets/images/printer-white.svg">
        <div class="button-label">
          {{ printButtonText }}
        </div>
      </v-btn>
      <v-btn
        v-else
        class="btn next-btn"
        @click="$emit('printNextDoc')"
      >
        {{ completeButtonText }}
      </v-btn>
    </div>
    <confirm-modal
      v-if="shouldShowConfirmationModal"
      :dialog-data="modalData"
      :button-data="modalButtons"
      :dialog-property="dialogProperty"
      @cancel="closeCancelModal"
      @confirm="resetPrintFlow"
    />
  </div>
</template>
<script>
import ConfirmModal from '../confirmation-dialog/confirmation.vue';

export default {
  name: 'PrintInstruction',
  components: {
    ConfirmModal,
  },
  computed: {
    printButtonText() {
      if (this.multipleDocuments) {
        return 'Print documents';
      }
      return 'Print document';
    },
    completeButtonText() {
      if (this.nextDocumentToPrint) {
        return 'Next document set';
      }
      return 'Finished printing';
    },
  },
  data() {
    return {
      shouldShowConfirmationModal: false,
      modalData: {
        heading: 'Cancel print queue',
        subHeading: 'Instructions',
        description:
          'To cancel the current print job, you must press the Cancel '
          + 'button on the printer itself. Would you like to cancel the current print queue?',
      },
      modalButtons: {
        buttonConfirmText: 'Cancel print queue',
        buttonCancelText: 'Back',
        buttonConfirmType: 'btn-primary',
        buttonConfirmVisibility: true,
        buttonCancelVisibility: true,
      },
      dialogProperty: {
        modalHeight: 350,
        modalWidth: 840,
      },
      disablePrintBtn: false,
    };
  },
  methods: {
    printDocuments() {
      this.$emit('printDocuments');
      this.disablePrintBtn = true;
    },
    openCancelModal() {
      if (this.hasPrintedOnce) {
        this.shouldShowConfirmationModal = true;
      } else {
        this.$emit('reset', true);
      }
    },
    closeCancelModal() {
      this.shouldShowConfirmationModal = false;
    },
    resetPrintFlow() {
      this.$emit('reset', true);
    },
  },
  props: {
    printDescription: {
      type: String,
      default() {
        return '';
      },
    },
    printingStatusComplete: {
      type: Boolean,
      default() {
        return false;
      },
    },
    multipleDocuments: {
      type: Boolean,
      default() {
        return false;
      },
    },
    nextDocumentToPrint: {
      type: Boolean,
      default() {
        return false;
      },
    },
    hasPrintedOnce: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    printingStatusComplete() {
      this.disablePrintBtn = false;
    },
  },
};
</script>
