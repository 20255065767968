<template>
  <v-row
    class="item-container"
    @click="scanAlert"
  >
    <v-col class="image-container">
      <img
        :src="imageUrl()"
        class="product-image"
      >
    </v-col>
    <v-col class="alert-description">
      <div class="alert-status">
        Ready to sort
      </div>
      <div class="product-name">
        {{ alert.product_name }}
      </div>
      <div class="scanned-date">
        Scanned on: {{ alert.scanned_on | alertScannedDate }}
      </div>
    </v-col>
    <v-col class="barcode-container">
      <img src="src/assets/images/icon-barcode.svg">
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AlertItem',
  computed: {
    ...mapGetters([
      'getStopDetail',
    ]),
  },
  methods: {
    scanAlert() {
      this.$emit('scanAlert', this.alert.cloud_id);
    },
    imageUrl() {
      if (this.alert.image.length > 0) {
        if (this.alert.image[0].image && this.alert.image[0].image.image) {
          return this.alert.image[0].image.image.url;
        }
      }
      return '';
    },
  },
  props: {
    alert: {
      type: Object,
      default() {
        return { };
      },
    },
  },
};
</script>
