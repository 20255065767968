<template>
  <v-container class="alert-container">
    <div class="header">
      Alerts
    </div>
    <div class="container alert-list-container">
      <alert-item
        v-for="(alert, index) in alerts"
        :key="index"
        :alert="alert"
        @scanAlert="scanAlert"
      />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import AlertItem from '../alert-item/alertItem.vue';

export default {
  name: 'AlertComponent',
  components: {
    AlertItem,
  },
  computed: {
    ...mapGetters({
      alerts: 'alerts/getAlertsData',
    }),
  },
  methods: {
    scanAlert(alertId) {
      this.$emit('scanAlert', alertId);
    },
  },
};
</script>
