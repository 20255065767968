//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import SearchLoader from '../../../helpers/component/search-loader/search-loader.vue';
import MobileRxItemListCard from '../rx-item-list-card/index-mobile.vue';
import mobileRxApprovalList from '../rx-drawer/index-mobile.vue';

export default {
  name: 'RxApprovalListMobile',
  components: {
    SearchLoader,
    MobileRxItemListCard,
    mobileRxApprovalList,
  },
  computed: {
    ...mapGetters({
      rxItemList: 'rxApproval/getMobileRxItemList',
      rxItemMainProp: 'rxApproval/getRxItemMainProp',
    }),
  },
  data() {
    return {
      showApprovalDetails: true,
      showSearchResult: false,
      showSearchIcon: false,
      noRecordsFound: false,
    };
  },
  created() {
    this.$store.commit('rxApproval/setSelectedRxItem', {});
    this.showApprovalDetails = this.rxItemMainProp.showApprovalDetails;
    this.showSearchResult = this.rxItemMainProp.showSearchResult;
    this.showSearchIcon = this.rxItemMainProp.showSearchIcon;
    this.noRecordsFound = this.rxItemMainProp.noRecordsFound;
  },
  methods: {
    goBack() {
      this.$store.commit('rxApproval/setRxItemMainPropDefault');
      this.$router.push({ name: 'Mobile Add RX Items' });
    },
  },
  watch: {
    rxItemMainProp: {
      deep: true,
      handler(val) {
        this.showApprovalDetails = val.showApprovalDetails;
        this.showSearchResult = val.showSearchResult;
        this.showSearchIcon = val.showSearchIcon;
        this.noRecordsFound = val.noRecordsFound;
      },
    },
  },
};
