<template>
  <div>
    <tripList />
  </div>
</template>

<script>

import tripList from '../trips/list/index.vue';

export default {
  name: 'LoggedInHomeComponent',
  components: { tripList },
};
</script>
