import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import { FIRESTORE_CREDENTIALS } from '../../constants/index';

firebase.initializeApp(FIRESTORE_CREDENTIALS);

if (process.env.NODE_ENV !== 'mock') {
  firebase.firestore().enablePersistence()
    .catch((err) => {
      if (err.code === 'failed-precondition') {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code === 'unimplemented') {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
}

function parseRequest(url, options) {
  return {
    route: url.split('/api').pop(),
    body: JSON.parse(options.body || '{}'),
  };
}

function queryToArray(result) {
  return result.docs.map((doc) => Object.assign(doc.data(), { doc_id: doc.id }));
}

function errorResponse(message) {
  return {
    ok: false,
    data: message,
  };
}

function okResponse(data) {
  return {
    ok: true,
    data,
  };
}

const firestoreDB = {
  db: firebase.firestore(),
  auth: firebase.auth(),
  parseRequest,
  queryToArray,
  errorResponse,
  okResponse,
  fieldValue: firebase.firestore.FieldValue,
};

export default firestoreDB;
