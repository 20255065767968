//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'AuthHeaderMobile',
};
