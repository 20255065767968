//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import stopSummaryItem from '../stop-summary-item/stopSummaryItem.vue';

export default {
  name: 'StopSummaryTable',
  components: { stopSummaryItem },
  props: {
    profileList: {
      type: Array,
      default() {
        return [];
      },
    },
    tableHeader: {
      type: String,
      default: '',
    },
  },
};
