//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import MobileStopCard from '../../helpers/component/stop-card/stopCard-mobile.vue';

export default {
  name: 'MobileStopList',
  components: { MobileStopCard },
  computed: {
    ...mapGetters({ stopList: 'getStopListsArray' }),
  },
  created() {
    this.getStopsList();
  },
  methods: {
    getStopsList() {
      this.$store.dispatch('mobileStopList', parseInt(this.tripDocId, 10));
    },
  },
  props: {
    tripDocId: {
      type: String,
      required: true,
    },
    tripCode: {
      type: String,
      required: true,
    },
  },
};
