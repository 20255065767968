//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ApprovalListCard',
  methods: {
    assignApproval() {
      this.approvalDetail.bill_units = JSON.parse(this.approvalDetail.bill_units);
      this.$store.dispatch('assignApprovalAction', this.approvalDetail);
      this.$router.push({ name: 'Add Approval' });
    },
  },
  props: {
    approvalDetail: {
      type: Object,
      required: true,
    },
  },
};
