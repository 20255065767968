//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import containerBuilder from './containerBuilder.vue';

export default {
  name: 'ContainerList',
  components: { containerBuilder },
  data: () => ({
    containerList: [],
    isNoMoreContainer: false,
  }),
  beforeDestroy() {
    this.$scaleSocketsDisconnect();
  },
  created() {
    this.$scaleSocketsConnect();
    this.containerList = this.$store.state.container.containerList;
    if (this.hasCustomerSpecificBilling) {
      this.containerList = this.containerList.map((container, indx) => {
        const containerObj = container;
        if (indx === 0) containerObj.quantity = 0;
        return containerObj;
      });
    }
    this.getContainerTypeListChanges();
  },
  methods: {
    addAnotherContainer() {
      if (
        this.isNoMoreContainer === false
        && this.containerList[this.containerList.length - 1].type.id
      ) {
        this.$store.dispatch('container/addAnotherContainerAction');
      }
    },
    getContainerTypeListChanges() {
      this.$store.subscribe((mutation, state) => {
        if (
          mutation.type === 'container/setContainerTypeID'
          || mutation.type === 'container/resetContainerIndex'
          || mutation.type === 'container/triggerContainerTypeList'
        ) {
          const containerTypeList = state.container.containerTypeList.filter(
            (containerType) => !containerType.is_selected,
          );
          this.isNoMoreContainer = false;
          if (containerTypeList.length === 0) {
            this.isNoMoreContainer = true;
          }
        }
      });
    },
  },
  props: {
    hasCustomerSpecificBilling: {
      default: false,
      type: Boolean,
    },
    totalQuantity: {
      default: 0,
      type: Number,
    },
  },
  watch: {
    totalQuantity(val) {
      if (this.hasCustomerSpecificBilling) {
        this.containerList = this.containerList.map((container, indx) => {
          const containerObj = container;
          if (indx === 0) containerObj.quantity = val;
          return containerObj;
        });
      }
    },
  },
};
