import {
  API_BASE_URL, SS_CLOUD_URL, USERNAME, PASSWORD,
} from '../constants/index';
import fetchService from './fetch.service';

function scanAttachment(attachment) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachment),
  };
  const url = `${API_BASE_URL}stops/attachment/scan/${attachment.page}`;
  return fetchService.getFetchData(url, requestOptions);
}


function mobileBarCodeScan(payload) {
  const requestOptions = {
    method: 'GET',
    headers: { Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}` },
  };

  return fetchService.getFetchData(`${SS_CLOUD_URL}/master-scan?barcode=${payload.barcode}&use_generator_id=${payload.use_generator_id}`, requestOptions);
}

const scanBackendService = {
  scanAttachment,
  mobileBarCodeScan,
};
export default scanBackendService;
