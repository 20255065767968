//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BenchScale',
  beforeMount() {
  },
  methods: {
    tareScale() {
      this.$store.dispatch('weight/setTareWeight');
    },
  },
  props: {

    totalWeight: {
      type: Number,
      default: 0.0,
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
  },
};
