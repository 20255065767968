<template>
  <div>
    <v-overlay
      :value="dialog"
      z-index="202"
    >
      <div class="stop-voided-container">
        <div class="d-flex flex-column align-center justify-center stop-voided-box">
          <div class="d-flex justify-center align-center fs-3em m28">
            <i class="fas fa-exclamation-triangle" />
          </div>
          <div class="stop-voided-info">
            <label>
              Stop voided by Field Service Support
            </label>
          </div>
          <div class="stop-voided-info-child">
            <label>
              This stop has been voided and should not be continued. If you have any questions,
            </label>
            <label>
              contact Field Service support at (866) 900-3762.
            </label>
          </div>
          <div class="btn-group-stop-voided mt-10">
            <button
              @click="goToStopLists()"
              class="btn-submit goto-active-stop"
            >
              Back to stop list
            </button>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StopVoidedWarning',
  computed: {
    ...mapGetters({ stopDetailChange: 'getStopDetail', voidedStop: 'getStopVoidStatus', leaveApproval: 'leaveApproval/getLeaveApprovalDetail' }),
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    goToStopLists() {
      this.resetApprovalBuilder();
      this.$router.push({
        name: 'Stop List',
        params: { tripId: this.stopDetailChange.trip_id.toString() },
      });
    },
    resetApprovalBuilder() {
      const currentRoute = this.$route.name;
      this.$store.dispatch('rxApproval/resetAllContentData');
      if (currentRoute === 'Consolidate' || currentRoute === 'RxConsolidate') {
        this.resetConsolidate();
      }
      if (currentRoute === 'Add Rx Items') {
        this.$store.commit('rxApproval/resetApprovalDetail');
      }
      this.$store.commit('resetApprovalDetail');
      this.$store.commit('resetScaleWeight');
      this.$store.commit('resetScaleWeightUnit');
      this.$store.commit('leaveApproval/resetLeaveApprovalDialog');
    },
    resetConsolidate() {
      const consolidateDetail = this.$store.state.approvalList.consolidateApproval;
      if (consolidateDetail && consolidateDetail.id) {
        const options = { id: consolidateDetail.id, stopId: consolidateDetail.stop_id };
        this.$store.dispatch('consolidate/removeConsolidationSavedData', options);
        this.$store.commit('resetConsolidateApproval');
        this.$store.commit('consolidate/resetConsolidation');
      }
      this.$store.commit('rxApproval/resetApprovalDetail');
      this.$store.commit('rxContent/resetContent');
    },
  },
  watch: {
    voidedStop() {
      this.dialog = this.voidedStop;
    },
  },
};
</script>
