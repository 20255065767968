<template>
  <div class="width-100p height-100p">
    <div class="d-flex flex-column justify-space-between height-90p">
      <div>
        <div
          class="stop-label font-size-em-1-5"
        >
          <label class="trip-header">Trip stops</label>
          <p class="map-title">
            {{ tripDetail.use_trip_id }}
          </p>
        </div>
        <div class="mt-8">
          <span class="stop-header-label">
            Trip name:
          </span>
          <p class="fs-16em">
            {{ tripDetail.trip_name }}
          </p>
        </div>
        <div class="truck-trailer-num mb-4 mt-8">
          <div class="truck-num-col mr-4">
            <div class="mb-1">
              <label class="label trip-stop-code-label">Truck number</label>
            </div>
            <div
              class="truck-width"
              v-if="(!tripDetail.is_completed && tripDetail.is_active) || !tripDetail.is_active"
            >
              <input
                type="text"
                class="input fs-16em"
                v-model="tripDetail.truck_number"
                @click="checkDialog('numeric','truck_number',
                                    'Enter truck number',tripDetail.truck_number)"
                @selectstart.prevent
                @paste.prevent
                @copy.prevent
                @cut.prevent
                @drag.prevent
                @drop.prevent
                v-unSelect
              >
            </div>
            <span
              class="fs-16em"
              v-else
            >{{ tripDetail.truck_number }}</span>
          </div>
          <div class="trailer-num-col">
            <div class="mb-1">
              <label class="label trip-stop-code-label">Trailer number</label>
            </div>
            <div
              class="truck-width"
              v-if="(!tripDetail.is_completed && tripDetail.is_active) || !tripDetail.is_active"
            >
              <input
                type="text"
                class="input fs-16em"
                v-model="tripDetail.trailer_number"
                value="7236235"
                @click="checkDialog('alphaNumeric','trailer_number',
                                    'Enter trailer number',tripDetail.trailer_number)"
                @selectstart.prevent
                @paste.prevent
                @copy.prevent
                @cut.prevent
                @drag.prevent
                @drop.prevent
                v-unSelect
              >
            </div>
            <span
              class="fs-16em"
              v-else
            >{{ tripDetail.trailer_number }}</span>
          </div>
        </div>
        <div
          v-if="!updateShow && !successShow"
          class="update-truck"
        >
          <button
            class="truck-btn"
            :class="{'truck-btn-active' : getTruckChanged()}"
            :disabled="!getTruckChanged()"
            @click="updateTrip()"
            v-if="(!tripDetail.is_completed && tripDetail.is_active) || !tripDetail.is_active"
          >
            Update Truck/Trailer #
          </button>
        </div>
        <div
          class="d-flex"
          v-if="updateShow"
        >
          <div class="refresh-icon fa-spin" />
          <div class="update-truck-trailer">
            <label>Updating Truck/Trailer #</label>
          </div>
        </div>
        <div
          class="d-flex"
          v-if="successShow"
        >
          <div class="save-icon" />
          <div class="success-text">
            <label>Truck/Trailer number updated</label>
          </div>
        </div>
        <KeyboardDialog
          :open-dialog="pinDialogIsOpen"
          :keyboard-type="keyBoardType"
          :model-name="modelName"
          :label-name="labelName"
          :model-value="modelValue"
          @dialogInputChanged="dialogInputChanges"
          name="keyboard"
          @enterClicked="enterClicked"
        />
      </div>
      <div class="mt-6 d-flex flex-column complete-stop">
        <button
          class="activate-trip-btn"
          @click="completeCurrentTrip()"
          v-if="tripDetail.is_completed"
        >
          <em class="fas fa-pencil-alt mr-3" />
          Activate trip
        </button>
        <button
          class="btn-submit"
          @click="openCompleteTripModal()"
          v-else-if="tripDetail.is_active"
        >
          Complete trip
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import KeyboardDialog from '../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';


export default {
  name: 'StopMap',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({ tripDetailChanges: 'getTripDetail' }),
  },

  data() {
    return {
      tripDetail: {},
      activeStop: {},
      updateShow: false,
      successShow: false,
      existingTrailerNumber: '',
      existingTruckNumber: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
    };
  },
  created() {
    this.getActiveStop();
  },
  methods: {
    getActiveStop() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'createActiveStop') {
          this.activeStop = mutation.payload;
        }
      });
    },
    undoStop() {
      const params = this.activeStop;
      params.stop_active = false;
      params.stop_active_time = null;
      this.$store.dispatch('updateActiveStop', params);
    },
    getTruckChanged() {
      return this.existingTrailerNumber
      !== this.tripDetail.trailer_number
      || this.existingTruckNumber !== this.tripDetail.truck_number;
    },
    updateTrip() {
      const params = {};
      params.userId = this.tripDetail.user_id;
      params.tripCode = this.tripDetail.trip_code;
      params.truck_number = this.tripDetail.truck_number;
      params.trailer_number = this.tripDetail.trailer_number;
      this.updateShow = true;
      this.$store.dispatch('updateTrip', params).then((res) => {
        if (res === 'tripUpdateSuccess') {
          setTimeout(() => {
            this.updateShow = false;
            this.successShow = true;
            setTimeout(() => {
              this.successShow = false;
            }, 500);
          }, 1000);
        }
        this.setTruckAndTrailerNumber();
      });
    },
    setTruckAndTrailerNumber() {
      this.existingTrailerNumber = this.tripDetail.trailer_number;
      this.existingTruckNumber = this.tripDetail.truck_number;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    enterClicked(value) {
      if (value.modelName === 'truck_number') {
        this.tripDetail.truck_number = value.input;
      }
      if (value.modelName === 'trailer_number') {
        this.tripDetail.trailer_number = value.input;
      }
    },
    openCompleteTripModal() {
      const { tripId } = this.$route.params;
      this.$store.dispatch('openCompletedTripModal', tripId);
    },
    completeCurrentTrip() {
      const { tripId } = this.$route.params;
      this.$store.dispatch('completeCurrentTrip', { tripId, is_completed: false }).then((res) => {
        this.$router.push('/trip-list');
        this.$store.dispatch('tripStatusChangeToaster', { tripCode: res.trip_code, type: 'reopen' });
      });
    },
  },
  watch: {
    tripDetailChanges(newVal) {
      this.tripDetail = newVal;
      this.setTruckAndTrailerNumber();
    },
  },
};
</script>
