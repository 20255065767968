import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function getStopsByTrip(tripId) {
  const requestOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${API_BASE_URL}trips/${tripId}/stops`, requestOptions);
}
function cancelStopArrivel(stopId) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${API_BASE_URL}stops/arrive/${stopId}`, requestOptions);
}
function stopActive(payload, stopId) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  return fetch(`${API_BASE_URL}stops/${stopId}`, requestOptions);
}
function stopArrive(stopID) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return fetchService.getFetchData(`${API_BASE_URL}stops/arrive/${stopID}`, requestOptions);
}
function addStopPickUpData(payload, stopId) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  return fetch(`${API_BASE_URL}stops/create/${stopId}`, requestOptions);
}
function removePickUpData(pickupId) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return fetch(`${API_BASE_URL}stops/pickup/${pickupId}`, requestOptions);
}
function getStopByID(stopID) {
  const requestOptions = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return fetchService.getFetchData(`${API_BASE_URL}stops/${stopID}`, requestOptions);
}
function cancelArrive(stopID) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  return fetchService.getFetchData(`${API_BASE_URL}stops/arrive/${stopID}`, requestOptions);
}
function getPrintDocuments(stopID) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/print/${stopID}`;
  return fetchService.getFetchData(url, requestOptions);
}
function updateManifestName(payload, stopID) {
  const requestOptions = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  const url = `${API_BASE_URL}stops/print/${stopID}`;
  return fetchService.getFetchData(url, requestOptions);
}
function updateGeneratorSignName(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  return fetchService.getFetchData(`${API_BASE_URL}stops/generator_sign_name/${payload.stopID}`, requestOptions);
}
function getPrintLabels(stopID, labelGroup) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/print/label/${stopID}?label_group=${labelGroup}`;
  return fetchService.getFetchData(url, requestOptions);
}
function checkStopCompleteStatusByStopId(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/${stopId}/incomplete_sections`;
  return fetchService.getFetchData(url, requestOptions);
}
function stopCompleteByStopId(payload) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  const url = `${API_BASE_URL}stops/complete/${payload.stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function getAttachments(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  return fetchService.getFetchData(`${API_BASE_URL}stops/attachment/${stopId}`, requestOptions);
}
function addAttachment(attachment) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(attachment),
  };
  return fetchService.getFetchData(`${API_BASE_URL}stops/attachment`, requestOptions);
}
function deleteAttachment(attachmentId) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const url = `${API_BASE_URL}stops/attachment/${attachmentId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function checkUniqueManifestNumber(params) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}stops/check_manifest_number`;
  return fetchService.getFetchData(url, requestOptions);
}
function getDocumentReprintStatus(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/check_manifest_reprint_status/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function getLabelReprintingStatus(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/check_label_reprint_status/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getIncomopletedStops(tripId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}trips/get_incomplete_stops/${tripId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getStopVoidStatus(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/get_voided_status/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getVoidedStopsInTrip(tripId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/get_voided_stops_in_trip/${tripId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function updateStopStartTime(params) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}stops/update_start_time`;
  return fetchService.getFetchData(url, requestOptions);
}

function getLastStopData(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/last-stop/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getExpectedPickupList(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/expected-pickup/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getMostOccurringProfiles(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/most-occurring-profile/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function firstStopArrive(params) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ storage_method: params.storageMethod }),
  };
  return fetchService.getFetchData(`${API_BASE_URL}stops/update_storage_method/${params.stopId}`, requestOptions);
}

function getAccumulationPickupList(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/retail_sis_expected_profile_list/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getAccumulationContainerList(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/retail_sis_accumulation_containers/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getTotalStopApprovalWeight(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/stop_approval_total_weight/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getFirstPickup(stopID) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/is_first_pickup_of_generator/${stopID}`;
  return fetchService.getFetchData(url, requestOptions);
}

const userService = {
  getStopsByTrip,
  cancelStopArrivel,
  stopActive,
  stopArrive,
  addStopPickUpData,
  removePickUpData,
  getStopByID,
  cancelArrive,
  getPrintDocuments,
  updateManifestName,
  updateGeneratorSignName,
  getPrintLabels,
  checkStopCompleteStatusByStopId,
  stopCompleteByStopId,
  addAttachment,
  deleteAttachment,
  getAttachments,
  checkUniqueManifestNumber,
  getDocumentReprintStatus,
  getLabelReprintingStatus,
  firstStopArrive,
  getIncomopletedStops,
  getStopVoidStatus,
  getVoidedStopsInTrip,
  updateStopStartTime,
  getLastStopData,
  getExpectedPickupList,
  getMostOccurringProfiles,
  getAccumulationPickupList,
  getAccumulationContainerList,
  getTotalStopApprovalWeight,
  getFirstPickup,
};
export default userService;
