<template>
  <div>
    <v-row
      class="help-page-row"
    >
      <div class="help-drawer-shadow card-detail-bg contact-card">
        <div>
          <div class="help-support-header-div">
            <p class="help-support-title">
              Help & support
            </p>
          </div>
          <div
            class="help-submenu-div"
            :class="pageView==='helpDocument'? 'helpMenuAtive': ''"
            @click="gotoHelpDocument()"
          >
            <p class="help-submenu-text">
              Help documentation and training
            </p>
          </div>
          <div
            class="help-submenu-div"
            :class="pageView==='releaseNotes'? 'helpMenuAtive': ''"
            @click="gotoReleaseNotes()"
          >
            <p class="help-submenu-text">
              Release notes
            </p>
          </div>
          <div
            class="help-submenu-div"
            :class="{ 'helpMenuAtive': pageView==='systemDiagnostics' }"
            @click="goToSystemDiagnostics()"
          >
            <p class="help-submenu-text">
              System diagnostics
            </p>
          </div>
          <div class="contact-main-div ">
            <img
              src="src/assets/images/telephone-contact.svg"
              alt="contact"
            >
            <span class="contact-title">Contact</span>
            <div
              class="general-contact-info"
              v-if="pageView!=='systemDiagnostics'"
            >
              <p class="field-service-support">
                Field Service Support
              </p>
              <p class="field-service-support-number">
                (866) 900-3762
              </p>
              <p class="field-service-support-time">
                7:00 am – 10:00 pm EST M–F
              </p>
              <p class="field-service-support-time">
                (Saturdays as needed)
              </p>
              <p class="us-ecology-it-support">
                US Ecology I.T. Support
              </p>
              <p class="us-ecology-it-support-number">
                (734) 521-8057
              </p>
            </div>
            <div
              class="smarter-sorting-contact-info"
              v-if="pageView==='systemDiagnostics'"
            >
              <p class="field-service-support">
                Smarter Sorting Support
              </p>
              <p class="field-service-support-number">
                (512) 593-2594
              </p>
              <p class="field-service-support-time">
                7:00 am – 8:00 pm CST
              </p>
              <p class="field-service-support-time">
                7 days a week
              </p>
            </div>
            <button
              class="contact-example-btn"
              @click="gotoContactExamples()"
            >
              Contact examples
            </button>
            <button
              class="help-feedback-btn"
              @click="gotoFeedback()"
            >
              Feedback
            </button>
            <p class="help-page-info-value">
              <span class="help-page-info-label">Serial number: </span> {{ serialNumber }}
            </p>
            <p class="help-page-info-value version-numbers">
              <span>
                <span class="help-page-info-label">F: </span>
                v{{ versions.f }}
              </span>
              <span>
                <span class="help-page-info-label">B: </span>
                v{{ versions.b }}
              </span>
              <span>
                <span class="help-page-info-label">P: </span>
                v{{ versions.p }}
              </span>
            </p>
          </div>
        </div>
      </div>
      <v-col
        class="help-drawer-shadow help-page-bg col help-document height-930px"
        v-if="pageView === 'helpDocument'"
      >
        <help-document-page />
      </v-col>
      <v-col
        class="help-drawer-shadow help-page-bg col help-document height-930px"
        v-if="pageView === 'releaseNotes'"
      >
        <release-notes-page />
      </v-col>
      <v-col
        class="help-drawer-shadow help-page-bg col help-document height-930px"
        v-if="pageView === 'systemDiagnostics'"
      >
        <system-diagnostic-page />
      </v-col>
      <v-col
        class="help-drawer-shadow help-page-bg col help-document height-930px"
        v-if="pageView === 'contactExamples'"
      >
        <contact-examples-page />
      </v-col>
      <v-col
        class="help-drawer-shadow help-page-bg col help-document height-930px"
        v-if="pageView === 'feedback'"
      >
        <feedback-page />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import helpDocumentPage from './help-document/help-document.vue';
import releaseNotesPage from './release-notes/release-notes.vue';
import contactExamplesPage from './contact-examples/contact-examples.vue';
import updateCheckService from '../../service/updateCheck.service';
import feedbackPage from './feedback/feedback.vue';
import systemDiagnosticPage from '../system-diagnostic/index.vue';
import { version } from '../../../package.json';

export default {
  name: 'HelpComponent',
  components: {
    helpDocumentPage,
    releaseNotesPage,
    contactExamplesPage,
    feedbackPage,
    systemDiagnosticPage,
  },
  data() {
    return {
      pageView: 'helpDocument',
      versions: { f: '', b: '', p: '' },
      serialNumber: '',
    };
  },
  created() {
    updateCheckService.getVersion().then((res) => {
      this.versions = { f: version, b: res.backend, p: res.production };
    });
    updateCheckService.getSerialNumber().then((res) => {
      const sn = res.serial_number;
      if (sn) {
        this.serialNumber = sn.trim();
      } else {
        this.serialNumber = 'Unavailable';
      }
    });
  },
  methods: {
    gotoHelpDocument() {
      this.pageView = 'helpDocument';
    },
    gotoReleaseNotes() {
      this.pageView = 'releaseNotes';
    },
    gotoContactExamples() {
      this.pageView = 'contactExamples';
    },
    goToSystemDiagnostics() {
      this.pageView = 'systemDiagnostics';
    },
    gotoFeedback() {
      this.pageView = 'feedback';
    },
  },
};
</script>
