//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StopInventoryCard from '../../helpers/component/stop-inventory-card/stopInventoryCard.vue';

export default {
  name: 'LastStopInventoryList',
  components: { StopInventoryCard },
  props: {
    lastStopInventoryList: {
      type: Array,
      default() {
        return [];
      },
    },
    lastStopCompletedDate: {
      type: String,
      default: null,
    },
    inventoryUseCcid: {
      type: Boolean,
      default: false,
    },
  },
};
