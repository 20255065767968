<template>
  <tr
    class="document-table-item"
    :class="{ 'selected-row' : rowData.selected && !printingDocListTable }"
  >
    <td
      @click="switchDocumentItemSelection"
      v-if="!printingDocListTable"
    >
      <div class="d-flex justify-center img-div">
        <div
          class="reprint-container"
          v-if="rowData.need_reprinting"
        >
          <img
            class="reprint-alert"
            src="src/assets/images/alert.svg"
            alt="alert"
          >
        </div>
        <img
          class="check-img"
          src="src/assets/images/check-circle.svg"
          alt="check circle"
          v-if="rowData.selected"
        >
        <img
          class="check-img"
          src="src/assets/images/check-circle-grey.svg"
          alt="check circle grey"
          v-else
        >
      </div>
    </td>
    <td
      v-for="(item, index) in headers"
      :key="index"
      @click="switchDocumentItemSelection"
    >
      <span
        v-if="item != 'name' && item != 'tsdf'"
      >
        {{ rowData[item].charAt(0).toUpperCase() }}{{ rowData[item].slice(1).toLowerCase() }}
      </span>
      <span v-else-if="item == 'tsdf'">
        <span>
          {{ rowData[item] }}
        </span>
      </span>
      <span v-else>
        <span>
          {{ manifestNumberValue }}
          <span v-if="isReprinted">Replacement</span>
        </span>
      </span>
    </td>
    <td class="view-row">
      <button
        class="btn-submit view-btn"
        @click="showSDSPDF"
      >
        View
      </button>
    </td>
  </tr>
</template>

<script>
import documentConstants from '../../../constants/document';
import printService from '../../../service/print.backend.service';
import { API_BASE_URL } from '../../../constants/index';

const documentTypes = documentConstants.types;

export default {
  name: 'DocumentTableItem',
  computed: {
    isReprinted() {
      return this.rowData.reprinted;
    },
    manifestNumberValue() {
      if (this.isManifestDocument() && this.rowData.name === '') return this.rowData.original_name;
      return this.rowData.name;
    },
  },
  methods: {
    switchDocumentItemSelection() {
      this.$store.dispatch(
        'stopDocuments/updateDocumentSelectionStatus',
        this.rowData,
      );
    },
    isManifestDocument() {
      return [
        documentTypes.HAZ_MANIFEST,
        documentTypes.HAZ_CONTINUATION,
        documentTypes.LAND_DISPOSAL,
        documentTypes.NON_HAZ_MANIFEST,
        documentTypes.NON_HAZ_CONTINUATION,
      ].indexOf(this.rowData.type) > -1;
    },
    showSDSPDF() {
      const stopId = this.$store.getters.getStopDetail.id;
      const payload = {
        doc_type: this.rowData.type,
        cloud_id: this.rowData.cloud_id,
        original_name: this.rowData.original_name,
        tsdf: this.rowData.tsdf,
      };
      printService.printDocument(payload, stopId, true).then(
        () => {
          const cacheBuster = Date.now();
          const sdsUrl = `${API_BASE_URL}stops/view/pdf#toolbar=0&navpanes=0&timestamp=${cacheBuster}`;
          this.$store.dispatch('stopDocuments/setCurrentSDSUrl', sdsUrl);
        },
        () => {
          this.$store.dispatch('stopDocuments/setCurrentSDSApiFail');
        },
      );
    },
  },
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      },
    },
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
    printingDocListTable: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>
