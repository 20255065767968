<template>
  <v-navigation-drawer
    v-model="approvalDrawer"
    absolute
    temporary
    right
    stateless
    overlay-color="rgba(36,42,46,0.3)"
    width="600px"
  >
    <div class="approval-detail-div">
      <div class="drawer-approval-details">
        <div class="d-flex flex-column px-6 pt-6">
          <div class="mb-4">
            <label class="item-details-header">Approval details</label>
          </div>
          <v-row
            class="mb-4"
            no-gutters
          >
            <v-col :cols="3">
              <div class="approval-detail-label">
                <label>DOT HAZMAT</label>
              </div>
              <div
                class="approval-details-text"
                :class="[{ 'color-green': approval.haz_flag }]"
              >
                {{ approval.haz_flag ? "Yes" : "No" }}
              </div>
            </v-col>
            <v-col :cols="4">
              <div class="approval-detail-label">
                <label>RQ</label>
              </div>
              <div
                class="approval-details-text"
                :class="[{ 'color-green': approval.dot_rq_flag === 'T' }]"
              >
                {{ approval.dot_rq_flag === "T" ? "Yes" : "No" }}
              </div>
            </v-col>
            <v-col :cols="5">
              <div class="approval-detail-label">
                <label>RQ Reason</label>
              </div>
              <div class="approval-details-text">
                {{ approval.dot_rq_reason ? approval.dot_rq_reason : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="mb-4" />
          <v-row
            class="mb-9"
            no-gutters
          >
            <v-col :cols="3">
              <div class="approval-detail-label">
                <label>UN/NA #</label>
              </div>
              <div class="approval-details-text">
                {{ approval.dot_unna_number ? approval.dot_unna_number : "-" }}
              </div>
            </v-col>
            <v-col :cols="8">
              <div class="approval-detail-label">
                <label>DOT Shipping Name</label>
              </div>
              <div class="approval-details-text">
                {{
                  approval.proper_shipping_name
                    ? approval.proper_shipping_name
                    : "-"
                }}
              </div>
            </v-col>
          </v-row>
          <v-row
            class="mb-4"
            no-gutters
          >
            <v-col :cols="3">
              <div class="approval-detail-label">
                <label>HAZ Class</label>
              </div>
              <div class="approval-details-text">
                {{
                  approval.dot_hazmat_class ? approval.dot_hazmat_class : "-"
                }}
              </div>
            </v-col>
            <v-col :cols="4">
              <div class="approval-detail-label">
                <label>Sub Haz Class</label>
              </div>
              <div class="approval-details-text">
                {{
                  approval.dot_hazmat_subclass
                    ? approval.dot_hazmat_subclass
                    : "-"
                }}
              </div>
            </v-col>
            <v-col :cols="5">
              <div class="approval-detail-label">
                <label>Packing group</label>
              </div>
              <div class="approval-details-text">
                {{ approval.packing_group ? approval.packing_group : "-" }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="mb-4" />
          <v-row
            class="mb-4"
            no-gutters
          >
            <v-col :cols="5">
              <div class="approval-detail-label">
                <label>DOT-SP Number</label>
              </div>
              <div class="approval-details-text">
                {{ approval.dot_sp_number ? approval.dot_sp_number : "-" }}
              </div>
            </v-col>
            <v-col :cols="6">
              <div class="approval-detail-label">
                <label>DOT-SP Permit Text</label>
              </div>
              <div class="approval-details-text">
                {{
                  approval.dot_special_permit_description
                    ? approval.dot_special_permit_description
                    : "-"
                }}
              </div>
            </v-col>
          </v-row>
          <v-divider class="mb-4" />
          <v-row
            class="mb-4"
            no-gutters
          >
            <v-col>
              <div class="approval-detail-label">
                <label>Waste codes</label>
              </div>
              <div class="approval-details-text approval-detail-waste-codes">
                <template
                  v-if="
                    approval.waste_codes && approval.waste_codes.length === 0
                  "
                >
                  -
                </template>
                <template v-else>
                  <div
                    class="mt-4"
                    v-for="(wasteCode, index) in approval.waste_codes"
                    :key="index"
                    :class="{ 'mr-4': (index + 1) % 6 === 0 }"
                  >
                    {{ wasteCode.name }}
                    <v-divider
                      v-if="
                        approval.waste_codes.length - 1 !== index &&
                          (index + 1) % 6 !== 0
                      "
                      class="mx-4"
                      vertical
                    />
                  </div>
                </template>
              </div>
            </v-col>
          </v-row>
        </div>
        <div class="approval-warn-box mx-auto mb-4">
          <div class="d-flex flex-row pa-4">
            <img
              class="approval-details-warn"
              src="src/assets/images/alert.svg"
              alt="alert"
            >
            <div class="d-flex flex-column ml-5">
              <span
                class="approval-emergency"
              >Emergency Response Guide Number</span>
              <span class="approval-emergency-number">126</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row mb-4 approval-drawer-close-btn">
        <button
          class="close-approval-details"
          @click="closeDrawer()"
        >
          Close
        </button>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'ApprovalDetailDrawer',
  methods: {
    closeDrawer() {
      this.$emit('closeApprovalDrawer');
    },
  },
  props: {
    approvalDrawer: {
      type: Boolean,
      default: false,
    },
    approval: {
      type: Object,
      default() {
        return {
          haz_flag: false,
          dot_rq_flag: false,
          dot_rq_reason: '-',
          dot_unna_number: '-',
          proper_shipping_name: '-',
          dot_hazmat_class: '-',
          dot_hazmat_subclass: '-',
          packing_group: '-',
          dot_sp_number: '-',
          dot_special_permit_description: '-',
          waste_codes: '',
        };
      },
    },
  },
};
</script>
