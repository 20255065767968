<template>
  <div
    class="container-detail"
  >
    <v-container
      class="expected-container-item d-flex flex-row align-center"
    >
      <div
        class="icon-container d-flex justify-center align-center"
        v-if="showContainer"
      >
        <div
          class="icon-field"
          :style="{ background: background}"
        />
      </div>
      <div
        class="container-name d-flex align-center"
        :class="{'no-container-dot': !showContainer}"
      >
        {{ container.container_count }} {{ titleCaseContainer(container.container_name) }}
      </div>
      <div class="expected-weight-container d-flex align-center justify-center">
        <div class="weight-group d-flex flex-column">
          <div class="label">
            Expected total weight
          </div>
          <div class="value">
            {{ container.container_weight }} LB
          </div>
        </div>
      </div>
      <div
        class="dropdown-container d-flex align-center justify-center"
        @click="toggleOpenContainer"
      >
        <img
          :src="getArrow('src')"
          :alt="getArrow('alt')"
        >
      </div>
    </v-container>
    <v-container
      class="container-profile-detail align-start"
      v-if="openContainer"
    >
      <template v-if="container.known_profiles.length">
        <div class="header-label">
          Known profiles in this container
        </div>
        <div class="profile-list-container">
          <expected-pickup-inventory-card
            v-for="(profile, index) in container.known_profiles"
            :key="index"
            :profile="profile"
            :container="container"
            @addToInventory="addToInventoryEvent()"
          />
        </div>
      </template>
      <template v-if="container.potential_profiles.length">
        <div class="header-label">
          Potential profiles in this container
        </div>
        <div class="description-label">
          There are some items (or groups of items) that could be assigned to different
          approvals based on your expertise and judgment.
        </div>
        <div class="profile-list-container">
          <expected-pickup-inventory-card
            v-for="(profile, index) in container.potential_profiles"
            :key="index"
            :profile="profile"
            :container="container"
            @addToInventory="addToInventoryEvent()"
          />
        </div>
      </template>
      <div
        v-if="container.unknown_item_count && container.unknown_item_weight"
        class="unknown-item-container"
      >
        <div class="notes d-flex align-center">
          <img
            class="unknown-icon"
            src="src/assets/images/unknown-yellow.svg"
            alt="unknown icon"
          >
          <div class="label">
            There are items in this container that may not be in the above approvals.
            Please use your expertise and judgment to assign the right approval.
          </div>
        </div>
        <div class="unknown-item-info d-flex align-center">
          <div class="label">
            Unknown approvals: {{ unknownItemCountString }}
          </div>
          <div class="label">
            Total weight: {{ container.unknown_item_weight }} LB
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import expectedPickupInventoryCard from '../expected-pickup-inventory-card/expectedPickupInventoryCard.vue';
import colorGenerator from '../../utils/colorGenerator';
import convertTextToTitleCase from '../../utils/convert-text-to-titlecase';
import customerTypesConstant from '../../../constants/customer-type';

export default {
  name: 'AccumulationExpectedContainer',
  components: {
    expectedPickupInventoryCard,
  },
  computed: {
    ...mapGetters({
      approvalAdded: 'getApprovalAddedSuccessfully',
      openedContainerId: 'getOpenedContainerId',
    }),
    unknownItemCountString() {
      const isOne = Number(this.container.unknown_item_count) === 1;
      const itemString = isOne ? 'item' : 'items';
      return `${this.container.unknown_item_count} ${itemString}`;
    },
  },
  data() {
    return {
      openContainer: false,
      background: null,
      showContainer: false,

    };
  },
  mounted() {
    if (this.customerName === customerTypesConstant.customerTypes.is_costco) {
      this.background = colorGenerator(this.container.container_name);
      if (this.background) {
        this.showContainer = true;
      }
    }
    this.checkPreOpenContainer();
  },
  methods: {
    getArrow(type) {
      if (this.openContainer) {
        if (type === 'src') return 'src/assets/images/up-arrow-active.svg';
        return 'up-arrow';
      }
      if (type === 'src') return 'src/assets/images/down-arrow-active.svg';
      return 'down-arrow';
    },
    titleCaseContainer(containerName) {
      return convertTextToTitleCase(containerName);
    },
    toggleOpenContainer() {
      this.openContainer = !this.openContainer;
      if (this.openContainer) {
        this.$emit('toggleContainer', { id: this.container.container_id });
        this.$store.commit('setOpenedContainerId', this.container.container_id);
      } else {
        this.$emit('toggleContainer', { id: null });
      }
    },
    addToInventoryEvent() {
      this.$store.commit('setOpenedContainerId', this.container.container_id);
    },
    checkPreOpenContainer() {
      if (this.openedContainerId) {
        if (this.container.container_id === this.openedContainerId) {
          this.toggleOpenContainer();
        }
      }
    },
  },
  props: {
    container: {
      type: Object,
      default() {
        return { };
      },
    },
    customerName: {
      type: String,
      default() {
        return '';
      },
    },
  },
};
</script>
