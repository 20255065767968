//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import DeleteApprovalConfirmationDialog from '../../../helpers/component/delete-approval/deleteApproval.vue';

export default {
  name: 'RxApprovalCard',
  components: {
    DeleteApprovalConfirmationDialog,
  },
  computed: {
    ...mapGetters({
      rxApprovedList: 'rxApprovalList/getParticularList',
      rxEditApproval: 'rxApproval/getRxEditApproval',
      consolidationMessage: 'consolidate/getConsolidationSuccessMessage',
      isRxItemAvailabe: 'consolidate/getAvailableRxItem',
      stopApprovalApiCallInProgress: 'rxApproval/getStopApprovalApiCallStatus',
      useCCIDStorage: 'getUseCCIDStorage',
    }),
    hideRxApprovalEditData() {
      return this.rxEditApproval && !this.rxEditApproval.id;
    },
    rxApprovedListDescription() {
      return this.rxApprovedList && this.rxApprovedList.approval
        ? this.rxApprovedList.approval.description
        : '';
    },
    rxEditApprovalTotalWeight() {
      return this.rxEditApproval.approval_rx_item
        ? this.rxEditApproval.approval_rx_item.total_weight
        : '';
    },
  },
  data() {
    return {
      text: '',
      rxApprovedObj: [],
      mutationTypeList: [
        'rxApprovalList/setRxApprovalList',
        'rxApprovalList/increaseRxContainerQuantity',
        'rxApprovalList/decreaseRxContainerQuantity',
        'rxApprovalList/setRxContainerValue',
        'rxApprovalList/setSelectedApproval',
        'rxApprovalList/setSelectedIndex',
      ],
      selectedRxApprovedObj: {},
      containerId: 0,
      containerQuantity: 0,
      selectedIndex: -1,
      dialog: false,
    };
  },
  created() {
    this.selectedIndex = this.$store.state.rxApprovalList.selectedIndex;
    this.$store.subscribe((mutation, state) => {
      const findMutationType = this.mutationTypeList.find(
        (mutationType) => mutationType === mutation.type,
      );
      if (findMutationType) {
        this.selectedIndex = this.$store.state.rxApprovalList.selectedIndex;
        this.rxApprovedObj = [];
        this.rxApprovedObj = state.rxApprovalList.rxSelectedApproval;
        if (mutation.type !== 'rxApprovalList/setRxApprovalList') {
          this.selectedIndexRxApprovalList(this.selectedIndex);
        }
        if (
          mutation.type === 'rxApprovalList/setRxApprovalList'
          && mutation.payload[this.selectedIndex]
          && mutation.payload[this.selectedIndex].stop_approval
          && !mutation.payload[this.selectedIndex].stop_approval.stop_containers
            .length
        ) {
          this.selectedIndexRxApprovalList(this.selectedIndex);
        }
        if (
          mutation.type === 'rxApprovalList/setRxApprovalList'
          && mutation.payload[this.selectedIndex]
          && !mutation.payload[this.selectedIndex].stop_approval
        ) {
          this.selectedIndexRxApprovalList(this.selectedIndex);
        }
        if (
          mutation.type === 'rxApprovalList/setRxApprovalList'
          && mutation.payload[this.selectedIndex]
          && mutation.payload[this.selectedIndex].stop_approval
          && mutation.payload[this.selectedIndex].stop_approval.stop_containers
            .length
        ) {
          this.containerQuantity = mutation.payload[
            this.selectedIndex
          ].stop_approval.stop_containers[0].quantity;
          this.containerId = mutation.payload[
            this.selectedIndex
          ].stop_approval.stop_containers[0].container_id;
        }
      }
    });
    this.selectedIndexRxApprovalList(this.selectedIndex);
    this.rxApprovedObj = this.$store.state.rxApprovalList.rxSelectedApproval;
    this.$forceUpdate();
  },
  methods: {
    gotoAddRxItem() {
      this.$store.commit('rxApprovalList/setSelectedIndex', -1);
      this.$router.push('add-rx-items');
      this.$store.commit('rxApproval/resetApprovalDetail');
      this.$store.commit('rxContent/resetContent');
      this.$store.commit('rxApproval/setRxEmptyBottle', false);
      this.$store.commit('rxApproval/enableShowContent', true);
    },
    goToEditRx(index) {
      this.$store.dispatch('rxApproval/setSelectedRxApproval', index);
      this.$store.dispatch('rxContent/setContentList', {
        index,
        rxEditApproval: this.rxEditApproval,
      });
      this.$store.commit('rxContent/setPreviousMonthButtonStatus', false);
      this.$router.push('add-rx-items');
    },
    saveExit() {
      const params = {};
      params.weight = this.rxEditApproval.approval_rx_item.total_weight;
      params.stop_id = this.rxEditApproval.approval_rx_item.stop_id;
      params.approval_id = this.rxEditApproval.approval_rx_item.approval_id;
      params.waste_type = this.rxEditApproval.waste_type;
      params.approval = this.rxEditApproval.approval_rx_item.approval;
      params.stopApprovalId = this.rxEditApproval.approval_rx_item.stop_approval.id;
      this.$store.dispatch('rxApprovalList/saveAndExit', params).then(() => {
        this.$store.commit('rxApproval/resetApprovalDetail');
        this.$router.push({ name: 'stopDetailWorkArea' });
      });
    },
    consolidate() {
      const params = {
        weight: this.rxApprovedList.total_weight,
        stop_id: +this.$route.params.stopId,
        approval_id: this.rxApprovedList.approval_id,
        waste_type: 'rx',
        containers: [],
        approval_rx_item_id: this.rxApprovedList.id,
        approval: this.rxApprovedList.approval,
      };
      const containerObj = {};
      if (
        this.selectedRxApprovedObj
        && this.selectedRxApprovedObj.containerId
        && this.selectedRxApprovedObj.containerQuantity
      ) {
        if (
          this.selectedRxApprovedObj.stop_approval
          && this.selectedRxApprovedObj.stop_approval.stop_containers.length
        ) {
          containerObj.id = this.selectedRxApprovedObj.stop_approval.stop_containers[0].id;
        } else {
          containerObj.id = '';
        }
        containerObj.container_id = this.selectedRxApprovedObj.containerId;
        containerObj.quantity = this.selectedRxApprovedObj.containerQuantity;
      } else if (
        !this.rxApprovedList.containerQuantity
        && !this.rxApprovedList.containerId
        && this.rxApprovedList.stop_approval
        && this.rxApprovedList.stop_approval.stop_containers.length > 0
      ) {
        containerObj.id = this.rxApprovedList.stop_approval.stop_containers[0].id;
        containerObj.container_id = this.rxApprovedList.stop_approval
          .stop_containers[0].container_id;
        containerObj.quantity = this.rxApprovedList.stop_approval.stop_containers[0].quantity;
      } else {
        containerObj.id = '';
        if (
          this.rxApprovedList.stop_approval
          && this.rxApprovedList.stop_approval.stop_containers.length > 0
        ) {
          containerObj.id = this.rxApprovedList.stop_approval.stop_containers[0].id;
        } else {
          containerObj.id = '';
        }
        containerObj.container_id = this.rxApprovedList.containerId;
        containerObj.quantity = this.rxApprovedList.containerQuantity;
      }
      params.containers.push(containerObj);
      if (this.rxApprovedList.stop_approval) {
        params.stopApprovalId = this.rxApprovedList.stop_approval.id;
        this.$store.dispatch('rxApprovalList/rxReConsolidate', params);
      } else {
        params.approval_rx_item_id = this.rxApprovedList.id;
        this.$store.dispatch('rxApprovalList/rxConsolidate', params);
        this.$store.commit(
          'rxApprovalList/hideRxApprovalConsolidationList',
          false,
        );
      }
    },
    reConsolidated() {
      const params = {};
      params.weight = this.rxEditApproval.approval_rx_item.total_weight;
      params.stop_id = this.rxEditApproval.approval_rx_item.stop_id;
      params.approval_id = this.rxEditApproval.approval_rx_item.approval_id;
      params.waste_type = this.rxEditApproval.waste_type;
      params.approval = this.rxEditApproval.approval_rx_item.approval;
      params.approval.consolidateApprovalId = this.rxEditApproval.approval_rx_item.stop_approval.id;
      params.stopApprovalId = this.rxEditApproval.approval_rx_item.stop_approval.id;
      this.$store.dispatch('rxApprovalList/rxReConsolidate', params);
      this.$store.commit(
        'rxApprovalList/hideRxApprovalConsolidationList',
        false,
      );
    },
    rxEditApprovalSequenceCCIDs(index) {
      return index
        === this.rxEditApproval.approval_rx_item.stop_rx_approval_ccids.length - 1
        ? ''
        : ', ';
    },
    stopRxDescription(rxItem) {
      return rxItem && rxItem.rx_item && rxItem.rx_item.description_with_dosage
        ? rxItem.rx_item.description_with_dosage
        : '-';
    },
    stopRxTotalWeight(rxItem) {
      return rxItem
        ? `${rxItem.total_weight} ${rxItem.manifest_unit.unit}`
        : '-';
    },
    stopRxNdc(rxItem) {
      return rxItem && rxItem.rx_item.ndc ? rxItem.rx_item.ndc : '-';
    },
    stopEditRXApprovalDescription(rxList) {
      return rxList && rxList.approval.description
        ? rxList.approval.description
        : '-';
    },
    stopEditRXApprovalNdc(rxList) {
      return rxList && rxList.rx_item.ndc ? rxList.rx_item.ndc : '-';
    },
    getManifestUnit(rxApprovedList) {
      if (rxApprovedList.manifest_unit && rxApprovedList.manifest_unit.unit) {
        return rxApprovedList.manifest_unit.unit;
      }
      return '';
    },
    goToEditRxDetail(index) {
      this.$store.dispatch('rxApproval/editRXApprovalForConsolidate', {
        index,
        rxApprovedList: this.rxApprovedList,
      });
      this.$store.dispatch('rxContent/setContentList', {
        index,
        rxApprovedList: this.rxApprovedList,
      });
      this.$store.commit('rxContent/setPreviousMonthButtonStatus', false);
      this.$router.push('add-rx-items');
    },
    showConfirmDelete(index, status, type) {
      if (
        type === 'consolidate'
        || this.rxEditApproval.approval_rx_item.stop_rx_items.length > 1
      ) {
        if (type === 'reconsolidate') {
          this.$store.commit('rxApproval/confirmDelete', {
            index,
            value: status,
          });
        } else {
          this.$store.commit('rxApprovalList/confirmDelete', {
            index,
            value: status,
          });
        }
      } else {
        this.$store.commit('consolidate/setApprovalDelete', {
          description: this.rxEditApproval.approval_rx_item.approval
            .description,
          type: 'rx',
          stopApprovalId: this.rxEditApproval.approval_rx_item.stop_approval.id,
          ccids: this.rxEditApproval.approval_rx_item.stop_rx_approval_ccids,
          stopRxItemId: this.rxEditApproval.approval_rx_item.stop_rx_items[0].id,
          approvalId: this.rxEditApproval.approval_id,
        });
        this.dialog = true;
      }
    },
    deleteRxItems(index) {
      if (this.rxEditApproval.approval_rx_item) {
        this.$store.commit('consolidate/setApprovalDelete', {
          rxItemDescription: this.rxEditApproval.approval_rx_item.stop_rx_items[
            index
          ].rx_item.description,
          description: this.rxEditApproval.approval_rx_item.approval
            .description,
          stopRxItemId: this.rxEditApproval.approval_rx_item.stop_rx_items[
            index
          ].id,
          lastRxItem:
            this.rxEditApproval.approval_rx_item.stop_rx_items.length === 1,
          type: 'rx',
          stopId: this.rxEditApproval.approval_rx_item.stop_id,
          stopApprovalId: this.rxEditApproval.approval_rx_item.stop_approval.id,
          approvalId: this.rxEditApproval.approval_id,
        });
        this.dialog = true;
      } else {
        this.$store.commit('consolidate/setApprovalDelete', {
          rxItemDescription: this.rxApprovedList.stop_rx_items[index].rx_item
            .description,
          description: this.rxApprovedList.approval.description,
          stopRxItemId: this.rxApprovedList.stop_rx_items[index].id,
          lastRxItem: this.rxApprovedList.stop_rx_items.length === 1,
          type: 'rx',
          stopId: this.rxApprovedList.stop_id,
          stopApprovalId: this.rxApprovedList.stop_approval_id,
          approvalId: this.rxEditApproval.approval_id,
        });
        this.dialog = true;
        this.$store.commit('rxApproval/resetApprovalDetail');
        this.$store.commit('rxContent/resetContent');
        this.$store.commit('rxApproval/setRxEmptyBottle', false);
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    selectedIndexRxApprovalList(index) {
      this.selectedRxApprovedObj = this.$store.state.rxApprovalList.rxApprovalList[
        index
      ];
      this.checkContainerIdAndContainerQuantityExist();
    },
    checkContainerIdAndContainerQuantityExist() {
      if (
        this.rxApprovedObj
        && this.rxApprovedObj.containerId
        && this.rxApprovedObj.containerQuantity
      ) {
        this.containerId = this.rxApprovedObj.containerId;
        this.containerQuantity = this.rxApprovedObj.containerQuantity;
      } else if (
        this.selectedRxApprovedObj
        && this.selectedRxApprovedObj.containerId
        && this.selectedRxApprovedObj.containerQuantity
      ) {
        this.containerId = this.selectedRxApprovedObj.containerId;
        this.containerQuantity = this.selectedRxApprovedObj.containerQuantity;
      } else {
        this.containerId = 0;
        this.containerQuantity = 0;
      }
    },
  },
  watch: {
    consolidationMessage(newVal) {
      if (newVal === 'successRx') {
        this.dialog = false;
      } else if (newVal && this.dialog) {
        this.dialog = false;
        this.$router.push({ name: 'stopDetailWorkArea' });
      } else if (!newVal && this.dialog) {
        this.dialog = false;
      }
      this.$store.commit('consolidate/setRemoveConsolidationSuccess', '');
      this.dialog = false;
    },
    isRxItemAvailabe() {
      if (this.dialog) {
        this.dialog = false;
        this.$router.push({ name: 'Rx Approval List' });
        this.$store.commit('consolidate/setAvailableRxItem', '');
      }
    },
  },
};
