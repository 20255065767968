//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import KeyboardDialog from '../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import VSelectArrow from '../../helpers/utils/v-select-arrow';

export default {
  name: 'Notes',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({
      getCommonNotesList: 'notes/getCommonNotes',
      getStopNotesDetail: 'notes/getStopNotes',
      stopDetail: 'getStopDetail',
    }),
  },
  data() {
    return {
      newNotes: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      commonNotesList: '',
      selectNotes: '',
      notesError: '',
    };
  },
  extends: VSelectArrow,
  beforeMount() {
    const { stopId } = this.$route.params;
    this.$store.dispatch('notes/getAllCommonNotes');
    this.$store.dispatch('notes/getStopNotes', stopId);
  },
  methods: {
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    keyPressed(value) {
      this.newNotes = value.input;
      if (this.newNotes.length > 500) {
        this.notesError = true;
      } else {
        this.notesError = false;
      }
    },
    enterClicked(value) {
      this.newNotes = value.input;
      const { stopId } = this.$route.params;
      const data = {
        stopsId: stopId,
        notes: this.newNotes,
      };
      if (this.newNotes.length <= 500) {
        this.$store.dispatch('notes/saveStopNotes', data);
      }
    },
    getNotesDetail() {
      this.newNotes = `${this.newNotes} ${this.selectNotes.notes}`;
      if (this.newNotes.length <= 500) {
        const { stopId } = this.$route.params;
        const data = {
          stopsId: stopId,
          notes: this.newNotes,
        };
        this.$store.dispatch('notes/saveStopNotes', data);
      }
      this.selectNotes = '';
      if (this.newNotes.length > 500) {
        this.notesError = true;
      } else {
        this.notesError = false;
      }
    },
  },
  watch: {
    getCommonNotesList(newVal) {
      this.commonNotesList = newVal;
    },
    getStopNotesDetail(newVal) {
      if (newVal.notes) {
        this.newNotes = newVal.notes;
      }
    },
  },
};
