//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'ActiveStopWarning',
  computed: {
    ...mapGetters({ voidedStopsInTrip: 'getVoidedStopsInTrip' }),
  },
  data() {
    return {
      dialog: false,
      activeTripAndStopName: {
        stopName: '',
        activeStop: '',
        activeTrip: '',
        activeStopId: '',
      },
    };
  },
  created() {
    this.checkStatusWarningDialog();
  },
  methods: {
    checkStatusWarningDialog() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'showStopActiveWarning') {
          this.dialog = mutation.payload;
          this.activeTripAndStopName = this.$store.getters.getActiveTripAndStopName;
        }
      });
    },
    goToStopLists() {
      this.dialog = false;
    },
    goToActiveStopDetail() {
      this.$store.dispatch('getStopDetailAction', {
        stopID: this.activeTripAndStopName.activeStopId,
        type: 'popup',
      }, { root: true });
    },
  },
  watch: {
    voidedStopsInTrip() {
      if (this.voidedStopsInTrip.includes(this.activeTripAndStopName.activeStopId)) {
        this.dialog = false;
      }
    },
  },
};
