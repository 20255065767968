<template>
  <div class="consolidate-main-section">
    <div
      v-if="!showCCIDSuccess"
      class="consolidate-sub-section"
    >
      <div class="">
        <v-row
          no-gutters
        >
          <v-col
            cols="12"
            lg="6"
          >
            <div class="header">
              <div
                class="consolidate-main-title"
              >
                Add approval to CCID
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="text-right"
          >
            <div
              class="consolidate-head-title"
            >
              Approval
            </div>
            <div
              class="consolidate-approval-name"
            >
              {{ consolidateApproval.description }}
            </div>
          </v-col>
        </v-row>
      </div>
      <ConsolidationBuilder
        :consolidate-approval="consolidateApproval"
        :route-path="routePath"
      />
    </div>
    <div
      class="d-flex ccid-success-outer-box"
      v-if="showCCIDSuccess"
    >
      <transition
        name="fade"
        appear
        mode="out-in"
      >
        <div
          class="ccid-success-inner-box"
          v-show="showFadeIn"
          key="content"
        >
          <img
            alt="save"
            src="src/assets/images/save.svg"
            class="ccid-success-img"
          >
          <div
            class="d-flex mt-2"
            :class="{'flex-column': addedCCIDs.length > 1}"
          >
            <div>
              <p class="ccid-success-text">
                Approval added to CCID
              </p>
            </div>
            <div
              class="ml-2"
              :class="{'ccid-success-ccid-list': addedCCIDs.length > 1}"
            >
              <p
                v-for="(ccid, index) in addedCCIDs"
                :key="index"
                class="ccid-success-text-list mb-0"
              >
                {{ ccid }}
              </p>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ConsolidationBuilder from '../../helpers/component/consolidation/consolidationBuilder.vue';

export default {
  name: 'Consolidate',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const obj = vm;
      obj.routePath = {
        from: from.path,
        to: to.path,
      };
    });
  },
  components: { ConsolidationBuilder },
  computed: {
    ...mapGetters({
      addedCCIDs: 'consolidate/getAddedCCIDs',
      addToTruckInventorySuccess: 'consolidate/getAddToTruckInventorySuccess',
      rxApprovalList: 'rxApprovalList/getRxApprovalList',
    }),
  },
  data() {
    return {
      consolidateApproval: {
        id: '',
        consolidateApprovalId: '',
        description: '',
      },
      showCCIDSuccess: false,
      showFadeIn: false,
      addToInventoryCompleted: false,
      routePath: {},
    };
  },
  beforeMount() {
    const consolidateDetail = this.$store.state.approvalList.consolidateApproval;
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'setConsolidateApproval' && consolidateDetail) {
        this.getConsolidatesCCIDList();
      }
    });
    this.getConsolidatesCCIDList();
  },
  methods: {
    gotoStopDetailWorkArea() {
      this.$router.push({ name: 'stopDetailWorkArea' });
      this.showCCIDSuccess = false;
    },
    getConsolidatesCCIDList() {
      const consolidateDetail = this.$store.state.approvalList.consolidateApproval;
      if (consolidateDetail && consolidateDetail.approval_id) {
        this.consolidateApproval = consolidateDetail.approval_detail;
        this.consolidateApproval.consolidateApprovalId = consolidateDetail.id;
        this.consolidateApproval.waste_type = consolidateDetail.waste_type;
        this.$store.dispatch('consolidate/getConsolidateLists', {
          approvalId: this.consolidateApproval.consolidateApprovalId,
        });
        this.$store.dispatch('consolidate/getContainerSizeListAction');
        this.$store.dispatch('consolidate/getContainerTypeListAction');
      } else {
        this.gotoStopDetailWorkArea();
      }
    },
  },
  props: {
    rxConsolidate: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    addToTruckInventorySuccess(newVal) {
      if (newVal) {
        this.showCCIDSuccess = true;
        this.showFadeIn = true;
      } else {
        this.showFadeIn = newVal;
        setTimeout(() => {
          if (this.rxConsolidate) {
            this.addToInventoryCompleted = true;
            this.$store.dispatch('rxApprovalList/getRxApprovalList', +this.$route.params.stopId);
          } else {
            this.gotoStopDetailWorkArea();
          }
        }, 200);
      }
    },
    rxApprovalList(newVal) {
      if (newVal.length > 0) {
        if (!this.$route.params.draftRxConsolidate && this.addToInventoryCompleted) {
          this.$store.dispatch('container/containerTypeListAction');
          this.$router.push({ name: 'Add Rx Items' });
          this.addToInventoryCompleted = false;
        }
        this.$route.params.draftRxConsolidate = false;
        this.showCCIDSuccess = false;
      } else if (!this.$route.params.draftRxConsolidate) {
        this.gotoStopDetailWorkArea();
      }
    },
  },
};
</script>
