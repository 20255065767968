//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import expectedPickupInventoryCard from '../expected-pickup-inventory-card/expectedPickupInventoryCard.vue';
import colorGenerator from '../../utils/colorGenerator';
import convertTextToTitleCase from '../../utils/convert-text-to-titlecase';
import customerTypesConstant from '../../../constants/customer-type';

export default {
  name: 'AccumulationExpectedContainer',
  components: {
    expectedPickupInventoryCard,
  },
  computed: {
    ...mapGetters({
      approvalAdded: 'getApprovalAddedSuccessfully',
      openedContainerId: 'getOpenedContainerId',
    }),
    unknownItemCountString() {
      const isOne = Number(this.container.unknown_item_count) === 1;
      const itemString = isOne ? 'item' : 'items';
      return `${this.container.unknown_item_count} ${itemString}`;
    },
  },
  data() {
    return {
      openContainer: false,
      background: null,
      showContainer: false,

    };
  },
  mounted() {
    if (this.customerName === customerTypesConstant.customerTypes.is_costco) {
      this.background = colorGenerator(this.container.container_name);
      if (this.background) {
        this.showContainer = true;
      }
    }
    this.checkPreOpenContainer();
  },
  methods: {
    getArrow(type) {
      if (this.openContainer) {
        if (type === 'src') return 'src/assets/images/up-arrow-active.svg';
        return 'up-arrow';
      }
      if (type === 'src') return 'src/assets/images/down-arrow-active.svg';
      return 'down-arrow';
    },
    titleCaseContainer(containerName) {
      return convertTextToTitleCase(containerName);
    },
    toggleOpenContainer() {
      this.openContainer = !this.openContainer;
      if (this.openContainer) {
        this.$emit('toggleContainer', { id: this.container.container_id });
        this.$store.commit('setOpenedContainerId', this.container.container_id);
      } else {
        this.$emit('toggleContainer', { id: null });
      }
    },
    addToInventoryEvent() {
      this.$store.commit('setOpenedContainerId', this.container.container_id);
    },
    checkPreOpenContainer() {
      if (this.openedContainerId) {
        if (this.container.container_id === this.openedContainerId) {
          this.toggleOpenContainer();
        }
      }
    },
  },
  props: {
    container: {
      type: Object,
      default() {
        return { };
      },
    },
    customerName: {
      type: String,
      default() {
        return '';
      },
    },
  },
};
