<template>
  <v-col
    cols="12"
    lg="4"
  >
    <span
      class="fs-24 float-right"
      v-if="firstIndex || !lastIndex"
    >
      {{ month }}.
    </span>
    <v-row v-if="lastIndex && !firstIndex">
      <v-col
        cols="12"
        lg="6"
        class="text-right"
      >
        <span class="fs-24">
          {{ month }}
        </span>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        class="text-right"
      >
        <img
          alt="remove"
          src="src/assets/images/minus-circle-icon.svg"
          @click="removeLastMonth()"
        >
      </v-col>
    </v-row>
  </v-col>
</template>
<script>

export default {
  name: 'CloseContent',
  methods: {
    removeLastMonth() {
      this.$store.commit('rxContent/removeContent', this.contentIndex);
    },

  },
  props: {

    month: {
      type: String,
      default: '',
    },
    lastIndex: {
      type: Boolean,
      default: false,
    },
    firstIndex: {
      type: Boolean,
      default: false,
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
  },
};
</script>
