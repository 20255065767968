<template>
  <div class="rx-main-section ml-56p">
    <div class="position-relative overflow-hidden">
      <div class="add-rx-page-div">
        <div>
          <v-row no-gutters>
            <v-col
              cols="12"
              lg="3"
            >
              <div>
                <p
                  v-if="(rxEditApproval && rxEditApproval.id) || rxApprovalDetail.editType"
                  class="add-rx-items-title"
                >
                  Edit Rx items
                </p>
                <p
                  v-else
                  class="add-rx-items-title"
                >
                  Add Rx items
                </p>
              </div>
            </v-col>
            <v-col
              cols="12"
              lg="6"
            >
              <rxSearchItems
                v-if="!rxApprovalDetail.editType && rxEditApproval && !rxEditApproval.id"
                :search="search"
                :search-click="searchClick"
                @checkDialog="checkDialogSearch($event)"
                @cancelDialog="clearSearch"
              />
            </v-col>
            <v-col
              cols="12"
              lg="3"
            >
              <template
                v-if="!rxApprovalDetail.editType && rxEditApproval && !rxEditApproval.id"
              >
                <div
                  class="float-right"
                  v-if="rxEmptyBottle"
                >
                  <p class="add-rx-items-details-sub-title margin-bottom-5">
                    Adding bottles
                  </p>
                  <span
                    class="clear-empty-bottles"
                    @click="removeEmptyBottles()"
                  >
                    <i class="fas fa-times" />
                    Clear
                  </span>
                </div>
                <button
                  class="add-empty-bottles-btn"
                  @click="addEmptyBottles()"
                  v-if="!rxEmptyBottle"
                >
                  Add empty p-listed bottles
                </button>
              </template>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="add-rx-sub-div">
        <v-row no-gutters>
          <v-col
            cols="12"
            lg="6"
          >
            <div>
              <p class="add-rx-items-details-title">
                Item details
              </p>
              <label class="add-rx-items-details-sub-title mb-1 d-block">
                NDC
              </label>
              <p
                class="add-rx-items-details-value"
                :class="{'rx-detail': getRxDetail.rx_item &&
                  getRxDetail.rx_item.ndc ? true : false}"
              >
                {{ getRxItemValue(getRxDetail, getRxDetail.rx_item, 'ndc') }}
              </p>
              <label class="add-rx-items-details-sub-title mb-1 d-block">
                Description
              </label>
              <p
                class="add-rx-items-details-value"
                :class="{'rx-detail': getRxDetail.description ? true : false}"
              >
                {{ getRxDetail.description ?
                  getRxDetail.description : 'Search to add...' }}
              </p>
              <label class="add-rx-items-details-sub-title mb-1 d-block">
                Approval
              </label>
              <p
                class="add-rx-items-details-value"
                :class="{'rx-detail': getRxDetail.approval_description ? true : false}"
              >
                {{ getRxDetail.approval_description ?
                  getRxDetail.approval_description : 'Search to add...' }}
              </p>
              <v-row no-gutters>
                <v-col
                  cols="12"
                  lg="12"
                >
                  <label class="add-rx-items-details-sub-title mb-1 d-block">
                    DEA schedule
                  </label>
                  <p
                    class="add-rx-items-details-value"
                    :class="{'rx-detail': getRxDetail.rx_item &&
                      getRxDetail.rx_item.dea_schedule ? true : false}"
                  >
                    {{ getRxItemValue(getRxDetail, getRxDetail.rx_item, 'dea_schedule') }}
                  </p>
                </v-col>
              </v-row>
              <label class="add-rx-items-details-sub-title mb-1 d-block">
                Notes
              </label>
              <div
                class="add-rx-items-details-dropdown cursor-pointer"
                @click="editNote()"
                :class="selectedNotes? 'rx-detail':'' "
              >
                <template v-if="selectedNotes">
                  {{ selectedNotes }}
                </template>
                <template v-else>
                  Please select...
                </template>
              </div>
              <span
                class="add-rx-items-details-dropdown-icon cursor-pointer"
                @click.prevent="viewNotesList()"
              />
              <div
                class="rx-itmes-note-loop"
                v-if="showNotesList"
                @click="viewNotesList()"
              >
                <div class="rx-items-note-scroll">
                  <div
                    class="rx-items-notes-list cursor-pointer"
                    v-for="(approval, index) in getApprovalNotes"
                    :key="index"
                    @click="selectNotes(approval.notes, approval.id)"
                  >
                    {{ approval.notes }}
                  </div>
                </div>
                <div
                  v-if="rxApprovalDetail.rx_item && rxApprovalDetail.rx_item.id"
                  class="rx-items-add-notes-list"
                >
                  <button
                    class="rx-items-add-new-notes"
                    @click="checkDialog('alphaNumeric','note','Add a new note')"
                  >
                    Add a new note
                  </button>
                </div>
              </div>
            </div>
            <div
              class="mt-4"
              :class="{'display-none': showNotesList, 'd-flex flex-row': !showNotesList}"
              v-if="rxApprovalDetail.rx_item.id"
            >
              <button
                class="approval-details"
                @click="drawer = true"
              >
                Approval details
              </button>
            </div>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <rxContentList />
          </v-col>
        </v-row>
        <ApprovalDetailDrawer
          :approval-drawer="drawer"
          :approval="approvalDrawerDetails"
          @closeApprovalDrawer="closeApprovalDrawer"
        />
      </div>
    </div>
    <!-- Add Rx items Search -->
    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="clearApprovalChoice"
      :allow-empty="allowEmpty"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @keyPressed="keyPressed"
      @enterClicked="enterClicked"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import KeyboardDialog from '../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import rxSearchItems from '../rx-search-items/index.vue';
import rxContentList from '../../../helpers/component/rx-content/rxContentList.vue';
import ApprovalDetailDrawer from '../../trip-stops/stop-details/approvals/ApprovalDetailDrawer.vue';

export default {
  name: 'AddRxItems',
  components: {
    KeyboardDialog,
    rxContentList,
    rxSearchItems,
    ApprovalDetailDrawer,
  },
  computed: {
    ...mapGetters({
      getRxDetail: 'rxApproval/getRxApprovalDetail',
      getApprovalNotes: 'rxApproval/getApprovalNotes',
      rxApprovalDetail: 'rxApproval/getRxApprovalDetail',
      rxEditApproval: 'rxApproval/getRxEditApproval',
      addedNotesChanges: 'rxApproval/getAddedNotes',
      showContent: 'rxApproval/getShowContent',
      rxEmptyBottle: 'rxApproval/getRxEmptyBottle',
      approvalDrawerDetails: 'getApprovalDrawerDetails',
    }),
  },
  data() {
    return {
      input: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      search: '',
      modelName: '',
      labelName: '',
      clearApprovalChoice: '',
      keypadIndex: 0,
      modelValue: '',
      showNotesList: false,
      selectedNotes: '',
      rxCancelDialog: false,
      searchClick: false,
      showEmptyBottles: false,
      allowEmpty: false,
      drawer: false,
    };
  },
  created() {
    let rxItemId = 0;
    if (this.getRxDetail.rx_item && this.getRxDetail.rx_item.id) {
      rxItemId = this.getRxDetail.rx_item.id;
      this.selectedNotes = this.getRxDetail.selected_notes;
    }
    this.$store.dispatch('rxApproval/getApprovalNotes', { id: rxItemId });
    this.getContentDetailsList(this.getRxDetail);
    if (this.rxEditApproval) {
      this.$store.dispatch('getApprovalDetailForDrawer', this.rxEditApproval.approval_id, { root: true });
    }
  },
  methods: {
    checkDialog(KeyboardType, modelName, labelName, index,
      clearApprovalChoice, allowEmpty = false) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.keypadIndex = index;
      this.clearApprovalChoice = clearApprovalChoice;
      this.allowEmpty = allowEmpty;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    addEmptyBottles() {
      this.showEmptyBottles = true;
      this.$store.dispatch('rxApproval/getEmptyBottles', this.$route.params.stopId);
      this.$store.commit('rxContent/enablePreviousMonthBtn');
    },
    removeEmptyBottles() {
      this.$store.dispatch('rxApproval/resetAllContentData');
      this.clearSearch();
    },
    enterClicked(value) {
      if (value.modelName === 'search') {
        this.search = value.input;
        this.searchClick = true;
      } else if (value.modelName === 'note') {
        this.selectedNotes = value.input;
        this.$store.commit('rxApproval/setSelectedNotes', this.selectedNotes);
      }
    },
    checkDialogSearch(ev) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = ev.alphaNumeric;
      this.modelName = ev.search;
      this.labelName = ev.labelName;
      this.clearApprovalChoice = ev.clearApprovalChoice;
    },
    getContentDetailsList(rxApprovalDetail) {
      if (rxApprovalDetail.rx_item && rxApprovalDetail.rx_item.id) {
        this.$store.dispatch('rxApproval/getContentDetailsList');
      }
    },
    viewNotesList() {
      this.showNotesList = !this.showNotesList;
    },
    selectNotes(notes, id) {
      this.$store.commit('rxApproval/setSelectedNotes', notes);
      this.selectedNotes = this.getRxDetail.selected_notes;
      if (id) {
        this.getRxDetail.selected_notes = notes;
      }
    },
    clearSearch() {
      this.search = '';
    },
    getRxItemValue(rxDetail, actualField, fieldName) {
      let returnValue = 'Search to add...';
      if (rxDetail && actualField && actualField[fieldName]) {
        returnValue = actualField[fieldName];
      } else if (rxDetail.id) {
        returnValue = 'N/A';
      }
      return returnValue;
    },
    editNote() {
      if (this.getRxDetail.id) {
        if (this.selectedNotes) {
          this.checkDialog('alphaNumeric', 'note', 'Edit note', 0, this.selectedNotes.toString(), true);
        } else {
          this.viewNotesList();
        }
      }
    },
    keyPressed(value) {
      this.search = value.input;
    },
    closeApprovalDrawer() {
      this.drawer = false;
    },
  },
  watch: {
    getRxDetail(newVal) {
      const rxItemId = newVal.rx_item.id || 0;
      this.$store.dispatch('rxApproval/getApprovalNotes', { id: rxItemId });
      this.getContentDetailsList(newVal);
      if (!newVal.selected_notes) {
        this.selectedNotes = '';
      } else {
        this.selectedNotes = newVal.selected_notes;
      }
    },
    addedNotesChanges(newVal) {
      this.selectNotes(newVal);
    },
  },
};
</script>
