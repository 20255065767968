//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StopIcon',
  computed: {
    getClass() {
      let className = 'dea-active';
      if (this.stopType.toLowerCase() === 'st') {
        className = 'st-active';
      }
      if (this.stopType.toLowerCase() === 'fs') {
        className = 'fs-active';
      }
      if (this.stopType.toLowerCase() === 'rx') {
        className = 'rx-active';
      }
      if (this.stopType.toLowerCase() === 'dc') {
        className = 'dc-active';
      }
      if (this.stopType.toLowerCase() === 'cf') {
        className = 'cf-active';
      }
      return className;
    },
  },
  props: {
    stopType: {
      type: String,
      default: '',
    },
    connected: Boolean,
  },
};
