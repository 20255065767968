//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ScanPage from '../../helpers/component/scan-item/scanPage.vue';
import AlertPage from '../../helpers/component/alert-page/alertPage.vue';

export default {
  name: 'RightDrawer',
  components: {
    ScanPage, AlertPage,
  },
  computed: {
    ...mapGetters([
      'getStopDetail', 'getStopVoidStatus', 'getVoidedStopsInTrip',
    ]),
    activeStopLocation() {
      if (this.getStopDetail && this.getStopDetail.generator) {
        return this.getStopDetail.generator.name;
      }
      return '';
    },
  },
  methods: {
    closePDF() {
      this.$emit('closePDFView');
    },
    closeRightDrawer() {
      this.$emit('closeDrawer');
    },
  },
  props: {
    rightDrawer: {
      type: Boolean,
      default: false,
    },
    scanComponent: {
      type: Boolean,
      default: false,
    },
    viewPdf: {
      type: Boolean,
      default: false,
    },
    pdfUrl: {
      type: String,
      default: '',
    },
    alertComponent: {
      type: Boolean,
      default: false,
    },
    fileType: {
      type: String,
      default: 'pdf',
    },
  },
  watch: {
    getStopVoidStatus() {
      this.closeRightDrawer();
      this.closePDF();
    },
    getVoidedStopsInTrip() {
      if (this.getVoidedStopsInTrip.includes(this.getStopDetail.id)) {
        this.closeRightDrawer();
        this.closePDF();
      }
    },
  },
};
