//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { required, email } from 'vuelidate/lib/validators';
import { mapGetters, mapState } from 'vuex';
import pinDialog from '../../helpers/component/pin-dialog/pinDialog.vue';
import pinToolTip from '../../helpers/component/tool-tip/pinToolTip.vue';
import technicianLastEmail from '../../helpers/component/technician-last-email/technicianLastEmail.vue';
import KeyboardDialog from '../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';

export default {
  name: 'LoginComponent',
  components: {
    pinDialog,
    pinToolTip,
    technicianLastEmail,
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({ errorMessage: 'authentication/getError', loginStatus: 'authentication/getloginStatus', storeRecentTech: 'getRecentTech' }),
    ...mapState({ stateDetail: 'authentication/loggedIn' }),
    getLastEmail() {
      return this.$store.getters.getLastLogin;
    },
  },
  data() {
    return {
      form: {
        email: '',
        pin: '',
        lastEmail: 'null',
      },
      pinDialogIsOpen: false,
      welcomeBackTechnician: false,
      highlightVal: '',
      keyboardDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
    };
  },
  beforeMount() {
    this.checkRecentTech();
  },
  methods: {
    submit() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$store.dispatch('createAccount/setDialogValue', false);
      this.pinDialogIsOpen = true;
    },

    signIn() {
      this.$store.dispatch('createAccount/setDialogValue', false);
      this.pinDialogIsOpen = true;
    },

    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },

    dialogInputChangesKeyboard(changes) {
      this.keyboardDialogIsOpen = changes;
    },

    getEmailError() {
      let returnMessage = '';
      if (!this.$v.form.email.required) {
        returnMessage = 'Email is required';
      }
      if (!this.$v.form.email.email) {
        returnMessage = 'Email is invalid';
      }
      return returnMessage;
    },

    checkRecentTech() {
      if (localStorage.getItem('selectedRecentTechnician')) {
        this.lastTechnician = JSON.parse(localStorage.getItem('selectedRecentTechnician'));
        this.form.email = this.lastTechnician.email;
        this.welcomeBackTechnician = true;
      } else {
        this.lastTechnician = '';
        this.form.email = '';
        this.welcomeBackTechnician = false;
        this.$store.dispatch('checkSelectedTech', false);
      }
    },

    changeUser() {
      localStorage.removeItem('selectedRecentTechnician');
      this.welcomeBackTechnician = false;
      this.form.email = '';
      this.$store.dispatch('checkSelectedTech', false);
      this.highlightVal = false;
      this.$store.dispatch('authentication/updateRecentTechnician', '');
    },

    checkChild1Res(data) {
      if (data === true) {
        this.checkRecentTech();
      }
    },

    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.keyboardDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },

    enterClicked(value) {
      this.form[value.modelName] = value.input;
    },
  },
  props: {
    storeRecentTech1: {
      default: false,
      type: Boolean,
    },
  },

  validations: {
    form: {
      email: { required, email },
    },
  },

  watch: {
    loginStatus(newVal) {
      if (newVal === true) {
        this.$router.push('logged-in-home');
      }
    },
    storeRecentTech1(val) {
      this.storeRecentTech = val;
      this.checkRecentTech();
    },
  },
};
