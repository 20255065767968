//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import expectedPickupItem from '../expected-pickup-item/expectedPickupItem.vue';

export default {
  name: 'ExpectedPickupTable',
  components: { expectedPickupItem },
  props: {
    expectedPickupList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
