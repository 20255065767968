//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ApprovalDetailDrawer',
  methods: {
    closeDrawer() {
      this.$emit('closeApprovalDrawer');
    },
  },
  props: {
    approvalDrawer: {
      type: Boolean,
      default: false,
    },
    approval: {
      type: Object,
      default() {
        return {
          haz_flag: false,
          dot_rq_flag: false,
          dot_rq_reason: '-',
          dot_unna_number: '-',
          proper_shipping_name: '-',
          dot_hazmat_class: '-',
          dot_hazmat_subclass: '-',
          packing_group: '-',
          dot_sp_number: '-',
          dot_special_permit_description: '-',
          waste_codes: '',
        };
      },
    },
  },
};
