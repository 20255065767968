<template>
  <span>
    {{ pinSingleDigit }}
  </span>
</template>
<script>
export default {
  name: 'PinDialog',
  props: {
    pinSingleDigit: {
      type: String,
      default: '',
    },
  },
};
</script>
