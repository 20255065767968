//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import labelTableItem from '../label-table-item/labelTableItem.vue';

export default {
  name: 'LabelGroupTable',
  components: {
    labelTableItem,
  },
  computed: {
    headerKeys() {
      return Object.keys(this.tableHeaders);
    },
    isAllLabelsSelected() {
      for (let index = 0; index < this.tableRows.length; index += 1) {
        const item = this.tableRows[index];
        if (!item.selected) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    switchLabelsSelection() {
      if (this.isAllLabelsSelected) {
        this.$store.dispatch('stopLabels/deselectAllLabels');
      } else {
        this.$store.dispatch('stopLabels/selectAllLabels');
      }
    },
  },
  props: {
    tableHeaders: {
      type: Object,
      default() {
        return [];
      },
    },
    tableRows: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
