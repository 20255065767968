<template>
  <div class="contact-examples-main-div">
    <p class="us-ecology-contacts-title">
      US Ecology contacts
    </p>
    <p class="us-ecology-contacts-text">
      If you are unsuccessful with obtaining a response from the
      primary contact please reach out to your supervisor for immediate assistance.
    </p>
    <div class="regulator-div">
      <v-row no-gutters>
        <div
          class="regulator-col-1"
        >
          <img
            class="regulator-icon"
            src="src/assets/images/regulator-icon.svg"
            alt="image"
          >
        </div>
        <div
          class="regulator-col-2"
        >
          <p class="regulator-text-1">
            Regulatory incident reporting
          </p>
          <p class="regulator-text-2">
            Contact your supervisor if there is a regulatory incident. If your
            supervisor is unavailable, contact your EHS Representative.
          </p>
        </div>
      </v-row>
    </div>
    <v-row
      class="margin-top-26"
      no-gutters
    >
      <v-col>
        <div>
          <p class="regulator-example-title">
            Issues related to your trips or stops
          </p>
          <p class="regulator-example-subtitle">
            Contact Field Service Support at
          </p>
          <p class="regulator-example-contacts">
            (866) 900-3762
          </p>
          <p class="regulator-example-title1">
            Examples
          </p>
          <ul class="regulator-example-list">
            <li>
              Unable to open a trip
            </li>
            <li>
              Trip issues
            </li>
            <li>
              Selected wrong stop on trip
            </li>
            <li>
              Missing stops on trip
            </li>
            <li>
              Missing approvals at a stop
            </li>
            <li>
              Missing NDC numbers
            </li>
            <li>
              Service concerns
            </li>
            <li>
              Retail SOP questions
            </li>
          </ul>
        </div>
      </v-col>
      <v-col>
        <div>
          <p class="regulator-example-title">
            Hardware and/or software issues
          </p>
          <p class="regulator-example-subtitle">
            Contact I.T. Support
          </p>
          <p class="regulator-example-contacts">
            (734) 521-8057
          </p>
          <p class="regulator-example-title1">
            Examples
          </p>
          <ul class="regulator-example-list">
            <li>
              TruckSiS equipment issues
            </li>
            <li>
              Trouble printing
            </li>
            <li>
              Unable to sync
            </li>
            <li>
              Error messages
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
    <v-row
      no-gutters
      class="margin-top-40"
    >
      <v-col>
        <div>
          <p class="regulator-example-title">
            Contact your supervisor for:
          </p>
          <p class="regulator-example-title1">
            Examples
          </p>
          <ul class="regulator-example-list">
            <li>
              Request change in route order
            </li>
            <li>
              Credit card (fleet card)/lodging
            </li>
            <li>
              Missing supplies
            </li>
            <li>
              Field Service Support is not available
            </li>
          </ul>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'ContactExamples',
};
</script>
