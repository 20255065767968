<template>
  <div class="trip-code">
    <label class="mb-6 trip-code-header">
      My trips
    </label>
    <label class="ml-1 title pb-2 trip-code-sub-header">
      Add a new trip
    </label>
    <form
      @submit.prevent="submit"
      class="mt-2"
    >
      <div
        class="mb-4 trip-code-input"
        :class="{ 'hasError':submitted && $v.form.trip_code.$error }"
      >
        <p class="label trip-code-label">
          <template v-if="!tripCodeErrorMessage">
            Trip code
          </template>
          <pinToolTip
            v-if="tripCodeErrorMessage"
            :error-message="tripCodeErrorMessage"
          />
        </p>

        <input
          v-model="form.trip_code"
          @click="checkDialog('numeric','trip_code','Enter trip code',form.trip_code)"
          type="text"
          name="trip_code"
          class="input"
          :class="{ 'input-has-success':submitted && !$v.form.trip_code.$error }"
          autocomplete="nope"
          readonly
        >
      </div>
      <div class="truck-trailer-num mb-4">
        <div class="truck-num-col">
          <div>
            <label class="label trip-code-label">Truck number</label>
          </div>
          <div class="width-158p">
            <input
              type="text"
              class="input"
              v-model="form.truck_number"
              readonly
              :disabled="disableTruckNumber"
              @click="checkDialog('numeric','truck_number','Enter truck number',form.truck_number)"
            >
          </div>
        </div>
        <div class="trailer-num-col margin-left-7">
          <div>
            <label class="label trip-code-label">Trailer number</label>
          </div>
          <div class="width-158p">
            <input
              type="text"
              class="input"
              v-model="form.trailer_number"
              readonly
              :disabled="disableTrailerNumber"
              @click="checkDialog('alphaNumeric','trailer_number',
                                  'Enter trailer number',form.trailer_number)"
            >
          </div>
        </div>
      </div>
      <div :class="{ 'hasError':submitted && $v.form.password.$error }">
        <p class="label trip-code-label">
          <template v-if="!tripPasswordErrorMessage">
            Password
          </template>
          <pinToolTip
            v-if="tripPasswordErrorMessage"
            :error-message="tripPasswordErrorMessage"
          />
        </p>
        <div class="width-333p">
          <input
            v-model="form.password"
            @click="checkDialog('alphaNumeric','password','Enter password',
                                form.password,'Caps Lock')"
            type="password"
            name="password"
            class="input"
            :class="{ 'input-has-success': submitted && !$v.form.password.$error }"
            autocomplete="nope"
            readonly
            :disabled="disablePassword"
          >
        </div>
      </div>
      <div class="pt-10">
        <button
          type="submit"
          :disabled="disableSubmit"
          class="my-trip-button-submit"
        >
          Add trip
        </button>
      </div>
    </form>
    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="modelValue"
      :caps-lock="capsLock"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @enterClicked="enterClicked"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import pinToolTip from '../../../helpers/component/tool-tip/pinToolTip.vue';
import KeyboardDialog from '../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';

export default {
  name: 'AddTrip',
  components: {
    pinToolTip,
    KeyboardDialog,
  },
  computed: {
    ...mapGetters(
      {
        tripCodeErrorMessage: 'trip/getTripCodeError',
        tripCodeValidate: 'trip/getTripCodeValid',
        tripPasswordErrorMessage: 'trip/getTripPasswordError',
        tripAddStatusChange: 'trip/getTripAddStatus',
      },
    ),
  },
  data() {
    return {
      disablePassword: true,
      disableTruckNumber: true,
      disableTrailerNumber: true,
      disableSubmit: true,
      form: {
        password: '',
        trip_code: '',
        truck_number: '',
        trailer_number: '',
      },
      submitted: false,
      input: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      capsLock: '',
    };
  },
  beforeMount() {
    this.$store.commit('trip/clearStatus');
  },
  methods: {
    submit() {
      this.submitted = true;
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$store.dispatch('trip/addTripAction', this.form);
      this.resetForm();
    },
    tripCodeOnBlur() {
      this.$store.dispatch('trip/tripValidateAction', this.form.trip_code);
    },
    passwordChanges() {
      this.disableSubmit = true;
      if (this.form.password) {
        this.disableSubmit = false;
      }
    },
    resetForm() {
      this.disablePassword = true;
      this.disableSubmit = true;
      this.form.trip_code = '';
      this.form.password = '';
      this.form.truck_number = '';
      this.form.trailer_number = '';
      this.submitted = false;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue, capsLock) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
      this.capsLock = capsLock;
    },
    enterClicked(value) {
      this.form[value.modelName] = value.input;
      if (value.modelName === 'trip_code') {
        this.tripCodeOnBlur();
      }
      if (value.modelName === 'password') {
        this.passwordChanges();
      }
    },
  },
  validations: {
    form: {
      password: { required },
      trip_code: { required },
    },
  },
  watch: {
    tripCodeValidate(newVal) {
      if (newVal) {
        this.disablePassword = false;
        this.disableTruckNumber = false;
        this.disableTrailerNumber = false;
      }
    },
  },


};
</script>
