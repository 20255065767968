import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function printDocument(payload, stopId, preview = false) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  };
  let url = `${API_BASE_URL}stops/print/${stopId}`;
  if (preview) {
    url += `?preview=${preview}`;
  }
  return fetchService.getFetchData(url, requestOptions);
}

function printTestDocument() {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const url = `${API_BASE_URL}stops/print_test`;
  return fetchService.getFetchData(url, requestOptions);
}

function printLabel(labels, tripId) {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ labels }),
  };
  const url = `${API_BASE_URL}stops/print/label/${tripId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function printTestLabel() {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const url = `${API_BASE_URL}stops/print_test_label`;
  return fetchService.getFetchData(url, requestOptions);
}

function printSerialNumber() {
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };
  const url = `${API_BASE_URL}stops/print_serial_number`;
  return fetchService.getFetchData(url, requestOptions);
}

function printTripsDetail(userId, preview, tripId) {
  const requestOptions = {
    method: 'GET',
  };
  let url = `${API_BASE_URL}trips/print_trips_details/${userId}?preview=${preview}`;
  if (tripId) {
    url += `&trip_id=${tripId}`;
  }
  return fetchService.getFetchData(url, requestOptions);
}

const printBackendService = {
  printLabel,
  printDocument,
  printTestDocument,
  printTripsDetail,
  printTestLabel,
  printSerialNumber,
};
export default printBackendService;
