//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'ActiveStopsForTrip',
  computed: {
    ...mapGetters({
      incompletedStops: 'getIncompletedStopList',
      modalType: 'getCompleteTripModalType',
      activeStops: 'getActiveStopList',
    }),
    stopList() {
      if (this.activeStops.length > 0) {
        return this.activeStops;
      }
      return this.incompletedStops;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  created() {
    this.checkStatusWarningDialog();
  },
  methods: {
    checkStatusWarningDialog() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'setShowCompleteTripModal') {
          this.dialog = mutation.payload.openModal;
        }
      });
    },
    goToStopLists() {
      this.dialog = false;
    },
    goToSelectedStop(stopId) {
      this.goToStopLists();
      this.$store.dispatch('getStopDetailAction', {
        stopID: stopId,
        type: 'popup',
      });
    },
    completeCurrentTrip() {
      const { tripId } = this.$route.params;
      this.$store
        .dispatch('completeCurrentTrip', { tripId, is_completed: true })
        .then((res) => {
          this.$router.push('/trip-list');
          this.$store.dispatch('tripStatusChangeToaster', {
            tripCode: res.trip_code,
            type: 'completed',
          });
        });
    },
  },
};
