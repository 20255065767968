import Vue from 'vue';

Vue.filter('phoneNumberFormat', (value) => {
  let splitPhoneNumber = '';
  if (value && value.length === 10) {
    const val = value.toString();
    for (let i = 0; i < val.length; i += 1) {
      if (i < 3) {
        splitPhoneNumber += val.charAt(i);
        if (i === 2) {
          splitPhoneNumber += '-';
        }
      } else if (i > 2 && i < 6) {
        splitPhoneNumber += val.charAt(i);
        if (i === 5) {
          splitPhoneNumber += '-';
        }
      } else if (i > 5 && i < 10) {
        splitPhoneNumber += val.charAt(i);
      }
    }
    return splitPhoneNumber;
  }
  return value;
});
