
const sequenceList = [
  {
    index: 0,
    label: 'Sorting',
    path: '/stops/detail/stopID',
    subPaths: [
      '/add/approval',
      '/approval/list',
      '/approval-consolidate',
      '/add-rx-items',
      '/rx/item/lists',
      '/rx-approval-consolidate',
      '/rx-approval-list',
    ],
    active: true,
  },
  {
    index: 1,
    label: 'Q&A',
    path: '/stops/detail/stopID/q-a-page',
    subPaths: [],
    active: false,
  },
  {
    index: 2,
    label: 'Print documents',
    path: '/stops/detail/stopID/print-document',
    subPaths: [],
    active: false,
    show_stop_completed: true,
  },
  {
    index: 3,
    label: 'Print labels',
    path: '/stops/detail/stopID/print-label',
    subPaths: [],
    active: false,
    show_stop_completed: true,
  },
  {
    index: 4,
    label: 'Attachments',
    path: '/stops/detail/stopID/attachment',
    subPaths: [],
    active: false,
  },
  {
    index: 5,
    label: 'Notes',
    path: '/stops/detail/stopID/note',
    subPaths: [],
    active: false,
  },
];

module.exports = {
  sequenceList,
};
