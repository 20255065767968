//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import KeyboardDialog from '../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';


export default {
  name: 'StopMap',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({ tripDetailChanges: 'getTripDetail' }),
  },

  data() {
    return {
      tripDetail: {},
      activeStop: {},
      updateShow: false,
      successShow: false,
      existingTrailerNumber: '',
      existingTruckNumber: '',
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
    };
  },
  created() {
    this.getActiveStop();
  },
  methods: {
    getActiveStop() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'createActiveStop') {
          this.activeStop = mutation.payload;
        }
      });
    },
    undoStop() {
      const params = this.activeStop;
      params.stop_active = false;
      params.stop_active_time = null;
      this.$store.dispatch('updateActiveStop', params);
    },
    getTruckChanged() {
      return this.existingTrailerNumber
      !== this.tripDetail.trailer_number
      || this.existingTruckNumber !== this.tripDetail.truck_number;
    },
    updateTrip() {
      const params = {};
      params.userId = this.tripDetail.user_id;
      params.tripCode = this.tripDetail.trip_code;
      params.truck_number = this.tripDetail.truck_number;
      params.trailer_number = this.tripDetail.trailer_number;
      this.updateShow = true;
      this.$store.dispatch('updateTrip', params).then((res) => {
        if (res === 'tripUpdateSuccess') {
          setTimeout(() => {
            this.updateShow = false;
            this.successShow = true;
            setTimeout(() => {
              this.successShow = false;
            }, 500);
          }, 1000);
        }
        this.setTruckAndTrailerNumber();
      });
    },
    setTruckAndTrailerNumber() {
      this.existingTrailerNumber = this.tripDetail.trailer_number;
      this.existingTruckNumber = this.tripDetail.truck_number;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    enterClicked(value) {
      if (value.modelName === 'truck_number') {
        this.tripDetail.truck_number = value.input;
      }
      if (value.modelName === 'trailer_number') {
        this.tripDetail.trailer_number = value.input;
      }
    },
    openCompleteTripModal() {
      const { tripId } = this.$route.params;
      this.$store.dispatch('openCompletedTripModal', tripId);
    },
    completeCurrentTrip() {
      const { tripId } = this.$route.params;
      this.$store.dispatch('completeCurrentTrip', { tripId, is_completed: false }).then((res) => {
        this.$router.push('/trip-list');
        this.$store.dispatch('tripStatusChangeToaster', { tripCode: res.trip_code, type: 'reopen' });
      });
    },
  },
  watch: {
    tripDetailChanges(newVal) {
      this.tripDetail = newVal;
      this.setTruckAndTrailerNumber();
    },
  },
};
