<template>
  <div class="width-100p attachments-component">
    <div class="d-flex flex-column">
      <div class="d-flex justify-space-between width-100p attachments-header">
        <div class="attachment-label">
          <label>{{ currentScanState }}</label>
        </div>
      </div>
    </div>
    <div
      class="attachment-table-div"
      v-if="currentScanState === scanStates.addAttachment"
    >
      <attachment-type-table @scanAttachment="scanPrePopulatedAttachment" />
    </div>
    <div
      class="d-flex add-attachment-section"
      :class="attachmentExists ? 'pl-85' : ''"
      v-if="currentScanState === scanStates.addAttachment"
    >
      <div class="type-selector-div">
        <v-select
          ref="attachmentType"
          outlined
          :items="attachmentTypes"
          height="49"
          v-model="currentAttachmentType"
          class="manifest-select"
          append-icon="fa-angle-down"
          :menu-props="{ offsetY: true }"
          background-color="white"
          placeholder="Select..."
          @click:append="closeSelectDropdown('attachmentType')"
        />
      </div>
      <div class="input-div">
        <input
          class="input desc-input"
          type="text"
          placeholder="Enter description"
          @click="openKeyboard"
          v-model="currentDescription"
          @selectstart.prevent
          @paste.prevent
          @copy.prevent
          @cut.prevent
          @drag.prevent
          @drop.prevent
        >
        <div
          v-if="descriptionErrorMsg"
          class="description-error"
        >
          {{ descriptionErrorMsg }}
        </div>
      </div>
      <div class="add-div">
        <v-btn
          class="add-btn"
          @click="addAttachment"
          :class="{ disabled: !attachmentIsValid }"
        >
          <img
            src="src/assets/images/plus-circle-icon.svg"
            alt="recycle"
          >
          Add new attachment
        </v-btn>
      </div>
    </div>
    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="modelValue"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @enterClicked="enterClicked"
    />
    <div
      class="attachments-dialog"
      v-if="shouldShowAddAttachmentsDialog"
    >
      <div class="attachments-header">
        <div class="add-attachment-label">
          Add attachments
        </div>
        <button
          class="close-btn"
          @click="returnToAttachmentsScreen"
        >
          <img
            class="close-img"
            src="src/assets/images/close-circle.svg"
            alt="close"
          >
          Cancel
        </button>
      </div>
      <div class="attachments-content">
        <div class="attachment-label">
          <div
            class="error-label"
            v-if="currentScanState === scanStates.scanError"
          >
            Error message from scanner:
          </div>
          <label>{{ currentScanState }}</label>
          <div
            class="page-desc-label"
            v-if="currentScanState === scanStates.scanAnotherPage"
          >
            Page {{ currentPage }}
          </div>
        </div>
        <div class="scan-actions">
          <v-btn
            class="scan-page-btn"
            @click="scanPage"
            v-if="currentScanState === scanStates.enterIntoScanner"
          >
            Begin scan
          </v-btn>
          <v-btn
            class="scan-page-btn w-210 rescan-btn"
            @click="setupRescanPage"
            v-if="currentScanState === scanStates.scanAnotherPage"
          >
            Rescan page
          </v-btn>
          <v-btn
            class="scan-page-btn w-210"
            @click="scanAnotherPage"
            v-if="currentScanState === scanStates.scanAnotherPage"
          >
            Add another page
          </v-btn>
          <v-btn
            class="done-btn"
            @click="completeScanning"
            v-if="currentScanState === scanStates.scanAnotherPage"
          >
            Save and exit document
          </v-btn>
          <v-btn
            class="scan-page-btn"
            @click="setupRescanPage"
            v-if="currentScanState === scanStates.scanError"
          >
            Rescan
          </v-btn>
        </div>
        <div
          class="i-frame-content"
          v-if="currentScanState === scanStates.scanAnotherPage"
        >
          <iframe
            :src="currentPdfUrl"
            width="635"
            height="905"
          />
        </div>
      </div>
    </div>
    <right-drawer
      :right-drawer="openPdfDrawer"
      :scan-component="false"
      :view-pdf="true"
      :pdf-url="decodedUrl"
      :file-type="dataTypeForDrawer"
      @closePDFView="removePdfUrl"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import attachmentTypeTable from '../../../helpers/component/attachment-type-table/attachmentTypeTable.vue';
import KeyboardDialog from '../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import scanBackendService from '../../../service/scan.backend.service';
import { API_BASE_URL } from '../../../constants/index';
import rightDrawer from '../../../layout/logged-in-header/rightDrawer.vue';
import vSelectArrow from '../../../helpers/utils/v-select-arrow';

export default {
  name: 'StopAttachments',
  components: {
    attachmentTypeTable,
    KeyboardDialog,
    rightDrawer,
  },
  computed: {
    attachmentIsValid() {
      return this.currentDescription && this.currentAttachmentType;
    },
    shouldShowAddAttachmentsDialog() {
      return (
        [
          this.scanStates.enterIntoScanner,
          this.scanStates.scanningDocument,
          this.scanStates.scanAnotherPage,
          this.scanStates.scanError,
        ].indexOf(this.currentScanState) > -1
      );
    },
    ...mapGetters({
      attachments: 'attachment/getAttachments',
      pdfUrl: 'attachment/getCurrentPdfUrl',
      stopDetail: 'getStopDetail',
      fileType: 'attachment/getCurrentAttachmentType',
    }),
    attachmentExists() {
      return this.attachments.length > 0;
    },
    decodedUrl() {
      let dataType = 'data:application/pdf;base64';
      if (this.fileType === 'jpg') {
        dataType = 'data:image/jpeg;base64';
      }
      return `${dataType},${this.pdfUrl}`;
    },
  },
  data() {
    return {
      attachmentTypes: [
        { text: 'LDR' },
        { text: 'BOL' },
        { text: 'Pickup Report' },
        { text: 'DEA-41' },
        { text: 'DEA-222' },
        { text: 'PRD' },
        { text: 'Packing List' },
        // { text: 'Photo' },
        { text: 'Generator Initial Manifest' },
        { text: 'Attachment' },
        { text: 'Customer Document' },
        { text: 'Other' },
      ],
      currentAttachmentType: '',
      currentManifestName: '',
      scanCancelled: false,
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      currentDescription: '',
      currentScanState: '',
      currentPageState: 'first',
      scanStates: {
        addAttachment: 'Add attachments',
        enterIntoScanner: 'Please insert first page into scanner.',
        scanningDocument: 'Scanning document...',
        scanAnotherPage: 'Scanned document',
        scanError: '',
      },
      descriptionErrorMsg: '',
      dialog: true,
      currentPage: 1,
      currentPdfUrl: '',
      scanTimeout: null,
      openPdfDrawer: false,
      dataTypeForDrawer: 'pdf',
    };
  },
  extends: vSelectArrow,
  mounted() {
    this.currentScanState = this.scanStates.addAttachment;
    this.$store.dispatch(
      'attachment/setAttachmentsDataFromDb',
      this.$store.getters.getStopDetail.id,
    );
  },
  beforeDestroy() {
    if (this.scanTimeout) {
      clearTimeout(this.scanTimeout);
    }
  },
  methods: {
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      this.descriptionErrorMsg = '';
      this.currentDescription = value.input;
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    openKeyboard() {
      this.checkDialog(
        'alphaNumeric',
        'currentDescription',
        'Description',
        this.currentDescription,
      );
    },
    addAttachment() {
      if (this.attachmentIsValid) {
        if (this.currentDescription.length < 16) {
          this.scanCancelled = false;
          this.currentScanState = this.scanStates.enterIntoScanner;
        } else {
          this.descriptionErrorMsg = 'Description should have a maximum of 15 characters.';
        }
      }
    },
    scanPage() {
      this.currentScanState = this.scanStates.scanningDocument;
      this.scanTimeout = setTimeout(() => {
        this.scanAttachment();
      }, 1000);
    },
    scanAttachment() {
      const attachment = {
        page: this.currentPage,
      };
      scanBackendService.scanAttachment(attachment).then(
        (res) => {
          if (!this.scanCancelled) {
            if (res.success) {
              this.showScanSuccessComponentWithPdf();
            } else {
              this.showErrorComponent(res.error);
            }
          }
        },
        () => {
          this.$store.dispatch(
            'attachment/showToastErrorMessage',
            'There was a problem communicating with the scanner',
          );
          this.returnToAttachmentsScreen();
        },
      );
    },
    showErrorComponent(errorMessage) {
      this.scanStates.scanError = errorMessage;
      this.currentScanState = this.scanStates.scanError;
    },
    showScanSuccessComponentWithPdf() {
      const cacheBuster = Date.now();
      let zeroKey = '';
      if (this.currentPage < 10) {
        zeroKey = '0';
      }
      this.currentPdfUrl = `${API_BASE_URL}stops/attachment/pdf/${zeroKey}${this.currentPage}#toolbar=0&navpanes=0&timestamp=${cacheBuster}`;
      this.showScanSuccessComponent();
    },
    showScanSuccessComponent() {
      this.currentScanState = this.scanStates.scanAnotherPage;
    },
    scanAnotherPage() {
      this.currentPage += 1;
      this.currentScanState = this.scanStates.enterIntoScanner;
    },
    completeScanning() {
      this.addAttachmentInStore();
      this.returnToAttachmentsScreen();
    },
    setupRescanPage() {
      this.currentScanState = this.scanStates.enterIntoScanner;
    },
    returnToAttachmentsScreen() {
      this.scanCancelled = true;
      this.currentAttachmentType = '';
      this.currentDescription = '';
      this.currentPage = 1;
      this.currentScanState = this.scanStates.addAttachment;
      this.currentManifestName = '';
    },
    addAttachmentInStore() {
      const attachmentData = {
        id: 0,
        attachment_type: this.currentAttachmentType,
        description: this.currentDescription,
        stop: this.$store.getters.getStopDetail.id,
        manifest_name: this.currentManifestName,
        file_type: this.getFileType(this.currentAttachmentType), // TODO: Fill with actual file type
      };
      if (this.attachments.length > 0) {
        attachmentData.id = this.attachments[this.attachments.length - 1].id + 1;
      }
      this.$store.dispatch('attachment/addAttachmentsData', attachmentData);
    },
    getFileType(attachmentType) {
      if (attachmentType) {
        return 'pdf';
      }
      return '';
    },
    removePdfUrl() {
      this.$store.dispatch('attachment/setCurrentAttachmentPdfUrl', '');
    },
    openPdfView() {
      this.openPdfDrawer = true;
    },
    closePDFView() {
      this.openPdfDrawer = false;
    },
    scanPrePopulatedAttachment(attachment) {
      this.scanCancelled = false;
      this.currentScanState = this.scanStates.enterIntoScanner;
      this.currentAttachmentType = attachment.attachment_type;
      this.currentManifestName = attachment.manifest_name;
      this.currentDescription = attachment.description;
    },
  },
  watch: {
    currentPage(page) {
      if (page === 1) {
        this.currentPageState = 'first';
        this.scanStates.enterIntoScanner = 'Please insert first page into scanner.';
      } else {
        this.currentPageState = 'next';
        this.scanStates.enterIntoScanner = 'Please insert the next page into scanner.';
        this.currentScanState = this.scanStates.enterIntoScanner;
      }
    },
    pdfUrl: {
      deep: true,
      handler(url) {
        if (url) {
          this.openPdfView();
        } else {
          this.closePDFView();
        }
      },
    },
    fileType: {
      deep: true,
      handler(val) {
        this.dataTypeForDrawer = val;
      },
    },
  },
};
</script>
