<template>
  <div class="feedback-contact-main-div">
    <img
      class="feedback-image"
      src="src/assets/images/ss-logo.svg"
    >
    <p
      class="please-contact-text"
    >
      Please contact Smarter Sorting Support if you have feedback or
      feature requests for the TruckSiS hardware and software.
    </p>
    <v-row no-gutters>
      <v-col>
        <p class="please-contact-title">
          Email:
        </p>
        <p class="please-contact-id">
          support@smartersorting.com
        </p>
      </v-col>
      <v-col>
        <p class="please-contact-title">
          Hours:
        </p>
        <p class="please-contact-id">
          7 days a week
        </p>
        <p class="please-contact-id">
          7am-8pm Central Time
        </p>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'Feedback',
};
</script>
