//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import tripStorage from '../../../constants/trip-storage';

export default {
  name: 'BeginStopInstructionDialog',
  computed: {
    ...mapGetters({
      getStopDetail: 'getStopDetail',
      storageSelectedForTrip: 'trip/getStorageSelectedForTrip',
      getVoidedStopsInTrip: 'getVoidedStopsInTrip',
      getTripDetail: 'getTripDetail',
    }),
  },
  data() {
    return {
      dialog: false,
      stopId: null,
      unsubscribe: () => {},
    };
  },
  created() {
    this.checkStatusBeginStopDialog();
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    checkStatusBeginStopDialog() {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === 'showBeginStopInstruction') {
          this.dialog = mutation.payload.status;
          this.stopId = mutation.payload.stopId;
        }
      });
    },
    cancel() {
      this.dialog = false;
    },
    beginStop() {
      if (this.storageSelectedForTrip) {
        this.$store.dispatch('stopArriveAction', this.stopId);
      } else if (this.checkActiveOrCompletedStop()) {
        const params = {
          stopId: this.stopId,
          storageMethod: tripStorage.storageMethods.use_ccid,
        };
        this.$store.dispatch('firstStopArriveAction', params);
      } else {
        this.$emit('openStorageModel', this.stopId);
      }
    },
    checkActiveOrCompletedStop() {
      return this.getTripDetail.stops.some((stop) => stop.is_completed || stop.is_active);
    },
  },
  watch: {
    getVoidedStopsInTrip() {
      if (this.getVoidedStopsInTrip.includes(this.stopId)) {
        this.dialog = false;
      }
    },
  },
};
