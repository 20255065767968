//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RxItemIncrementDecrementInput from '../../../helpers/component/mobile-rx-inc-dec-input/mobileRxIncDecInput.vue';

export default {
  name: 'MobileBillUnits',
  components: {
    RxItemIncrementDecrementInput,
  },
  props: {
    billUnits: {
      default: () => [
        {
          BillUnitCode: '',
          quantity: 0,
        },
      ],
      type: Array,
    },
  },
};
