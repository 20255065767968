import forgotPIN from '../views/forgot-pin/forgotPin.vue';
import bootUp from '../views/landing-page/bootUp.vue';
import createAccount from '../views/create-account/createAccount.vue';
import Login from '../views/login/login.vue';
import loggedInHome from '../views/logged-in-home/loggedInHome.vue';
import confirmCreateAccount from '../views/confirm-createAccount/confirm.vue';
import resetPin from '../views/reset-pin/resetPin.vue';
import stopLists from '../views/trip-stops/tripStopList.vue';
import stopDetail from '../views/trip-stops/stop-details/tripStopDetail.vue';
import stopDetailWorkArea from '../views/trip-stops/stop-details/stopDetailWorkArea.vue';
import rxBuilder from '../views/rx-items/add-rx-items/index.vue';
import addApproval from '../views/trip-stops/stop-details/approvals/addApproval.vue';
import approvalList from '../views/trip-stops/stop-details/approvals/approvalList.vue';
import helpComponent from '../views/help-page/help.vue';
import rxItemLists from '../views/rx-items/rx-item-lists/index.vue';
import Consolidate from '../views/consolidate/index.vue';
import stopDocument from '../views/trip-stops/stop-details/stopDocument.vue';
import stopLabel from '../views/trip-stops/stop-details/stopLabel.vue';
import stopAttachments from '../views/trip-stops/stop-details/stopAttachments.vue';
import rxApprovals from '../views/rx-items/rx-approval-card/index.vue';
import QAndAPage from '../views/q-a-page/index.vue';
import Notes from '../views/notes-page/index.vue';
import MobileComponent from '../views/mobile/index.vue';
import MobileRoutes from './mobile-routes';
import mobileLogin from '../views/login/login-mobile.vue';
import restart from '../views/power-off/restart-screen.vue';
import shutdown from '../views/power-off/shutdown-screen.vue';
import viewAllCcid from '../views/trip-stops/viewAllCcid.vue';
import pickupData from '../views/trip-stops/pickupData.vue';
import releaseNotes from '../views/release-notes/index.vue';

export default [
  {
    path: '/',
    redirect: '/bootUp',
  },
  {
    path: '/restart',
    name: 'Restart',
    component: restart,
    meta: { layout: 'empty-layout' },
  },
  {
    path: '/shutdown',
    name: 'Shutdown',
    component: shutdown,
    meta: { layout: 'empty-layout' },
  },
  {
    path: '/forgot-pin',
    name: 'Forgot PIN',
    component: forgotPIN,
    meta: { layout: 'auth-layout' },
  },
  {
    path: '/bootUp',
    name: 'Boot Up',
    component: bootUp,
    meta: { layout: 'auth-layout' },
  },
  {
    path: '/create-account',
    name: 'Create Account',
    component: createAccount,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logged-in-home',
    name: 'Loggedin Home',
    component: loggedInHome,
    meta: { requiresAuth: true, layout: 'logged-in-layout' },
  },
  {
    path: '/trip-list',
    name: 'Trip List',
    component: loggedInHome,
    meta: { requiresAuth: true, layout: 'logged-in-layout' },
  },
  {
    path: '/confirm-account',
    name: 'Confirm Account',
    component: confirmCreateAccount,
    props: true,
  },
  {
    path: '/reset-pin',
    name: 'Reset Pin',
    component: resetPin,
    meta: { layout: 'auth-layout' },
  },
  {
    path: '/stops/list/:tripId',
    name: 'Stop List',
    component: stopLists,
    props: true,
    meta: { requiresAuth: true, layout: 'logged-in-layout', stopList: true },
  },
  {
    path: '/view-all-ccid/:tripId',
    name: 'View All CCID',
    component: viewAllCcid,
    props: true,
    meta: { requiresAuth: true, layout: 'logged-in-layout', stopList: true },
  },
  {
    path: '/help',
    name: 'Help Page',
    component: helpComponent,
    meta: { requiresAuth: true, layout: 'logged-in-layout' },
  },
  {
    path: '/boot-help',
    name: 'Help Page Boot',
    component: helpComponent,
  },
  {
    path: '/stop/pickup-data/:stopId',
    component: pickupData,
    props: true,
    meta: { requiresAuth: true, layout: 'logged-in-layout', pickupData: true },
  },
  {
    path: '/release-notes',
    name: 'Release notes',
    component: releaseNotes,
    meta: { layout: 'empty-container', showContainerBox: false },
  },
  {
    path: '/stops/detail/:stopId',
    component: stopDetail,
    props: true,
    children: [
      {
        path: '',
        name: 'stopDetailWorkArea',
        component: stopDetailWorkArea,
        meta: { requiresAuth: true, layout: 'logged-in-layout' },
      },
      {
        path: 'add/approval',
        component: addApproval,
        name: 'Add Approval',
        meta: { requiresAuth: true, layout: 'logged-in-layout' },
      },
      {
        path: 'approval/list/:approvalCode?',
        component: approvalList,
        name: 'Approval List',
        meta: { requiresAuth: true, layout: 'logged-in-layout' },
      },
      {
        path: 'q-a-page',
        component: QAndAPage,
        name: 'Q And A Page',
        meta: { requiresAuth: true, layout: 'logged-in-layout' },
      },
      {
        path: 'note',
        component: Notes,
        name: 'Notes',
        meta: { requiresAuth: true, layout: 'logged-in-layout' },
      },
      {
        path: 'add-rx-items',
        name: 'Add Rx Items',
        component: rxBuilder,
        meta: { requiresAuth: true, layout: 'logged-in-layout', componentName: 'rx-drawer' },
      },
      {
        path: 'rx-approval-list',
        name: 'Rx Approval List',
        component: rxApprovals,
        meta: { requiresAuth: true, layout: 'logged-in-layout', componentName: 'rx-drawer' },
      },
      {
        path: 'rx/item/lists',
        name: 'Rx Items Lists',
        component: rxItemLists,
        meta: { requiresAuth: true, layout: 'logged-in-layout', componentName: 'rx-drawer' },
      },
      {
        path: 'approval-consolidate',
        name: 'Consolidate',
        component: Consolidate,
        meta: { requiresAuth: true, layout: 'logged-in-layout' },
      },
      {
        path: 'rx-approval-consolidate',
        name: 'RxConsolidate',
        component: Consolidate,
        meta: { requiresAuth: true, layout: 'logged-in-layout', componentName: 'rx-drawer' },
        props: { rxConsolidate: true },
      },
      {
        path: 'print-document',
        name: 'Print Document',
        component: stopDocument,
        meta: { requiresAuth: true, layout: 'logged-in-layout' },
      },
      {
        path: 'print-label',
        name: 'Print Label',
        component: stopLabel,
        meta: { requiresAuth: true, layout: 'logged-in-layout' },
      },
      {
        path: 'attachment',
        name: 'Attachments',
        component: stopAttachments,
        meta: { requiresAuth: true, layout: 'logged-in-layout' },
      },
    ],
  },
  {
    path: '/mobile',
    name: 'Mobile View Pages',
    component: MobileComponent,
    props: true,
    children: MobileRoutes,
  },
  {
    path: '/login-mobile',
    name: 'Login1',
    component: mobileLogin,
    meta: { layout: 'mobile-auth-layout' },
    props: true,
  },
];
