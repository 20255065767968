//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StopSummaryItem',
  computed: {
    formattedRetailSisWeight() {
      return Number(this.summaryData.retailSisWeight).toFixed(2);
    },
    formattedTruckSisWeight() {
      return Number(this.summaryData.truckSisWeight).toFixed();
    },
  },
  props: {
    summaryData: {
      type: Object,
      default() {
        return { };
      },
    },
  },
};
