//
//
//
//
//

export default {
  name: 'PinDialog',
  props: {
    pinSingleDigit: {
      type: String,
      default: '',
    },
  },
};
