//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MobileRxItemListCard',
  created() {
    this.$store.commit('loadingSearchIcon/loadingRequest', { status: false, loadingName: 'Loading search results' }, { root: true });
  },
  methods: {
    goToApprovalDetails(rxItem) {
      this.$store.commit('rxApproval/setSelectedRxItem', rxItem);
      this.$router.push({ name: 'Mobile View Item Details' });
    },
  },
  props: {
    rxItem: {
      type: Object,
      default: () => { },
    },
  },
};
