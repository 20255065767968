<template>
  <v-row class="ma-0 width-pc-100 plr-10">
    <template v-if="tripList.length>0">
      <MobileTripCard
        v-for="(trip, index) in tripList"
        :key="index"
        :trip-detail="trip"
      />
    </template>
    <template v-if="tripList.length===0">
      <div class="mt-2 no-assign-trip">
        No assigned trips
      </div>
    </template>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import MobileTripCard from '../../../helpers/component/trip-card/tripCard-mobile.vue';

export default {
  name: 'MobileTripList',
  components: { MobileTripCard },
  computed: {
    ...mapGetters({ tripList: 'trip/getTripList' }),
  },
  created() {
    this.$store.commit('trip/setFirstLogin', false);
    this.$store.dispatch('trip/mobileTripListAction');
  },

};
</script>
