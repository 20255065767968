<template>
  <div class="width-100p height-100p mob-stop-main-page-container">
    <div
      class="width-100p h690px"
      :class="{'stop-info-background': show}"
    >
      <div
        class="mob-stop-info-expand"
        :class="[{'height-100vh': show}, {'stop-info-icon-background': !show}]"
      >
        <div
          class="width-100p"
          :class="{'stop-main-page-box-shadow': !show}"
        >
          <div
            class="d-flex mob-stop-info-item"
            @click.prevent="showContent('outer')"
          >
            <div
              class="d-flex flex-3"
            >
              <div
                class="m-right"
                @click.stop="showContent('inner')"
              >
                <div
                  v-if="!show"
                  class="info-icon"
                />
                <div
                  v-if="show"
                  class="close-icon cursor-pointer"
                />
              </div>
              <div class="mob-stop-info-header">
                <label>{{ stopDetail.generator.name }}</label>
              </div>
            </div>
            <div class="stop-information-header-types">
              <div
                v-for="(stop_type, index) in stopDetail.type"
                :key="index"
                class="box"
              >
                <label>{{ stop_type }}</label>
                <div
                  class="ml-2"
                  :class="getClass(stop_type)"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-if="show">
          <stopInfoContent />
        </div>
      </div>
      <div
        class="stop-nav-list-container h84p5"
        v-if="!show"
      >
        <div
          class="list-items d-flex flex-column"
        >
          <template v-for="(item, index) in navList">
            <main-stop-page-nav-list
              v-if="!item.hide"
              :key="index"
              :nav-item="item"
              :has-network="hasNetwork"
              @go-to-page="goToPage"
            />
          </template>
        </div>
        <button
          class="sync-to-truck"
          :disabled="syncToTruck"
        >
          <span> Sync to truck </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MainStopPageNavList from '../../../helpers/component/main-stop-page-nav-list/mainStopPageNavList.vue';
import stopInfoContent from './stopInfoContent.vue';

export default {
  name: 'MobileStopDetailPage',
  components: {
    MainStopPageNavList,
    stopInfoContent,
  },
  computed: {
    ...mapGetters({
      stopDetail: 'getStopDetail',
      navList: 'getStopNavList',
      hasNetwork: 'getHasNetworkConnection',
    }),
  },
  data() {
    return {
      show: false,
      syncToTruck: true,
      connectionInterval: {},
    };
  },
  created() {
    this.checkNetworkConnection();
    this.$store.commit('rxApprovalList/setPListedBottleStatus', true);
    this.$store.dispatch('questionnaire/getQuestionAndNumbersMobile', { stopId: this.stopDetail.doc_id });
    this.$store.dispatch('attachment/getAttachmentsDataFromFirestore', this.stopDetail.doc_id);
    this.$store.dispatch('rxApprovalList/getMobileRxApprovalList', this.stopDetail.doc_id);
    this.$store.commit('rxApproval/setRxItemMainPropDefault');
  },
  destroyed() {
    clearInterval(this.connectionInterval);
  },
  methods: {
    showContent(type) {
      if (this.show && type === 'outer') {
        return;
      }
      this.show = !this.show;
    },
    getClass(stopType) {
      let className = 'dea-active';
      if (stopType.toLowerCase() === 'rx') {
        className = 'rx-active';
      }
      if (stopType.toLowerCase() === 'st') {
        className = 'st-active';
      }
      if (stopType.toLowerCase() === 'fs') {
        className = 'fs-active';
      }
      return className;
    },
    goToPage(ev) {
      this.$router.push({ name: ev.routerPath });
    },
    checkAddSync() {
      let status = false;
      this.navList.forEach((list) => {
        if (list.edited) {
          status = true;
        }
      });
      return status;
    },
    checkNetworkConnection() {
      this.$store.dispatch('setNetworkConnection');
      this.connectionInterval = setInterval(() => {
        this.$store.dispatch('setNetworkConnection');
      }, 20000);
    },
  },
  watch: {
    navList: {
      deep: true,
      handler(val) {
        if (val[2].edited) {
          this.syncToTruck = false;
        } else {
          this.syncToTruck = true;
        }
      },
    },
  },
};
</script>
