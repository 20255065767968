<template>
  <div class="container attachment-type-table">
    <table cellspacing="0">
      <col
        width="86"
        v-if="attachmentExists"
      >
      <col width="350">
      <col width="400">
      <tr class="table-header-row">
        <th v-if="attachmentExists" />
        <th>
          Type
        </th>
        <th>
          Description
        </th>
        <th />
      </tr>
      <tr
        class="table-data-row"
        v-for="(attachment, index) in combineAttachment"
        :key="index"
      >
        <td
          class="view-row"
          v-if="attachmentExists"
        >
          <button
            class="btn-submit view-btn"
            @click="viewAttachmentPdf(attachment)"
            v-if="!attachment.isPrePopulated"
          >
            View
          </button>
        </td>
        <td v-if="attachment.isPrePopulated">
          {{ attachment.type | readableAttachmentType }}
        </td>
        <td v-else>
          {{ attachment.attachment_type | readableAttachmentType }}
        </td>
        <td v-if="attachment.isPrePopulated">
          {{ attachment.name }}
        </td>
        <td v-else>
          {{ attachment.description }}
        </td>
        <td
          v-if="attachment.isPrePopulated"
          class="action-btn"
        >
          <v-btn
            class="capture-btn"
            @click="scanAttachment(attachment)"
          >
            Capture
          </v-btn>
        </td>
        <td
          class="delete-btn-td"
          v-else
        >
          <button
            class="delete-btn"
            @click="deleteAttachment(attachment.id)"
          >
            <img
              src="src/assets/images/minus-circle-icon.svg"
              alt="minus"
            >
            <div class="btn-label">
              Delete
            </div>
          </button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import documentConstants from '../../../constants/document';

const documentTypes = documentConstants.types;

export default {
  name: 'AttachmentTypeTable',
  computed: {
    ...mapGetters({
      attachments: 'attachment/getAttachments',
      combineAttachment: 'attachment/getAttachmentWithPrePopulatedData',
    }),
    attachmentExists() {
      return this.attachments.length > 0;
    },
  },
  methods: {
    deleteAttachment(id) {
      this.$store.dispatch('attachment/deleteAttachmentsData', id);
    },
    viewAttachmentPdf(attachment) {
      this.$store.dispatch('attachment/setCurrentAttachmentPdfUrl', attachment.raw_data);
      this.$store.commit('attachment/setCurrentAttachmentType', attachment.file_type);
    },
    scanAttachment(prePopulatedAttachment) {
      const attachment = {
        attachment_type: prePopulatedAttachment.type,
      };
      if (prePopulatedAttachment.type === documentTypes.HAZ_MANIFEST
        || prePopulatedAttachment.type === documentTypes.NON_HAZ_MANIFEST
        || prePopulatedAttachment.type === documentTypes.BOL) {
        attachment.manifest_name = prePopulatedAttachment.name;
        attachment.description = prePopulatedAttachment.name;
      } else {
        attachment.description = prePopulatedAttachment.name;
        attachment.manifest_name = '';
      }
      this.$store.dispatch('attachment/setSelectedPrePopulatedAttachment', prePopulatedAttachment);
      this.$emit('scanAttachment', attachment);
    },
  },
};
</script>
