//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import helpDocumentPage from './help-document/help-document.vue';
import releaseNotesPage from './release-notes/release-notes.vue';
import contactExamplesPage from './contact-examples/contact-examples.vue';
import updateCheckService from '../../service/updateCheck.service';
import feedbackPage from './feedback/feedback.vue';
import systemDiagnosticPage from '../system-diagnostic/index.vue';
import { version } from '../../../package.json';

export default {
  name: 'HelpComponent',
  components: {
    helpDocumentPage,
    releaseNotesPage,
    contactExamplesPage,
    feedbackPage,
    systemDiagnosticPage,
  },
  data() {
    return {
      pageView: 'helpDocument',
      versions: { f: '', b: '', p: '' },
      serialNumber: '',
    };
  },
  created() {
    updateCheckService.getVersion().then((res) => {
      this.versions = { f: version, b: res.backend, p: res.production };
    });
    updateCheckService.getSerialNumber().then((res) => {
      const sn = res.serial_number;
      if (sn) {
        this.serialNumber = sn.trim();
      } else {
        this.serialNumber = 'Unavailable';
      }
    });
  },
  methods: {
    gotoHelpDocument() {
      this.pageView = 'helpDocument';
    },
    gotoReleaseNotes() {
      this.pageView = 'releaseNotes';
    },
    gotoContactExamples() {
      this.pageView = 'contactExamples';
    },
    goToSystemDiagnostics() {
      this.pageView = 'systemDiagnostics';
    },
    gotoFeedback() {
      this.pageView = 'feedback';
    },
  },
};
