<template>
  <div class="stop-questionnaire">
    <div class="questionnaire-heading">
      <label>Stop questionnaire</label>
    </div>
    <div
      v-if="survey && showNoQuestionError === false"
      :class="{'stop-complete-disable': stopCompleted}"
    >
      <survey
        :survey="survey"
      />
    </div>
    <div
      class="fs-18"
      v-if="showNoQuestionError === true"
    >
      No questions on this stop.
    </div>
    <KeyboardDialog
      :open-dialog="keyboardDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="modelValue"
      :max-length="keyBoardMaxLength"
      @dialogInputChanged="dialogInputChangesKeyboard"
      name="keyboard"
      @enterClicked="enterClicked"
    />
  </div>
</template>
<script>
import SurveyVue from 'survey-vue';
import { mapGetters } from 'vuex';
import KeyboardDialog from '../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';

const { Survey } = SurveyVue;
export default {
  name: 'QAndAPage',
  components: {
    Survey,
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({
      showNoQuestionErrorMsg: 'questionnaire/getShowNoQuestionError',
      questionDetail: 'questionnaire/getActiveQuestionDetails',
    }),
  },
  data() {
    return {
      survey: null,
      stopCompleted: false,
      keyboardDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
      keyBoardMaxLength: '',
      currentFreeTextQuestionActiveName: '',
      showNoQuestionError: null,
    };
  },
  created() {
    const { stopDetail } = this.$store.state.stopDetail;
    this.stopCompleted = stopDetail.is_completed;
    this.$store.dispatch('questionnaire/getQuestionAndNumbers', {
      stopId: this.$route.params.stopId,
      stopCompleted: this.stopCompleted,
    });
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'questionnaire/setQuestionAndAnswerList') {
        this.survey = new SurveyVue.Model(mutation.payload);
        this.questionOnValueChanged();
        this.onAfterPageRender();
      }
    });
  },
  methods: {
    questionOnValueChanged() {
      this.survey
        .onAfterRenderPage.add(() => {
          const node = document.querySelectorAll('.sv_row input');
          for (let i = 0; i < node.length; i += 1) {
            node[i].addEventListener('focus', () => {
              if (node[i]) {
                this.currentFreeTextQuestionActiveName = node[i].parentNode.parentNode.getAttribute('name');
                this.checkDialog('alphaNumeric', '', 'Enter answer', node[i].value, '25');
              }
            });
          }
        });
      this.survey
        .onValueChanged
        .add((result, options) => {
          const stopQuestionId = options.name.match(/question(\d+)/i)[1];
          this.$store.commit('questionnaire/setQuestionNumber', stopQuestionId);
          const questionStatus = result.data;
          if (questionStatus && !this.questionDetail.id) {
            const selectedQuestionValues = this.survey.getValue(options.name);
            if (selectedQuestionValues && selectedQuestionValues.length > 1) {
              this.survey.setValue(options.name, [selectedQuestionValues[1]]);
            }
            if (!selectedQuestionValues
              || (selectedQuestionValues && selectedQuestionValues.length < 2)) {
              let answerType;
              if (selectedQuestionValues
                && (selectedQuestionValues[0] === 'No'
                || selectedQuestionValues[0] === 'Yes')) {
                [answerType] = selectedQuestionValues;
              } else {
                answerType = '';
              }
              const params = {
                stop_question_id: stopQuestionId,
                answer_text: answerType,
              };
              this.updateQuestionAndAnswer(params);
            }
          } else if (questionStatus && this.survey.getValue(options.name)) {
            const params = {
              stop_question_id: stopQuestionId,
              answer_text: this.survey.getValue(options.name),
            };
            this.updateQuestionAndAnswer(params);
          }
        });
    },
    onAfterPageRender() {
      this.survey.onAfterRenderPage.add(() => {
        const rowElements = this.$el.querySelectorAll('.sv_row');
        rowElements.forEach((el, i) => {
          const newItem = document.createElement('div');
          const textNode = document.createTextNode(`Question ${i + 1}`);
          newItem.classList.add('question-number');
          newItem.appendChild(textNode);
          el.insertBefore(newItem, el.childNodes[0]);
        });
      });
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue, maxLength) {
      this.keyboardDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
      this.keyBoardMaxLength = maxLength;
    },
    dialogInputChangesKeyboard(changes) {
      this.keyboardDialogIsOpen = changes;
    },
    enterClicked(value) {
      if (value.input && this.currentFreeTextQuestionActiveName) {
        this.survey.setValue(this.currentFreeTextQuestionActiveName, value.input);
        this.currentFreeTextQuestionActiveName = '';
      }
    },
    updateQuestionAndAnswer(params) {
      this.$store.dispatch('questionnaire/updateQuestionnaireAction', {
        updateParams: params,
        stopCompleted: this.stopCompleted,
      });
    },
  },
  watch: {
    showNoQuestionErrorMsg(newVal) {
      this.showNoQuestionError = newVal;
    },
  },
};
</script>
