//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import VSelectArrow from '../../utils/v-select-arrow';

export default {
  name: 'ContentSelect',
  computed: {
    ...mapGetters({
      contentDetails: 'rxApproval/getContentDetails',
      rxApprovalData: 'rxApproval/getRxApprovalDetail',
    }),
  },
  data: () => ({
    selectedID: '',
  }),
  extends: VSelectArrow,
  created() {
    this.selectedID = this.contentId ? +this.contentId : '';
  },
  methods: {
    contentSelectChange() {
      this.$store.commit('rxContent/setContentValue', {
        index: this.contentIndex,
        field: 'contentID',
        value: String(+this.selectedID.id),
      });
    },
  },
  props: {

    contentId: {
      type: String,
      default: '',
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    contentDetails(newVal) {
      if (newVal.length === 0) {
        this.selectedID = '';
      }
    },
  },
};
