<template>
  <div>
    <div
      class="mobile-group-close-icon-div"
      @click="goBack"
    >
      <img
        class="mobile-approval-details-close-icon"
        src="src/assets/images/close-circle-blue.svg"
        alt="close"
      >
      <span class="mobile-approval-details-close-text">Close</span>
    </div>
    <div class="mobile-rx-approval-details-div">
      <v-row
        no-gutters
        class="padding-15p"
      >
        <v-col cols="8">
          <p class="mobile-rx-list-approval-name ma-b-8">
            Approval
          </p>
          <p class="mobile-rx-list-approval-description">
            {{ approvalObj.approvalDescription }}
          </p>
        </v-col>
        <v-col
          cols="4"
          class="text-align-right"
        >
          <p class="mobile-rx-list-approval-name ma-b-8">
            Total weight
          </p>
          <p class="mobile-rx-list-approval-description">
            {{ approvalObj.totalWeight }} lb
          </p>
        </v-col>
      </v-row>
      <hr class="mobile-rx-items-line">
      <div class="mobile-rx-approval-description-div">
        <div class>
          <p>Description</p>
        </div>
      </div>
      <hr class="mobile-rx-items-line">
      <div class="mobile-rx-item-details-group padding-15p">
        <v-row
          v-for="(rxItem, index) in approvalObj.rxItems"
          :key="index"
          no-gutters
        >
          <v-col cols="7">
            <p>{{ rxItem.rx_item_description }}</p>
          </v-col>
          <v-col
            cols="4"
            class="text-center"
          >
            <button
              class="mobile-rx-approval-details-edit-btn"
              @click="editRxItem(index)"
            >
              Edit
            </button>
          </v-col>
          <v-col
            cols="1"
            class="text-center"
          >
            <img
              class="mobile-image-delete-icon margin-top-8"
              src="src/assets/images/trash-red.svg"
              alt="delete"
              @click="openDialog(rxItem, index)"
            >
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="removeApprovalDialog"
        width="80%"
      >
        <v-card>
          <div class="padding-24">
            <p
              class="remove-approval-dialog-title"
              primary-title
            >
              Are you sure you want to delete:
            </p>
            <p class="remove-approval-dialog-description">
              {{ rxItemData.rx_item_description }}
            </p>
          </div>
          <div>
            <button
              class="remove-approval-cancel-btn"
              @click="closeDialog()"
            >
              Cancel
            </button>
            <button
              class="remove-approval-confirm-btn"
              @click="deleteRxItem()"
            >
              <em class="fas fa-trash" />
              Delete
            </button>
          </div>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MobileRxApprovalDetail',
  computed: {
    ...mapGetters({ approval: 'rxApproval/getSelectedViewApproval' }),
  },
  data() {
    return {
      approvalObj: {},
      stopId: '',
      removeApprovalDialog: false,
      indexData: null,
      rxItemData: {},
    };
  },
  created() {
    this.stopId = this.$store.state.stopDetail.stopDetail.id;
    // this is needed because when i changed something on edit details page and clicked cancel
    // the original object values are getting updated.
    this.approvalObj = JSON.parse(JSON.stringify(this.approval));
  },
  methods: {
    calculateWeight() {
      let totalWeight = this.approvalObj.rxItems.reduce((response, rxItem) => {
        const dupRxItem = rxItem;
        const contentWeight = dupRxItem.contentList.reduce((res, content) => {
          if (this.approvalObj.empty_bottle_flag) {
            const weight = parseFloat(content.bottleWeight) + content.residualWeight;
            return res + weight;
          }
          const calculate = parseFloat(content.weightPounds + (content.weightOunces / 16));
          return res + calculate;
        }, 0.0);
        return response + contentWeight;
      }, 0.0);
      totalWeight = Math.round((totalWeight + Number.EPSILON) * 100) / 100;
      this.approvalObj.totalWeight = totalWeight;
    },
    editRxItem(index) {
      const rxItem = this.approvalObj.rxItems[index];
      const rxItemObj = {
        ...rxItem,
        edit: 1,
        stop_id: this.stopId,
        approval_id: this.approvalObj.approvalId,
        index,
        empty_bottle_flag: this.approvalObj.empty_bottle_flag,
      };
      this.$store.commit('rxApproval/setRxItemObject', rxItemObj);
      this.$store.commit('rxContent/setContentList', rxItem.contentList);
      this.$router.push({ path: 'add-rx_approval-card' });
    },
    deleteRxItem() {
      this.approvalObj.rxItems.splice(this.indexData, 1);
      this.calculateWeight();
      this.$store.dispatch('rxApproval/deleteRxItemFromApproval', {
        rxItem: this.rxItemData,
        index: this.indexData,
        docId: this.approvalObj.doc_id,
      });
      this.removeApprovalDialog = false;
      if (this.approvalObj.rxItems.length <= 0) {
        this.$store.commit('rxApproval/setRxItemMainPropDefault');
        this.$router.push({ name: 'Mobile Add RX Items' });
      }
    },
    openDialog(rxItem, index) {
      this.rxItemData = rxItem;
      this.indexData = index;
      this.removeApprovalDialog = true;
    },
    closeDialog() {
      this.removeApprovalDialog = false;
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
