//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import LogOutConfirmation from '../../helpers/component/logout-confirmation/logoutConfirmation.vue';
import PowerOffConfirmation from '../../helpers/component/power-off-comfirmation/index.vue';

export default {
  name: 'SideMenu',
  components: {
    LogOutConfirmation,
    PowerOffConfirmation,
  },
  computed: {
    ...mapGetters({ modalType: 'leaveApproval/getModalType' }),
    ...mapGetters(['getSideNavBarStates']),
  },
  data() {
    return {
      drawer: true,
      clipped: false,
      isOpenDialog: false,
      isPowerDialog: false,
    };
  },
  methods: {
    gotoTrips() {
      this.$router.push('/trip-list');
    },
    logOut() {
      const { approvalDetail } = this.$store.state.approvalList;
      const { rxApprovalDetail } = this.$store.state.rxApproval;

      if (approvalDetail.id
      && this.$route.name === 'Add Approval') {
        this.$store.commit('leaveApproval/setLeaveApprovalDialog', {
          isLeaveApprovalDialog: true,
          pageType: 'approval',
          fromUrl: '',
          redirectUrl: '',
          triggerDialog: 'logout',
        });
      } else if (this.$route.name === 'Consolidate') {
        this.$store.commit('leaveApproval/setLeaveApprovalDialog', {
          isLeaveApprovalDialog: true,
          pageType: 'consolidate',
          fromUrl: '',
          redirectUrl: '',
          triggerDialog: 'logout',
        });
      } else if (this.$route.name === 'RxConsolidate') {
        this.$store.commit('leaveApproval/setLeaveApprovalDialog', {
          isLeaveApprovalDialog: true,
          pageType: 'rxconsolidate',
          fromUrl: '',
          redirectUrl: '',
          triggerDialog: 'logout',
        });
      } else if (rxApprovalDetail.id
        && !rxApprovalDetail.editType
        && this.$route.name === 'Add Rx Items') {
        this.$store.commit('leaveApproval/setLeaveApprovalDialog', {
          isLeaveApprovalDialog: true,
          pageType: 'rx-approval',
          fromUrl: '',
          redirectUrl: '',
          triggerDialog: 'logout',
        });
      } else {
        this.showLogoutDialog();
        this.resetLeaveApprovalModal();
      }
    },
    cancelPowerDialog(value) {
      this.isPowerDialog = value;
    },
    cancelDialog(value) {
      this.isOpenDialog = value;
      this.resetLeaveApprovalModal();
    },
    gotoHelpPage() {
      this.$router.push('/help');
    },
    showLogoutDialog() {
      this.isOpenDialog = true;
    },
    powerOffDialog() {
      this.isPowerDialog = true;
    },
    resetLeaveApprovalModal() {
      this.$store.commit('leaveApproval/setModalType', '');
    },
  },
  watch: {
    modalType(newVal) {
      if (newVal === 'logout') {
        this.showLogoutDialog();
      }
    },
  },
};
