<template>
  <v-container class="d-flex flex-row justify-start align-center profile-info-container">
    <div
      class="d-flex align-center profile-item"
      :class="{'inactive-profile': !profile.profile_present_flag}"
    >
      <div class="d-flex flex-column justify-center align-center info-container">
        <template v-if="profile.percentage">
          <div class="percentage-value">
            {{ profile.percentage }}
          </div>
          <div class="label-text">
            of stops
          </div>
        </template>
        <div
          v-else
          class="not-available-label"
        >
          Not available
        </div>
      </div>
      <div class="d-flex justify-space-between align-center profile-container">
        <div class="profile-label">
          {{ profile.profile }}
        </div>
        <v-btn
          v-if="!profile.stop_approval_data && profile.profile_present_flag"
          class="btn add-btn"
          @click="addApproval(profile.approval_data)"
        >
          Add to inventory
        </v-btn>
        <v-btn
          v-else-if="profile.stop_approval_data && profile.profile_present_flag"
          class="btn edit-btn"
          @click="editApproval(profile.stop_approval_data)"
        >
          Edit approval
        </v-btn>
        <div
          v-else
          class="not-present-label"
        >
          Marked as not present at stop
        </div>
      </div>
    </div>
    <div class="action-container">
      <v-btn
        v-if="!profile.stop_approval_data && profile.profile_present_flag"
        class="cancel-btn"
        @click="profileActiveStatus(false)"
      >
        <img
          src="src/assets/images/cancel-white.svg"
          alt="remove-icon"
        >
      </v-btn>
      <div
        v-else-if="profile.stop_approval_data && profile.profile_present_flag"
        class="d-flex flex-column justify-center align-center add-container"
      >
        <img
          class="check-icon"
          src="src/assets/images/check-full-green.svg"
          alt="check-icon"
        >
        <div class="add-label">
          Added
        </div>
      </div>
      <v-btn
        v-else
        class="add-btn"
        @click="profileActiveStatus(true)"
      >
        <img
          src="src/assets/images/plus-circle-icon.svg"
          alt="add-icon"
        >
      </v-btn>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import connectedStopConstant from '../../../constants/connected-stop';

export default {
  name: 'ExpectedPickupInventoryCard',
  computed: {
    ...mapGetters({ useCCIDStorage: 'getUseCCIDStorage', stopDetails: 'getStopDetail' }),
  },
  data() {
    return {
      approvalAdded: false,
    };
  },
  methods: {
    addApproval(approvalData) {
      this.$emit('addToInventory');
      const approval = approvalData;
      if (typeof approval.bill_units !== 'object') {
        approval.bill_units = JSON.parse(approval.bill_units);
      }
      this.$store.dispatch('assignApprovalAction', approval);
      this.$router.push({ name: 'Add Approval' });
    },

    editApproval(stopApprovalData) {
      this.$store.commit('setApprovalStatusType', true);
      const stopApproval = {};
      let id = null;
      let wasteType = null;
      if (this.useCCIDStorage) {
        id = stopApprovalData.stop_approval.id;
        wasteType = stopApprovalData.stop_approval.waste_type;
      } else {
        id = stopApprovalData.id;
        wasteType = stopApprovalData.waste_type;
      }
      Object.assign(stopApproval, { id, waste_type: wasteType });
      if (stopApproval.id && stopApproval.waste_type === 'retail') {
        this.$store.dispatch('editApproval', stopApproval).then(() => {
          this.$router.push({ name: 'Add Approval' });
          setTimeout(() => {
            this.$store.dispatch('container/setSelectedContainerTypeLists');
          }, 0);
        });
      } else {
        this.$store.dispatch('rxApproval/editRxApproval', { stop_approval: stopApproval });
        this.$store.commit('rxApprovalList/hideRxApprovalConsolidationList', true);
        this.$router.push({ name: 'Rx Approval List' });
      }
    },

    profileActiveStatus(status) {
      if (this.stopDetails.connected_status
        !== connectedStopConstant.status.ACCUMULATION_DATA_PRESENT) {
        const storedData = JSON.parse(localStorage.getItem('profileActiveStatus'));
        const profileObj = storedData
          .find((localData) => this.profile.manifest_profile_id
              === localData.manifestProfileId);
        Object.assign(profileObj, { profilePresentFlag: status });
        localStorage.setItem('profileActiveStatus', JSON.stringify(storedData));
      } else {
        const storedData = JSON.parse(localStorage.getItem('accumulatedProfileActiveStatus'));
        const profileObj = storedData
          .find((localData) => this.profile.manifest_profile_id
              === localData.manifestProfileId
              && this.container.container_id === localData.containerId);
        Object.assign(profileObj, { profilePresentFlag: status });
        localStorage.setItem('accumulatedProfileActiveStatus', JSON.stringify(storedData));
      }

      this.profile.profile_present_flag = status;
    },
  },
  props: {
    profile: {
      type: Object,
      default() {
        return { };
      },
    },
    container: {
      type: Object,
      default() {
        return { };
      },
    },
  },
};
</script>
