<template>
  <div class="container">
    <v-overlay
      :value="dialog"
      opacity="0.94"
      z-index="3"
    >
      <div class="logout-container">
        <div class="logout-content-box">
          <div class="logout-header">
            <label>Are you sure you want to logout?</label>
          </div>
          <div class="logout-confirmation-btn">
            <div>
              <button
                class="Cancel"
                @click="cancelLogout()"
              >
                Cancel
              </button>
            </div>
            <div>
              <button
                class="logout"
                @click="logout()"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import alertsService from '../../../service/alerts.service';

export default {
  name: 'LogoutConfirmation',
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch('authentication/logoutAction');
      this.$store.commit('attachment/resetPrintedDocumentList');
      this.$store.commit('attachment/resetPrintedDocumentAndAttachmentMap');
      this.$store.commit('stopLabels/updateLabelReprintStatus', false);
      this.$store.commit('stopDocuments/updateDocumentReprintStatus', false);
      alertsService.stopAlertsUpdate();
    },
    cancelLogout() {
      this.$emit('cancel-dialog', false);
    },
  },
  props: {
    openDialog: Boolean,
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
  },
};
</script>
