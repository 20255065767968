import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function createContact(stopID) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = `${API_BASE_URL}stops/contact/${stopID}`;
  return fetchService.getFetchData(url, requestOptions);
}

function deleteContact(stopID) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = `${API_BASE_URL}stops/contact/${stopID}`;
  return fetchService.getFetchData(url, requestOptions);
}

const stopContactService = {
  createContact,
  deleteContact,

};
export default stopContactService;
