
import questionService from '../service/question.service';
import questionMobileService from '../service/firestore/question.service';
import toastMessage from './toast-message';

function sendErrorMessage(commit, errorMessage, errorType) {
  let messageData = '';
  messageData = errorMessage.split(':');
  messageData = messageData[messageData.length - 1];
  toastMessage.showToastErrorMessage(commit, messageData, errorType);
}

function defaultQuestion() {
  return {
    pages: [
      {
        name: 'page1',
        elements: [
        ],
      },
    ],
    showQuestionNumbers: 'off',
  };
}
function convertSurveyJsJsonFormatAndCommit(context, payload, stopCompleted) {
  const questionFormat = defaultQuestion();
  const elementsArray = [];
  const questionFreeTextListArray = [];
  payload.forEach((obj) => {
    let answerType = obj.answer_text;
    if (answerType.toString() === '') {
      answerType = null;
    }
    if (obj.answer_type_id === 1) {
      questionFreeTextListArray.push(obj);
      elementsArray.push({
        type: 'text',
        title: obj.question_text,
        name: `question${obj.id}`,
        id: obj.id,
        placeHolder: '',
        enableIf: `${stopCompleted} = false`,
        maxLength: 25,
        defaultValue: obj.answer_text,
      });
    } else if (obj.answer_type_id === 2) {
      elementsArray.push({
        type: 'checkbox',
        title: obj.question_text,
        name: `question${obj.id}`,
        colCount: 2,
        choices: [
          'Yes',
          'No'],
        defaultValue: answerType,
        enableIf: `${stopCompleted} = false`,
      });
    }
  });
  questionFormat.pages[0].elements = elementsArray;
  context.commit('setQuestionAndAnswerList', questionFormat);
  context.commit('setQuestionFreeTextListArray', questionFreeTextListArray);
}
function convertSurveyJsJsonFormatAndCommitMobile(context, payload) {
  const questionFormat = defaultQuestion();
  const elementsArray = [];
  const questionFreeTextListArray = [];
  let checkStatus = false;
  payload.forEach((obj) => {
    let answerType = obj.answer_text;
    if (answerType.toString() === '') {
      answerType = null;
    } else {
      checkStatus = true;
    }
    if (obj.answer_type_id === 1) {
      questionFreeTextListArray.push(obj);
      elementsArray.push({
        type: 'text',
        title: obj.question_text,
        name: `question${obj.id}`,
        placeHolder: '',
        maxLength: 25,
        defaultValue: obj.answer_text,
      });
    } else if (obj.answer_type_id === 2) {
      elementsArray.push({
        type: 'checkbox',
        title: obj.question_text,
        name: `question${obj.id}`,
        colCount: 2,
        choices: [
          'Yes',
          'No'],
        defaultValue: answerType,
      });
    }
  });
  if (checkStatus === true) {
    context.commit('updateNavListValue', { index: 3, field: 'edited', value: true }, { root: true });
  } else {
    context.commit('updateNavListValue', { index: 3, field: 'edited', value: false }, { root: true });
  }
  questionFormat.pages[0].elements = elementsArray;
  context.commit('setQuestionAndAnswerListMobile', questionFormat);
  context.commit('setQuestionFreeTextListArray', questionFreeTextListArray);
}
const state = {
  questionAndAnswerLists: defaultQuestion(),
  updateQuestionStatus: null,
  questionFreeTextListArray: [],
  questionNumber: 0,
  showNoQuestionErrorMsg: null,
};
const getters = {
  getQuestionAndAnswerList(context) {
    return context.questionAndAnswerLists;
  },
  getActiveQuestionDetails(context) {
    return context.questionFreeTextListArray.find((obj) => obj.id === context.questionNumber) || {};
  },
  getShowNoQuestionError(context) {
    return context.showNoQuestionErrorMsg;
  },
};
const mutations = {
  setQuestionAndAnswerListMobile(stateControl, value) {
    const stateControld = stateControl;
    stateControld.questionAndAnswerLists = value;
  },
  setQuestionAndAnswerList(stateControl, value) {
    const stateControld = stateControl;
    stateControld.questionAndAnswerLists = value;
  },
  setUpdateQuestionStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.updateQuestionStatus = payload;
  },
  setQuestionFreeTextListArray(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.questionFreeTextListArray = payload;
  },
  setQuestionNumber(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.questionNumber = 0;
    stateControld.questionNumber = parseInt(payload, 10);
  },
  showNoQuestionError(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.showNoQuestionErrorMsg = payload;
  },
};
const actions = {
  getQuestionAndNumbersMobile({ commit, dispatch }, payload) {
    questionMobileService.getQuestionList(commit, dispatch, payload);
  },
  getQuestionAndNumbers(context, payload) {
    context.commit('showNoQuestionError', null);
    questionService.getQuestionList(payload.stopId).then((result) => {
      if (result && result.stop_questions.length > 0) {
        context.commit('showNoQuestionError', false);
        convertSurveyJsJsonFormatAndCommit(context, result.stop_questions, payload.stopCompleted);
      } else {
        context.commit('showNoQuestionError', true);
      }
    }).catch((err) => {
      sendErrorMessage(context.commit, err, 'error');
    });
  },
  updateQuestionnaireAction({ commit }, payload) {
    questionService.updateQuestionnaire(payload.updateParams).then((response) => {
      if (response) {
        commit('setUpdateQuestionStatus', 'Success');
      }
    }, (errorMessage) => {
      commit('setUpdateQuestionStatus', 'Failed');
      sendErrorMessage(commit, errorMessage, 'error');
    });
  },
  updateQuestionnaireActionMobile({ commit }, payload) {
    questionMobileService.updateQuestionnaire(payload.updateParams).then((response) => {
      if (response) {
        commit('setUpdateQuestionStatus', 'Success');
        commit('updateNavListValue', { index: 3, field: 'edited', value: true }, { root: true });
      }
    }, (errorMessage) => {
      commit('setUpdateQuestionStatus', 'Failed');
      sendErrorMessage(commit, errorMessage, 'error');
    });
  },
  getList(context, payload) {
    convertSurveyJsJsonFormatAndCommitMobile(context, payload);
  },
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
