//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import AlertList from '../alert-list/alertList.vue';
import ScanSuccessItem from '../scan-success-item/scan-success-item.vue';

export default {
  name: 'AlertComponent',
  components: {
    AlertList,
    ScanSuccessItem,
  },
  computed: {
    ...mapGetters({
      alerts: 'alerts/getAlertsData',
    }),
  },
  data() {
    return {
      alertSelected: false,
      master: null,
      selectedAlertData: null,
    };
  },
  mounted() {
    this.alertSelected = false;
  },
  methods: {
    scanAlert(alertId) {
      this.selectedAlertData = this.alerts.find((alert) => alert.cloud_id === alertId);
      this.assignMaster();
      this.alertSelected = true;
    },
    assignMaster() {
      this.master = {
        upc: this.selectedAlertData.upc,
        product_name: this.selectedAlertData.product_name,
        has_approval_in_stop: this.selectedAlertData.has_approval_in_stop,
        sds_url: this.selectedAlertData.sds_url,
        master_bin_rule: {
          bin_name: this.selectedAlertData.master_bin_rule.bin_name,
          bin: this.selectedAlertData.master_bin_rule.bin,
        },
      };
    },
    backToAlert() {
      this.alertSelected = false;
    },
  },
};
