//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import cancelConfirmation from '../../../helpers/component/cancel-confirmation-dialog/cancelConfirmation.vue';
import stopInfoWorkArea from './stopInformationWorkArea.vue';
import stopCheckList from './stopCheckList.vue';
import LastStopInventoryList from '../lastStopInventory.vue';
import stopVoidedWarning from '../../../helpers/component/stop-voided-warning/stopVoidedWarning.vue';

export default {
  name: 'StopDetails',
  components: {
    cancelConfirmation,
    stopInfoWorkArea,
    stopCheckList,
    stopVoidedWarning,
    LastStopInventoryList,
  },
  computed: {
    ...mapGetters({ voidedStop: 'getStopVoidStatus', getLastStopData: 'getLastStopData' }),
  },
  data() {
    return {
      hasPickUpData: true,
      showDialog: false,
      name: 'name',
      pickupId: 0,
      openLastStopInventoryList: false,
      lastStopInventoryList: [],
      lastStopCompletedDate: null,
      voidedStatusCheckInterval: null,
      inventoryUseCcid: false,
    };
  },
  created() {
    this.setFirstLogin();
    this.$store.dispatch('getLastStopData', this.stopId);
    this.checkStopVoidStatus();
  },
  beforeDestroy() {
    clearInterval(this.voidedStatusCheckInterval);
  },
  methods: {
    stopArrived() {
      this.$store.dispatch('stopArriveAction', this.stopId);
    },
    createPickupData() {
      const activeStop = this.$store.getters.getStopActive[this.stopId];
      if (activeStop) {
        this.$store.dispatch('pickupDataAction', this.stopId);
      }
      return null;
    },
    cancelArrival() {
      const hasPickUpData = this.$store.getters.getPickUpData(this.stopId);
      if (!hasPickUpData.stopId) {
        this.$store.dispatch('cancelArriveAction', { id: this.stopId, status: false });
      } else {
        this.showDialog = true;
        this.pickupId = hasPickUpData.id;
        this.name = 'Do you want cancel this arrival';
      }
    },
    closeModal() {
      this.showDialog = false;
    },
    setFirstLogin() {
      this.$store.commit('trip/setFirstLogin', false);
    },
    openInventoryList() {
      this.openLastStopInventoryList = true;
    },
    closeInventoryList() {
      this.openLastStopInventoryList = false;
    },
    checkStopVoidStatus() {
      this.voidedStatusCheckInterval = setInterval(() => {
        this.$store.dispatch('setStopVoidStatus', this.stopId);
      }, 10000);
    },
  },
  props: {
    stopId: {
      type: String,
      required: true,
    },
  },
  watch: {
    getLastStopData(newVal) {
      this.lastStopInventoryList = newVal.last_stop_inventory;
      this.lastStopCompletedDate = newVal.actual_departure_time;
      this.inventoryUseCcid = newVal.number_of_ccid > 0;
    },
    voidedStop() {
      if (this.voidedStop) {
        clearInterval(this.stopVoidCheckInterval);
      }
    },
  },
};
