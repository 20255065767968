//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import rxCancelConfirmationDialog from '../../../helpers/component/rx-cancel-confirmation-dialog/rxCancelConfirmationDialog.vue';

export default {
  name: 'RxSearchRxItems',
  components: {
    rxCancelConfirmationDialog,
  },
  computed: {
    ...mapGetters({
      getRxDetail: 'rxApproval/getRxApprovalDetail',
    }),
  },
  data() {
    return {
      rxCancelDialog: false,
      modalName: '',
      placeholder: '',
      modalValue: '',
      searchTerm: '',
    };
  },
  created() {
    this.searchTerm = this.search;
  },
  methods: {
    submitOnEnter(e) {
      // If the user is using an external USB numpad this method causes the
      // enter key to submit the search query
      if (e.keyCode === 13) {
        e.preventDefault();
        this.searchItems();
      }
    },
    searchItems() {
      if (this.searchTerm) {
        const convertedSearchTerm = this.searchTerm.replace(/-|\*/g, '');
        this.$router.push({ name: 'Rx Items Lists' });
        this.$store.dispatch('rxApproval/searchRxItem', { search: convertedSearchTerm, stopId: this.$store.state.stopDetail.stopDetail.id }).then((res) => {
          if (res === 'exactlyOneApproval') {
            this.$router.push({ name: 'Add Rx Items' });
          }
        });
      }
    },
    checkDialog(keyboardType, modalName, placeholder, modalValue) {
      this.modalName = modalName;
      this.placeholder = placeholder;
      this.modalValue = modalValue;
      if (this.searchTerm || (this.getRxDetail && this.getRxDetail.id)) {
        this.rxCancelDialog = true;
      } else {
        this.$emit('checkDialog', {
          alphaNumeric: keyboardType, search: 'search', type: 'Type here', modalValue: this.modalValue,
        });
        this.delayAndFocusOnSearchBar();
      }
    },
    delayAndFocusOnSearchBar() {
      // This returns focus back to the input field so that an external USB 10-key can be used
      // for Rx search
      setTimeout(() => {
        this.$refs.rx_searchbar.focus();
      }, 300);
    },
    closeModal(type) {
      this.rxCancelDialog = false;
      this.$store.commit('rxApprovalList/showRxCancelConfirmationDialog', false);
      if (type === 'yes') {
        this.$emit('cancelDialog', false);
        this.$emit('checkDialog', {
          alphaNumeric: 'alphaNumericFull',
          search: 'search',
          type: 'Type here',
          modalName: this.modalName,
          modalValue: this.modalValue,
        });
      }
    },
  },
  props: {
    search: {
      type: String,
      default: '',
    },
    searchClick: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    searchClick() {
      this.searchItems();
    },
    search(newVal) {
      this.searchTerm = newVal;
    },
  },
};
