//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import KeyboardDialog from '../virtual-keyboard/virtualKeyboardDialog.vue';

export default {
  name: 'ContentQuantity',
  components: {
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({
      rxApprovalData: 'rxApproval/getRxApprovalDetail',
    }),
  },
  data() {
    return {
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
    };
  },
  methods: {
    increaseContentQuantity() {
      this.$store.commit('rxContent/increaseContent', {
        index: this.contentIndex,
        field: 'contentQuantity',
      });
    },
    decreaseContentQuantity() {
      this.$store.commit('rxContent/decreaseContent', {
        index: this.contentIndex,
        field: 'contentQuantity',
      });
    },
    checkDialog(KeyboardType, modelName, labelName, index, modelValue) {
      if (this.rxApprovalData.id) {
        this.pinDialogIsOpen = true;
        this.keyBoardType = KeyboardType;
        this.modelName = modelName;
        this.labelName = labelName;
        this.keypadIndex = index;
        this.modelValue = modelValue;
      }
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      if (value.modelName === 'search') {
        this.search = value.input;
      } else {
        const model = value.modelName;
        this.$store.commit('rxContent/setContentValue', {
          index: this.contentIndex,
          field: model,
          value: +value.input,
        });
      }
    },

  },
  props: {

    quantity: {
      type: Number,
      default: 0,
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
  },
};
