//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'CloseContent',
  methods: {
    removeLastMonth() {
      this.$store.commit('rxContent/removeContent', this.contentIndex);
    },

  },
  props: {

    month: {
      type: String,
      default: '',
    },
    lastIndex: {
      type: Boolean,
      default: false,
    },
    firstIndex: {
      type: Boolean,
      default: false,
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
  },
};
