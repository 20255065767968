const stateObj = {
  rawWeight: 0,
  scaledWeight: 0,
  rawTareWeight: 0,
  scaledTareWeight: 0,
  isConnected: false,
  calConstant: 35000,
  calConstantLoaded: false,
};

const mutations = {
  setRawWeight(context, weight) {
    const c = context; // think this probably not needed, but the linter complains otherwise
    c.rawWeight = weight;
  },
  setScaledWeight(context, scaledWeight) {
    const c = context; // think this probably not needed, but the linter complains otherwise
    c.scaledWeight = scaledWeight;
  },
  setRawTareWeight(context) {
    const c = context; // think this probably not needed, but the linter complains otherwise
    c.rawTareWeight = context.rawWeight;
  },
  setTareWeight(context, weight) {
    const c = context;
    c.tareWeight = weight;
  },
  setScaledTareWeight(context, scaledTareWeight) {
    const c = context; // think this probably not needed, but the linter complains otherwise
    c.scaledTareWeight = scaledTareWeight;
  },
  setConnectionStatus(context, connected) {
    const c = context; // think this probably not needed, but the linter complains otherwise
    c.isConnected = connected;
  },
  setCalConstant(context, calConstant) {
    const c = context; // think this probably not needed, but the linter complains otherwise
    c.calConstant = calConstant;
  },
  setCalConstantLoaded(context, calConstantLoaded) {
    const c = context; // think this probably not needed, but the linter complains otherwise
    c.calConstantLoaded = calConstantLoaded;
  },
};

const getters = {
  getWeight(context) {
    return context.scaledWeight - context.scaledTareWeight;
  },
  getScaledTareWeight(context) {
    return context.scaledTareWeight;
  },
  getRawTareWeight(context) {
    return context.rawTareWeight;
  },
  getRawWeight(context) {
    return context.rawWeight;
  },
  getCalConstant(context) {
    return context.calConstant;
  },
};

const actions = {
  resetWeightAction({ commit }) {
    commit('setRawWeight', 0);
    commit('setTareWeight', 0);
    commit('setConnectionStatus', false);
  },
  setCalConstant({ commit, state }, calConstant) {
    const incomingCalConstant = parseInt(calConstant, 10);
    const calConstantHasChanged = state.calConstant !== incomingCalConstant;
    const calConstantIsNumber = !Number.isNaN(incomingCalConstant);
    if (calConstantIsNumber && calConstantHasChanged) {
      commit('setCalConstant', incomingCalConstant);
    }
  },
  setRawWeight({ commit }, rawWeight) {
    const incomingRawWeight = parseInt(rawWeight, 10);
    const rawWeightIsANumber = !Number.isNaN(incomingRawWeight);
    if (rawWeightIsANumber) {
      commit('setRawWeight', incomingRawWeight);
    }
  },
  setScaledWeight({ commit, state }) {
    const scaledWeight = state.rawWeight / state.calConstant;
    const scaledWeightIsANumber = !Number.isNaN(scaledWeight);
    if (scaledWeightIsANumber) {
      const roundedScaledWeight = Math.round(scaledWeight * 10) / 10;
      commit('setScaledWeight', roundedScaledWeight);
    }
  },
  calculateScaledTareWeight({ commit, state }, calConstant) {
    const scaledTareWeight = state.rawTareWeight / calConstant;
    const scaledTareWeightIsANumber = !Number.isNaN(scaledTareWeight);
    if (scaledTareWeightIsANumber) {
      const roundedScaledTareWeight = Math.round(scaledTareWeight * 10) / 10;
      commit('setScaledTareWeight', roundedScaledTareWeight);
    }
  },
  setTareWeight({ commit, dispatch, state }) {
    commit('setRawTareWeight');
    dispatch('calculateScaledTareWeight', state.calConstant);
  },
};


export default {
  namespaced: true,
  state: stateObj,
  getters,
  actions,
  mutations,
};
