import api from '../service/approval.service';

const state = {
  selectedInventoryApproval: {
    weight_unit: 4,
  },
  stopInventoryLists: [],
};
const getters = {
  getSelectedInventoryApproval(context) {
    return context.selectedInventoryApproval;
  },
  getStopInventoryLists(context) {
    return context.stopInventoryLists;
  },
};
const mutations = {
  setSelectedInventoryApproval(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.selectedInventoryApproval = payload;
  },
  setStopInventoryLists(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopInventoryLists = payload;
  },
  increaseInventoryBillUnitQuantity(stateControl, payload) {
    const stateControld = stateControl;
    const qty = stateControld.selectedInventoryApproval.bill_units[payload].quantity;
    stateControld.selectedInventoryApproval.bill_units[payload].quantity = qty + 1;
  },
  decreaseInventoryBillUnitQuantity(stateControl, payload) {
    const stateControld = stateControl;
    const qty = stateControld.selectedInventoryApproval.bill_units[payload].quantity;
    stateControld.selectedInventoryApproval.bill_units[payload].quantity = qty - 1;
  },
  setInventoryBillUnitQuantity(stateControl, payload) {
    const stateControld = stateControl;
    const qty = parseInt(payload.value, 10);
    stateControld.selectedInventoryApproval.bill_units[payload.index].quantity = qty;
  },
};
const actions = {
  setSelectedInventoryApproval(context, payload) {
    context.commit('setSelectedInventoryApproval', payload);
  },
  getInventoryLists(context, stopId) {
    api.getInventoryLists(stopId).then((res) => {
      if (res && res.consolidation_containers) {
        context.commit('setStopInventoryLists', res.consolidation_containers);
        context.commit('trip/setAllCcidList', []);
      } else if (res && res.stop_approvals) {
        context.commit('setStopInventoryLists', res.stop_approvals);
        context.commit('trip/setAllCcidList', []);
      }
    });
  },
  editApproval(context, payload) {
    return new Promise((resolve) => {
      api.getStopApproval(payload.id, payload.waste_type).then(
        (response) => {
          const res = response;
          res.stop_approval.bill_units = JSON.parse(res.stop_approval.bill_units);
          const parsedWc = JSON.parse(res.stop_approval.approval.waste_codes);
          if (parsedWc && parsedWc.waste_codes) {
            res.stop_approval.approval.waste_codes = parsedWc.waste_codes;
          }
          context.commit('setSelectedInventoryApproval', res);
          context.dispatch('container/containerTypeListAction').then(() => {
            if (res && res.stop_approval
              && res.stop_approval.stop_containers.length > 0) {
              const containerList = [];
              const containerTypeListIds = [];
              res.stop_approval.stop_containers.forEach((obj) => {
                const param = {};
                param.quantity = parseInt(obj.quantity, 10);
                param.type = {};
                param.type.id = obj.container_id;
                param.type.container_type = '';
                containerList.push(param);
                containerTypeListIds.push(obj.container_id);
              });
              context.commit('container/setContainerLists',
                { containerList });
              context.commit('container/setContainerTypeListIds', containerTypeListIds);
              resolve();
            } else {
              resolve();
            }
            context.commit('setSelectedInventoryApproval', res.stop_approval);
          });
        },
      ).catch(() => {
        resolve();
      });
    });
  },
  reopenApproval({ dispatch }, payload) {
    const { containerId, stopId, onlyTripInventory } = payload;
    api.reopenApproval(containerId).then((res) => {
      if (!onlyTripInventory) dispatch('getInventoryLists', stopId);
      dispatch('trip/viewAllCcid', res.consolidation_container.trip_id);
    });
  },
  markasFull({ dispatch }, payload) {
    const { containerId, stopId, onlyTripInventory } = payload;
    api.markasFull(containerId).then((res) => {
      if (!onlyTripInventory) dispatch('getInventoryLists', stopId);
      dispatch('trip/viewAllCcid', res.consolidation_container.trip_id);
    });
  },
  increaseInventoryBillUnitQuantity(context, index) {
    context.commit('increaseInventoryBillUnitQuantity', index);
  },
  decreaseInventoryBillUnitQuantity(context, index) {
    context.commit('decreaseInventoryBillUnitQuantity', index);
  },
  setInventoryBillUnitQuantity(context, payload) {
    context.commit('setInventoryBillUnitQuantity', payload);
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
