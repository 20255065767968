//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Quagga from '../../assets/javascripts/quagga.min';

export default {
  name: 'BarCodeScanner',
  computed: {
    ...mapGetters({
      stopDetail: 'getStopDetail',
    }),
  },
  data() {
    return {
      scannerActive: false,
      foundCodes: {},
    };
  },
  mounted() {
    this.startScan();
    Quagga.onProcessed((result) => {
      const drawingCtx = Quagga.canvas.ctx.overlay;
      const drawingCanvas = Quagga.canvas.dom.overlay;
      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute('width'), 10),
            parseInt(drawingCanvas.getAttribute('height'), 10),
          );
          // green box
          result.boxes
            .filter((box) => box !== result.box)
            .forEach((box) => {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: 'green',
                lineWidth: 10,
              });
            });
        }

        if (result.box) {
          // blue box
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: '#00F',
            lineWidth: 10,
          });
        }

        if (result.codeResult && result.codeResult.code) {
          // red line
          Quagga.ImageDebug.drawPath(
            result.line,
            { x: 'x', y: 'y' },
            drawingCtx,
            { color: 'red', lineWidth: 15 },
          );
        }
      }
    });
    Quagga.onDetected((data) => {
      const foundResult = data;
      this.foundCodes = {
        code: foundResult.codeResult.code,
        type: foundResult.codeResult.format,
      };
      this.getResult();
      const video = document.querySelector('video');
      video.pause();
      this.stopScan();
    });
  },
  destroyed() {
    this.stopScan();
  },
  methods: {
    startScan() {
      this.scannerActive = true;
      const config = {
        inputStream: {
          name: 'live',
          type: 'LiveStream',
          target: document.querySelector('#videoWindow'),
          constraints: {
            width: 1920, // if page is breaked/closed by unexpected way, change width & height
            height: 1440,
            facingMode: 'environment', // or user
          },
        },
        decoder: {
          readers: [{ format: 'upc_reader', config: {} }],
        },
        numOfWorkers: 1,
        frequency: 10,
        locate: true,
      };
      Quagga.init(config, (err) => {
        if (err) {
          return;
        }
        Quagga.start();
      });
    },
    stopScan() {
      this.scannerActive = false;
      Quagga.stop();
    },
    getResult() {
      this.$store
        .dispatch('getUPCDetail', {
          barcode: this.foundCodes.code,
          use_generator_id: this.stopDetail.generator.use_id,
        })
        .then(() => {
          this.redirectUPC();
        });
    },
    redirectUPC() {
      this.$router.push({ path: 'mobile-upc-scan-response' });
    },
  },
};
