<template>
  <div>
    <v-overlay :value="dialog">
      <div class="d-flex flex-row">
        <div
          class="d-flex flex-column justify-space-between stop-warn-container w-642p"
        >
          <div class="d-flex flex-column stop-warn-box">
            <div class="complete-trip-title mb-6">
              <label v-if="modalType === 'CompleteStop'">
                Complete this trip?
              </label>
              <div v-else>
                <div class="d-flex flex-column">
                  <img
                    class="active-incomplete-stop-warn"
                    src="src/assets/images/alert.svg"
                    alt="alert"
                  >
                  <span> You have an active stop. </span>
                </div>
              </div>
            </div>
            <div v-if="modalType === 'CompleteStop'">
              <div class="complete-trip-info mb-6">
                You have incomplete stops on this trip. Are you sure you would
                like to mark this trip as <strong>Complete?</strong>
              </div>
              <div class="complete-trip-info-em">
                <em>
                  (Note: You can always access complete trips from the
                  <strong>Completed trips</strong> page if you need to view, or
                  change their status in the future.)
                </em>
              </div>
            </div>
            <div v-else>
              <div class="complete-trip-info mb-6">
                You will need to complete the stop listed on the right before
                you can mark this trip as <strong>Complete</strong>
              </div>
            </div>
          </div>
          <div class="ma-5">
            <button
              @click.prevent="goToStopLists()"
              class="btn-submit cancel-complete-trip"
            >
              Cancel
            </button>
            <button
              @click.prevent="completeCurrentTrip()"
              class="btn-submit complete-trip-btn"
              v-if="modalType === 'CompleteStop'"
            >
              Complete trip
            </button>
            <button
              @click.prevent="goToStopLists()"
              class="btn-submit complete-trip-btn-blue"
              v-else
            >
              View active trip
            </button>
          </div>
        </div>
        <div class="stop-warn-container w-294p mt-0">
          <div class="incomplete-stop-title mx-auto">
            <div class="d-flex flex-row pa-4">
              <div v-if="modalType === 'CompleteStop'">
                <img
                  class="incomplete-stop-warn"
                  src="src/assets/images/alert.svg"
                  alt="alert"
                >
                <span class="incomplete-stop-header ml-2">
                  Incomplete stops
                </span>
              </div>
              <div
                class="d-flex align-center"
                v-else
              >
                <img
                  class="incomplete-stop-warn-cross"
                  src="src/assets/images/error-x.svg"
                  alt="alert"
                >
                <span class="incomplete-stop-header ml-2"> Active stops </span>
              </div>
            </div>
          </div>
          <div class="incomplete-stop-container">
            <div
              v-for="(stop, indx) in stopList"
              :key="indx"
            >
              <div class="d-flex flex-row pa-4 individual-incomplete-stop">
                <div style="height: 50px">
                  <div v-if="modalType === 'CompleteStop'">
                    <img
                      class="incomplete-stop-img"
                      src="src/assets/images/alert.svg"
                      alt="alert"
                    >
                  </div>
                  <div v-else>
                    <img
                      class="incomplete-stop-img"
                      src="src/assets/images/error-x.svg"
                      alt="alert"
                    >
                  </div>
                </div>
                <div class="d-flex flex-column">
                  <div class="ml-3 incomplete-stop-name">
                    {{ stop.stop_number + '. ' + stop.name }}
                  </div>
                  <button
                    class="ml-3 view-incomplete-stop-link"
                    @click.prevent="goToSelectedStop(stop.id)"
                  >
                    View stop
                  </button>
                </div>
              </div>
              <hr v-if="indx !== stopList.length - 1">
            </div>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ActiveStopsForTrip',
  computed: {
    ...mapGetters({
      incompletedStops: 'getIncompletedStopList',
      modalType: 'getCompleteTripModalType',
      activeStops: 'getActiveStopList',
    }),
    stopList() {
      if (this.activeStops.length > 0) {
        return this.activeStops;
      }
      return this.incompletedStops;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  created() {
    this.checkStatusWarningDialog();
  },
  methods: {
    checkStatusWarningDialog() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'setShowCompleteTripModal') {
          this.dialog = mutation.payload.openModal;
        }
      });
    },
    goToStopLists() {
      this.dialog = false;
    },
    goToSelectedStop(stopId) {
      this.goToStopLists();
      this.$store.dispatch('getStopDetailAction', {
        stopID: stopId,
        type: 'popup',
      });
    },
    completeCurrentTrip() {
      const { tripId } = this.$route.params;
      this.$store
        .dispatch('completeCurrentTrip', { tripId, is_completed: true })
        .then((res) => {
          this.$router.push('/trip-list');
          this.$store.dispatch('tripStatusChangeToaster', {
            tripCode: res.trip_code,
            type: 'completed',
          });
        });
    },
  },
};
</script>
