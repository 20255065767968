<template>
  <div class="width-100p">
    <div class="add-approval-container">
      <div class="add-approval-inner-container">
        <div
          class="add-approval-column-divide overflow-hidden"
          style="position:relative"
        >
          <div class="divide1">
            <div class="header">
              <div>{{ showEditApproval ? 'Edit approval' : 'Add approval' }}</div>
            </div>
            <div class="item-details">
              <div class="mb-4">
                <label class="item-details-header">Item details</label>
              </div>
              <div class="mb-4">
                <p class="mb-1">
                  Description
                </p>
                <p
                  v-if="showEditApproval"
                  class="item-details-val"
                  :class="{'none': editApprovalDescription}"
                >
                  {{ editApproval.approval.description }}
                </p>
                <p
                  v-else
                  class="item-details-val"
                  :class="{'none': selectedApprovalDescription}"
                >
                  {{ selectedApproval.description }}
                </p>
              </div>
              <div class="mb-4">
                <p class="mb-1">
                  Approval
                </p>
                <p
                  v-if="showEditApproval"
                  class="item-details-val"
                  :class="{'none': editApprovalCode}"
                >
                  {{ editApproval.approval.approval_code }}
                </p>
                <p
                  v-else
                  class="item-details-val"
                  :class="{'none': selectedApprovalCode}"
                >
                  {{ selectedApproval.approval_code }}
                </p>
              </div>
              <div
                class="mb-4 dot-sp-checkbox-div"
                v-if="!showEditApproval"
              >
                <p class="mb-1">
                  DOT Special Permit
                </p>
                <label
                  class="checkbox-container"
                  v-if="hasDotSpecialPermit"
                >Print DOT Special Permit
                  <input
                    type="checkbox"
                    v-model="dotSpPermit"
                    true-value="T"
                    false-value="F"
                  >
                  <span class="checkmark" />
                </label>
                <label
                  v-if="!hasDotSpecialPermit && selectedApproval.approval_code === 'none'"
                  class="item-details-val"
                  :class="{'none': selectedApprovalCode}"
                >none</label>
                <label
                  v-if="!hasDotSpecialPermit && selectedApproval.approval_code != 'none'"
                  class="item-details-val"
                  :class="{'none': selectedApprovalCode}"
                >Permit N/A</label>
              </div>
              <div
                class="mb-4 dot-sp-checkbox-div"
                v-if="showEditApproval"
              >
                <p class="mb-1">
                  DOT Special Permit
                </p>
                <label
                  class="checkbox-container"
                  v-if="hasDotSpecialPermit"
                >Print DOT Special Permit
                  <input
                    type="checkbox"
                    v-model="editApproval.dot_sp_flag"
                    true-value="T"
                    false-value="F"
                  >
                  <span class="checkmark" />
                </label>
                <label
                  v-if="!hasDotSpecialPermit"
                  class="item-details-val"
                  :class="{'none': selectedApprovalCode}"
                >Permit N/A</label>
              </div>
              <div v-if="showEditApproval">
                <div class="d-flex flex-row">
                  <button
                    class="approval-details"
                    @click="drawer = true"
                  >
                    Approval details
                  </button>
                </div>
              </div>
              <div
                class="approval-btn d-flex align-center"
                v-else
              >
                <template v-if="!selectedApproval.id">
                  <div
                    @click.prevent="goToLists()"
                    class="assign-approval-btn"
                  >
                    <button class="btn-submit">
                      Assign an approval
                    </button>
                  </div>
                </template>
                <template v-if="selectedApproval.id">
                  <div class="d-flex flex-row">
                    <button
                      class="change-approval"
                      @click="goToLists()"
                    >
                      Change approval
                    </button>
                    <button
                      class="ml-4 approval-details"
                      @click="drawer = true"
                    >
                      Approval details
                    </button>
                  </div>
                </template>
              </div>
            </div>
            <div
              class="d-flex flex-column reconsolidate"
              v-if="showEditApproval"
            >
              <div v-if="useCCIDStorage">
                <div class="consolidate-in">
                  <label>Consolidated in:</label>
                </div>
                <div class="consolidate-val">
                  <span
                    v-for="(ccids, index) in editApproval.stop_approval_ccids"
                    :key="index"
                  >
                    {{ ccids }}
                    {{ rxEditApprovalSequenceCCIDs(index) }}
                  </span>
                </div>
              </div>
              <div class="d-flex align-center">
                <button
                  v-if="useCCIDStorage"
                  class="reconsolidate-btn d-flex align-center justify-center"
                  @click.prevent="handleSavingByConsolidationType('reconsolidate')"
                  :disabled="saveBtnDisable"
                >
                  Reconsolidate
                </button>
                <button
                  class="delete-approval d-flex align-center justify-center"
                  @click="deleteApproval()"
                >
                  <i class="fa fa-trash" />
                  Delete approval
                </button>
              </div>
            </div>
          </div>
          <div class="divide-2">
            <containerList
              :class="{ 'avoid-clicks':!selectedApproval.id && !showEditApproval}"
              :has-customer-specific-billing="hasCustomerSpecificBilling"
              :total-quantity="totalQuantity"
            />
            <billUnitList
              v-if="hasCustomerSpecificBilling"
              :edit="Boolean(editApproval && editApproval.approval)"
              :total-quantity.sync="totalQuantity"
            />
            <div class="consolidate-container mt-4">
              <div class="total-weight mt-4 mb-1">
                <label>Total weight</label>
              </div>
              <div
                class="mt-4 d-flex height-49p"
                v-if="type === 'floor' || type === 'bench'"
              >
                <div
                  v-if="showEditApproval"
                  class="lb"
                >
                  {{ editApproval.weight }} {{ $options.filters
                    .weightUnitFormat(manifestUnitsList, editApproval.weight_unit) }}
                </div>
                <div
                  v-else
                  class="lb"
                >
                  {{ this.$store.getters["weight/getWeight"].toFixed(1) }} {{ $options.filters
                    .weightUnitFormat(manifestUnitsList, weightUnit) }}
                </div>
                <div
                  class="tare-scale"
                  @click.prevent="tareScale()"
                >
                  <label>Tare scale</label>
                </div>
              </div>
              <div
                class="d-flex weight-input"
                v-if="type === 'manual'"
              >
                <div class="lb add-approval-input-total">
                  <input
                    v-if="showEditApproval"
                    class="input"
                    type="text"
                    v-model="editApproval.weight"
                    :disabled="!editApproval.id && !selectedApproval.id"
                    @click="checkDialog('numeric','editApproval.weight','Total weight',
                                        editApproval.weight.toString())"
                    @selectstart.prevent
                    @paste.prevent
                    @copy.prevent
                    @cut.prevent
                    @drag.prevent
                    @drop.prevent
                    v-unSelect
                  >
                  <input
                    class="input"
                    type="text"
                    :class="{ 'avoid-clicks':!selectedApproval.id && !showEditApproval}"
                    v-model="weight"
                    :disabled="!editApproval.id && !selectedApproval.id"
                    @click="checkDialog('numeric','weight','Total weight', weight.toString())"
                    v-else
                    @selectstart.prevent
                    @paste.prevent
                    @copy.prevent
                    @cut.prevent
                    @drag.prevent
                    @drop.prevent
                    v-unSelect
                  >
                </div>
                <div class="weight-dropdown">
                  <v-select
                    ref="containerType"
                    class="input-type-select dropdown-color-grey"
                    v-model="editApproval.weight_unit"
                    v-if="showEditApproval"
                    :items="manifestUnitsList"
                    item-value="id"
                    item-text="unit"
                    outlined
                    append-icon="fa-angle-down"
                    :menu-props="{ offsetY: true }"
                    background-color="white"
                    placeholder
                    @click:append="closeSelectDropdown('containerType')"
                  />
                  <v-select
                    ref="containerType"
                    class="input-type-select dropdown-color-grey"
                    :class="{ 'avoid-clicks':!selectedApproval.id && !showEditApproval}"
                    v-model="weightUnit"
                    v-else
                    :items="manifestUnitsList"
                    item-value="id"
                    item-text="unit"
                    outlined
                    append-icon="fa-angle-down"
                    :menu-props="{ offsetY: true }"
                    background-color="white"
                    placeholder
                    @click:append="closeSelectDropdown('containerType')"
                  />
                </div>
              </div>
              <div
                class="mt-6 d-flex scale"
                :class="{ 'avoid-clicks':!selectedApproval.id && !showEditApproval}"
              >
                <div
                  @click.prevent="showScaleType('manual')"
                  :class="type === 'manual' ? 'scaleActive' : 'scaleInactive'"
                >
                  Manual
                </div>
                <div
                  @click.prevent="showScaleType('bench')"
                  :class="type === 'bench' ? 'scaleActive' : 'scaleInactive'"
                >
                  Bench scale
                </div>
              </div>
              <div
                class="mt-8 approval-btn-groups"
                v-if="editApproval && !editApproval.approval"
              >
                <div>
                  <div
                    @click.prevent="cancelApproval()"
                    class="cancel-approval cursor-pointer"
                  >
                    <div class="approval-back-img" />Cancel approval
                  </div>
                </div>
                <div
                  class="consolidate"
                  :class="{'consolidate-btn-disabled' : consolidateBtnDisabled}"
                >
                  <button
                    class="approval-consolidate-btn"
                    @click="consolidate()"
                    :disabled="consolidateBtnDisabled"
                  >
                    <span v-if="!useCCIDStorage">Add to truck inventory</span>
                    <span v-else>Consolidate</span>
                  </button>
                </div>
              </div>
              <div
                class="d-flex align-center justify-end mt-275p"
                v-else
              >
                <button
                  class="save-exist d-flex align-center justify-center custom-alignment-btm"
                  @click.prevent="handleSavingByConsolidationType('save')"
                  :disabled="saveBtnDisable"
                >
                  Save and exit
                </button>
              </div>
            </div>
          </div>
          <ApprovalDetailDrawer
            :approval-drawer="drawer"
            :approval="approvalDrawerDetails"
            @closeApprovalDrawer="closeApprovalDrawer"
          />
        </div>
      </div>
    </div>
    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="modelValue"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @enterClicked="enterClicked"
    />
    <delete-approval-confirmation-dialog
      :open-dialog="dialog"
      @close="closeDialog()"
    />
    <pListedWeightWarning
      :consolidate-type="pListConsolidateType"
      @proceedToConsolidate="retailApprovalConsolidate"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import containerList from '../../../../helpers/component/container/containerList.vue';
import KeyboardDialog from '../../../../helpers/component/virtual-keyboard/virtualKeyboardDialog.vue';
import DeleteApprovalConfirmationDialog from '../../../../helpers/component/delete-approval/deleteApproval.vue';
import billUnitList from '../../../../helpers/component/billUnits/billUnitList.vue';
import VSelectArrow from '../../../../helpers/utils/v-select-arrow';
import pListedWeightWarning from '../../../../helpers/component/pListedWeightWarning/index.vue';
import ApprovalDetailDrawer from './ApprovalDetailDrawer.vue';

export default {
  name: 'AddApproval',
  components: {
    containerList,
    billUnitList,
    KeyboardDialog,
    DeleteApprovalConfirmationDialog,
    pListedWeightWarning,
    ApprovalDetailDrawer,
  },
  computed: {
    ...mapGetters({
      selectedApproval: 'getSelectedApproval',
      weightData: 'getWeightData',
      containerList: 'container/getContainerList',
      getStopDetail: 'getStopDetail',
      editApproval: 'getSelectedInventoryApproval',
      manifestUnitsList: 'getManiFestUnits',
      consolidateApproval: 'getConsolidateApproval',
      weightUnitData: 'getWeightUnit',
      consolidationMessage: 'consolidate/getConsolidationSuccessMessage',
      approvalDrawerDetails: 'getApprovalDrawerDetails',
      useCCIDStorage: 'getUseCCIDStorage',
    }),
    hideEditApproval() {
      return this.editApproval && !this.editApproval.approval;
    },
    showEditApproval() {
      return this.editApproval && this.editApproval.approval;
    },
    editApprovalDescription() {
      return !(
        this.editApproval && this.editApproval.approval.description !== 'none'
      );
    },
    selectedApprovalDescription() {
      return !(
        this.selectedApproval && this.selectedApproval.description !== 'none'
      );
    },
    editApprovalCode() {
      return !(
        this.editApproval && this.editApproval.approval.approval_code !== 'none'
      );
    },
    selectedApprovalCode() {
      return !(
        this.selectedApproval && this.selectedApproval.approval_code !== 'none'
      );
    },
    billUnitsComplete() {
      const billUnits = this.$store.state.approvalList.approvalDetail.bill_units.filter(
        (bu) => bu.unit !== 'LBS',
      );
      return billUnits.some((bu) => bu.quantity > 0);
    },
    consolidateBtnDisabled() {
      return (
        !this.containerList[0].type.id
        || (this.type === 'manual'
          && (!this.weight || !this.weightUnit || this.weight <= 0))
        || (this.hasCustomerSpecificBilling && !this.billUnitsComplete)
      );
    },
    hasCustomerSpecificBilling() {
      if (!this.selectedApproval && !this.editApproval) {
        return false;
      }
      if (this.showEditApproval) {
        return (
          this.filterOutLbsBillUnit(this.editApproval.bill_units).length > 0
        );
      }
      return (
        this.filterOutLbsBillUnit(this.selectedApproval.bill_units).length > 0
      );
    },
    hasDotSpecialPermit() {
      let res = '';
      if (this.showEditApproval) {
        res = this.editApproval.approval.dot_sp_number;
      } else {
        res = this.selectedApproval.id && this.selectedApproval.dot_sp_number;
      }
      return res;
    },
  },
  data: () => ({
    type: 'manual',
    weight: '',
    weightUnit: '',
    manualWeightUnit: '4',
    dotSpPermit: '',
    keyBoardType: '',
    modelName: '',
    labelName: '',
    modelValue: '',
    pinDialogIsOpen: false,
    saveBtnDisable: true,
    dialog: false,
    pListConsolidateType: '',
    totalQuantity: 0,
    drawer: false,
  }),
  extends: VSelectArrow,
  created() {
    this.getManiFestUnits();
    this.setDefaultWeightUnit();
    this.weight = this.weightData;
    this.weightUnit = this.weightUnitData;
    this.saveBtnDisabled(this.editApproval.weight);
    this.setDotSpPermit();
    if (this.editApproval.approval) {
      const approvalDetail = this.editApproval.approval;
      approvalDetail.bill_units = this.convertStringToJson(approvalDetail.bill_units);
      this.$store.commit('setApprovalDrawerDetails', approvalDetail);
    }
    if (!this.useCCIDStorage) {
      this.$store.commit('setNonCCIDApprovalAdded', false);
    }
  },
  destroyed() {
    this.$store.commit('setApprovalDrawerDetails', {});
  },
  methods: {
    convertStringToJson(str) {
      try {
        return JSON.parse(str);
      } catch (e) {
        return str;
      }
    },
    setDotSpPermit() {
      if (this.selectedApproval.id && this.selectedApproval.dot_sp_number) {
        this.dotSpPermit = 'T';
      } else {
        this.dotSpPermit = 'F';
      }
    },
    cancelApproval() {
      this.clearApprovalAndRedirect(false);
    },
    goToLists() {
      this.$router.push({ name: 'Approval List' });
    },
    filterOutLbsBillUnit(billUnits) {
      return billUnits.filter(
        (bu) => bu.unit !== 'LBS' && bu.billing_flag === 'T',
      );
    },
    showScaleType(val) {
      this.type = val;
      if (val === 'floor' || val === 'bench') {
        this.manualWeightUnit = this.weightUnit;
        if (this.showEditApproval) {
          this.manualWeightUnit = this.editApproval.weight_unit;
        }
        this.setDefaultWeightUnit();
      } else {
        this.weightUnit = this.manualWeightUnit;
        if (this.showEditApproval) {
          this.editApproval.weight_unit = this.manualWeightUnit;
        }
      }
    },
    tareScale() {
      this.$store.dispatch('weight/setTareWeight');
    },
    consolidate(validate = false) {
      const params = {};
      if (this.type === 'manual') {
        params.weight = this.weight;
        params.weight_unit = this.weightUnit;
      } else {
        params.weight = this.$store.getters['weight/getWeight'];
        params.weight_unit = 4;
      }
      params.stop_id = this.getStopDetail.id;
      params.approval_id = this.selectedApproval.id;
      params.waste_type = 'retail';
      params.dot_sp_flag = this.dotSpPermit;
      params.manifest_id = this.selectedApproval.manifest_id;
      switch (this.type) {
        case 'floor':
          params.scale_type = 2;
          break;
        case 'bench':
          params.scale_type = 3;
          break;
        case 'manual':
          params.scale_type = 1;
          break;
        default:
      }
      params.containers = this.containerList;
      params.bill_units = this.$store.state.approvalList.approvalDetail.bill_units;
      params.allow_consolidate = validate;
      this.$store.commit('consolidate/resetEditableCCID');
      this.$store.dispatch('consolidateApproval', params).then((stopApprovalData) => {
        if (!this.useCCIDStorage) {
          this.$router.push({ name: 'stopDetailWorkArea' });
          this.resetApprovalBuilder();
          this.$store.commit('setApprovalAddedSuccessfully', true);
          this.$store.dispatch('updateAccumulationData', { id: stopApprovalData.stop_approval.id, waste_type: stopApprovalData.stop_approval.waste_type, approval_id: stopApprovalData.stop_approval.approval.id });
        }
      });
    },
    handleSavingByConsolidationType(type, validate = false) {
      this.pListConsolidateType = type;
      const params = this.formatParamsObject(type);
      params.allow_consolidate = validate;
      this.$store.dispatch('consolidateApproval', params).then(() => {
        if (type === 'save') {
          this.$store.commit('setApprovalDetail', {});
          this.$router.push({ name: 'stopDetailWorkArea' });
          this.$store.commit('consolidate/resetEditableCCID');
          this.resetApprovalBuilder();
        }
      });
    },
    getManiFestUnits() {
      this.$store.dispatch('getManiFestUnits');
    },
    clearApprovalAndRedirect(pageName) {
      if (pageName) {
        this.$router.push({ name: 'Consolidate' });
      } else {
        this.$router.push({ name: 'stopDetailWorkArea' });
      }
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      const weight = value.input;
      if (value.modelName === 'editApproval.weight') {
        this.editApproval.weight = weight;
        this.saveBtnDisabled(weight);
      } else {
        this.weight = weight;
      }
    },
    setDefaultWeightUnit() {
      if (this.editApproval && this.editApproval.id) {
        this.editApproval.weight_unit = parseInt(
          this.editApproval.weight_unit,
          10,
        );
        this.weightUnit = this.editApproval.weight_unit;
      } else {
        this.weightUnit = 4;
      }
    },
    rxEditApprovalSequenceCCIDs(index) {
      return index === this.editApproval.stop_approval_ccids.length - 1
        ? ''
        : ', ';
    },
    formatParamsObject(type) {
      const params = {};
      if (this.type === 'manual') {
        params.weight = this.editApproval.weight;
        params.weight_unit = this.editApproval.weight_unit;
      } else {
        params.weight = this.weightData;
        params.weight_unit = 4;
      }
      params.dot_sp_flag = this.editApproval.dot_sp_flag;
      params.stop_id = this.editApproval.stop_id;
      params.approval_id = this.editApproval.approval_id;
      params.bill_units = this.$store.state.inventoryApproval.selectedInventoryApproval.bill_units;
      switch (this.type) {
        case 'floor':
          params.scale_type = 2;
          break;
        case 'bench':
          params.scale_type = 3;
          break;
        case 'manual':
          params.scale_type = 1;
          break;
        default:
      }
      params.containers = this.containerList;
      params.waste_type = 'retail';
      params.id = this.editApproval.id;
      params.approval = this.editApproval.approval;
      params.approval.consolidateApprovalId = this.editApproval.id;
      params.type = type;
      params.stopId = this.$route.params.stopId;
      return params;
    },
    saveBtnDisabled(weight) {
      this.saveBtnDisable = false;
      const editApprovalWeight = +weight;
      if (editApprovalWeight <= 0) {
        this.saveBtnDisable = true;
      }
    },
    resetApprovalBuilder() {
      this.$store.commit('resetApprovalDetail');
      this.$store.commit('container/resetContainerType');
      this.$store.commit('resetScaleWeight');
      this.$store.commit('resetScaleWeightUnit');
      this.$store.commit('setSelectedInventoryApproval', {});
    },
    deleteApproval() {
      this.$store.commit('consolidate/setApprovalDelete', {
        description: this.editApproval.approval.description,
        type: 'retail',
        stopApprovalId: this.editApproval.id,
        ccids: this.editApproval.stop_approval_ccids,
        stopRxItemId: '',
        deleteCCIDS: this.editApproval.last_approval_ccid_containers,
        stopId: this.editApproval.stop_id,
        approvalId: this.editApproval.approval_id,
      });
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
    retailApprovalConsolidate(consolidateType) {
      if (this.editApproval && !this.editApproval.approval) {
        this.consolidate(true);
      } else if (consolidateType) {
        this.handleSavingByConsolidationType(consolidateType, true);
      }
    },
    closeApprovalDrawer() {
      this.drawer = false;
    },
  },
  watch: {
    editApproval(newVal) {
      if (newVal) {
        switch (newVal.scale_type) {
          case '2':
            this.type = 'floor';
            break;
          case '3':
            this.type = 'bench';
            break;
          case '1':
            this.type = 'manual';
            break;
          default:
        }
      }
    },
    clearApprovalAndRedirect(pageName) {
      if (pageName) {
        this.$router.push({ name: 'Consolidate' });
      } else {
        this.$router.push({ name: 'stopDetailWorkArea' });
      }
    },
    consolidateApproval(newVal) {
      if (newVal.id) {
        this.clearApprovalAndRedirect(true);
      }
    },
    weight(newVal) {
      this.$store.commit('setScaleWeight', +newVal);
    },
    weightUnit(newVal) {
      this.$store.commit('setScaleWeightUnit', +newVal);
    },
    consolidationMessage() {
      if (this.dialog) {
        this.dialog = false;
        this.$store.commit('setSelectedInventoryApproval', {});
        this.$router.push({ name: 'stopDetailWorkArea' });
      }
    },
  },
};
</script>
