//
//
//
//
//
//
//
//
//



export default {
  name: 'PinToolTip',

  props: {
    errorMessage: {
      type: String,
      default: '',
    },
  },


};
