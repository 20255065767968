//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'ViewRxItemDetail',
  computed: {
    ...mapGetters({
      rxItemObj: 'rxApproval/getSelectedRxItem',
    }),
  },
  data() {
    return {
      rxItem: {},
    };
  },
  created() {
    this.rxItem = this.rxItemObj;
  },
  methods: {
    goToBack() {
      const props = {};
      props.showApprovalDetails = false;
      props.showSearchIcon = false;
      props.noRecordsFound = false;
      props.showSearchResult = true;
      if (this.rxItem && this.rxItem.viaEmptyBottleButton) {
        props.showApprovalDetails = true;
        props.showSearchResult = false;
      }
      this.$store.commit('rxApproval/setRxItemMainProp', props);
      this.$router.push({ name: 'Mobile Add RX Items' });
    },
    confirmRxItem() {
      this.rxItem = { ...this.rxItem, note: '' };
      this.$store.commit('rxContent/resetContent');
      this.$store.commit('rxApproval/setRxItemObject', this.rxItem);
      this.$router.push({ path: 'add-rx_approval-card' });
      this.$store.commit('rxApproval/setRxItemListMobile', [], { root: true });
    },
  },
};
