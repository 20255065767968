//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import expectedPickupInventoryCard from '../expected-pickup-inventory-card/expectedPickupInventoryCard.vue';
import connectedStopConstant from '../../../constants/connected-stop';
import customerTypesConstant from '../../../constants/customer-type';
import accumulationExpectedContainer from '../accumulation-expected-container/accumulationExpectedContainer.vue';
import apiResponse from '../../../constants/apiResponseStatus';

export default {
  name: 'ExpectedPickupInventory',
  components: {
    expectedPickupInventoryCard,
    accumulationExpectedContainer,
  },
  computed: {
    ...mapGetters({
      mostOccurringPickupList: 'getMostOccurringProfiles',
      accumulationContainerList: 'getAccumulationContainerList',
      stopDetail: 'getStopDetail',
      apiStatus: 'getAccumulationContainerApiCallStatus',
    }),
    customerName() {
      return this.stopDetail.customer.name;
    },
    stopTypeStingValue() {
      return this.stopDetail.stop_type.join(', ');
    },
    isPriorPickupData() {
      return this.stopDetail.connected_status === connectedStopConstant.status.PRIOR_DATA_PRESENT;
    },
    isAccumulationPickupData() {
      return this.stopDetail.connected_status === connectedStopConstant
        .status.ACCUMULATION_DATA_PRESENT;
    },
    showNotes() {
      return this.stopDetail.customer.name === customerTypesConstant.customerTypes.is_costco;
    },
    isSuccessfulResponse() {
      return this.apiStatus === apiResponse.status.SUCCESS;
    },
  },
  data() {
    return {
      activeContainerId: null,
      expandInfo: true,
      content1: '',
      content2: '',
      noteContent: '',
      apiResponseStatus: apiResponse.status,
    };
  },
  mounted() {
    this.checkConnectedStatus();
    this.contentReset(true);
  },
  methods: {
    checkConnectedStatus() {
      if (this.isPriorPickupData) {
        this.$store.dispatch('getMostOccurringProfiles', this.$store.state.stopDetail.stopDetail.id);
      } else if (this.isAccumulationPickupData) {
        this.$store.dispatch('getAccumulationContainerList', this.stopDetail.id);
      }
    },
    openContainer(id) {
      this.activeContainerId = id;
      // this.reduceContainer();
    },
    contentReset(resetFlag) {
      if (resetFlag) {
        this.content1 = 'This generator has logged all of their items in every container with the RetailSiS. Select a';
        this.content2 = 'container below to view and add the possible approvals based on the items you’re picking up.';
        this.noteContent = 'Pharmacy/Rx items are not included in this expected weight or profiles listed below.';
      } else {
        this.content1 = 'Information';
        this.content2 = '';
        this.noteContent = '';
      }
    },
    reduceContainer() {
      this.contentReset(false);
      this.expandInfo = false;
      const element = document.getElementById('resizableContainer');
      element.classList.add('resizable-container-active');
    },
    expandContainer() {
      this.contentReset(true);
      this.expandInfo = true;
      const element = document.getElementById('resizableContainer');
      element.classList.remove('resizable-container-active');
    },
  },
};
