<template>
  <v-col
    class="mobile-trip-card mt-4 pa-1"
    cols="12"
  >
    <div class="mobile-trip-title">
      Trip name:
    </div>
    <p class="mobile-trip-content">
      {{ tripDetail.trip_name }}
    </p>
    <div class="mobile-trip-title">
      Trip id:
    </div>
    <p class="mobile-trip-content">
      {{ tripDetail.trip_code }}
    </p>
    <button
      block
      dark
      class="mobile-view-trip-btn"
      @click.prevent="gotoStopList()"
    >
      View trip
    </button>
  </v-col>
</template>
<script>
export default {
  name: 'MobileTripCard',
  methods: {
    gotoStopList() {
      this.$router.push(`/mobile/stop-list/${this.tripDetail.doc_id}/${this.tripDetail.trip_code}`);
    },
  },
  props: {
    tripDetail: {
      type: Object,
      default() {
        return {
          trip_name: '',
          use_trip_id: '',
        };
      },
    },
  },
};
</script>
