
<template>
  <div class="font-face-source-opnsans">
    <v-row
      class="width-pc-100 width-100p"
      no-gutters
    >
      <v-col class="col-md-3 col-xl-3 px-6 card-shadow stop-map-vh card-detail-bg">
        <trip-map />
      </v-col>
      <v-col
        class="col-md-8 col-xl-9 pl-4 stop-list-top padding-top-20"
      >
        <button
          class="back-btn-view-ccid"
          @click="gotoStopPage()"
        >
          <span class="back-btn-view-ccid-icon" />
          <span
            class="margin-left-5"
          >
            Back
          </span>
        </button>
        <p class="trip-ccids-title">
          Trip inventory
        </p>
        <viewAllCCIDCard
          class="mb-4"
          v-for="(ccidList, index) in getAllCcidList"
          :key="index"
          :ccid-list="ccidList"
          :index="index"
          :only-trip-inventory="true"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import tripMap from './tripStopMap.vue';
import viewAllCCIDCard from './stop-details/viewAllCCIDCard.vue';

export default {
  name: 'ViewAllCCID',
  components: {
    tripMap,
    viewAllCCIDCard,
  },
  computed: {
    ...mapGetters(['getStopLists', 'getStopListsArray']),
    ...mapGetters({
      getAllCcidList: 'trip/getAllCcidList',
    }),
  },
  data() {
    return {
      stopLists: [],
    };
  },
  created() {
    this.getStopDetails();
    this.getAllCCID();
  },
  methods: {
    getStopDetails() {
      this.$store.dispatch('getStopDetailsAction', this.tripId).then((res) => {
        if (res && res.stops && res.stops.length > 0) {
          this.stopLists = res.stops;
        }
      });
    },
    getAllCCID() {
      this.$store.dispatch('trip/viewAllCcid', this.tripId);
    },
    gotoStopPage() {
      this.$router.go(-1);
    },
  },
  props: {
    tripId: {
      type: String,
      required: true,
    },
  },
};
</script>
