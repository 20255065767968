import Vue from 'vue';

Vue.filter('containerType', (list) => {
  if (list.containerQuantity) {
    return list.containerQuantity;
  } if (!list.previousQuantity && list.stop_approval && list.stop_approval.stop_containers.length) {
    const listd = list;
    const { quantity } = list.stop_approval.stop_containers[0];
    const containerId = list.stop_approval.stop_containers[0].container_id;
    listd.previousQuantity = quantity;
    listd.containerQuantity = parseInt(quantity, 10);
    listd.containerId = parseInt(containerId, 10);
    return quantity;
  }
  return 0;
});
