const state = {
  alerts: [],
};

const getters = {
  getAlertsData(stateControl) {
    return stateControl.alerts;
  },
  getAlertsCount(stateControl) {
    return stateControl.alerts.length;
  },
};

const mutations = {
  setAlertsData(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.alerts = payload;
  },
};

const actions = {
  setAlertsData({ commit }, alerts) {
    commit('setAlertsData', alerts);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
