<template>
  <v-btn
    @click="pinButtonClick()"
    class="pin-button white font-weight-bold fs-24"
  >
    {{ inputDigit }}
  </v-btn>
</template>
<script>
export default {
  name: 'PinButton',
  methods: {
    pinButtonClick() {
      this.$emit('inputButtonChange', this.inputDigit);
    },
  },
  props: {
    inputDigit: {
      type: String,
      default: '',
    },

  },
};
</script>
