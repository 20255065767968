//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import ScanViewSDS from '../scan-viewSDS/scan-viewSDS.vue';
import alertsService from '../../../service/alerts.service';

export default {
  name: 'ScanSuccessItem',
  components: { ScanViewSDS },
  computed: {
    ...mapGetters([
      'getStopDetail',
    ]),
    masterHasApprovals() {
      if (this.scanFromAlert()) {
        return this.alert.state === 'verified';
      }
      const haulerBins = this.master.master_bin_rule.hauler_bins;
      return haulerBins && haulerBins.length > 0;
    },
  },
  data() {
    return {
      isDispositionFlagClicked: false,
      viewSDS: false,
    };
  },
  methods: {
    dispositionFlagClicked() {
      this.isDispositionFlagClicked = true;
    },
    cancelFlagDisposition() {
      this.isDispositionFlagClicked = false;
    },
    showViewSDS() {
      this.viewSDS = true;
    },
    goBack() {
      this.viewSDS = false;
    },
    scanFromAlert() {
      return this.alert && this.alert.cloud_id;
    },
    scanSuccess() {
      if (this.scanFromAlert()) {
        this.updateAlert();
      }
      this.backToPreviousPage();
    },
    updateAlert() {
      alertsService.updateAlert(this.alert.cloud_id, this.getStopDetail.id);
    },
    closeScanDrawer() {
      this.$emit('closeDrawer');
    },
    navigateToApprovalList() {
      // Bin names will always be in <Approval description> - <Approval Code format>
      const ind = this.master.master_bin_rule.bin_name.lastIndexOf('- ');
      const approvalCode = this.master.master_bin_rule.bin_name.substr(ind + 2);
      this.$router.push(`/stops/detail/${this.getStopDetail.id}/approval/list/${approvalCode}`);
    },
    backToPreviousPage() {
      if (this.scanFromAlert()) {
        this.$emit('backToAlert');
      } else {
        this.$emit('backToScanPage');
      }
    },
  },
  props: {
    master: {
      type: Object,
      default() {
        return {};
      },
    },
    alert: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
