<template>
  <v-col
    cols="12"
    lg="7"
  >
    <label class="add-rx-items-details-sub-title mb-1 d-block">
      Contents
    </label>
    <v-select
      ref="contentType"
      :items="contentDetails"
      item-value="id"
      item-text="content"
      v-model="selectedID"
      @change="contentSelectChange()"
      return-object
      outlined
      append-icon="fa-angle-down"
      :menu-props="{ offsetY: true }"
      background-color="white"
      placeholder="Please select"
      :disabled="!rxApprovalData.id"
      @click:append="closeSelectDropdown('contentType')"
      class="add-rx-items-content-details-dropdown resize-v-select"
    />
  </v-col>
</template>
<script>
import { mapGetters } from 'vuex';
import VSelectArrow from '../../utils/v-select-arrow';

export default {
  name: 'ContentSelect',
  computed: {
    ...mapGetters({
      contentDetails: 'rxApproval/getContentDetails',
      rxApprovalData: 'rxApproval/getRxApprovalDetail',
    }),
  },
  data: () => ({
    selectedID: '',
  }),
  extends: VSelectArrow,
  created() {
    this.selectedID = this.contentId ? +this.contentId : '';
  },
  methods: {
    contentSelectChange() {
      this.$store.commit('rxContent/setContentValue', {
        index: this.contentIndex,
        field: 'contentID',
        value: String(+this.selectedID.id),
      });
    },
  },
  props: {

    contentId: {
      type: String,
      default: '',
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    contentDetails(newVal) {
      if (newVal.length === 0) {
        this.selectedID = '';
      }
    },
  },
};
</script>
