<template>
  <div
    class="stop-inventory-card d-flex"
    :class="{'full-inventory': isFullInventory}"
  >
    <div
      class="card-divide1"
      :class="{'tsdf-column': !useCCIDStorage}"
    >
      <div v-if="checkInventoryCcidStorage()">
        <div class="header">
          <label>CCID
            <span
              class="full-text"
              v-if="!lastInventoryList"
            >
              {{ inventory.consolidation_container.is_full ? '(Full)' : '' }}
            </span>
          </label>
        </div>
        <div class="value">
          <label>{{ inventory.consolidation_container.ccid }}</label>
        </div>
      </div>
      <div v-else>
        <div class="header">
          <label>TSDF</label>
        </div>
        <div class="value tsdf-code">
          <label>{{ inventory.tsdf.code }}</label>
        </div>
      </div>
    </div>
    <div class="card-divide2 d-flex">
      <div
        class="card-sub-divide1"
        :class="{'equal-width': lastInventoryList}"
      >
        <div class="d-flex flex-column description">
          <div class="header">
            <label>Description</label>
          </div>
          <div class="value">
            <label>{{ inventory.approval.description }}</label>
          </div>
        </div>
      </div>
      <div
        class="card-sub-divide2"
        :class="{'equal-width': lastInventoryList}"
      >
        <div class="d-flex flex-column approval">
          <div class="value-box">
            <div class="header">
              <label>Approval</label>
            </div>
            <div class="value truncate-text">
              <label>{{ inventory.approval.approval_code }}</label>
            </div>
          </div>
          <div class="value-box border-0">
            <div class="header">
              <label>Weight</label>
            </div>
            <div
              class="value"
              v-if="!lastInventoryList"
            >
              <label>{{ inventory.weight }}
                {{ inventory.manifest_unit.unit }}</label>
            </div>
            <div
              class="value"
              v-else
            >
              <label>{{ inventory.manifest_unit.weight }}
                {{ inventory.manifest_unit.unit }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-divide3 d-flex">
      <div class="card-sub-divide2">
        <div class="d-flex flex-column approval">
          <div class="value-box">
            <div class="header">
              <label>Container</label>
            </div>
            <div class="value">
              <label v-if="checkInventoryCcidStorage()">
                {{ inventory.consolidation_container.container_detail.container_type }}
              </label>
              <label v-else>{{ inventory.container_type }}</label>
            </div>
          </div>
          <div
            class="value-box"
            v-if="checkInventoryCcidStorage()"
          >
            <div class="header">
              <label>Size</label>
            </div>
            <div class="value">
              <label>{{ inventory.consolidation_container.container_detail.container_size }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="card-divide4 d-flex"
      v-if="!stopCompleted && !lastInventoryList"
    >
      <div v-if="useCCIDStorage">
        <div
          class="d-flex flex-column mleft-4 w-100 align-items-center justify-content-center"
          v-if="!inventory.consolidation_container.is_full"
        >
          <div class="edit-approval-card btn-box-table">
            <button
              class="stop-inventory-card-btn"
              @click.prevent="markasFull()"
            >
              Mark as full
            </button>
          </div>
          <div class="edit-btn-div">
            <button
              class="edit-approval-inventory-btn"
              @click.prevent="editApproval()"
            >
              <img
                src="src/assets/images/pencil.svg"
                alt="edit-pencil"
                class="edit-pencil-img"
              >
              Edit approval
            </button>
          </div>
        </div>
        <div v-else>
          <div class="edit-approval-card btn-box-table border-0">
            <div class="stop-inventory-reopen">
              <button
                class="stop-inventory-card-btn"
                @click.prevent="reopen()"
              >
                Reopen
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="d-flex flex-column mleft-4 w-100 align-items-center justify-content-center"
      >
        <div class="edit-btn-div">
          <button
            class="edit-approval-inventory-btn"
            @click.prevent="editApproval()"
          >
            <img
              src="src/assets/images/pencil.svg"
              alt="edit-pencil"
              class="edit-pencil-img"
            >
            Edit approval
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'StopInventoryCard',
  computed: {
    ...mapGetters({ useCCIDStorage: 'getUseCCIDStorage' }),
    isFullInventory() {
      const consolidationContainer = this.inventory.consolidation_container;
      const isFull = consolidationContainer && consolidationContainer.is_full;
      return !this.lastInventoryList && this.useCCIDStorage && isFull;
    },
  },
  methods: {
    editApproval() {
      this.$store.commit('setApprovalStatusType', true);
      const stopApproval = {};
      let id = null;
      let wasteType = null;
      if (this.inventory) {
        if (this.useCCIDStorage) {
          id = this.inventory.stop_approval.id;
          wasteType = this.inventory.stop_approval.waste_type;
        } else {
          id = this.inventory.id;
          wasteType = this.inventory.waste_type;
        }
      }
      Object.assign(stopApproval, { id, waste_type: wasteType });
      if (stopApproval.id && stopApproval.waste_type === 'retail') {
        this.$store.dispatch('editApproval', stopApproval).then(() => {
          this.$router.push({ name: 'Add Approval' });
          setTimeout(() => {
            this.$store.dispatch('container/setSelectedContainerTypeLists');
          }, 0);
        });
      } else {
        this.$store.dispatch('rxApproval/editRxApproval', { stop_approval: stopApproval });
        this.$store.commit('rxApprovalList/hideRxApprovalConsolidationList', true);
        this.$router.push({ name: 'Rx Approval List' });
      }
    },
    reopen() {
      this.$store.dispatch('reopenApproval', { containerId: this.inventory.consolidation_container.id, stopId: this.stopId });
    },
    markasFull() {
      this.$store.dispatch('markasFull', { containerId: this.inventory.consolidation_container.id, stopId: this.stopId });
    },
    checkInventoryCcidStorage() {
      const thisStopUsesCCID = !this.lastInventoryList && this.useCCIDStorage;
      const lastStopUsedCCID = this.lastInventoryList && this.inventoryUseCcid;
      return thisStopUsesCCID || lastStopUsedCCID;
    },
  },
  props: {
    inventory: {
      type: Object,
      required: true,
    },
    stopCompleted: {
      type: Boolean,
      default() {
        return false;
      },
    },
    stopId: {
      type: Number,
      default: 0,
    },
    lastInventoryList: {
      type: Boolean,
      default: false,
    },
    inventoryUseCcid: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
