<template>
  <tr class="accumulation-table-data">
    <td class="label profile-label">
      <pre>{{ accumulationData.profile_name }}</pre>
    </td>
    <td class="label container-label">
      <pre>{{ containers }}</pre>
    </td>
    <td class="label weight-label">
      {{ accumulationData.weight }}LB
    </td>
    <td class="label quantity-label">
      {{ accumulationData.quantity }}
    </td>
    <td class="label">
      <div class="progress-container">
        <div
          class="progress-fill"
          :style="{ width: percentageOfStopWeight + '%' }"
        />
      </div>
    </td>
  </tr>
</template>

<script>
import convertTextToTitleCase from '../../utils/convert-text-to-titlecase';

export default {
  name: 'AccumulationPickupItem',
  computed: {
    containers() {
      return this.accumulationData.containers.map((container) => convertTextToTitleCase(container)).join(',\n');
    },
    percentageOfStopWeight() {
      return ((this.accumulationData.weight / this.expectedWeight) * 100).toFixed(2);
    },
  },
  props: {
    accumulationData: {
      type: Object,
      default() {
        return { };
      },
    },
    expectedWeight: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
};
</script>
