
import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function getFloorScale() {
  const requestOptions = {
    method: 'GET',
  };

  const url = `${API_BASE_URL}floor`;
  return fetchService.getFetchData(url, requestOptions);
}

function getBenchScale() {
  const requestOptions = {
    method: 'GET',
  };

  const url = `${API_BASE_URL}bench`;
  return fetchService.getFetchData(url, requestOptions);
}

const scaleService = {
  getFloorScale,
  getBenchScale,
};
export default scaleService;
