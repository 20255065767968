//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import VSelectArrow from '../../../helpers/utils/v-select-arrow';
import RxItemIncrementDecrementInput from '../../../helpers/component/mobile-rx-inc-dec-input/mobileRxIncDecInput.vue';

export default {
  name: 'MobileRxApprovalCard',
  components: {
    RxItemIncrementDecrementInput,
  },
  computed: {
    ...mapGetters({ rxContainerTypes: 'rxContent/getRxContainerTypes' }),
  },
  data() {
    return {
      containerQuantity: 0,
    };
  },
  extends: VSelectArrow,
  created() {
    this.containerQuantity = this.approval.containerQuantity;
  },
  methods: {
    viewApprovalDetails() {
      this.$store.commit('rxApproval/setSelectedViewApproval', this.approval);
      this.$router.push({ name: 'Mobile Add Rx Item Details' });
    },
    updateApproval(approval, change) {
      this.$store.dispatch('rxApproval/updateApprovalDetails', { approval, change });
    },
  },
  props: {
    approval: {
      type: Object,
      default: () => ({
        approvalDescription: '',
        totalWeight: '0.0',
        noOfRxItems: 0,
        containerType: '',
        containerQuantity: 0,
      }),
    },
  },
  watch: {
    containerQuantity(newVal) {
      this.approval.containerQuantity = newVal;
      this.updateApproval(this.approval, { containerQuantity: newVal });
    },
    approval: {
      deep: true,
      handler(val, newVal) {
        if (newVal.containerType && newVal.containerType !== '') {
          this.updateApproval(this.approval, { containerType: newVal.containerType });
        }
      },
    },
  },
};
