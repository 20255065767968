<template>
  <v-row class="ma-0 width-pc-100 plr-10 pb-4 pt-2">
    <template v-if="stopList.length>0">
      <MobileStopCard
        v-for="(stop, index) in stopList"
        :key="index"
        :stop-detail="stop"
        :trip-code="tripCode"
      />
    </template>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import MobileStopCard from '../../helpers/component/stop-card/stopCard-mobile.vue';

export default {
  name: 'MobileStopList',
  components: { MobileStopCard },
  computed: {
    ...mapGetters({ stopList: 'getStopListsArray' }),
  },
  created() {
    this.getStopsList();
  },
  methods: {
    getStopsList() {
      this.$store.dispatch('mobileStopList', parseInt(this.tripDocId, 10));
    },
  },
  props: {
    tripDocId: {
      type: String,
      required: true,
    },
    tripCode: {
      type: String,
      required: true,
    },
  },
};
</script>
