<template>
  <div>
    <v-container
      v-if="!isLabelVisibleClicked"
      class="scan-item-not-found"
    >
      <v-row class="width-100p">
        <v-col
          :cols="7"
          class="instruction-container"
        >
          <div class="instruction-header">
            We don’t have sorting instructions for this item yet.
          </div>
          <div class="instruction-sub-header">
            What to do next:
          </div>
          <div class="instruction">
            <ol>
              <li>
                Confirm that the front label and product name are clearly visible in the picture.
              </li>
              <li>
                Once confirmed, set the item aside. We will alert you when we have identified the
                item and it is ready to be rescanned and sorted.
              </li>
            </ol>
          </div>
        </v-col>
        <v-col class="right-section-container">
          <div class="retake-image-container">
            <div class="image-container">
              <img :src="photoUrl">
            </div>
            <v-btn
              class="btn btn-retake"
              v-if="isImageCapture"
              @click="retakeImage"
            >
              Retake image
            </v-btn>
            <v-btn
              class="btn btn-retake"
              v-else
              @click="captureImage"
            >
              Capture image
            </v-btn>
          </div>
          <div class="d-flex justify-end action-btn-group">
            <v-btn
              class="btn btn-cancel"
              @click="backToScanPage"
            >
              Cancel
            </v-btn>
            <v-btn
              class="btn btn-success"
              @click="labelVisibleClicked"
              :class="{'btn-disable': !isImageCapture}"
            >
              <img
                class="check-icon"
                src="src/assets/images/check-full-green.svg"
                alt="checkfullgreen"
              >
              Label is visible
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container
      v-else
      class="send-animation-container"
    >
      <div class="image-container">
        <img src="src/assets/images/illos-sent.svg">
      </div>
      <div class="description-header">
        <div v-if="!isSendingAnimationComplete">
          Sending<span id="first-dot">.</span><span
            id="second-dot"
          >.</span><span id="third-dot">.</span>
        </div>
        <div v-else>
          Sent
        </div>
      </div>
      <div class="description-text">
        We’ll get right on it. We should have a recommendation back to you
        ASAP (we shoot for 10 minutes).
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ScanItemNotFound',
  computed: {
    ...mapGetters({
      photoUrl: 'masterScan/getPhotoUrl',
      master: 'masterScan/getMasterScanned',
    }),
  },
  data() {
    return {
      isLabelVisibleClicked: false,
      isSendingAnimationComplete: false,
      sendingAnimationTimeout: setTimeout(() => {}),
      sentAnimationTimeout: setTimeout(() => {}),
      isImageCapture: false,
      unknownItemLog: {},
    };
  },
  created() {
    this.$camSocketsConnect();
    this.resetStreaming();
    this.$store.commit('masterScan/setPhotoUrl', '');
  },
  beforeDestroy() {
    this.$camSocketsDisconnect();
  },
  destroyed() {
    if (this.sendingAnimationTimeout) {
      clearTimeout(this.sendingAnimationTimeout);
    }
    if (this.sentAnimationTimeout) {
      clearTimeout(this.sentAnimationTimeout);
    }
  },
  methods: {
    labelVisibleClicked() {
      this.isLabelVisibleClicked = true;
      this.isSendingAnimationComplete = false;
      this.createUnknownItemLog();
      this.sendingAnimationTimeout = setTimeout(() => {
        this.completeSendingAnimation();
      }, 2000);
    },
    completeSendingAnimation() {
      this.isSendingAnimationComplete = true;
      this.sentAnimationTimeout = setTimeout(() => {
        this.backToScanPage();
      }, 500);
    },
    backToScanPage() {
      this.$emit('backToScanPage');
    },
    captureImage() {
      this.isImageCapture = true;
      this.$camSocketsSend('save_images');
      this.$store.dispatch('masterScan/setIsStreaming', false);
    },
    retakeImage() {
      this.isImageCapture = false;
      this.resetStreaming();
    },
    resetStreaming() {
      this.$store.dispatch('masterScan/setIsStreaming', true);
      this.$store.commit('masterScan/setPhotoUrl', '');
    },
    createUnknownItemLog() {
      this.buildUnknownItemLog();
      const params = {
        log: this.unknownItemLog,
        stopId: this.$store.state.stopDetail.stopDetail.id,
      };
      this.$store.dispatch('masterScan/createUnknownItemLog', params);
    },
    buildUnknownItemLog() {
      this.unknownItemLog = {
        bar_code: this.master.upc,
        master_id: this.master.cloud_id,
        weight_in_lbs: this.master.system_weight,
        scanned_at: new Date().toJSON(),
        source: '',
        package_id: '',
        quantity: 0,
        itemNumber: this.master.sku,
        component_number: 0,
      };
    },
  },
};
</script>
