<template>
  <v-card
    class="d-flex ma-b-24p card-shadow min-height-110px"
    :class="{'card-active-trip': tripDetail.is_active === true }"
    outlined
    tile
  >
    <div class="col-md-2">
      <div class="mb-1 label">
        Trip ID:
      </div>
      <div class="trip-card-val">
        <h3>
          {{ tripDetail.use_trip_id }}
        </h3>
      </div>
    </div>
    <div class="col-md-4 col-xl-5">
      <div class="mb-1 label">
        Trip name:
      </div>
      <div class="trip-card-val">
        {{ tripDetail.trip_name }}
      </div>
    </div>
    <div class="col-md-2">
      <div class="mb-1 label opacity-0">
        Completion Date:
      </div>
      <div class="opacity-0">
        {{ tripDetail.completion_date | dateFormat }}
      </div>
    </div>
    <div
      fill-height
      class="col-md-3 col-xl-3 position-relative border-left-1px
      margin-top--16px padding-0px text-center"
    >
      <template v-if="completedTrip === false">
        <div
          class="trip-status-sync-active"
          v-if="!tripDetail.stops_synced"
        >
          <div class="trip-status-text">
            Status: <img
              src="/src/assets/images/refresh-icon.svg"
              alt="refresh"
              class="trip-sync-indicator"
            > Trip syncing
          </div>
        </div>
        <div
          class="trip-status-active"
          v-else-if="tripDetail.trip_status === 'active'"
        >
          <p class="trip-status-text">
            Status: <span class="trip-status-active-circle" /> Active Stop
          </p>
        </div>
        <div
          class="trip-status-in-progress"
          v-else-if="tripDetail.trip_status === 'in_progress'"
        >
          <p class="trip-status-text">
            Status:  <span class="trip-status-in-progress-circle" /> In Progress
          </p>
        </div>
        <div
          class="trip-status-todo"
          v-else-if="tripDetail.trip_status === 'todo'"
        >
          <p class="trip-status-text">
            Status:  <span class="trip-status-todo-circle" /> To do
          </p>
        </div>
      </template>
      <template v-else>
        <div
          class="trip-status-completed"
        >
          <p class="trip-status-text">
            Status:  <span class="trip-status-completed-circle " /> Trip completed
          </p>
        </div>
      </template>
      <template v-if="completedTrip === false">
        <div
          class="trip-button-aligner"
        >
          <template v-if="tripDetail.stops_synced">
            <button
              class="keyboard-submit width-100p height-56p view-trip bg-light-blue view-trip-button"
              @click="viewTrip()"
            >
              View trip
            </button>
          </template>
          <template v-else>
            <div class="syncing-in-progress">
              <img
                src="src/assets/images/loader.png"
                alt="loading-icon"
                class="trip-sync-spinner"
              >
              <span>
                Syncing...
              </span>
            </div>
          </template>
        </div>
      </template>
      <div
        class="trip-button-aligner"
        v-if="completedTrip === true"
      >
        <button
          class="view-completed-trip-btn"
          @click="viewTrip()"
        >
          View trip
        </button>
        <button
          class="remove-completed-trip-btn"
          @click="removeTripDailogOpen(tripDetail.id)"
        >
          Remove trip
        </button>
      </div>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="removeTripDialog"
        width="837"
      >
        <v-card
          class="padding-24"
        >
          <p
            class="remove-trip-dialog-title"
            primary-title
          >
            Remove this trip?
          </p>
          <strong class="remove-trip-code"> Trip code: {{ tripDetail.trip_code }} </strong>
          <p class="remove-trip-description">
            You will no longer be able to view details or print documents
            from this trip once it is removed. If you need to access
            the trip again, you’ll be able to enter the trip code to retrieve it.
          </p>
          <button
            class="remove-trip-cancel-btn"
            @click="closeDialog()"
          >
            Cancel
          </button>
          <button
            class="remove-trip-confirm-btn"
            @click="removeTripConfirm()"
          >
            <i class="fas fa-trash" />
            Remove trip
          </button>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>
<script>
export default {
  name: 'TripCardComponent',
  data() {
    return {
      removeTripDialog: false,
      removeTripId: null,
      tripSyncInterval: {},
    };
  },
  methods: {
    removeTrip() {
      this.$store.dispatch('trip/hideTripAction', {
        tripId: this.tripDetail.id,
      });
    },
    viewTrip() {
      this.$store.dispatch('trip/updateStorageSetForTrip', this.tripDetail);
      this.$router.push(`stops/list/${this.tripDetail.id}`);
    },
    removeTripDailogOpen(id) {
      this.removeTripId = id;
      this.removeTripDialog = true;
    },
    closeDialog() {
      this.removeTripDialog = false;
    },
    removeTripConfirm() {
      const data = this.removeTripId;
      this.$store.dispatch('trip/removeTripAction', data);
    },
  },
  props: {
    tripDetail: {
      type: Object,
      default() {
        return {
          trip_name: '',
          use_trip_id: '',
          profit_center: '',
        };
      },
    },
    completedTrip: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
