<template>
  <div>
    <v-overlay
      :value="dialog"
      opacity="0.94"
      z-index="3"
    >
      <div class="d-flex justify-start align-start flex-column stop-begin-container">
        <div class="stop-instruction-header-dialog mt-6">
          <div><label>Stop instructions</label></div>
        </div>
        <div class="mt-6">
          <div class="notes">
            <label>Notes</label>
          </div>
          <div
            class="remove-all-drums-of"
            :class="{'none': !getStopDetail.comments}"
          >
            <p>
              {{ getStopDetail.comments ? getStopDetail.comments : 'none' }}
            </p>
          </div>
        </div>
        <div class="mt-6">
          <div class="directions">
            <label>Directions</label>
          </div>
          <div
            class="site-directions"
            :class="{'none': !getStopDetail.site_directions}"
          >
            <label>{{ getStopDetail.site_directions
              ? getStopDetail.site_directions : 'none' }}</label>
          </div>
        </div>
        <div class="mt-10 d-flex">
          <div
            @click="cancel()"
            class="cancel d-flex align-center justify-center cursor-pointer"
          >
            <button>Cancel</button>
          </div>
          <div
            @click="beginStop()"
            class="begin-stop d-flex align-center justify-center cursor-pointer"
          >
            <button>Begin stop</button>
          </div>
        </div>
      </div>
    </v-overlay>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import tripStorage from '../../../constants/trip-storage';

export default {
  name: 'BeginStopInstructionDialog',
  computed: {
    ...mapGetters({
      getStopDetail: 'getStopDetail',
      storageSelectedForTrip: 'trip/getStorageSelectedForTrip',
      getVoidedStopsInTrip: 'getVoidedStopsInTrip',
      getTripDetail: 'getTripDetail',
    }),
  },
  data() {
    return {
      dialog: false,
      stopId: null,
      unsubscribe: () => {},
    };
  },
  created() {
    this.checkStatusBeginStopDialog();
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    checkStatusBeginStopDialog() {
      this.unsubscribe = this.$store.subscribe((mutation) => {
        if (mutation.type === 'showBeginStopInstruction') {
          this.dialog = mutation.payload.status;
          this.stopId = mutation.payload.stopId;
        }
      });
    },
    cancel() {
      this.dialog = false;
    },
    beginStop() {
      if (this.storageSelectedForTrip) {
        this.$store.dispatch('stopArriveAction', this.stopId);
      } else if (this.checkActiveOrCompletedStop()) {
        const params = {
          stopId: this.stopId,
          storageMethod: tripStorage.storageMethods.use_ccid,
        };
        this.$store.dispatch('firstStopArriveAction', params);
      } else {
        this.$emit('openStorageModel', this.stopId);
      }
    },
    checkActiveOrCompletedStop() {
      return this.getTripDetail.stops.some((stop) => stop.is_completed || stop.is_active);
    },
  },
  watch: {
    getVoidedStopsInTrip() {
      if (this.getVoidedStopsInTrip.includes(this.stopId)) {
        this.dialog = false;
      }
    },
  },
};
</script>
