import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function getRxApprovals(generatorId, value) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}rx_approval/stop/${generatorId}?search=${value}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getApprovalNotes(rxItemId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}rx_approval/${rxItemId}/notes`;
  return fetchService.getFetchData(url, requestOptions);
}

function addApprovalNotes(rxItemId, notes) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ notes }),
  };
  const url = `${API_BASE_URL}rx_approval/${rxItemId}/notes`;
  return fetchService.getFetchData(url, requestOptions);
}

function getRxContentDetails() {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}rx_approval/content_details`;
  return fetchService.getFetchData(url, requestOptions);
}

function addToApprovalList(payload, stopId) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };
  const url = `${API_BASE_URL}stops/${stopId}/add_to_approval_list`;
  return fetchService.getFetchData(url, requestOptions);
}

function getRxApprovalList(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stops/${stopId}/rx_approval_list`;
  return fetchService.getFetchData(url, requestOptions);
}

function rxConsolidate(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };
  const url = `${API_BASE_URL}consolidate_rx_item`;
  return fetchService.getFetchData(url, requestOptions);
}
function updateRxApproval(id, payload) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };
  const url = `${API_BASE_URL}stop_rx_items/${id}`;
  return fetchService.getFetchData(url, requestOptions);
}
function rxReConsolidate(payload) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };
  const url = `${API_BASE_URL}consolidate_rx_item/${payload.stopApprovalId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function getEmptyBottles(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}rx_approval/empty_bottle/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}
const RxApprovalService = {
  addApprovalNotes,
  getRxApprovals,
  getApprovalNotes,
  getRxContentDetails,
  updateRxApproval,
  addToApprovalList,
  getRxApprovalList,
  rxConsolidate,
  rxReConsolidate,
  getEmptyBottles,
};
export default RxApprovalService;
