<template>
  <div
    :class="{ 'margin-top-15': contentIndex > 0 }"
  >
    <template v-if="showContent">
      <v-row no-gutters>
        <v-col
          cols="12"
          lg="8"
        >
          <span class="add-rx-items-details-title">
            Content details
          </span>
        </v-col>


        <closeContent
          :month="contentDetail.month"
          :content-index="contentIndex"
          :first-index="firstIndex"
          :last-index="lastIndex"
        />
      </v-row>


      <v-row
        no-gutters
        class="margin-top-10"
      >
        <contentSelect
          :content-id="contentDetail.contentID"
          :content-index="contentIndex"
        />

        <contentQuantity
          :quantity="contentDetail.contentQuantity"
          :content-index="contentIndex"
        />
      </v-row>
      <hr class="rx-items-line">
    </template>
    <v-row
      no-gutters
      class="mt-4"
    >
      <v-col
        cols="12"
        lg="8"
      >
        <span class="add-rx-items-details-title">
          Weight and quantity
        </span>
      </v-col>

      <closeContent
        v-if="!getRxDetail.rx_item || rxEmptyBottle"
        :month="contentDetail.month"
        :content-index="contentIndex"
        :first-index="firstIndex"
        :last-index="lastIndex"
      />
    </v-row>
    <div v-if="!rxEmptyBottle">
      <v-row
        no-gutters
        class="mt-4"
      >
        <template v-if="contentDetail.weightType == 0">
          <weightPounds
            :weight-pounds="contentDetail.weightPounds"
            :content-index="contentIndex"
          />
          <weightOunces
            :weight-ounces="contentDetail.weightOunces"
            :content-index="contentIndex"
          />
        </template>
        <template v-if="contentDetail.weightType == 1">
          <benchScale
            :total-weight="contentDetail.totalWeight"
            :content-index="contentIndex"
          />
        </template>


        <contentContainer
          :containers="contentDetail.containers"
          :content-index="contentIndex"
        />
      </v-row>
    </div>
    <div>
      <v-row
        no-gutters
        class="margin-top-15"
        v-if="rxEmptyBottle"
      >
        <rxEmptyBottleContainer
          :no-of-empty-bottles="contentDetail.noOfEmptyBottles"
          :content-index="contentIndex"
        />
        <v-col
          cols="12"
          lg="6"
          class="residual-weight-text"
        >
          <div class="rx-items-float-right">
            <p class="add-rx-items-details-sub-title margin-bottom-4">
              Residual weight
            </p>
            <p class="add-rx-items-details-value rx-detail">
              {{ contentDetail.residualWeight }} lb
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row
        no-gutters
        v-if="rxEmptyBottle"
      >
        <v-col
          cols="12"
          lg="7"
        >
          <v-row>
            <template v-if="contentDetail.weightType == 0">
              <v-col
                cols="12"
                lg="6"
              >
                <div class="rx-bottle-weight-label">
                  <p class="add-rx-items-details-sub-title margin-bottom-4">
                    Bottle weight
                  </p>
                  <input
                    class="input"
                    type="text"
                    v-model="weight"
                    @click="checkDialog('numeric','weight','Bottle weight', weight.toString())"
                  >
                </div>
              </v-col>
              <v-col
                cols="12"
                lg="6"
              >
                <div class="rx-bottle-weight-dropdown-div">
                  <v-select
                    ref="containerType"
                    class="input-type-select dropdown-color-grey"
                    v-model="weightUnit"
                    :items="manifestUnitsList"
                    item-value="id"
                    item-text="unit"
                    outlined
                    append-icon="fa-angle-down"
                    :menu-props="{ offsetY: true }"
                    background-color="white"
                    @change="changeWeightUnit($event)"
                    placeholder=""
                    @click:append="closeSelectDropdown('containerType')"
                  />
                </div>
              </v-col>
            </template>
          </v-row>
          <template v-if="contentDetail.weightType == 1">
            <benchScale
              :total-weight="contentDetail.totalWeight"
              :content-index="contentIndex"
            />
          </template>
        </v-col>
        <v-col
          cols="12"
          lg="5"
          class="bottle-total-weight"
        >
          <div
            class="margin-top-15 rx-items-float-right"
            v-if="rxEmptyBottle"
          >
            <p
              class="add-rx-items-details-sub-title"
            >
              Residual weight +<br> bottle weight: <br>
              <b>{{ calculatedResidualWeight() }} lb</b>
            </p>
          </div>
        </v-col>
      </v-row>
    </div>


    <div class="rx-items-toggle-btn mb-24">
      <v-btn
        class="fs-20 width-170
          rx-items-toggle-btn-box-shadow
          text-transform-none border-radius-left-4 letter-spacing-0"
        @click="weightTypeChange(0)"
        :class="selectedWeightType === 0 ? 'rx-toggle-active' : ''"
      >
        Manual
      </v-btn>

      <v-btn
        class="fs-20 width-170 rx-items-toggle-btn-box-shadow
        text-transform-none margin-left--5 border-radius-right-4 letter-spacing-0"
        @click="weightTypeChange(1)"
        :class="selectedWeightType === 1 ? 'rx-toggle-active' : ''"
      >
        Bench scale
      </v-btn>
    </div>
    <billUnitList
      v-if="hasCustomerSpecificBilling"
      :rx-item="true"
      :content-index="contentIndex"
    />
    <KeyboardDialog
      :open-dialog="pinDialogIsOpen"
      :keyboard-type="keyBoardType"
      :model-name="modelName"
      :label-name="labelName"
      :model-value="modelValue"
      @dialogInputChanged="dialogInputChanges"
      name="keyboard"
      @enterClicked="enterClicked"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import benchScale from './benchScale.vue';
import closeContent from './closeContent.vue';
import contentContainer from './contentContainer.vue';
import contentSelect from './contentSelect.vue';
import contentQuantity from './contentQuantity.vue';
import weightOunces from './weightOunces.vue';
import weightPounds from './weightPounds.vue';
import rxEmptyBottleContainer from './emptyBottleContainer.vue';
import KeyboardDialog from '../virtual-keyboard/virtualKeyboardDialog.vue';
import VSelectArrow from '../../utils/v-select-arrow';
import billUnitList from '../billUnits/billUnitList.vue';

export default {
  name: 'RxContentBuilder',
  components: {
    billUnitList,
    closeContent,
    contentSelect,
    contentQuantity,
    contentContainer,
    weightOunces,
    weightPounds,
    benchScale,
    rxEmptyBottleContainer,
    KeyboardDialog,
  },
  computed: {
    ...mapGetters({
      getRxDetail: 'rxApproval/getRxApprovalDetail',
      showContent: 'rxApproval/getShowContent',
      rxEmptyBottle: 'rxApproval/getRxEmptyBottle',
      manifestUnitsList: 'getManiFestUnits',
    }),
    totalResidualWeight() {
      // The conversion to string and back to float allows us to limit the # decimal points
      // while preventing printing unnecessary trailing zeros.
      const calculatedVal = this.contentDetail.residualWeight * this.contentDetail.noOfEmptyBottles;
      const fixedVal = calculatedVal.toFixed(this.precisionValue);

      return parseFloat(fixedVal);
    },
    precisionValue() {
      const originalVal = this.contentDetail.residualWeight;

      if (originalVal === 0) {
        return 0;
      }

      const decimalPlaces = originalVal.toString().split('.')[1]
        ? originalVal.toString().split('.')[1].length
        : 0;

      return decimalPlaces;
    },
    hasCustomerSpecificBilling() {
      if (!this.checkBillUnitAvail) return false;
      return this.filterOutLbsBillUnit(this.contentDetail.billUnits).length > 0;
    },
    checkBillUnitAvail() {
      return this.contentDetail && this.contentDetail.billUnits;
    },
  },
  data() {
    return {
      selectedWeightType: 0,
      weight: '',
      weightUnit: 4,
      pinDialogIsOpen: false,
      keyBoardType: '',
      modelName: '',
      labelName: '',
      modelValue: '',
    };
  },
  extends: VSelectArrow,
  created() {
    this.getManiFestUnits();
    this.weight = this.contentDetail.bottleWeight;
    this.weightUnit = this.contentDetail.weightUnit;
    if (this.$store.state.rxApproval.rxEmptyBottle) {
      this.$store.commit('rxContent/enablePreviousMonthBtn');
      this.$store.commit('rxContent/setEmptyBottleStatus', true);
    }
  },
  destroyed() {
    this.resetData();
  },
  methods: {
    resetData() {
      this.$store.commit('rxContent/setEmptyBottleStatus', false);
    },
    weightTypeChange(input) {
      if (this.getRxDetail.id) {
        this.selectedWeightType = input;
        this.setContentValue(input, 'weightType');
      }
    },
    getManiFestUnits() {
      this.$store.dispatch('getManiFestUnits');
    },
    checkDialog(KeyboardType, modelName, labelName, modelValue) {
      this.pinDialogIsOpen = true;
      this.keyBoardType = KeyboardType;
      this.modelName = modelName;
      this.labelName = labelName;
      this.modelValue = modelValue;
    },
    dialogInputChanges(changes) {
      this.pinDialogIsOpen = changes;
    },
    enterClicked(value) {
      const weight = value.input;
      this.weight = weight;
      this.setContentValue(this.weight, 'bottleWeight');
    },
    setContentValue(input, fieldName) {
      this.$store.commit('rxContent/setContentValue', {
        index: this.contentIndex,
        field: fieldName,
        value: input,
      });
    },
    changeWeightUnit(ev) {
      this.setContentValue(ev, 'weightUnit');
    },
    filterOutLbsBillUnit(billUnits) {
      return billUnits.filter((bu) => bu.unit !== 'LBS');
    },
    calculatedResidualWeight() {
      const total = this.totalResidualWeight + Number(this.weight);

      return total.toFixed(this.precisionValue);
    },
  },
  props: {
    contentDetail: {
      type: Object,
      default: () => ({
        contentID: '',
        contentQuantity: 0,
        weightType: 0,
        weightPounds: 0,
        weightOunces: 0,
        containers: 0,
        totalWeight: 0.0,
        month: '',
        billUnits: null,
      }),
    },
    lastIndex: {
      type: Boolean,
      default: false,
    },
    firstIndex: {
      type: Boolean,
      default: false,
    },
    contentIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    rxEmptyBottle(newVal) {
      if (newVal) {
        this.weight = this.contentDetail.bottleWeight;
        this.$store.commit('rxContent/enablePreviousMonthBtn');
        this.$store.commit('rxContent/setEmptyBottleStatus', true);
      }
    },
    contentDetail() {
      this.selectedWeightType = 0;
    },
  },
};
</script>
