<template>
  <div
    class="d-flex nav-item cursor-pointer"
    :class="[navItem.borderColor, {'nav-item-disabled': navItem.disabled || disableIdentifyItem }]"
    @click="goToPage()"
  >
    <div
      class="flex-w-1 d-flex align-center justify-center height-100p"
    >
      <img
        src="src/assets/images/no-signal.svg"
        alt="scan-inactive"
        class="scan-inactive"
        v-if="disableIdentifyItem"
      >
      <div
        :class="navItem.icon"
        v-else
      />
    </div>
    <div
      class="d-flex flex-column nav-item-label"
    >
      <label>{{ navItem.name }}</label>
      <span v-if="disableIdentifyItem">Find a stable internet connection to scan</span>
    </div>
    <div
      class="d-flex flex-column flex-w-1 align-center"
    >
      <div
        v-if="navItem.edited"
        class="refresh-icon-grey"
      />
      <div
        class="nav-edit-label"
        v-if="navItem.edited"
      >
        <label>Edited</label>
      </div>
      <div
        v-if="navItem.synced"
        class="tick-icon-green"
      />
      <div
        class="nav-edit-label"
        v-if="navItem.synced"
      >
        <label>Synced</label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'MainStopPageNavList',
  computed: {
    disableIdentifyItem() {
      return this.navItem.name === 'Identify an item' && !this.hasNetwork;
    },
  },
  methods: {
    goToPage() {
      this.$emit('go-to-page', this.navItem);
    },
  },
  props: {
    navItem: {
      type: Object,
      default: () => ({
        name: '',
        borderColor: '',
        icon: '',
        edited: false,
      }),
    },
    hasNetwork: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
