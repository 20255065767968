import api from '../service/rx-approval.service';
import approval from '../service/approval.service';
import rxItemMobile from '../service/firestore/rx-items.service';

function defaultRxApprovalDetail() {
  return {
    id: null,
    generator_id: null,
    approval_code: null,
    description: null,
    tsdf: null,
    rx_item: {
      id: null,
      approval_id: null,
      ndc: null,
      disposition: null,
      dea_schedule: null,
    },
    selected_notes: 0,
  };
}
const stateObj = {
  rxApprovalList: [],
  rxApprovalDetail: defaultRxApprovalDetail(),
  contentDetails: [],
  approvalNotes: [],
  showContent: true,
  noApprovalDialog: false,
  rxEditApproval: {},
  addedNotes: null,
  rxEmptyBottle: false,
  approvalList: [],
  currentSearchValue: null,
  currentSearchResult: false,
  mobileRxItemList: [],
  rxItemObject: {},
  confirmedRxItem: {},
  selectedViewApproval: {},
  selectedRxItem: {},
  rxItemMobileProp: {
    showSearchIcon: false,
    showApprovalDetails: true,
    showSearchResult: false,
    noRecordsFound: false,
  },
  mobileRxEmptyBottleDetails: {},
  stopApprovalApiCallInProgress: false,
};
function getSelectedEditApproval(commit, state, payload, editType) {
  const rxApprovalLists = state.rxEditApproval;
  const param = state.rxApprovalDetail;
  if (
    rxApprovalLists
    && rxApprovalLists.approval_rx_item
    && rxApprovalLists.approval_rx_item.stop_rx_items.length > 0
  ) {
    const editApprovalDetail = rxApprovalLists.approval_rx_item.stop_rx_items[payload];
    param.id = editApprovalDetail.id;
    param.approvalId = editApprovalDetail.approval_id;
    param.generator_id = editApprovalDetail.approval.generator_id;
    param.approval_code = editApprovalDetail.approval.approval_code;
    param.approval_description = editApprovalDetail.approval.description;
    param.tsdf = editApprovalDetail.approval.tsdf;
    param.total_weight = editApprovalDetail.total_weight;
    param.selected_notes = editApprovalDetail.rx_item_note;
    param.rx_item = editApprovalDetail.rx_item;
    param.rx_code = editApprovalDetail.rx_code;
    param.description = editApprovalDetail.rx_item.description_with_dosage;
    param.approval_rx_item_id = rxApprovalLists.approval_rx_item.id;
    if (editType) {
      param.editType = editType;
    }
    commit('setSelectedNotes', editApprovalDetail.rx_item_note);
  } else if (
    payload
    && payload.rxApprovedList
    && payload.rxApprovedList.stop_rx_items.length > 0
  ) {
    const rxApprovalDetail = payload.rxApprovedList;
    param.id = rxApprovalDetail.stop_rx_items[payload.index].id;
    param.approvalId = rxApprovalDetail.stop_rx_items[payload.index].approval_id;
    param.generator_id = rxApprovalDetail.approval.generator_id;
    param.approval_code = rxApprovalDetail.approval.approval_code;
    param.approval_description = rxApprovalDetail.approval.description;
    param.tsdf = rxApprovalDetail.approval.tsdf;
    param.total_weight = rxApprovalDetail.total_weight;
    param.selected_notes = rxApprovalDetail.stop_rx_items[payload.index].rx_item_note;
    param.rx_item = rxApprovalDetail.stop_rx_items[payload.index].rx_item;
    param.rx_code = rxApprovalDetail.stop_rx_items[payload.index].rx_code;
    param.approval_rx_item_id = rxApprovalDetail.id;
    param.description = param.rx_item.description_with_dosage;
    param.editType = 'consolidate';
    commit('setSelectedNotes', param.selected_notes);
  }
}
const getters = {
  getRxApprovalList(context) {
    return context.approvalList;
  },
  getSelectedApproval(context) {
    return context.approvalDetail;
  },
  getRxLists(context) {
    return context.rxApprovalList;
  },
  getRxApprovalDetail(context) {
    return context.rxApprovalDetail;
  },
  getApprovalNotes(context) {
    return context.approvalNotes;
  },
  getContentDetails(context) {
    return context.contentDetails;
  },
  getShowContent(context) {
    return context.showContent;
  },
  getSelectedNotes(context) {
    return context.rxApprovalDetail.selectedNotes;
  },
  getRxEditApproval(context) {
    return context.rxEditApproval;
  },
  hideRxApprovalConsolidationList(context) {
    return context.hideAndShowRxApprovalList;
  },
  getAddedNotes(stateControl) {
    return stateControl.addedNotes;
  },
  getRxEmptyBottle(stateControl) {
    return stateControl.rxEmptyBottle;
  },
  getSearchValue(stateControl) {
    return stateControl.currentSearchValue;
  },
  getSearchResult(stateControl) {
    return stateControl.currentSearchResult;
  },
  getMobileRxItemList(stateControl) {
    return stateControl.mobileRxItemList;
  },
  getSelectedRxItem(stateControl) {
    return stateControl.selectedRxItem;
  },
  getRxItemObject(stateControl) {
    return stateControl.rxItemObject;
  },
  getSelectedViewApproval(stateControl) {
    return stateControl.selectedViewApproval;
  },
  getRxItemMainProp(stateControl) {
    return stateControl.rxItemMobileProp;
  },
  getMobileRxEmptyBottleDetails(stateControl) {
    return stateControl.mobileRxEmptyBottleDetails;
  },
  getStopApprovalApiCallStatus(stateControl) {
    return stateControl.stopApprovalApiCallInProgress;
  },
};
const mutations = {
  setApprovalList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxApprovalList = payload;
  },
  setApprovalDetail(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxApprovalDetail = payload;
    stateControld.rxApprovalDetail.total_weight = 0.0;
    stateControld.rxApprovalDetail.selected_notes = null;
    stateControld.showContent = false;
    if (payload.rx_item && payload.rx_item.empty_bottle_flag) {
      stateControld.rxEmptyBottle = true;
      stateControld.showContent = false;
    } else if (payload.rx_item) {
      stateControld.showContent = true;
    }
  },
  resetApprovalDetail(stateControl) {
    const stateControld = stateControl;
    stateControld.rxApprovalDetail = defaultRxApprovalDetail();
  },
  setContentDetails(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.contentDetails = payload;
  },
  resetContentDetails(stateControl) {
    const stateControld = stateControl;
    stateControld.contentDetails = [];
  },
  setApprovalNotes(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.approvalNotes = payload;
  },
  resetApprovalNotes(stateControl) {
    const stateControld = stateControl;
    stateControld.approvalNotes = [];
  },
  resetRxApprovalList(stateControl) {
    const stateControld = stateControl;
    stateControld.rxApprovalList = [];
  },
  setSelectedNotes(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxApprovalDetail.selected_notes = payload;
  },
  setNoApprovalDialog(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.noApprovalDialog = payload;
  },
  setEditApprovalDetail(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxApprovalDetail = {};
    stateControld.rxApprovalDetail = payload;
    stateControld.showContent = false;
    if (payload.rx_item) {
      stateControld.showContent = true;
    }
  },
  setSelectedEditApproval(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxEditApproval = payload;
  },
  setAddedNotes(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.addedNotes = payload;
  },
  confirmDelete(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxEditApproval.approval_rx_item.stop_rx_items[
      payload.index
    ].deleteRxStatus = payload.value;
  },
  setRxEmptyBottle(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxEmptyBottle = payload;
  },
  enableShowContent(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.showContent = payload;
  },
  setRxApprovalList(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.approvalList = payload;
  },
  setSearchValue(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.currentSearchValue = payload;
  },
  resultEmpty(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.currentSearchResult = payload;
  },
  setRxItemListMobile(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.mobileRxItemList = payload;
  },
  setSelectedRxItem(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.selectedRxItem = payload;
  },
  setRxItemObject(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.rxItemObject = payload;
  },
  setSelectedViewApproval(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.selectedViewApproval = payload;
  },
  setRxItemMainProp(stateControl, payload) {
    const {
      showSearchIcon,
      showApprovalDetails,
      showSearchResult,
      noRecordsFound,
    } = payload;
    const stateControld = stateControl;
    stateControld.rxItemMobileProp = {
      showSearchIcon,
      showApprovalDetails,
      showSearchResult,
      noRecordsFound,
    };
  },
  setRxItemMainPropDefault(stateControl) {
    const props = {};
    props.showApprovalDetails = true;
    props.showSearchIcon = false;
    props.noRecordsFound = false;
    props.showSearchResult = false;
    const stateControld = stateControl;
    stateControld.rxItemMobileProp = props;
  },
  setMobileRxEmptyBottleDetails(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.mobileRxEmptyBottleDetails = payload;
  },
  setStopApprovalApiCallStatus(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.stopApprovalApiCallInProgress = payload;
  },
};
const actions = {
  setApprovalList(context, generatorId) {
    api.getApprovals(generatorId, '').then((res) => {
      context.commit('setApprovalList', res.approvals);
    });
  },
  assignApprovalAction({ commit }, payload) {
    commit('setApprovalDetail', payload);
  },
  searchApprovals(context, obj) {
    api.getApprovals(obj.id, obj.value).then((res) => {
      context.commit('setApprovalList', res.approvals);
    });
  },
  getContentDetailsList(context) {
    api.getRxContentDetails().then((res) => {
      context.commit('setContentDetails', res.content_details);
    });
  },
  getApprovalNotes(context, payload) {
    if (payload && payload.id) {
      api.getApprovalNotes(payload.id).then((res) => {
        context.commit('setApprovalNotes', res.notes);
      });
    } else {
      context.commit('setApprovalNotes', []);
    }
  },
  searchRxItem(context, res) {
    context.commit('resetContentDetails');
    context.commit('resultEmpty', false);
    return new Promise((resolve, reject) => {
      context.commit('setApprovalList', []);
      context.commit('setRxApprovalList', []);
      context.commit(
        'loadingSearchIcon/loadingRequest',
        { status: true, loadingName: 'Loading search results' },
        { root: true },
      );
      api
        .getRxApprovals(res.stopId, res.search)
        .then((response) => {
          context.commit(
            'loadingSearchIcon/loadingRequest',
            { status: false, loadingName: 'Loading search results' },
            { root: true },
          );
          context.commit('setNoApprovalDialog', false);
          if (
            response
            && response.approvals
            && response.approvals.length === 1
            && response.approvals[0].approval_id
          ) {
            context.dispatch('fetchApprovalDetail', response.approvals[0]);
            resolve('exactlyOneApproval');
          } else if (
            response
            && response.approvals
            && response.approvals.length > 0
          ) {
            context.commit('setApprovalList', response.approvals);
            context.commit('setRxApprovalList', response.approvals);
            resolve('maximumOneApproval');
          } else {
            context.commit('setApprovalList', response.approvals);
            context.commit('setSearchValue', res.search);
            context.commit('resultEmpty', true);
            resolve(1);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  resetAllContentData({ commit }) {
    commit('resetApprovalDetail');
    commit('resetContentDetails');
    commit('resetApprovalNotes');
    commit('resetRxApprovalList');
    commit('setRxEmptyBottle', false);
    commit(
      {
        type: 'rxContent/resetContent',
      },
      { root: true },
    );
    commit('enableShowContent', true);
  },
  setSelectedRxApproval({ commit, state }, payload) {
    getSelectedEditApproval(commit, state, payload, 'rxReConsolidate');
  },
  editRxApproval({ commit, dispatch }, payload) {
    if (payload.stop_approval.editType) {
      if (payload.stop_approval.editType === 'consolidate') {
        commit(
          'rxApprovalList/setRxApprovedList',
          payload.res.rx_approval_item,
          { root: true },
        );
        dispatch(
          'rxApprovalList/getRxApprovalList',
          payload.stop_approval.stopId,
          { root: true },
        );
      } else {
        commit('setSelectedEditApproval', {
          approval_rx_item: payload.res.rx_approval_item,
          waste_type: 'rx',
          id: payload.res.rx_approval_item.stop_approval.id,
        });
      }
    } else {
      commit('setStopApprovalApiCallStatus', true);
      approval
        .getStopApproval(
          payload.stop_approval.id,
          payload.stop_approval.waste_type,
        )
        .then((response) => {
          if (!payload.stop_approval.editType) {
            commit('setSelectedEditApproval', response.stop_approval);
          }
          commit('setStopApprovalApiCallStatus', false);
        })
        .catch(() => {
          commit('setStopApprovalApiCallStatus', false);
        });
    }
  },
  editRXApprovalForConsolidate({ commit, state }, payload) {
    getSelectedEditApproval(commit, state, payload, 'rxConsolidate');
  },
  getEmptyBottles({ commit }, stopId) {
    api.getEmptyBottles(stopId).then((response) => {
      if (response && response.approvals.length) {
        commit('setApprovalDetail', response.approvals[0]);
        commit('rxContent/setBillUnitList', response.approvals[0].bill_units, {
          root: true,
        });
        commit(
          'rxContent/setContentValue',
          {
            index: 0,
            field: 'residualWeight',
            value: response.approvals[0].rx_item.empty_bottle_residue_factor,
          },
          { root: true },
        );
      } else {
        commit('setNoApprovalDialog', true);
      }
    });
  },
  mobileSearchRxItem({ commit }, params) {
    const props = {};
    props.showApprovalDetails = false;
    props.showSearchIcon = true;
    props.noRecordsFound = false;
    props.showSearchResult = false;
    commit('setRxItemMainProp', props);

    commit('resetContentDetails');
    commit('resultEmpty', false);
    commit(
      'loadingSearchIcon/loadingRequest',
      { status: true, loadingName: 'Loading search results' },
      { root: true },
    );
    rxItemMobile.getRxItemList(commit, params);
  },
  updateApprovalDetails({ commit }, params) {
    rxItemMobile.updateRxApproval(commit, params);
  },
  deleteRxItemFromApproval({ commit }, params) {
    rxItemMobile.deleteRxItemFromApproval(commit, params);
  },
  fetchApprovalDetail({ commit }, item) {
    return approval.fetchRxApprovalDetail(item).then((response) => {
      commit('setApprovalDetail', response.approval);
      commit('rxContent/setBillUnitList', response.approval.bill_units, {
        root: true,
      });
      const approvalDetail = response.approval.approval_detail;
      approvalDetail.bill_units = JSON.parse(approvalDetail.bill_units);
      approvalDetail.waste_codes = JSON.parse(approvalDetail.waste_codes);
      if (approvalDetail.waste_codes) {
        approvalDetail.waste_codes = approvalDetail.waste_codes.waste_codes;
      }
      commit('setApprovalDrawerDetails', approvalDetail, { root: true });
    });
  },
};
export default {
  namespaced: true,
  state: stateObj,
  getters,
  actions,
  mutations,
};
