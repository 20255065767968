
import pageSequence from '../constants/page-sequence';

const state = {
  pageSequenceList: pageSequence.sequenceList,
  nextPage: pageSequence.sequenceList[1],
};
const getters = {
  getPageSequenceList(stateControl) {
    return stateControl.pageSequenceList;
  },
  getNextPage(stateControl) {
    return stateControl.nextPage;
  },
};

const mutations = {
  setPageSequenceValue(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.pageSequenceList.map((pageData) => {
      const pageDetail = pageData;
      const path = pageData.path.replace('stopID', payload.stopId);
      let payloadPagePath = payload.currentPath;

      // Remove any subpaths from the path so that we match the original pageDetail
      pageDetail.subPaths.forEach((subPath) => {
        payloadPagePath = payloadPagePath.replace(subPath, '');
        // remove a trailing slash
        if (payloadPagePath.substr(-1) === '/') {
          payloadPagePath = payloadPagePath.slice(0, -1);
        }
      });

      pageDetail.active = false;
      if (path === payloadPagePath) {
        pageDetail.active = true;
        stateControld.nextPage = stateControld.pageSequenceList[pageDetail.index + 1];
      }
      return pageData;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
