import api from '../service/auth.service';

const state = {
  email: null,
  resendPinEmailStatus: null,
};
const getters = {
  getEmail(stateControl) {
    return stateControl.email;
  },
};
const mutations = {
  addEmail(stateControl, email) {
    const stateControld = stateControl;
    stateControld.email = email;
  },
  setResendEmailStatusSuccess(stateControl) {
    const stateControld = stateControl;
    stateControld.resendPinEmailStatus = 'Success';
  },
  setResendEmailStatusFailed(stateControl) {
    const stateControld = stateControl;
    stateControld.resendPinEmailStatus = 'Failed';
  },
};
const actions = {
  addActionEmail(context, email) {
    return new Promise((resolve, reject) => {
      api.getForgotPIN(email).then((res) => {
        context.commit('addEmail', email);
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
    });
  },
  resendPinEmail(context, email) {
    return new Promise((resolve, reject) => {
      api.postSendPinEmail(email).then((res) => {
        resolve(res);
        context.commit('setResendEmailStatusSuccess');
      }).catch((err) => {
        reject(err);
        context.commit('setResendEmailStatusFailed');
      });
    });
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
