<template>
  <div class="d-flex">
    <div class="weight-container">
      <div class="label">
        Total RetailSiS weight
      </div>
      <div class="value">
        {{ formattedRetailSiTotalWeight }}
      </div>
    </div>
    <div class="weight-container">
      <div class="label">
        Total TruckSiS Weight
      </div>
      <div
        class="value truck-sis-weight-label"
        :class="{'warning-label': displayOverweightWarning}"
      >
        <img
          v-if="displayOverweightWarning"
          class="weight-indication"
          src="src/assets/images/alert.svg"
          alt="weight-icon"
        >
        <img
          v-else
          class="weight-indication"
          src="src/assets/images/check-full-green.svg"
          alt="weight-icon"
        >
        {{ truckSisTotalWeight.toFixed(1) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CompleteStopWeightInfo',
  computed: {
    formattedRetailSiTotalWeight() {
      return Number(this.retailSisTotalWeight).toFixed(2);
    },
  },
  props: {
    truckSisTotalWeight: {
      type: Number,
      default() {
        return {};
      },
    },
    retailSisTotalWeight: {
      type: Number,
      default() {
        return {};
      },
    },
    displayOverweightWarning: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
};
</script>
