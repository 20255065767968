//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'DeleteApprovalConfirmationDialog',
  computed: {
    ...mapGetters({
      approvalDetail: 'consolidate/getApprovalDelete',
      useCCIDStorage: 'getUseCCIDStorage',
      voidedStop: 'getStopVoidStatus',
    }),
  },
  data() {
    return {
      dialog: false,
      approvalType: this.$route.name,
    };
  },
  methods: {
    closeDialog() {
      this.$emit('close', false);
    },
    rxEditApprovalSequenceCCIDs(ccids) {
      return ccids.join(', ');
    },
    confirmDelete() {
      if (this.approvalDetail.type === 'retail') {
        const options = {
          stopApprovalId: this.approvalDetail.stopApprovalId,
          stopId: this.approvalDetail.stopId,
          approvalId: this.approvalDetail.approvalId,
        };
        this.$store.dispatch('consolidate/deleteFinalApproval', options);
      } else {
        this.$store.dispatch(
          'consolidate/deleteRxItems', {
            id: this.approvalDetail.stopRxItemId,
            stopId: this.approvalDetail.stopId,
          },
        );
      }
    },
    confirmRemoveCcid() {
      this.$emit('close', false);
      this.$store.dispatch('consolidate/removeRealCCIDAction', this.ccidObject.consolidateDataid);
    },
  },
  props: {
    openDialog: Boolean,
    ccidObject: {
      type: Object,
      default() {
        return {
          popupTitle: '',
        };
      },
    },
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
    voidedStop() {
      this.dialog = false;
    },
  },
};
