<template>
  <div class="consolidate-sub-div">
    <label class="consolidate-sub-title">
      Eligible CCIDs
    </label>
    <v-row class="mt-3">
      <v-col
        cols="12"
        lg="6"
      >
        <button
          :disabled="isCreateCCID"
          @click.prevent="createNewCCID()"
          class="consolidate-create-ccid-button"
          type="button"
        >
          <img
            alt="plus-circle"
            src="src/assets/images/plus-circle-icon.svg"
          >
          <span
            class="margin-left-10"
          >
            Create a new CCID
          </span>
        </button>
      </v-col>

      <v-col
        cols="12"
        lg="6"
        class="text-right margin-top-30"
      >
        <button
          class="view-eligible-full-ccid"
          @click.prevent="showEligibleCCIDs()"
        >
          <template v-if="!isEligibleCCIDs">
            View eligible full CCIDs
          </template>
          <template v-else>
            Hide eligible full CCIDs
          </template>
        </button>
      </v-col>
    </v-row>
    <div class="consolidate-sub-div-2">
      <p
        class="no-eligible-ccids"
        v-if="$options.filters
          .eligibleCCIDsFormat(getConsolidateList, isEligibleCCIDs).length === 0"
      >
        No eligible CCIDs available
      </p>
      <div v-if="getConsolidateList">
        <ConsolidationCard
          v-for="(consolidateData, index) in $options.filters
            .eligibleCCIDsFormat(getConsolidateList, isEligibleCCIDs)"
          :key="index"
          :consolidate-data="consolidateData"
          :consolidate-index="index"
          :stop-approval-id="consolidateApproval.consolidateApprovalId"
          :check-btn-click="addToTruckInventoryBtnClicked"
          :is-selected-ccid="consolidateData.is_selected"
        />
      </div>
    </div>

    <div class="text-right margin-top-30">
      <button
        class="consolidate-back-btn"
        @click.prevent="goToBackPage()"
      >
        <span class="back-btn-consolidate" />
        <span
          class="margin-left-5"
        >
          Back
        </span>
      </button>
      <button
        class="consolidate-add-btn"
        :disabled="checkAnythingIsSelected()"
        @click.prevent="addToTruckInventory()"
      >
        Add to truck inventory
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ConsolidationCard from './consolidationCard.vue';
import clearAndSaveModalService from '../../utils/clearAndSaveModalService';

export default {
  name: 'ConsolidationBuilder',
  components: { ConsolidationCard },
  computed: {
    ...mapGetters({
      getConsolidateList: 'consolidate/getConsolidateList',
      isCreateCCID: 'consolidate/getIsCreateCCID',
      isSeletedCCID: 'consolidate/getIsSelectedCCID',
      addToTruckInventorySuccess: 'consolidate/getAddToTruckInventorySuccess',
      editApproval: 'getSelectedInventoryApproval',
      rxEditApproval: 'rxApproval/getRxEditApproval',
    }),
  },
  data() {
    return {
      isEligibleCCIDs: false,
      addToTruckInventoryBtnClicked: false,
    };
  },
  methods: {
    createNewCCID() {
      this.consolidateApproval.tripId = this.$store.state.stopDetail.stopDetail.trip_id;
      this.$store.dispatch('consolidate/createAndSaveNewCCID', this.consolidateApproval);
      this.$forceUpdate();
    },
    goToBackPage() {
      let approvalType;
      if (this.consolidateApproval.waste_type === 'retail') {
        approvalType = 'consolidate';
      } else {
        approvalType = 'rxConsolidate';
      }
      if (this.$store.state.approvalList.approvalStatusType) {
        this.$store.commit('consolidate/resetConsolidation');
        this.$store.commit('resetConsolidateApproval');
        this.$router.go(-1);
      } else {
        clearAndSaveModalService(this.$store, approvalType, this.routePath.from, this.routePath.to, 'goPreviousRoute');
      }
    },
    addToTruckInventory() {
      if (this.checkAnythingIsSelected()) {
        this.triggerErrorMessage();
      } else {
        const consolidationContainerIds = [];
        this.getConsolidateList.forEach((consolidateData) => {
          if (consolidateData.is_selected) {
            consolidationContainerIds.push(consolidateData.id);
          }
        });
        const stopApprovalId = this.$store.state.approvalList.consolidateApproval.id;
        this.$store.commit('resetConsolidateApproval');
        this.$store.commit('rxApproval/resetApprovalDetail');
        this.$store.commit('rxContent/resetContent');
        this.$store.dispatch('consolidate/addToTruckInventoryAction',
          {
            consolidationContainerIds,
            stopApprovalId,
          });
        this.$store.commit('resetApprovalDetail');
        this.$store.commit('container/resetContainerType');
        this.$store.commit('resetScaleWeight');
        this.$store.commit('resetScaleWeightUnit');
        this.$store.commit('setSelectedInventoryApproval', {});
      }
    },
    showEligibleCCIDs() {
      this.isEligibleCCIDs = !this.isEligibleCCIDs;
    },
    checkAnythingIsSelected() {
      let resp = false;
      const containers = this.getConsolidateList;
      resp = containers
        .every((ccid) => ccid.container_id && ccid.container_size_id);
      if (resp) {
        resp = this.getConsolidateList.some((ccid) => ccid.is_selected);
      }
      return !resp;
    },
    triggerErrorMessage() {
      this.addToTruckInventoryBtnClicked = true;
    },
    isCCIDAvailable() {
      return !this.getConsolidateList.some((ccid) => ccid.is_selected);
    },
  },
  props: {
    consolidateApproval: {
      type: Object,
      default: () => ({ }),
    },
    routePath: {
      type: Object,
      default: () => ({ }),
    },
  },
};
</script>
