//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UniqueManifestNumberWarning',
  data() {
    return {
      dialog: false,
      manifestNumber: '',
    };
  },
  created() {
    this.checkStatusWarningDialog();
  },
  methods: {
    checkStatusWarningDialog() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'stopDocuments/setIsUniqueNumber') {
          this.dialog = !mutation.payload.isUnique;
          this.manifestNumber = mutation.payload.manifestNumber;
        }
      });
    },
    gotIt() {
      this.dialog = false;
    },
  },
};
