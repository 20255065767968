import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function getConsolidateListByApprovalID(approvalID) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}trips/ccids/${approvalID}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getContainerSizeList() {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}trips/list_container_sizes`;
  return fetchService.getFetchData(url, requestOptions);
}

function getContainerTypeList() {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}trips/list_container_types`;
  return fetchService.getFetchData(url, requestOptions);
}

function addToTruckInventory(params) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}trips/add_to_stop_inventory`;
  return fetchService.getFetchData(url, requestOptions);
}

function markAsFullByConsolidationContainerId(consolidationContainerId) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = `${API_BASE_URL}mark_as_full/${consolidationContainerId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function reopenContainerByConsolidationContainerId(consolidationContainerId) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = `${API_BASE_URL}reopen_container/${consolidationContainerId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function CCIDContainerChangeByConsolidationContainerId(containerId, params) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}trips/ccid/${containerId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function removeRealCCID(containerId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = `${API_BASE_URL}trips/ccid/${containerId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function removeConsolidateData(stopApprovalId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = `${API_BASE_URL}trips/leave_consolidation/${stopApprovalId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function removeRxConsolidateData(stopRxItemId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = `${API_BASE_URL}stop_rx_items/${stopRxItemId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function deleteFinalApprovalWithCCID(stopApprovalId) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  };
  const url = `${API_BASE_URL}approvals/${stopApprovalId}`;
  return fetchService.getFetchData(url, requestOptions);
}

function createAndSaveNewCCID(params) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}trips/ccid/create`;
  return fetchService.getFetchData(url, requestOptions);
}

function createAndSaveDuplicateCCID(params) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}ccid/duplicate/${params.consolidate_container_id}`;
  return fetchService.getFetchData(url, requestOptions);
}

function getPListedApprovalList(params) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  };
  const url = `${API_BASE_URL}stops/p_listed_approvals`;
  return fetchService.getFetchData(url, requestOptions);
}

const ConsolidateService = {
  getConsolidateListByApprovalID,
  addToTruckInventory,
  CCIDContainerChangeByConsolidationContainerId,
  getContainerSizeList,
  getContainerTypeList,
  markAsFullByConsolidationContainerId,
  reopenContainerByConsolidationContainerId,
  removeConsolidateData,
  removeRxConsolidateData,
  deleteFinalApprovalWithCCID,
  removeRealCCID,
  createAndSaveNewCCID,
  createAndSaveDuplicateCCID,
  getPListedApprovalList,
};

export default ConsolidateService;
