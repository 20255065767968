//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'HelpDocumentMobile',
  methods: {
    goToBack() {
      this.$router.go(-1);
    },
  },
};
