//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import stopInventoryCard from '../../../helpers/component/stop-inventory-card/stopInventoryCard.vue';
import viewAllCCIDCard from './viewAllCCIDCard.vue';
import expectedPickupInventory from '../../../helpers/component/expected-pickup-inventory/expectedPickupInventory.vue';
import connectedStopConstant from '../../../constants/connected-stop';

export default {
  name: 'StopDetailWorkArea',
  components: {
    stopInventoryCard,
    viewAllCCIDCard,
    expectedPickupInventory,
  },
  computed: {
    ...mapGetters({
      enableAddRxBtn: 'getEnableAddRxBtn',
      addToTruckInventorySuccess: 'consolidate/getAddToTruckInventorySuccess',
      addedCCIDs: 'consolidate/getAddedCCIDs',
      stopDetailChange: 'getStopDetail',
      stopInventoryLists: 'getStopInventoryLists',
      useCCIDStorage: 'getUseCCIDStorage',
      getAllCcidList: 'trip/getAllCcidList',
      activeInventoryTab: 'getStopInventoryActiveTab',
      expectedApiCallResponse: 'getAccumulationApiCallStatus',
      accumulationPickupList: 'getAccumulationPickupList',
    }),
    isActiveStop() {
      return this.stopDetailChange.is_active;
    },
    isAccumulationPickupData() {
      return this.stopDetailChange.connected_status === connectedStopConstant
        .status.ACCUMULATION_DATA_PRESENT;
    },
    isCompletedStop() {
      return this.stopDetailChange.is_completed;
    },
  },
  data() {
    return {
      stopDetail: {},
      stopCompleted: false,
      showCCIDSuccess: false,
      showFadeIn: false,
      showStopInventory: true,
      showTripInventory: false,
      showExpectedPickup: false,
    };
  },
  created() {
    const { consolidateApproval } = this.$store.state.approvalList;
    if (consolidateApproval && consolidateApproval.id) {
      this.resetApprovalAndRxApproval();
      if (consolidateApproval.waste_type && consolidateApproval.waste_type === 'rx') {
        this.$router.push({ name: 'RxConsolidate', params: { draftRxConsolidate: true } });
      } else if (this.useCCIDStorage) {
        this.$router.push({ name: 'Consolidate' });
      }
    }
    const { approvalDetail } = this.$store.state.approvalList;
    if (approvalDetail.id) {
      this.$router.push({ name: 'Add Approval' });
    }
    const { rxApprovalDetail } = this.$store.state.rxApproval;
    if (rxApprovalDetail.id) {
      this.$router.push({ name: 'Add Rx Items' });
    }
    this.$store.commit('rxApprovalList/hideRxApprovalConsolidationList', false);
    this.stopCompleted = this.stopDetailChange.is_completed;
    this.updateExpectedPickupActiveTab();
    this.getAccumulationContainers();
  },
  methods: {
    addApproval() {
      if (!this.stopDetailChange.is_completed) {
        this.resetApprovalAndRxApproval();
        this.$store.dispatch('resetApprovalAddedFromContainer');
        this.$store.commit('setApprovalStatusType', false);
        this.$router.push({ name: 'Approval List' });
      }
    },
    gotoRXBuilder() {
      if (!this.stopDetailChange.is_completed) {
        this.resetApprovalAndRxApproval();
        this.$store.dispatch('resetApprovalAddedFromContainer');
        this.$store.commit('rxApproval/setSelectedEditApproval', {});
        this.$store.commit('rxApproval/resetApprovalDetail', {});
        this.$store.commit('rxContent/resetContent', {});
        this.$store.commit('setApprovalStatusType', false);
        this.$router.push({ name: 'Add Rx Items' });
      }
    },
    getInventoryLists() {
      this.$store.dispatch('getInventoryLists', this.stopDetailChange.id);
    },
    getAllCCID() {
      this.$store.dispatch('trip/viewAllCcid', this.stopDetailChange.trip_id);
    },
    resetApprovalAndRxApproval() {
      this.$store.commit('resetApprovalDetail');
      this.$store.commit('container/resetContainerType');
      this.$store.dispatch('rxApproval/resetAllContentData');
      this.$store.commit('resetScaleWeight');
      this.$store.commit('resetScaleWeightUnit');
      this.$store.commit('setSelectedInventoryApproval', {});
    },
    changeSelection(type) {
      this.showStopInventory = false;
      this.showTripInventory = false;
      this.showExpectedPickup = false;
      this.$store.commit('setStopInventoryActiveTab', type);
      switch (type) {
        case 'trip':
          this.getAllCCID();
          this.showTripInventory = true;
          break;
        case 'stop':
          this.showStopInventory = true;
          this.getInventoryLists();
          break;
        case 'expected pickup':
          this.showExpectedPickup = true;
          break;
        default:
      }
    },
    checkConnectedStopStatus() {
      if (this.stopDetailChange.connected_status) {
        if (this.stopDetailChange.connected_status === connectedStopConstant.status.NOT_CONNECTED) {
          return false;
        }
        return true;
      }
      return false;
    },
    updateExpectedPickupActiveTab() {
      if (this.activeInventoryTab) {
        this.changeSelection(this.activeInventoryTab);
      } else if (this.checkConnectedStopStatus() && !this.isCompletedStop) {
        this.switchTabForAccumulationData();
      } else {
        this.changeSelection('stop');
      }
    },
    switchTabForAccumulationData() {
      if (this.isAccumulationPickupData) {
        this.changeSelection('expected pickup');
      } else {
        this.changeSelection('stop');
      }
    },
    getAccumulationContainers() {
      if (this.checkConnectedStopStatus() && this.isActiveStop && this.stopDetailChange.connected_status === connectedStopConstant
        .status.ACCUMULATION_DATA_PRESENT) {
        this.$store.dispatch('getAccumulationContainerList', this.stopDetailChange.id);
      }
    },
  },
  watch: {
    stopDetailChange: {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal.id !== oldVal.id) {
          this.$store.commit('setStopInventoryActiveTab', null);
          this.updateExpectedPickupActiveTab();
        }
      },
    },
  },
};
