<template>
  <tr
    class="label-table-item position-relative"
    :class="{ 'selected-row' : rowData.selected }"
  >
    <td @click="switchLabelSelection">
      <div class="d-flex img-div ">
        <img
          class="check-img"
          src="src/assets/images/check-circle.svg"
          alt="checkCircle"
          v-if="rowData.selected"
        >
        <img
          class="check-img"
          src="src/assets/images/check-circle-grey.svg"
          alt="checkCircleGrey"
          v-else
        >
      </div>
    </td>
    <td
      v-for="(item, index) in headers.slice(0, -1)"
      :key="index"
      @click="switchLabelSelection"
    >
      <div :class="item === ccidHeader ? 'ccid-div' : ''">
        {{ rowData[item] }}
      </div>
      <div
        v-if="item === non_ccid_approval_header || item === ccidHeader"
        class="approval-div"
      >
        Approval: {{ rowData.approval }}
      </div>
    </td>
    <td
      class="quantity-row"
    >
      <quantitySelector
        :quantity="rowData.quantity"
        :minimum="1"
        :maximum="10"
        @quantityChanged="updateQuantity"
      />
    </td>
  </tr>
</template>

<script>
import quantitySelector from '../quantity-selector/quantitySelector.vue';

export default {
  name: 'LabelTableItem',
  components: {
    quantitySelector,
  },
  data() {
    return {
      ccidHeader: 'ccid',
      non_ccid_approval_header: 'approval_code',
    };
  },
  methods: {
    switchLabelSelection() {
      if (this.rowData.selected) {
        this.$store.dispatch('stopLabels/deselectLabel', this.rowData.ccid + this.rowData.displayedType);
      } else {
        this.$store.dispatch('stopLabels/selectLabel', this.rowData.ccid + this.rowData.displayedType);
      }
    },
    updateQuantity(qty) {
      this.$store.dispatch('stopLabels/updateQuantity', { ccid: this.rowData.ccid + this.rowData.displayedType, qty });
    },
  },
  props: {
    rowData: {
      type: Object,
      default() {
        return {};
      },
    },
    headers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
