const stateObj = {
  lastEmail: localStorage.getItem('lastEmail') ? localStorage.getItem('lastEmail') : [],
  recentTech: false,
  states: {
    myTrips: true,
    helpPage: false,
  },
  updateIsAvailableForDownload: false,
  downloadInProgress: false,
  updateHasFinishedDownloading: false,
  updateReadyToastDisplayed: false,
  softwareVersions: {},
};
function setSideNavBarActive(state, field) {
  const stateAttr = state;
  if (field !== 'states') {
    stateAttr.states[field] = true;
  }
  Object.keys(stateAttr.states).forEach((key) => {
    if (key !== field) {
      stateAttr.states[key] = false;
    }
  });
}
const getters = {
  getLastLogin(context) {
    return context.lastEmail;
  },
  getRecentTech(context) {
    return context.recentTech;
  },
  getSideNavBarStates(context) {
    return context.states;
  },
  getUpdateHasFinishedDownloading(context) {
    return context.updateHasFinishedDownloading;
  },
  getDownloadInProgress(context) {
    return context.downloadInProgress;
  },
  getUpdateIsAvailableForDownload(context) {
    return context.updateIsAvailableForDownload;
  },
  getUpdateReadyToastDisplayed(context) {
    return context.updateReadyToastDisplayed;
  },
  getSoftwareVersions(state) {
    return state.softwareVersions;
  },
};
const mutations = {
  saveLastEmail(stateControl, emailDetail) {
    const stateControld = stateControl;
    let lastEmailList = [];
    if (localStorage.getItem('lastEmail')) {
      lastEmailList = JSON.parse(localStorage.getItem('lastEmail'));
    }
    const emailIndex = lastEmailList.findIndex((email) => email.id === emailDetail.id);
    if (emailIndex > -1) {
      lastEmailList.splice(emailIndex, 1);
    }
    lastEmailList.unshift({
      id: emailDetail.id,
      email: emailDetail.email,
      first_name: emailDetail.first_name,
      last_name: emailDetail.last_name,
    });
    if (lastEmailList.length > 4) {
      lastEmailList = lastEmailList.slice(0, 4);
    }
    localStorage.setItem('lastEmail', JSON.stringify(lastEmailList).toString());
    stateControld.lastEmail = lastEmailList;
  },
  checkSelectedTech(stateControl, value) {
    const stateControld = stateControl;
    stateControld.recentTech = value;
  },
  setUpdateHasFinishedDownloading(stateControl, value) {
    const stateControld = stateControl;
    stateControld.updateHasFinishedDownloading = value;
  },
  setDownloadInProgress(stateControl, value) {
    const stateControld = stateControl;
    stateControld.downloadInProgress = value;
  },
  setUpdateIsAvailableForDownload(stateControl, value) {
    const stateControld = stateControl;
    stateControld.updateIsAvailableForDownload = value;
  },
  setUpdateReadyToastDisplayed(stateControl, value) {
    const stateControld = stateControl;
    stateControld.updateReadyToastDisplayed = value;
  },
  setSoftwareVersions(stateControl, payload) {
    const stateControld = stateControl;
    stateControld.softwareVersions = payload;
  },
};
const actions = {
  saveLastEmail(context, res) {
    context.commit('saveLastEmail', res);
  },
  getEmailLocalStorage(context, res) {
    context.commit('sessionEmail', res);
  },
  checkSelectedTech(context, res) {
    context.commit('checkSelectedTech', res);
  },
  setSideNavBarActive(context, payload) {
    setSideNavBarActive(context.state, payload);
  },
  setUpdateHasFinishedDownloading(context, payload) {
    context.commit('setUpdateHasFinishedDownloading', payload);
  },
  setDownloadInProgress(context, payload) {
    context.commit('setDownloadInProgress', payload);
  },
  setUpdateIsAvailableForDownload(context, payload) {
    context.commit('setUpdateIsAvailableForDownload', payload);
  },
  setUpdateReadyToastDisplayed(context, payload) {
    context.commit('setUpdateReadyToastDisplayed', payload);
  },
};
export default {
  state: stateObj,
  getters,
  actions,
  mutations,
};
