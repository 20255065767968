//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'BeginStopInstruction',
  computed: {
    ...mapGetters({
      stopDetail: 'getStopDetail',
      user: 'authentication/getUser',
    }),
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    beginStop() {
      const params = { stopId: this.stopDetail.doc_id, userId: this.user.doc_id };
      this.$store.dispatch('updateStopStatus', params);
      this.$router.push('/mobile/stop-detail');
    },
  },
};
