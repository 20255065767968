<template>
  <div
    class="d-flex page-sequence"
    :class="getClass()"
    @click="gotoPage"
  >
    <div
      class="check-list-box-active"
    >
      <img
        :src="getImage()"
        alt="checkCircle"
      >
      <label>{{ sequenceDetail.label }}</label>
      <div class="reprint-container">
        <img
          v-if="showReprintAlertIcon()"
          class="page-sequence-alert"
          src="src/assets/images/alert.svg"
          alt="alert"
        >
      </div>
    </div>
  </div>
</template>
<script>

import { mapGetters } from 'vuex';

export default {
  name: 'PageSequence',
  computed: {
    ...mapGetters({
      reprintDocStatus: 'stopDocuments/getDocumentReprintStatus',
      reprintLabelStatus: 'stopLabels/getLabelReprintStatus',
    }),
  },
  methods: {
    getClass() {
      let className = 'page-sequence-deactive';
      if (this.sequenceDetail.active) {
        className = 'page-sequence-active';
      }
      return className;
    },
    getImage() {
      let imageUrl = 'src/assets/images/nav-inactive-icon.svg';
      if (this.sequenceDetail.active) {
        imageUrl = 'src/assets/images/nav-active-icon.svg';
      }
      return imageUrl;
    },
    gotoPage() {
      const pagePath = this.sequenceDetail.path.replace('stopID', this.$route.params.stopId);
      this.$router.push({ path: pagePath });
    },
    showReprintAlertIcon() {
      if (this.sequenceDetail.index === 2) {
        return this.reprintDocStatus;
      }
      if (this.sequenceDetail.index === 3) {
        return this.reprintLabelStatus;
      }
      return false;
    },
  },
  props: {
    sequenceDetail: {
      type: Object,
      default: () => ({
        label: '',
        path: '',
        active: false,
        disabled: false,
      }),
    },
    firstIndex: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
