//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import VSelectArrow from '../../utils/v-select-arrow';

export default {
  name: 'ContainerType',
  computed: {
    ...mapGetters({ typeListChange: 'container/getContainerTypeList' }),
  },
  data: () => ({
    containerTypeList: [],
    selectedID: '',
  }),
  extends: VSelectArrow,
  created() {
    const typeList = this.$store.state.container.containerTypeList;
    this.selectedID = this.parentType.id ? +this.parentType.id : '';
    typeList.map((containerType) => {
      if (!containerType.is_selected || this.selectedID) {
        this.containerTypeList.push(containerType);
      }
      return containerType;
    });

    this.getContainerTypeListChanges();
  },
  updated() {
    this.selectedID = this.parentType.id ? +this.parentType.id : '';
    this.containerTypeList = this.$store.state.container.containerTypeList;
  },
  methods: {
    containerTypeChange(event) {
      if (event) {
        this.$store.dispatch('container/addContainerTypeAction', { index: this.containerIndex, typeID: event.id });
      }
    },
    getContainerTypeListChanges() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'container/setContainerTypeID'
        || mutation.type === 'container/resetContainerIndex'
         || mutation.type === 'container/setContainerLists'
         || mutation.type === 'container/triggerContainerTypeList') {
          this.containerList = [];
          this.containerList = state.container.containerList;
          this.containerTypeList = state.container.containerTypeList
            .filter((containerType) => (!containerType.is_selected
          || containerType.containerIndex === this.containerIndex));
        }
      });
    },
  },
  props: {
    parentType: {
      type: Object,
      default: () => ({
        id: '',
        container_type: '',
      }),
    },
    lastIndex: {
      type: Boolean,
      default: false,
    },
    containerIndex: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    typeListChange(newVal) {
      this.containerTypeList = newVal;
    },
  },
};
