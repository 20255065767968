import { API_BASE_URL } from '../constants/index';
import fetchService from './fetch.service';

function getApprovals(generatorId, value) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}approvals/stop/${generatorId}?search=${value}`;
  return fetchService.getFetchData(url, requestOptions);
}
function getInventoryLists(stopId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}stop_inventory/${stopId}`;
  return fetchService.getFetchData(url, requestOptions);
}
function getStopApproval(approvalId, wasteType) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}edit_approval/${approvalId}?waste_type=${wasteType}`;
  return fetchService.getFetchData(url, requestOptions);
}
function consolidateApprovals(payload) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };
  const url = `${API_BASE_URL}consolidate`;
  return fetchService.getFetchData(url, requestOptions);
}
function updateConsolidate(id, payload) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };
  const url = `${API_BASE_URL}consolidate/${id}`;
  return fetchService.getFetchData(url, requestOptions);
}
function getManiFestUnits() {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}approvals/measure_units`;
  return fetchService.getFetchData(url, requestOptions);
}
function reopenApproval(id) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  };
  const url = `${API_BASE_URL}reopen_container/${id}`;
  return fetchService.getFetchData(url, requestOptions);
}
function markasFull(id) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({}),
  };
  const url = `${API_BASE_URL}mark_as_full/${id}`;
  return fetchService.getFetchData(url, requestOptions);
}

function fetchRxApprovalDetail(item) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(item),
  };
  const url = `${API_BASE_URL}approvals/get_rx_approval_details`;
  return fetchService.getFetchData(url, requestOptions);
}
function getApproval(id, manifestId) {
  const requestOptions = {
    method: 'GET',
  };
  const url = `${API_BASE_URL}approvals/approval_details/${id}?manifest_id=${manifestId}`;
  return fetchService.getFetchData(url, requestOptions);
}

const approvalService = {
  getApprovals,
  getInventoryLists,
  getStopApproval,
  consolidateApprovals,
  updateConsolidate,
  getManiFestUnits,
  reopenApproval,
  markasFull,
  fetchRxApprovalDetail,
  getApproval,
};
export default approvalService;
