//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//



export default {
  name: 'MobileHeader',
  data() {
    return {
      pageTitle: '',
      pageUrl: '',
      urlTitle: '',
      showHeader: true,
      dialog: false,
    };
  },
  created() {
    this.getPageHeader();
  },
  methods: {
    getPageHeader() {
      const routeArray = this.$route.path.substring(1).split('/');
      const routePath = `${routeArray[0]}/${routeArray[1]}`;
      const noHeaderArray = [
        'Mobile View Item Details',
        'Mobile Add Rx Item Details',
        'Mobile Active Stop Warning',
        'Mobile UPC Scan',
      ];
      if (routePath === 'mobile/trip-list') {
        this.pageTitle = 'Select a trip';
        this.pageUrl = '';
        this.urlTitle = '';
      }
      if (routePath === 'mobile/stop-list') {
        this.pageTitle = 'Select a stop';
        this.pageUrl = '/mobile/trip-list';
        this.urlTitle = 'View trips';
      }
      if (routePath === 'mobile/stop-detail'
        || routePath === 'mobile/mobile-stop-instruction') {
        const { stopDetail } = this.$store.state.stopDetail;
        this.pageTitle = `Stop ${stopDetail.stop_number}`;
        this.pageUrl = `/mobile/stop-list/${stopDetail.trip_id}/${stopDetail.tripCode}`;
        this.urlTitle = 'Stop list';
      }
      if (routePath === 'mobile/mobile-help-documentation' || routePath === 'mobile/mobile-release-notes' || routePath === 'mobile/mobile-contact-examples' || routePath === 'mobile/mobile-help-feedback') {
        this.pageTitle = 'Help & Support';
      }
      if (noHeaderArray.includes(this.$route.name)) {
        this.showHeader = false;
      } else {
        this.showHeader = true;
      }
    },
    gotoPage() {
      this.$router.push({ path: this.pageUrl });
    },
    goToTraining() {
      this.dialog = false;
      this.$router.push({ path: '/mobile/mobile-help-documentation' });
    },
    goToReleaseNotes() {
      this.dialog = false;
      this.$router.push({ path: '/mobile/mobile-release-notes' });
    },
    goToContactExamples() {
      this.dialog = false;
      this.$router.push({ path: '/mobile/mobile-contact-examples' });
    },
    goToFeedback() {
      this.dialog = false;
      this.$router.push({ path: '/mobile/mobile-help-feedback' });
    },
  },
  watch: {
    $route() {
      this.getPageHeader();
    },
  },
};
