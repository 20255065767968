<template>
  <v-dialog
    v-model="dialog"
    width="460"
  >
    <v-card class="pin-modal-bg">
      <v-card
        :class=" inValidPin === true ? 'pin-header-bg-error' : 'pin-header-bg'"
        class="ma-1"
      >
        <v-layout
          class="mx-0"
          row
        >
          <v-flex class="col-md-8 label pl-5">
            <pinToolTip
              v-if="error"
              :error-message="error"
            />
            <template v-if="!error && isCreateAccount === false">
              Enter your PIN
            </template>
            <template v-if="!error && isCreateAccount === true && confirmPin === false">
              Create a 4-digit PIN
            </template>
            <template v-if="!error && isCreateAccount === true && confirmPin === true">
              Retype your PIN to confirm
            </template>
          </v-flex>
          <v-flex
            class="col-md-4 text-end pr-5"
            v-if="isCreateAccount === false"
          >
            <a
              href="javascript:void(0)"
              @click="gotoForgotPin()"
            >Forgot PIN</a>
          </v-flex>
        </v-layout>
        <v-layout
          class="ml-4 ml-4 mr-0 min-height-57"
          row
        >
          <v-flex
            :class=" inValidPin === true ? 'pin-line-error' : 'pin-line'"
            class="col-md-2 mb-2 mr-4 text-center"
          >
            <span
              v-if="pin.length>0"
              :class=" inValidPin === true ? 'pin-dot-error' : 'pin-dot'"
            />
            <template v-if="pin.length ===0 && pinSingleDigit">
              <pinDigit :pin-single-digit="pinSingleDigit" />
            </template>
          </v-flex>
          <v-flex
            :class=" inValidPin === true ? 'pin-line-error' : 'pin-line'"
            class="col-md-2 mb-2 mr-4 text-center"
          >
            <span
              v-if="pin.length>1"
              :class=" inValidPin === true ? 'pin-dot-error' : 'pin-dot'"
            />
            <template v-if="pin.length ===1 && pinSingleDigit">
              <pinDigit :pin-single-digit="pinSingleDigit" />
            </template>
          </v-flex>
          <v-flex
            :class=" inValidPin === true ? 'pin-line-error' : 'pin-line'"
            class="col-md-2 mb-2 mr-4 text-center"
          >
            <span
              v-if="pin.length>2"
              :class=" inValidPin === true ? 'pin-dot-error' : 'pin-dot'"
            />
            <template v-if="pin.length ===2 && pinSingleDigit">
              <pinDigit :pin-single-digit="pinSingleDigit" />
            </template>
          </v-flex>
          <v-flex
            :class=" inValidPin === true ? 'pin-line-error' : 'pin-line'"
            class="col-md-2 mb-2 mr-4 text-center"
          >
            <span
              v-if="pin.length>3"
              :class=" inValidPin === true ? 'pin-dot-error' : 'pin-dot'"
            />
            <template v-if="pin.length ===3 && pinSingleDigit">
              <pinDigit :pin-single-digit="pinSingleDigit" />
            </template>
          </v-flex>
        </v-layout>
      </v-card>

      <v-row
        align="center"
        class="mx-auto"
      >
        <v-col
          class="text-center pa-1"
          cols="12"
          sm="4"
          v-for="n in 9"
          :key="n"
        >
          <pinButton
            :input-digit="''+n"
            @inputButtonChange="inputButtonChanges"
          />
        </v-col>

        <v-col
          class="text-center pa-1"
          cols="12"
          sm="4"
        >
          <v-btn
            @click="deletePin()"
            class="pin-button text-capitalize pa-0 white"
          >
            <v-img
              src="src/assets/images/key-delete.svg"
              left
              class="pr-2"
              aspect-ratio="1"
              max-width="36"
              max-height="23"
            />
            <span class="ml-2 fs-24 font-weight-bold">
              Delete
            </span>
          </v-btn>
        </v-col>
        <v-col
          class="text-center pa-1"
          cols="12"
          sm="4"
        >
          <pinButton
            :input-digit="'0'"
            @inputButtonChange="inputButtonChanges"
          />
        </v-col>
        <v-col
          class="text-center pa-1"
          cols="12"
          sm="4"
        >
          <v-btn class="pin-button white" />
        </v-col>
        <v-col
          class="text-center pa-1"
          cols="4"
          sm="4"
        >
          <v-btn
            @click="handleInput('cancel')"
            class="pin-button text-capitalize keyboard-cancel fs-24"
          >
            Cancel
          </v-btn>
        </v-col>
        <v-col
          class="text-center pa-1"
          cols="8"
          sm="8"
        >
          <v-btn
            @click="pinSubmit()"
            class="pin-button text-capitalize keyboard-submit btn-submit fs-24"
            v-if="isCreateAccount === false"
          >
            Sign in
          </v-btn>
          <v-btn
            @click="createPinSubmit()"
            class="pin-button text-capitalize keyboard-submit btn-submit fs-24"
            v-if="isCreateAccount === true && confirmPin === false"
          >
            Next: Confirm PIN
          </v-btn>
          <v-btn
            @click="confirmPinSubmit()"
            class="pin-button text-capitalize keyboard-submit btn-submit fs-24"
            v-if="isCreateAccount === true && confirmPin === true"
          >
            Create account
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import pinToolTip from '../tool-tip/pinToolTip.vue';
import pinDigit from './pinDigit.vue';
import pinButton from './pinButton.vue';


export default {
  name: 'PinDialog',
  components: { pinToolTip, pinDigit, pinButton },
  computed: {
    ...mapGetters({
      errorMessage: 'authentication/getError',
      isCreateAccount: 'createAccount/getPinDialogValue',
      createAccountData: 'createAccount/getCreateAccountValue',
      loginStatus: 'authentication/getloginStatus',
    }),
  },
  data() {
    return {
      dialog: false,
      pin: '',
      inValidPin: false,
      error: '',
      showToolTip: false,
      pinSingleDigit: '',
      confirmPin: false,
      firstPin: '',
      secondPin: '',
    };
  },
  methods: {
    handleInput(pin) {
      if (pin === 'cancel') {
        this.pin = '';
        this.dialog = false;
        this.inValidPin = false;
        this.error = '';
        this.firstPin = '';
        this.secondPin = '';
        this.confirmPin = false;
        return;
      }
      if (this.pin.length === 4) {
        return;
      }
      this.pinSingleDigit = pin;
    },
    gotoForgotPin() {
      this.dialog = false;
      this.$store.dispatch('authentication/setLoginEmailAction', this.email);
      this.$router.push('/forgot-pin');
    },
    deletePin() {
      if (this.pin.length > 0) {
        this.pin = this.pin.slice(0, -1);
      }
    },
    pinSubmit() {
      if (!this.inValidPin && this.pin.length !== 4) {
        this.inValidPin = true;
        this.error = 'Invalid pin. Try again?';
      }
      if (this.inValidPin === false && this.pin.length === 4) {
        this.inValidPin = false;
        this.error = '';
        this.$store.dispatch('authentication/loginAction', {
          email: this.email,
          password: this.pin,
        });
      }
    },
    createPinSubmit() {
      if (!this.inValidPin && this.pin.length !== 4) {
        this.inValidPin = true;
        this.error = 'Invalid pin. Try again?';
      }
      if (this.inValidPin === false && this.pin.length === 4) {
        this.inValidPin = false;
        this.error = '';
        this.firstPin = this.pin;
        this.pin = '';
        this.confirmPin = true;
      }
    },
    confirmPinSubmit() {
      if (!this.inValidPin && this.pin.length !== 4) {
        this.inValidPin = true;
        this.error = 'Invalid pin. Try again?';
      }
      if (this.inValidPin === false && this.pin.length === 4) {
        this.inValidPin = false;
        this.error = '';
        if (this.firstPin === this.pin) {
          this.secondPin = this.pin;
          this.$store.dispatch('createAccount/createAccountAction', {
            first_name: this.createAccountData.first_name,
            last_name: this.createAccountData.last_name,
            email: this.createAccountData.email,
            phone_number: this.createAccountData.phone_number,
            pin: this.secondPin,
          }).then((res) => {
            if (res === 'failed') {
              this.pin = '';
              this.dialog = false;
              this.inValidPin = false;
              this.error = '';
              this.firstPin = '';
              this.secondPin = '';
              this.confirmPin = false;
            } else {
              this.$store.dispatch('authentication/loginAction', {
                email: this.createAccountData.email,
                password: this.secondPin,
              });
            }
          });
        } else {
          this.inValidPin = true;
          this.error = 'PINs do not match. Try again.';
        }
      }
    },
    inputButtonChanges(input) {
      this.handleInput(input);
    },


  },
  props: {
    openDialog: Boolean,
    email: {
      type: String,
      default: '',
    },
  },
  watch: {
    openDialog(newVal) {
      this.dialog = newVal;
    },
    dialog(newVal) {
      if (!newVal) {
        this.pin = '';
        this.error = '';
        this.inValidPin = false;
      }
      this.$emit('dialogInputChanged', newVal);
    },
    errorMessage(newVal) {
      if (newVal) {
        this.inValidPin = true;
        this.error = newVal;
        this.showToolTip = true;
      }
    },
    pinSingleDigit(newVal) {
      if (newVal) {
        this.pin += this.pinSingleDigit;
        this.pinSingleDigit = '';
        if (this.inValidPin && this.pin.length === 4) {
          this.inValidPin = false;
          this.error = '';
        }
      }
    },
    loginStatus(newVal) {
      if (newVal === true) {
        this.$router.push('logged-in-home');
      }
    },
  },


};
</script>
